import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { AdminOrVendorRoute } from '../../Shared/ProtectedRoute/ProtectedRoute';
import { RequisitionsIndex } from './RequisitionsIndex/RequisitionsIndex';
import { CreateRequisitionIndex } from './CreateRequisition/CreateRequisition';
import { EditRequisition } from './EditRequisition/EditRequisition';
import { EditRequisitionProducts } from './EditRequisitionProducts/EditRequisitionProducts';
import { RequisitionDetails } from './RequisitionDetails/RequisitionDetails';

export const RequisitionsRoutes: FC = () => (
  <Switch>
    <Route exact path={routes.requisitions.index} component={RequisitionsIndex} />
    <AdminOrVendorRoute exact path={routes.requisitions.new}>
      <CreateRequisitionIndex />
    </AdminOrVendorRoute>
    <Route exact path={routes.requisitions.editTemplate} component={EditRequisition} />
    <Route exact path={routes.requisitions.editProductsTemplate} component={EditRequisitionProducts} />
    <Route exact path={routes.requisitions.detailTemplate} component={RequisitionDetails} />
  </Switch>
);
