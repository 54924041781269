import { MergedRequisition } from '../../../../Data/Requisition';
import { CasePatientFormFields } from './casePatientInfo.types';

export const caseDateMin = 500;
export const caseDateMax = 0;

export const casePatientInfoDefaultValues: CasePatientFormFields = {
  patientsMRN: '',
  patient: null,
  caseNumber: '',
  caseDate: null,
};

export const getCasePatientInfoInitialValues = (requisition: MergedRequisition): CasePatientFormFields => ({
  patientsMRN: requisition.patient?.mrn ?? casePatientInfoDefaultValues.patientsMRN,
  patient: requisition.patient ?? casePatientInfoDefaultValues.patient,
  caseNumber: requisition.caseNumber ?? casePatientInfoDefaultValues.caseNumber,
  caseDate: requisition.caseDate ?? casePatientInfoDefaultValues.caseDate,
});

export const getReqFromFormikValues = ({ patient, caseNumber, caseDate }: CasePatientFormFields): MergedRequisition => ({
  patient: patient!,
  caseNumber: caseNumber || null,
  caseDate: caseDate! || null,
});
