import { FormikErrors, FormikValues } from 'formik';
import { HospitalUserRole, HospitalUserRoleOption, hospitalUserRoles } from '../../../Data/User';
import { dictionary } from '../../../dictionary';
import { createDescriptor, getFieldDescriptorMap } from '../../../Utils/formik.utils';
import { HospitalBase } from '../../../Data/Hospital';
import { DepartmentBase } from '../../../Data/Department';
import { ManufacturerBase } from '../../../Data/Manufacturer';
import { validateRequiredFields } from '../../../Utils/validationUtils';

export interface AddUserAssociationFormFields {
  hospital: HospitalBase;
  userRole: HospitalUserRoleOption;
  departments: DepartmentBase[];
  manufacturers: ManufacturerBase[];
}

export interface UserFormFields {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  hospitalEmployeeId?: string;
  password: string;
}

export const userFormFields = getFieldDescriptorMap<UserFormFields>([
  createDescriptor({ name: 'firstName', label: dictionary.FORM_CONTROL_LABEL_FIRST_NAME, required: true }),
  createDescriptor({ name: 'lastName', label: dictionary.FORM_CONTROL_LABEL_LAST_NAME, required: true }),
  createDescriptor({ name: 'email', label: dictionary.FORM_CONTROL_LABEL_EMAIL, required: true }),
  createDescriptor({ name: 'phoneNumber', label: dictionary.FORM_CONTROL_LABEL_PHONE_NUMBER, required: true }),
  createDescriptor({ name: 'hospitalEmployeeId', label: dictionary.HOSPITAL_EMPLOYEE_ID_LABEL }),
  createDescriptor({ name: 'password', label: dictionary.FORM_CONTROL_LABEL_PASSWORD }),
]);

export const getAddUserAssociationFormFields = (isVendorUser: boolean) => getFieldDescriptorMap<AddUserAssociationFormFields>([
  createDescriptor({ name: 'hospital', label: dictionary.FORM_CONTROL_LABEL_HOSPITAL, required: true }),
  createDescriptor({ name: 'userRole', label: dictionary.USER_ASSOCIATIONS_EDIT_USER_ROLE, required: true }),
  createDescriptor({
    name: 'departments',
    label: isVendorUser ? dictionary.FORM_CONTROL_LABEL_ASSOCIATED_DEPARTMENTS : dictionary.FORM_CONTROL_LABEL_DEPARTMENTS,
    required: true,
    inputFormat: 'multi-select',
  }),
  createDescriptor({ name: 'manufacturers', label: dictionary.FORM_CONTROL_LABEL_MANUFACTURERS, required: isVendorUser, inputFormat: 'multi-select' }),
]);

export const validateAddVendorUserAssociationForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> =>
  validateRequiredFields(values, Object.values(getAddUserAssociationFormFields(true)));

export const validateAddHospitalUserAssociationForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> =>
  validateRequiredFields(values, Object.values(getAddUserAssociationFormFields(false)));

export const getUserRoleOption = (id: HospitalUserRole): HospitalUserRoleOption =>
  hospitalUserRoles.filter(ur => ur.id === id)[0];

export const getUserRoleName = (id: number): string =>
  hospitalUserRoles.filter(ur => ur.id === id)[0]?.name ?? dictionary.EMPTY_FIELD_MARK;
