import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { ReduxState } from '../../../../redux/initialState';
import { HospitalContactNotification } from '../../shared/HospitalContactNotification';
import { FormFlowStepComponent } from '../../../../Shared/FormFlow/FormFlow';
import { dictionary } from '../../../../dictionary';
import { ReqProcedureInfoDisplay } from '../../shared/ReqProcedureInfoDisplay';
import { ReqCasePatientInfoDisplay } from '../../shared/ReqCasePatientInfoDisplay';
import { ReqBillingInfoDisplay } from '../../shared/ReqBillingInfoDisplay';
import { ProductsToBill } from '../../shared/ProductsToBill/ProductsToBill';
import { useUtilityStyles } from '../../../../Themes/utility.styles';
import { MergedRequisition } from '../../../../Data/Requisition';
import { maxLimitMsg } from '../../../../Utils/validationUtils';
import { If } from '../../../../Shared/If';
import { currentUserRoleSelector } from '../../../../redux/user/userSelectors';
import { userRoles } from '../../../../Utils/userRoles';

type ReviewSubmitReqProps = FormFlowStepComponent<Partial<MergedRequisition>>;
export const ReviewSubmitReq: FC<ReviewSubmitReqProps> = ({ FormActionsComponent, onFormStepComplete, onFormSaveDraft }) => {
  const requisition = useSelector((state: ReduxState) => state.requisition.editRequisition);
  const utilityClasses = useUtilityStyles();

  const onSubmitAction = () => onFormStepComplete(requisition);
  const onSaveDraftAction = () => onFormSaveDraft!(requisition);

  const [errorState, setErrorState] = useState(false);

  const handleInputChange = (e: any) => {
    requisition.additionalNotes = e.target.value;
    if (requisition.additionalNotes && requisition.additionalNotes.length > 500) {
      setErrorState(true);
    } else {
      setErrorState(false);
    }
  };

  const currentUserRole = useSelector(currentUserRoleSelector);
  const userCanPostNote = currentUserRole === userRoles.omiAdmin || currentUserRole === userRoles.vendor;

  return (
    <>
      <HospitalContactNotification
        contactPhoneNumber={requisition.hospital?.contactPhoneNumber}
        contactEmail={requisition.hospital?.contactEmail}
        contactMessage={requisition.hospital?.contactMessage}
      />

      <Row className={utilityClasses.mt1}>
        <Col xs={4}><ReqProcedureInfoDisplay requisition={requisition} /></Col>
        <Col xs={4}><ReqCasePatientInfoDisplay requisition={requisition} /></Col>
        <Col xs={4}><ReqBillingInfoDisplay requisition={requisition} /></Col>
      </Row>

      <Row>
        <Col xs={12}>
          <h2>{dictionary.REQ_PRODUCTS_TO_BILL_LABEL}</h2>
          <section>
            <ProductsToBill requisition={requisition} />
          </section>
        </Col>
      </Row>

      <If condition={userCanPostNote}>
        <Row>
          <Col xs={12}>
            <h2>Additional Notes:</h2>
            <If condition={errorState}>
              <div role="alert" className={`validation-errors ${utilityClasses.textRight}`} data-testid="vendor-note-input-error">
                {maxLimitMsg(500)}
              </div>
            </If>
            <textarea className={`textarea ${errorState ? 'input-invalid-field' : ''}`} rows={4} onChange={handleInputChange} />
          </Col>
        </Row>
      </If>

      <FormActionsComponent onSubmitAction={onSubmitAction} onSaveDraftAction={onSaveDraftAction} />
    </>
  );
};
