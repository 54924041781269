import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { MergedRequisition } from '../../../../Data/Requisition';
import { actionTypes } from '../../../../redux/actionTypes';
import { ReduxState } from '../../../../redux/initialState';
import { HospitalContactNotification } from '../../shared/HospitalContactNotification';
import { FormFlowStepComponent } from '../../../../Shared/FormFlow/FormFlow';
import { validateCasePatientForm, validateCasePatientFormMinimum } from '../../shared/validation/casePatientInfo.validation';
import { CasePatientFormFields } from './casePatientInfo.types';
import { CasePatientInfoForm } from './CasePatientInfoForm';
import { getCasePatientInfoInitialValues, getReqFromFormikValues } from './casePatientInfo.utils';
import { useCaseDateUpdateConfirmation } from '../../shared/useCaseDateUpdateConfirmation';
import { omitNullFromObj } from '../../../../Utils/objectUtils';

export const CasePatientInfo: FC<FormFlowStepComponent<MergedRequisition>> = ({
  onFormStepComplete,
  FormActionsComponent,
  onFormStepBack,
  onFormSaveDraft,
}) => {
  const dispatch = useDispatch();
  const updateRequisitionState = (updatedRequisition: MergedRequisition) => dispatch({ type: actionTypes.requisition.updateNew, requisition: updatedRequisition });
  const editRequisition = useSelector((state: ReduxState) => state.requisition.editRequisition);

  const saveAndNavigate = async (values: CasePatientFormFields) => {
    const updatedRequisition = getReqFromFormikValues(values);
    updateRequisitionState(updatedRequisition);
    onFormStepComplete(updatedRequisition);
  };

  const saveAndBack = (values: CasePatientFormFields) => {
    const updatedRequisition: MergedRequisition = getReqFromFormikValues(values);
    updateRequisitionState(updatedRequisition);
    if (onFormStepBack) {
      onFormStepBack();
    }
  };

  const casePatientInfoInitialValues = useMemo(() => getCasePatientInfoInitialValues(editRequisition), [editRequisition]);

  const { onTryFormSubmit, CaseDateConfirmationModal } = useCaseDateUpdateConfirmation(casePatientInfoInitialValues, saveAndNavigate, editRequisition);

  const formikProps = useFormik<CasePatientFormFields>({
    initialValues: casePatientInfoInitialValues,
    validate: editRequisition.validate ? validateCasePatientForm : validateCasePatientFormMinimum,
    onSubmit: onTryFormSubmit,
  });

  const { handleSubmit, values } = formikProps;

  const onSaveDraftAction = async () => {
    onFormSaveDraft!({ ...omitNullFromObj(editRequisition), ...omitNullFromObj(getReqFromFormikValues(values)) });
  };

  return (
    <>
      <HospitalContactNotification
        contactPhoneNumber={editRequisition.hospital?.contactPhoneNumber}
        contactEmail={editRequisition.hospital?.contactEmail}
        contactMessage={editRequisition.hospital?.contactMessage}
      />
      <form onSubmit={handleSubmit}>
        <CasePatientInfoForm formikProps={formikProps} editRequisition={editRequisition} />

        <Row>
          <Col xs={12} lg={8}>
            <FormActionsComponent onBackAction={() => saveAndBack(values)} onSaveDraftAction={onSaveDraftAction} />
          </Col>
        </Row>
      </form>

      <CaseDateConfirmationModal formikProps={formikProps} />
    </>
  );
};
