import { FormikValues, FormikErrors } from 'formik';
import Manufacturer from '../../../Data/Manufacturer';
import { dictionary } from '../../../dictionary';
import { FormFieldDescriptor } from '../../../Shared/fields/formTypes';
import { createDescriptor, getFieldDescriptorMap } from '../../../Utils/formik.utils';
import { validateCharacterLimit, validateRequiredFields } from '../../../Utils/validationUtils';

export interface HospMfctIdentifierFormFields {
  hospital: string;
}

export const hospMfctIdentifierFormFields: Record<keyof HospMfctIdentifierFormFields, FormFieldDescriptor> = {
  hospital: createDescriptor({ name: 'hospital', label: dictionary.FORM_CONTROL_LABEL_HOSPITAL, required: true }),
};

export const validateCreateHospMfctIdentifierForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => ({
  ...validateRequiredFields(values, Object.values(hospMfctIdentifierFormFields)),
});

export interface HospMfctIdentifierAssocFormFields {
  manufacturer: Manufacturer;
  identifier: string;
  siteIdentifier: string;
  siteName: string;
}

const hospMfctAssocFields = getFieldDescriptorMap<HospMfctIdentifierAssocFormFields>([
  createDescriptor({ name: 'identifier', label: dictionary.HOSPITAL_MANUFACTURER_ID_NAME_LABEL, required: true }),
  createDescriptor({ name: 'manufacturer', label: dictionary.CREATE_HOSPITAL_MANUFACTURER_ID_MANUFACTURER, required: true }),
  createDescriptor({ name: 'siteName', label: dictionary.HOSPITAL_MANUFACTURER_ID_SITE_NAME_LABEL }),
  createDescriptor({ name: 'siteIdentifier', label: dictionary.HOSPITAL_MANUFACTURER_ID_SITE_ID_LABEL }),
]);
export const hospMfctIdentifierAssocFormFields = hospMfctAssocFields;

export const validateCreateHospMfctIdentifierAssocForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { identifier, siteName, siteIdentifier } = hospMfctIdentifierAssocFormFields;

  return {
    ...validateCharacterLimit(values, [identifier, siteName, siteIdentifier], 50),
    ...validateRequiredFields(values, Object.values(hospMfctIdentifierAssocFormFields)),
  };
};

export const validateEditHospMfctIdentifierAssocForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { identifier, siteName, siteIdentifier } = hospMfctIdentifierAssocFormFields;

  return {
    ...validateCharacterLimit(values, [identifier, siteName, siteIdentifier], 50),
    ...validateRequiredFields(values, [identifier]),
  };
};
