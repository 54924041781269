import { FC, useEffect, useState } from 'react';
import { Row } from 'react-flexbox-grid';
import { useDispatch } from 'react-redux';
import { Button } from '../buttons/Button';
import { dictionary } from '../../dictionary';
import { Modal } from './Modal/Modal';
import { businessRulesService } from '../../Services/businessRulesService';
import { HospitalBusinessRuleDescriptionRequest } from '../../Data/BusinessRules';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import LoadState from '../../redux/loadState';
import { EmptyState } from '../EmptyState/EmptyState';
import { NotificationType } from '../../redux/initialState';
import { addNotification } from '../../redux/notifications/notificationsActionCreator';

interface BusinessRulesModalProps {
  isOpen?: boolean;
  onDismiss?: () => void;
}

type BusinessRulesModalGenerator = (header: string, hospitalId: number) => FC<BusinessRulesModalProps>;

export const createBusinessRulesModalComponent: BusinessRulesModalGenerator = (header, hospitalId) => ({ isOpen, onDismiss }) => {
  const dispatch = useDispatch();
  const [businessRuleset, setBusinessRuleset] = useState<HospitalBusinessRuleDescriptionRequest[]>();
  const [businessRulesetLoadState, setBusinessRulesetLoadState] = useState(LoadState.loading);

  useEffect(() => {
    (async () => {
      if (isOpen) {
        try {
          const fetched = await businessRulesService.getBusinessRulesByHospId(hospitalId);
          setBusinessRuleset(fetched);
          setBusinessRulesetLoadState(LoadState.loaded);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_BUSINESS_RULES_LOAD_ERR));
          setBusinessRulesetLoadState(LoadState.error);
        }
      }
    })();
  }, [dispatch, isOpen, onDismiss]);

  return (
    <Modal
      data-testid="business-rules-modal"
      title={header}
      isOpen={!!isOpen}
      actionsContent={(
        <>
          <div />
          <Button
            onClick={onDismiss}
            buttonStyle="reverse"
            data-testid="attention-stayonthispage"
          >
            {dictionary.CANCEL}
          </Button>
        </>
      )}
    >
      <Row>
        {businessRulesetLoadState === LoadState.loading && <LoadingSpinner />}
        {businessRulesetLoadState === LoadState.error && <EmptyState>{dictionary.BUSINESS_RULES_EMPTY_STATE}</EmptyState>}
        {businessRulesetLoadState === LoadState.loaded && (
          <ul>
            {businessRuleset?.map(r => {
              switch (r.businessRuleId) {
                case 8: return <li key={r.businessRuleId}>{dictionary.LOOKBACK_PERIOD_DESCRIPTION(parseInt(r.value, 10))}</li>;
                case 9: return <li key={r.businessRuleId}>{dictionary.ELIGIBILITY_DATE_DESCRIPTION(r.value)}</li>;
                default: return <li key={r.businessRuleId}>{r.description}</li>;
              }
            })}
          </ul>
        ) }
      </Row>
    </Modal>
  );
};
