import { FormikErrors, FormikValues } from 'formik';
import { dictionary } from '../../../dictionary';
import { createDescriptor, getFieldDescriptorMap, getFormikInitialValues } from '../../../Utils/formik.utils';
import { validateCharacterLimit, validateRequiredFields } from '../../../Utils/validationUtils';
import { HealthSystem, HealthSystemRequest } from '../../../Data/HealthSystem';

export interface HealthSystemFormFields {
  name: string;
}

export const healthSystemFormFields = getFieldDescriptorMap<HealthSystemFormFields>([
  createDescriptor({ name: 'name', label: dictionary.HEALTH_SYSTEM_FORM_NAME, required: true }),
]);

export const getHealthSystemFormInitialVals = (healthSystem?: HealthSystem): HealthSystemFormFields =>
  getFormikInitialValues<HealthSystemFormFields>(
    healthSystemFormFields,
    {
      name: healthSystem?.name,
    }
  );

export const validateHealthSystemForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { name } = healthSystemFormFields;

  return {
    ...validateCharacterLimit(values, [name], 100),
    ...validateRequiredFields(values, Object.values(healthSystemFormFields)),
  };
};

export const getHealthSystemUpdateRequestFromFormFields = (values: HealthSystemFormFields): HealthSystemRequest =>
  ({
    name: values.name,
  });
