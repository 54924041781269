import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useButtonStyles } from '../../Themes/button.styles';
import { joinArgs } from '../../Utils/arrayUtils';

interface LinkButtonProps {
  to: string;
  'data-testid'?: string;
  leadingIcon?: IconDefinition;
  onClick?: () => void;
}

export const LinkButton: FC<LinkButtonProps> = ({ children, to, leadingIcon, 'data-testid': dataTestId = '', onClick }) => {
  const allButtonClasses = useButtonStyles({ buttonStyle: leadingIcon ? 'reverse' : 'primary', intent: 'primary' });
  const relevantButtonClasses = [allButtonClasses.base, allButtonClasses.primaryIntent];

  if (leadingIcon) {
    relevantButtonClasses.push(allButtonClasses.reverse, allButtonClasses.leadingIcon);
  } else {
    relevantButtonClasses.push(allButtonClasses.large);
  }

  return (
    <Link
      onClick={() => onClick && onClick()}
      data-testid={dataTestId}
      to={to}
      className={joinArgs(...relevantButtonClasses)}
    >
      {leadingIcon ? <FontAwesomeIcon icon={leadingIcon} /> : null}
      {children}
    </Link>
  );
};
