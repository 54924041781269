import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSettingsStyles from './HospitalSelectionStyles';
import { dictionary } from '../../dictionary';
import { HospitalBase } from '../../Data/Hospital';
import { hospitalService } from '../../Services/HospitalService';
import { addNotification } from '../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../redux/initialState';
import { LoadingSpinner } from '../../Shared/LoadingSpinner/LoadingSpinner';

interface VerticalHospitalSelectionPaneProps {
  selectedHospitalId: number;
  setSelectedHospitalId: (id: number) => void;
}

export const VerticalHospitalSelectionPane: FC<VerticalHospitalSelectionPaneProps> = ({ selectedHospitalId, setSelectedHospitalId }) => {
  const [associatedHospitalList, setAssociatedHospitalList] = useState<HospitalBase[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const classes = useSettingsStyles();

  useEffect(() => {
    (async () => {
      try {
        const fetchedAdminAssociatedHospitals = await hospitalService.getAdminHospitals();
        setAssociatedHospitalList(fetchedAdminAssociatedHospitals);
        setSelectedHospitalId(fetchedAdminAssociatedHospitals[0].id);
      } catch (error) {
        dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dispatch, setSelectedHospitalId]);

  const hospitalItems = associatedHospitalList.map((associatedHospital) => (
    <div
      key={associatedHospital.id}
      role="button"
      onClick={() => setSelectedHospitalId(associatedHospital.id)}
      onKeyDown={() => setSelectedHospitalId(associatedHospital.id)}
      className={selectedHospitalId === associatedHospital.id ? classes.selectedAssociateHospitalItems : classes.associateHospitalItems}
      tabIndex={0}
    >
      {associatedHospital.name}
    </div>
  ));

  return (
    <>
      <span className={classes.settingsHospitalHeader}>{dictionary.ASSOCIATED_HOSPITALS}</span>
      <div className={classes.parentContainer}>
        <div className={classes.container}>
          {isLoading ? <LoadingSpinner /> : hospitalItems}
        </div>
      </div>
    </>
  );
};
