// Needs to be kept in-sync with RequisitionStatuses db table &
// backend status string constants.
export enum RequisitionStatus {
  declined = 'Declined',
  approved = 'Approved',
  waitingForPo = 'Waiting for PO',
  pendingBuyer = 'Pending Buyer Approval',
  pendingFirstApproval = 'Pending First Approval',
  pendingSecondApproval = 'Pending Second Approval',
  pendingThirdApproval = 'Pending Third Approval',
  returned = 'Returned to Vendor',
  approvedWithExceptions = 'Approved with Exceptions',
  completed = 'Completed',
  draft = 'Draft',
  archived = 'Archived'
}
