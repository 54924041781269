import { FC } from 'react';
import { TableInstance, UseSortByColumnProps, HeaderGroup } from 'react-table';
import useUtilityStyles from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import { If } from '../If';
import { SortIcon } from '../SortableTable/SortableIcon';

interface SortableTableHeaderProps {
  tableContext: TableInstance<any>;
}

export const SortableTableHeader: FC<SortableTableHeaderProps> = ({ tableContext }) => {
  const utilClasses = useUtilityStyles();

  return (
    <thead>
      {tableContext.headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(providedCol => {
            const column = providedCol as UseSortByColumnProps<any> & HeaderGroup<any>;
            const headerProps = column.getHeaderProps(column.getSortByToggleProps());
            const sortDir = column.isSortedDesc ? 'desc' : 'asc';
            const sortableColumn = column.canSort;
            const css: React.CSSProperties = {
              width: column.width || 'auto',
            };

            return (
              <th {...headerProps} data-testid="req-index-table-header-cell" style={css}>
                <div className={joinArgs(utilClasses.inlineFlex, utilClasses.alignCenter)}>
                  {column.render('Header')}
                  <span className={utilClasses.ml05}>
                    <If condition={sortableColumn}>
                      <SortIcon direction={column.isSorted ? sortDir : undefined} />
                    </If>
                  </span>
                </div>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};
