import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CapitationRequest } from '../../../Data/Capitation';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { createCapitation } from '../../../Services/capitationService';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { routes } from '../../../Utils/routes';
import { CapitationForm } from '../shared/CapitationForm';

const CapitationFormWrapper: FC<FormFlowStepComponent<CapitationRequest>> = ({ FormActionsComponent, onFormStepComplete }) =>
  <CapitationForm FormActionsComponent={FormActionsComponent} onFormStepComplete={onFormStepComplete} />;

export const CreateCapitation: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <FormFlow<CapitationRequest>
      steps={[
        { pageHeader: dictionary.CAPITATIONS_NEW_HEADER_LABEL, component: CapitationFormWrapper },
      ]}
      completeButtonLabel={dictionary.CAPITATIONS_CREATE_ACTION}
      onCancel={() => {
        history.push(routes.capitations.index);
      }}
      onComplete={async (capitationRequest: CapitationRequest) => {
        try {
          await createCapitation(capitationRequest);
          history.push(routes.capitations.index);
          dispatch(addNotification(NotificationType.success, dictionary.CREATE_CAPITATION_SUCCESS));
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.CREATE_CAPITATION_ERROR));
        }
      }}
    />
  );
};
