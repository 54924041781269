import { convertToHTML } from 'draft-convert';
import { EditorState, Editor, convertFromHTML, ContentState } from 'draft-js';
import { FC, useState, useMemo, useEffect } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { dictionary } from '../../../dictionary';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';

export const TermsOfUseEditorForm: FC<FormFlowStepComponent<string, string>> = (props) => {
  const {
    FormActionsComponent,
    onFormStepComplete,
    editSource: termsOfUseHtml,
  } = props;

  const [termsOfUseEditorState, setTermsOfUseEditorState] = useState<EditorState>(EditorState.createEmpty());
  const termsOfUseHtmlPreview = useMemo(() => convertToHTML(termsOfUseEditorState.getCurrentContent()), [termsOfUseEditorState]);

  useEffect(() => {
    if (!termsOfUseHtml) return;
    const blocksFromHTML = convertFromHTML(termsOfUseHtml);
    const restoredContentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    setTermsOfUseEditorState(EditorState.createWithContent(restoredContentState));
  }, [termsOfUseHtml]);

  const utilClasses = useUtilityStyles();

  const onSubmitHandler: React.FormEventHandler<HTMLFormElement> = (e) => {
    onFormStepComplete(termsOfUseHtmlPreview);
    e.preventDefault();
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Row className={joinArgs(utilClasses.flex, utilClasses.spaceBetween)}>
        <Col xs={12} lg={6}>
          <label htmlFor="terms-input" data-testid="terms-input-label">
            <div className={joinArgs(utilClasses.flex, utilClasses.spaceBetween)}>
              <h2>{dictionary.MANAGE_TERMS_OF_USE_EDITOR_LABEL}</h2>
            </div>
          </label>
          <div className={joinArgs('text-input', utilClasses.hAuto, utilClasses.m0, utilClasses.p0)}>
            <Editor
              editorState={termsOfUseEditorState}
              onChange={setTermsOfUseEditorState}
              placeholder={dictionary.MANAGE_TERMS_PLACEHOLDER_TEXT}
            />
          </div>
          <div className={joinArgs(utilClasses.mt2, utilClasses.flex, utilClasses.flexEnd)}>
            <FormActionsComponent />
          </div>
        </Col>

        <Col xs={12} lg={6}>
          <h2>{dictionary.MANAGE_TERMS_OF_USE_PREVIEW_HEADER}</h2>
          {/* eslint-disable-next-line react/no-danger */}
          <div className={joinArgs(utilClasses.p24, utilClasses.backgroundNearWhite)} dangerouslySetInnerHTML={{ __html: termsOfUseHtmlPreview }} />
        </Col>
      </Row>
    </form>
  );
};
