import { ReactElement, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { productCategoryColumnProperties, ProductCategoryForTable } from './productCategoriesTable.utils';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { routes } from '../../../../Utils/routes';

type ProductCategoriesTableProps = {
  productCategories: ProductCategoryForTable[],
  columnProperties?: ColumnProperty<ProductCategoryForTable>[],
  isLoading: boolean,
};

export const ProductCategoriesTable: FunctionComponent<ProductCategoriesTableProps> = ({
  productCategories,
  columnProperties = productCategoryColumnProperties,
  isLoading,
}): ReactElement => {
  const history = useHistory();
  const handleRowClick = (productCategory: ProductCategoryForTable) => history.push(routes.productCategories.edit(productCategory.id));
  return (
    <section>
      <SortableTable
        initialColumnSort="healthSystemName"
        rowData={productCategories}
        columns={columnProperties}
        handleRowSelect={handleRowClick}
        noDataMessage={dictionary.PRODUCT_CATEGORY_TABLE_EMPTY}
        isLoading={isLoading}
        tableTestId="productCategories-table"
      />
    </section>
  );
};
