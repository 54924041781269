import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

export interface PhysicianHospitalsForTable {
  id: number;
  hospitalId: number;
  physicianIdentifier: string;
  departmentNames: string;
  hospitalName: string;
  canCurrentUserEdit?: boolean;
}

export const physicianColumnProperties: ColumnProperty<PhysicianHospitalsForTable>[] = [
  { displayName: dictionary.PHYSICIAN_FORM_HOSPITAL_ID, name: 'hospitalName' },
  { displayName: dictionary.PHYSICIAN_FORM_DEPARTMENT_ID, name: 'departmentNames' },
  { displayName: dictionary.PHYSICIAN_FORM_PHYSICIAN_ID, name: 'physicianIdentifier' },
];
