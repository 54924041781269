import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CapitationForm } from '../shared/CapitationForm';
import { fetchCapitationById, updateCapitation } from '../../../Services/capitationService';
import Capitation, { CapitationRequest } from '../../../Data/Capitation';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { dictionary } from '../../../dictionary';
import { routes } from '../../../Utils/routes';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../redux/initialState';

const CapitationFormWrapper: FC<FormFlowStepComponent<CapitationRequest, Capitation>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <CapitationForm FormActionsComponent={FormActionsComponent} capitation={editSource} onFormStepComplete={onFormStepComplete} />;

export const EditCapitation: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [capitation, setCapitation] = useState<Capitation>();
  const params = useParams<{ id: string }>();
  const capitationId = Number(params.id!);

  useEffect(() => {
    (async () => {
      const fetchedCapitation = await fetchCapitationById(capitationId);
      setCapitation(fetchedCapitation);
    })();
  }, [capitationId]);

  const onComplete = useMemo(() => async (capitationRequest: CapitationRequest) => {
    try {
      await updateCapitation(capitationRequest);
      history.push(routes.capitations.index);
      dispatch(addNotification(NotificationType.success, dictionary.UPDATE_CAPITATION_SUCCESS));
    } catch (e) {
      dispatch(addNotification(NotificationType.error, dictionary.UPDATE_CAPITATION_ERROR));
    }
  }, [dispatch, history]);

  if (!capitation) return null;

  return (
    <FormFlow<CapitationRequest, Capitation>
      editSource={capitation}
      steps={[
        {
          pageHeader: `${dictionary.CAPITATIONS_EDIT_HEADER_LABEL} ${capitation?.name}`,
          component: CapitationFormWrapper,
        },
      ]}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      onCancel={() => { history.push(routes.capitations.index); }}
      onComplete={onComplete}
    />
  );
};
