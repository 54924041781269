import { FC } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { createUseStyles } from 'react-jss';
import { HospitalSettings } from '../../Data/Settings';
import { FormFlowStepComponent } from '../../Shared/FormFlow/FormFlow';
import { getFormikInitialValues } from '../../Utils/formik.utils';
import { approvalRoutingCustomizationFormFields, ApprovalRoutingCustomizationFormFields } from './settings.utils';
import { Checkbox } from '../../Shared/fields/Checkbox';
import { dictionary } from '../../dictionary';
import pdf from './BillOnlyWorkflowV7.pdf';

interface ApprovalRoutingCustomizationFormProps extends FormFlowStepComponent<HospitalSettings> {
  settings?: HospitalSettings;
}

export const ApprovalRoutingCustomizationFormWrapper: FC<FormFlowStepComponent<HospitalSettings, HospitalSettings>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <ApprovalRoutingCustomizationForm FormActionsComponent={FormActionsComponent} settings={editSource} onFormStepComplete={onFormStepComplete} />;

export const ApprovalRoutingCustomizationForm: FC<ApprovalRoutingCustomizationFormProps> = (props) => {
  const { FormActionsComponent, onFormStepComplete, settings } = props;

  const formikProps = useFormik<ApprovalRoutingCustomizationFormFields>({
    initialValues: getFormikInitialValues<ApprovalRoutingCustomizationFormFields>(approvalRoutingCustomizationFormFields, { ...settings }),
    enableReinitialize: true,
    onSubmit: (formValues) => {
      onFormStepComplete({
        offContractSkipBuyer: formValues.offContractSkipBuyer || false,
        offContractDoNotPermit: formValues.offContractDoNotPermit || false,
        contractPriceChangeSkipBuyer: formValues.contractPriceChangeSkipBuyer || false,
        contractPriceChangeDoNotPermit: formValues.contractPriceChangeDoNotPermit || false,
        newProductsSkipBuyer: formValues.newProductsSkipBuyer || false,
        newProductsDoNotPermit: formValues.newProductsDoNotPermit || false,
        zeroPriceProductsRouteToBuyer: formValues.zeroPriceProductsRouteToBuyer || false,
        missingItemIdRouteToBuyer: formValues.missingItemIdRouteToBuyer || false,
        trialProductsRouteToBuyer: formValues.trialProductsRouteToBuyer || false,
        wastedProductsRouteToBuyer: formValues.wastedProductsRouteToBuyer || false,
        explantedProductsRouteToBuyer: formValues.explantedProductsRouteToBuyer || false,
        hospitalId: settings?.hospitalId,
      });
    },
  });

  // To Do: break this out into a separate file to be shared by other tabs if needed
  const useSettingsStyles = createUseStyles({
    checkboxarea: {
      padding: '1rem',
      '& label': {
        fontWeight: 'normal',
        display: 'flex',
      },
    },
    settingsTabHeader: {
      fontSize: '1.5rem',
      marginBottom: '1rem',
      position: 'relative',
      top: '-1rem',
    },
    staticPdfMessage: {
      marginTop: '5rem',
    },
  });

  const styles = useSettingsStyles();

  return (
    <>
      <form className="input-form" onSubmit={formikProps.handleSubmit}>
        <span className={styles.settingsTabHeader}>{dictionary.REQ_PRODUCT_APPROVAL_PREFERENCES}</span>
        <Row>
          <Col className={styles.checkboxarea}>
            <div className="label"><strong>{dictionary.OFF_CONTRACT_PRODUCTS_LABEL_1}</strong></div>
            <div className="label"><em>{dictionary.OFF_CONTRACT_PRODUCTS_LABEL_2}</em></div>
            <Checkbox
              {...formikProps.getFieldProps('offContractSkipBuyer')}
              label={approvalRoutingCustomizationFormFields.offContractSkipBuyer.label}
              checked={formikProps.getFieldProps('offContractSkipBuyer').value}
              onChange={() => {
                formikProps.setFieldValue('offContractSkipBuyer', !formikProps.getFieldProps('offContractSkipBuyer').value);
                formikProps.setFieldValue('offContractDoNotPermit', false);
              }}
              id="1"
            />
            <Checkbox
              {...formikProps.getFieldProps('offContractDoNotPermit')}
              label={approvalRoutingCustomizationFormFields.offContractDoNotPermit.label}
              checked={formikProps.getFieldProps('offContractDoNotPermit').value}
              onChange={() => {
                formikProps.setFieldValue('offContractDoNotPermit', !formikProps.getFieldProps('offContractDoNotPermit').value);
                formikProps.setFieldValue('offContractSkipBuyer', false);
              }}
              id="2"
            />
          </Col>

          <Col className={styles.checkboxarea}>
            <div className="label"><strong>{dictionary.CONTRACT_PRICE_CHANGES_LABEL}</strong></div>
            <Checkbox
              {...formikProps.getFieldProps('contractPriceChangeSkipBuyer')}
              label={approvalRoutingCustomizationFormFields.contractPriceChangeSkipBuyer.label}
              checked={formikProps.getFieldProps('contractPriceChangeSkipBuyer').value}
              onChange={() => {
                formikProps.setFieldValue('contractPriceChangeSkipBuyer', !formikProps.getFieldProps('contractPriceChangeSkipBuyer').value);
                formikProps.setFieldValue('contractPriceChangeDoNotPermit', false);
              }}
              id="3"
            />
            <Checkbox
              {...formikProps.getFieldProps('contractPriceChangeDoNotPermit')}
              label={approvalRoutingCustomizationFormFields.contractPriceChangeDoNotPermit.label}
              checked={formikProps.getFieldProps('contractPriceChangeDoNotPermit').value}
              onChange={() => {
                formikProps.setFieldValue('contractPriceChangeDoNotPermit', !formikProps.getFieldProps('contractPriceChangeDoNotPermit').value);
                formikProps.setFieldValue('contractPriceChangeSkipBuyer', false);
              }}
              id="4"
            />
          </Col>

          <Col className={styles.checkboxarea}>
            <div className="label"><strong>{dictionary.NEW_PRODUCTS_LABEL}</strong></div>
            <Checkbox
              {...formikProps.getFieldProps('newProductsSkipBuyer')}
              label={approvalRoutingCustomizationFormFields.newProductsSkipBuyer.label}
              checked={formikProps.getFieldProps('newProductsSkipBuyer').value}
              onChange={() => {
                formikProps.setFieldValue('newProductsSkipBuyer', !formikProps.getFieldProps('newProductsSkipBuyer').value);
                formikProps.setFieldValue('newProductsDoNotPermit', false);
              }}
              id="5"
            />
            <Checkbox
              {...formikProps.getFieldProps('newProductsDoNotPermit')}
              label={approvalRoutingCustomizationFormFields.newProductsDoNotPermit.label}
              checked={formikProps.getFieldProps('newProductsDoNotPermit').value}
              onChange={() => {
                formikProps.setFieldValue('newProductsDoNotPermit', !formikProps.getFieldProps('newProductsDoNotPermit').value);
                formikProps.setFieldValue('newProductsSkipBuyer', false);
              }}
              id="6"
            />
          </Col>
        </Row>

        <Row>
          <Col className={styles.checkboxarea}>
            <span className="label"><strong>{dictionary.ROUTE_TO_BUYER}</strong></span>
            <Checkbox
              {...formikProps.getFieldProps('zeroPriceProductsRouteToBuyer')}
              label={approvalRoutingCustomizationFormFields.zeroPriceProductsRouteToBuyer.label}
              checked={formikProps.getFieldProps('zeroPriceProductsRouteToBuyer').value}
              id="7"
            />
            <Checkbox
              {...formikProps.getFieldProps('missingItemIdRouteToBuyer')}
              label={approvalRoutingCustomizationFormFields.missingItemIdRouteToBuyer.label}
              checked={formikProps.getFieldProps('missingItemIdRouteToBuyer').value}
              id="8"
            />
            <Checkbox
              {...formikProps.getFieldProps('trialProductsRouteToBuyer')}
              label={approvalRoutingCustomizationFormFields.trialProductsRouteToBuyer.label}
              checked={formikProps.getFieldProps('trialProductsRouteToBuyer').value}
              id="9"
            />
            <Checkbox
              {...formikProps.getFieldProps('wastedProductsRouteToBuyer')}
              label={approvalRoutingCustomizationFormFields.wastedProductsRouteToBuyer.label}
              checked={formikProps.getFieldProps('wastedProductsRouteToBuyer').value}
              id="10"
            />
            <Checkbox
              {...formikProps.getFieldProps('explantedProductsRouteToBuyer')}
              label={approvalRoutingCustomizationFormFields.explantedProductsRouteToBuyer.label}
              checked={formikProps.getFieldProps('explantedProductsRouteToBuyer').value}
              id="11"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className={styles.staticPdfMessage}><strong><em>{dictionary.STATIC_PDF_MESSAGE}</em></strong></div>
            <span><a href={pdf} download>{dictionary.BILL_ONLY_WORKFLOW_DIAGRAM}</a></span>
            <FormActionsComponent />
          </Col>
        </Row>
      </form>
    </>
  );
};
