import { FC } from 'react';
import { Route } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { ProductSearch } from './ProductSearch/ProductSearch';
import { ProductDetails } from './ProductDetails/ProductDetails';
import { EditProduct } from './EditProduct/EditProduct';

export const ProductsRoutes: FC = () => (
  <>
    <Route exact path={routes.products.index}>
      <ProductSearch />
    </Route>
    <Route exact path={routes.products.detailsTemplate}>
      <ProductDetails />
    </Route>
    <Route exact path={routes.products.editTemplate}>
      <EditProduct />
    </Route>
  </>
);
