import { FC } from 'react';
import { Route } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { ManufacturersIndex } from './ManufacturersIndex/ManufacturersIndex';
import { CreateManufacturer } from './CreateManufacturer/CreateManufacturer';
import { EditManufacturer } from './EditManufacturer/EditManufacturer';

export const ManufacturersRoutes: FC = () => (
  <>
    <Route exact path={routes.manufacturers.index}>
      <ManufacturersIndex />
    </Route>
    <Route exact path={routes.manufacturers.create}>
      <CreateManufacturer />
    </Route>
    <Route exact path={routes.manufacturers.editTemplate}>
      <EditManufacturer />
    </Route>
  </>
);
