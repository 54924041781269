import { AxiosError } from 'axios';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HospMfctIdentifierRequest, HospMfctIdentifierUniquenessError } from '../../../Data/ManufacturerIdentifier';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { hospitalService } from '../../../Services/HospitalService';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { routes } from '../../../Utils/routes';
import { CreateHospMfctIdentifierForm } from './CreateHospMfctIdentifierForm';

export const CreateHospMfctIdentifier: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onCancel = () => history.push(routes.hospitalManufacturerIdentifiers.index);
  const onComplete = async (request: HospMfctIdentifierRequest) => {
    try {
      await hospitalService.createManufacturerIdentifier(request);
      dispatch(addNotification(NotificationType.success, dictionary.CREATE_HOSPITAL_MANUFACTURER_ID_SUCCESS));
      history.push(routes.hospitalManufacturerIdentifiers.index);
    } catch (exception) {
      if ((exception as AxiosError).response?.status === 409) {
        const uniqueErr: HospMfctIdentifierUniquenessError = (exception as AxiosError).response?.data;
        if (uniqueErr.manufacturerNames.length) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_MANUFACTURER_NOT_UNIQUE_ERROR(uniqueErr.manufacturerNames)));
        }
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.CREATE_HOSPITAL_MANUFACTURER_ID_ERROR));
      }
    }
  };

  return (
    <FormFlow<HospMfctIdentifierRequest>
      onComplete={onComplete}
      onCancel={onCancel}
      completeButtonLabel={dictionary.CREATE_HOSPITAL_MANUFACTURER_ID_SUBMIT_BTN}
      steps={[
        { pageHeader: dictionary.CREATE_HOSPITAL_MANUFACTURER_ID_PAGE_HEADER, component: CreateHospMfctIdentifierForm },
      ]}
    />
  );
};
