import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ApiRequisition } from '../../../Data/Requisition';
import { dictionary } from '../../../dictionary';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { If } from '../../../Shared/If';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { routes } from '../../../Utils/routes';
import { ProductsToBill } from '../shared/ProductsToBill/ProductsToBill';

interface ReqDetailsProductInfoProps {
  req: ApiRequisition;
  hospHasProductCategories: boolean;
}

export const ReqDetailsProductInfo: FC<ReqDetailsProductInfoProps> = ({ req, hospHasProductCategories }) => {
  const utilClasses = useUtilityStyles();

  return (
    <>
      <Row className={utilClasses.my1}>
        <Col xs={12}>
          <div className={joinArgs(utilClasses.pb1, utilClasses.horizontalRuleBorder)}>
            <Row>
              <Col xs={6}>
                <h2 className={utilClasses.mb0}>{dictionary.REQ_PRODUCTS_TO_BILL_LABEL}</h2>
              </Col>
              <If condition={req.canCurrentUserEdit}>
                <Col xs={6} className={joinArgs(utilClasses.flex, utilClasses.flexEnd, utilClasses.alignEnd)}>
                  <LinkButton to={routes.requisitions.editProducts(req.id)} leadingIcon={faEdit}>{dictionary.REQ_UPDATE_PRODUCTS_LINK}</LinkButton>
                </Col>
              </If>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <ProductsToBill requisition={req} hospHasProductCategories={hospHasProductCategories} />
        </Col>
      </Row>
    </>
  );
};
