import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { minutesUntilLogoutPrompt } from '../envSettings';
import { actionTypes } from '../redux/actionTypes';
import { logout } from '../Services/LoginService';
import { LogoutModal } from './LogoutModal';
import { useTimer } from './useTimer';

export const AutoLogout: FC = () => {
  const [isLogoutModalOpen, setIsLogoutOutModalOpen] = useState(false);
  const [listenersAddedToDocument, setListenersAddedToDocument] = useState<(() => any)[]>([]);
  const dispatch = useDispatch();
  const exitNow = () => {
    logout();
    dispatch({ type: actionTypes.user.clearUser });
  };

  const stayLoggedIn = () => {
    setIsLogoutOutModalOpen(false);
  };

  const { restartTimer } = useTimer(() => setIsLogoutOutModalOpen(true), minutesUntilLogoutPrompt * 60_000);

  useEffect(() => {
    const allEventTypes = ['mousemove', 'mousedown', 'mouseup', 'keydown', 'keypress', 'keyup', 'touchstart', 'touchend', 'touchmove'];

    if (listenersAddedToDocument.length) {
      allEventTypes.forEach((eventType) => {
        document.removeEventListener(eventType, listenersAddedToDocument[0]);
      });
    }
    allEventTypes.forEach((eventType) => {
      document.addEventListener(eventType, restartTimer);
    });
    setListenersAddedToDocument([restartTimer]);

    return () => {
      allEventTypes.forEach((eventType) => {
        document.removeEventListener(eventType, restartTimer);
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restartTimer]);

  return <LogoutModal isOpen={isLogoutModalOpen} exitNow={exitNow} stayLoggedIn={stayLoggedIn} />;
};
