import { getIn, FormikErrors, FormikTouched, FormikValues } from 'formik';
import { FormFieldDescriptor } from '../Shared/fields/formTypes';
import { PartialBy } from '../Shared/types/conditionalRequirementTypes';

interface MinimumFormikObj<Values> {
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
}

export function getFormikErrorOrEmptyString<V>(formikObj: MinimumFormikObj<V>, lookupKey: keyof V): string {
  if (formikObj.errors === undefined || !formikObj.errors[lookupKey]) return '';
  if (formikObj.touched === undefined || !formikObj.touched[lookupKey]) return '';

  const errObj = formikObj.errors[lookupKey];

  if (errObj === undefined) return '';
  if (Array.isArray(errObj)) return errObj.join('. ');
  return errObj.toString();
}

export const getClassForInput = (fieldId: string, errors: FormikErrors<FormikValues>, touched: FormikTouched<FormikValues>): string => {
  if (getIn(errors, fieldId) && getIn(touched, fieldId)) {
    return 'input-invalid-field';
  }
  return '';
};

export const getClassForLabel = (fieldId: string, errors: FormikErrors<FormikValues>, touched: FormikTouched<FormikValues>): string => {
  if (getIn(errors, fieldId) && getIn(touched, fieldId)) {
    return 'label-invalid-field';
  }
  return '';
};

export const createDescriptor = <T>(descriptor: PartialBy<FormFieldDescriptor<T>, 'inputFormat'>): FormFieldDescriptor<T> => ({ inputFormat: 'text', ...descriptor });
export const getFieldDescriptorMap = <T>(descriptors: FormFieldDescriptor<T>[]): Record<keyof T, FormFieldDescriptor<T>> =>
  descriptors.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {} as Record<keyof T, FormFieldDescriptor<T>>);

// From the descriptors of a form's fields, get their initial values as a Record<key, value> where
// the key is the name of the field from the descriptor.
export const getFormikInitialValues = <TFieldVals>(
  descriptorsMap: Record<Extract<TFieldVals, 'string'>, FormFieldDescriptor<TFieldVals>>, initialVals: Partial<TFieldVals> = {}
): Record<keyof TFieldVals, any> => {
  const descriptors = Object.values(descriptorsMap) as FormFieldDescriptor<TFieldVals>[];
  return descriptors.reduce(
    (acc: Record<keyof TFieldVals, any>, curr) => ({ ...acc, [curr.name]: getInitialValue(curr, initialVals[curr.name]) }),
    {} as Record<keyof TFieldVals, any>
  );
};

const typeInitialValueDefaults: Record<FormFieldDescriptor<any>['inputFormat'], any> = {
  text: '',
  textarea: '',
  'multi-select': [],
  number: 0,
};
const getInitialValue = <T>(input: FormFieldDescriptor<T>, initialVal?: unknown) => {
  if (initialVal) {
    return initialVal;
  }

  return input.defaultVal ?? typeInitialValueDefaults[input.inputFormat || 'text'];
};
