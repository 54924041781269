import { ActionCreator } from 'redux';
import { NotificationAddAction, NotificationRemoveAction } from './notificationsReducer';
import { NotificationType } from '../initialState';
import { getNextId } from '../../Utils/idUtils';

export enum NotificationActionType {
  add = 'notification.add',
  remove = 'notification.remove',
}

type AddNotificationActionCreator = (
  type: NotificationType,
  message: string,
  onComplete?: () => void
) => NotificationAddAction;

export const addNotification: AddNotificationActionCreator = (type, message, onComplete) => ({
  type: NotificationActionType.add,
  notification: {
    type,
    message,
    onComplete,
    id: getNextId(),
  },
});

export const removeNotification: ActionCreator<NotificationRemoveAction> = (id: number) => ({
  type: NotificationActionType.remove,
  id,
});
