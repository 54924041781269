import { FunctionComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { HospitalBase } from '../../../Data/Hospital';
import { dictionary } from '../../../dictionary';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import { getSlashFormattedDate } from '../../../Utils/dateUtils';
import { formatPercentage } from '../../../Utils/formatNumber';

const ContractBaseInfoDisplay: FunctionComponent<any> = ({ contract }) => {
  const getContractHospitals = () => (<div>{contract?.hospitals.map((hospital: HospitalBase) => <div key={hospital.id}>{hospital.name}</div>)}</div>);

  return (
    <>
      <h2>{dictionary.CONTRACT_INFORMATION_SECTION_HEADER}</h2>

      <div>
        <SimpleDisplayField label={dictionary.CONTRACT_ID_LABEL} value={contract.contractIdentifier} />
        <SimpleDisplayField label={dictionary.CONTRACT_HOSPITALS} value={getContractHospitals()} />
        <SimpleDisplayField label={dictionary.MANUFACTURER_NAME_LABEL} value={contract.manufacturer?.name} />
        <SimpleDisplayField label={dictionary.HOSPITAL_MANUFACTURER_ID_NAME_LABEL} value={contract.manufacturerIdentifier} />
        <Row>
          <Col xs={6} sm={4} md={2}><SimpleDisplayField label={dictionary.CONTRACT_EFFECTIVE_DATE_LABEL} value={getSlashFormattedDate(contract.effectiveDate)} /></Col>
          <Col xs={6} sm={4} md={2}><SimpleDisplayField label={dictionary.CONTRACT_EXPIRATION_DATE_LABEL} value={getSlashFormattedDate(contract.expirationDate)} /></Col>
        </Row>
        <Row>
          <Col xs={12} sm={4} md={2}><SimpleDisplayField label={dictionary.CONTRACT_WASTED_DISCOUNT_LABEL} value={formatPercentage(contract.wastedDiscount || 0)} /></Col>
          <Col xs={12} sm={4} md={2}><SimpleDisplayField label={dictionary.CONTRACT_EXPLANT_DISCOUNT_LABEL} value={formatPercentage(contract.explantDiscount || 0)} /></Col>
          <Col xs={12} sm={4} md={2}><SimpleDisplayField label={dictionary.CONTRACT_TRIAL_DISCOUNT_LABEL} value={formatPercentage(contract.trialDiscount || 0)} /></Col>
        </Row>
      </div>
    </>
  );
};

export default ContractBaseInfoDisplay;
