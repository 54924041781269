import { RSAA } from 'redux-api-middleware';
import { Middleware, AnyAction } from 'redux';
import { acquireTokenSilent } from '../Services/LoginService';

const authInjectorMiddleware: Middleware = () => (next) => async (action: AnyAction) => {
  const apiAction = action[RSAA];
  // Check if this action is a redux-api-middleware action.
  if (apiAction) {
    const authResponse = await acquireTokenSilent();

    if (authResponse) {
      apiAction.headers = {
        ...apiAction.headers,
        Authorization: `Bearer ${authResponse.idToken}`,
      };
      return next(action);
    }
    throw new Error('Acquiring Token Failed, browser should be redirecting');
  } else {
    return next(action);
  }
};

export default authInjectorMiddleware;
