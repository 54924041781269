import { useFormik, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { updateRequisition } from '../../../Services/RequisitionService';
import { NotificationType } from '../../../redux/initialState';
import { ApiRequisition } from '../../../Data/Requisition';
import { RequisitionUpdateRequest } from '../../../Data/RequisitionRequest';
import { routes } from '../../../Utils/routes';
import { dictionary } from '../../../dictionary';
import { getIsoFormattedDate, getSlashFormattedDate } from '../../../Utils/dateUtils';
import { validateCasePatientForm } from '../shared/validation/casePatientInfo.validation';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { ReqPatientFormFields } from '../shared/ReqPatientForm';
import { FormFieldDescriptor } from '../../../Shared/fields/formTypes';
import { PermissionTo } from '../../../Utils/permissionChecker';
import { currentUserSelector } from '../../../redux/user/userSelectors';
import { createDescriptor } from '../../../Utils/formik.utils';
import { validateProcedureInfoForm } from '../CreateRequisition/ProcedureInfo/procedureInfo.utils';
import { validateCharacterLimit } from '../../../Utils/validationUtils';

export type EditRequisitionFields = ReqPatientFormFields & Pick<
ApiRequisition,
| 'id'
| 'procedure'
| 'physician'
| 'caseNumber'
| 'caseDate'
| 'costCenter'
| 'subCostCenter'
| 'hospital'
| 'department'
| 'poNumber'
| 'additionalNotes'
>;
export type EditRequisitionFieldErrors = Partial<Record<keyof EditRequisitionFields, string>>;

export const editReqFieldDescriptors: Record<string, FormFieldDescriptor> = {
  caseNumber: createDescriptor({ name: 'caseNumber', label: dictionary.FORM_CONTROL_LABEL_CASE_NUMBER, required: true }),
  caseDate: createDescriptor({ name: 'caseDate', label: dictionary.FORM_CONTROL_LABEL_CASE_DATE, required: true }),
  additionalNotes: createDescriptor({ name: 'additionalNotes', label: 'Additional Notes', inputFormat: 'textarea' }),
};

type UseEditReqOnCompleteHook = (reqId: number) => (fields: EditRequisitionFields) => Promise<void>;
export const useEditRequisitionFormSubmitHandler: UseEditReqOnCompleteHook = (requisitionId) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = async (values: EditRequisitionFields) => {
    if (requisitionId) {
      const reqCostCenterPatchRequest: RequisitionUpdateRequest = {
        id: requisitionId,
        procedureId: values.procedure.id,
        physicianId: values.physician.id,
        patientId: values.patient!.id,
        caseNumber: values.caseNumber ?? null,
        caseDate: getIsoFormattedDate(values.caseDate!),
        costCenterId: values.costCenter?.id ?? null,
        subCostCenterId: values.subCostCenter?.id ?? null,
        poNumber: values.poNumber ?? null,
        additionalNotes: values.additionalNotes ?? null,
      };

      try {
        await updateRequisition(reqCostCenterPatchRequest);

        dispatch(addNotification(NotificationType.success, dictionary.REQ_EDIT_SUCCESS));
        history.push(routes.requisitions.detail(requisitionId));
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.REQ_EDIT_ERROR));
      }
    }
  };

  return handleSubmit;
};

export const getEditReqFormInitialValues = (requisition: ApiRequisition): EditRequisitionFields => ({
  id: requisition.id,
  hospital: requisition.hospital,
  department: requisition.department,
  procedure: requisition.procedure,
  physician: requisition.physician,
  patient: requisition.patient,
  patientsMRN: requisition.patient.mrn,
  caseNumber: requisition.caseNumber,
  caseDate: getSlashFormattedDate(requisition.caseDate),
  costCenter: requisition.costCenter,
  subCostCenter: requisition.subCostCenter,
  poNumber: requisition.poNumber,
  additionalNotes: requisition.additionalNotes,
});

const getEditReqValidateFn = (canUserEditCostCenter: boolean) => (values: EditRequisitionFields) => {
  const errors: EditRequisitionFieldErrors = {
    ...validateCharacterLimit(values, [editReqFieldDescriptors.additionalNotes], 500),
    ...validateProcedureInfoForm(values),
    ...validateCasePatientForm(values),
  };

  if (canUserEditCostCenter && !values.costCenter) {
    errors.costCenter = 'Cost Center is required.';
  }

  return errors;
};

type OnEditReqFormStepComplete = FormFlowStepComponent<EditRequisitionFields>['onFormStepComplete'];
type UseEditReqForm = (initialValues: EditRequisitionFields, onSubmit: OnEditReqFormStepComplete) => FormikProps<EditRequisitionFields>;

export const useEditRequisitionForm: UseEditReqForm = (initialValues, onSubmit) => {
  const user = useSelector(currentUserSelector);
  const currentUserCanUpdateCostCenter = PermissionTo.updateAReqCostCenter.isGrantedTo(user);
  const validate = getEditReqValidateFn(currentUserCanUpdateCostCenter);

  return useFormik<EditRequisitionFields>({
    initialValues,
    initialTouched: {
      caseNumber: true,
      caseDate: true,
    },
    validateOnMount: true,
    validate,
    onSubmit,
  });
};

export const getSubCostCenterPlaceholder = (isACostCenterSelected: boolean, areAnySubCostCentersSelectable: boolean): string => {
  if (!isACostCenterSelected) {
    return dictionary.REQ_EDIT_SELECT_COST_CENTER;
  }

  return areAnySubCostCentersSelectable ? dictionary.REQ_EDIT_TYPE_TO_FILTER : dictionary.REQ_EDIT_NO_SUB_COST_CENTER;
};
