import { FormikValues, FormikErrors } from 'formik';
import { dictionary } from '../../../dictionary';
import { FormFieldDescriptor } from '../../../Shared/fields/formTypes';
import { createDescriptor } from '../../../Utils/formik.utils';
import { validateCharacterLimit, validateRequiredFields } from '../../../Utils/validationUtils';

export interface CreateProcedureFormFields {
  name: string;
  hospital: string;
  department: string;
}

export const createProcedureFormFields: Record<keyof CreateProcedureFormFields, FormFieldDescriptor> = {
  name: createDescriptor({ name: 'name', label: dictionary.CREATE_EDIT_PROCEDURE_NAME, required: true }),
  hospital: createDescriptor({ name: 'hospital', label: dictionary.FORM_CONTROL_LABEL_HOSPITAL, required: true }),
  department: createDescriptor({ name: 'department', label: dictionary.FORM_CONTROL_LABEL_DEPARTMENT, required: true }),
};

export const validateCreateProcedureForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { name } = createProcedureFormFields;

  return {
    ...validateCharacterLimit(values, [name], 255),
    ...validateRequiredFields(values, Object.values(createProcedureFormFields)),
  };
};
