import { useEffect, useState, FC } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EditRequisitionFields } from './editRequisition.utils';
import { EditRequisitionForm } from './EditRequisitionForm';
import { costCenterService } from '../../../Services/CostCenterService';
import CostCenter from '../../../Data/CostCenter';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { ApiRequisition } from '../../../Data/Requisition';
import { fetchRequisitionById } from '../../../Services/RequisitionService';
import { dictionary } from '../../../dictionary';
import { ProcedureBase } from '../../../Data/Procedure';
import { PhysicianBase } from '../../../Data/Physician';
import { If } from '../../../Shared/If';
import { RequisitionStatus } from '../../../Data/RequisitionStatus';
import { Callout } from '../../../Shared/Callout/Callout';
import useUtilityStyles from '../../../Themes/utility.styles';
import { getLatestReturnReason } from '../shared/requisition.utils';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { PermissionTo } from '../../../Utils/permissionChecker';
import { currentUserSelector } from '../../../redux/user/userSelectors';
import { hospitalService } from '../../../Services/HospitalService';
import { departmentService } from '../../../Services/DepartmentService';

export const EditRequisitionFormWrapper: FC<FormFlowStepComponent<EditRequisitionFields>> = ({ onFormStepComplete, FormActionsComponent }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector);
  const [procedures, setProcedures] = useState<ProcedureBase[]>([]);
  const [physicians, setPhysicians] = useState<PhysicianBase[]>([]);
  const [requisition, setRequisition] = useState<ApiRequisition>();
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);

  useEffect(() => {
    const fetchReq = async (): Promise<void> => {
      try {
        const req = await fetchRequisitionById(parseInt(id, 10));
        const reqHospital = await hospitalService.getHospitalById(req.hospital.id);
        setRequisition(req);
        const currentDepartment = reqHospital.departments.find(d => d.id === req?.department?.id);
        const reqPhysicians = await departmentService.getPhysiciansByDepartmentId(currentDepartment!.id);
        const reqProcedures = await departmentService.getProceduresByDepartmentId(currentDepartment!.id);
        setPhysicians(reqPhysicians ?? []);
        setProcedures(reqProcedures ?? []);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
      }
    };
    fetchReq();
  }, [dispatch, id]);

  useEffect(() => {
    const currentUserCanUpdateCostCenter = PermissionTo.updateAReqCostCenter.isGrantedTo(user);
    const fetchCostCenters = async (departmentId: number): Promise<void> => {
      const fetchedCostCenters = await costCenterService.getByDepartment(departmentId);
      setCostCenters(fetchedCostCenters);
    };
    if (requisition?.department?.id && currentUserCanUpdateCostCenter) {
      fetchCostCenters(requisition?.department?.id);
    }
  }, [requisition, user]);

  const utilityClasses = useUtilityStyles();

  if (!requisition) {
    return <p>{ dictionary.REQ_DETAILS_LOADING_REQ }</p>;
  }

  if (!requisition.canCurrentUserEdit) {
    return <p>{ dictionary.REQ_EDIT_NO_PERMISSIONS }</p>;
  }

  const latestReturnReason = getLatestReturnReason(requisition);

  return (
    <>
      <If condition={!!latestReturnReason && requisition.status === RequisitionStatus.returned}>
        <Callout title={dictionary.REQ_RETURN_REASON} titleClass={utilityClasses.backgroundCantaloupe}>
          {latestReturnReason}
        </Callout>
      </If>

      <EditRequisitionForm
        requisition={requisition}
        procedures={procedures}
        physicians={physicians}
        costCenters={costCenters}
        FormActionsComponent={FormActionsComponent}
        onFormStepComplete={onFormStepComplete}
      />
    </>
  );
};
