import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Contract } from '../../../Data/Contract';
import { dictionary } from '../../../dictionary';
import { ReduxState } from '../../../redux/initialState';
import { Divider } from '../../../Shared/Divider/Divider';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { If } from '../../../Shared/If';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { useProductEditCounts } from '../CreateContract/ProductPricingInfo/useProductUploadCount';
import ContractBaseInfoDisplay from '../shared/ContractBaseInfoDisplay';
import { ProductsOnContract } from '../shared/ProductsOnContract';

export const ReviewAndUpdateProducts: FC<FormFlowStepComponent<Contract>> = ({ FormActionsComponent, onFormStepComplete }) => {
  const { errors: productErrorCount } = useProductEditCounts();
  const utilClasses = useUtilityStyles();
  const { editContract } = useSelector((state: ReduxState) => state.contract);

  const onSubmitAction = () => onFormStepComplete(editContract);

  return (
    <>
      <If condition={!!editContract.hospitals?.length}>
        <ContractBaseInfoDisplay contract={editContract} />
      </If>

      <section className={joinArgs(utilClasses.py1)}>
        <div className="details-label">
          <div role="alert" className={joinArgs(utilClasses.fs18, utilClasses.fwBold, utilClasses.pt1, utilClasses.textRed)}>
            {productErrorCount ? <p>{`${productErrorCount} ${dictionary.CONTRACT_UPDATE_EXCEPTION_MESSAGE}`}</p> : ''}
          </div>
        </div>
      </section>

      <FormActionsComponent onSubmitAction={onSubmitAction} />

      <Divider />

      <ProductsOnContract products={editContract.products?.filter(p => p.deleteProduct?.toLowerCase() !== 'y')} />
    </>
  );
};
