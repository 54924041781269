import { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { Notification as NotificationProps, NotificationType } from '../../redux/initialState';
import { allNotifications } from '../../redux/notifications/notificationsSelectors';
import { removeNotification } from '../../redux/notifications/notificationsActionCreator';
import { joinArgs } from '../../Utils/arrayUtils';
import useUtilityClasses, { useUtilityStyles } from '../../Themes/utility.styles';
import useNotificationType from './useNotificationType';
import useNotificationStyles from './notifications.styles';
import { dictionary } from '../../dictionary';

export const Notification: FC<NotificationProps> = ({ type, message, id, onComplete }: NotificationProps): ReactElement => {
  const utilityClasses = useUtilityClasses();
  const dispatch = useDispatch();
  const [notificationClasses, notificationIcon] = useNotificationType(type);
  const history = useHistory();

  const onDismiss = () => {
    dispatch(removeNotification(id));
    if (onComplete) {
      onComplete();
    }
  };

  useEffect(() => {
    if (typeof history?.listen === 'function') {
      const unlisten = history.listen(() => {
        if (type === NotificationType.success) {
          dispatch(removeNotification(id));
          if (onComplete) {
            onComplete();
          }
        }
      });
      return unlisten;
    }
    return () => {};
  }, [dispatch, history, type, id, onComplete]);

  return (
    <div role="alertdialog" className={joinArgs(notificationClasses, utilityClasses.flex, utilityClasses.alignCenter)}>
      <FontAwesomeIcon icon={notificationIcon} />

      <p className={joinArgs(utilityClasses.flex1, utilityClasses.mx1, utilityClasses.m0)}>{message}</p>

      <button
        type="button"
        aria-label="close"
        onClick={onDismiss}
        className={joinArgs(utilityClasses.transparentButton, utilityClasses.textTrueWhite, utilityClasses.underline)}
      >
        {dictionary.DISMISS}
      </button>
    </div>
  );
};

export const NotificationCenter: FC = (): ReactElement => {
  const notifications = useSelector(allNotifications);
  const classes = useNotificationStyles();
  const utilClasses = useUtilityStyles();

  return (
    <section className={classes.root}>
      <div className={joinArgs(classes.container, utilClasses.maxAppWidth)}>
        {notifications.map(notificationItem => <Notification key={notificationItem.id} {...notificationItem} />)}
      </div>
    </section>
  );
};
