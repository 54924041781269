import { FC } from 'react';
import { CellProps, Row, UseRowSelectRowProps } from 'react-table';
import { Checkbox } from '../fields/Checkbox';

export const CheckboxCell: FC<CellProps<any>> = ({ row }) => {
  const selectableRow = row as Row<any> & UseRowSelectRowProps<any>;
  const { indeterminate, ...rest } = selectableRow.getToggleRowSelectedProps();

  return <Checkbox {...rest} id={selectableRow.id} data-testid={`product[${selectableRow.index}]`} />;
};
