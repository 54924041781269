import { ReactElement, useEffect, useState } from 'react';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../../dictionary';
import { ManufacturersTable } from './ManufacturersTable/ManufacturersTable';
import { ErrorPage } from '../../../Shared/ErrorPage';
import Manufacturer from '../../../Data/Manufacturer';
import { manufacturerService } from '../../../Services/ManufacturerService';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { routes } from '../../../Utils/routes';

export function ManufacturersIndex(): ReactElement {
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadingError, setHasLoadingError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const fetchedManufacturers = await manufacturerService.getAll();
        if (fetchedManufacturers) {
          setManufacturers(fetchedManufacturers);
          setIsLoading(false);
        }
      } catch (err) {
        setHasLoadingError(true);
      }
    })();
  }, []);

  if (hasLoadingError) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader title={dictionary.MANUFACTURERS_LIST_HEADER_LABEL}>
        <LinkButton to={routes.manufacturers.create}>{dictionary.MANUFACTURERS_CREATE_NEW_ACTION}</LinkButton>
      </PageHeader>

      <ManufacturersTable
        manufacturers={manufacturers ?? []}
        isLoading={isLoading}
      />
    </>
  );
}
