import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HospitalManufacturerContractCapitation } from '../../../../Data/Capitation';
import { MergedRequisition } from '../../../../Data/Requisition';
import { actionTypes } from '../../../../redux/actionTypes';
import { ReduxState } from '../../../../redux/initialState';
import { applyCapitationAction } from '../../../../redux/requisition/requisitionActions';
import { FormFlowStepComponent } from '../../../../Shared/FormFlow/FormFlow';
import { ProductInfoForm } from './ProductInfoForm/ProductInfoForm';

export const ProductInfo: FC<FormFlowStepComponent<MergedRequisition>> = ({ FormActionsComponent, onFormStepComplete, onFormStepBack, onFormSaveDraft }) => {
  const editRequisition = useSelector((state: ReduxState) => state.requisition.editRequisition);
  const dispatch = useDispatch();
  const updateRequisition = (req: MergedRequisition) => dispatch({ type: actionTypes.requisition.updateNew, requisition: req });
  const applyCapitation = (capitation: HospitalManufacturerContractCapitation, productIndices: number[]) => dispatch(applyCapitationAction(capitation, productIndices));

  return (
    <ProductInfoForm
      editRequisition={editRequisition}
      dispatchNewReqStateUpdate={updateRequisition}
      FormActionsComponent={FormActionsComponent}
      onFormStepComplete={onFormStepComplete}
      onFormStepBack={onFormStepBack}
      onFormSaveDraft={onFormSaveDraft}
      applyCapitation={applyCapitation}
    />
  );
};
