import { FC } from 'react';
import { dictionary } from '../../../../dictionary';
import { routes } from '../../../../Utils/routes';
import { createHospitalUser } from '../../../../Services/UserService';
import { CreateOrEditUser } from '../../shared/CreateOrEditUser/CreateOrEditUser';
import { CreateHospitalUserRequest } from '../../../../Data/User';

export const CreateHospitalUser: FC = () => (
  <CreateOrEditUser<CreateHospitalUserRequest>
    pageHeader={dictionary.CREATE_HOSPITAL_USER_ACCOUNT}
    completeButtonLabel={dictionary.CREATE_NEW_USER_BUTTON_LABEL}
    onDoneRoutePath={routes.users.hospitals.index}
    submitRequest={createHospitalUser}
    isHospitalUser
  />
);
