import { HospMfctIdentifierStub } from '../../../../Data/ManufacturerIdentifier';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

export const hospMfctIdentifiersIndexColumnProperties: ColumnProperty<HospMfctIdentifierStub>[] = [
  { displayName: dictionary.HOSPITAL_NAME_LABEL, name: 'hospitalName' },
  { displayName: dictionary.MANUFACTURER_NAME_LABEL, name: 'manufacturerName' },
  { displayName: dictionary.HOSPITAL_MANUFACTURER_ID_NAME_LABEL, name: 'identifier' },
  { displayName: dictionary.HOSPITAL_MANUFACTURER_ID_SITE_NAME_LABEL, name: 'siteName' },
  { displayName: dictionary.HOSPITAL_MANUFACTURER_ID_SITE_ID_LABEL, name: 'siteIdentifier' },
];
