import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import { dashboardLinkStyles } from '../../Themes/dashboardLink.styles';

interface ReportsLinkProps {
  icon: FontAwesomeIconProps['icon'];
  toPath: string;
  label: string;
  'data-testid': string;
}

export const ReportsLink: FC<ReportsLinkProps> = (props: ReportsLinkProps) => {
  const { icon, toPath, label, 'data-testid': testid } = props;
  const styles = dashboardLinkStyles();

  return (
    <Col xs={6} md={4} lg={3} xl={2}>
      <Link to={toPath} className={styles.container} data-testid={testid}>
        <div className={styles.icon}>
          <FontAwesomeIcon size="4x" icon={icon} />
        </div>
        <div className={styles.label}>{label}</div>
      </Link>
    </Col>
  );
};
