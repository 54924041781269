import { Column } from 'react-table';
import { ContractProduct } from '../../../../Data/Contract';
import { dictionary } from '../../../../dictionary';
import { getTruncatedCellComponent } from '../../../../Shared/tables/TruncatedCell';
import { toCurrency } from '../../../../Utils/formatNumber';
import { getNextId } from '../../../../Utils/idUtils';

export type ContractProductForTable = {
  id: number;
  hospitalProductIds: number[];
  cleanCatalogNumber: string;
  catalogNumber: string;
  description: string;
  unitOfMeasure: string | number;
  quantityUnitOfMeasure: string | number;
  orderIdentifier: string | number;
  productCategoryIdentifier: string | number;
  contractPrice: string | number;
};

export const getContractProductRows = (products: ContractProduct[]): ContractProductForTable[] =>
  products.map((product) => ({
    id: getNextId(),
    hospitalProductIds: products.filter(p => p.catalogNumber === product.catalogNumber).map(p => p.hospitalProductId),
    cleanCatalogNumber: product.cleanCatalogNumber,
    catalogNumber: product.catalogNumber,
    description: product.description,
    unitOfMeasure: product.unitOfMeasure,
    quantityUnitOfMeasure: product.quantityUnitOfMeasure,
    orderIdentifier: product.orderIdentifier,
    productCategoryIdentifier: product.productCategoryIdentifier || '',
    contractPrice: toCurrency(product.price),
  })).filter((product, index, self) =>
    self.findIndex(p => p.catalogNumber === product.catalogNumber) === index);

export const productsOnContractColumnProperties: Column<ContractProductForTable>[] = [
  {
    Header: dictionary.REQ_PRODUCT_CATALOG_NUM,
    accessor: 'catalogNumber',
    Cell: getTruncatedCellComponent(50),
  },
  {
    Header: dictionary.REQ_PRODUCT_DESCRIPTION,
    accessor: 'description',
    Cell: getTruncatedCellComponent(50),
  },
  {
    Header: dictionary.REQ_PRODUCT_UOM,
    accessor: 'unitOfMeasure',
    Cell: getTruncatedCellComponent(50),
  },
  {
    Header: dictionary.REQ_PRODUCT_QUANTITY_UOM,
    accessor: 'quantityUnitOfMeasure',
    Cell: getTruncatedCellComponent(50),
  },
  {
    Header: dictionary.REQ_PRODUCT_ITEM_ID,
    accessor: 'orderIdentifier',
    Cell: getTruncatedCellComponent(50),
  },
  {
    Header: dictionary.PRODUCT_CATEGORY_IDENTIFIER_NAME_LABEL,
    accessor: 'productCategoryIdentifier',
    Cell: getTruncatedCellComponent(50),
  },
  {
    Header: dictionary.REQ_PRODUCT_CONTRACT_PRICE,
    accessor: 'contractPrice',
    Cell: getTruncatedCellComponent(50),
    className: 'cellAlignRight',
  },
];
