import { createUseStyles } from 'react-jss';
import Colors from '../../Themes/colors';

export default createUseStyles({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: Colors.trueWhite,
    padding: '0.75rem',
  },
  telLink: {
    color: Colors.trueWhite,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});
