import apiClient from './apiClient';
import { CreateProcedureRequest, UpdateProcedureRequest, GetProceduresResponse, ProcedureStub } from '../Data/Procedure';

export const proceduresUrl = '/procedures';
export const procedureByIdUrl = (procedureId: number): string => `${proceduresUrl}/${procedureId}`;
export const createProcedureUrl = (departmentId: number): string => `/departments/${departmentId}/procedures`;

const createProcedure = async (req: CreateProcedureRequest): Promise<{ id: number }> =>
  apiClient.post({ url: createProcedureUrl(req.departmentId), data: req });

const getProcedures = async (): Promise<GetProceduresResponse> =>
  apiClient.get({ url: proceduresUrl });

const getProcedureById = async (id: number): Promise<ProcedureStub> =>
  apiClient.get({ url: procedureByIdUrl(id) });

const updateProcedure = async (id: number, req: UpdateProcedureRequest): Promise<void> =>
  apiClient.put({ url: procedureByIdUrl(id), data: req });

export const procedureService = {
  create: createProcedure,
  getProcedures,
  getProcedureById,
  update: updateProcedure,
};
