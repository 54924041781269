import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApprovalRouting } from '../../../Data/ApprovalRouting';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { editApprovalRouting, fetchApprovalRoutingById } from '../../../Services/ApprovalRoutingService';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { routes } from '../../../Utils/routes';
import { ApprovalRoutingFormFields } from '../shared/ApprovalRoutingForm.types';
import { convertApprovalRoutingFormFieldsToApprovalRoutingRequest } from '../shared/approvalRoutingForm.utils';
import { ApprovalRoutingFormWrapper } from '../shared/ApprovalRoutingFormWrapper';

export const EditApprovalRouting: FC = () => {
  const [approvalRouting, setApprovalRouting] = useState<ApprovalRouting>();
  const params: any = useParams();
  const approvalRoutingId = params.id!;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const fetchedApprovalRouting = await fetchApprovalRoutingById(approvalRoutingId);
      setApprovalRouting(fetchedApprovalRouting);
    })();
  }, [approvalRoutingId]);

  if (!approvalRouting) return null;

  const onCancel = () => history.push(routes.approvalRoutings.index);
  const onComplete = async (values: ApprovalRoutingFormFields) => {
    const updateRequest = convertApprovalRoutingFormFieldsToApprovalRoutingRequest(values);
    try {
      await editApprovalRouting(approvalRouting!.id, updateRequest);
      history.push(routes.approvalRoutings.index);
    } catch (exception) {
      dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
    }
  };

  return (
    <FormFlow<ApprovalRoutingFormFields, ApprovalRouting>
      editSource={approvalRouting}
      steps={[
        { pageHeader: dictionary.APPROVAL_ROUTES_EDIT_HEADER_LABEL, component: ApprovalRoutingFormWrapper },
      ]}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      completeButtonDisabled={!approvalRouting.canCurrentUserEdit}
      isViewOnly={!approvalRouting.canCurrentUserEdit}
      onCancel={onCancel}
      onComplete={onComplete}
    />
  );
};
