import { useHistory } from 'react-router-dom';
import { ReactElement, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { procedureColumnProperties, ManufacturerForTable } from './manufacturersTable.utils';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { currentUserRoleSelector } from '../../../../redux/user/userSelectors';
import { userRoles } from '../../../../Utils/userRoles';
import { ManufacturerBase } from '../../../../Data/Manufacturer';
import { routes } from '../../../../Utils/routes';

type ManufacturersTableProps = {
  manufacturers: ManufacturerForTable[],
  columnProperties?: ColumnProperty<ManufacturerForTable>[],
  isLoading: boolean,
};

export const ManufacturersTable: FunctionComponent<ManufacturersTableProps> = ({
  manufacturers,
  columnProperties = procedureColumnProperties,
  isLoading,
}): ReactElement => {
  const history = useHistory();
  const currentUserRole = useSelector(currentUserRoleSelector);
  const isOmiSupport = currentUserRole === userRoles.omiAdmin;
  const handleRowClick = (manufacturer: ManufacturerBase) => history.push(routes.manufacturers.edit(manufacturer.id));

  return (
    <section>
      <SortableTable
        initialColumnSort="name"
        rowData={manufacturers}
        columns={columnProperties}
        handleRowSelect={isOmiSupport ? handleRowClick : undefined}
        noDataMessage={dictionary.MANUFACTURER_TABLE_EMPTY}
        isLoading={isLoading}
        tableTestId="manufacturers-table"
      />
    </section>
  );
};
