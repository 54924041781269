import { FormikValues, FormikErrors } from 'formik';
import { CostCenterStub } from '../../../Data/CostCenter';
import { SubCostCenterStub } from '../../../Data/SubCostCenter';
import { dictionary } from '../../../dictionary';
import { createDescriptor, getFieldDescriptorMap, getFormikInitialValues } from '../../../Utils/formik.utils';
import { validateCharacterLimit, validateRequiredFields } from '../../../Utils/validationUtils';

export interface CostCenterFormFields {
  hospital?: string;
  department?: string;
  name: string;
  hospitalCostCenterIdentifier: string;
}

export interface SubCostCenterFormFields {
  name: string;
  hospitalSubCostCenterIdentifier: string;
}

export const costCenterFormFields = getFieldDescriptorMap<CostCenterFormFields>([
  createDescriptor({ name: 'hospital', label: dictionary.FORM_CONTROL_LABEL_HOSPITAL, required: true }),
  createDescriptor({ name: 'department', label: dictionary.FORM_CONTROL_LABEL_DEPARTMENT, required: true }),
  createDescriptor({ name: 'name', label: dictionary.COST_CENTER_FORM_NAME, required: true }),
  createDescriptor({ name: 'hospitalCostCenterIdentifier', label: dictionary.COST_CENTER_FORM_IDENTIFIER, required: true }),
]);

export const subCostCenterFormFields = getFieldDescriptorMap<SubCostCenterFormFields>([
  createDescriptor({ name: 'name', label: dictionary.SUB_COST_CENTER_FORM_NAME, required: true }),
  createDescriptor({ name: 'hospitalSubCostCenterIdentifier', label: dictionary.SUB_COST_CENTER_FORM_IDENTIFIER, required: true }),
]);

export const getCostCenterFormInitialVals = (costCenter?: CostCenterStub): CostCenterFormFields =>
  getFormikInitialValues<CostCenterFormFields>(
    costCenterFormFields,
    {
      hospital: costCenter?.hospitalName,
      department: costCenter?.departmentName,
      name: costCenter?.name,
      hospitalCostCenterIdentifier: costCenter?.hospitalCostCenterIdentifier,
    }
  );

export const validateCostCenterForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { name, hospitalCostCenterIdentifier } = costCenterFormFields;

  return {
    ...validateCharacterLimit(values, [name, hospitalCostCenterIdentifier], 50),
    ...validateRequiredFields(values, Object.values(costCenterFormFields)),
  };
};

export const validateSubCostCenterForm = (addedSubCostCenters: SubCostCenterStub[]) =>
  async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
    const { name, hospitalSubCostCenterIdentifier } = subCostCenterFormFields;

    return {
      ...validateNoDuplicateSubCostCenters(values, addedSubCostCenters),
      ...validateCharacterLimit(values, [name, hospitalSubCostCenterIdentifier], 50),
      ...validateRequiredFields(values, Object.values(subCostCenterFormFields)),
    };
  };

const validateNoDuplicateSubCostCenters = (values: FormikValues, addedSubCostCenters: SubCostCenterStub[]): FormikErrors<FormikValues> => {
  const errors: FormikErrors<FormikValues> = {};
  if (addedSubCostCenters.map((scc) => scc.name.toLowerCase()).includes(values.name.toLowerCase())) {
    errors.name = dictionary.COST_CENTER_FORM_DUPLICATE_SUB_COST_CENTER_NAME_ERROR;
  }
  if (addedSubCostCenters.map((scc) => scc.hospitalSubCostCenterIdentifier.toLowerCase()).includes(values.hospitalSubCostCenterIdentifier.toLowerCase())) {
    errors.hospitalSubCostCenterIdentifier = dictionary.COST_CENTER_FORM_DUPLICATE_SUB_COST_CENTER_IDENTIFIER_ERROR;
  }
  return errors;
};
