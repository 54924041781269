export const toCurrency = (number?: number | null): string => {
  if (number === null || number === undefined) {
    return '';
  }
  const formatter = new Intl.NumberFormat('en-US',
    {
      style: 'currency',
      currency: 'USD',
    });

  return formatter.format(number);
};

export const calculatePrice = (price: (number | undefined), quantity?: number, discount?: number): number => {
  if (!price || !quantity) {
    return 0;
  }

  if (Number.isNaN(price)) {
    return 0;
  }

  const calcDiscount = discount ?? 0.00;

  return price * quantity * (1 - (calcDiscount / 100));
};

export const displayPrice = (price: (number | undefined), quantity?: number, discount?: number): string => toCurrency(calculatePrice(price, quantity, discount));

export const formatPercentage = (inputValue: number): string => `${inputValue.toFixed(2)}%`;

export const formatPercentageVariable = (inputValue: number): string => {
  const displayValue = inputValue % 1 === 0 ? inputValue.toFixed(0) : inputValue.toFixed(2);
  return `${displayValue}%`;
};
