import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import { dictionary } from '../../../../dictionary';
import { csvHeadersExceptions, csvHeadersExceptionsWithDelete } from '../../../../Utils/contracts/contractUtils';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { useButtonStyles } from '../../../../Themes/button.styles';
import { useUtilityStyles } from '../../../../Themes/utility.styles';

export const ExceptionDownloadButton: FC<{lines: string[], isEdit?: boolean }> = ({ lines, isEdit }) => {
  const buttonClasses = useButtonStyles();
  const utilClasses = useUtilityStyles();

  return (
    <div className={joinArgs(utilClasses.mt1)}>
      <CSVLink
        data={lines.join('\n')}
        headers={isEdit ? csvHeadersExceptionsWithDelete : csvHeadersExceptions}
        filename="exception-products.csv"
        className={joinArgs(buttonClasses.base, buttonClasses.leadingIcon, buttonClasses.reverse)}
      >
        <FontAwesomeIcon icon={faFileDownload} />
        {dictionary.CONTRACT_EXCEPTIONAL_PRODUCTS_DOWNLOAD}
      </CSVLink>
    </div>
  );
};
