import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldInputProps, FormikProps } from 'formik';
import { FC, Ref, useRef } from 'react';
import { dictionary } from '../../dictionary';
import { useButtonStyles } from '../../Themes/button.styles';
import useUtilityStyles from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import { Button } from '../buttons/Button';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

interface SearchFieldProps extends Omit<React.HTMLProps<HTMLInputElement>, 'form'> {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  labelText: string;
  onSearch: (searchString: string) => void;
  placeholderText?: string;
  hintText?: string;
  disabled?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  processing?: boolean;
}

export const SearchField: FC<SearchFieldProps> = (props) => {
  const {
    field,
    form,
    labelText,
    placeholderText = dictionary.SEARCH_FIELD_INPUT_PLACEHOLDER_DEFAULT,
    onSearch,
    hintText,
    disabled = false,
    inputRef,
    processing,
    ...inputProps
  } = props;

  const utilClasses = useUtilityStyles();
  const buttonClasses = useButtonStyles();
  const defaultRef = useRef<HTMLInputElement>(null);
  const searchInputRef = inputRef || defaultRef;

  const fieldError = form.errors[field.name];
  const isErrorState = fieldError && form.touched[field.name];

  const labelClassName = isErrorState ? 'label-invalid-field' : '';
  const fieldClassName = isErrorState ? 'input-invalid-field' : '';

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearch(field.value);
    }
  };

  const buttonIconContent = processing
    ? <LoadingSpinner />
    : <FontAwesomeIcon icon={faSearch} size="1x" title={dictionary.REQ_CASE_PATIENT_SEARCH_BUTTON} />;

  return (
    <label
      htmlFor={field.name}
      key={field.name}
      className={joinArgs(labelClassName, utilClasses.flexColumn)}
      data-testid={`${field.name}-label`}
    >
      <div className={joinArgs(utilClasses.flex, utilClasses.spaceBetween)}>
        <div>{labelText}</div>

        {isErrorState && (
          <div role="alert" className="validation-errors" data-testid={`${field.name}-input-error`}>
            {fieldError}
          </div>
        )}
      </div>

      <div className={joinArgs(utilClasses.flex, utilClasses.positionRelative)}>
        <input
          type="text"
          {...field}
          data-testid={`${field.name}-input`}
          className={joinArgs('text-input', fieldClassName, utilClasses.w100)}
          id={field.name}
          placeholder={placeholderText}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(event)}
          disabled={disabled}
          ref={searchInputRef}
          {...inputProps}
        />

        <Button
          data-testid={`${field.name}-search-button`}
          type="button"
          onClick={() => onSearch(field.value)}
          className={buttonClasses.insetRight}
          leadingIcon={buttonIconContent}
          disabled={disabled}
        >
          {dictionary.REQ_CASE_PATIENT_SEARCH_BUTTON}
        </Button>
      </div>
      {!hintText ? null : <div className={joinArgs(utilClasses.textGray, utilClasses.fs12, utilClasses.fwLight)}>{hintText}</div>}
    </label>
  );
};
