import { TermsOfUseResponse } from '../Data/TermsOfUse';
import apiClient from './apiClient';

export const metaInfoUrl = '/meta';
export const termsOfUseUrl = `${metaInfoUrl}/termsOfUse`;

const getTermsOfUse = async (): Promise<TermsOfUseResponse> => apiClient.get<TermsOfUseResponse>({ url: termsOfUseUrl });

const updateTermsOfUse = async (html: string): Promise<{ id: number }> => apiClient.post({
  url: termsOfUseUrl,
  data: {
    html,
  },
});

export const ipmMetaInfoService = {
  getTermsOfUse,
  updateTermsOfUse,
};
