import { FC, useState, PropsWithChildren, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { FormStepper } from '../FormStepper';
import { PageHeader, PageHeaderProps } from '../PageHeader/PageHeader';
import { FormActions } from '../FormActions';
import { dictionary } from '../../dictionary';
import LoadState from '../../redux/loadState';
import { If } from '../If';
import { useIsMounted } from '../useIsMounted';
import { addNotification } from '../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../redux/initialState';

export interface FormActionsComponentProps {
  onSubmitAction?: () => void;
  onBackAction?: () => void;
  onSaveDraftAction?: () => void;
  onClickArchivedBackButton?: () => void;
}

export interface FormFlowStepComponent<TOutput, TInput = never> {
  onFormStepComplete: (data: Partial<TOutput>) => void;
  onFormStepBack?: () => void;
  onFormSaveDraft?: (data: Partial<TOutput>) => void;
  FormActionsComponent: FC<FormActionsComponentProps>;
  editSource?: TInput;
}

export interface FormFlowStep<TOutput, TInput = never> {
  pageHeader?: string;
  PageHeaderComponent?: FC<PageHeaderProps>;
  stepTitle?: string;
  component: FC<FormFlowStepComponent<TOutput, TInput>>;
}

export interface FormFlowProps<TOutput, TInput = never> {
  steps: FormFlowStep<TOutput, TInput>[];
  completeButtonLabel: string;
  completeButtonDisabled?: boolean;
  onCancel: () => void;
  onComplete?: ((data: TOutput) => void) | ((data: TOutput) => Promise<void>);
  onSaveDraft?: ((data: TOutput) => void) | ((data: TOutput) => Promise<void>);
  editSource?: TInput;
  isViewOnly?: boolean;
  updateData?: () => void;
}

type FormFlowOutput = ReactElement<any, any> | null;
export const FormFlow = <TOutput extends any, TInput = never>(props: PropsWithChildren<FormFlowProps<TOutput, TInput>>): FormFlowOutput => {
  const { steps, onComplete, onSaveDraft, completeButtonLabel, completeButtonDisabled, onCancel, editSource, isViewOnly } = props;
  const [stepIndex, setStepIndex] = useState(0);
  const [submitState, setSubmitState] = useState<LoadState>(LoadState.loaded);
  const [saveDraftState, setSaveDraftState] = useState<LoadState>(LoadState.loaded);
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const {
    pageHeader = '',
    stepTitle,
    component: FormComponent,
    PageHeaderComponent = PageHeader,
  } = steps[stepIndex];
  const onLastStep = stepIndex === steps.length - 1;
  const onFirstStep = stepIndex === 0;
  const submitButtonLabel = onLastStep ? completeButtonLabel : dictionary.PROCEED_ACTION;

  const onFormStepComplete = async (dataUpdate: Partial<TOutput>) => {
    if (onLastStep && onComplete) {
      setSubmitState(LoadState.loading);
      try {
        await onComplete(dataUpdate as TOutput);
        window.scrollTo(0, 0);
      } catch (e: any) {
        dispatch(addNotification(NotificationType.error, e.message));
        window.scrollTo(0, 0);
        setStepIndex(0);
      } finally {
        if (isMounted()) {
          setSubmitState(LoadState.loaded);
        }
      }
    } else {
      setStepIndex(stepIndex + 1);
    }
  };

  const onFormSaveDraft = async (dataUpdate: Partial<TOutput>) => {
    if (onSaveDraft) {
      setSaveDraftState(LoadState.loading);
      try {
        await onSaveDraft(dataUpdate as TOutput);
        window.scrollTo(0, 0);
      } catch (e: any) {
        dispatch(addNotification(NotificationType.error, e.message));
        setStepIndex(0);
      } finally {
        if (isMounted()) {
          setSaveDraftState(LoadState.loaded);
        }
      }
    }
  };

  const onFormStepBack = onFirstStep ? undefined : () => {
    window.scrollTo(0, 0);
    setStepIndex(stepIndex - 1);
  };

  const showSubmitButton = !onLastStep || !!onComplete;

  return (
    <>
      <PageHeaderComponent title={pageHeader ?? stepTitle} />

      <If condition={steps.length > 1}>
        <FormStepper stepTitles={steps.map(s => s.stepTitle || pageHeader)} currentPage={stepIndex} />
      </If>

      <FormComponent
        editSource={editSource}
        onFormStepComplete={onFormStepComplete}
        onFormStepBack={onFormStepBack}
        onFormSaveDraft={onFormSaveDraft}
        FormActionsComponent={({ onSubmitAction, onBackAction = onFormStepBack, onSaveDraftAction }) => (
          <FormActions
            onSubmitAction={onSubmitAction}
            onBackAction={onBackAction}
            onSaveDraftAction={onSaveDraftAction}
            isSubmitting={submitState === LoadState.loading}
            isSavingDraft={saveDraftState === LoadState.loading}
            submitButtonLabel={submitButtonLabel}
            submitButtonDisabled={completeButtonDisabled}
            onCancelAction={onCancel}
            showSubmit={showSubmitButton}
            isViewOnly={isViewOnly}
          />
        )}
      />
    </>
  );
};
