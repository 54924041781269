import { FormikHelpers, useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HospitalManufacturerContract, HospitalManufacturerContractProduct } from '../../../../Data/Contract';
import { HospitalProduct } from '../../../../Data/HospitalProduct';
import { ManufacturerBase } from '../../../../Data/Manufacturer';
import { ProductCategoryStub } from '../../../../Data/ProductCategory';
import { MergedRequisition } from '../../../../Data/Requisition';
import RequisitionProduct from '../../../../Data/RequisitionProduct';
import { dictionary } from '../../../../dictionary';
import { NotificationType } from '../../../../redux/initialState';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { currentUserIsCSRForHospital, currentUserRoleSelector, currentUserSelector } from '../../../../redux/user/userSelectors';
import { contractService, fetchActiveHospitalManufacturerContracts } from '../../../../Services/ContractService';
import { hospitalService } from '../../../../Services/HospitalService';
import { manufacturerService } from '../../../../Services/ManufacturerService';
import Autocomplete from '../../../../Shared/Autocomplete/Autocomplete';
import { Value } from '../../../../Shared/Autocomplete/autocomplete.types';
import { hasValueIsNotArray } from '../../../../Shared/Autocomplete/autocomplete.utils';
import { SimpleDisplayField } from '../../../../Shared/fields/SimpleDisplayField';
import useUtilityStyles from '../../../../Themes/utility.styles';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { maximumProductsPerRequisition } from '../../../../Utils/Constants';
import { alphanumericSortDescriptor, nameSortDescriptor } from '../../../../Utils/SortUtils';
import { userRoles } from '../../../../Utils/userRoles';
import {
  addProductToRequisition,
  areProductsOnRequisition,
  composeRequisitionProductFromExistingHospitalProduct,
  createReqProductFormFields,
  defaultValuesWithManufacturer,
  getActiveContractProduct,
  hasMaximumProducts,
  KeyedReqProductForTable,
  ProductInfoFormValues,
  resetFormExceptManufacturer,
  resetFormExceptManufacturerAndVendor,
} from './shared/ProductInfo.utils';
import { ProductInfoCreateProductModal } from './ProductInfoCreateProductModal/ProductInfoCreateProductModal';
import { ProductInfoHospitalProductDetails } from './ProductInfoHospitalProductDetails';
import { ProductInfoReqProductFormFields } from './shared/ProductInfoReqProductFormFields';
import { validateProductInfoFields } from './shared/ProductInfo.validations';
import { Button } from '../../../../Shared/buttons/Button';
import { getClassForInput } from '../../../../Utils/formik.utils';
import { ProductInfoCreateProductFormValues } from './ProductInfoCreateProductModal/productInfoCreateProduct.utils';
import { useProductInfoStyles } from './productInfo.styles';
import { displayPrice } from '../../../../Utils/formatNumber';
import { HospitalManufacturerContractCapitation } from '../../../../Data/Capitation';
import { UserBase } from '../../../../Data/User';
import { fetchVendorsForCSR } from '../../../../Services/UserService';
import { If } from '../../../../Shared/If';
import { HospitalSettings } from '../../../../Data/Settings';

interface ProductInfoAddProductFormProps {
  requisition: MergedRequisition;
  activeContracts: HospitalManufacturerContract[] | null;
  setProductInfoFormValues: (values: ProductInfoFormValues) => unknown;
  setActiveContracts: (contracts: HospitalManufacturerContract[] | null) => unknown;
  // setContracts: (contracts: HospitalManufacturerContract[]) => unknown;
  dispatchNewReqStateUpdate: (requisition: MergedRequisition) => unknown;
  // capitations: HospitalManufacturerContractCapitation[];
  setCapitations: (capitations: HospitalManufacturerContractCapitation[]) => unknown;
}

export const ProductInfoAddProductForm: FC<ProductInfoAddProductFormProps> = (props) => {
  const dispatch = useDispatch();
  const {
    requisition,
    activeContracts,
    setProductInfoFormValues,
    dispatchNewReqStateUpdate,
    setActiveContracts,
    // setContracts: setCapitationsForRequisition,
    // capitations,
    setCapitations,
  } = props;
  const [productCategories, setProductCategories] = useState<ProductCategoryStub[]>([]);
  const [isHospitalManufacturerContractsLoading, setIsHospitalManufacturerContractsLoading] = useState(false);
  const currentUser = useSelector(currentUserSelector);
  const currentUserRole = useSelector(currentUserRoleSelector);
  const [hospitalSettings, setHospitalSettings] = useState<HospitalSettings>();
  const [activeContract, setActiveContract] = useState<HospitalManufacturerContract>();
  const [activeContractProduct, setActiveContractProduct] = useState<HospitalManufacturerContractProduct>();
  const [createNewProductModalOpen, setCreateNewProductModalOpen] = useState(false);
  const [hospitalProductOptions, setHospitalProductOptions] = useState<HospitalProduct[] | null>(null);
  const [manufacturerOptions, setManufacturerOptions] = useState<ManufacturerBase[]>(requisition.manufacturer ? [requisition.manufacturer] : []);
  const [vendorOptions, setVendorOptions] = useState<UserBase[]>(requisition?.vendor ? [requisition?.vendor] : []);
  const productInfoInitialValues: ProductInfoFormValues = defaultValuesWithManufacturer(requisition?.manufacturer);
  const [lastHospProdCatalogNumSearchText, setLastHospProdCatalogNumSearchText] = useState('');
  const [selectedManufacterer, setSelectedManufacturer] = useState<ManufacturerBase>();

  const currentUserIsCSR = useSelector(currentUserIsCSRForHospital(requisition.hospital!.id));

  const onHospProdCatalogNumSearchTextChange = async (text: string) => {
    if (lastHospProdCatalogNumSearchText) {
      if (text.length >= 3 && requisition.hospital && selectedManufacterer) {
        const products = await hospitalService.getProductsWithCatNumber(requisition.hospital.id, selectedManufacterer.id, text, requisition.caseDate || undefined);
        setHospitalProductOptions(products);
      }
      if (hospitalProductOptions && text.length > 0 && text.length <= 2) {
        const newList = hospitalProductOptions.filter(hpo => hpo.catalogNumber.includes(lastHospProdCatalogNumSearchText));
        setHospitalProductOptions(newList);
      }
    }
    setLastHospProdCatalogNumSearchText(text);
  };

  const appendHospitalProductOption = (newHospProd: HospitalProduct) => {
    if (hospitalProductOptions) {
      setHospitalProductOptions([...hospitalProductOptions, newHospProd]);
    } else {
      setHospitalProductOptions([newHospProd]);
    }
  };

  const dispatchUpdatedRequisition = (completeProduct: RequisitionProduct): void => {
    const reqWithNewProduct = addProductToRequisition(requisition, completeProduct);
    dispatchNewReqStateUpdate(reqWithNewProduct);
  };

  const onHospProdCreate = (newHospProd: HospitalProduct, values: ProductInfoCreateProductFormValues) => {
    if (!hospitalSettings?.newProductsDoNotPermit) appendHospitalProductOption(newHospProd);
    const reqProd = composeRequisitionProductFromExistingHospitalProduct(values, activeContractProduct, activeContract?.contractIdentifier, newHospProd);
    dispatchUpdatedRequisition(reqProd!);
  };

  const handleAddProduct = async (vals: ProductInfoFormValues, formikHelpers: FormikHelpers<ProductInfoFormValues>) => {
    setProductInfoFormValues(vals);
    const userChoseExistingProduct = !!vals.hospitalProduct;
    const handleReqProductStateAdd = (product: KeyedReqProductForTable | null) => {
      if (product) {
        dispatchUpdatedRequisition(product);
        const showMaximumProductsAlert: boolean = !!requisition.products && (requisition.products.length + 1 >= maximumProductsPerRequisition);
        if (showMaximumProductsAlert) {
          dispatch(addNotification(NotificationType.error, dictionary.REQ_PRODUCT_MAXIMUM_ERROR(maximumProductsPerRequisition)));
        }
      }
    };

    if (userChoseExistingProduct) {
      const [newActiveContract, newActiveContractProduct] = getActiveContractProduct(vals.hospitalProduct, activeContracts ?? []);
      const reqProduct = composeRequisitionProductFromExistingHospitalProduct(
        vals,
        newActiveContractProduct,
        newActiveContract?.contractIdentifier,
        hospitalProduct || undefined
      );

      handleReqProductStateAdd(reqProduct);
      setActiveContract(undefined);
      setActiveContractProduct(undefined);
      resetFormExceptManufacturerAndVendor(vals, formikHelpers.resetForm);
    }
  };

  const formikConfig = useFormik<ProductInfoFormValues>({
    initialValues: productInfoInitialValues,
    validate: validateProductInfoFields,
    onSubmit: (vals: ProductInfoFormValues, formikHelpers) => handleAddProduct(vals, formikHelpers),
  });
  const { values, setFieldValue, resetForm } = formikConfig;
  const { hospitalProduct, manufacturer, vendor } = values || {};
  const [haveFetchedManufacturers, setHaveFetchedManufacturers] = useState(false);
  const utilClasses = useUtilityStyles();
  const classes = useProductInfoStyles();

  const newReqHospital = requisition.hospital;

  useEffect(() => {
    (async () => {
      if (haveFetchedManufacturers) { return; }

      setManufacturerOptions([]);
      try {
        const results = await manufacturerService.getAllByHospitalId(requisition.hospital?.id || 0);
        setManufacturerOptions(results ?? []);
        setHaveFetchedManufacturers(true);
      } catch (e) {
        dispatch(addNotification(
          NotificationType.error,
          dictionary.FETCH_MANUFACTURERS_ERROR,
        ));
        setFieldValue('manufacturer', null);
      }
    })();
  }, [
    dispatch,
    haveFetchedManufacturers,
    requisition.hospital,
    requisition.products,
    requisition.capitations,
    setFieldValue,
    setManufacturerOptions,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const results = await hospitalService.getHospitalSettingsById(requisition.hospital?.id || 0);
        setHospitalSettings(results);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
      }
    })();
  }, [dispatch, requisition.hospital]);

  const callFetchHospitalManufacturerContracts = async (hospitalId: number, manufacturerId: number, catalogNum: string) => {
    if (requisition.caseDate) {
      try {
        setIsHospitalManufacturerContractsLoading(true);
        const results = await fetchActiveHospitalManufacturerContracts(hospitalId, manufacturerId, requisition.caseDate!, catalogNum);
        // We may not need to do this in the future
        // const caps = await contractService.getCapitations(manufacturerId, hospitalId);
        // setCapitations(caps);
        // const mappedResults: HospitalManufacturerContract[] = [];
        // results.forEach(r => {
        //   if (capitations?.length) {
        //     mappedResults.push({ ...r, capitations });
        //   } else {
        //     mappedResults.push({ ...r, capitations: [] });
        //   }
        // });
        setActiveContracts(results ?? []);
        setIsHospitalManufacturerContractsLoading(false);
        // if (mappedResults) {
        //   setCapitationsForRequisition(mappedResults);
        // }
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.REQ_PRODUCT_FETCH_CONTRACTS_ERROR));
      } finally {
        setIsHospitalManufacturerContractsLoading(false);
      }
    }
  };

  const getCSRVendors = async (newManufacturerId: number) => {
    if (requisition.hospital?.id && requisition.department?.id) {
      try {
        const results = await fetchVendorsForCSR(requisition.hospital?.id, requisition.department?.id, newManufacturerId);
        const indexOfCurrentUser = results.findIndex(r => r.id === currentUser!.id);
        results[indexOfCurrentUser].firstName = `* ${results[indexOfCurrentUser].firstName}`;
        const sortedVendors = results.filter(v => !v.archived).sort(nameSortDescriptor);
        setVendorOptions(sortedVendors);
        if (!requisition.vendor) setFieldValue('vendor', sortedVendors[0]); // set default value
      } catch (e) {
        dispatch(addNotification(
          NotificationType.error,
          dictionary.FETCH_VENDORS_ERROR,
        ));
      }
    }
  };

  const handleChangeManufacturer = async (newMfct: ManufacturerBase | null) => {
    const oldMfct = manufacturer;
    resetFormExceptManufacturer(values, resetForm);
    setFieldValue('manufacturer', newMfct);
    setProductInfoFormValues({ ...productInfoInitialValues, manufacturer: newMfct });
    setActiveContracts(null);
    setHospitalProductOptions([]);
    if (hasValueIsNotArray(newMfct)) {
      const updatePayload: Partial<MergedRequisition> = { manufacturer: newMfct };
      if (oldMfct?.id && oldMfct.id !== newMfct.id) updatePayload.products = [];
      dispatchNewReqStateUpdate(updatePayload);
      setSelectedManufacturer(newMfct);
      if (newReqHospital) {
        try {
          setIsHospitalManufacturerContractsLoading(true);
          const caps = await contractService.getCapitations(newMfct.id, newReqHospital.id);
          setCapitations(caps);
          // setCapitationsForRequisition(caps);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.REQ_PRODUCT_FETCH_CONTRACTS_ERROR));
        } finally {
          setIsHospitalManufacturerContractsLoading(false);
        }
      }
      if (currentUserIsCSR) getCSRVendors(newMfct.id);
    }
  };

  useEffect(() => {
    if (requisition.manufacturer) {
      handleChangeManufacturer(requisition.manufacturer);
    }
    if (requisition.vendor) {
      setFieldValue('vendor', requisition.vendor);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeVendor = (newVendor: UserBase | null) => {
    setFieldValue('vendor', newVendor);
    const updatePayload: Partial<MergedRequisition> = { vendor: newVendor! };
    dispatchNewReqStateUpdate(updatePayload);
  };

  useEffect(() => {
    (async () => {
      if (currentUserRole === userRoles.vendor) {
        return;
      }
      try {
        const response = await hospitalService.getProductCategoriesByHealthSystem(requisition.hospital!.healthSystemId);
        setProductCategories(response.healthSystemProductCategories);
      } catch (err) {
        dispatch(addNotification(NotificationType.error, dictionary.PRODUCT_CATEGORY_LOADING_ERROR));
      }
    })();
  }, [currentUserRole, requisition.hospital, dispatch]);

  const handleChangeHospitalProduct = (newHospitalProduct: Value<HospitalProduct, boolean>) => {
    resetFormExceptManufacturerAndVendor(values, resetForm);
    setFieldValue('hospitalProduct', newHospitalProduct);

    if (newHospitalProduct && newReqHospital && selectedManufacterer) {
      const hospitalId = newReqHospital.id;
      const manufacturerId = selectedManufacterer.id;
      const catalogNumber = hasValueIsNotArray(newHospitalProduct) ? newHospitalProduct.catalogNumber : '';
      if (!activeContracts?.some(ac => ac.products.some(p => p.catalogNumber === catalogNumber))) {
        callFetchHospitalManufacturerContracts(hospitalId, manufacturerId, catalogNumber);
      }
    }
  };

  useEffect(() => {
    const [newActiveContract, newActiveContractProduct] = getActiveContractProduct(hasValueIsNotArray(hospitalProduct) ? hospitalProduct : null, activeContracts ?? []);
    setActiveContract(newActiveContract);
    setActiveContractProduct(newActiveContractProduct);

    if (newActiveContractProduct && typeof newActiveContractProduct.price === 'number') {
      setFieldValue('price', newActiveContractProduct.price.toFixed(2));
    }
    if (newActiveContractProduct && newActiveContractProduct.unitOfMeasure) {
      setFieldValue('unitOfMeasure', newActiveContractProduct.unitOfMeasure);
    } else {
      setFieldValue('unitOfMeasure', hospitalProduct?.unitOfMeasure || null);
    }
    if (newActiveContractProduct && newActiveContractProduct.quantityUnitOfMeasure) {
      setFieldValue('quantityUnitOfMeasure', newActiveContractProduct.quantityUnitOfMeasure);
    } else {
      setFieldValue('quantityUnitOfMeasure', hospitalProduct?.quantityUnitOfMeasure || null);
    }
    if (hasValueIsNotArray(hospitalProduct) && hospitalProduct.productCategoryId) {
      const productCategoryId = hospitalProduct?.productCategoryId || '';
      setFieldValue('productCategory', productCategories.find((prod) => prod.id === productCategoryId));
    }

    setFieldValue('catalogNumber', hospitalProduct?.catalogNumber || null);
    setFieldValue('description', hospitalProduct?.description || null);
    setFieldValue('brandName', hospitalProduct?.brandName || '');
    setFieldValue('productType', hospitalProduct?.productType || '');
    setFieldValue('orderIdentifier', hospitalProduct?.orderIdentifier || '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalProduct, activeContracts]);

  const onCreateNewProduct = () => {
    setCreateNewProductModalOpen(true);
  };
  const onCloseProdInfoCreateProdModal = () => {
    setCreateNewProductModalOpen(false);
    setLastHospProdCatalogNumSearchText('');
  };

  const sortedManufacturers = manufacturerOptions.sort(alphanumericSortDescriptor('name', 1));

  const isASubmittedReq = !!requisition.id && requisition.statusId !== 11;

  return (
    <>
      <ProductInfoCreateProductModal
        isOpen={!!createNewProductModalOpen}
        activeContracts={activeContracts}
        onHospitalProductCreation={onHospProdCreate}
        close={onCloseProdInfoCreateProdModal}
        productCategories={productCategories}
        manufacturer={requisition.manufacturer!}
        hospital={requisition.hospital!}
        initialCatalogNumber={lastHospProdCatalogNumSearchText}
      />

      <form className="input-form" onSubmit={formikConfig.handleSubmit} data-testid="product-info-add-product-form">
        <Row>
          <Col xs={12} lg={4}>
            { isASubmittedReq
              ? (
                <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_MANUFACTURER} value={requisition.manufacturer?.name} className={utilClasses.mt2} />
              ) : (
                <>
                  <Autocomplete
                    descriptor={createReqProductFormFields.manufacturer}
                    formikProps={formikConfig}
                    options={sortedManufacturers}
                    disabled={!requisition.hospital || (areProductsOnRequisition(requisition) && isASubmittedReq)}
                    value={(manufacturerOptions && manufacturer && manufacturerOptions.find((m: ManufacturerBase) => m.id === manufacturer.id)) ? manufacturer : null}
                    isLoading={isHospitalManufacturerContractsLoading}
                    onChange={handleChangeManufacturer}
                  />
                  <If condition={currentUserIsCSR}>
                    <Autocomplete
                      descriptor={createReqProductFormFields.vendor}
                      formikProps={formikConfig}
                      options={vendorOptions}
                      getOptionLabel={v => `${v.firstName} ${v.lastName}`}
                      onChange={handleChangeVendor}
                      disabled={!requisition.manufacturer}
                      value={(vendorOptions && vendor && vendorOptions.find((v: UserBase) => v.id === vendor.id)) ? vendor : null}
                    />
                  </If>
                </>
              )}
          </Col>
          <Col xs={12} lg={8} className={classes.productForm}>
            <Row>
              <Col xs={12}>
                <h2>{dictionary.REQ_PRODUCT_FORM_HEADER}</h2>
              </Col>
            </Row>
            <Row className={joinArgs(utilClasses.p1, utilClasses.backgroundNearWhite)}>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <Autocomplete
                      id="hospitalProduct"
                      data-testid="hospitalProduct-search"
                      options={hospitalProductOptions || []}
                      disabled={!manufacturer || !manufacturer.id}
                      className={getClassForInput('search', formikConfig.errors, formikConfig.touched)}
                      value={hospitalProductOptions && hospitalProduct && hospitalProductOptions.find((hp: HospitalProduct) => hp.id === hospitalProduct.id) ? hospitalProduct : null}
                      placeholder={dictionary.REQ_PRODUCT_SEARCH_PLACEHOLDER}
                      onChange={handleChangeHospitalProduct}
                      onCreateNew={hospitalSettings?.newProductsDoNotPermit ? undefined : onCreateNewProduct}
                      isLoading={isHospitalManufacturerContractsLoading}
                      onFilterTextChange={onHospProdCatalogNumSearchTextChange}
                      icon={faSearch}
                    />
                  </Col>
                </Row>
                <Row className={utilClasses.mt1}>
                  <Col xs className={joinArgs(classes.productDetailsCol)}>
                    <h3>{dictionary.REQ_EDIT_PRODUCTS_PRODUCT_INFO_HEADER}</h3>
                    <ProductInfoHospitalProductDetails activeContractProduct={activeContractProduct} formikProps={formikConfig} />
                  </Col>

                  <Col xs className={utilClasses.pl3}>
                    <h3>{dictionary.REQ_EDIT_PRODUCTS_REQ_INFO_HEADER}</h3>
                    <ProductInfoReqProductFormFields
                      formikConfig={formikConfig}
                      activeContracts={activeContracts}
                      disabled={!hospitalProduct}
                      allowPriceChanges={!(typeof activeContractProduct?.price === 'number' && hospitalSettings?.contractPriceChangeDoNotPermit)}
                    />
                  </Col>
                </Row>
                <Row className={joinArgs(utilClasses.flex, utilClasses.flexEnd, utilClasses.w100)}>
                  <Col xs={12} md={6} mdOffset={6} className={classes.productSubtotal} data-testid="display-price">
                    { dictionary.REQ_PRODUCT_SUBTOTAL }
                    <span className="display-price">
                      {displayPrice(Number.parseFloat(formikConfig.values.price), formikConfig.values.quantity, formikConfig.values.discount)}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} mdOffset={6} className={joinArgs(utilClasses.flex, utilClasses.flexEnd)}>
                    <Button type="submit" disabled={hasMaximumProducts(requisition)} leadingIcon={<FontAwesomeIcon icon={faPlus} />} data-testid="add-button">
                      {dictionary.REQ_PRODUCT_ADD_BTN}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </>
  );
};
