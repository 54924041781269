import { createUseStyles } from 'react-jss';

export const useSettingsStyles = createUseStyles({
  parentContainer: {
    diplay: 'flexbox',
    margin: '0 1rem 1rem 1rem',
    borderLeft: 'solid 0.0625rem #b5b5b5',
  },
  container: {
    display: 'flexbox',
    height: '24rem',
    overflow: 'auto',
    border: 'solid 0.0625rem transparent',
    borderRadius: '0.2rem',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    justifyContent: 'space-evenly',
    backgroundColor: '#ffffff',
    margin: '1rem 1rem 1rem 2rem',
  },
  emptyContainer: {
    display: 'flexbox',
    border: 'solid 0.0625rem transparent',
    borderRadius: '0.2rem',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    justifyContent: 'space-evenly',
    backgroundColor: '#ffffff',
    margin: '1rem 1rem 1rem 2rem',
    padding: '1rem',
  },
  associateHospitalItems: {
    '&:hover': {
      backgroundColor: '#ededed',
      cursor: 'pointer',
      borderRadius: '0.2rem',
    },
    padding: '0.8rem 5rem 0.8rem 0.8rem',
    borderBottom: 'solid 0.0625rem #dbdbdb',
  },
  selectedAssociateHospitalItems: {
    padding: '0.8rem 5rem 0.8rem 0.8rem',
    borderBottom: 'solid 0.0625rem #dbdbdb',
    backgroundColor: '#0076A8',
    borderColor: '#0076A8',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    color: '#ffffff',
  },
  settingsHospitalHeader: {
    fontSize: '1.5rem',
    marginLeft: '3.5rem',
  },
});

export default useSettingsStyles;
