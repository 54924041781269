import { FC, useState } from 'react';

interface EditableCellProps {
  value: string;
  row: any;
  column: any;
  updateData: (index: any, column: any, value: string) => void;
}

export const EditableCell: FC<EditableCellProps> = ({
  value: initialValue,
  row: { index },
  column,
  updateData,
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const onKeyUp = () => {
    updateData(index, column.id, value);
  };

  return <input value={value === null ? '' : value} onChange={onChange} onKeyUp={onKeyUp} />;
};
