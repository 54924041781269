import { createUseStyles } from 'react-jss';
import colors from '../../../../Themes/colors';
import { fontWeights } from '../../../../Themes/fontWeights';

const tableRowPadding = '.4rem 1.9rem .625rem .43rem';
const shadeBackground = colors.nearWhite;
const lastCellPaddingRight = '.75rem';

export const useNotesTableStyles = createUseStyles({
  root: {
    width: '100%',

    '& tbody': {
      '& tr:nth-child(odd)': {
        background: shadeBackground,
        '&:hover': {
          background: `${shadeBackground} !important`,
        },
      },
    },
    '& tr': {
      '& td:last-child': {
        paddingRight: lastCellPaddingRight,
        whiteSpace: 'nowrap',
      },
      '& th:last-child': {
        paddingRight: lastCellPaddingRight,
      },
      '&:hover': {
        background: 'transparent !important',
      },
    },
    '& th': {
      padding: tableRowPadding,
      fontWeight: fontWeights.bold,
    },
    '& td': {
      padding: tableRowPadding,
    },
  },
  notesTableContainer: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
    border: `solid 1px ${colors.darkGray}`,
    overflowY: 'auto',
    padding: '.625rem .75rem',
  },
  notesTable: {
    width: '100%',
    borderSpacing: 0,
  },
});
