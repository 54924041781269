import { createUseStyles } from 'react-jss';
import colors from './colors';
import { fontWeights } from './fontWeights';

export const dashboardLinkStyles = createUseStyles({
  container: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    aspectRatio: '1/1',
    marginBottom: '1rem',
    padding: '1.5rem',
    textDecoration: 'none',
    textAlign: 'center',
    backgroundColor: colors.nearWhite,
    '&:hover, &:focus': {
      backgroundColor: colors.kingfisher,
      outline: 'none',
      '& div': {
        color: colors.nearWhite,
      },
    },
  },

  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '5rem',
    height: '5rem',
    color: colors.blue,
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '3rem',
    marginTop: '0.25rem',
    fontSize: '1.125rem',
    fontWeight: fontWeights.bold,
    textAlign: 'center',
    color: colors.blue,
  },
});
