import { FieldInputProps } from 'formik';
import { FC } from 'react';
import { Checkbox } from '../../../../../Shared/fields/Checkbox';
import { If } from '../../../../../Shared/If';

interface TransactionTypeCheckboxProps {
  formikErrors: any;
  displayErrors: boolean;
  fieldLabel: string;
  fieldName: string;
  checked: boolean;
  formikFieldConfig: FieldInputProps<any>;
  onChangeHandler: () => any;
  onBlurHandler: (eventOrString: any) => void | ((e: any) => void);
  disabled: boolean;
}

export const TransactionTypeCheckbox: FC<TransactionTypeCheckboxProps> =
  ({ formikErrors, displayErrors = false, onChangeHandler, fieldLabel, fieldName, formikFieldConfig, checked, onBlurHandler, disabled }) => (
    <>
      <If condition={displayErrors}>
        <div role="alert" className="validation-errors">
          {formikErrors}
        </div>
      </If>
      <Checkbox
        {...formikFieldConfig}
        id={`transactionType${fieldName}`}
        key={fieldLabel}
        disabled={disabled}
        checked={checked}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        label={fieldLabel}
      />
    </>
  );
