import { AnyAction } from 'redux';
import { ContractProductCSV } from '../../Data/Contract';
import { actionTypes } from '../actionTypes';

export interface AddContractProductPricingAction extends AnyAction {
  newProductPricings: ContractProductCSV[];
}

export const getAddContractProductsAction =
  (csvProductPricings: ContractProductCSV[]): AddContractProductPricingAction => ({ type: actionTypes.contract.addProducts, newProductPricings: csvProductPricings });

export const getAddContractProductsEditingAction =
  (csvProductPricings: ContractProductCSV[]): AddContractProductPricingAction => ({ type: actionTypes.contract.addProductsEditing, newProductPricings: csvProductPricings });
export interface SetProductUploadErrorAction extends AnyAction {
  failedLines: string[];
}

export const getSetProductUploadErrorsAction =
  (failedLines: string[]): SetProductUploadErrorAction => ({ type: actionTypes.contract.setProductUploadErrors, failedLines });

export const getSetProductUploadErrorsEditingAction =
  (failedLines: string[]): SetProductUploadErrorAction => ({ type: actionTypes.contract.setProductUploadErrorsEditing, failedLines });
