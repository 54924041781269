import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CostCenterEditRequest, CostCenterStub } from '../../../Data/CostCenter';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { costCenterService } from '../../../Services/CostCenterService';
import { ArchiveButton } from '../../../Shared/buttons/ArchiveButton';
import { If } from '../../../Shared/If';
import { createArchiveModalComponent } from '../../../Shared/modals/ArchiveModal';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';

interface ArchiveCostCenterPageHeaderProps {
  costCenter: CostCenterStub;
  onSuccess: (updatedCostCenter: CostCenterStub) => void;
}

export const ArchiveCostCenterPageHeader: FC<ArchiveCostCenterPageHeaderProps> = ({ costCenter, onSuccess }) => {
  const dispatch = useDispatch();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const toggleArchived = useCallback(async (costCenterReq: CostCenterEditRequest) => {
    const costCenterRequest = { ...costCenterReq, archived: !costCenter?.archived };

    try {
      await costCenterService.update(costCenter.id, costCenterRequest);
      if (!costCenter?.archived) dispatch(addNotification(NotificationType.success, dictionary.ARCHIVE_COST_CENTER_SUCCESS));
      else dispatch(addNotification(NotificationType.success, dictionary.ACTIVATE_COST_CENTER_SUCCESS));

      onSuccess({ ...costCenter!, archived: costCenterRequest.archived });
    } catch (e) {
      dispatch(addNotification(NotificationType.error, dictionary.ARCHIVE_COST_CENTER_ERROR));
    }
  }, [costCenter, dispatch, onSuccess]);

  const openArchiveModal = () => setIsArchiveModalOpen(true);
  const closeArchiveModal = () => setIsArchiveModalOpen(false);

  const toggleArchivedAndCloseModal = useCallback(() => {
    toggleArchived(costCenter!);
    closeArchiveModal();
  }, [costCenter, toggleArchived]);

  const archiveBtnText = useMemo(() => (costCenter?.archived ? dictionary.ACTIVATE_COST_CENTER_BTN : dictionary.ARCHIVE_COST_CENTER_BTN), [costCenter?.archived]);
  const archiveBtnOnClick = useMemo(() => (costCenter?.archived ? toggleArchivedAndCloseModal : openArchiveModal), [costCenter?.archived, toggleArchivedAndCloseModal]);
  const ArchiveCostCenterModal = useMemo(() => createArchiveModalComponent(dictionary.ARCHIVE_COST_CENTER_MODAL_HEADER, dictionary.ARCHIVE_COST_CENTER_MODAL_TEXT), []);

  return (
    <>
      <PageHeader title={dictionary.EDIT_COST_CENTER_PAGE_HEADER}>
        <If condition={!!costCenter.canCurrentUserEdit}>
          <ArchiveButton archived={!!costCenter?.archived} onClick={archiveBtnOnClick}>{archiveBtnText}</ArchiveButton>
        </If>
      </PageHeader>
      <If condition={!!costCenter.canCurrentUserEdit}>
        <ArchiveCostCenterModal isOpen={isArchiveModalOpen} onConfirm={toggleArchivedAndCloseModal} onDismiss={closeArchiveModal} />
      </If>
    </>
  );
};
