import { createUseStyles } from 'react-jss';
import colors from '../../../../Themes/colors';

export const useProductsToBillTableStyles = createUseStyles({
  selectColumnCell: {
    textAlign: 'center',
  },
  indvRoot: {
    overflow: 'auto',
    '& table': {
      '& thead': {
        backgroundColor: colors.gray,
        '& th': {
          color: colors.trueWhite,
        },
      },
      '& .expand-row > td': {
        backgroundColor: colors.nearWhite,
        padding: 0,
        '& table thead': {
          backgroundColor: colors.mystic,
          '& th': {
            color: colors.nearBlack,
          },
        },
        '& tbody > tr': {
          backgroundColor: colors.mystic,
        },
      },
    },
  },
  capitatedRoot: {
    overflow: 'auto',
    '& table': {
      border: `1px solid ${colors.darkGray}`,
      '& thead': {
        backgroundColor: colors.gray,
        '& th': {
          borderBottom: `1px solid ${colors.darkGray}`,
          color: colors.trueWhite,
        },
      },
      '& tbody tr': {
        borderBottom: 'none',
        borderTop: `1px solid ${colors.darkGray}`,
        '& td': {
          backgroundColor: colors.matcha,
        },
      },
      '& tbody tr:first-child': {
        borderTop: 'none',
      },
    },
    '& .expand-row > td': {
      backgroundColor: colors.radiumGreen,
      '& table tbody tr td': {
        backgroundColor: colors.nearWhite,
      },
    },
  },
  ptbInsetRight: {
    '& td': {
      '&.insetRight:last-of-type': {
        paddingRight: '2.5rem',
      },
    },
  },
});
