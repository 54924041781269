import { FC, useState } from 'react';

interface EditableCurrencyCellProps {
  value: number;
  row: any;
  column: any;
  updateData: (index: any, column: any, value: any) => void;
}

export const EditableCurrencyCell: FC<EditableCurrencyCellProps> = ({
  value: initialValue,
  row: { index },
  column,
  updateData,
}) => {
  const [value, setValue] = useState(initialValue.toFixed(2));

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const onKeyUp = () => {
    updateData(index, column.id, parseFloat(value));
  };

  return (
    <>
      <span>$</span>
      <input value={value} onChange={onChange} onKeyUp={onKeyUp} />
    </>
  );
};
