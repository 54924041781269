import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { CellProps, Row, TableCommonProps } from 'react-table';
import useUtilityStyles from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import { Button } from '../buttons/Button';
import { If } from '../If';
import { Intent } from '../UiTypes';

interface ActionCellOptions {
  key: string;
  intent: Intent;
  title: string;
  icon: IconDefinition;
  action: (cellProps: CellProps<any, any>) => unknown;
  keep?: boolean;
  testId?: string;
  ariaLabel?: string;
  getBtnPropsFromRow?: (row: Row<any>) => Partial<TableCommonProps>;
}
interface CellComponentOptions {
  keep?: boolean;
  key: string;
  Cell: FC<CellProps<any>>;
}

type GetActionsCellFn = (options: (ActionCellOptions | CellComponentOptions)[]) => FC<CellProps<any>>;
const optionsIsActionCellOptions = (opts: ActionCellOptions | CellComponentOptions): opts is ActionCellOptions => !(opts as any).Cell;
export const getActionsCellComponent: GetActionsCellFn = (options) => {
  const ActionCells: FC<CellProps<any, any>> = (cellProps) => {
    const utilClasses = useUtilityStyles();

    return (
      <div className={joinArgs(utilClasses.flex, utilClasses.spaceBetween)}>
        {
          options.map(opts => {
            if (!optionsIsActionCellOptions(opts)) {
              const { Cell, key } = opts;
              return <Cell {...cellProps} key={key} />;
            }
            const { intent, title, icon, ariaLabel, action, testId, key, keep = true } = opts;
            return (
              <If condition={!!keep} key={key}>
                <Button
                  buttonStyle="icon"
                  intent={intent}
                  onClick={() => action(cellProps)}
                  tooltipTitle={title}
                  leadingIcon={(
                    <FontAwesomeIcon
                      icon={icon}
                      size="1x"
                      data-testid={testId}
                      aria-label={ariaLabel}
                    />
                  )}
                />
              </If>
            );
          })
        }
      </div>
    );
  };

  return ActionCells;
};
