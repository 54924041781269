import { NotificationActionType } from './notifications/notificationsActionCreator';

const requisition = {
  fetchById: 'req.fetchById',
  updateNew: 'req.updateNew',
  create: 'req.create',
  clearNew: 'req.clearNew',
  clearManufacturer: 'req.clearManufacturer',
  clearProducts: 'req.clearProducts',
  clearPatient: 'req.clearPatient',
  removeProduct: 'req.removeProduct',
  applyCapitation: 'req.applyCapitation',
  removeCapitation: 'req.removeCapitation',
  removeProductFromCapitation: 'req.removeProductFromCapitation',
  updateProduct: 'req.updateProduct',
};

const contract = {
  create: 'contract.create',
  clearNew: 'contract.clearNew',
  updateNew: 'contract.updateNew',
  addProducts: 'contract.addProducts',
  setProductUploadErrors: 'contract.setProductUploadErrors',
  updateEditing: 'contract.updateEditing',
  clearEditing: 'contract.clearEditing',
  addProductsEditing: 'contract.addProductsEditing',
  setProductUploadErrorsEditing: 'contract.setProductUploadErrorsEditing',
};

const notification = {
  add: NotificationActionType.add,
  remove: NotificationActionType.remove,
};

const user = {
  fetch: 'user.fetch',
  fetchSuccess: 'user.fetchSuccess',
  fetchError: 'user.fetchError',
  clearUser: 'user.clearUser',
};

export const actionTypes = Object.freeze({
  requisition,
  contract,
  user,
  notification,
});
