import { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FormikValues, useFormik } from 'formik';
import { dictionary } from '../../dictionary';
import { Modal } from '../../Shared/modals/Modal/Modal';
import { SimpleFieldWithLabel } from '../../Shared/fields/SimpleFieldWithLabel';
import { SimpleDisplayField } from '../../Shared/fields/SimpleDisplayField';
import { createDescriptor } from '../../Utils/formik.utils';
import { FormFieldDescriptor } from '../../Shared/fields/formTypes';
import { Button } from '../../Shared/buttons/Button';
import { AutoArchiveHospital } from '../../Data/Hospital';
import { validateNumberInRangeInclusive, validateRequiredFields } from '../../Utils/validationUtils';
import { autoArchiveNumDaysMin, autoArchiveNumDaysMax, autoArchiveNumDaysDefault } from '../../Utils/Constants';

interface UpdateAutoArchiveModalProps {
  isModalOpen: boolean;
  selectedArchivedItem: AutoArchiveHospital;
  submitAutoArchiveChange: (value: number) => void;
  cancelOnClick: () => void;
}

export const UpdateAutoArchiveModal: FC<UpdateAutoArchiveModalProps> = ({
  isModalOpen,
  selectedArchivedItem,
  submitAutoArchiveChange,
  cancelOnClick }) => {
  const [updatedValueOfDaysUntilArchive, setUpdatedValueOfDaysUntilArchive] = useState();

  const useSettingsStyles = createUseStyles({
    modalContainer: {
      borderBottom: 'solid 0.1rem #dbdbdb',
      paddingBottom: '0.7rem',
      fontSize: '1rem',
    },
  });

  const classes = useSettingsStyles();

  const daysUntilArchiveField: FormFieldDescriptor = createDescriptor({
    label: dictionary.DAYS_UNTIL_ARCHIVE,
    inputFormat: 'number',
    maxLength: autoArchiveNumDaysMax,
    minLength: autoArchiveNumDaysMin,
    required: true,
    name: 'daysUntilAutoArchive' });

  const submitHandler = (values: FormikValues) => setUpdatedValueOfDaysUntilArchive(values[daysUntilArchiveField.name]);
  const validate = (values: FormikValues) => ({
    ...validateNumberInRangeInclusive(values, [daysUntilArchiveField], autoArchiveNumDaysMin, autoArchiveNumDaysMax),
    ...validateRequiredFields(values, [daysUntilArchiveField]),
  });

  const formikConfig = useFormik({
    initialValues: { [daysUntilArchiveField.name]: selectedArchivedItem?.daysUntilAutoArchive ?? autoArchiveNumDaysDefault },
    onSubmit: submitHandler,
    validate,
    enableReinitialize: true,
  });

  return (
    <Modal
      data-testid="update-information-modal"
      title={dictionary.AUTO_ARCHIVE_UPDATE_TITLE}
      isOpen={isModalOpen}
      icon={faInfoCircle}
      actionsContent={(
        <>
          <Button buttonStyle="reverse" onClick={cancelOnClick} data-testid="modal-cancel">
            {dictionary.CANCEL}
          </Button>
          <Button buttonStyle="primary" onClick={() => submitAutoArchiveChange(formikConfig.values.daysUntilAutoArchive)} data-testid="modal-continue">
            {dictionary.SAVE_BUTTON_LABEL}
          </Button>
        </>
      )}
    >
      { selectedArchivedItem &&
      (
        <div key={selectedArchivedItem.hospitalId}>
          <SimpleDisplayField
            label={dictionary.HOSPITAL_NAME_LABEL}
            className={classes.modalContainer}
            value={selectedArchivedItem!.hospitalName}
          />
          <SimpleFieldWithLabel
            form={formikConfig}
            field={updatedValueOfDaysUntilArchive}
            descriptor={daysUntilArchiveField}
            placeholder={autoArchiveNumDaysDefault.toString()}
          />
        </div>
      )}
    </Modal>
  );
};
