import { DateTime } from 'luxon';

export const dateToString = (date: Date | string): string => (typeof date === 'string' ? date : date.toISOString());

export const getIsoFormattedDate = (date: string): string => (date.indexOf('-') < 0 ? DateTime.fromFormat(date, 'MM/dd/yyyy').toFormat('yyyy-MM-dd') : date);

export const getSlashFormattedDate = (date?: Date | string | null): string => {
  if (!date) return '';
  if (typeof date === 'string') {
    return date.indexOf('/') < 0
      ? DateTime.fromISO(date).toFormat('MM/dd/yyyy')
      : date || '';
  }
  return DateTime.fromJSDate(date).toFormat('MM/dd/yyyy');
};

export const getSlashFormattedDateTime = (date: string): string => (date.indexOf('/') < 0 ? DateTime.fromISO(date).toFormat('MM/dd/yyyy, t') : date);

export const asEasternDateTime = (date: DateTime | string): DateTime => (typeof date === 'string'
  ? DateTime.fromISO(date).setZone('UTC-4', { keepLocalTime: true })
  : date.setZone('UTC-4', { keepLocalTime: true }));

export const getLocalDateWithTime = (): DateTime => (DateTime.fromISO(DateTime.local().toFormat('yyyy-MM-dd\'T\'hh:mm:ss')));
