import { createUseStyles } from 'react-jss';
import colors from './colors';

export default createUseStyles({
  active: {
    '&:before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      bottom: '2rem',
      left: 0,
      width: '100%',
      height: '0.4rem',
      borderRadius: '0.25rem',
      backgroundColor: colors.blue,
    },
  },
});
