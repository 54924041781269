import { FormikErrors, FormikValues } from 'formik';
import { validateRequiredFields, validateCharacterLimit, validateEmailFormat } from '../../../Utils/validationUtils';
import { userFormFields } from './user.utils';
import { validateCreateOrEditUserForm } from './createOrEditUserForm.validation';

export const validateCreateUserForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { email } = userFormFields;
  const emailLimit = 320;
  const emailFormatValidation = validateEmailFormat(values, email);

  return {
    ...validateCreateOrEditUserForm(values),
    ...emailFormatValidation,
    ...validateCharacterLimit(values, [email], emailLimit),
    ...validateRequiredFields(values, [email]),
  };
};
