import { FC, useMemo } from 'react';
import { Column } from 'react-table';
import { ReqTable } from '../shared/RequisitionsTable/ReqTable';
import { dictionary } from '../../../../dictionary';
import useUtilityStyles from '../../../../Themes/utility.styles';
// eslint-disable-next-line max-len
import { ReqForIndexTable, pendingApprovalColumnProperties, approvedColumnProperties, declinedColumnProperties, vendorRequisitionTableColumnProperties, ReqTableForIndex, archivedColumnProperties }
  from '../shared/RequisitionsTable/reqTable.utils';
import { RequisitionStatus } from '../../../../Data/RequisitionStatus';
import { SortValues } from '../../../../Shared/SortableTable/useSortedRows';
import { If } from '../../../../Shared/If';

interface VendorRequisitionsProps {
  isLoading: boolean;
  requisitions: ReqForIndexTable[];
  activeTab: number;
}

export const VendorRequisitions: FC<VendorRequisitionsProps> = (props) => {
  const { isLoading, requisitions, activeTab } = props;

  const utilClasses = useUtilityStyles();

  const notApprovedOrDeclined = (req: ReqForIndexTable) =>
    req.statusCode !== RequisitionStatus.approved
    && req.statusCode !== RequisitionStatus.completed
    && req.statusCode !== RequisitionStatus.archived
    && req.statusCode !== RequisitionStatus.declined
    && req.statusCode !== RequisitionStatus.returned;

  const processingRequisitions = requisitions.filter(notApprovedOrDeclined);
  const approvedRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.approved
                                                        || req.statusCode === RequisitionStatus.completed);
  const declinedRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.declined);
  const returnedRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.returned);
  const draftRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.draft);
  const archivedRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.archived);

  const VendorReqTable = useMemo((): FC<{ reqs: ReqForIndexTable[], colProps: Column<ReqForIndexTable>[], noDataMsg: string, sortOrder?: SortValues }> =>
    ({ reqs, colProps, noDataMsg, sortOrder }) => (
      <ReqTable
        requisitions={reqs}
        columns={colProps}
        noDataMessage={noDataMsg}
        isLoading={isLoading}
        initialSortOrder={sortOrder ?? 'desc'}
      />
    ), [isLoading]);

  const requisitionTables: ReqTableForIndex[] = [
    {
      testId: 'my-requisitions-box',
      title: dictionary.REQS_AWAITING_YOUR_ACTION_HEADER,
      reqs: [...draftRequisitions, ...returnedRequisitions],
      colProps: vendorRequisitionTableColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_AWAITING_ACTION_EMPTY,
      sortOrder: 'asc',
    },
    {
      testId: 'processing-requisitions-box',
      title: dictionary.REQS_PROCESS_PROCESSING_HEADER,
      reqs: processingRequisitions,
      colProps: pendingApprovalColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_PROCESSING_EMPTY,
      sortOrder: 'asc',
    },
    {
      testId: 'approved-requisitions-box',
      title: dictionary.REQS_APPROVED_HEADER,
      reqs: approvedRequisitions,
      colProps: approvedColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_APPROVED_EMPTY,
    },
    {
      testId: 'declined-requisitions-box',
      title: dictionary.REQS_DECLINED_HEADER,
      reqs: declinedRequisitions,
      colProps: declinedColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_DECLINED_EMPTY,
    },
    {
      testId: 'archive-requisitions-box',
      title: dictionary.REQS_ARCHIVED_HEADER,
      reqs: archivedRequisitions,
      colProps: archivedColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_ARCHIVED_EMPTY,
    },
  ];

  return (
    <>
      { requisitionTables.map((table, index) => (
        <If condition={(activeTab === 0 || activeTab === index + 1) && ((activeTab === 0 && table.testId !== 'archive-requisitions-box') || activeTab === index + 1)} key={table.testId}>
          <div className={utilClasses.mb2} data-testid={table.testId}>
            <h2 className={utilClasses.mt0}>{table.title}</h2>
            <VendorReqTable reqs={table.reqs} colProps={table.colProps} noDataMsg={table.noDataMsg} sortOrder={table.sortOrder} />
          </div>
        </If>
      )) }
    </>
  );
};
