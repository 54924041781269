import { FC, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { FormikValues, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { getProductCategoryFormInitialVals, productCategoryFormFields, validateProductCategoryForm } from './productCategory.utils';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { dictionary } from '../../../dictionary';
import { CreateOrEditProductCategoryRequest, ProductCategoryStub } from '../../../Data/ProductCategory';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';
import { If } from '../../../Shared/If';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import { BackButton } from '../../../Shared/buttons/BackButton';
import { routes } from '../../../Utils/routes';
import { HealthSystem } from '../../../Data/HealthSystem';
import { healthSystemService } from '../../../Services/HealthSystemService';

interface ProductCategoryFormProps extends FormFlowStepComponent<CreateOrEditProductCategoryRequest> {
  productCategory?: ProductCategoryStub;
}

export const ProductCategoryForm: FC<ProductCategoryFormProps> = ({ productCategory, FormActionsComponent, onFormStepComplete }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [healthSystems, setHealthSystems] = useState<HealthSystem[]>([]);
  const isEdit = !!productCategory;
  const isFormDisabled = isEdit && !productCategory?.canCurrentUserEdit;

  useEffect(() => {
    (async () => {
      if (!healthSystems.length && !productCategory) {
        try {
          const response = await healthSystemService.get();
          setHealthSystems(response.sort(alphanumericSortDescriptor('name', 1)));
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, healthSystems, productCategory]);

  const formikInitialValues = useMemo(() => getProductCategoryFormInitialVals(productCategory), [productCategory]);
  const formikProps = useFormik({
    initialValues: formikInitialValues,
    validate: validateProductCategoryForm,
    onSubmit: (formValues: FormikValues) => {
      onFormStepComplete({
        healthSystemId: isEdit ? productCategory?.healthSystemId : formValues.healthSystem.id,
        name: formValues.name,
        hospitalProductCategoryIdentifier: formValues.hospitalProductCategoryIdentifier,
      });
    },
  });

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={4}>
          <If condition={!productCategory}>
            <Autocomplete descriptor={productCategoryFormFields.healthSystem} formikProps={formikProps} options={healthSystems} disabled={!healthSystems.length} />
          </If>
          <If condition={!!productCategory}>
            <SimpleDisplayField label={productCategoryFormFields.healthSystem.label} value={productCategory?.healthSystemName} />
          </If>
          <SimpleFieldWithLabel form={formikProps} descriptor={productCategoryFormFields.name} disabled={isFormDisabled} />
          <SimpleFieldWithLabel form={formikProps} descriptor={productCategoryFormFields.hospitalProductCategoryIdentifier} disabled={isFormDisabled} />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <If condition={!isFormDisabled}>
            <FormActionsComponent />
          </If>
          <If condition={isFormDisabled}>
            <BackButton onClick={() => history.push(routes.productCategories.index)} />
          </If>
        </Col>
      </Row>
    </form>
  );
};
