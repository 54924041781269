import { ManufacturerBase } from './Manufacturer';
import { DepartmentBase } from './Department';
import { dictionary } from '../dictionary';

export interface UserBase {
  id: number;
  firstName: string;
  lastName: string;
  archived?: boolean;
}

export interface User extends UserBase {
  roleName: string;
  email: string;
  phoneNumber: string;
  hospitalEmployeeId: string;
  hospitalAssociations: UserHospitalAssociation[];
  acceptedTermsOfUse: boolean;
  canCurrentUserEdit: boolean;
  archived?: boolean;
}

export interface TableUser extends User {
  hospitalNames: string[],
}

export interface GetUsersResponse {
  users: User[];
}

export interface UserHospitalAssociation {
  hospitalId: number;
  hospitalName: string;
  hospitalUserRole?: number;
  departments: DepartmentBase[];
  manufacturers: ManufacturerBase[];
  isHospitalAdmin?: boolean;
  isHospitalCSR?: boolean;
  canCurrentUserEdit?: boolean;
}

type CreateUserFields =
  'firstName'
  | 'lastName'
  | 'phoneNumber'
  | 'email'
  | 'roleName'
  | 'hospitalAssociations';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CreateUserRequest extends Pick<User, CreateUserFields> {
}

export interface CreateHospitalUserRequest extends CreateUserRequest {
  hospitalEmployeeId: string;
}

export interface EditHospitalUserRequest extends CreateHospitalUserRequest {
  id: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateVendorUserRequest extends CreateUserRequest {}

export interface EditVendorUserRequest extends CreateVendorUserRequest {
  id: number;
}

export default User;

export interface HospitalUserRoleOption {
  id: number;
  name: string;
}

export enum HospitalUserRole {
  Admin = 1,
  Staff = 2,
  StandardRepresentative = 3,
  CustomerServiceRepresentative = 4,
}

export const hospitalUserRolesforHospitalUsers: HospitalUserRoleOption[] = Object.values([
  { id: HospitalUserRole.Admin, name: dictionary.HOSPITAL_USER_ROLE_ADMIN },
  { id: HospitalUserRole.Staff, name: dictionary.HOSPITAL_USER_ROLE_STAFF },
]);

export const hospitalUserRolesforVendorUsers: HospitalUserRoleOption[] = Object.values([
  { id: HospitalUserRole.StandardRepresentative, name: dictionary.HOSPITAL_USER_ROLE_SR },
  { id: HospitalUserRole.CustomerServiceRepresentative, name: dictionary.HOSPITAL_USER_ROLE_CSR },
]);

export const hospitalUserRoles = [...hospitalUserRolesforHospitalUsers, ...hospitalUserRolesforVendorUsers];
