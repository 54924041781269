import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-flexbox-grid';
import { ArchivedRequisitions } from './ArchivedRequisitions';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { ArchivedReqStub } from '../../../Data/Requisition';
import { fetchArchivedRequisitions } from '../../../Services/RequisitionService';
import { getRequisitionRows, ArchivedReqForIndexTable, shouldIncludeReqInResults } from './shared/ArchivedRequisitionsTable/reqTable.utils';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../../dictionary';
import { currentUserSelector } from '../../../redux/user/userSelectors';
import { userRoles } from '../../../Utils/userRoles';
import { ArchivedRequisitionsIndexFilters } from './ArchivedRequisitionsIndexFilters';
import { TabInfo, Tabs } from '../../../Shared/Tabs/Tabs';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';

export const ArchivedRequisitionsIndex: FC = () => {
  const [formattedReqs, setFormattedReqs] = useState<ArchivedReqForIndexTable[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [filterColumnSelectedOption, setFilterColumnSelectedOption] = useState<keyof ArchivedReqForIndexTable>();
  const [activeTab, setActiveTab] = useState(0);
  const user = useSelector(currentUserSelector);
  const utilClasses = useUtilityStyles();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const requisitionsResponse: ArchivedReqStub[] = await fetchArchivedRequisitions();
        const incomingFormattedReqs: ArchivedReqForIndexTable[] = getRequisitionRows(requisitionsResponse);
        setFormattedReqs(incomingFormattedReqs);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const filteredReqs = useMemo(
    () => formattedReqs.filter(req => req.status && shouldIncludeReqInResults(filter, req, filterColumnSelectedOption)),
    [filter, filterColumnSelectedOption, formattedReqs]
  );

  const tabs: TabInfo[] = useMemo(() => {
    if (user?.roleName === userRoles.omiAdmin) {
      return [
        { title: dictionary.REQS_TAB_ALL, active: activeTab === 0, onClick: () => { setActiveTab(0); } },
      ];
    }
    return [
      { title: dictionary.REQS_TAB_ALL, active: activeTab === 0, onClick: () => { setActiveTab(0); } },
    ];
  }, [activeTab, user?.roleName]);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader title={dictionary.REQ_ARCHIVED} withoutBottomMargin />

      <Row>
        <Col xs={12} lg={6} className={joinArgs(utilClasses.flexRow, utilClasses.p0)}>
          <Tabs tabs={tabs} />
        </Col>
        <Col xs={12} lg={6}>
          <ArchivedRequisitionsIndexFilters
            filterColumn={filterColumnSelectedOption}
            onSelectFilterColumn={setFilterColumnSelectedOption}
            filterText={filter}
            onChangeFilterText={setFilter}
          />
        </Col>
      </Row>

      <ArchivedRequisitions isLoading={isLoading} requisitions={filteredReqs} activeTab={activeTab} />
    </>
  );
};
