import { FormikErrors, FormikValues } from 'formik';
import { dictionary } from '../../../../../dictionary';
import { validateCharacterLimit, validateCurrency, validateNumberDoesNotExceed, validateNumberInRangeInclusive, validateNumberIsWhole, validateRequiredFields } from '../../../../../Utils/validationUtils'; // eslint-disable-line max-len
import { createReqProductFormFields } from './ProductInfo.utils';

export const validateProductInfoFields = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { serialNumber, lotNumber, quantity, price } = createReqProductFormFields;
  const errors: any = {};

  if ((values.explantDiscountApplied || values.trialDiscountApplied || values.wastedDiscountApplied) && (Number.isNaN(values.discount))) {
    errors.discount = dictionary.REQ_PRODUCT_DISCOUNT_REQUIRED_ERROR;
  }

  if (values.discount && (values.discount < 0 || values.discount > 100 || Number.isNaN(values.discount))) {
    errors.discount = dictionary.REQ_PRODUCT_DISCOUNT_RANGE_ERROR;
  }

  return {
    ...errors,
    ...validateCharacterLimit(values, [serialNumber, lotNumber], 100),
    ...validateCurrency(values, [price]),
    ...validateNumberDoesNotExceed(values, [price], 100_000_000),
    ...validateNumberInRangeInclusive(values, [quantity], 1, 5_000),
    ...validateNumberIsWhole(values, [quantity]),
    ...validateRequiredFields(values, Object.values(createReqProductFormFields)),
  };
};
