import { MergedRequisition } from '../Data/Requisition';
import { LoadState } from './loadState';
import { Contract } from '../Data/Contract';
import User from '../Data/User';

export interface RequisitionState {
  editRequisition: MergedRequisition,
}

export interface ContractState {
  newContract: Partial<Contract>
  editContract: Partial<Contract>
}

export interface UserState {
  currentUser?: User,
  fetchLoadState: LoadState
}

export enum NotificationType {
  success,
  error,
  info,
}

export interface Notification {
  type: NotificationType
  message: string,
  id: number,
  onComplete?: () => void,
}

export type NotificationState = Array<Notification>;

export interface ReduxState {
  requisition: RequisitionState,
  contract: ContractState,
  user: UserState
  notifications: NotificationState
}

export const initialState: ReduxState = {
  requisition: {
    editRequisition: {},
  },
  contract: {
    newContract: {},
    editContract: {},
  },
  user: {
    currentUser: undefined,
    fetchLoadState: LoadState.notLoaded,
  },
  notifications: [],
};

export default initialState;
