import { useState, useEffect, FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Contract, ContractStatus } from '../../../Data/Contract';
import { getContractById } from '../../../Services/ContractService';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../../dictionary';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import ContractBaseInfoDisplay from '../shared/ContractBaseInfoDisplay';
import { routes } from '../../../Utils/routes';
import { If } from '../../../Shared/If';
import { Divider } from '../../../Shared/Divider/Divider';
import { ProductsOnContract } from '../shared/ProductsOnContract';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../redux/initialState';
import LoadState from '../../../redux/loadState';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { ErrorPage } from '../../../Shared/ErrorPage';

export const ContractDetails: FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const contractId = Number(params.id);
  const [contract, setContract] = useState<Contract>();
  const [contractLoadState, setContractLoadState] = useState(LoadState.loading);
  const isActiveContract = contract?.status === ContractStatus.Active || contract?.status === ContractStatus.ExpiringSoon;

  useEffect(() => {
    (async () => {
      try {
        const fetchedContract = await getContractById(contractId);
        setContract(fetchedContract);
        setContractLoadState(LoadState.loaded);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_LOAD_ERROR));
        setContractLoadState(LoadState.error);
      }
    })();
  }, [dispatch, contractId]);

  if (contractLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }

  if (contractLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader title={dictionary.CONTRACT_DETAIL_PAGE_HEADER(contract?.contractIdentifier)}>
        <If condition={contract?.canCurrentUserEdit ?? false}>
          <LinkButton to={routes.contracts.edit(contractId)} leadingIcon={faEdit}>
            Edit
          </LinkButton>
        </If>
      </PageHeader>

      <If condition={!!contract}>
        <Row>
          <Col xs={12}>
            <ContractBaseInfoDisplay contract={contract} />
          </Col>
        </Row>

        <Divider />

        <ProductsOnContract
          products={contract?.products}
          contractId={contractId}
          isActiveContract={isActiveContract}
          canCurrentUserEdit={contract?.canCurrentUserEdit}
        />
      </If>
    </>
  );
};
