import { createUseStyles } from 'react-jss';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProductCategoryStub } from '../../../Data/ProductCategory';
import { ApiRequisition } from '../../../Data/Requisition';
import { RequisitionStatus } from '../../../Data/RequisitionStatus';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { currentUserRoleSelector } from '../../../redux/user/userSelectors';
import { requisitionApiService } from '../../../Services/RequisitionService';
import { Button } from '../../../Shared/buttons/Button';
import { If } from '../../../Shared/If';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { routes } from '../../../Utils/routes';
import { userRoles } from '../../../Utils/userRoles';
import { ReqDeclineModal } from './ReqDeclineModal';
import { getReqReturnRequest, getReqStatusLogRequest } from './reqDetailsUtils';
import { ReqReturnToVendorModal } from './ReqReturnToVendorModal';

interface ReqDetailsApprovalActionsProps {
  req: ApiRequisition;
  hospitalProductCategories: ProductCategoryStub[];
  isLoading: boolean;
}

const useStyles = createUseStyles({
  approvalActionButtons: {
    '& > :not(first-child)': {
      marginLeft: '1rem',
    },
  },
});

export const ReqDetailsApprovalActions: FC<ReqDetailsApprovalActionsProps> = ({ req, hospitalProductCategories, isLoading }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const utilClasses = useUtilityStyles();
  const styles = useStyles();
  const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false);
  const [showReturnModal, setShowReturnModal] = useState<boolean>(false);
  const currentUserRole = useSelector(currentUserRoleSelector);

  const handleApprovalSubmit = async (
    approvalSubmitFn: () => Promise<void>,
    setModalVisibleFn: (visible: boolean) => void,
    errorMsg: string,
    successMsg: string
  ) => {
    try {
      await approvalSubmitFn();
      dispatch(addNotification(NotificationType.success, successMsg));
      history.push(routes.requisitions.index);
    } catch (exception: any) {
      if (exception.message.includes('409')) {
        dispatch(addNotification(NotificationType.info, dictionary.REQ_DETAILS_ALREADY_ADDRESSED_ERR));
      } else if (exception.message.includes('422')) {
        dispatch(addNotification(NotificationType.error, dictionary.REQ_DETAILS_RETURN_TO_INVALID_VENDOR_ERR));
      } else {
        dispatch(addNotification(NotificationType.error, errorMsg));
      }
      setModalVisibleFn(false);
    }
  };

  const handleDeclineOrApproveClick = async (isApproval: boolean, declineReason?: string) => {
    const statusLogRequest = getReqStatusLogRequest(req, isApproval, declineReason);
    const errorMessage = !isApproval ? dictionary.REQ_DETAILS_DECLINE_ERROR : dictionary.REQ_DETAILS_APPROVAL_ERROR;
    const successMessage = !isApproval ? dictionary.REQ_DETAILS_DECLINE_SUCCESS : dictionary.REQ_DETAILS_APPROVAL_SUCCESS;
    const submitStatusLogRequest = (statusLogRequest.isApproval
      ? async () => requisitionApiService.approve(statusLogRequest)
      : async () => requisitionApiService.decline(statusLogRequest));

    await handleApprovalSubmit(submitStatusLogRequest, setShowDeclineModal, errorMessage, successMessage);
  };

  const declineOnClick = (declineReason: string) => handleDeclineOrApproveClick(false, declineReason);

  const handleClickApprove = () => {
    let hasError = false;
    const reqHasCostCenter = !!req.costCenter?.id;
    const userIsApprover = currentUserRole === userRoles.hospital.approver;
    const userAssumesBuyerRole = userIsApprover && req.status === RequisitionStatus.pendingBuyer;

    // Enforces rule that hospital buyers can approve without a cost center, but not anyone else (including OMI support users) when
    // the req is in the pending buyer status.
    if (!reqHasCostCenter && !userAssumesBuyerRole) {
      dispatch(addNotification(NotificationType.error, dictionary.REQ_DETAILS_COST_CENTER_REQUIRED_ERROR));
      hasError = true;
    }

    // const allReqProductsHaveAnItemId = (
    //   req.products.every(p => p.orderIdentifier !== null && p.orderIdentifier !== '') &&
    //   req.capitations.flatMap(cap => cap.products).every(p => p.orderIdentifier !== null && p.orderIdentifier !== '')
    // );

    // if there is some product(s) on the requisition with no item identifier,
    // and user is not a buyer, alert the user to add item identifiers to the products
    // if (!allReqProductsHaveAnItemId && !userAssumesBuyerRole) { // To Do: To Be Determined by business rules engine
    //   dispatch(addNotification(NotificationType.error, dictionary.REQ_DETAILS_ITEM_IDS_REQUIRED_ERROR));
    //   hasError = true;
    // }

    const productIsMissingCategory = (
      req.products.some(product => !product.productCategoryName) ||
      req.capitations.flatMap(cap => cap.products).some(product => !product.productCategoryName)
    );

    // If hospital has a Product Category and there is some product(s) on the requisition with no product category,
    // and user is not a buyer, alert the user to add product categories to the products
    if (hospitalProductCategories.length && productIsMissingCategory && req.status !== RequisitionStatus.pendingBuyer) {
      dispatch(addNotification(NotificationType.error, dictionary.REQ_DETAILS_PRODUCT_CATEGORIES_REQUIRED_ERROR));
      hasError = true;
    }

    if (!hasError) {
      handleDeclineOrApproveClick(true);
    }
  };

  const handleReturnClick = async (returnReason: string) => {
    const returnRequest = getReqReturnRequest(req, returnReason);
    const submitStatusLogRequest = async () => requisitionApiService.returnToVendor(req.id, returnRequest);

    await handleApprovalSubmit(submitStatusLogRequest, setShowReturnModal, dictionary.REQ_RETURN_ERROR, dictionary.REQ_RETURN_SUCCESS);
  };

  return (
    <If condition={req.canCurrentUserApprove}>
      <div className={joinArgs(utilClasses.flex, utilClasses.spaceBetween, styles.approvalActionButtons)}>
        <Button data-testid="decline-button" onClick={() => setShowDeclineModal(true)} buttonStyle="destructive">
          {dictionary.REQ_DETAILS_DECLINE}
        </Button>
        <Button data-testid="return-button" onClick={() => setShowReturnModal(true)} buttonStyle="reverse">
          {dictionary.REQ_DETAILS_RETURN_TO_VENDOR}
        </Button>
        <Button data-testid="approve-button" onClick={handleClickApprove} disabled={isLoading}>
          {dictionary.REQ_DETAILS_APPROVE}
        </Button>
      </div>

      <ReqDeclineModal isOpen={showDeclineModal} cancelOnClick={() => setShowDeclineModal(false)} declineOnClick={declineOnClick} />
      <ReqReturnToVendorModal isOpen={showReturnModal} cancelOnClick={() => setShowReturnModal(false)} onSubmit={handleReturnClick} />
    </If>
  );
};
