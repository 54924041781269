import { ReactElement } from 'react';
import useUtilityStyles from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import useFooterStyles from './footer.styles';

export default function Footer(): ReactElement {
  const classes = useFooterStyles();
  const utilClasses = useUtilityStyles();

  return (
    <footer className={joinArgs(utilClasses.trueCenter, utilClasses.backgroundGray)}>
      <div className={joinArgs(utilClasses.maxAppWidth, classes.footer)}>
        <section>
          &copy; Owens &amp; Minor
          {' '}
          {new Date().getFullYear()}
        </section>

        <section>
          Need Assistance:
          {' '}
          <a className={joinArgs(classes.telLink)} href="tel:+18777744488">Call 877-774-4488</a>
        </section>
      </div>
    </footer>
  );
}
