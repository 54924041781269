import { FC, useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { MergedRequisition, RequisitionCapitation } from '../../../../../Data/Requisition';
import RequisitionProduct from '../../../../../Data/RequisitionProduct';
import { dictionary } from '../../../../../dictionary';
import { TableHeaderRow } from '../../../../../Shared/ReactTable/TableHeaderRow';
import { useTableStyles } from '../../../../../Themes/table.styles';
import useUtilityStyles from '../../../../../Themes/utility.styles';
import { joinArgs } from '../../../../../Utils/arrayUtils';
import { useProductsToBillTableStyles } from '../productsToBill.styles';
import { ProductsToBillExpandRowButton } from '../shared/ProductsToBillExpandRowButton';
import { useReqCapitationTable } from './capitatedProductsTable.utils';
import { CapitationRow } from './CapitationRow';

export interface CapitatedProductsTableProps {
  editable: boolean;
  requisition: MergedRequisition;
  setProductInEditInfo: (info: [RequisitionProduct, number | undefined, number] | undefined) => void; // TODO: share between IndividualProductsTable and here
  hospHasProductCategories: boolean;
}

export const getRowKey = (cap: RequisitionCapitation, index: number): string => `${index}-${cap.capitationId}-${cap.id}-${cap.products.length}`;

export const CapitatedProductsTable: FC<CapitatedProductsTableProps> = ({ editable, requisition, setProductInEditInfo, hospHasProductCategories }) => {
  const classes = { ...useTableStyles(), ...useProductsToBillTableStyles() };
  const utilClasses = useUtilityStyles();
  const tableContext = useReqCapitationTable(editable, requisition);
  const [rowKeysFromPreviousRenders, setRowKeysFromPreviousRenders] = useState<string[]>([]);

  useEffect(() => {
    const capitations = requisition.capitations || [];
    const rowKeysToExpand = capitations.map((c, index) => getRowKey(c, index)).filter(key => !rowKeysFromPreviousRenders.includes(key));
    if (rowKeysToExpand.length) {
      setRowKeysFromPreviousRenders([...rowKeysFromPreviousRenders, ...rowKeysToExpand]);

      tableContext.toggleRowExpanded(rowKeysToExpand, true);
    }
  }, [tableContext, rowKeysFromPreviousRenders, requisition]);

  const onProductRowEditClick = useCallback((capitationIndex: number, productIndex: number) => {
    const capitations = requisition.capitations ?? [];
    // TODO: determine what, alongside productIndex, needs to be passed here
    setProductInEditInfo([capitations[capitationIndex].products[productIndex], capitationIndex, productIndex]);
  }, [requisition.capitations, setProductInEditInfo]);

  return (
    <>
      <Row>
        <Col xs={6}><h3>{dictionary.REQ_PRODUCTS_TO_BILL_CAP_TABLE_HEADER}</h3></Col>
        <Col xs={6} className={joinArgs(utilClasses.flex, utilClasses.flexEnd, utilClasses.alignCenter)}>
          <ProductsToBillExpandRowButton tableContext={tableContext} />
        </Col>
      </Row>
      <div className={classes.capitatedRoot}>
        <table {...tableContext.getTableProps()} className={joinArgs(classes.root, classes.ptbInsetRight)}>
          <thead>
            <TableHeaderRow headerGroups={tableContext.headerGroups} />
          </thead>
          <tbody {...tableContext.getTableBodyProps()}>
            {tableContext.rows.map((row, index) => {
              tableContext.prepareRow(row);
              const rowKey = getRowKey(row.original, index);
              return (
                <CapitationRow
                  key={rowKey}
                  tableContext={tableContext}
                  row={row}
                  editable={editable}
                  requisition={requisition}
                  index={index}
                  onEditClick={onProductRowEditClick}
                  hospHasProductCategories={hospHasProductCategories}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
