import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { ContractsIndex } from './ContractsIndex/ContractsIndex';
import { CreateContract } from './CreateContract/CreateContract';
import { ContractDetails } from './ContractDetails/ContractDetails';
import { EditContract } from './EditContract/EditContract';
import { UpdateContractProducts } from './UpdateContractProducts/UpdateContractProducts';
import { HospitalAdminRoute } from '../../Shared/ProtectedRoute/ProtectedRoute';

export const ContractsRoutes: FC = () => (
  <Switch>
    <Route exact path={routes.contracts.index} component={ContractsIndex} />
    <HospitalAdminRoute path={routes.contracts.create}>
      <CreateContract />
    </HospitalAdminRoute>
    <Route exact path={routes.contracts.detailsTemplate} component={ContractDetails} />
    <HospitalAdminRoute path={routes.contracts.editTemplate}>
      <Route exact path={routes.contracts.editTemplate} component={EditContract} />
    </HospitalAdminRoute>
    <HospitalAdminRoute path={routes.contracts.updateProductsTemplate}>
      <Route exact path={routes.contracts.updateProductsTemplate} component={UpdateContractProducts} />
    </HospitalAdminRoute>
  </Switch>
);
