import { User, UserBase, GetUsersResponse, CreateVendorUserRequest, CreateHospitalUserRequest, EditVendorUserRequest, EditHospitalUserRequest } from '../Data/User';
import { userRoles } from '../Utils/userRoles';
import apiClient from './apiClient';

export const usersUrl = '/users';
export const csrVendorsUrl = '/csr-vendors';
export const getVendorsForCSR = (
  hospitalId: number, departmentId: number, manufacturerId: number
): string => `${usersUrl}${csrVendorsUrl}/${hospitalId}/${departmentId}/${manufacturerId}`;
export const singleUserUrl = (id: number): string => `${usersUrl}/${id}`;
export const singleHospitalUserUrl = (id: number): string => `${usersUrl}/hospital-users/${id}`;
export const singleVendorUserUrl = (id: number): string => `${usersUrl}/vendors/${id}`;
const currentUserUrl = `${usersUrl}/me`;

export const verifyEmailUniqueUrl = `${usersUrl}/verifyEmailUnique`;

export const fetchUsers = async (roleFilter?: string): Promise<User[]> => {
  const requestConfig = { url: usersUrl };
  return apiClient.get<GetUsersResponse>(requestConfig, { role: roleFilter })
    .then(response => response.users);
};

export const fetchSingleHospitalUser = async (id: number): Promise<User> => {
  const requestConfig = { url: singleHospitalUserUrl(id) };
  return apiClient.get<User>(requestConfig);
};

export const fetchVendorsForCSR = async (hospitalId: number, departmentId: number, manufacturerId: number): Promise<UserBase[]> => {
  const requestConfig = { url: getVendorsForCSR(hospitalId, departmentId, manufacturerId) };
  return apiClient.get<UserBase[]>(requestConfig);
};

export const fetchSingleVendorUser = async (id: number): Promise<User> => {
  const requestConfig = { url: singleVendorUserUrl(id) };
  return apiClient.get<User>(requestConfig);
};

// this endpoint is only used by the dev tools page and will return 404 in any env other than dev/local
export const updateUser = async (user: User): Promise<any> => {
  const requestConfig = {
    url: currentUserUrl,
    data: JSON.stringify(user),
  };
  return apiClient.put(requestConfig);
};

export const userAcceptTermsOfUse = async (lastModified: Date): Promise<void> => {
  const requestConfig = {
    url: currentUserUrl,
    data: JSON.stringify({
      acceptedTermsOfUseTimestamp: lastModified,
    }),
  };
  return apiClient.patch(requestConfig);
};

export const createHospitalUser = async (userToCreate: CreateHospitalUserRequest): Promise<number> => {
  const requestConfig = {
    url: usersUrl,
    data: JSON.stringify({
      ...userToCreate,
      roleName: userRoles.hospital.approver,
    }),
  };
  return apiClient.post<number>(requestConfig);
};

export const createVendorUser = async (userToCreate: CreateVendorUserRequest): Promise<number> => {
  const requestConfig = {
    url: usersUrl,
    data: JSON.stringify({
      ...userToCreate,
      roleName: userRoles.vendor,
    }),
  };
  return apiClient.post<number>(requestConfig);
};

export const editVendorUser = async (updatedValues: EditVendorUserRequest): Promise<void> => {
  const requestConfig = {
    url: singleUserUrl(updatedValues.id),
    data: JSON.stringify({
      ...updatedValues,
      roleName: userRoles.vendor,
    }),
  };
  return apiClient.put<void>(requestConfig);
};

export const editHospitalUser = async (updatedValues: EditHospitalUserRequest): Promise<void> => {
  const requestConfig = {
    url: singleUserUrl(updatedValues.id),
    data: JSON.stringify({
      ...updatedValues,
      roleName: userRoles.hospital.approver,
    }),
  };
  return apiClient.put<void>(requestConfig);
};
