import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { procedureService } from '../../../Services/ProcedureService';
import { ProcedureStub, UpdateProcedureRequest } from '../../../Data/Procedure';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { dictionary } from '../../../dictionary';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../redux/initialState';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { routes } from '../../../Utils/routes';
import { EditProcedureForm } from './EditProcedureForm';
import LoadState from '../../../redux/loadState';
import { PageHeaderProps } from '../../../Shared/PageHeader/PageHeader';
import { ArchiveProcPageHeader } from './ArchiveProcPageHeader';

const ProcedureFormWrapper: FC<FormFlowStepComponent<UpdateProcedureRequest, ProcedureStub>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <EditProcedureForm FormActionsComponent={FormActionsComponent} procedure={editSource} onFormStepComplete={onFormStepComplete} />;

export const EditProcedure: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [procedure, setProcedure] = useState<ProcedureStub>();
  const [procedureLoadState, setProcedureLoadState] = useState(LoadState.loading);
  const params = useParams<{ id: string }>();
  const procedureId = Number(params.id!);

  useEffect(() => {
    (async () => {
      try {
        const fetchedProcedure = await procedureService.getProcedureById(procedureId);
        setProcedure(fetchedProcedure);
        setProcedureLoadState(LoadState.loaded);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.PROCEDURE_DETAILS_LOAD_ERROR));
        setProcedureLoadState(LoadState.error);
      }
    })();
  }, [dispatch, procedureId]);

  const PageHeaderComponent: FC<PageHeaderProps> = useMemo(() => () =>
    <ArchiveProcPageHeader procedure={procedure!} onSuccess={setProcedure} />,
  [procedure]);

  if (procedureLoadState === LoadState.loading) return <LoadingSpinner />;
  if (procedureLoadState === LoadState.error) return <ErrorPage />;

  const loadedProcedure = procedure!;
  const onComplete = async (procedureRequest: UpdateProcedureRequest) => {
    try {
      await procedureService.update(loadedProcedure.id, procedureRequest);
      history.push(routes.procedures.index);
      dispatch(addNotification(NotificationType.success, dictionary.EDIT_PROCEDURE_SUCCESS));
    } catch (e) {
      if (e.message.includes('409')) {
        dispatch(addNotification(NotificationType.error, dictionary.PROCEDURE_NOT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_PROCEDURE_ERROR));
      }
    }
  };

  return (
    <>
      <FormFlow<UpdateProcedureRequest, ProcedureStub>
        editSource={loadedProcedure}
        steps={[{ pageHeader: dictionary.PROCEDURES_EDIT_HEADER_LABEL, PageHeaderComponent, component: ProcedureFormWrapper }]}
        isViewOnly={procedure?.archived}
        completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
        completeButtonDisabled={procedure?.archived}
        onCancel={() => { history.push(routes.procedures.index); }}
        onComplete={onComplete}
      />
    </>
  );
};
