import { FC } from 'react';
import { MergedRequisition } from '../../../../Data/Requisition';
import { If } from '../../../../Shared/If';
import { HospitalContactNotification } from '../HospitalContactNotification';

interface ProductInfoNotificationsProps {
  requisition: MergedRequisition;
  showHospitalContactInfo: boolean;
}

export const ProductInfoNotifications: FC<ProductInfoNotificationsProps> = ({ requisition, showHospitalContactInfo = false }) => (
  <If condition={showHospitalContactInfo}>
    <HospitalContactNotification
      contactPhoneNumber={requisition.hospital?.contactPhoneNumber}
      contactEmail={requisition.hospital?.contactEmail}
      contactMessage={requisition.hospital?.contactMessage}
    />
  </If>
);
