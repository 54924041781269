import { createUseStyles } from 'react-jss';
import Colors from '../../Themes/colors';
import { fontWeights } from '../../Themes/fontWeights';

export const useDropdownStyles = createUseStyles({
  dropdownRoot: {
    zIndex: 1,
  },
  toggle: {
    position: 'relative',
    zIndex: 2,
    fontSize: '1rem',
  },
  listBoxContainer: {
    position: 'absolute',
    zIndex: 0,
    width: 'calc(100% - 0.125rem)',
    maxHeight: '16rem',
    fontWeight: fontWeights.normal,
    color: Colors.nearBlack,
    border: `1px solid ${Colors.darkGray}`,
    borderBottomLeftRadius: '0.25rem',
    borderBottomRightRadius: '0.25rem',
    backgroundColor: Colors.trueWhite,
    overflow: 'hidden',
    marginTop: '-1.5rem',
  },
  listBoxDirectionUp: {
    bottom: '1.5rem',
    borderRadius: 0,
    borderTopLeftRadius: '0.25rem',
    borderTopRightRadius: '0.25rem',
    marginBottom: '0.5rem',

    '& [role="listbox"]': {
      marginTop: 0,
      paddingTop: 0,
    },
  },
  listReset: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  listBox: {
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: Colors.trueWhite,
    padding: '.85rem',
    paddingBottom: 0,
    overflowY: 'auto',
    maxHeight: '15rem',
    marginTop: '.5rem',
  },
  listItem: {
    fontSize: '.875rem',
    padding: '.5rem',
    borderBottom: `1px solid ${Colors.darkGray}`,
    cursor: 'default',
    '&:last-child': {
      borderBottomColor: 'transparent',
      marginBottom: '.5rem',
    },
    '&:hover': {
      backgroundColor: Colors.blue,
      color: Colors.trueWhite,
      '& em': {
        color: Colors.trueWhite,
      },
    },
    '&.focused': {
      backgroundColor: Colors.blue,
      color: Colors.trueWhite,
      '& em': {
        color: Colors.trueWhite,
      },
    },
    '& em': {
      color: Colors.blue,
      fontStyle: 'normal',
    },
  },
  inputActions: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: '0.8rem',
    top: 0,
    bottom: 0,
    fill: Colors.nearBlack,
    color: Colors.nearBlack,
    zIndex: 2,
  },
  inputClearVisible: { paddingRight: '3rem !important' }, // overriding the override
  inputActionClear: {
    width: '1.375rem',
    height: '1.375rem',
    border: 0,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    cursor: 'default',
    '&:hover': {
      backgroundColor: Colors.nearWhite,
    },
  },
});
