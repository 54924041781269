import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useMemo } from 'react';
import { TableInstance, UsePaginationInstanceProps, UsePaginationState } from 'react-table';
import { dictionary } from '../../dictionary';
import useUtilityStyles from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import { Button } from '../buttons/Button';
import { Dropdown, DropdownOption } from '../Dropdown/Dropdown';

interface TablePagingControlsProps {
  tableContext: TableInstance<any> & UsePaginationInstanceProps<any>;
}

export const TablePagingControls: FC<TablePagingControlsProps> = ({ tableContext }) => {
  const utilClasses = useUtilityStyles();
  const containerClassName = joinArgs(utilClasses.flex, utilClasses.flexEnd, utilClasses.alignCenter, utilClasses.pr1, 'paging-controls');
  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state,
    page,
    data,
  } = tableContext;

  const { pageIndex, pageSize } = (state as UsePaginationState<any>);
  const onPageOptionChange = useCallback((val?: string) => { gotoPage(Number(val)); }, [gotoPage]);

  const pageRange: [number, number] = useMemo(() => {
    const pageNum = pageIndex + 1;
    const numberOfPreviousPages = pageNum - 1;
    const numberOfPreviousRecords = numberOfPreviousPages * pageSize;
    return [(numberOfPreviousRecords + 1), (numberOfPreviousRecords + page.length)];
  }, [page.length, pageIndex, pageSize]);

  const pageDropdownOptions: DropdownOption[] = useMemo(() => pageOptions.map(o => ({ value: o.toString(), displayName: (o + 1).toString() })), [pageOptions]);

  return (
    <div className={containerClassName} data-testid="paging-controls">
      <span className={joinArgs(utilClasses.mw30pct, utilClasses.textRight)}>{dictionary.TABLE_PAGING_ITEM_RANGE(pageRange[0], pageRange[1], data.length)}</span>
      <div className={joinArgs(utilClasses.flex, utilClasses.alignCenter, utilClasses.spaceBetween)}>
        <Button buttonStyle="icon" intent="secondary" onClick={() => previousPage()} disabled={!canPreviousPage} leadingIcon={<FontAwesomeIcon icon={faChevronLeft} />} />
        <span className={joinArgs(utilClasses.mw6r, utilClasses.trueCenter)}>
          {dictionary.TABLE_PAGING_PAGE_PROGRESS(pageIndex + 1, pageOptions.length)}
        </span>
        <Button buttonStyle="icon" intent="secondary" onClick={() => nextPage()} disabled={!canNextPage} leadingIcon={<FontAwesomeIcon icon={faChevronRight} />} />
      </div>
      <div className={utilClasses.w4r}>
        <Dropdown options={pageDropdownOptions} value={pageIndex.toString()} onChange={onPageOptionChange} disallowClear placeholder="" />
      </div>
    </div>
  );
};
