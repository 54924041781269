import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { HospMfctIdentifierResponse, HospMfctIdentifierUpdateRequest } from '../../../Data/ManufacturerIdentifier';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import LoadState from '../../../redux/loadState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { hospitalService } from '../../../Services/HospitalService';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { routes } from '../../../Utils/routes';
import { EditHospMfctIdentifierForm } from './EditHospMfctIdentifierForm';

export const EditHospMfctIdentifier: FC = () => {
  const { id: idParam } = useParams<{ id: string }>();
  const id = Number(idParam);
  const [identifier, setIdentifier] = useState<HospMfctIdentifierResponse>();
  const [identifierLoadState, setIdentifierLoadState] = useState<LoadState>(LoadState.loading);
  const history = useHistory();
  const routeToIndexPage = () => history.push(routes.hospitalManufacturerIdentifiers.index);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        const response = await hospitalService.getHospMfctIdentifierById(id);
        setIdentifier(response);
        setIdentifierLoadState(LoadState.loaded);
      } catch {
        setIdentifierLoadState(LoadState.error);
      }
    })();
  }, [id]);

  if (identifierLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }
  if (identifierLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  const onComplete = async (updateRequest: HospMfctIdentifierUpdateRequest) => {
    try {
      await hospitalService.updateHospMfctIdentifier(id, updateRequest);
      dispatch(addNotification(NotificationType.success, dictionary.EDIT_HOSPITAL_MANUFACTURER_ID_SUCCESS));
      routeToIndexPage();
    } catch {
      dispatch(addNotification(NotificationType.error, dictionary.EDIT_HOSPITAL_MANUFACTURER_ID_ERROR));
    }
  };

  return (
    <FormFlow<HospMfctIdentifierUpdateRequest, HospMfctIdentifierResponse>
      editSource={identifier}
      isViewOnly={!identifier?.canCurrentUserEdit}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      completeButtonDisabled={!identifier?.canCurrentUserEdit}
      onComplete={onComplete}
      onCancel={routeToIndexPage}
      steps={[{ component: EditHospMfctIdentifierForm, pageHeader: dictionary.EDIT_HOSPITAL_MANUFACTURER_ID_PAGE_HEADER }]}
    />
  );
};
