/* eslint-disable max-len */
import { FormikProps } from 'formik';
import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useSelector } from 'react-redux';
import { HospitalProductDetails } from '../../../../Data/HospitalProduct';
import { ProductCategoryStub } from '../../../../Data/ProductCategory';
import RequisitionProduct from '../../../../Data/RequisitionProduct';
import { dictionary } from '../../../../dictionary';
import { currentUserRoleSelector } from '../../../../redux/user/userSelectors';
import Autocomplete from '../../../../Shared/Autocomplete/Autocomplete';
import { FormFieldDescriptor } from '../../../../Shared/fields/formTypes';
import { SimpleDisplayField } from '../../../../Shared/fields/SimpleDisplayField';
import { SimpleFieldWithLabel } from '../../../../Shared/fields/SimpleFieldWithLabel';
import { If } from '../../../../Shared/If';
import useUtilityStyles from '../../../../Themes/utility.styles';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { displayPrice, formatPercentage } from '../../../../Utils/formatNumber';
import { userRoles } from '../../../../Utils/userRoles';
import { EditReqProductFields, getTranTypeString } from './editReqProduct.utils';
import { HospitalManufacturerContract } from '../../../../Data/Contract';

interface EditReqProductFormProps {
  reqProduct: RequisitionProduct;
  chosenContractIdentifier?: string;
  productIndex: number;
  capitationIndex: number;
  activeContracts: HospitalManufacturerContract[];
  hospitalProduct: HospitalProductDetails;
  formikProps: FormikProps<EditReqProductFields>;
  productCategories?: ProductCategoryStub[];
  editReqProductFields: Record<keyof EditReqProductFields, FormFieldDescriptor<EditReqProductFields>>;
  isProductCapitated: boolean;
}

export const EditReqProductForm: FC<EditReqProductFormProps> = (
  { reqProduct, chosenContractIdentifier, activeContracts, hospitalProduct, productCategories, formikProps, editReqProductFields, isProductCapitated }
) => {
  const utilClasses = useUtilityStyles();
  const userIsVendor = useSelector(currentUserRoleSelector) === userRoles.vendor;

  const handleContractChange = (val: any) => {
    formikProps.setFieldValue(editReqProductFields.contract.name, val);
    if (!val) {
      formikProps.setFieldValue(editReqProductFields.contractPrice.name, '0.00');
    } else {
      formikProps.setFieldValue(editReqProductFields.contractPrice.name, reqProduct.contractPrice);
    }
  };

  return (
    <Row className="input-form">
      <Col xs={6} className={joinArgs(utilClasses.backgroundNearWhite, utilClasses.px2)}>
        <h3>{dictionary.REQ_EDIT_PRODUCTS_PRODUCT_INFO_HEADER}</h3>
        <SimpleDisplayField label={dictionary.REQ_PRODUCT_CATALOG_NUM} value={hospitalProduct.catalogNumber} />
        <SimpleDisplayField label={dictionary.REQ_PRODUCT_DESCRIPTION} value={hospitalProduct.description} />
        <SimpleDisplayField label={dictionary.REQ_PRODUCT_BRAND} value={hospitalProduct.brandName} />
        <SimpleDisplayField label={dictionary.REQ_PRODUCT_UOM_FULL} value={hospitalProduct.unitOfMeasure} />
        <SimpleDisplayField label={dictionary.REQ_PRODUCT_QUANTITY_UOM_FULL} value={hospitalProduct.quantityUnitOfMeasure} />
        <If condition={!userIsVendor}>
          <Autocomplete allowClear direction="up" formikProps={formikProps} descriptor={editReqProductFields.contract} options={activeContracts} disabled={!activeContracts.length} onChange={handleContractChange} />
          <SimpleFieldWithLabel form={formikProps} descriptor={editReqProductFields.contractPrice} disabled={!chosenContractIdentifier} />
          <SimpleFieldWithLabel form={formikProps} descriptor={editReqProductFields.orderIdentifier} />
          <Autocomplete allowClear direction="up" formikProps={formikProps} descriptor={editReqProductFields.category} options={productCategories || []} />
        </If>
      </Col>
      <Col xs={6} className={utilClasses.px2}>
        <h3>{dictionary.REQ_EDIT_PRODUCTS_REQ_INFO_HEADER}</h3>
        <SimpleFieldWithLabel form={formikProps} descriptor={editReqProductFields.serialNumber} />
        <SimpleFieldWithLabel form={formikProps} descriptor={editReqProductFields.lotNumber} />
        <SimpleFieldWithLabel form={formikProps} descriptor={editReqProductFields.quantity} />
        <SimpleDisplayField label={dictionary.REQ_PRODUCT_TRANSACTION_TYPE} value={getTranTypeString(reqProduct)} />
        <SimpleDisplayField label={dictionary.REQ_PRODUCT_DISCOUNT} value={reqProduct.discount ? formatPercentage(Number(reqProduct.discount)) : ''} />
        <If condition={!isProductCapitated}>
          <SimpleFieldWithLabel form={formikProps} descriptor={editReqProductFields.price} />
        </If>

        <Row className={joinArgs(utilClasses.mt2, utilClasses.fs18, utilClasses.fwBold)}>
          <Col xs={6} className={utilClasses.textRight}>{dictionary.REQ_PRODUCT_SUBTOTAL}</Col>
          <Col xs={6} className={utilClasses.textRight}>{displayPrice(parseFloat(formikProps.values.price), parseFloat(formikProps.values.quantity), reqProduct.discount)}</Col>
        </Row>
      </Col>
    </Row>
  );
};
