import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

export interface DepartmentForTable {
  id: number;
  hospitalName: string;
  hospitalId: number;
  departmentName: string;
  archived: boolean;
}

export const departmentColumnProperties: ColumnProperty<DepartmentForTable>[] = [
  { displayName: dictionary.HOSPITAL_NAME_LABEL, name: 'hospitalName' },
  { displayName: dictionary.HOSPITAL_ID_LABEL, name: 'hospitalId' },
  { displayName: dictionary.DEPARTMENT_NAME_LABEL, name: 'departmentName' },
  { displayName: dictionary.DEPARTMENT_ID_LABEL, name: 'id' },
];
