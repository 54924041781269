import { dictionary } from '../../dictionary';
import { getSlashFormattedDate } from '../../Utils/dateUtils';
import { toCurrency } from '../../Utils/formatNumber';

export const renderSlashDateCell = (value?: string | null): string => getSlashFormattedDate(value) || dictionary.EMPTY_FIELD_MARK;

export const renderTruncatedCell = (truncateLength: number) => (value?: string | number | null): string => {
  const stringValue = value ? value.toString() : '';
  if (stringValue.length === 0) {
    return dictionary.EMPTY_FIELD_MARK;
  }
  if (stringValue.length > truncateLength) {
    return `${stringValue.substr(0, truncateLength)}…`;
  }
  return stringValue;
};

export function renderPriceCell(value: number | null): string {
  return ((value !== null)
    ? toCurrency(value)
    : dictionary.EMPTY_FIELD_MARK);
}
