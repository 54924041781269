import { createUseStyles } from 'react-jss';
import colors from '../../../../../Themes/colors';
import { fontWeights } from '../../../../../Themes/fontWeights';

export default createUseStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0.25rem 0.5rem',
    borderRadius: '0.25rem',
    backgroundColor: colors.red,
    color: colors.trueWhite,
  },
  percentMore: {
    fontWeight: fontWeights.bold,
    fontSize: '0.75rem',
    marginRight: '0.5rem',
    whiteSpace: 'nowrap',
  },
  required: {
    fontWeight: fontWeights.bold,
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
});
