import { FC } from 'react';
import { MergedRequisition } from '../../../Data/Requisition';
import { dictionary } from '../../../dictionary';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import { useUtilityStyles } from '../../../Themes/utility.styles';
import { getSlashFormattedDate } from '../../../Utils/dateUtils';
import { ReqPatientDisplayFields } from './ReqPatientDisplayFields';

interface RequisitionInfoDisplayProps {
  requisition: MergedRequisition;
}

export const ReqCasePatientInfoDisplay: FC<RequisitionInfoDisplayProps> = ({ requisition }) => {
  const utilityClasses = useUtilityStyles();

  return (
    <section className={`details-section ${utilityClasses.h100} ${utilityClasses.px2} ${utilityClasses.py1} ${utilityClasses.backgroundNearWhite}`}>
      <h2>{dictionary.REQ_CASE_INFO_HEADER}</h2>

      <ReqPatientDisplayFields patient={requisition.patient} />

      <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_CASE_NUMBER} value={requisition.caseNumber} />
      <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_CASE_DATE} value={getSlashFormattedDate(requisition.caseDate ?? '')} />
    </section>
  );
};
