import { createUseStyles } from 'react-jss';
import { colors } from '../../Themes/colors';

export const useCheckboxStyles = createUseStyles<any, { label?: string }>({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: props => (props.label ? undefined : 'auto'),
  },
  trailingLabelText: {
    marginLeft: '0.5rem',
  },
  leadingLabelText: {
    marginRight: '0.5rem',
    fontWeight: '400',
  },
  input: {
    zIndex: 1,
  },
  checkboxIcon: {
    position: 'relative',
    width: '1rem',
    height: '1rem',

    '& .svg-inline--fa, & input[type="checkbox"]': {
      position: 'absolute',
      left: 0,
      width: '100%',
      height: '100%',
    },
    '& input[type="checkbox"]': {
      opacity: 0,

      '& + .svg-inline--fa': {
        padding: '0.0625rem',
        border: `0.0625rem solid ${colors.gray}`,
        borderRadius: '0.125rem',
        backgroundColor: colors.trueWhite,
        color: colors.trueWhite,
      },
      '&:checked + .svg-inline--fa': {
        backgroundColor: colors.blue,
        borderColor: colors.blue,
        color: colors.trueWhite,
      },
      '&:focus + .svg-inline--fa, &:active + .svg-inline--fa': {
        outline: 'none',
        boxShadow: `0 0 0 0.125rem ${colors.kingfisher}`,
        borderColor: colors.blue,
      },
      '&:disabled + .svg-inline--fa, &:disabled:active + .svg-inline--fa': {
        backgroundColor: colors.disabled,
        color: colors.disabled,
        boxShadow: 'none',
        borderColor: colors.gray,
      },
    },
  },
});
