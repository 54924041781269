import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch } from 'react-redux';
import { DepartmentRequest, Department } from '../../../Data/Department';
import { DepartmentFormFields, departmentFormFields, validateCreateDepartmentForm, validateEditDepartmentForm } from './department.utils';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { NotificationType } from '../../../redux/initialState';
import { HospitalBase } from '../../../Data/Hospital';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { dictionary } from '../../../dictionary';
import { getFormikInitialValues } from '../../../Utils/formik.utils';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';
import { hospitalService } from '../../../Services/HospitalService';
import { If } from '../../../Shared/If';
import { DisplayField } from '../../../Shared/fields/DisplayField';

interface DepartmentFormProps extends FormFlowStepComponent<DepartmentRequest> {
  department?: Department;
}

export const DepartmentForm: FC<DepartmentFormProps> = (props) => {
  const { FormActionsComponent, onFormStepComplete, department } = props;
  const dispatch = useDispatch();
  const [hospitalBases, setHospitalBases] = useState<HospitalBase[]>([]);
  const sortedHospitals = hospitalBases.sort(alphanumericSortDescriptor('name', 1));

  const isEditing = !!department;

  useEffect(() => {
    (async () => {
      if (!isEditing && !hospitalBases.length) {
        try {
          const hospitalBasesResult = await hospitalService.getHospitalBases();
          setHospitalBases(hospitalBasesResult);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, isEditing, hospitalBases]);

  const formikProps = useFormik<DepartmentFormFields>({
    initialValues: getFormikInitialValues<DepartmentFormFields>(departmentFormFields, { name: department?.name }),
    validate: isEditing ? validateEditDepartmentForm : validateCreateDepartmentForm,
    onSubmit: (formValues) => {
      onFormStepComplete({
        name: formValues.name,
        hospitals: formValues.hospitals.map((h: HospitalBase) => h.id),
      });
    },
  });

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={4}>
          <If condition={!isEditing}>
            <Autocomplete
              multiple
              descriptor={departmentFormFields.hospitals}
              formikProps={formikProps}
              options={sortedHospitals}
              disabled={!hospitalBases.length}
            />
          </If>
          <If condition={isEditing}>
            <DisplayField
              label={departmentFormFields.hospitals.label}
              value={department?.hospital.name}
            />
          </If>
          <SimpleFieldWithLabel form={formikProps} descriptor={departmentFormFields.name} disabled={department?.archived} />
        </Col>
      </Row>

      <Row>
        <Col xs={4}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
