import { FC, ReactNode } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../buttons/Button';
import { dictionary } from '../../dictionary';
import { Modal } from './Modal/Modal';

interface ArchiveModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

type ArchiveModalGenerator = (header: string, bodyContent: ReactNode) => FC<ArchiveModalProps>;
export const createArchiveModalComponent: ArchiveModalGenerator = (header, bodyText) => ({ isOpen, onConfirm, onDismiss }) => (
  <Modal
    data-testid="archive-modal"
    title={header}
    isOpen={isOpen}
    icon={faInfoCircle}
    actionsContent={(
      <>
        <Button
          onClick={onDismiss}
          buttonStyle="reverse"
          data-testid="attention-stayonthispage"
        >
          {dictionary.CANCEL}
        </Button>
        <Button
          onClick={onConfirm}
          buttonStyle="primary"
          data-testid="attention-confirm"
        >
          {dictionary.CONTINUE}
        </Button>
      </>
    )}
  >
    {bodyText}
  </Modal>
);
