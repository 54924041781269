import { FC, useMemo } from 'react';
import { Row, TableInstance } from 'react-table';
import { dictionary } from '../../dictionary';
import useUtilityStyles from '../../Themes/utility.styles';
import { EmptyState } from '../EmptyState/EmptyState';
import { If } from '../If';
import { TableBodyRow } from './TableBodyRow';

interface TableBodyProps {
  tableContext: TableInstance<any>;
  noDataMessage: string;
  handleRowClick?: (data: any) => void;
  isLoading: boolean;
  rows: Row<any>[];
  toolTip?: string;
  toolTipDef?: string;
  expandTableContext?: any;
  expandTableExtraColSpan?: number;
}

export const TableBody: FC<TableBodyProps> = ({ tableContext, toolTip, toolTipDef, expandTableContext, expandTableExtraColSpan, rows, noDataMessage, isLoading, handleRowClick }) => {
  const emptyMsg = useMemo(() => (isLoading ? dictionary.TABLE_LOADING : noDataMessage), [isLoading, noDataMessage]);
  const utilClasses = useUtilityStyles();

  return (
    <tbody {...tableContext.getTableBodyProps()} data-testid="table-body">
      <If condition={isLoading || !rows.length}>
        <tr>
          <td colSpan={tableContext.visibleColumns.length} className={utilClasses.textCenter}>
            <EmptyState>
              {emptyMsg}
            </EmptyState>
          </td>
        </tr>
      </If>
      <If condition={!isLoading && !!rows.length}>
        {rows.map(row => {
          tableContext.prepareRow(row);
          return (
            <TableBodyRow
              key={row.id}
              row={row}
              toolTip={toolTip}
              toolTipDef={toolTipDef}
              expandTableContext={expandTableContext}
              expandTableExtraColSpan={expandTableExtraColSpan}
              data-testid={`row-${row.id}`}
              onClick={handleRowClick ? () => (handleRowClick(row.original)) : undefined}
            />
          );
        })}
      </If>
    </tbody>
  );
};
