import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { DepartmentEditRequest, Department } from '../../../Data/Department';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import LoadState from '../../../redux/loadState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { departmentService } from '../../../Services/DepartmentService';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { PageHeaderProps } from '../../../Shared/PageHeader/PageHeader';
import { routes } from '../../../Utils/routes';
import { DepartmentForm } from '../shared/DepartmentForm';
import { ArchiveDeptPageHeader } from './ArchiveDeptPageHeader';

const EditDepartmentFormWrapper: FC<FormFlowStepComponent<DepartmentEditRequest, Department>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <DepartmentForm FormActionsComponent={FormActionsComponent} department={editSource} onFormStepComplete={onFormStepComplete} />;

export const EditDepartment: FC = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const departmentId = Number(params.id);
  const [department, setDepartment] = useState<Department>();
  const [deptLoadState, setDeptLoadState] = useState(LoadState.loading);

  useEffect(() => {
    (async () => {
      try {
        const result = await departmentService.getDepartmentById(departmentId);
        setDepartment(result);
        setDeptLoadState(LoadState.loaded);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.DEPARTMENT_LOAD_ERR));
        setDeptLoadState(LoadState.error);
      }
    })();
  }, [dispatch, departmentId]);

  const onComplete = async (departmentRequest: DepartmentEditRequest) => {
    try {
      await departmentService.update(departmentId, departmentRequest);
      dispatch(addNotification(NotificationType.success, dictionary.EDIT_DEPARTMENT_SUCCESS));
      history.push(routes.departments.index);
    } catch (e) {
      const exception = e as AxiosError;
      if (exception?.response?.status === 409) {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_DEPARTMENT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_DEPARTMENT_ERROR));
      }
    }
  };

  const PageHeaderComponent: FC<PageHeaderProps> = useMemo(() => () =>
    <ArchiveDeptPageHeader department={department!} onSuccess={setDepartment} />,
  [department]);

  if (deptLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }

  if (deptLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  return (
    <>
      <FormFlow<DepartmentEditRequest, Department>
        editSource={department}
        onComplete={onComplete}
        onCancel={() => { history.push(routes.departments.index); }}
        isViewOnly={department?.archived}
        completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
        completeButtonDisabled={department?.archived}
        steps={[
          { pageHeader: dictionary.EDIT_DEPARTMENT_PAGE_HEADER, PageHeaderComponent, component: EditDepartmentFormWrapper },
        ]}
      />
    </>
  );
};
