import { faInfoCircle, faExclamationTriangle, faCheckCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import useNotificationStyles from './notifications.styles';
import { NotificationType } from '../../redux/initialState';
import { joinArgs } from '../../Utils/arrayUtils';

export default function useNotificationType(type: NotificationType): [string, IconDefinition] {
  const classes = useNotificationStyles();
  let typeClass;
  let typeIcon;

  switch (type) {
    case NotificationType.success: {
      typeClass = classes.success;
      typeIcon = faCheckCircle;
      break;
    }
    case NotificationType.error: {
      typeClass = classes.error;
      typeIcon = faExclamationTriangle;
      break;
    }
    case NotificationType.info: {
      typeClass = classes.info;
      typeIcon = faInfoCircle;
      break;
    }
    default: throw new Error('Unknown `type` given to Notification');
  }

  return [
    joinArgs(classes.base, typeClass),
    typeIcon,
  ];
}
