import { FormikErrors, FormikValues } from 'formik';
import { validateRequiredFields, validateCharacterLimit, validatePhoneNumber, validatePassword, validateEmailFormat } from '../../../Utils/validationUtils';
import { userFormFields } from './user.utils';

export const validateCreateOrEditUserForm = (values: FormikValues): FormikErrors<FormikValues> => {
  const { firstName, lastName, phoneNumber, password, email } = userFormFields;
  const nameLimit = 50;
  const emailLimit = 320;
  const emailFormatValidation = validateEmailFormat(values, email);

  return {
    ...validatePhoneNumber(values, phoneNumber),
    ...validateCharacterLimit(values, [firstName, lastName], nameLimit),
    ...validateCharacterLimit(values, [email], emailLimit),
    ...emailFormatValidation,
    ...validateRequiredFields(values, [firstName, lastName, phoneNumber]),
    ...validatePassword(values, password),
  };
};
