import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { HospMfctIdentifierStub } from '../../../../Data/ManufacturerIdentifier';
import { hospMfctIdentifiersIndexColumnProperties } from './hospMfctIdentifiersTable.utils';
import { routes } from '../../../../Utils/routes';

interface HospMfctIdentifiersForTableProps {
  hospitalManufacturerIds: HospMfctIdentifierStub[];
  columnProperties?: ColumnProperty<HospMfctIdentifierStub>[];
  isLoading: boolean;
}

export const HospMfctIdentifiersTable: FC<HospMfctIdentifiersForTableProps> = (props) => {
  const { hospitalManufacturerIds, isLoading } = props;
  const history = useHistory();
  const handleRowSelect = (item: HospMfctIdentifierStub) => history.push(routes.hospitalManufacturerIdentifiers.edit(item.id));

  return (
    <section>
      <SortableTable
        initialColumnSort="hospitalName"
        rowData={hospitalManufacturerIds}
        columns={hospMfctIdentifiersIndexColumnProperties}
        handleRowSelect={handleRowSelect}
        noDataMessage={dictionary.HOSPITAL_MANUFACTURER_ID_TABLE_EMPTY}
        isLoading={isLoading}
        tableTestId="hospital-manufacturer-ids-table"
      />
    </section>
  );
};
