import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createUseStyles } from 'react-jss';
import { FC } from 'react';
import { colors } from '../../../../Themes/colors';
import useUtilityStyles from '../../../../Themes/utility.styles';

const useBadgeStyles = createUseStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0.25rem 0.5rem',
    border: `0.0625rem solid ${colors.mirkwood}`,
    borderRadius: '0.25rem',
    backgroundColor: colors.orange,
    color: colors.nearBlack,
  },
});

const RequisitionProductPoNumberBadge: FC = () => {
  const classes = useBadgeStyles();
  const utilClasses = useUtilityStyles();

  return (
    <div className={classes.root}>
      <FontAwesomeIcon icon={faExclamationTriangle} className={utilClasses.mr1} />
      &mdash;
    </div>
  );
};

export const renderRequisitionProductPoNumber = (poNumber?: string | null): string | JSX.Element => poNumber || <RequisitionProductPoNumberBadge />;
