import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

export interface ProductCategoryForTable {
  id: number;
  healthSystemName: string;
  name: string;
  hospitalProductCategoryIdentifier: string;
}

export const productCategoryColumnProperties: ColumnProperty<ProductCategoryForTable>[] = [
  { displayName: dictionary.HEALTH_SYSTEM_NAME_LABEL, name: 'healthSystemName' },
  { displayName: dictionary.PRODUCT_CATEGORY_NAME_LABEL, name: 'name' },
  { displayName: dictionary.PRODUCT_CATEGORY_IDENTIFIER_NAME_LABEL, name: 'hospitalProductCategoryIdentifier' },
];
