export enum RequisitionHistoryAction {
  SubmittedForApproval = 1,
  RequisitionInformationEdited = 2,
  ProductInformationUpdated = 3,
  Approved = 4,
  Declined = 5,
  SubmittedForProductOrder = 6,
  ReceivedProductOrder = 7,
  Returned = 8,
}

export interface RequisitionHistoryNote {
  timestamp: Date;
  historyActionId: RequisitionHistoryAction;
  actionTaken: string;
  userRole?: string;
  user?: RequisitionHistoryNoteUser | null;
  comment: string;
}

export interface RequisitionHistoryNoteUser {
  firstName: string;
  lastName: string;
}
