import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { FC, useMemo, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { DepartmentBase, DepartmentStub } from '../../../../Data/Department';
import { ManufacturerBase } from '../../../../Data/Manufacturer';
import { hospitalUserRolesforHospitalUsers, hospitalUserRolesforVendorUsers, UserHospitalAssociation } from '../../../../Data/User';
import { dictionary } from '../../../../dictionary';
import Autocomplete from '../../../../Shared/Autocomplete/Autocomplete';
import { Button } from '../../../../Shared/buttons/Button';
import { SimpleDisplayField } from '../../../../Shared/fields/SimpleDisplayField';
import { If } from '../../../../Shared/If';
import { SubHeader } from '../../../../Shared/SubHeader/SubHeader';
import { TabInfo } from '../../../../Shared/Tabs/Tabs';
import useUtilityStyles from '../../../../Themes/utility.styles';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { getFormikInitialValues } from '../../../../Utils/formik.utils';
import { alphanumericSortDescriptor } from '../../../../Utils/SortUtils';
import { getUserRoleOption } from '../user.utils';
import { EditUserAssociationFields,
  getEditUserAssociationFields,
  validateEditHospitalUserAssociationForm,
  validateEditVendorUserAssociationForm } from './editUserAssociationModal.utils';
import { EditUserAssociationModalFormTable } from './EditUserAssociationModalFormTable';
import { currentUserSelector } from '../../../../redux/user/userSelectors';
import { userRoles } from '../../../../Utils/userRoles';

interface EditUserAssociationFormProps {
  userAssociation: UserHospitalAssociation;
  formikProps: FormikProps<EditUserAssociationFields>;
  setHighlightAssociationSectionError: (setHighlight: boolean) => void;
  departments: DepartmentStub[] | undefined;
  manufacturers: ManufacturerBase[] | undefined;
  isVendorUser: boolean;
  highlightAssociationSectionError: boolean;
}

export const EditUserAssociationModalForm: FC<EditUserAssociationFormProps> =
  ({ userAssociation, formikProps, departments, isVendorUser, manufacturers, setHighlightAssociationSectionError, highlightAssociationSectionError }) => {
    const utilClasses = useUtilityStyles();
    const [selectedDepartments, setSelectedDepartments] = useState<DepartmentBase[]>(userAssociation.departments);
    const [selectedManufacturers, setSelectedManufacturers] = useState<ManufacturerBase[]>(userAssociation.manufacturers);
    const [showDepartments, setShowDepartments] = useState(!isVendorUser);
    const sortedActiveDepartments = departments
      ?.filter(d => !d.archived && !selectedDepartments.some(selectedD => selectedD.id === d.id))
      ?.sort(alphanumericSortDescriptor('name', 1)) || [];
    const sortedActiveManufacturers = manufacturers
      ?.filter(m => !selectedManufacturers.some(selected => selected.id === m.id))
      ?.sort(alphanumericSortDescriptor('name', 1)) || [];
    const editUserAssociationFields = useMemo(() => getEditUserAssociationFields(isVendorUser), [isVendorUser]);
    const currentUser = useSelector(currentUserSelector);

    const handleUpdateDepartments = (updatedDeptList: DepartmentBase[]) => {
      setSelectedDepartments(updatedDeptList);
      formikProps.setValues({
        ...formikProps.values,
        [editUserAssociationFields.departments.name]: updatedDeptList,
      });
    };

    const handleUpdateManufacturers = (updatedManufacturerList: ManufacturerBase[]) => {
      setSelectedManufacturers(updatedManufacturerList);
      formikProps.setValues({
        ...formikProps.values,
        [editUserAssociationFields.manufacturers.name]: updatedManufacturerList,
      });
    };
    const onRemoveDepartment = (dept: DepartmentBase) => handleUpdateDepartments(selectedDepartments.filter(d => d.id !== dept.id));
    const onRemoveManufacturer = (manufacturer: ManufacturerBase) => handleUpdateManufacturers(selectedManufacturers.filter(m => m.id !== manufacturer.id));

    const validate = useMemo(() => (isVendorUser ? validateEditVendorUserAssociationForm : validateEditHospitalUserAssociationForm), [isVendorUser]);
    const initialValues = useMemo(() =>
      getFormikInitialValues(editUserAssociationFields, { userRole: userAssociation.hospitalUserRole ? getUserRoleOption(userAssociation.hospitalUserRole) : undefined }),
    [editUserAssociationFields, userAssociation.hospitalUserRole]);

    const addFormFormikProps = useFormik({
      initialValues,
      validate,
      onSubmit: (formValues, formikHelpers) => {
        const updatingManufacturer = isVendorUser && !showDepartments;
        const updatingDepartments = !isVendorUser || showDepartments;
        const updatedManufacturerList = updatingManufacturer ? [...selectedManufacturers, ...formValues.manufacturers] : selectedManufacturers;
        const updatedDeptList = updatingDepartments ? [...selectedDepartments, ...formValues.departments] : selectedDepartments;
        if (updatingManufacturer) {
          handleUpdateManufacturers(updatedManufacturerList);
          const preSelectedDepts = formValues.departments;
          formikHelpers.resetForm();
          formikHelpers.setFieldValue(editUserAssociationFields.departments.name, preSelectedDepts, false);
        } else {
          handleUpdateDepartments(updatedDeptList);
          const preSelectedManus = formValues.manufacturers;
          formikHelpers.resetForm();
          formikHelpers.setFieldValue(editUserAssociationFields.manufacturers.name, preSelectedManus, false);
        }

        formikProps.setValues({
          ...formikProps.values,
          [editUserAssociationFields.manufacturers.name]: updatedManufacturerList,
          [editUserAssociationFields.departments.name]: updatedDeptList,
        });

        if (updatedDeptList.length && (!isVendorUser || updatedManufacturerList.length)) {
          setHighlightAssociationSectionError(false);
        }
      },
    });

    const tableTabs: TabInfo[] = useMemo(() => [
      { title: dictionary.MANUFACTURER_TAB_LABEL, active: !showDepartments, onClick: () => setShowDepartments(false) },
      { title: dictionary.DEPARTMENT_TAB_LABEL, active: showDepartments, onClick: () => setShowDepartments(true) },
    ], [showDepartments]);

    return (
      <>
        <form className="input-form" onSubmit={addFormFormikProps.handleSubmit}>
          <Row>
            <Col xs={6} className={utilClasses.px2}>
              <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_HOSPITAL} value={userAssociation.hospitalName} />
              <Autocomplete
                formikProps={formikProps}
                descriptor={editUserAssociationFields.userRole}
                options={isVendorUser ? hospitalUserRolesforVendorUsers : hospitalUserRolesforHospitalUsers}
                disabled={!(currentUser?.roleName === userRoles.omiAdmin)}
              />
            </Col>
            <Col xs={6} className={joinArgs(utilClasses.backgroundNearWhite, utilClasses.px2, utilClasses.mt05, highlightAssociationSectionError ? utilClasses.errorBorder : '')}>
              <Row className={utilClasses.mt2}>
                <If condition={!isVendorUser || showDepartments}>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      id="edit-user-association-department"
                      data-testid="edit-user-association-department"
                      options={sortedActiveDepartments}
                      descriptor={editUserAssociationFields.departments}
                      formikProps={addFormFormikProps}
                    />
                  </Col>
                </If>
                <If condition={isVendorUser && !showDepartments}>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      id="edit-user-association-manufacturer"
                      data-testid="edit-user-association-manufacturer"
                      options={sortedActiveManufacturers}
                      descriptor={editUserAssociationFields.manufacturers}
                      formikProps={addFormFormikProps}
                    />
                  </Col>
                </If>
              </Row>
              <Row className={joinArgs(utilClasses.mt2, utilClasses.mb2)}>
                <Col xs={12} className={utilClasses.textRight}>
                  <Button
                    data-testid="edit-user-association-add-associations"
                    buttonStyle="reverse"
                    leadingIcon={<FontAwesomeIcon icon={faPlus} />}
                    type="submit"
                  >
                    {dictionary.FORM_CONTROL_LABEL_ADD_ASSOCIATIONS}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>

        <Row>
          <Col xs={12}>
            <SubHeader title={dictionary.FORM_DISPLAY_HOSPITAL_ASSOCIATIONS} tabs={isVendorUser ? tableTabs : undefined} />
            <If condition={!isVendorUser || showDepartments}>
              <EditUserAssociationModalFormTable
                rowData={selectedDepartments}
                onRemoveAssociation={onRemoveDepartment}
                displayName={dictionary.DEPARTMENT_TAB_LABEL}
                tableEmptyText={dictionary.USER_ASSOCIATIONS_EDIT_MODAL_DEPT_TABLE_EMPTY}
                removeRowText={dictionary.USER_ASSOCIATIONS_EDIT_MODAL_TABLE_ROW_REMOVE_DEPT}
              />
            </If>
            <If condition={isVendorUser && !showDepartments}>
              <EditUserAssociationModalFormTable
                rowData={selectedManufacturers}
                onRemoveAssociation={onRemoveManufacturer}
                displayName={dictionary.MANUFACTURER_TAB_LABEL}
                tableEmptyText={dictionary.USER_ASSOCIATIONS_EDIT_MODAL_MANU_TABLE_EMPTY}
                removeRowText={dictionary.USER_ASSOCIATIONS_EDIT_MODAL_TABLE_ROW_REMOVE_MANUFACTURER}
              />
            </If>
          </Col>
        </Row>
      </>
    );
  };
