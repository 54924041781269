import { FC } from 'react';
import { CellProps } from 'react-table';
import { dictionary } from '../../dictionary';
import { Button } from '../buttons/Button';

export const UpdateButtonCell: FC<CellProps<any>> = ({ row, submitRowData }) => (
  <>
    <Button
      key={`req-products-${row.id}`}
      type="submit"
      data-testid="update-action"
      onClick={() => submitRowData?.(row.original)}
    >
      {dictionary.REQ_EDIT_PRODUCT_MODAL_SUBMIT_BTN}
    </Button>
  </>
);
