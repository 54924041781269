import { ApprovalRoutingRequest } from '../../../Data/ApprovalRouting';
import User from '../../../Data/User';
import { Value } from '../../../Shared/Autocomplete/autocomplete.types';
import { ApprovalRoutingFormFields, Approver } from './ApprovalRoutingForm.types';

export const userToApprover = (user: User): Approver => ({
  ...user,
  name: `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim(),
});

export const findMatchingApprover = (options: User[], item: Value<Approver, boolean>): User[] => {
  if (item === null) {
    return [];
  }

  const approverIds = (Array.isArray(item) ? item : [item]).map(i => i && i.id);
  return options.filter(i => approverIds.includes(i.id));
};

export const getApprovers = (approvers: number[], approverOptions: User[]): User[] =>
  (approverOptions.length ?
    approvers.map(a => approverOptions.find(ao => ao.id === a)).filter(notEmpty) : []);

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const convertApprovalRoutingFormFieldsToApprovalRoutingRequest = (values: ApprovalRoutingFormFields): Partial<ApprovalRoutingRequest> => {
  const { department, firstApprovers, firstApproverThreshold, secondApprovers, secondApproverThreshold, thirdApprovers, buyerApprovers } = values;

  const approvalRoutingUpdateRequest: Partial<ApprovalRoutingRequest> = {
    departmentId: department?.id,
    buyerApprovers: buyerApprovers.map((user: User) => user.id),
    firstApprovers: firstApprovers.map((user: User) => user.id),
  };

  if (firstApproverThreshold > 0) {
    approvalRoutingUpdateRequest.firstApproverThreshold = Number(firstApproverThreshold);
  }

  const isSecondApproverDisabled = firstApproverThreshold <= 0;
  if (!isSecondApproverDisabled) {
    approvalRoutingUpdateRequest.secondApprovers = secondApprovers.map((user: User) => user.id);
    if (secondApproverThreshold > 0) {
      approvalRoutingUpdateRequest.secondApproverThreshold = Number(secondApproverThreshold);
    }
  }

  if (!(isSecondApproverDisabled || (secondApproverThreshold <= 0))) {
    approvalRoutingUpdateRequest.thirdApprovers = thirdApprovers.map((user: User) => user.id);
  }

  return approvalRoutingUpdateRequest;
};
