import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { dictionary } from '../../../dictionary';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { routes } from '../../../Utils/routes';
import { getEditRequisitionHeaderComponent } from '../shared/EditRequisitionHeader';
import { EditRequisitionFields, useEditRequisitionFormSubmitHandler } from './editRequisition.utils';
import { EditRequisitionFormWrapper } from './EditRequisitionFormWrapper';

export const EditRequisition: FC = () => {
  const { id } = useParams<{ id: string }>();
  const requisitionId = parseInt(id, 10);
  const history = useHistory();

  const goBackToReqDetails = () => history.push(routes.requisitions.detail(requisitionId));

  const HeaderComponent = getEditRequisitionHeaderComponent(requisitionId);
  const onComplete = useEditRequisitionFormSubmitHandler(requisitionId);

  return (
    <FormFlow<EditRequisitionFields>
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      onCancel={goBackToReqDetails}
      onComplete={onComplete}
      steps={[
        { pageHeader: dictionary.REQ_EDIT_HEADER, PageHeaderComponent: HeaderComponent, component: EditRequisitionFormWrapper },
      ]}
    />
  );
};
