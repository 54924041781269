import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { getNextId } from '../../Utils/idUtils';

export const Ie11FocusableElementFixer: FC = () => {
  const [previouslyFocusedElement, setPreviouslyFocusedElement] = useState<HTMLElement>(document.body);
  const [lastUpdated, setLastUpdated] = useState(getNextId());
  // @ts-ignore
  const isIE11 = useMemo(() => !!window.MSInputMethodContext && !!document.documentMode, []);

  const accumulatePriorityRefocusElements: ((element: HTMLElement | null, acc?: HTMLElement[]) => HTMLElement[]) = useCallback((element, acc = []) => {
    if (acc.length === 10 || !element || !element.parentElement) {
      return acc;
    }
    if (acc.length === 0 && element && element.previousElementSibling) {
      return accumulatePriorityRefocusElements(element, [element.previousElementSibling as HTMLElement]);
    }
    return accumulatePriorityRefocusElements(element.parentElement, [...acc, element.parentElement]);
  }, []);
  const priorityRefocusElements = useMemo(() => accumulatePriorityRefocusElements(previouslyFocusedElement), [accumulatePriorityRefocusElements, previouslyFocusedElement]);
  const refocusToHighestPriorityElementInDocument = useCallback(() => {
    if (!priorityRefocusElements.length) {
      document.body.focus();
      setPreviouslyFocusedElement(document.body);
    }
    const numberOfElementsToTry = priorityRefocusElements.length;
    let refocusElement = document.body;
    for (let i = 0; i < numberOfElementsToTry; i += 1) {
      const element = priorityRefocusElements[i];

      if (document.contains(element)) {
        refocusElement = element;
        break;
      }
    }
    refocusElement.focus();
    setPreviouslyFocusedElement(refocusElement);
  }, [priorityRefocusElements]);

  useEffect(() => {
    if (isIE11) {
      setTimeout(() => {
        if (document.activeElement !== null && document.activeElement !== previouslyFocusedElement) {
          setPreviouslyFocusedElement(document.activeElement as HTMLElement);
        }

        if (document.activeElement === null) {
          refocusToHighestPriorityElementInDocument();
        }
        setLastUpdated(getNextId());
      }, 50);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdated]);

  return null;
};
