import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { ArchivedRequisitionsIndex } from './ArchivedRequisitionsIndex/ArchivedRequisitionsIndex';
import { RequisitionDetails } from './ArchivedRequisitionDetail/RequisitionDetails';

export const ArchivedRequisitionsRoutes: FC = () => (
  <Switch>
    <Route exact path={routes.archivedRequisitions.index}>
      <ArchivedRequisitionsIndex />
    </Route>
    <Route exact path={routes.archivedRequisitions.detailTemplate}>
      <RequisitionDetails />
    </Route>
  </Switch>
);
