import { FC, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch } from 'react-redux';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { getFormikInitialValues } from '../../../Utils/formik.utils';
import useUtilityStyles from '../../../Themes/utility.styles';
import { PhysicianAddHospitalFormFields, PhysicianFormFields, physicianFormFields, validatePhysicianForm } from './physician.utils';
import { Physician, PhysicianHospital, PhysicianRequest } from '../../../Data/Physician';
import { dictionary } from '../../../dictionary';
import { PhysicianAddHospitals } from './PhysicianAddHospitals';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { PhysicianHospitalTable } from './PhysicianHospitalTable/PhysicianHospitalTable';
import { PhysicianHospitalsForTable } from './PhysicianHospitalTable/physicianHospitalTable.utils';

interface PhysicianFormProps extends FormFlowStepComponent<PhysicianRequest, Physician> {
  physician?: Physician;
}

export const PhysicianForm: FC<PhysicianFormProps> = ({ FormActionsComponent, onFormStepComplete, physician }) => {
  const [hospitals, setHospitals] = useState<PhysicianHospital[]>(physician?.hospitals ?? []);
  const [highlightAssociationSectionError, setHighlightAssociationSectionError] = useState(false);
  const dispatch = useDispatch();

  const formikInitialValues = useMemo(() => getFormikInitialValues<PhysicianFormFields>(physicianFormFields, { ...physician }), [physician]);
  const formikProps = useFormik<PhysicianFormFields>({
    initialValues: formikInitialValues,
    validate: validatePhysicianForm,
    onSubmit: physFields => {
      if (!hospitals.length) {
        setHighlightAssociationSectionError(true);
        dispatch(addNotification(NotificationType.error, dictionary.PHYSICIAN_NO_ASSOCIATIONS_ERROR));
      } else {
        setHighlightAssociationSectionError(false);
        onFormStepComplete({
          firstName: physFields.firstName,
          middleInitial: physFields.middleInitial,
          lastName: physFields.lastName,
          suffix: physFields.suffix,
          hospitals: hospitals.map(h => ({ hospitalId: h.id, hospitalPhysicianIdentifier: h.physicianIdentifier, departmentIds: h.departments.map(d => d.id) })),
        });
      }
    },
  });

  const addHospital = (hospital: PhysicianAddHospitalFormFields) => {
    setHighlightAssociationSectionError(false);
    setHospitals([...hospitals,
      { ...hospital, id: hospital.hospital.id, name: hospital.hospital.name, physicianIdentifier: hospital.hospitalPhysicianIdentifier } as PhysicianHospital]);
  };

  const editHospitalAssociation = (association: PhysicianHospital) => {
    const updatedList = hospitals.filter(ha => ha.id !== association.id);
    setHospitals([...updatedList, association]);
  };

  const removeHospitalAssociation = (association: PhysicianHospitalsForTable) => {
    const updatedList = hospitals.filter(ha => ha.id !== association.hospitalId);
    setHospitals(updatedList);
  };

  const utilClasses = useUtilityStyles();

  return (
    <form className="input-form" id="physician-form" onSubmit={formikProps.handleSubmit}>
      <Row className={utilClasses.mb2}>
        <Col xs={4}>
          <SimpleFieldWithLabel
            form={formikProps}
            descriptor={physicianFormFields.firstName}
            placeholder={dictionary.CREATE_PHYSICIAN_FIRST_NAME_PLACEHOLDER}
            disabled={physician?.archived}
          />
          <SimpleFieldWithLabel
            form={formikProps}
            descriptor={physicianFormFields.middleInitial}
            placeholder={dictionary.CREATE_PHYSICIAN_MIDDLE_INITIAL_PLACEHOLDER}
            disabled={physician?.archived}
          />
          <SimpleFieldWithLabel
            form={formikProps}
            descriptor={physicianFormFields.lastName}
            placeholder={dictionary.CREATE_PHYSICIAN_LAST_NAME_PLACEHOLDER}
            disabled={physician?.archived}
          />
          <SimpleFieldWithLabel
            form={formikProps}
            descriptor={physicianFormFields.suffix}
            placeholder={dictionary.CREATE_PHYSICIAN_SUFFIX_PLACEHOLDER}
            disabled={physician?.archived}
          />
        </Col>
        <Col xs={8} className={utilClasses.pl3}>
          <PhysicianAddHospitals
            addHospital={addHospital}
            addedHospitalIds={hospitals.map(h => h.id)}
            errorHighlight={highlightAssociationSectionError}
            archived={physician?.archived}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <h2>{dictionary.FORM_DISPLAY_HOSPITAL_ASSOCIATIONS}</h2>
          <PhysicianHospitalTable
            physicianHospitals={hospitals}
            archived={physician?.archived}
            onRemoveAssociation={removeHospitalAssociation}
            onEditAssociation={editHospitalAssociation}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
