import { FC, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../../../Shared/PageHeader/PageHeader';
import User from '../../../../Data/User';
import { fetchUsers } from '../../../../Services/UserService';
import { userRoles } from '../../../../Utils/userRoles';
import { dictionary } from '../../../../dictionary';
import { routes } from '../../../../Utils/routes';
import { HospitalUsersTable } from './HospitalUsersTable';
import { LinkButton } from '../../../../Shared/buttons/LinkButton';
import { currentUserIsAdminForAtLeastOneHospital } from '../../../../redux/user/userSelectors';
import { If } from '../../../../Shared/If';
import { TabInfo } from '../../../../Shared/Tabs/Tabs';

export const HospitalUsersIndex: FC = () => {
  const [hospitalUsers, setHospitalUsers] = useState<User[]>([]);
  const [showArchived, setShowArchived] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const onUserRowClick = (user: User) => history.push(routes.users.hospitals.edit(user.id));
  const showCreateButton = useSelector(currentUserIsAdminForAtLeastOneHospital);

  useEffect(() => {
    (async (): Promise<void> => {
      const fetchedUsers: User[] = await fetchUsers(userRoles.hospital.approver);
      if (fetchedUsers) {
        setHospitalUsers(fetchedUsers);
        setIsLoading(false);
      }
    })();
  }, []);

  const filteredUsers = hospitalUsers.filter((u) => u.archived === showArchived);

  const usersTabs: TabInfo[] = useMemo(() => [
    { title: dictionary.ACTIVE_TAB_LABEL, active: !showArchived, onClick: () => setShowArchived(false) },
    { title: dictionary.DEACTIVATED_TAB_LABEL, active: showArchived, onClick: () => setShowArchived(true) },
  ], [showArchived]);

  return (
    <>
      <PageHeader title={dictionary.HOSPITAL_USERS} tabs={usersTabs}>
        <If condition={showCreateButton}>
          <LinkButton to={routes.users.hospitals.create} data-testid="create-hospital-user">
            {dictionary.CREATE_NEW_HOSPITAL_USER}
          </LinkButton>
        </If>
      </PageHeader>

      <HospitalUsersTable users={filteredUsers} isLoading={isLoading} onRowClick={onUserRowClick} />
    </>
  );
};
