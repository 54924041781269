import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Row, TableInstance, UseExpandedRowProps } from 'react-table';
import ReactTooltip from 'react-tooltip';
import { dictionary } from '../../dictionary';
import { useUtilityStyles } from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import { If } from '../If';
import { TableHeaderRow } from './TableHeaderRow';

interface TableRowProps {
  row: Row<any>;
  extraColSpan?: number;
  expandTableContext?: TableInstance<any>;
  expandTableExtraColSpan?: number;
  ['data-testid']?: string;
  expandRowInset?: boolean;
  onClick?: () => void;
  rowStyle?: string;
  toolTip?: string;
  toolTipDef?: string;
}

const useTableRowStyles = createUseStyles({
  expandRow: {
    '& > td': {
      paddingTop: 0,
    },
  },
});

export const TableBodyRow: FC<TableRowProps> = (props) => {
  const {
    row: uncastRow,
    extraColSpan = 0,
    expandTableContext,
    'data-testid': dataTestId = '',
    expandRowInset = false,
    toolTip,
    toolTipDef,
    onClick,
    rowStyle,
  } = props;

  const row = uncastRow as Row<any> & UseExpandedRowProps<any>;
  const utilClasses = useUtilityStyles();
  const rowClass = joinArgs(
    !row.isExpanded ? utilClasses.tableBottomBorder : '',
    onClick ? 'hasOnClick' : '',
  );
  const expandDataTestId = dataTestId ? `${dataTestId}-expand` : '';

  return (
    <>
      <tr
        {...row.getRowProps({ className: rowClass })}
        style={{ backgroundColor: rowStyle }}
        data-testid={dataTestId}
        onClick={onClick}
      >
        {row.cells.map((cell, index) => (
          <td {...cell.getCellProps({ className: cell.column.className, width: cell.column.width, colSpan: cell.column.colSpan })}>
            <Cell id={`${cell.row.original.id}-${index}`} cell={cell} row={row} toolTip={toolTip} toolTipDef={toolTipDef} />
          </td>
        ))}
        <If condition={extraColSpan > 0}>
          <td colSpan={extraColSpan}>&nbsp;</td>
        </If>
      </tr>
      <If condition={!!expandTableContext && !!row.isExpanded}>
        <ExpandRow expandTableContext={expandTableContext!} outerTableColCount={row.allCells.length} data-testid={expandDataTestId} inset={expandRowInset} />
      </If>
    </>
  );
};

interface ExpandRowProps {
  expandTableContext: TableInstance<any>;
  outerTableColCount: number;
  expandTableExtraColSpan?: number;
  ['data-testid']: string;
  inset?: boolean;
}

const ExpandRow: FC<ExpandRowProps> = (props) => {
  const {
    expandTableContext,
    outerTableColCount,
    expandTableExtraColSpan,
    'data-testid': dataTestId,
    inset = false,
  } = props;
  const utilClasses = useUtilityStyles();
  const classes = useTableRowStyles();
  const expandTableDataRowClass = joinArgs(utilClasses.tableBottomBorder);
  const expandTableHeadGroups = expandTableContext?.headerGroups ?? undefined;

  return (
    <tr className={joinArgs(classes.expandRow, expandTableDataRowClass, 'expand-row')} data-testid={dataTestId}>
      <td colSpan={outerTableColCount} className={inset ? 'insetRight' : ''}>
        <table {...expandTableContext.getTableBodyProps()} className={joinArgs(utilClasses.backgroundNearWhite, utilClasses.w100)}>
          <thead>
            <TableHeaderRow headerGroups={expandTableHeadGroups!} extraColSpan={expandTableExtraColSpan} />
          </thead>
          <tbody>
            {
              expandTableContext.rows.map(row => {
                expandTableContext.prepareRow(row);
                return <TableBodyRow key={`expand-row-${row.id}`} row={row} extraColSpan={expandTableExtraColSpan} />;
              })
            }
          </tbody>
        </table>
      </td>
    </tr>
  );
};

const Cell: FC<any> = (props) => {
  const { id, cell, row, toolTip = 'catalogNumber', toolTipDef = dictionary.REQ_PRODUCTS_TO_BILL_ID_TOOLTIP } = props;
  const utilClasses = useUtilityStyles();
  const includeTooltip = cell.column.id === toolTip;
  return (
    <>
      <div data-tip={includeTooltip} data-for={id}>
        {cell.render('Cell')}
      </div>
      <If condition={includeTooltip}>
        <ReactTooltip id={id} place="bottom" effect="solid" className={utilClasses.fwBold}>
          {`${toolTipDef} ${row.original.id}`}
        </ReactTooltip>
      </If>
    </>
  );
};
