import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Column, TableInstance, useRowSelect, useTable, TableOptions, UseRowSelectOptions, UseRowSelectInstanceProps } from 'react-table';
import { SubCostCenterStub } from '../../../../Data/SubCostCenter';
import { dictionary } from '../../../../dictionary';
import { CheckboxCell } from '../../../../Shared/tables/CheckboxCell';
import { getActionsCellComponent } from '../../../../Shared/tables/getActionsCellComponent';
import { buildTableCols } from '../../../../Shared/tables/tableUtils';
import { getNextId } from '../../../../Utils/idUtils';
import { useSubCostCenterTableStyles } from './subCostCenterTable.styles';

export interface SubCostCenterForTable extends Omit<SubCostCenterStub, 'id'> {
  subCostCenterId?: number;
  id: number;
}

type UngroupedSubCCOptions = TableOptions<SubCostCenterForTable> & UseRowSelectOptions<SubCostCenterForTable>;

export const getNextSubCostCenterId = (subs: {id?: number}[]): number => getNextId(...subs.map(s => s.id ?? 0));

export const useSubCostCenterTable = (
  subCostCenters: SubCostCenterForTable[],
  selectable: boolean,
  userIsAllowedToEdit: boolean,
  onRemoveClick: (rowIdx: number) => void,
): TableInstance<SubCostCenterForTable> & UseRowSelectInstanceProps<SubCostCenterForTable> => {
  const subCCTableStyles = useSubCostCenterTableStyles();
  const tableSubs = subCostCenters as SubCostCenterForTable[];
  const memoizedSubs = useMemo(() => tableSubs.reverse(), [tableSubs]);
  const getRowId = useMemo(() => (subCC: SubCostCenterForTable) => subCC.id?.toString() || '', []);

  const columns: Column<SubCostCenterForTable>[] = useMemo(() => {
    const ActionsCell = getActionsCellComponent([
      {
        keep: userIsAllowedToEdit,
        key: 'sccRemove',
        intent: 'danger',
        title: dictionary.SUB_COST_CENTER_REMOVE_BTN,
        icon: faTrash,
        action: (cellProps) => onRemoveClick(+cellProps.row.id),
      },
    ]);

    const cols = buildTableCols<SubCostCenterForTable>([
      ...(selectable ? [{ id: 'selection', className: subCCTableStyles.selectColumnCell, Cell: CheckboxCell, width: '5%' }] : []),
      { Header: dictionary.SUB_COST_CENTER_FORM_NAME, accessor: 'name' },
      { Header: dictionary.SUB_COST_CENTER_FORM_IDENTIFIER, accessor: 'hospitalSubCostCenterIdentifier' },
      ...(!selectable ? [{ id: 'sccActions', Cell: ActionsCell, width: '5%' }] : []),
    ]);

    return cols;
  }, [userIsAllowedToEdit, selectable, subCCTableStyles.selectColumnCell, onRemoveClick]);

  const ungroupedTableOptions: UngroupedSubCCOptions = { data: memoizedSubs, columns, getRowId, autoResetSelectedRows: true };

  return useTable<SubCostCenterForTable>(
    ungroupedTableOptions,
    useRowSelect,
  ) as TableInstance<SubCostCenterForTable> & UseRowSelectInstanceProps<SubCostCenterForTable>;
};
