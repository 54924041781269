import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HospitalAdminRoute } from '../../Shared/ProtectedRoute/ProtectedRoute';
import { routes } from '../../Utils/routes';
import { CapitationsIndex } from './CapitationsIndex/CapitationsIndex';
import { CreateCapitation } from './CreateCapitation/CreateCapitation';
import { EditCapitation } from './EditCapitation/EditCapitation';

export const CapitationsRoutes: FC = () => (
  <Switch>
    <Route exact path={routes.capitations.index}>
      <CapitationsIndex />
    </Route>
    <HospitalAdminRoute exact path={routes.capitations.new}>
      <CreateCapitation />
    </HospitalAdminRoute>
    <Route exact path={routes.capitations.editTemplate}>
      <EditCapitation />
    </Route>
  </Switch>
);
