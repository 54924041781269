import { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSortedRows, { SortValues } from './useSortedRows';
import { useSortableTableStyles } from './sortableTable.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import useUtilityClasses from '../../Themes/utility.styles';
import { hasOwnProperty } from '../../Utils/objectUtils';
import { dictionary } from '../../dictionary';
import { Button } from '../buttons/Button';
import { ColumnProperty, RowAction } from './SortableTable.types';
import { SortableTableHeaderCell } from './SortableTableHeaderCell';
import { If } from '../If';
import { EmptyState } from '../EmptyState/EmptyState';

export type RequiredKeys = { id: number | string };

function isId(obj: RequiredKeys): obj is RequiredKeys {
  return hasOwnProperty(obj, 'id');
}

export function getRowId(o: RequiredKeys): number | string {
  if (isId(o)) return o.id;

  throw new Error('Tried to get identifier that does not exist');
}
export interface SortableTableProps<T extends RequiredKeys> {
  rowData: T[];
  columns: ColumnProperty<T>[];
  initialColumnSort: keyof T;
  noDataMessage: string;
  isLoading: boolean;
  defaultSortDirection?: SortValues;
  handleRowSelect?: (rowItem: T) => void;
  rowActions?: RowAction<T>[];
  selectedRowIds?: (number | string)[];
  className?: string;
  tableTestId?: string;
  archived?: boolean;
  rowActionsWidth?: string;
  shouldRenderRowActions?: (rowItem: T) => boolean;
}

export const SortableTable = <T extends RequiredKeys>({
  rowData,
  columns,
  initialColumnSort,
  noDataMessage,
  isLoading,
  defaultSortDirection = 'asc',
  handleRowSelect,
  rowActions = [],
  selectedRowIds = [],
  className,
  tableTestId,
  rowActionsWidth,
  shouldRenderRowActions,
}: SortableTableProps<T>): ReactElement => {
  const classes = useSortableTableStyles();
  const utilityClasses = useUtilityClasses();
  const [sortedRows, sortDirection, sortField, handleSort] = useSortedRows<T>(rowData, defaultSortDirection, initialColumnSort);
  const sectionClasses = joinArgs(
    classes.container,
    className,
  );

  return (
    <section className={sectionClasses}>
      <If condition={!isLoading && !!sortedRows.length}>
        <table className={classes.tableRoot} data-testid={tableTestId ?? 'sortable-table'}>
          <thead>
            <tr>
              {columns.map((col) => (
                <SortableTableHeaderCell<T>
                  key={`sortable-table-head-${col.name}`}
                  column={col}
                  handleSort={handleSort}
                  sortDirection={sortDirection}
                  sortField={sortField}
                />
              ))}
              <If condition={!!rowActions.length}>
                <th style={{ width: rowActionsWidth || 'auto' }}>{dictionary.NBSP}</th>
              </If>
            </tr>
          </thead>

          <tbody data-testid="sortable-table-body">
            {sortedRows !== 'loading' &&
                sortedRows.map((row, rowIndex) => (
                  <tr
                    data-testid={`row-${getRowId(row)}`}
                    className={joinArgs(
                      classes.rowBody,
                      handleRowSelect ? classes.rowBodyInteractive : '',
                      selectedRowIds.includes(row.id) ? 'selectedRow' : '',
                    )}
                    key={`sortable-table-body-row-${getRowId(row)}`}
                    onClick={() => (handleRowSelect ? handleRowSelect(row) : {})}
                    onKeyDown={({ key }) => (key === 'Enter' && handleRowSelect ? handleRowSelect(row) : null)}
                    {...(handleRowSelect ? { tabIndex: 0 } : {})}
                  >
                    {columns.map(({ name: columnName, align = 'left', className: colClassName = '', colRenderCell }) =>
                      (
                        <td
                          className={joinArgs(
                            classes.paddedCell,
                            colClassName,
                            align === 'left' ? utilityClasses.textLeft : utilityClasses.textRight,
                          )}
                          data-testid={`sortable-table-cell-${getRowId(row)}-${columnName}`}
                          key={`sortable-table-body-cell-${getRowId(row)}-${columnName}`}
                        >
                          {
                            colRenderCell
                              ? colRenderCell(row[columnName])
                              : row[columnName] ?? dictionary.EMPTY_FIELD_MARK
                          }
                        </td>
                      ))}
                    <If condition={!!rowActions.length}>
                      <td className={utilityClasses.textRight}>
                        <If condition={!shouldRenderRowActions || shouldRenderRowActions(row)}>
                          {rowActions.map(rowAction => (
                            <Button
                              key={rowAction.name}
                              buttonStyle="text"
                              intent={rowAction.intent ?? 'primary'}
                              onClick={() => rowAction.onAction(row, rowIndex)}
                              tooltipTitle={rowAction.name}
                            >
                              <FontAwesomeIcon icon={rowAction.icon} />
                            </Button>
                          ))}
                        </If>
                      </td>
                    </If>
                  </tr>
                ))}
          </tbody>
        </table>
      </If>

      <If condition={isLoading}>
        <div className={joinArgs(classes.loadingState, utilityClasses.py1)}>
          {dictionary.TABLE_LOADING}
        </div>
      </If>

      <If condition={!isLoading && !sortedRows.length}>
        <EmptyState>
          {noDataMessage}
        </EmptyState>
      </If>
    </section>
  );
};
