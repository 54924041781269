import { createUseStyles } from 'react-jss';
import colors from '../../../Themes/colors';

export const casePatientInfoStyles = createUseStyles({
  patientInfo: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.nearWhite,
    padding: '0.25rem 2rem 2rem 2rem',
    marginTop: '1rem',
  },
});
