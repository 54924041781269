import { AxiosError } from 'axios';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Physician, PhysicianRequest, PhysicianUniquenessError } from '../../../Data/Physician';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import LoadState from '../../../redux/loadState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { physicianService } from '../../../Services/PhysicianService';
import { PageHeaderProps } from '../../../Shared/PageHeader/PageHeader';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { routes } from '../../../Utils/routes';
import { PhysicianForm } from '../shared/PhysicianForm';
import { ArchivePhysicianPageHeader } from './ArchivePhysicianPageHeader';

const PhysicianFormWrapper: FC<FormFlowStepComponent<PhysicianRequest, Physician>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <PhysicianForm FormActionsComponent={FormActionsComponent} onFormStepComplete={onFormStepComplete} physician={editSource} />;

export const EditPhysician: FC = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const physicianId = Number(params.id);
  const [physician, setPhysician] = useState<Physician>();
  const [physicianLoadState, setPhysicianLoadState] = useState(LoadState.loading);

  useEffect(() => {
    (async () => {
      try {
        const result: Physician = await physicianService.getPhysicianById(physicianId);
        setPhysician(result);
        setPhysicianLoadState(LoadState.loaded);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.PHYSICIAN_FETCH_ERROR));
        setPhysicianLoadState(LoadState.error);
      }
    })();
  }, [dispatch, physicianId]);

  const PageHeaderComponent: FC<PageHeaderProps> = useMemo(() => () =>
    <ArchivePhysicianPageHeader physician={physician!} onSuccess={setPhysician} />,
  [physician]);

  if (physicianLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }
  if (physicianLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  const onCancel = () => history.push(routes.physicians.index);
  const onComplete = async (request: PhysicianRequest) => {
    try {
      await physicianService.update(physicianId, request);
      dispatch(addNotification(NotificationType.success, dictionary.EDIT_PHYSICIAN_SUCCESS));
      history.push(routes.physicians.index);
    } catch (exception) {
      // eslint-disable-next-line prefer-destructuring
      const response = (exception as AxiosError).response;
      if (response?.status === 409 && response?.data.message.includes(request.lastName)) {
        const uniquePhysicianError = response?.data.message;
        dispatch(addNotification(NotificationType.error, uniquePhysicianError));
      } else if (response?.status === 409) {
        const uniqueErr: PhysicianUniquenessError = response?.data;
        dispatch(addNotification(NotificationType.error, dictionary.PHYSICIAN_NOT_UNIQUE_ERROR(uniqueErr?.hospitalNames || [])));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_PHYSICIAN_ERROR));
      }
    }
  };

  return (
    <FormFlow<PhysicianRequest, Physician>
      editSource={physician}
      onComplete={onComplete}
      onCancel={onCancel}
      isViewOnly={physician?.archived}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      completeButtonDisabled={physician?.archived}
      steps={[{ pageHeader: dictionary.EDIT_PHYSICIAN_PAGE_HEADER, PageHeaderComponent, component: PhysicianFormWrapper }]}
    />
  );
};
