import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { ProceduresIndex } from './ProceduresIndex/ProceduresIndex';
import { CreateProcedure } from './CreateProcedure/CreateProcedure';
import { EditProcedure } from './EditProcedure/EditProcedure';
import { HospitalAdminRoute } from '../../Shared/ProtectedRoute/ProtectedRoute';

export const ProceduresRoutes: FC = () => (
  <Switch>
    <Route exact path={routes.procedures.index}>
      <ProceduresIndex />
    </Route>
    <HospitalAdminRoute exact path={routes.procedures.create}>
      <CreateProcedure />
    </HospitalAdminRoute>
    <Route exact path={routes.procedures.editTemplate}>
      <EditProcedure />
    </Route>
  </Switch>
);
