import { FC } from 'react';
import { RequisitionStatus } from '../../Data/RequisitionStatus';
import useBadgeStyles from '../../Themes/status-badge.styles';
import { StatusBadge } from '../StatusBadge/StatusBadge';

interface ReqStatusBadgeProps {
  status: string;
  large?: boolean;
}

export const ReqStatusBadge: FC<ReqStatusBadgeProps> = ({ status, large = false }) => {
  const classes = useBadgeStyles();

  const getColorClass = (statusText: string) => {
    switch (statusText) {
      case RequisitionStatus.declined:
      case RequisitionStatus.approvedWithExceptions:
      case RequisitionStatus.draft:
        return classes.danger;
      case RequisitionStatus.returned:
        return classes.warning;
      case RequisitionStatus.approved:
      case RequisitionStatus.waitingForPo:
      case RequisitionStatus.completed:
        return classes.success;
      case RequisitionStatus.archived:
        return classes.info;
      default:
        return classes.info;
    }
  };

  return (
    <StatusBadge status={status} large={large} getBadgeColorClass={getColorClass} />
  );
};
