import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProcedureStub, UpdateProcedureRequest } from '../../../Data/Procedure';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { procedureService } from '../../../Services/ProcedureService';
import { ArchiveButton } from '../../../Shared/buttons/ArchiveButton';
import { createArchiveModalComponent } from '../../../Shared/modals/ArchiveModal';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';

interface ArchiveProcPageHeaderProps {
  procedure: ProcedureStub;
  onSuccess: (updatedProc: ProcedureStub) => void;
}

export const ArchiveProcPageHeader: FC<ArchiveProcPageHeaderProps> = ({ procedure, onSuccess }) => {
  const dispatch = useDispatch();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const toggleArchived = useCallback(async (procedureRequest: UpdateProcedureRequest) => {
    const procRequest = { ...procedureRequest, archived: !procedure?.archived };

    try {
      await procedureService.update(procedure.id, procRequest);
      if (!procedure?.archived) {
        dispatch(addNotification(NotificationType.success, dictionary.ARCHIVE_PROCEDURE_SUCCESS));
      } else {
        dispatch(addNotification(NotificationType.success, dictionary.ACTIVATE_PROCEDURE_SUCCESS));
      }

      onSuccess({ ...procedure!, archived: procRequest.archived });
    } catch (e) {
      dispatch(addNotification(NotificationType.error, dictionary.ARCHIVE_PROCEDURE_ERROR));
    }
  }, [procedure, dispatch, onSuccess]);

  const openArchiveModal = () => setIsArchiveModalOpen(true);
  const closeArchiveModal = () => setIsArchiveModalOpen(false);

  const toggleArchivedAndCloseModal = useCallback(() => {
    toggleArchived(procedure!);
    closeArchiveModal();
  }, [procedure, toggleArchived]);

  const archiveBtnText = useMemo(() => (procedure?.archived ? dictionary.PROCEDURE_ACTIVATE_BTN : dictionary.PROCEDURE_ARCHIVE_BTN), [procedure?.archived]);
  const archiveBtnOnClick = useMemo(() => (procedure?.archived ? toggleArchivedAndCloseModal : openArchiveModal), [procedure?.archived, toggleArchivedAndCloseModal]);
  const ArchiveProcedureModal = useMemo(() => createArchiveModalComponent(dictionary.ARCHIVE_PROCEDURE_MODAL_HEADER, dictionary.ARCHIVE_PROCEDURE_MODAL_TEXT), []);

  return (
    <>
      <PageHeader title={dictionary.PROCEDURES_EDIT_HEADER_LABEL}>
        <ArchiveButton archived={!!procedure?.archived} onClick={archiveBtnOnClick}>{archiveBtnText}</ArchiveButton>
      </PageHeader>
      <ArchiveProcedureModal isOpen={isArchiveModalOpen} onConfirm={toggleArchivedAndCloseModal} onDismiss={closeArchiveModal} />
    </>
  );
};
