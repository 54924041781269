import { ReactElement, FunctionComponent } from 'react';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { CapitationCategory } from '../../../../Data/CapitationCategory';
import { CapitationCategoryForTable, defaultColumnProperties } from './capitationCategoriesTable.utils';
import { dictionary } from '../../../../dictionary';

type CapitationCategoriesTableProps = {
  categories: CapitationCategory[],
  handleRowClick?: (capitation: CapitationCategoryForTable) => void,
  isLoading: boolean,
};

export const CapitationCategoriesTable: FunctionComponent<CapitationCategoriesTableProps> = ({
  categories,
  handleRowClick,
  isLoading,
}): ReactElement => {
  const formattedCategories: CapitationCategoryForTable[] = categories.map((cat) => ({
    id: cat.id,
    name: cat.name,
    hospitalName: cat.hospital.name,
  }));

  return (
    <section>
      <SortableTable
        initialColumnSort="hospitalName"
        rowData={formattedCategories}
        columns={defaultColumnProperties}
        handleRowSelect={handleRowClick}
        noDataMessage={dictionary.CAPITATION_CATEGORIES_TABLE_EMPTY}
        isLoading={isLoading}
        tableTestId="capitation-categories-table"
      />
    </section>
  );
};
