import { FC, useMemo } from 'react';
import { Column, TableInstance, TableState, usePagination, UsePaginationInstanceProps, useSortBy, UseSortByState, useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SortableTableProps } from '../../../../../Shared/SortableTable/SortableTable';
import { useTableStyles } from '../../../../../Themes/table.styles';
import { joinArgs } from '../../../../../Utils/arrayUtils';
import { ReqForIndexTable } from './reqTable.utils';
import { TableFooter } from '../../../../../Shared/ReactTable/TableFooter';
import { SortableTableHeader } from '../../../../../Shared/ReactTable/SortableTableHeader';
import { TableBody } from '../../../../../Shared/ReactTable/TableBody';
import { RequisitionStatus } from '../../../../../Data/RequisitionStatus';
import { routes } from '../../../../../Utils/routes';
import { actionTypes } from '../../../../../redux/actionTypes';
import { requisitionApiService } from '../../../../../Services/RequisitionService';
import { getSlashFormattedDate } from '../../../../../Utils/dateUtils';

interface ReqTableProps {
  requisitions: ReqForIndexTable[];
  columns: Column<ReqForIndexTable>[];
  noDataMessage: string;
  isLoading: boolean;
  initialSortOrder?: SortableTableProps<ReqForIndexTable>['defaultSortDirection'];
}

export const ReqTable: FC<ReqTableProps> = (props) => {
  const { requisitions: data, columns, isLoading, noDataMessage, initialSortOrder = 'desc' } = props;
  const initialSortCol: keyof ReqForIndexTable = 'createdDate';

  const initialState: Partial<TableState<ReqForIndexTable> & UseSortByState<ReqForIndexTable>> = useMemo(() =>
    ({ sortBy: [{ id: initialSortCol, [initialSortOrder]: true }] }), [initialSortOrder]);

  const tableContext = useTable({ columns, data, getRowId: item => item.id.toString(), initialState }, useSortBy, usePagination) as
    unknown as TableInstance<ReqForIndexTable> & UsePaginationInstanceProps<ReqForIndexTable>;

  const tblClasses = useTableStyles({ hasTableContainer: true });
  const reactTableProps = tableContext.getTableProps();

  const history = useHistory();
  const dispatch = useDispatch();

  const handleRowClick = async (reqForTable: ReqForIndexTable) => {
    if (reqForTable.statusCode === RequisitionStatus.draft) {
      const requisition = await requisitionApiService.getById(reqForTable.id);
      requisition.caseDate = getSlashFormattedDate(requisition.caseDate!);
      dispatch({ type: actionTypes.requisition.updateNew, requisition });
      history.push(routes.requisitions.new);
      window.scrollTo(0, 0);
    } else {
      history.push(routes.requisitions.detail(reqForTable.id!));
    }
  };

  return (
    <section className={tblClasses.container}>
      <div className={tblClasses.scrollableContainer}>
        <table {...reactTableProps} className={joinArgs(reactTableProps.className, tblClasses.root)}>
          <SortableTableHeader tableContext={tableContext} />
          <TableBody tableContext={tableContext} rows={tableContext.page} isLoading={isLoading} noDataMessage={noDataMessage} handleRowClick={handleRowClick} />
        </table>
      </div>
      <TableFooter tableContext={tableContext} />
    </section>
  );
};
