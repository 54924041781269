import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import colors from '../../Themes/colors';

export const useDividerStyles = createUseStyles({
  wrapper: {
    marginTop: '2rem',
    borderBottom: `0.0625rem solid ${colors.darkGray}`,
  },
});

export const Divider: FC = () => {
  const classes = useDividerStyles();

  return (
    <div className={classes.wrapper} />
  );
};
