import { faFilter, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { FC, FormEventHandler, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { useDispatch } from 'react-redux';
import { HospitalProductSearchResult } from '../../../Data/HospitalProduct';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { hospitalProductService } from '../../../Services/hospitalProductService';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { Button } from '../../../Shared/buttons/Button';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { If } from '../../../Shared/If';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { colors } from '../../../Themes/colors';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs, uniqueStringFilter } from '../../../Utils/arrayUtils';
import { getFormikInitialValues } from '../../../Utils/formik.utils';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';
import { ProductSearchFields, productSearchFields, ProductSearchOption, validateProductSearchForm } from './productSearch.utils';
import { ProductSearchResultsTable } from './ProductSearchResultsTable/ProductSearchResultsTable';
import { healthSystemService } from '../../../Services/HealthSystemService';
import { HealthSystem } from '../../../Data/HealthSystem';

export const ProductSearch: FC = () => {
  const [searchResults, setSearchResults] = useState<HospitalProductSearchResult[]>([]);
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const initialValues = useMemo(() => getFormikInitialValues(productSearchFields), []);
  const formikProps = useFormik({
    initialValues,
    validateOnChange: false,
    validate: validateProductSearchForm,
    onSubmit: async (values: ProductSearchFields) => {
      setIsLoading(true);
      try {
        const results = await hospitalProductService.search(values.searchText, values.healthSystem?.id);
        setSearchResults(results);
        setSearchIsActive(true);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.PRODUCT_SEARCH_ERROR));
      } finally {
        setIsLoading(false);
      }
    },
  });
  const { searchText, contractIdentifier, hospital, manufacturer } = formikProps.values;
  const resetSearchFields = (newSearchText?: string) => {
    formikProps.setValues({
      ...getFormikInitialValues(productSearchFields),
      searchText: newSearchText ?? searchText,
      healthSystem: formikProps.values.healthSystem,
    });
  };

  const onSearchTextChange: FormEventHandler<HTMLInputElement> = val => {
    resetSearchFields(val.currentTarget.value);
    setSearchResults([]);
    setSearchIsActive(false);
  };
  const valuesToOptions = (values?: (string | null)[], nullishDisplay = ''): ProductSearchOption[] =>
    (!values
      ? []
      : values
        .filter(uniqueStringFilter)
        .map(val => ({ id: val ?? '', value: val, displayName: val ?? nullishDisplay }))
        .sort(alphanumericSortDescriptor('value', 1)) || []);

  const filteredSearchResults = searchResults.filter(
    sr => (
      (!manufacturer || sr.manufacturerName === manufacturer.value)
        && (!contractIdentifier || sr.contractIdentifier === contractIdentifier.value)
    )
  );

  // const hospitalOptions = useMemo(() => valuesToOptions(filteredSearchResults.map(sr => sr.hospitalName)), [filteredSearchResults]);
  const manufacturerOptions = useMemo(() => valuesToOptions(filteredSearchResults.map(sr => sr.manufacturerName)), [filteredSearchResults]);
  const contractIdentifierOptions = useMemo(() => valuesToOptions(
    filteredSearchResults.map(sr => sr.contractIdentifier),
    dictionary.PRODUCT_SEARCH_NULL_CONTRACT_IDENTIFIER_DISPLAY
  ), [filteredSearchResults]);

  const utilClasses = useUtilityStyles();
  const searchButtonIconContent = isLoading ? <LoadingSpinner /> : <FontAwesomeIcon icon={faSearch} />;
  const filterIsActive = hospital || manufacturer || contractIdentifier;
  const filterIconColor = filterIsActive ? colors.blue : colors.darkGray;
  const filterTextColorClass = filterIsActive ? utilClasses.textBlue : utilClasses.textNearBlack;

  const [healthSystems, setHealthSystems] = useState<HealthSystem[]>([]);

  useEffect(() => {
    (async () => {
      if (!healthSystems.length) {
        try {
          const response = await healthSystemService.get();
          setHealthSystems(response.sort(alphanumericSortDescriptor('name', 1)));
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, healthSystems.length]);

  return (
    <>
      <PageHeader title={dictionary.PRODUCTS} />

      <form className="input-form" onSubmit={formikProps.handleSubmit}>
        <Row className={joinArgs(utilClasses.mb05, utilClasses.alignEnd)}>
          <Col xs={4}>
            <Autocomplete
              descriptor={{ name: 'healthSystem', label: 'Search Health System', required: false, inputFormat: 'text' }}
              formikProps={formikProps}
              options={healthSystems}
              disabled={!healthSystems.length}
            />
          </Col>
        </Row>
        <Row className={joinArgs(utilClasses.mb05, utilClasses.alignEnd)}>
          <Col xs={10}>
            <hr className={joinArgs(utilClasses.mt1, utilClasses.mb1)} />
            <SimpleFieldWithLabel
              descriptor={productSearchFields.searchText}
              form={formikProps}
              onChange={onSearchTextChange}
              className={utilClasses.flexGrow}
              placeholder={dictionary.PRODUCT_SEARCH_PLACEHOLDER}
              data-testid="product-search-input"
            />
          </Col>
          <Col xs={2} className={joinArgs(utilClasses.flex, utilClasses.alignCenter, utilClasses.my05)}>
            <Button type="submit" leadingIcon={searchButtonIconContent} className={utilClasses.flexGrow}>
              { dictionary.SEARCH }
            </Button>
          </Col>
        </Row>
        <Row className={joinArgs(utilClasses.alignCenter, utilClasses.spaceBetween, utilClasses.mb05)}>
          <Col xs={1} className={joinArgs(utilClasses.flex, utilClasses.alignCenter)}>
            <FontAwesomeIcon icon={faFilter} className={utilClasses.mr1} color={filterIconColor} />
            <div className={joinArgs(utilClasses.fs21, utilClasses.fwBold, filterTextColorClass)}>
              { dictionary.FILTER_LABEL }
            </div>
          </Col>
          <Col xs={11} className={utilClasses.flex}>
            <Row className={utilClasses.flexGrow}>
              <Col xs={4}>
                {/* <Autocomplete
                  descriptor={productSearchFields.hospital}
                  formikProps={formikProps}
                  options={hospitalOptions}
                  allowClear
                  getOptionLabel={val => val.displayName}
                  placeholder={dictionary.PRODUCT_SEARCH_HOSPITAL_PLACEHOLDER}
                  disabled={!searchIsActive || !searchResults.length}
                /> */}
              </Col>
              <Col xs={4}>
                <Autocomplete
                  descriptor={productSearchFields.contractIdentifier}
                  formikProps={formikProps}
                  options={contractIdentifierOptions}
                  allowClear
                  getOptionLabel={val => val.displayName}
                  placeholder={dictionary.PRODUCT_SEARCH_CONTRACT_IDENTIFIER_PLACEHOLDER}
                  disabled={!searchIsActive || !searchResults.length}
                />
              </Col>
              <Col xs={4}>
                <Autocomplete
                  descriptor={productSearchFields.manufacturer}
                  formikProps={formikProps}
                  options={manufacturerOptions}
                  allowClear
                  getOptionLabel={val => val.displayName}
                  placeholder={dictionary.PRODUCT_SEARCH_MANUFACTURER_PLACEHOLDER}
                  disabled={!searchIsActive || !searchResults.length}
                />
              </Col>
            </Row>
            <Button onClick={() => resetSearchFields()} leadingIcon={<FontAwesomeIcon icon={faTimes} />} buttonStyle="text" disabled={!filterIsActive}>
              {dictionary.PRODUCT_SEARCH_CLEAR_FILTERS_BTN}
            </Button>
          </Col>
        </Row>
      </form>

      <Row>
        <Col xs={12} className={joinArgs(utilClasses.fs21, utilClasses.fwBold, utilClasses.mb1)}>
          <If condition={searchIsActive && filteredSearchResults.length === 0}>
            {dictionary.PRODUCT_SEARCH_RESULT_COUNT_EMPTY}
          </If>
          <If condition={searchIsActive && !!filteredSearchResults.length}>
            { dictionary.PRODUCT_SEARCH_RESULT_COUNT(filteredSearchResults.length) }
          </If>
        </Col>
      </Row>

      <ProductSearchResultsTable products={filteredSearchResults} isLoading={isLoading} searchTerm={searchText} searchIsActive={searchIsActive} />
    </>
  );
};
