import { FC } from 'react';
import { renderTruncatedCell } from './sortableTableRenderers';

interface TruncatedCellProps {
  value: string | number | null;
}

export const getTruncatedCellComponent = (max: number): FC<TruncatedCellProps> => {
  const getDisplayVal = renderTruncatedCell(max);
  const TruncatedCell: FC<TruncatedCellProps> = ({ value }) => (
    <>{ getDisplayVal(value) }</>
  );

  return TruncatedCell;
};
