export enum colors {
  // blues
  blue = '#0076a8',
  kingfisher = '#155a8b',
  coastBlue = '#207fcb',
  ice = '#e9f9f8',
  glacier = '#d8f5f2',
  mystic = '#dceceb',
  mintCream = '#f3fafa',

  // greens
  green = '#84bd00',
  tookishGreen = '#567a00',
  matcha = '#f3f8e6',
  radiumGreen = '#daebb3',

  // oranges
  orange = '#ff9e1b',
  cantaloupe = '#ffc576',
  mirkwood = '#a26411',

  // reds
  red = '#d32f2f',
  redHover = '#c64735',
  darkRedHover = '#ac2d1b',

  // grayscale
  nearBlack = '#15191b',
  gray = '#444',
  darkGray = '#979797',
  nearWhite = '#f9f9f9',
  trueWhite = '#fff',
  disabled = '#dedede',
  disabledDark = '#888',
  grayMinimumContrast = '#767676',
  grayHover = '#E4E4E4',
}

export default colors;
