import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import { dictionary } from '../../dictionary';
import useUtilityStyles from '../../Themes/utility.styles';
import { Button } from '../buttons/Button';
import { createBusinessRulesModalComponent } from '../modals/BusinessRulesModal';

export const BusinessRulesCell: FC<CellProps<any>> = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const utilClasses = useUtilityStyles();
  const { hospitalName, hospitalId } = row.original;

  const header = dictionary.HEADER_BUSINESS_RULES_FOR(hospitalName);
  const BusinessRulesModal = useMemo(() => createBusinessRulesModalComponent(header, hospitalId), [header, hospitalId]);

  return (
    <>
      <Button
        buttonStyle="icon"
        data-testid="hint-action"
        aria-label="Some data is hidden. Click to show all the data."
        className={utilClasses.fs30}
        leadingIcon={<FontAwesomeIcon icon={faQuestionCircle} size="xs" />}
        intent="secondary"
        tooltipTitle="View Business Rules"
        onClick={() => setIsOpen(true)}
        key={`business-rules-${row.id}`}
      />
      <BusinessRulesModal isOpen={isOpen} onDismiss={() => setIsOpen(false)} />
    </>
  );
};
