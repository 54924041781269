import { FC } from 'react';
import { Route } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { CostCentersIndex } from './CostCentersIndex/CostCentersIndex';
import { CreateCostCenter } from './CreateCostCenter/CreateCostCenter';
import { EditCostCenter } from './EditCostCenter/EditCostCenter';

export const CostCentersRoutes: FC = () => (
  <>
    <Route exact path={routes.costCenters.index}>
      <CostCentersIndex />
    </Route>
    <Route exact path={routes.costCenters.create}>
      <CreateCostCenter />
    </Route>
    <Route exact path={routes.costCenters.editTemplate}>
      <EditCostCenter />
    </Route>
  </>
);
