import { userRoles } from './userRoles';
import User from '../Data/User';

const permissionChecker = (approvedRoles: string[]) => {
  const isGrantedTo = (user?: User) => {
    if (!user) return false;

    if (user.roleName === userRoles.omiAdmin) return true;

    if (approvedRoles.includes(user.roleName)) return true;

    return false;
  };
  return { isGrantedTo };
};

export const PermissionTo = Object.freeze({
  performAnyHospitalAdminAction: permissionChecker([userRoles.hospital.approver]),
  approveARequisition: permissionChecker([userRoles.hospital.approver]),
  createARequisition: permissionChecker([userRoles.vendor, userRoles.hospital.approver]),
  createAVendorUser: permissionChecker([]),
  createAHospitalUser: permissionChecker([]),
  updateAReqCostCenter: permissionChecker([userRoles.hospital.approver]),
});
