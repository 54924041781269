import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { FormikValues, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { getFormikInitialValues } from '../../../Utils/formik.utils';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import {
  HospMfctIdentifierAssocFormFields,
  HospMfctIdentifierFormFields,
  hospMfctIdentifierFormFields,
  validateCreateHospMfctIdentifierForm,
} from '../shared/hospMfctIdentifier.utils';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { dictionary } from '../../../dictionary';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';
import { CreateHospMfctIdentifierTable } from './CreateHospMfctIdentifierTable/CreateHospMfctIdentifierTable';
import useUtilityStyles from '../../../Themes/utility.styles';
import { CreateHospMfctIdentifierAssoc } from './CreateHospMfctIdentifierAssoc';
import { HospMfctIdentifierRequest } from '../../../Data/ManufacturerIdentifier';
import { formHospitalManufacturerToTableFormat } from './CreateHospMfctIdentifierTable/createHospMfctIdentifierTable.utils';
import Manufacturer from '../../../Data/Manufacturer';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { manufacturerService } from '../../../Services/ManufacturerService';
import Hospital, { HospitalBase } from '../../../Data/Hospital';
import { hospitalService } from '../../../Services/HospitalService';

export const CreateHospMfctIdentifierForm: FC<FormFlowStepComponent<HospMfctIdentifierRequest>> = (props) => {
  const { FormActionsComponent, onFormStepComplete } = props;
  const [hospitalBases, setHospitalBases] = useState<HospitalBase[]>([]);
  const [hospitalManufacturerIdentifierAssociations, setHospitalManufacturerIdentifierAssociations] = useState<HospMfctIdentifierAssocFormFields[]>([]);
  const [highlightAssociationSectionError, setHighlightAssociationSectionError] = useState(false);
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const dispatch = useDispatch();
  const utilClasses = useUtilityStyles();

  const addedManufacturers = hospitalManufacturerIdentifierAssociations.map(assoc => assoc.manufacturer.id);

  useEffect(() => {
    (async () => {
      if (!hospitalBases.length) {
        try {
          const hospitalBasesResult = await hospitalService.getAdminHospitals();
          setHospitalBases(hospitalBasesResult);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospitalBases]);

  useEffect(() => {
    (async () => {
      try {
        const fetchedManufacturers = await manufacturerService.getAll();
        if (fetchedManufacturers) {
          setManufacturers(fetchedManufacturers);
        }
      } catch (err) {
        setHasLoadingError(true);
      }
    })();
  }, []);

  const formikProps = useFormik({
    initialValues: getFormikInitialValues<HospMfctIdentifierFormFields>(hospMfctIdentifierFormFields),
    validate: validateCreateHospMfctIdentifierForm,
    onSubmit: (formValues: FormikValues) => {
      if (!hospitalManufacturerIdentifierAssociations.length) {
        setHighlightAssociationSectionError(true);
        dispatch(addNotification(NotificationType.error, dictionary.CREATE_MANUFACTURER_ID_NO_ASSOCIATIONS_ERROR));
      } else {
        onFormStepComplete({
          hospitalId: formValues.hospital.id,
          hospitalManufacturerIdentifierAssociations: hospitalManufacturerIdentifierAssociations.map((assoc) => ({
            identifier: assoc.identifier,
            manufacturerId: assoc.manufacturer.id,
            siteName: assoc.siteName,
            siteIdentifier: assoc.siteIdentifier,
          })),
        });
      }
    },
  });

  if (hasLoadingError) {
    return <ErrorPage />;
  }

  const addHospitalManufacturerIdentifierAssociation = (hospitalManufacturerIdentifierAssociation: HospMfctIdentifierAssocFormFields) =>
    setHospitalManufacturerIdentifierAssociations([...hospitalManufacturerIdentifierAssociations, hospitalManufacturerIdentifierAssociation]);

  const sortedHospitals = hospitalBases.sort(alphanumericSortDescriptor('name', 1));
  const selectedHospital = formikProps.values.hospital as Hospital;
  const getExistingAndAddedManufacturerIds = (): number[] =>
    addedManufacturers
      .concat(selectedHospital
        ?.manufacturerIdentifiers
        ?.map(hmi => hmi.manufacturerId) ?? []);

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row className={utilClasses.mb2}>
        <Col xs={4}>
          <Autocomplete
            descriptor={hospMfctIdentifierFormFields.hospital}
            formikProps={formikProps}
            options={sortedHospitals}
            disabled={!hospitalBases.length || (!!formikProps.values.hospital && hospitalManufacturerIdentifierAssociations.length > 0)}
          />
        </Col>
        <Col xs={8} className={utilClasses.pl3}>
          <CreateHospMfctIdentifierAssoc
            disableManufacturer={!selectedHospital}
            manufacturers={manufacturers}
            addedManufacturers={getExistingAndAddedManufacturerIds()}
            addHospMfctIdentifierAssoc={addHospitalManufacturerIdentifierAssociation}
            errorHighlight={highlightAssociationSectionError && hospitalManufacturerIdentifierAssociations.length === 0}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <CreateHospMfctIdentifierTable identifier={formHospitalManufacturerToTableFormat(hospitalManufacturerIdentifierAssociations)} />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
