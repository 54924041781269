import { FC, useState, useCallback } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { Button } from '../../../../../Shared/buttons/Button';
import { useModalStyles } from '../../../../../Shared/modals/Modal/modal.styles';
import { SortableTable } from '../../../../../Shared/SortableTable/SortableTable';
import { dictionary } from '../../../../../dictionary';
import { toCurrency } from '../../../../../Utils/formatNumber';
import { SearchField } from '../../../../../Shared/fields/SearchField';
import useUtilityStyles from '../../../../../Themes/utility.styles';
import { renderTruncatedCell } from '../../../../../Shared/tables/sortableTableRenderers';
import { Modal } from '../../../../../Shared/modals/Modal/Modal';
import { ColumnProperty } from '../../../../../Shared/SortableTable/SortableTable.types';
// import { HospitalManufacturerContract } from '../../../../../Data/Contract';
import { HospitalManufacturerContractCapitation } from '../../../../../Data/Capitation';

interface ApplyCapModalTableDatum {
  id: number;
  name: string;
  categoryName?: string;
  capitationItemId?: string | null;
  price: number;
}

type ApplyCapitationModalProps = {
  isOpen: boolean,
  cancelOnClick: () => void,
  // contracts: HospitalManufacturerContract[],
  submitOnClick: (capitation: HospitalManufacturerContractCapitation) => void,
  capitationsFromParent: HospitalManufacturerContractCapitation[]
};

const columnProperties: ColumnProperty<ApplyCapModalTableDatum>[] = [
  { displayName: dictionary.CAPITATION_NAME_LABEL, name: 'name', width: '20%', colRenderCell: renderTruncatedCell(50) },
  { displayName: dictionary.CAPITATION_CATEGORY_LABEL, name: 'categoryName', width: '16%', colRenderCell: renderTruncatedCell(50) },
  { displayName: dictionary.CAPITATION_ITEM_ID_LABEL, name: 'capitationItemId', width: '16%', colRenderCell: renderTruncatedCell(50) },
  { displayName: dictionary.CAPITATION_PRICE_LABEL, name: 'price', width: '16%', align: 'right', colRenderCell: toCurrency },
];

export const ApplyCapitationModal: FC<ApplyCapitationModalProps> = ({ isOpen, cancelOnClick, submitOnClick, capitationsFromParent }) => {
  const [selectedCapitationId, setSelectedCapitationId] = useState<number | null>(null);
  const [searchPattern, setSearchPattern] = useState<string>('');
  // const contractCapitations = contracts.flatMap(contract => contract.capitations);
  const newCapitations = capitationsFromParent.map(c => {
    const datum = {
      ...c,
      capitationItemId: c.orderIdentifier,
    };
    return datum;
  });
  const modalClasses = useModalStyles();
  const utilClasses = useUtilityStyles();
  const onRowSelect = useCallback((capitation: ApplyCapModalTableDatum) => {
    setSelectedCapitationId(capitation.id);
  }, []);
  const isSubmitDisabled = (selectedCapitationId === null);
  const selectedCapitation = capitationsFromParent.find(cap => cap.id === selectedCapitationId);
  const filteredCapitations = newCapitations.filter(cap => (
    RegExp(searchPattern, 'gi').test(cap.name) ||
      RegExp(searchPattern, 'gi').test(cap.capitationItemId || '')
  ));

  const formikConfig = useFormik({
    initialValues: { capitationSearchPattern: '' },
    onSubmit: () => { },
  });

  const onModalOpen = useCallback(() => {
    setSelectedCapitationId(null);
    setSearchPattern('');
  }, []);

  const handleCapitationSearch = useCallback((pattern: string) => {
    setSelectedCapitationId(null);
    setSearchPattern(pattern);
  }, []);

  return (
    <Modal
      data-testid="apply-capitation-modal"
      isOpen={isOpen}
      size="lg"
      onOpen={onModalOpen}
      title={dictionary.REQ_CAP_SEARCH_HEADER}
      actionsContent={(
        <>
          <Button buttonStyle="reverse" onClick={() => cancelOnClick()} data-testid="modal-cancel">
            {dictionary.CANCEL}
          </Button>

          <Button
            buttonStyle="primary"
            leadingIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => submitOnClick(selectedCapitation!)}
            data-testid="modal-submit"
            disabled={isSubmitDisabled}
          >
            {dictionary.REQ_CAP_SEARCH_APPLY_BUTTON}
          </Button>
        </>
      )}
    >
      <Row className={utilClasses.mt2}>
        <Col xs={5}>
          <SearchField
            name="capitationSearchPattern"
            labelText="Search Capitations"
            autoComplete="off"
            form={formikConfig}
            field={formikConfig.getFieldProps('capitationSearchPattern')}
            onSearch={handleCapitationSearch}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <h2>{dictionary.REQ_CAP_SEARCH_RESULTS_HEADER}</h2>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <SortableTable<ApplyCapModalTableDatum>
            initialColumnSort="name"
            rowData={filteredCapitations}
            columns={columnProperties}
            noDataMessage={dictionary.CAPITATIONS_MODAL_TABLE_EMPTY}
            isLoading={false}
            handleRowSelect={onRowSelect}
            selectedRowIds={selectedCapitationId !== null ? [selectedCapitationId] : []}
            className={modalClasses.table}
          />
        </Col>
      </Row>
    </Modal>
  );
};
