import { FC } from 'react';
import { FormikValues } from 'formik';
import { DeprecatedCurrencyField } from '../../../Shared/fields/DeprecatedCurrencyField';
import { Approver } from './ApprovalRoutingForm.types';
import { findMatchingApprover } from './approvalRoutingForm.utils';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { dictionary } from '../../../dictionary';

interface ApprovalRoutingApproverFieldsProps {
  formik: FormikValues;
  approvers: Approver[],
  selectedApprovers: Approver[],
  approverType: 'Buyer' | 'First' | 'Second' | 'Third';
  dollarThreshold?: number;
  disabled?: boolean;
}

const approverOrderWithNumberRecord: Record<string, string> = {
  First: '1st',
  Second: '2nd',
  Third: '3rd',
};

export const ApprovalRoutingApproverFields: FC<ApprovalRoutingApproverFieldsProps> = (props) => {
  const { formik, disabled, approvers, selectedApprovers, approverType, dollarThreshold } = props;
  const approverTypeWithNumber = approverOrderWithNumberRecord[approverType];
  const approverTypeLower = approverType.toLowerCase();
  const touchedApprovers = formik.touched[`${approverTypeLower}Approvers`];
  const approversErrors = formik.errors[`${approverTypeLower}Approvers`];

  return (
    <>
      <Autocomplete
        multiple
        label={`${approverType} Approver(s) ${dictionary.REQUIRED_FIELD_MARK}`}
        id={`${approverTypeLower}Approvers`}
        disabled={disabled}
        onChange={newValue => formik.setFieldValue(`${approverTypeLower}Approvers`, findMatchingApprover(approvers, newValue))}
        onBlur={formik.handleBlur}
        options={approvers}
        data-testid={`${approverTypeLower}-approvers-autocomplete`}
        value={selectedApprovers}
        validationError={(touchedApprovers && approversErrors?.toString()) || ''}
      />
      { dollarThreshold !== undefined
        ? (
          <DeprecatedCurrencyField
            name={`${approverTypeLower}ApproverThreshold`}
            label={`${approverTypeWithNumber} Approver Threshold`}
            disabled={disabled}
            formikValues={formik.values}
            handleBlur={formik.handleBlur}
            form={{ touched: formik.touched, errors: formik.errors }}
            field={{
              value: dollarThreshold || '',
              onChange: (evt: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue(`${approverTypeLower}ApproverThreshold`, evt.target.value);
                if (approverTypeLower === 'first' && evt.target.value === '') {
                  formik.setFieldValue('secondApproverThreshold', '');
                  formik.touched.secondApproverThreshold = true;
                }
              },
            }}
          />
        )
        : null }
    </>
  );
};
