import { createUseStyles } from 'react-jss';
import Colors from '../../Themes/colors';

export default createUseStyles({
  '@keyframes slideIn': {
    from: { transform: 'translateY(20vh)' },
    to: { transform: 'translateY(0vh)' },
  },
  root: {
    position: 'fixed',
    bottom: '3.5rem',
    width: '100%',
    padding: '0 3rem',
    zIndex: 1310,
  },
  container: {
    margin: '0 auto',
  },
  base: {
    display: 'flex',
    marginTop: '0.5rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.25rem',
    color: Colors.nearWhite,
    animation: '$slideIn 500ms',
  },
  success: { backgroundColor: Colors.tookishGreen },
  error: { backgroundColor: Colors.red },
  info: { backgroundColor: Colors.kingfisher },
});
