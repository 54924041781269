import { JssStyle } from 'jss';
import { createUseStyles } from 'react-jss';
import { colors } from './colors';

export const tableScrollbar: JssStyle = {
  '&::-webkit-scrollbar': {
    backgroundColor: colors.trueWhite,
  },
  '&::-webkit-scrollbar-thumb': {
    border: `0.125rem solid ${colors.trueWhite}`,
    borderRadius: '0.25rem',
    backgroundColor: colors.darkGray,
  },
};

export const tableContainerStyle: JssStyle = {
  width: '100%',
  overflowX: 'auto',
  border: `.0625rem solid ${colors.disabledDark}`,
  boxShadow: '0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.25)',
  backgroundColor: colors.trueWhite,
  ...tableScrollbar,
};

export const useTableStyles = createUseStyles <
'root' | 'tableStripes' | 'container' | 'scrollableContainer',
undefined | { hasTableContainer: boolean }
>({
  container: {
    ...tableContainerStyle,
    overflowX: 'visible',
    '& .paging-controls': {
      paddingBottom: '1rem',
      '& > *:not(:first-child)': {
        marginLeft: '2rem',
      },
    },
  },
  scrollableContainer: {
    overflowX: 'scroll',
    ...tableScrollbar,
  },
  root: {
    borderCollapse: 'collapse',
    textAlign: 'left',
    width: '100%',
    border: (props) => (!props?.hasTableContainer ? `0.0625rem solid ${colors.darkGray}` : ''),
    '& thead': {
      borderBottom: `0.0625rem solid ${colors.darkGray}`,
      boxShadow: '0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.25)',
    },
    '& tbody, & thead': {
      '& th, & td': {
        padding: '1rem',
        '&:first-of-type': { paddingLeft: '1.75rem' },
        '&:last-of-type': { paddingRight: '1.75rem' },
        '&.cellAlignRight': {
          textAlign: 'right',
        },
      },
    },
    '& th': {
      textAlign: 'left',
    },
    '& td': {
      wordWrap: 'break-word',
      '&.noWrap': {
        wordWrap: 'initial',
        overflowWrap: 'initial',
      },
      '&.insetRight:last-of-type': {
        paddingRight: '4.5rem',
      },
    },
    '& tbody tr.hasOnClick': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colors.grayHover,
      },
      '&:focus': {
        backgroundColor: colors.disabled,
      },
    },
    '& table': {
      borderCollapse: 'collapse',
      '& thead': {
        borderBottom: 'none',
        boxShadow: 'none',
      },
      '& tbody tr': {
        borderBottom: 'none',
      },
    },
  },
  tableStripes: {
    '& tr:nth-child(even)': {
      backgroundColor: colors.nearWhite,
    },
  },
});
