import { createUseStyles } from 'react-jss';
import colors from '../../../../Themes/colors';
import { fontWeights } from '../../../../Themes/fontWeights';

export const useProductInfoStyles = createUseStyles({
  productDetailsCol: {
    borderRight: `0.0625rem solid ${colors.gray}`,
  },
  productSubtotal: {
    justifyContent: 'space-between',
    textAlign: 'right',
    margin: '0.75rem 0',
    fontSize: '1.2rem',
    fontWeight: fontWeights.bold,
    '& .display-price': {
      marginLeft: '6rem',
    },
  },
  productForm: {
    paddingLeft: '2rem',
  },
  '@media (max-width: 991px)': {
    productForm: {
      paddingLeft: '8px',
    },
  },
});
