import apiClient from './apiClient';
import { PhysicianRequest, Physician } from '../Data/Physician';

export const physiciansUrl = '/physicians';
export const physicianByIdUrl = (id: number): string => `${physiciansUrl}/${id}`;

export interface PhysiciansResponse {
  physicians: Physician[];
}

const fetchPhysicians = async (): Promise<Physician[]> =>
  apiClient.get<PhysiciansResponse>({ url: physiciansUrl }).then(resp => resp.physicians);

const createPhysician = async (request: PhysicianRequest): Promise<{ id: number }> =>
  apiClient.post<{ id: number }>({ url: physiciansUrl, data: request });

const updatePhysician = async (id: number, physician: PhysicianRequest): Promise<void> =>
  apiClient.put({ url: physicianByIdUrl(id), data: physician });

const getPhysicianById = async (id: number): Promise<Physician> =>
  apiClient.get<Physician>({ url: physicianByIdUrl(id) });

export const physicianService = {
  getAll: fetchPhysicians,
  getPhysicianById,
  create: createPhysician,
  update: updatePhysician,
};
