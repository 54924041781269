import { ReactElement, FunctionComponent } from 'react';
import { SortableTable } from '../../../Shared/SortableTable/SortableTable';
import Capitation from '../../../Data/Capitation';
import { defaultColumnProperties } from './capitationsTable.utils';
import { dictionary } from '../../../dictionary';
import { ColumnProperty } from '../../../Shared/SortableTable/SortableTable.types';

type CapitationsTableProps = {
  capitations: Capitation[],
  columnProperties?: ColumnProperty<Capitation>[],
  handleRowClick?: (capitation: Capitation) => void,
  isLoading: boolean,
};

export const CapitationsTable: FunctionComponent<CapitationsTableProps> = ({
  capitations,
  columnProperties = defaultColumnProperties,
  handleRowClick,
  isLoading,
}): ReactElement => (
  <section>
    <SortableTable
      initialColumnSort="name"
      rowData={capitations}
      columns={columnProperties}
      handleRowSelect={handleRowClick}
      noDataMessage={dictionary.CAPITATIONS_TABLE_EMPTY}
      isLoading={isLoading}
      tableTestId="capitations-table"
    />
  </section>
);
