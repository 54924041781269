import { createUseStyles } from 'react-jss';
import colors from '../../../Themes/colors';
import { fontWeights } from '../../../Themes/fontWeights';
import { ModalSize } from './modalTypes';

export const useModalStyles = createUseStyles<any, { size?: ModalSize }>({
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
  },
  panel: {
    width: '100%',
    maxWidth: (props) => {
      const sizeMap: Record<ModalSize | 'default', string> = {
        lg: '80rem',
        md: '60rem',
        sm: '30rem',
        default: 'auto',
      };
      return sizeMap[props?.size || 'sm'] ?? sizeMap.sm;
    },
    overflow: 'hidden',
    borderRadius: '0.25rem',
    backgroundColor: colors.trueWhite,
    boxShadow: '0rem 1rem 2rem 0.5rem rgba(0, 0, 0, 0.3)',
  },
  content: {
    maxHeight: '75vh',
    overflowY: 'auto',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
  buttonsContainer: {
    display: 'flex',
    marginBottom: '1.5rem',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    '& button:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
  title: {
    backgroundColor: colors.blue,
    color: colors.trueWhite,
    fontSize: '1.2rem',
    fontWeight: fontWeights.bold,
    margin: 0,
    padding: '0.5rem 1.5rem',
    '& svg': {
      marginRight: '1rem',
    },
  },
  table: {
    maxHeight: '40vh',
    overflowY: 'scroll',
  },
});
