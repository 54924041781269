import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo } from 'react';
import { UseExpandedInstanceProps } from 'react-table';
import { dictionary } from '../../../../../dictionary';
import { Button } from '../../../../../Shared/buttons/Button';

export interface ProductsToBillExpandRowButtonProps {
  tableContext: UseExpandedInstanceProps<any>;
}

export const ProductsToBillExpandRowButton: FC<ProductsToBillExpandRowButtonProps> = ({ tableContext }) => {
  const isEveryRowExpanded = useMemo(
    () => (!tableContext.rows.length || tableContext.isAllRowsExpanded),
    [tableContext.isAllRowsExpanded, tableContext.rows]
  );

  return (
    <Button
      buttonStyle="text"
      leadingIcon={<FontAwesomeIcon size="lg" icon={isEveryRowExpanded ? faCaretUp : faCaretDown} />}
      onClick={() => tableContext.toggleAllRowsExpanded(!isEveryRowExpanded)}
    >
      { isEveryRowExpanded ? dictionary.REQ_PRODUCTS_TO_BILL_COLLAPSE_ALL : dictionary.REQ_PRODUCTS_TO_BILL_EXPAND_ALL }
    </Button>
  );
};
