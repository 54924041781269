import { FormikValues } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Department from '../../../Data/Department';
import User from '../../../Data/User';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { userToApprover } from './approvalRoutingForm.utils';
import { ApprovalRoutingApproverFields } from './ApprovalRoutingApproverFields';
import { DisplayField } from '../../../Shared/fields/DisplayField';
import { useUtilityStyles } from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { ApprovalRoutingFormFields } from './ApprovalRoutingForm.types';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';
import { HospitalBase } from '../../../Data/Hospital';
import { If } from '../../../Shared/If';
import { dictionary } from '../../../dictionary';

interface ApprovalRoutingFormProps extends FormFlowStepComponent<ApprovalRoutingFormFields> {
  formik: FormikValues;
  approverUsers: User[];
  hospitals: HospitalBase[];
  departments: Department[];
  onHospitalChange: (hospital: HospitalBase | null) => void;
  isEdit: boolean;
  canCurrentUserEdit: boolean;
}

export const ApprovalRoutingForm: FC<ApprovalRoutingFormProps> = (props) => {
  const { formik, hospitals, departments, approverUsers, onHospitalChange, isEdit, canCurrentUserEdit, FormActionsComponent } = props;
  const { hospital, department, firstApprovers, firstApproverThreshold, secondApprovers, secondApproverThreshold, thirdApprovers, buyerApprovers } = formik.values;
  const [filteredApprovers, setFilteredApprovers] = useState<User[]>(approverUsers);
  const utilClasses = useUtilityStyles();

  const isFormDisabled = isEdit && !canCurrentUserEdit;

  const isDepartmentDisabled = !hospital || !hospital.id || !departments.length;
  const isSecondApproverDisabled = firstApproverThreshold <= 0;
  const isThirdApproverDisabled = isSecondApproverDisabled || (secondApproverThreshold <= 0);
  const isAllApproverFieldsDisabled = !department || isFormDisabled;

  const mappedApproverOptions = filteredApprovers.map(userToApprover);

  const filterApprovers = (user: User, departmentId: number) => user.hospitalAssociations.some(x => x.departments.some(y => y.id === departmentId));
  useEffect(() => {
    if (department !== null && approverUsers && approverUsers.length) {
      setFilteredApprovers(approverUsers.filter(x => filterApprovers(x, department.id)));
    }
  }, [department, approverUsers]);

  const isAnyApproverSelected = formik.values.firstApprovers.length
    || formik.values.secondApprovers.length
    || formik.values.thirdApprovers.length
    || formik.values.buyerApprovers.length;

  const sortedHospitals = hospitals.sort(alphanumericSortDescriptor('name', 1));
  const sortedActiveDepartments = departments.filter(d => !d.archived).sort(alphanumericSortDescriptor('name', 1));

  return (
    <form className="input-form outset wide" onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={4} className={utilClasses.pr2}>
          <If condition={isEdit}>
            <div className="details-section-grid">
              <DisplayField compact label={dictionary.APPROVAL_ROUTES_HOSPITAL_LABEL} value={hospital?.name} />
              <DisplayField compact label={dictionary.APPROVAL_ROUTES_DEPARTMENT_LABEL} value={department?.name} />
            </div>
          </If>
          <If condition={!isEdit}>
            <Autocomplete
              id="hospital"
              data-testid="hospital-autocomplete"
              options={sortedHospitals}
              label="Hospital &#42;"
              value={hospitals.find((h: HospitalBase) => h.id === hospital?.id) ? hospital : null}
              onChange={(newValue: HospitalBase | null) => {
                formik.setFieldValue('hospital', newValue);
                formik.setFieldValue('department', null, false);
                onHospitalChange(newValue);
              }}
              onBlur={formik.handleBlur}
              validationError={formik.errors.hospital && formik.touched.hospital ? formik.errors.hospital.toString() : ''}
              disabled={isAnyApproverSelected || (!hospitals.length)}
            />
            <Autocomplete
              disabled={isDepartmentDisabled || isAnyApproverSelected}
              id="department"
              data-testid="department-autocomplete"
              label="Department &#42;"
              options={sortedActiveDepartments}
              validationError={formik.errors.department && formik.touched.department ? formik.errors.department.toString() : ''}
              value={departments.find((d: Department) => d.id === department?.id) ? department : null}
              onChange={(newValue: Department | null) => {
                formik.setFieldValue('department', newValue);
              }}
              onBlur={formik.handleBlur}
            />
          </If>

          <ApprovalRoutingApproverFields
            formik={formik}
            approverType="Buyer"
            approvers={mappedApproverOptions}
            selectedApprovers={buyerApprovers.map(userToApprover)}
            disabled={isAllApproverFieldsDisabled}
          />
        </Col>

        <Col xs={4} className={joinArgs(utilClasses.pl2, utilClasses.dividerBorder)}>
          <ApprovalRoutingApproverFields
            formik={formik}
            approverType="First"
            approvers={mappedApproverOptions}
            selectedApprovers={firstApprovers.map(userToApprover)}
            dollarThreshold={firstApproverThreshold}
            disabled={isAllApproverFieldsDisabled}
          />
          <ApprovalRoutingApproverFields
            formik={formik}
            approverType="Second"
            disabled={isSecondApproverDisabled || isAllApproverFieldsDisabled}
            approvers={mappedApproverOptions}
            selectedApprovers={secondApprovers.map(userToApprover)}
            dollarThreshold={secondApproverThreshold}
          />
          <ApprovalRoutingApproverFields
            formik={formik}
            approverType="Third"
            disabled={isThirdApproverDisabled || isAllApproverFieldsDisabled}
            approvers={mappedApproverOptions}
            selectedApprovers={thirdApprovers.map(userToApprover)}
          />
        </Col>
      </Row>

      <Row className={utilClasses.mt2}>
        <Col xs={8}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
