import { dictionary } from '../../../dictionary';
import Capitation from '../../../Data/Capitation';
import { dateSortDescriptor, numberSortDescriptor } from '../../../Utils/SortUtils';
import { renderPriceCell, renderSlashDateCell, renderTruncatedCell } from '../../../Shared/tables/sortableTableRenderers';
import { ColumnProperty } from '../../../Shared/SortableTable/SortableTable.types';

const caseInsensitiveSort = (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase());
export const hospitalList = (arr: string[]): string => [...arr].sort(caseInsensitiveSort).join(', ');

export const renderHospitalsCell = (value: string[]): string => hospitalList(value);

export const defaultColumnProperties: ColumnProperty<Capitation>[] = [
  { displayName: dictionary.CAPITATION_NAME_LABEL, name: 'name', colRenderCell: renderTruncatedCell(50) },
  { displayName: dictionary.CAPITATION_CONTRACT_ID_LABEL, name: 'contractIdentifier' },
  {
    displayName: dictionary.CAPITATION_CONTRACT_EXP_DATE_LABEL,
    name: 'contractExpirationDate',
    sortDescriptor: dateSortDescriptor,
    colRenderCell: renderSlashDateCell,
  },
  {
    displayName: dictionary.CAPITATION_CONTRACT_HOSPITAL_LABEL,
    name: 'hospitals',
    colRenderCell: renderHospitalsCell,
  },
  { displayName: dictionary.CAPITATION_MANUFACTURER_LABEL, name: 'manufacturer' },
  { displayName: dictionary.CAPITATION_CATEGORY_LABEL, name: 'category' },
  { displayName: dictionary.CAPITATION_ITEM_ID_LABEL, name: 'orderIdentifier' },
  {
    displayName: dictionary.CAPITATION_PRICE_LABEL,
    name: 'price',
    sortDescriptor: numberSortDescriptor,
    colRenderCell: renderPriceCell,
    align: 'right',
  },
];
