import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { createUseStyles } from 'react-jss';
import { joinArgs } from '../Utils/arrayUtils';
import colors from '../Themes/colors';
import { If } from './If';

const useStepperStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
  },
  stepItem: {
    '&.complete, &.current': {
      color: colors.blue,
    },
  },
  divider: {
    height: '0.125rem',
    width: '3rem',
    margin: '0 1rem',
    backgroundColor: colors.disabled,
    '&.complete': {
      backgroundColor: colors.blue,
    },
  },
  icon: {
    marginRight: '0.5rem',
    color: colors.disabled,
    '&.current': {
      color: colors.blue,
    },
    '&.complete': {
      color: colors.blue,
    },
  },
  '@media (max-width: 991px)': {
    stepItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    icon: {
      marginRight: '0',
    },
  },
});

type StepIconProps = {
  className: string;
  completed: boolean;
};

const StepIcon: FC<StepIconProps> = (props) => {
  const { className, completed } = props;

  if (completed) {
    return (<FontAwesomeIcon icon={faCheckCircle} size="lg" className={joinArgs(className, 'complete')} />);
  }

  return (<FontAwesomeIcon icon={faCircle} size="lg" className={className} />);
};

type FormStepperProps = {
  currentPage: number;
  stepTitles: string[];
};

export const FormStepper: FC<FormStepperProps> = ({ currentPage, stepTitles }) => {
  const classes = useStepperStyles();

  return (
    <div className={classes.root} data-testid="stepper">
      {stepTitles.map((label, index) => {
        const isComplete = (index < currentPage);
        const isCurrent = (index === currentPage);
        const isLast = (index === stepTitles.length - 1);

        return (
          <React.Fragment key={label}>
            <div
              className={joinArgs(
                classes.stepItem,
                isComplete ? 'complete' : '',
                isCurrent ? 'current' : '',
              )}
            >
              <StepIcon className={joinArgs(classes.icon, isCurrent ? 'current' : '')} completed={index < currentPage} />
              { label }
            </div>

            <If condition={!isLast}>
              <div className={joinArgs(classes.divider, isComplete ? 'complete' : '')} />
            </If>
          </React.Fragment>
        );
      })}
    </div>
  );
};
