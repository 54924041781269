import apiClient from './apiClient';
import Patient, { PatientSearchRequest } from '../Data/Patient';

export const patientsUrl = '/patients';
export const searchForPatientUrl = `${patientsUrl}/search`;

export const searchForPatient = async (patientSearchRequest: PatientSearchRequest): Promise<Patient> => {
  const requestConfig = {
    url: searchForPatientUrl,
    data: JSON.stringify(patientSearchRequest),
  };
  return apiClient.post<Patient>(requestConfig);
};

export const postPatient = async (patient: Pick<Patient, 'mrn' | 'hospitalId' | 'firstName'| 'lastName'>): Promise<Patient> => {
  const requestConfig = {
    url: patientsUrl,
    data: JSON.stringify(patient),
  };

  return apiClient.post<{ id: number }>(requestConfig).then(({ id }) => ({ ...patient, id }));
};
