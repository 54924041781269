import { useState, useEffect, FC, useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Contract } from '../../../Data/Contract';
import useUtilityStyles from '../../../Themes/utility.styles';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { DateFieldWithLabel } from '../../../Shared/fields/DateFieldWithLabel';
import { dictionary } from '../../../dictionary';
import { validate } from './editContract.validation';
import { HospitalManufacturerIdentifier, HospMfctIdentifierSearchRequest } from '../../../Data/ManufacturerIdentifier';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import { editContractFields, EditContractFormFields, getContractUpdateRequestFromFormFields, getEditContractFormInitialValues } from './editContract.utils';
import { SimpleDiscountField } from '../../../Shared/fields/SimpleDiscountField';
import { If } from '../../../Shared/If';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { hospitalService } from '../../../Services/HospitalService';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../redux/initialState';

export const EditContractForm: FC<FormFlowStepComponent<Contract, Contract>> = ({ onFormStepComplete, FormActionsComponent, editSource: contract }) => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const contractId = Number(params.id);
  const [manufacturerIdentifierOptions, setManufacturerIdentifierOptions] = useState<HospitalManufacturerIdentifier[]>([]);
  const utilityClasses = useUtilityStyles();

  useEffect(() => {
    (async () => {
      if (contract) {
        try {
          const searchRequest: HospMfctIdentifierSearchRequest = {
            hospitalIds: contract.hospitals.map(h => h.id),
            manufacturerId: contract.manufacturer!.id,
          };
          const hmiOptionsResponse = await hospitalService.searchHospitalManufacturerIdentifiers(searchRequest);
          setManufacturerIdentifierOptions(hmiOptionsResponse.hospitalManufacturerIdentifiers);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, contract]);

  const matchingManufacturerIdentifier = manufacturerIdentifierOptions.find((mi) => mi.identifier === contract?.manufacturerIdentifier);

  const onSubmit = async (values: EditContractFormFields) => {
    const updatedContract = getContractUpdateRequestFromFormFields(contractId, values);
    onFormStepComplete(updatedContract);
  };
  const formikInitialValues = getEditContractFormInitialValues({ ...contract, manufacturerIdentifier: matchingManufacturerIdentifier });
  const formikProps = useFormik({ initialValues: formikInitialValues, validate, enableReinitialize: true, onSubmit });
  const getContractHospitals = () => (<>{contract?.hospitals.map((hospital) => <div key={hospital.id}>{hospital.name}</div>)}</>);
  const numberOfProductsOnContract = useMemo(() => new Set(contract?.products?.map(p => p.catalogNumber) ?? []).size, [contract?.products]);

  return (
    <If condition={!!contract}>
      <h2>{dictionary.CONTRACT_INFORMATION_SECTION_HEADER}</h2>

      <form className={`input-form ${utilityClasses.topMargin}`} onSubmit={formikProps.handleSubmit}>
        <Row>
          <Col xs={12} sm={6} lg={4}>
            <SimpleFieldWithLabel descriptor={editContractFields.contractIdentifier} form={formikProps} maxLength={200} />

            <SimpleDisplayField label={dictionary.CONTRACT_HOSPITALS} value={getContractHospitals()} />
            <SimpleDisplayField label={dictionary.MANUFACTURER_NAME_LABEL} value={contract?.manufacturer?.name} />
            <SimpleDisplayField label={dictionary.CONTRACT_NUMBER_OF_PRODUCTS_LABEL} value={numberOfProductsOnContract} />

            <Autocomplete
              descriptor={editContractFields.manufacturerIdentifier}
              formikProps={formikProps}
              options={manufacturerIdentifierOptions}
              getOptionLabel={mi => mi.identifier}
              allowClear
            />
            <DateFieldWithLabel descriptor={editContractFields.effectiveDate} form={formikProps} />
            <DateFieldWithLabel descriptor={editContractFields.expirationDate} form={formikProps} />
            <SimpleDiscountField descriptor={editContractFields.wastedDiscount} form={formikProps} />
            <SimpleDiscountField descriptor={editContractFields.explantDiscount} form={formikProps} />
            <SimpleDiscountField descriptor={editContractFields.trialDiscount} form={formikProps} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6} lg={4}>
            <FormActionsComponent />
          </Col>
        </Row>
      </form>
    </If>
  );
};
