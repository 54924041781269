import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { FocusTrap } from '../../FocusTrap/FocusTrap';
import { If } from '../../If';
import { useModalStyles } from './modal.styles';
import { ModalProps } from './modalTypes';

export const ModalContent: FC<ModalProps> = (props) => {
  const { 'data-testid': testId, icon, children, actionsContent, title } = props;
  const classes = useModalStyles(props);
  const utilClasses = useUtilityStyles();

  return (
    <div className={classes.backdrop} data-testid={testId}>
      <div className={classes.panel} role="dialog">
        <h2 className={classes.title} id="dialog-title">
          <If condition={!!icon}>
            <FontAwesomeIcon icon={icon!} />
          </If>
          {title}
        </h2>

        <FocusTrap>
          <div className={joinArgs(classes.content, utilClasses.displayLineBreak, utilClasses.my3)}>
            {children}
          </div>

          <div className={joinArgs(classes.buttonsContainer, utilClasses.spaceBetween)}>
            {actionsContent}
          </div>
        </FocusTrap>
      </div>
    </div>
  );
};
