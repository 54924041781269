import { FC } from 'react';
import { MergedRequisition } from '../../../Data/Requisition';
import { dictionary } from '../../../dictionary';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import { useUtilityStyles } from '../../../Themes/utility.styles';
import { getFullName } from '../../../Utils/nameUtils';

interface RequisitionInfoDisplayProps {
  requisition: MergedRequisition;
}

export const ReqProcedureInfoDisplay: FC<RequisitionInfoDisplayProps> = ({ requisition }) => {
  const utilityClasses = useUtilityStyles();

  return (
    <section className={`details-section ${utilityClasses.h100} ${utilityClasses.px2} ${utilityClasses.py1} ${utilityClasses.backgroundNearWhite}`}>
      <h2>{dictionary.REQ_PROCEDURE_INFO_HEADER}</h2>

      <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_HOSPITAL} value={requisition.hospital?.name} />
      <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_DEPARTMENT} value={requisition.department?.name} />
      <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_PROCEDURE} value={requisition.procedure?.name} />
      <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_PHYSICIAN} value={getFullName(requisition.physician)} />
    </section>
  );
};
