import { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { SortValues } from './useSortedRows';

export const ArrowDownRounded = (): ReactElement => (
  <FontAwesomeIcon icon={faSortDown} />
);

export const ArrowUpRounded = (): ReactElement => (
  <FontAwesomeIcon icon={faSortUp} />
);

export function SortIcon({ direction }: { direction: SortValues | void }): ReactElement {
  if (direction === 'asc') {
    return (
      <div data-testid="sort-icon-asc">
        <ArrowUpRounded />
      </div>
    );
  } if (direction === 'desc') {
    return (
      <div data-testid="sort-icon-desc">
        <ArrowDownRounded />
      </div>
    );
  }

  return (
    <div data-testid="sort-icon">
      <FontAwesomeIcon icon={faSort} />
    </div>
  );
}
