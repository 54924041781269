import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

export interface HealthSystemForTable {
  id: number;
  name: string;
  hospitalNames: string;
}

export const healthSystemColumnProperties: ColumnProperty<HealthSystemForTable>[] = [
  { displayName: dictionary.HEALTH_SYSTEM_NAME_LABEL, name: 'name' },
  { displayName: dictionary.HEALTH_SYSTEMS_HOSPITALS_LABEL, name: 'hospitalNames' },
];
