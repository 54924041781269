import { FC, MouseEventHandler } from 'react';
import ReactTooltip from 'react-tooltip';
import { useButtonStyles } from '../../Themes/button.styles';
import { useUtilityStyles } from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import { If } from '../If';
import { Intent } from '../UiTypes';

export interface ButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  intent?: Intent;
  buttonStyle?: 'primary' | 'reverse' | 'destructive' | 'text' | 'altText' | 'icon';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  large?: boolean;
  leadingIcon?: JSX.Element;
  trailingIcon?: JSX.Element;
  'data-testid'?: string;
  className?: string;
  stretchToFit?: boolean;
  id?: string;
  tooltipTitle?: string;

}

export const Button: FC<ButtonProps> = (props) => {
  const {
    onClick,
    children,
    leadingIcon,
    trailingIcon,
    'data-testid': dataTestId,
    intent,
    disabled = false,
    buttonStyle = 'primary',
    large = false,
    type = 'button',
    className,
    stretchToFit,
    id,
    tooltipTitle,
  } = props;
  const buttonStyles = useButtonStyles(props);
  const utilStyles = useUtilityStyles();
  const actualIntent: Intent = intent ?? (buttonStyle === 'destructive' ? 'danger' : 'primary');
  let intentClass = '';
  if (actualIntent === 'danger') {
    intentClass = buttonStyles.dangerIntent;
  } else {
    intentClass = buttonStyles.primaryIntent;
  }
  const offset = tooltipTitle === 'Remove Association' ? { left: 20 } : undefined;

  return (
    <>
      <button
        className={joinArgs(
          buttonStyles.base,
          buttonStyle === 'reverse' ? buttonStyles.reverse : '',
          large ? buttonStyles.large : '',
          leadingIcon ? buttonStyles.leadingIcon : '',
          trailingIcon ? buttonStyles.trailingIcon : '',
          intentClass ?? '',
          buttonStyle === 'text' ? buttonStyles.text : '',
          buttonStyle === 'altText' ? buttonStyles.altText : '',
          buttonStyle === 'icon' ? buttonStyles.icon : '',
          stretchToFit ? utilStyles.w100 : '',
          className,
        )}
        onClick={onClick}
      type={type} // eslint-disable-line
        disabled={disabled}
        data-testid={tooltipTitle || dataTestId}
        data-tip
        data-for={`${tooltipTitle}-${id}`}
      >
        {leadingIcon}
        <If condition={buttonStyle !== 'icon'}>
          {children}
        </If>
        {trailingIcon}
      </button>
      <If condition={!!tooltipTitle}>
        <ReactTooltip id={`${tooltipTitle}-${id}`} place="bottom" effect="solid" offset={offset} className={utilStyles.fwBold}>
          {tooltipTitle}
        </ReactTooltip>
      </If>
    </>
  );
};
