import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch } from 'react-redux';
import Hospital, { HospitalBase } from '../../../Data/Hospital';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { hospitalService } from '../../../Services/HospitalService';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { Button } from '../../../Shared/buttons/Button';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { useUtilityStyles } from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { getFormikInitialValues } from '../../../Utils/formik.utils';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';
import { physicianAddHospitalFormFields, PhysicianAddHospitalFormFields, validatePhysicianAddHospitalForm } from './physician.utils';
import { useSubFormKeyPress } from '../../../Shared/SubFormKeyPress/useSubFormKeyPress';

interface PhysicianAddHospitalsProps {
  addHospital: (hospital: PhysicianAddHospitalFormFields) => void;
  addedHospitalIds: number[];
  errorHighlight?: boolean;
  archived?: boolean;
}

export const PhysicianAddHospitals: FC<PhysicianAddHospitalsProps> = ({ addHospital, addedHospitalIds, errorHighlight = false, archived }) => {
  const dispatch = useDispatch();
  const utilClasses = useUtilityStyles();
  const [hospitalBases, setHospitalBases] = useState<HospitalBase[]>([]);
  const [selectedHospital, setSelectedHospital] = useState<Hospital>();

  useEffect(() => {
    (async () => {
      try {
        const hospitalBasesResult = await hospitalService.getAdminHospitals();
        setHospitalBases(hospitalBasesResult);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
      }
    })();
  }, [dispatch]);

  const formikProps = useFormik({
    initialValues: getFormikInitialValues<PhysicianAddHospitalFormFields>(physicianAddHospitalFormFields),
    validate: validatePhysicianAddHospitalForm,
    onSubmit: (formValues, formikHelpers) => {
      addHospital({
        hospital: formValues.hospital,
        departments: formValues.departments,
        hospitalPhysicianIdentifier: formValues.hospitalPhysicianIdentifier.trim() || null,
      });
      formikHelpers.resetForm();
    },
  });

  const handleKeyPress = useSubFormKeyPress(formikProps.handleSubmit);

  const { hospital } = formikProps.values;

  useEffect(() => {
    (async () => {
      if (hospital) {
        setSelectedHospital(undefined);
        try {
          const hosp = await hospitalService.getHospitalById(hospital.id);
          setSelectedHospital(hosp);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.PHYSICIAN_HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospital]);

  const hospOptions = useMemo(() => hospitalBases.filter(h => !addedHospitalIds.includes(h.id)), [addedHospitalIds, hospitalBases]);
  const deptOptions = useMemo(() => selectedHospital?.departments || [], [selectedHospital]);

  const sortedHospitals = hospOptions.sort(alphanumericSortDescriptor('name', 1));
  const sortedActiveDepartments = deptOptions?.filter(d => !d.archived).sort(alphanumericSortDescriptor('name', 1));

  const rootElementClasses = joinArgs(utilClasses.backgroundNearWhite, utilClasses.px2, utilClasses.py1, errorHighlight ? utilClasses.errorBorder : '');

  return (
    <Col xs={12} data-testid="hospital-associations" className={rootElementClasses}>
      <h2 className={utilClasses.m0}>{dictionary.PHYSICIAN_HOSPITAL_SUBFORM_HEADER}</h2>
      <Row>
        <Col xs={6} className={utilClasses.pr2}>
          <Autocomplete
            options={sortedHospitals}
            formikProps={formikProps}
            descriptor={physicianAddHospitalFormFields.hospital}
            disabled={!hospitalBases.length || archived}
            onKeyPress={handleKeyPress}
          />
          <SimpleFieldWithLabel
            form={formikProps}
            descriptor={physicianAddHospitalFormFields.hospitalPhysicianIdentifier}
            disabled={archived}
            onKeyPress={handleKeyPress}
          />
        </Col>
        <Col xs={6} className={joinArgs(utilClasses.pl2, utilClasses.dividerBorder)}>
          <Autocomplete
            multiple
            options={sortedActiveDepartments}
            formikProps={formikProps}
            descriptor={physicianAddHospitalFormFields.departments}
            disabled={!selectedHospital || archived}
            onKeyPress={handleKeyPress}
          />
        </Col>
      </Row>

      <Row className={utilClasses.mt2}>
        <Col xs={12} className={utilClasses.textRight}>
          <Button
            data-testid="add-associations"
            buttonStyle="reverse"
            leadingIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={formikProps.submitForm}
            disabled={archived}
          >
            {dictionary.PHYSICIAN_ADD_HOSPITAL_BUTTON}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
