import React, { FC, useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import {
  Embed,
  IReportEmbedConfiguration,
  models,
  Report,
} from 'powerbi-client';
import { useParams } from 'react-router-dom';
import { reportService } from '../../Services/ReportService';
import useReportStyle from '../../Themes/reports.styles';

interface params {
  reportId: string;
}

export const ReportDetail: FC = () => {
  const classes = useReportStyle();
  const [sReport, setReport] = useState<Report>();
  const [embedConfig, setEmbedConfig] = useState<IReportEmbedConfiguration>({
    type: 'report',
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: models.TokenType.Embed,
  });
  const { reportId } = useParams<params>();
  useEffect(() => {
    const fetchData = async () => {
      const powerBiRes = await reportService.getReportEmbed(reportId);
      return powerBiRes;
    };
    fetchData()
      .then((res) => {
        const embedParams = res;
        const report = embedParams.embedReport.pop();
        const embedConfigRes = {
          type: 'report',
          id: report?.reportId,
          embedUrl: report?.embedUrl,
          accessToken: embedParams.embedToken.token,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: true,
                visible: true,
              },
            },
            zoomLevel: 1,
          },
        };
        setEmbedConfig(embedConfigRes);
      })
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getPages = async (report: Report) => report.getPages();
    if (sReport) {
      // eslint-disable-next-line no-console
      getPages(sReport).then(console.log).catch(console.error);
    }
  }, [sReport]);

  return (
    <PowerBIEmbed
      embedConfig={embedConfig}
      cssClassName={classes.reportStyle}
      getEmbeddedComponent={(embedObject: Embed) => {
        setReport(embedObject as Report);
      }}
    />
  );
};
