import { FC } from 'react';
import { FormikValues, useFormik } from 'formik';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../Shared/buttons/Button';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { dictionary } from '../../../dictionary';
import { Modal } from '../../../Shared/modals/Modal/Modal';
import { FormFieldDescriptor } from '../../../Shared/fields/formTypes';
import { validateCharacterLimit, validateRequiredFields } from '../../../Utils/validationUtils';
import { createDescriptor } from '../../../Utils/formik.utils';
import { useUtilityStyles } from '../../../Themes/utility.styles';

interface ReqReturnToVendorModalProps {
  isOpen: boolean;
  cancelOnClick: () => void;
  onSubmit: (reason: string) => void;
}

const returnReasonField: FormFieldDescriptor = createDescriptor({ label: dictionary.REQ_RETURN_REASON_LABEL, required: true, name: 'returnReason' });

export const ReqReturnToVendorModal: FC<ReqReturnToVendorModalProps> = ({ isOpen, cancelOnClick, onSubmit }) => {
  const utilClasses = useUtilityStyles();

  const validate = (values: FormikValues) => ({
    ...validateCharacterLimit(values, [returnReasonField], 255),
    ...validateRequiredFields(values, [returnReasonField]),
  });
  const submitHandler = (values: FormikValues) => onSubmit(values[returnReasonField.name]);
  const formikConfig = useFormik({
    initialValues: { [returnReasonField.name]: '' },
    validate,
    onSubmit: submitHandler,
    validateOnChange: true,
    validateOnBlur: true,
  });

  return (
    <Modal
      data-testid="return-modal"
      title={dictionary.REQ_RETURN_DESC}
      isOpen={isOpen}
      icon={faInfoCircle}
      size="sm"
      actionsContent={(
        <>
          <Button buttonStyle="reverse" onClick={() => cancelOnClick()} data-testid="modal-cancel">
            {dictionary.CANCEL}
          </Button>
          <Button type="submit" data-testid="modal-return" onClick={formikConfig.submitForm}>
            {dictionary.REQ_RETURN_SUBMIT_BUTTON}
          </Button>
        </>
      )}
    >
      <SimpleFieldWithLabel
        className={utilClasses.mt075n}
        form={formikConfig}
        descriptor={returnReasonField}
        value={formikConfig.values[returnReasonField.name]}
        placeholder={dictionary.REQ_RETURN_REASON_PLACEHOLDER}
      />
    </Modal>
  );
};
