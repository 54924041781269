import { createUseStyles } from 'react-jss';
import Colors from './colors';
import { fontWeights } from './fontWeights';

export const useUtilityStyles = createUseStyles({
  // Text Colors
  textNearBlack: { color: Colors.nearBlack },
  textGray: { color: Colors.gray },
  textNearWhite: { color: Colors.nearWhite },
  textTrueWhite: { color: Colors.trueWhite },
  textBlue: { color: Colors.blue },
  textBlueHover: { color: Colors.kingfisher },
  textGreen: { color: Colors.green },
  textTookishGreen: { color: Colors.tookishGreen },
  textRed: { color: Colors.red },
  textOrange: { color: Colors.orange },
  textDisabled: { color: Colors.disabled },
  textGrayMinimumContrast: { color: Colors.grayMinimumContrast },

  // Background Colors
  backgroundNearBlack: { backgroundColor: Colors.nearBlack },
  backgroundGray: { backgroundColor: Colors.gray },
  backgroundNearWhite: { backgroundColor: Colors.nearWhite },
  backgroundTrueWhite: { backgroundColor: Colors.trueWhite },
  backgroundBlue: { backgroundColor: Colors.blue },
  backgroundGreen: { backgroundColor: Colors.green },
  backgroundRed: { backgroundColor: Colors.red },
  backgroundOrange: { backgroundColor: Colors.orange },
  backgroundDisabled: { backgroundColor: Colors.disabled },
  backgroundGrayMinimumContrast: { backgroundColor: Colors.grayMinimumContrast },
  backgroundCantaloupe: { backgroundColor: Colors.cantaloupe },

  backgroundNearBlackHover: { '&:hover': { backgroundColor: Colors.nearBlack } },
  backgroundGrayHover: { '&:hover': { backgroundColor: Colors.gray } },
  backgroundNearWhiteHover: { '&:hover': { backgroundColor: Colors.nearWhite } },
  backgroundTrueWhiteHover: { '&:hover': { backgroundColor: Colors.trueWhite } },
  backgroundBlueHover: { '&:hover': { backgroundColor: Colors.kingfisher } },
  backgroundGreenHover: { '&:hover': { backgroundColor: Colors.green } },
  backgroundRedHover: { '&:hover': { backgroundColor: Colors.red } },
  backgroundOrangeHover: { '&:hover': { backgroundColor: Colors.orange } },
  backgroundDisabledHover: { '&:hover': { backgroundColor: Colors.disabled } },
  backgroundGrayMinimumContrastHover: { '&:hover': { backgroundColor: Colors.grayMinimumContrast } },

  // display: flex items
  inlineFlex: { display: 'inline-flex' },
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  flexRow: { display: 'flex', flexDirection: 'row' },
  trueCenter: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  flex1: { flex: '1 1 auto' },
  alignCenter: { alignItems: 'center' },
  alignStart: { alignItems: 'flex-start' },
  alignEnd: { alignItems: 'flex-end' },
  spaceBetween: { justifyContent: 'space-between' },
  flexEnd: { justifyContent: 'flex-end' },
  flexWrap: { flexWrap: 'wrap' },
  flexGrow: { flexGrow: 1 },

  // spacing -- margin
  m0: { margin: 0 },
  m1: { margin: '1rem' },
  m2: { margin: '1.25rem' },
  m3: { margin: '1.5rem' },
  mx1: { marginLeft: '1rem', marginRight: '1rem' },
  mx2: { marginLeft: '1.25rem', marginRight: '1.25rem' },
  mx3: { marginLeft: '1.5rem', marginRight: '1.5rem' },
  my05: { marginTop: '0.5rem', marginBottom: '0.5rem' },
  my1: { marginTop: '1rem', marginBottom: '1rem' },
  my2: { marginTop: '1.25rem', marginBottom: '1.25rem' },
  my3: { marginTop: '1.5rem', marginBottom: '1.5rem' },

  mt075n: { marginTop: '-.75rem' },
  mt0: { marginTop: 0 },
  mr0: { marginRight: 0 },
  mb0: { marginBottom: 0 },
  ml0: { marginLeft: 0 },
  mt025: { marginTop: '.25rem' },
  mr025: { marginRight: '.25rem' },
  mb025: { marginBottom: '.25rem' },
  ml025: { marginLeft: '.25rem' },
  mt05: { marginTop: '.5rem' },
  mr05: { marginRight: '.5rem' },
  mb05: { marginBottom: '.5rem' },
  ml05: { marginLeft: '.5rem' },
  mt1: { marginTop: '1rem' },
  mr1: { marginRight: '1rem' },
  mb1: { marginBottom: '1rem' },
  ml1: { marginLeft: '1rem' },
  mt2: { marginTop: '1.25rem' },
  mr2: { marginRight: '1.25rem' },
  mb2: { marginBottom: '1.25rem' },
  ml2: { marginLeft: '1.25rem' },
  mt3: { marginTop: '1.5rem' },
  mr3: { marginRight: '1.5rem' },
  mb3: { marginBottom: '1.5rem' },
  ml3: { marginLeft: '1.5rem' },
  mt4: { marginTop: '1.75rem' },
  mr4: { marginRight: '1.75rem' },
  mb4: { marginBottom: '1.75rem' },
  ml4: { marginLeft: '1.75rem' },
  mt5: { marginTop: '2rem' },
  mr5: { marginRight: '2rem' },
  mb5: { marginBottom: '2rem' },
  ml5: { marginLeft: '2rem' },
  mt6: { marginTop: '2.5rem' },
  'sm-mt1': {
    marginTop: '1rem',
  },
  'sm-mt2': {
    marginTop: '2rem',
  },
  'sm-mt0': {
  },
  '@media only screen and (min-width: 576px)': {
    'sm-mt0': {
      marginTop: 0,
    },
  },

  // spacing -- padding
  p0: { padding: 0 },
  p1: { padding: '1rem' },
  p2: { padding: '2rem' },
  p24: { padding: '2.4rem' },
  px1: { paddingLeft: '1rem', paddingRight: '1rem' },
  px2: { paddingLeft: '2rem', paddingRight: '2rem' },
  py1: { paddingTop: '1rem', paddingBottom: '1rem' },
  pt0: { paddingTop: 0 },
  pt1: { paddingTop: '1rem' },
  pr025: { paddingRight: '0.25rem' },
  pr05: { paddingRight: '0.5rem' },
  pr08: { paddingRight: '0.8rem' },
  pr1: { paddingRight: '1rem' },
  pr2: { paddingRight: '2rem' },
  pr9: { paddingRight: '9rem' },
  pb1: { paddingBottom: '1rem' },
  pl1: { paddingLeft: '1rem' },
  pb2: { paddingBottom: '2rem' },
  pb7: { paddingBottom: '7rem' },
  pl2: { paddingLeft: '2rem' },
  pl3: { paddingLeft: '3rem' },

  // sizing
  w30r: { width: '30rem' },
  w4r: { width: '4rem' },
  w100: { width: '100%' },
  w30pct: { width: '30%' },
  mw30pct: { minWidth: '30%' },
  mw6r: { minWidth: '6rem' },
  h100: { height: '100%' },
  hAuto: { height: 'auto' },
  maxAppWidth: {
    width: '100%',
    maxWidth: '112.5rem',
    paddingLeft: '2.375rem',
    paddingRight: '2.375rem',
  },

  // text formatting
  underline: { textDecoration: 'underline' }, // it'd be nice to get rid of this className
  tdUnderline: { textDecoration: 'underline' },
  tdUnderlineHover: { '&:hover': { textDecoration: 'underline' } },
  tdNone: { textDecoration: 'none' },
  textLeft: { textAlign: 'left' },
  textRight: { textAlign: 'right' },
  textCenter: { textAlign: 'center' },
  uppercase: { textTransform: 'uppercase' },
  noWrap: { whiteSpace: 'pre' },
  displayLineBreak: { whiteSpace: 'pre-line' },

  // font weight classNames are based on the Open Type common Names
  // https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping
  fwLight: { fontWeight: fontWeights.light },
  fwNormal: { fontWeight: fontWeights.normal },
  fwBold: { fontWeight: fontWeights.bold },

  fs12: { fontSize: '.75rem' },
  fs14: { fontSize: '.875rem' },
  fs18: { fontSize: '1.125rem' },
  fs21: { fontSize: '1.3125rem' },
  fs30: { fontSize: '1.875rem' },

  // utility classes
  transparentButton: { border: 0, backgroundColor: 'transparent' },
  topMargin: { marginTop: '20px' },
  noBorder: { border: 0 },
  dividerBorder: { borderLeft: `0.0625rem solid ${Colors.darkGray}` },
  horizontalRuleBorder: { borderBottom: `0.0625rem solid ${Colors.darkGray}` },
  errorBorder: { boxShadow: `0 0 0 0.125rem ${Colors.red}` },
  boldLabel: { fontWeight: fontWeights.bold, fontSize: '0.875rem' },
  singleColumnContainer: { maxWidth: '900px' },
  pointerEventsNone: { pointerEvents: 'none' },
  pointerEventsAll: { pointerEvents: 'all' },
  cursorDefault: { cursor: 'default' },
  noTextTransform: { textTransform: 'none' },
  positionRelative: { position: 'relative' },
  scrollX: { overflowX: 'scroll' },

  // Table related classes
  collapsed: { visibility: 'collapse' },
  visible: { visibility: 'visible' },
  borderCollapse: { borderCollapse: 'collapse' },
  tableBottomBorder: { borderBottom: `0.0625rem solid ${Colors.darkGray}` },
  wrapCell: { '&td': { overflowWrap: 'anywhere' } },

  // screen-reader stuff
  srOnly: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    borderWidth: 0,
  },

  // more complex reusable bits
  box: {
    backgroundColor: Colors.trueWhite,
    boxShadow: '0 0 .25rem 0 rgba(21, 25, 27, 0.25)',
    border: `.0625rem solid ${Colors.disabledDark}`,
    padding: '1.25rem',
  },
}, { index: 2 });

export default useUtilityStyles;
