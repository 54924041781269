import { FormikValues, FormikErrors } from 'formik';
import { ManufacturerBase, ManufacturerRequest } from '../../../Data/Manufacturer';
import { dictionary } from '../../../dictionary';
import { createDescriptor, getFieldDescriptorMap, getFormikInitialValues } from '../../../Utils/formik.utils';
import { validateCharacterLimit, validateRequiredFields } from '../../../Utils/validationUtils';

export interface ManufacturerFormFields {
  name: string;
}

export const manufacturerFormFields = getFieldDescriptorMap<ManufacturerFormFields>([
  createDescriptor({ name: 'name', label: dictionary.MANUFACTURER_FORM_NAME, required: true }),
]);

export const validateManufacturerForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { name } = manufacturerFormFields;

  return {
    ...validateCharacterLimit(values, [name], 255),
    ...validateRequiredFields(values, Object.values(manufacturerFormFields)),
  };
};

export const getMfctFormInitialVals = (manufacturer?: ManufacturerBase): ManufacturerFormFields =>
  getFormikInitialValues<ManufacturerFormFields>(
    manufacturerFormFields, { name: manufacturer?.name }
  );

export const getMfctUpdateRequestFromFormFields = (values: ManufacturerFormFields): ManufacturerRequest => ({ name: values.name });
