import { FC, useEffect, useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../Shared/buttons/Button';
import { dictionary } from '../../../../dictionary';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../../redux/initialState';
import { hospitalService } from '../../../../Services/HospitalService';
import { DepartmentStub } from '../../../../Data/Department';
import { PhysicianHospital } from '../../../../Data/Physician';
import { EditPhysicianHospitalFields, getPhysicianHospitalModalValues, validateEditPhysicianHospitalFields } from './editPhysicianHospitalModal.utils';
import { EditPhysicianHospitalModalForm } from './EditPhysicianHospitalModalForm';
import { Modal } from '../../../../Shared/modals/Modal/Modal';

interface EditPhysicianHospitalModalProps {
  association?: PhysicianHospital;
  onEditAssociation: (assoc: PhysicianHospital) => void;
  closeModal: () => void;
}

export const EditPhysicianHospitalModal: FC<EditPhysicianHospitalModalProps> = (props) => {
  const { association, onEditAssociation, closeModal } = props;
  const [hospitalDepartments, setHospitalDepartments] = useState<DepartmentStub[]>();
  const hospitalId = association?.id;
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (hospitalId) {
        try {
          const fetchedHospital = await hospitalService.getHospitalById(hospitalId!);
          setHospitalDepartments(fetchedHospital.departments.map(dept => ({
            ...dept,
            hospitalId: fetchedHospital.id,
            hospitalName: fetchedHospital.name,
          })));
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.EDIT_PHYSICIAN_ASSOCIATIONS_HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospitalId]);

  const formikProps = useFormik({
    initialValues: getPhysicianHospitalModalValues(association),
    onSubmit: async (vals: EditPhysicianHospitalFields) => {
      if (!vals.departments.length) {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_PHYSICIAN_NO_ASSOCIATIONS_ERROR));
      } else {
        onEditAssociation({ ...association!, ...vals });
        closeModal();
      }
    },
    validate: validateEditPhysicianHospitalFields,
    enableReinitialize: true,
  });

  if (!association) return null;

  return (
    <Modal
      data-testid="edit-association-modal"
      title={dictionary.EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_MODAL_TITLE}
      isOpen={!!association}
      size="md"
      icon={faInfoCircle}
      actionsContent={(
        <>
          <Button buttonStyle="reverse" onClick={() => closeModal()} data-testid="cancel-edit-association-button">
            {dictionary.CANCEL}
          </Button>
          <Button type="submit" onClick={() => { formikProps.submitForm(); }} data-testid="update-edit-association-button">
            {dictionary.EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_MODAL_SUBMIT}
          </Button>
        </>
      )}
    >
      <EditPhysicianHospitalModalForm
        formikProps={formikProps}
        association={association!}
        departments={hospitalDepartments}
      />
    </Modal>
  );
};
