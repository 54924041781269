import { FC } from 'react';
import { dictionary } from '../../../../dictionary';
import { ProductsBaseTable } from './ProductsBaseTable';
import { ContractProductForTable, productsOnContractColumnProperties } from './productsOnContractTable.utils';

type ProductsOnContractTableProps = {
  formattedProducts: ContractProductForTable[],
  isLoading: boolean,
};

export const ProductsOnContractTable: FC<ProductsOnContractTableProps> = (props) => {
  const { isLoading, formattedProducts } = props;

  return (
    <div data-testid="products-on-contract-table">
      <ProductsBaseTable
        formattedProducts={formattedProducts}
        columns={productsOnContractColumnProperties}
        noDataMessage={dictionary.CONTRACT_PRODUCTS_TABLE_EMPTY}
        isLoading={isLoading}
        initialSortOrder="desc"
        initialSortColumn="catalogNumber"
      />
    </div>
  );
};
