import apiClient from './apiClient';
import { HealthSystem, HealthSystemRequest } from '../Data/HealthSystem';

export const healthSystemsUrl = '/health-systems';
export const healthSystemByIdUrl = (healthSystemId: number): string => `${healthSystemsUrl}/${healthSystemId}`;

const getHealthSystems = async (): Promise<HealthSystem[]> => apiClient.get({ url: healthSystemsUrl });

const createHealthSystem = async (req: HealthSystemRequest): Promise<void> =>
  apiClient.post({ url: healthSystemsUrl, data: JSON.stringify(req) });

const updateHealthSystem = async (id: number, healthSystem: HealthSystemRequest): Promise<void> =>
  apiClient.put({ url: healthSystemByIdUrl(id), data: healthSystem });

const getHealthSystemById = async (healthSystemId: number): Promise<HealthSystem> =>
  apiClient.get<HealthSystem>({ url: healthSystemByIdUrl(healthSystemId) });

export const healthSystemService = {
  get: getHealthSystems,
  create: createHealthSystem,
  update: updateHealthSystem,
  getHealthSystemById,
};
