import { FC } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../Shared/buttons/Button';
import { dictionary } from '../../../dictionary';
import { Modal } from '../../../Shared/modals/Modal/Modal';

type Props = {
  isOpen: boolean,
  cancelOnClick: () => void,
  continueOnClick: () => void,
};

export const UpdateInformationModal: FC<Props> = (props) => {
  const { isOpen, cancelOnClick, continueOnClick } = props;

  return (
    <Modal
      data-testid="update-information-modal"
      title="Update this Information"
      isOpen={isOpen}
      icon={faInfoCircle}
      actionsContent={(
        <>
          <Button buttonStyle="reverse" onClick={cancelOnClick} data-testid="modal-cancel">
            {dictionary.CANCEL}
          </Button>
          <Button buttonStyle="primary" onClick={continueOnClick} data-testid="modal-continue">
            {dictionary.CONTINUE}
          </Button>
        </>
      )}
    >
      {dictionary.REQUISITIONS_CASE_DATE_CHANGED_MODAL_TEXT}
    </Modal>
  );
};
