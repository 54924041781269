import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/initialState';
import { dictionary } from '../../../../dictionary';
import { FormFlowStepComponent } from '../../../../Shared/FormFlow/FormFlow';
import ContractBaseInfoDisplay from '../../shared/ContractBaseInfoDisplay';
import { useUtilityStyles } from '../../../../Themes/utility.styles';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { Divider } from '../../../../Shared/Divider/Divider';
import { ProductsOnContract } from '../../shared/ProductsOnContract';
import { If } from '../../../../Shared/If';
import { useProductUploadCounts } from '../ProductPricingInfo/useProductUploadCount';
import { Contract } from '../../../../Data/Contract';

export const ReviewSubmitContract: FC<FormFlowStepComponent<Contract>> = ({ FormActionsComponent, onFormStepComplete }) => {
  const { errors: productErrorCount } = useProductUploadCounts();
  const utilClasses = useUtilityStyles();
  const { newContract } = useSelector((state: ReduxState) => state.contract);

  const onSubmitAction = () => onFormStepComplete(newContract);

  return (
    <>
      <If condition={!!newContract.hospitals?.length}>
        <ContractBaseInfoDisplay contract={newContract} />
      </If>

      <section className={joinArgs(utilClasses.py1)}>
        <div className="details-label">
          <div role="alert" className={joinArgs(utilClasses.fs18, utilClasses.fwBold, utilClasses.pt1, utilClasses.textRed)}>
            {productErrorCount ? <p>{`${productErrorCount} ${dictionary.CONTRACT_EXCEPTION_MESSAGE}`}</p> : ''}
          </div>
        </div>
      </section>

      <FormActionsComponent onSubmitAction={onSubmitAction} />

      <Divider />

      <ProductsOnContract products={newContract.products} />
    </>
  );
};
