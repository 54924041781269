import { ReactElement } from 'react';
import ReactTooltip from 'react-tooltip';
import { joinArgs } from '../../Utils/arrayUtils';
import { alphanumericSortDescriptor, SortDescriptor } from '../../Utils/SortUtils';
import { getAriaLabel } from './sortableTable.utils';
import { SortIcon } from './SortableIcon';
import { ColumnProperty } from './SortableTable.types';
import { useSortableTableStyles } from './sortableTable.styles';
import { SortValues } from './useSortedRows';
import { useUtilityStyles } from '../../Themes/utility.styles';

interface SortableTableHeaderCellProps<T> {
  column: ColumnProperty<T>;
  sortField: keyof T;
  sortDirection: SortValues;
  handleSort: (field: keyof T, sortDescriptor: SortDescriptor) => void;
}

export const SortableTableHeaderCell = <T extends unknown>({ column, sortField, sortDirection, handleSort }: SortableTableHeaderCellProps<T>): ReactElement => {
  const utilityClasses = useUtilityStyles();
  const classes = useSortableTableStyles();
  const headerAlignClass = !column.align || column.align === 'left' ? utilityClasses.textLeft : utilityClasses.textRight;
  const headerClassName = joinArgs(utilityClasses.fwBold, classes.paddedCell, (column.className ?? ''), headerAlignClass);
  const tooltipTitle = 'Sort By';

  return (
    <th data-testid={`sortable-table-th-${column.name}`} className={headerClassName} style={{ width: column.width || 'auto' }}>
      <div className={classes.flex}>
        {column.displayName}
        <button
          className={joinArgs(
            utilityClasses.transparentButton,
            classes.sortButtons,
          )}
          onClick={() => handleSort(column.name, column.sortDescriptor || alphanumericSortDescriptor)}
          type="button"
          data-tip
          data-for={tooltipTitle}
          aria-label={getAriaLabel<any>(column.displayName, column.name, sortField, sortDirection)}
        >
          <SortIcon direction={(sortField === column.name ? sortDirection : undefined)} />
        </button>
        <ReactTooltip id={tooltipTitle} place="bottom" effect="solid" className={utilityClasses.fwBold}>
          {tooltipTitle}
        </ReactTooltip>
      </div>
    </th>
  );
};
