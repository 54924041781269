import { useMemo, useState } from 'react';
import { Column, TableInstance, TableOptions, UsePaginationInstanceProps, useRowSelect, UseRowSelectOptions, useSortBy, usePagination, useExpanded, UseExpandedInstanceProps, UseExpandedOptions, useTable, TableState, UseSortByState } from 'react-table'; // eslint-disable-line max-len
import { useDispatch } from 'react-redux';
import RequisitionProduct from '../../../Data/RequisitionProduct';
import { dictionary } from '../../../dictionary';
import { StatusRenderReqCell } from '../../../Shared/ReqStatusBadge/ReqStatusCell';
import { ExpandCell } from '../../../Shared/tables/ExpandCell';
import { getActionsCellComponent } from '../../../Shared/tables/getActionsCellComponent';
import { BusinessRulesCell } from '../../../Shared/tables/BusinessRulesCell';
import { getSlashDateCellComponent } from '../../../Shared/tables/SlashDateCell';
import { buildTableCols } from '../../../Shared/tables/tableUtils';
import { getTruncatedCellComponent } from '../../../Shared/tables/TruncatedCell';
import { ReqForInterfacesTable } from '../InterfaceIndex/interfacesIndex.utils';
import { EditableCell } from '../../../Shared/tables/EditableCell';
import { requisitionApiService } from '../../../Services/RequisitionService';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../redux/initialState';
import { UpdateButtonCell } from '../../../Shared/tables/UpdateCellButton';
import { EditableCurrencyCell } from '../../../Shared/tables/EditableCurrencyCell';

type UngroupedReqOptions = TableOptions<ReqForInterfacesTable> & UseExpandedOptions<ReqForInterfacesTable> & UseRowSelectOptions<ReqForInterfacesTable>;

export type ReqProductsTableInstance = TableInstance<ReqForInterfacesTable>
& UseExpandedInstanceProps<ReqForInterfacesTable>
& UsePaginationInstanceProps<ReqForInterfacesTable>;

export const useReqExceptionTable = (
  requisitions: ReqForInterfacesTable[],
  initialState: Partial<TableState<ReqForInterfacesTable> & UseSortByState<ReqForInterfacesTable>>
): ReqProductsTableInstance => {
  const tableReqs = requisitions as ReqForInterfacesTable[];
  const memoizedReqs = useMemo(() => tableReqs.reverse(), [tableReqs]);
  const getRowId = useMemo(() => (requisition: ReqForInterfacesTable) => requisition.id.toString(), []);

  const columns: Column<ReqForInterfacesTable>[] = useMemo(() => {
    const Actions = getActionsCellComponent([
      { key: 'businessRulesHelp', Cell: BusinessRulesCell },
      { key: 'exceptionsProdExpand', Cell: ExpandCell },
    ]);

    const cols = buildTableCols<ReqForInterfacesTable>([
      { Header: dictionary.REQ_STATUS, accessor: 'status', Cell: StatusRenderReqCell },
      { Header: dictionary.REQ_ID, accessor: 'id', Cell: getTruncatedCellComponent(50) },
      { Header: dictionary.FORM_CONTROL_LABEL_PATIENT_MRN, accessor: 'mrn' },
      { Header: dictionary.REQ_CASE_DATE, accessor: 'caseDate', Cell: getSlashDateCellComponent() },
      { Header: dictionary.REQ_DATE_CREATED, accessor: 'createdDate', Cell: getSlashDateCellComponent() },
      { Header: dictionary.REQ_DATE_APPROVED, accessor: 'approvedDate', Cell: getSlashDateCellComponent() },
      { Header: dictionary.REQ_HOSPITAL, accessor: 'hospitalName', Cell: getTruncatedCellComponent(50) },
      { Header: dictionary.REQ_DEPT, accessor: 'departmentName', Cell: getTruncatedCellComponent(50) },
      { Header: dictionary.REQ_MANUFACTURER, accessor: 'manufacturerName', Cell: getTruncatedCellComponent(50) },
      { Header: dictionary.INTERFACE_MANUFACTURER_ID, accessor: 'hospitalManufacturerIdentifier' },
      { Header: dictionary.REQ_VALUE, accessor: 'value' },
      { id: 'actionsCell', Cell: Actions, defaultCanSort: false },
    ]);

    return cols;
  }, []);

  const ungroupedTableOptions: UngroupedReqOptions = { data: memoizedReqs, columns, autoResetExpanded: false, getRowId, autoResetSelectedRows: false, initialState };

  return useTable<ReqForInterfacesTable>(
    ungroupedTableOptions,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  ) as ReqProductsTableInstance;
};

export const useReqExceptionSubtable = (product: RequisitionProduct[]): TableInstance<RequisitionProduct> => {
  const memoizedProduct = useMemo(() => product, [product]);
  const [updatedProduct, setUpdatedProduct] = useState(memoizedProduct);
  const dispatch = useDispatch();

  const updateData = (rowIndex: any, column: any, value: any | undefined) => {
    setUpdatedProduct(product.map((prod, index) => {
      if (index === rowIndex) {
        const updatedProd = prod;
        updatedProd[column as keyof RequisitionProduct] = value;
        return updatedProd;
      }
      return prod;
    }));
  };

  const submitRowData = async (prod: RequisitionProduct) => {
    const { orderIdentifier, price } = prod;
    try {
      await requisitionApiService.patchProducts(prod!.id!, {
        orderIdentifier,
        price,
      });
      dispatch(addNotification(NotificationType.success, dictionary.INTERFACE_EDIT_PRODUCT_SUCCESS));
    } catch (e) {
      dispatch(addNotification(NotificationType.error, dictionary.INTERFACE_EDIT_PRODUCT_ERROR));
    }
  };

  const columns = useMemo(() => {
    const Actions = getActionsCellComponent([
      { key: 'patchRequisitionProduct', Cell: UpdateButtonCell },
    ]);

    const cols = buildTableCols<RequisitionProduct>([
      { Header: dictionary.PRODUCT_CATALOG_NUMBER, accessor: 'catalogNumber' },
      { Header: dictionary.PRODUCT_CONTRACT_ID, accessor: 'contractIdentifier' },
      { Header: dictionary.PRODUCT_ITEM_IDENTIFIER, accessor: 'orderIdentifier', Cell: EditableCell },
      { Header: dictionary.PRODUCT_CATEGORY_NAME_LABEL, accessor: 'productCategoryName' },
      { Header: dictionary.PRODUCT_CONTRACT_PRICE, accessor: 'contractPrice' },
      { Header: dictionary.REQ_PRODUCT_PRICE, accessor: 'price', Cell: EditableCurrencyCell },
      { id: 'actionsCell', Cell: Actions, defaultCanSort: false },
    ]);

    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProduct]);

  return useTable<RequisitionProduct>({ data: memoizedProduct, columns, updateData, submitRowData });
};
