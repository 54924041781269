import { useFormik } from 'formik';
import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { HospMfctIdentifierResponse, HospMfctIdentifierUpdateRequest } from '../../../Data/ManufacturerIdentifier';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { getFormikInitialValues } from '../../../Utils/formik.utils';
import { HospMfctIdentifierAssocFormFields, hospMfctIdentifierAssocFormFields, validateEditHospMfctIdentifierAssocForm } from '../shared/hospMfctIdentifier.utils';
import { HospMfctIdentifierAssocFields } from '../shared/HospMfctIdentifierAssocFields';

export const EditHospMfctIdentifierForm: FC<FormFlowStepComponent<HospMfctIdentifierUpdateRequest, HospMfctIdentifierResponse>> = (props) => {
  const { FormActionsComponent, onFormStepComplete, editSource } = props;
  const initialValues = getFormikInitialValues<HospMfctIdentifierAssocFormFields>(
    hospMfctIdentifierAssocFormFields,
    { identifier: editSource?.identifier, siteIdentifier: editSource?.siteIdentifier, siteName: editSource?.siteName }
  );

  const formikProps = useFormik({
    initialValues,
    validate: validateEditHospMfctIdentifierAssocForm,
    onSubmit: (formValues) => {
      onFormStepComplete(
        { identifier: formValues.identifier, siteIdentifier: formValues.siteIdentifier, siteName: formValues.siteName }
      );
    },
  });

  return (
    <form onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={3}>
          <HospMfctIdentifierAssocFields formikProps={formikProps} editHospMfctIdentifier={editSource} />
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
