import { FC } from 'react';
import { dictionary } from '../../dictionary';

interface SimpleDisplayFieldProps {
  label: string;
  value?: string | number | JSX.Element | null;
  className?: string;
  'data-testid'?: string;
}

export const SimpleDisplayField: FC<SimpleDisplayFieldProps> = ({ label, value, className, 'data-testid': dataTestId }) => (
  <div className={className} data-testid={dataTestId}>
    <label>{label}</label>
    <div>{value || dictionary.EMPTY_FIELD_MARK}</div>
  </div>
);
