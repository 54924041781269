import Capitation, { CapitationRequest } from '../Data/Capitation';
import apiClient from './apiClient';
import { CapitationCategory, CapitationCategoryRequest, UpdateCapitationCategoryRequest } from '../Data/CapitationCategory';

export const capitationsUrl = '/capitations';
export const getCapitationByIdUrl = (capitationId: number): string => `${capitationsUrl}/${capitationId}`;
export const capitationCategoriesUrl = `${capitationsUrl}/categories`;
export const capitationCategoryByIdUrl = (capCategoryId: number): string => `${capitationsUrl}/categories/${capCategoryId}`;
export const capitationCategoriesByHospitalIdsUrl = `${capitationCategoriesUrl}/hospitals`;

// capitations
export const fetchCapitations = async (): Promise<Capitation[]> => apiClient.get<Capitation[]>({ url: capitationsUrl });
export const fetchCapitationById = async (capitationId: number): Promise<Capitation> =>
  apiClient.get<Capitation>({ url: getCapitationByIdUrl(capitationId) });

export const createCapitation = async (capitation: CapitationRequest): Promise<any> => {
  const requestConfig = { url: capitationsUrl, data: JSON.stringify(capitation) };
  return apiClient.post(requestConfig);
};

export const updateCapitation = async (capitation: CapitationRequest): Promise<any> =>
  apiClient.put({ url: getCapitationByIdUrl(capitation.id!), data: JSON.stringify(capitation) });

// capitation categories
export const fetchCapitationCategories = async (): Promise<CapitationCategory[]> => apiClient.get<CapitationCategory[]>({ url: capitationCategoriesUrl });
export const fetchCapitationCategoriesByHospitalIds = async (hospitalIds: number[]): Promise<CapitationCategory[]> => (
  apiClient.post<CapitationCategory[]>({ url: capitationCategoriesByHospitalIdsUrl, data: { hospitalIds } }));
export const fetchCapitationCategoryById = async (capCategoryId: number): Promise<CapitationCategory> =>
  apiClient.get<CapitationCategory>({ url: capitationCategoryByIdUrl(capCategoryId) });
export const createCapitationCategory = async (capitationCategory: CapitationCategoryRequest): Promise<any> =>
  apiClient.post({ url: capitationCategoriesUrl, data: JSON.stringify(capitationCategory) });
export const updateCapitationCategory = async (id: number, capitationCategory: CapitationCategoryRequest): Promise<any> => {
  const updateRequest: UpdateCapitationCategoryRequest = { name: capitationCategory.name };
  return apiClient.put({ url: capitationCategoryByIdUrl(id), data: JSON.stringify(updateRequest) });
};

export const capitationService = {
  get: fetchCapitations,
  getById: fetchCapitationById,
  create: createCapitation,
  update: updateCapitation,
  getCategories: fetchCapitationCategories,
  getCategoryById: fetchCapitationCategoryById,
  getCategoriesByHospitalIds: fetchCapitationCategoriesByHospitalIds,
  createCategory: createCapitationCategory,
  updateCategory: updateCapitationCategory,
};
