import Patient, { genders } from '../Data/Patient';

export const getPatientDisplayName = (patient?: Patient | null): string => {
  if (!patient) {
    return '';
  }

  const middleInitial = patient.middleName?.charAt(0);
  const displayMiddle = middleInitial ? `${middleInitial} ` : '';

  return `${patient.firstName} ${displayMiddle}${patient.lastName}`;
};

export const formatGender = (gender: keyof typeof genders | null | undefined): string => (gender ? genders[gender] : '');
