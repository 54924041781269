import { FC } from 'react';
import { Route } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { HospitalsIndex } from './HospitalsIndex/HospitalsIndex';
import { CreateHospital } from './CreateHospital/CreateHospital';
import { EditHospital } from './EditHospital/EditHospital';

export const HospitalsRoutes: FC = () => (
  <>
    <Route exact path={routes.hospitals.index}>
      <HospitalsIndex />
    </Route>
    <Route exact path={routes.hospitals.create}>
      <CreateHospital />
    </Route>
    <Route exact path={routes.hospitals.editTemplate}>
      <EditHospital />
    </Route>
  </>
);
