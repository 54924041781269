import { dictionary } from '../../../../dictionary';
import { requiredMsg } from '../../../../Utils/validationUtils';
import { CreatePatientFields } from './createPatient.types';

type CreatePatientValidationErrors = Record<keyof CreatePatientFields, string>;

export const validateCreatePatient = (formFieldVals: CreatePatientFields): CreatePatientValidationErrors => {
  const { firstName, lastName } = formFieldVals;
  const errors: any = {};

  if (!firstName) {
    errors.firstName = requiredMsg(dictionary.FORM_CONTROL_LABEL_FIRST_NAME);
  } else if (firstName.length > 50) {
    errors.firstName = `${dictionary.FORM_CONTROL_LABEL_FIRST_NAME} ${dictionary.REQ_CASE_PATIENT_CREATE_PATIENT_TOO_LONG}`;
  }

  if (!lastName) {
    errors.lastName = requiredMsg(dictionary.FORM_CONTROL_LABEL_LAST_NAME);
  } else if (lastName.length > 50) {
    errors.lastName = `${dictionary.FORM_CONTROL_LABEL_LAST_NAME} ${dictionary.REQ_CASE_PATIENT_CREATE_PATIENT_TOO_LONG}`;
  }

  return errors;
};
