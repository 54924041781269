import { FC, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useUtilityStyles from '../../Themes/utility.styles';
import { TabInfo, Tabs } from '../../Shared/Tabs/Tabs';
import { PageHeader } from '../../Shared/PageHeader/PageHeader';
import { joinArgs } from '../../Utils/arrayUtils';
import { dictionary } from '../../dictionary';
import { If } from '../../Shared/If';
import { FormFlow } from '../../Shared/FormFlow/FormFlow';
import { ApprovalRoutingCustomizationFormWrapper } from './ApprovalRoutingCustomizationForm';
import { HospitalSettings } from '../../Data/Settings';
import { hospitalService } from '../../Services/HospitalService';
import { addNotification } from '../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../redux/initialState';
import { LoadingSpinner } from '../../Shared/LoadingSpinner/LoadingSpinner';
import { routes } from '../../Utils/routes';
import { VerticalHospitalSelectionPane } from './VerticalHospitalSelectionPane';
import { SortableTable } from '../../Shared/SortableTable/SortableTable';
import { autoArchiveColumnProperties } from '../AutoArchive/autoArchiveTable.utils';
import { AutoArchiveHospital } from '../../Data/Hospital';
import { UpdateAutoArchiveModal } from './UpdateAutoArchiveModal';

export const SettingsIndex: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [settings, setSettings] = useState<HospitalSettings>();
  const [activeTab, setActiveTab] = useState(0);
  const [associatedHospitalsForAutoArchive, setAssociatedHospitalsForAutoArchive] = useState<AutoArchiveHospital[]>([]);
  const [selectedHospitalId, setSelectedHospitalId] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedArchivedItem, setSelectedArchivedItem] = useState<AutoArchiveHospital>();
  const utilClasses = useUtilityStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    (async (): Promise<void> => {
      if (activeTab === 0 && selectedHospitalId > 0) {
        try {
          const response: HospitalSettings = await hospitalService.getHospitalSettingsById(selectedHospitalId);
          setSettings(response);
        } catch (error) {
          dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
        } finally {
          setIsLoading(false);
        }
      } if (activeTab === 1) {
        try {
          const getAssociatedHospitalsForAutoArchive = await hospitalService.getAdminHospitalsForAutoArchive();
          setAssociatedHospitalsForAutoArchive(getAssociatedHospitalsForAutoArchive);
        } catch (error) {
          dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [selectedHospitalId, activeTab, dispatch]);

  const tabs: TabInfo[] = useMemo(() => [
    { title: dictionary.APPROVAL_ROUTING_CUSTOMIZATION, active: activeTab === 0, onClick: () => { setActiveTab(0); } },
    { title: dictionary.AUTO_ARCHIVE, active: activeTab === 1, onClick: () => { setActiveTab(1); } },
  ], [activeTab]);

  const onCancel = () => history.push(routes.adminDashboard.index);
  const onComplete = async (request: HospitalSettings) => {
    try {
      await hospitalService.updateHospitalSettings(request);
      dispatch(addNotification(NotificationType.success, dictionary.EDIT_SETTINGS_SUCCESS));
    } catch (exception) {
      dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
    }
  };

  const getSelectedArchive = (hospitalId: number) => {
    const selectedItem = associatedHospitalsForAutoArchive.find(archiveId => archiveId.hospitalId === hospitalId);
    setIsModalOpen(true);
    setSelectedArchivedItem(selectedItem);
  };

  const cancelOnClick = () => setIsModalOpen(false);

  const submitAutoArchiveChange = async (value: number) => {
    const updatedNumberValueForDayUntilArchive = {
      id: selectedArchivedItem!.id,
      hospitalId: selectedArchivedItem!.hospitalId,
      hospitalName: selectedArchivedItem!.hospitalName,
      daysUntilAutoArchive: value!,
    };
    if (value) {
      try {
        await hospitalService.updateNumberOfDaysUntilArchive(updatedNumberValueForDayUntilArchive);
        const idx = associatedHospitalsForAutoArchive.findIndex(ah => ah.hospitalId === selectedArchivedItem!.hospitalId);
        associatedHospitalsForAutoArchive[idx].daysUntilAutoArchive = value;
        setAssociatedHospitalsForAutoArchive([...associatedHospitalsForAutoArchive]);
      } catch (error) {
        dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
      }
    }
    cancelOnClick();
  };

  return (
    <>
      <PageHeader title="Settings" withoutBottomMargin />
      <Row>
        <Col xs={12} lg={6} className={joinArgs(utilClasses.flexRow, utilClasses.p0)}>
          <Tabs tabs={tabs} />
        </Col>
      </Row>
      <Row>
        <Col xs={9}>
          {isLoading
            ? <LoadingSpinner />
            : (
              <If condition={activeTab === 0}>
                <FormFlow<HospitalSettings, HospitalSettings>
                  editSource={settings}
                  onComplete={onComplete}
                  onCancel={onCancel}
                  completeButtonLabel={dictionary.SAVE_SETTINGS}
                  steps={[
                    { component: ApprovalRoutingCustomizationFormWrapper },
                  ]}
                />
              </If>
            ) }
        </Col>
        <If condition={activeTab === 0}>
          <Col xs={9} lg={3}>
            <VerticalHospitalSelectionPane setSelectedHospitalId={setSelectedHospitalId} selectedHospitalId={selectedHospitalId} />
          </Col>
        </If>
        <If condition={activeTab === 1}>
          <SortableTable
            initialColumnSort="hospitalName"
            rowData={associatedHospitalsForAutoArchive}
            columns={autoArchiveColumnProperties}
            noDataMessage={dictionary.AUTO_ARCHIVE_EMPTY_TABLE}
            isLoading={isLoading}
            handleRowSelect={(e) => getSelectedArchive(e.hospitalId)}
          />
        </If>
        <UpdateAutoArchiveModal
          isModalOpen={isModalOpen}
          selectedArchivedItem={selectedArchivedItem!}
          submitAutoArchiveChange={submitAutoArchiveChange}
          cancelOnClick={cancelOnClick}
        />
      </Row>
    </>
  );
};
