import { FC } from 'react';
import { renderSlashDateCell } from './sortableTableRenderers';

interface SlashDateCellProps {
  value: string | null;
}

export const getSlashDateCellComponent = (): FC<SlashDateCellProps> => {
  const SlashDateCell: FC<SlashDateCellProps> = ({ value }) => (
    <>{ renderSlashDateCell(value) }</>
  );

  return SlashDateCell;
};
