import { FormikProps } from 'formik';
import { FC } from 'react';
import Manufacturer from '../../../Data/Manufacturer';
import { HospMfctIdentifierResponse } from '../../../Data/ManufacturerIdentifier';
import { dictionary } from '../../../dictionary';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { If } from '../../../Shared/If';
import { hospMfctIdentifierAssocFormFields } from './hospMfctIdentifier.utils';
import { useSubFormKeyPress } from '../../../Shared/SubFormKeyPress/useSubFormKeyPress';

export interface HospMnfctIdentifierAssocFields {
  formikProps: FormikProps<any>;
  editHospMfctIdentifier?: HospMfctIdentifierResponse;
  disableManufacturer?: boolean;
  manufacturers?: Manufacturer[];
}

export const HospMfctIdentifierAssocFields: FC<HospMnfctIdentifierAssocFields> = ({ formikProps, editHospMfctIdentifier, manufacturers, disableManufacturer = true }) => {
  const isEditing = !!editHospMfctIdentifier;
  const userIsAllowedToFillForm = !isEditing || !!editHospMfctIdentifier?.canCurrentUserEdit;
  const disableMfct = !manufacturers?.length || disableManufacturer;
  const handleKeyPress = useSubFormKeyPress(formikProps.handleSubmit);

  return (
    <>
      <If condition={isEditing}>
        <SimpleDisplayField label={dictionary.EDIT_HOSPITAL_MANUFACTURER_ID_HOSPITAL_LABEL} value={editHospMfctIdentifier?.hospitalName} />
      </If>
      <SimpleFieldWithLabel form={formikProps} descriptor={hospMfctIdentifierAssocFormFields.identifier} onKeyPress={handleKeyPress} disabled={!userIsAllowedToFillForm} />
      <If condition={!isEditing}>
        <Autocomplete
          formikProps={formikProps}
          descriptor={hospMfctIdentifierAssocFormFields.manufacturer}
          options={manufacturers!}
          onKeyPress={handleKeyPress}
          disabled={disableMfct || !userIsAllowedToFillForm}
        />
      </If>
      <If condition={isEditing}>
        <SimpleDisplayField label={dictionary.EDIT_HOSPITAL_MANUFACTURER_ID_MANUFACTURER_LABEL} value={editHospMfctIdentifier?.manufacturerName} />
      </If>
      <SimpleFieldWithLabel form={formikProps} descriptor={hospMfctIdentifierAssocFormFields.siteName} onKeyPress={handleKeyPress} disabled={!userIsAllowedToFillForm} />
      <SimpleFieldWithLabel form={formikProps} descriptor={hospMfctIdentifierAssocFormFields.siteIdentifier} onKeyPress={handleKeyPress} disabled={!userIsAllowedToFillForm} />
    </>
  );
};
