import { FC, useMemo } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { HospitalRequest, HospitalBase } from '../../../Data/Hospital';
import {
  editProcedureFormFields, EditProcedureFormFields, getEditProcedureFormInitialValues, getUpdateProcedureRequestFromFormFields, validateEditProcedureForm,
} from './editProcedureForm.utils';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { ProcedureStub } from '../../../Data/Procedure';
import { dictionary } from '../../../dictionary';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';

interface EditProcedureFormProps extends FormFlowStepComponent<HospitalRequest, HospitalBase> {
  procedure?: ProcedureStub;
}

export const EditProcedureForm: FC<EditProcedureFormProps> = ({ procedure: procedureInput, FormActionsComponent, onFormStepComplete }) => {
  const procedure = procedureInput!;

  const formikInitialValues = useMemo(() => getEditProcedureFormInitialValues(procedure), [procedure]);
  const formikProps = useFormik<EditProcedureFormFields>({
    initialValues: formikInitialValues,
    validate: validateEditProcedureForm,
    onSubmit: async (values: EditProcedureFormFields) => {
      const updatedHospital = getUpdateProcedureRequestFromFormFields(values);
      onFormStepComplete(updatedHospital);
    },
  });

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={12} sm={6} lg={4}>
          <SimpleDisplayField label={dictionary.HOSPITAL_NAME_LABEL} value={procedure?.hospitalName} />
          <SimpleDisplayField label={dictionary.DEPARTMENT_NAME_LABEL} value={procedure?.departmentName} />
          <SimpleFieldWithLabel form={formikProps} descriptor={editProcedureFormFields.name} disabled={procedure?.archived} />
        </Col>
      </Row>

      <Row>
        <Col xs={4}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
