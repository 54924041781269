import { ReactElement, FunctionComponent, useState } from 'react';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { dictionary } from '../../../../dictionary';
import { RowAction } from '../../../../Shared/SortableTable/SortableTable.types';
import { physicianColumnProperties, PhysicianHospitalsForTable } from './physicianHospitalTable.utils';
import { If } from '../../../../Shared/If';
import { EditPhysicianHospitalModal } from '../EditPhysicianHospitalModal/EditPhysicianHospitalModal';
import { PhysicianHospital } from '../../../../Data/Physician';

interface PhysicianHospitalTableProps {
  physicianHospitals: PhysicianHospital[];
  archived?: boolean;
  onRemoveAssociation: (assoc: PhysicianHospitalsForTable) => void;
  onEditAssociation: (assoc: PhysicianHospital) => void;
}

export const PhysicianHospitalTable: FunctionComponent<PhysicianHospitalTableProps> = ({
  physicianHospitals,
  archived = false,
  onRemoveAssociation,
  onEditAssociation,
}): ReactElement => {
  const [associationInEdit, setAssociationInEdit] = useState<PhysicianHospital>();

  const closeModal = () => setAssociationInEdit(undefined);
  const onEditClickAction = (assoc: PhysicianHospitalsForTable) => setAssociationInEdit(physicianHospitals.find(ph => ph.id === assoc.hospitalId));

  const rowData: PhysicianHospitalsForTable[] = physicianHospitals.map(ph => {
    const physHospitals: PhysicianHospitalsForTable = {
      id: ph.id,
      hospitalId: ph.id,
      physicianIdentifier: ph.physicianIdentifier || dictionary.EMPTY_FIELD_MARK,
      hospitalName: ph.name,
      departmentNames: ph.departments.map(d => d.name).join(', '),
      canCurrentUserEdit: ph.canCurrentUserEdit,
    };

    return physHospitals;
  });

  const rowActions: RowAction<PhysicianHospitalsForTable>[] = archived ? [] : [
    {
      name: dictionary.PHYSICIAN_ASSOCIATIONS_ROW_EDIT_TEXT,
      icon: faEdit,
      onAction: assoc => onEditClickAction(assoc),
      intent: 'primary',
    },
    {
      name: dictionary.PHYSICIAN_ASSOCIATIONS_ROW_REMOVE_TEXT,
      icon: faTrash,
      onAction: assoc => onRemoveAssociation(assoc),
      intent: 'danger',
    },
  ];

  const shouldRenderRowActions = (physHospitals: PhysicianHospitalsForTable) => physHospitals.canCurrentUserEdit ?? false;

  return (
    <>
      <section>
        <SortableTable
          initialColumnSort="hospitalName"
          rowData={rowData}
          columns={physicianColumnProperties}
          noDataMessage={dictionary.CREATE_EDIT_PHYSICIAN_TABLE_EMPTY}
          tableTestId="physicians-table"
          isLoading={false}
          archived={archived}
          rowActions={rowActions}
          rowActionsWidth="10%"
          shouldRenderRowActions={shouldRenderRowActions}
        />
      </section>

      <If condition={!!associationInEdit}>
        <EditPhysicianHospitalModal association={associationInEdit} closeModal={closeModal} onEditAssociation={onEditAssociation} />
      </If>
    </>
  );
};
