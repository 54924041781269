import { createRef, FC, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { dictionary } from '../../../../../dictionary';
import { useProductCsvDispatcher } from './useProductCsvDispatcher';
import { ReduxState } from '../../../../../redux/initialState';
import { hospitalService } from '../../../../../Services/HospitalService';
import { LoadingSpinner } from '../../../../../Shared/LoadingSpinner/LoadingSpinner';
import { ErrorPage } from '../../../../../Shared/ErrorPage';
import { ProductCategoryStub } from '../../../../../Data/ProductCategory';
import { Button } from '../../../../../Shared/buttons/Button';
import useUtilityStyles from '../../../../../Themes/utility.styles';

export const UploadEditsInput: FC = () => {
  const [productCategories, setProductCategories] = useState<ProductCategoryStub[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [hasLoadingError, setHasLoadingError] = useState(false);

  const healthSystemId = useSelector((state: ReduxState) => state.contract.editContract.healthSystemId);
  const hospitals = useSelector((state: ReduxState) => state.contract.editContract.hospitals);
  const hospitalIds = useMemo(() => hospitals?.map(h => h.id) || [], [hospitals]);
  useEffect(() => {
    if (healthSystemId) {
      (async () => {
        try {
          const response = await hospitalService.getProductCategoriesByHealthSystem(healthSystemId);
          setProductCategories(response.healthSystemProductCategories);
        } catch {
          setHasLoadingError(true);
        } finally {
          setIsLoadingCategories(false);
        }
      })();
    }
  }, [healthSystemId]);

  if (hasLoadingError) {
    return <ErrorPage />;
  }

  return <UploadContractInner hospitalIds={hospitalIds} productCategories={productCategories} isLoadingCategories={isLoadingCategories} />;
};

interface UploadContractInnerProps {
  isLoadingCategories: boolean;
  hospitalIds: number[];
  productCategories: ProductCategoryStub[];
}
const UploadContractInner: FC<UploadContractInnerProps> = ({ hospitalIds, productCategories, isLoadingCategories }) => {
  const setFiles = useProductCsvDispatcher(hospitalIds, productCategories, true);
  const ref = createRef<HTMLInputElement>();
  const [filePickerSerialNo, setFilePickerSerialNo] = useState(1);
  const utilClasses = useUtilityStyles();

  const replaceFilePickerDomElement = () => setFilePickerSerialNo(filePickerSerialNo + 1);
  const leadingIcon = isLoadingCategories
    ? <LoadingSpinner />
    : <FontAwesomeIcon icon={faFileUpload} />;

  return (
    <div className={utilClasses.mr3}>
      <input
        type="file"
        data-testid="contract-product-file-upload"
        ref={ref}
        key={filePickerSerialNo}
        hidden
        onChange={e => {
          setFiles(e.target.files);
          replaceFilePickerDomElement();
        }}
      />
      <Button
        onClick={() => ref!.current?.click()}
        leadingIcon={leadingIcon}
        disabled={isLoadingCategories}
        className={utilClasses.mb1}
      >
        {dictionary.CONTRACT_TEMPLATE_UPLOAD_PRODUCTS}
      </Button>
    </div>
  );
};
