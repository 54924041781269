import { FormikErrors, FormikValues } from 'formik';
import { HospitalProductRequest } from '../../../../../Data/HospitalProduct';
import { ManufacturerBase } from '../../../../../Data/Manufacturer';
import { ProductCategoryStub } from '../../../../../Data/ProductCategory';
import { dictionary } from '../../../../../dictionary';
import { UnitOfMeasureOption } from '../../../../../Utils/Constants';
import { createDescriptor, getFieldDescriptorMap } from '../../../../../Utils/formik.utils';
import { nonAlphanumericRegexGlobal, validateCharacterLimit, validateNumberInRangeInclusive, validateRequiredFields } from '../../../../../Utils/validationUtils';
import { createReqProductFormFields, ProductInfoFormValues } from '../shared/ProductInfo.utils';
import { validateProductInfoFields } from '../shared/ProductInfo.validations';

interface ProductInfoCreateProductSpecificFormValues {
  catalogNumber: string;
  description: string;
  brand: string;
  productType: string;
  unitOfMeasure: UnitOfMeasureOption;
  quantityUnitOfMeasure: number;
  orderIdentifier: string;
  productCategory: ProductCategoryStub;
}
export interface ProductInfoCreateProductFormValues extends ProductInfoFormValues, ProductInfoCreateProductSpecificFormValues {}

export const productInfoCreateProductFormFields = {
  ...createReqProductFormFields,
  ...getFieldDescriptorMap<ProductInfoCreateProductSpecificFormValues>([
    createDescriptor({ name: 'catalogNumber', label: dictionary.REQ_PRODUCT_CATALOG_NUM, required: true }),
    createDescriptor({ name: 'description', label: dictionary.REQ_PRODUCT_DESCRIPTION, required: true }),
    createDescriptor({ name: 'brand', label: dictionary.REQ_PRODUCT_BRAND }),
    createDescriptor({ name: 'productType', label: dictionary.REQ_PRODUCT_PRODUCT_TYPE }),
    createDescriptor({ name: 'unitOfMeasure', label: dictionary.REQ_PRODUCT_UOM_FULL, required: true }),
    createDescriptor({ name: 'quantityUnitOfMeasure', label: dictionary.REQ_PRODUCT_QUANTITY_UOM_FULL, required: true }),
    createDescriptor({ name: 'orderIdentifier', label: dictionary.REQ_PRODUCT_ITEM_ID }),
    createDescriptor({ name: 'productCategory', label: dictionary.REQ_PRODUCT_PRODUCT_CATEGORY }),
  ]),
};

export const validateProductInfoCreateProductForm = async (values: FormikValues): Promise<FormikErrors<ProductInfoCreateProductFormValues>> => {
  const { catalogNumber, description, brand, productType, quantityUnitOfMeasure, orderIdentifier } = productInfoCreateProductFormFields;
  const productInfoFormValidationErrors = await validateProductInfoFields(values);
  return {
    ...productInfoFormValidationErrors,
    ...validateCharacterLimit(values, [catalogNumber, brand, productType, orderIdentifier], 100),
    ...validateCharacterLimit(values, [description], 300),
    ...validateNumberInRangeInclusive(values, [quantityUnitOfMeasure], 1, 5_000),
    ...validateRequiredFields(values, Object.values(productInfoCreateProductFormFields)),
  };
};

export const composeHospitalProductRequest = (values: ProductInfoCreateProductFormValues, hospitalId: number, manufacturer: ManufacturerBase): HospitalProductRequest => {
  const { catalogNumber, description, unitOfMeasure, brand, quantityUnitOfMeasure, productType, orderIdentifier, productCategory } = values;

  return {
    hospitalId,
    manufacturerId: manufacturer!.id,
    catalogNumber,
    cleanCatalogNumber: catalogNumber.replace(nonAlphanumericRegexGlobal, ''),
    description,
    unitOfMeasure: unitOfMeasure.name,
    brandName: brand,
    quantityUnitOfMeasure,
    productType,
    orderIdentifier,
    productCategoryId: productCategory.id,
    needsApproval: true,
  };
};
