import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { HospitalProductDetails, HospitalProductEditRequest } from '../../../Data/HospitalProduct';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { hospitalProductService } from '../../../Services/hospitalProductService';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { routes } from '../../../Utils/routes';
import { EditProductForm } from './EditProductForm';

const EditProductFormWrapper: FC<FormFlowStepComponent<HospitalProductEditRequest, HospitalProductDetails>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <EditProductForm FormActionsComponent={FormActionsComponent} product={editSource} onFormStepComplete={onFormStepComplete} />;

export const EditProduct: FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const productId = Number(params.id);
  const [product, setProduct] = useState<HospitalProductDetails>();
  const [loadingProduct, setLoadingProduct] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const result = await hospitalProductService.getById(productId);
        setProduct(result);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.PRODUCT_DETAILS_LOAD_ERR));
      } finally {
        setLoadingProduct(false);
      }
    })();
  }, [dispatch, productId]);

  if (loadingProduct) {
    return <LoadingSpinner />;
  }
  if (!product) {
    return <ErrorPage />;
  }

  const loadedProduct = product!;
  const onComplete = async (hospitalProductRequest: HospitalProductEditRequest) => {
    try {
      await hospitalProductService.update(loadedProduct.id, hospitalProductRequest);
      dispatch(addNotification(NotificationType.success, dictionary.PRODUCT_EDIT_SUCCESS));
      history.push(routes.products.details(loadedProduct.id));
    } catch (e) {
      const exception = e as AxiosError;
      if (exception.response?.status === 409) {
        dispatch(addNotification(NotificationType.error, dictionary.PRODUCT_CATALOG_NUMBER_NOT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.PRODUCT_EDIT_FAILURE));
      }
    }
  };
  return (
    <FormFlow<HospitalProductEditRequest, HospitalProductDetails>
      editSource={loadedProduct}
      steps={[{ pageHeader: dictionary.PRODUCT_EDIT_HEADER, component: EditProductFormWrapper }]}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      onCancel={() => { history.push(routes.products.index); }}
      onComplete={onComplete}
    />
  );
};
