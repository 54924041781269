import { createUseStyles } from 'react-jss';
import colors from '../../Themes/colors';
import { fontWeights } from '../../Themes/fontWeights';

export const navBarStyles = createUseStyles({
  header: {
    position: 'fixed',
    width: '100%',
    zIndex: 9,
  },
  topBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '4rem',
    backgroundColor: colors.trueWhite,
  },
  logo: {
    height: '2.5rem',
  },
  topNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '3.125rem',
    marginBottom: '1.25rem',
    backgroundColor: colors.gray,
  },
  topNavLinks: {
    display: 'flex',
    alignItems: 'center',
    '& .top-nav-link': {
      fontWeight: fontWeights.bold,
      marginRight: '2.5rem',
      color: colors.trueWhite,
      flexGrow: 0,
      flexShrink: 0,
      textDecoration: 'none',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
});
