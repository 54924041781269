import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HospitalManufacturerContractCapitation } from '../../../Data/Capitation';
import { ApiRequisition, MergedRequisition } from '../../../Data/Requisition';
import { RequisitionStatus } from '../../../Data/RequisitionStatus';
import { dictionary } from '../../../dictionary';
import { actionTypes } from '../../../redux/actionTypes';
import { ReduxState } from '../../../redux/initialState';
import { applyCapitationAction } from '../../../redux/requisition/requisitionActions';
import { Callout } from '../../../Shared/Callout/Callout';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { If } from '../../../Shared/If';
import useUtilityStyles from '../../../Themes/utility.styles';
import { ProductInfoForm } from '../shared/ProductInfo/ProductInfoForm/ProductInfoForm';
import { getLatestReturnReason } from '../shared/requisition.utils';

export const ProductInfoFormWrapper: FC<FormFlowStepComponent<MergedRequisition>> = ({ FormActionsComponent, onFormStepComplete }) => {
  const editRequisition = useSelector((state: ReduxState) => state.requisition.editRequisition) as ApiRequisition;
  const dispatch = useDispatch();
  const utilityClasses = useUtilityStyles();
  const updateRequisition = (req: MergedRequisition) => dispatch({ type: actionTypes.requisition.updateNew, requisition: req });
  const applyCapitation = (capitation: HospitalManufacturerContractCapitation, productIndices: number[]) => dispatch(applyCapitationAction(capitation, productIndices));

  if (!editRequisition.canCurrentUserEdit) {
    return <p>{ dictionary.REQ_EDIT_NO_PERMISSIONS }</p>;
  }

  const latestReturnReason = getLatestReturnReason(editRequisition);

  return (
    <>
      <If condition={!!latestReturnReason && editRequisition.status === RequisitionStatus.returned}>
        <div className={utilityClasses.mb1}>
          <Callout title={dictionary.REQ_RETURN_REASON} titleClass={utilityClasses.backgroundCantaloupe}>
            {latestReturnReason}
          </Callout>
        </div>
      </If>

      <ProductInfoForm
        editRequisition={editRequisition}
        dispatchNewReqStateUpdate={updateRequisition}
        FormActionsComponent={FormActionsComponent}
        onFormStepComplete={onFormStepComplete}
        applyCapitation={applyCapitation}
        showHospitalContactNotification={false}
      />
    </>
  );
};
