import { FormikProps } from 'formik';
import { FC, useMemo } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { HealthSystemManufacturerContract } from '../../../Data/Contract';
import { HospitalProductDetails } from '../../../Data/HospitalProduct';
import { ProductCategoryStub } from '../../../Data/ProductCategory';
import { dictionary } from '../../../dictionary';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { CurrencyField } from '../../../Shared/fields/CurrencyField';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { unitOfMeasuresAsOptions } from '../../../Utils/Constants';
import { getSlashFormattedDate } from '../../../Utils/dateUtils';
import { HospitalProductEditFormFields, hospitalProductEditFormFields } from './editProduct.utils';

interface EditProductFields {
  formikProps: FormikProps<HospitalProductEditFormFields>;
  product: HospitalProductDetails;
  contracts: HealthSystemManufacturerContract[];
  productCategories: ProductCategoryStub[];
}

export const EditProductFields: FC<EditProductFields> = (props) => {
  const { product, contracts, productCategories, formikProps } = props;
  const { values, setFieldValue } = formikProps;

  const chosenUnitOfMeasure = unitOfMeasuresAsOptions.find(uom => uom.name === values.unitOfMeasure);
  const handleUnitOfMeasureChange = (newValue: any) => setFieldValue('unitOfMeasure', newValue.name);
  const chosenContract = useMemo(() => contracts.find(c => c.id === values.contractId), [contracts, values.contractId]);
  const onContractChange = (contract: HealthSystemManufacturerContract | null) => {
    const nextProduct = contract?.products.find(cp => cp.catalogNumber === product.catalogNumber);
    const nextPrice = nextProduct?.price || undefined;

    formikProps.setValues({
      ...formikProps.values,
      [hospitalProductEditFormFields.contractProductId.name]: nextProduct?.id,
      [hospitalProductEditFormFields.contractId.name]: contract?.id,
      [hospitalProductEditFormFields.contractPrice.name]: nextPrice,
    });
  };

  return (
    <>
      <h2>{dictionary.PRODUCT_DETAILS_PRODUCT_INFO_HEADER}</h2>
      <Row>
        <Col xs={12} md={6} xl={4}>
          <SimpleFieldWithLabel form={formikProps} descriptor={hospitalProductEditFormFields.brandName} />
          <SimpleFieldWithLabel form={formikProps} descriptor={hospitalProductEditFormFields.catalogNumber} />
        </Col>
        <Col xl={9}>
          <SimpleFieldWithLabel form={formikProps} descriptor={hospitalProductEditFormFields.description} />
        </Col>
        <Col xs={12} md={6} xl={4}>
          <Row>
            <Col xs={5} md={6} xl={6}>
              <Autocomplete
                formikProps={formikProps}
                options={unitOfMeasuresAsOptions}
                descriptor={hospitalProductEditFormFields.unitOfMeasure}
                value={chosenUnitOfMeasure}
                onChange={handleUnitOfMeasureChange}
              />
            </Col>
            <Col xs={7} md={6}>
              <SimpleFieldWithLabel form={formikProps} descriptor={hospitalProductEditFormFields.quantityUnitOfMeasure} />
            </Col>
          </Row>
          <SimpleFieldWithLabel form={formikProps} descriptor={hospitalProductEditFormFields.productType} />
        </Col>
      </Row>

      <Row>
        <Col md={6} xl={6}>
          <h2>{dictionary.PRODUCT_DETAILS_CONTRACT_INFO_HEADER}</h2>
          <Row>
            <Col xs={12} md={12} xl={8}>
              <Autocomplete
                formikProps={formikProps}
                options={contracts}
                descriptor={hospitalProductEditFormFields.contractId}
                getOptionLabel={val => val.contractIdentifier}
                value={chosenContract}
                onChange={onContractChange}
                allowClear
              />
              <SimpleDisplayField label={dictionary.HEALTH_SYSTEM_NAME_LABEL} value={product.healthSystemName} />
              <SimpleDisplayField label={dictionary.PRODUCT_MANUFACTURER} value={product.manufacturerName} />
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4} xl={3}>
              <SimpleDisplayField label={dictionary.CONTRACT_EFFECTIVE_DATE_LABEL} value={getSlashFormattedDate(chosenContract?.effectiveDate)} />
            </Col>
            <Col>
              <SimpleDisplayField label={dictionary.CONTRACT_EXPIRATION_DATE_LABEL} value={getSlashFormattedDate(chosenContract?.expirationDate)} />
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <CurrencyField disabled={!formikProps.values.contractId} form={formikProps} descriptor={hospitalProductEditFormFields.contractPrice} />
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={12} xl={6}>
          <h2>{dictionary.PRODUCT_DETAILS_BILLING_INFO_HEADER}</h2>
          <Row>
            <Col md={6}>
              <SimpleFieldWithLabel form={formikProps} descriptor={hospitalProductEditFormFields.orderIdentifier} />
              <Autocomplete
                formikProps={formikProps}
                options={productCategories}
                descriptor={hospitalProductEditFormFields.category}
                getOptionLabel={c => c.name}
                allowClear
                disabled={productCategories?.length === 0}
              />
              <SimpleDisplayField label={dictionary.PRODUCT_CATEGORY_IDENTIFIER_NAME_LABEL} value={formikProps.values.category?.hospitalProductCategoryIdentifier} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
