import { faCaretDown, faCaretUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { dictionary } from '../../dictionary';
import useUtilityStyles from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import { getNextId } from '../../Utils/idUtils';
import { getCircularNextIdx, getCircularPrevIdx } from '../arrays/circularArrays';
import { If } from '../If';
import { useDropdownStyles } from './dropdown.styles';

export interface DropdownOption {
  value: string;
  displayName: string;
}

interface DropdownProps {
  options: DropdownOption[];
  onChange: (val?: string) => unknown;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  disallowClear?: boolean;
}

export const Dropdown: FC<DropdownProps> = (props) => {
  const {
    options, defaultValue, onChange, disabled = false, placeholder = dictionary.DROPDOWN_DEFAULT_PLACEHOLDER, value, disallowClear: disableClear = false,
  } = props;
  const classes = useDropdownStyles();
  const utilClasses = useUtilityStyles();
  const [optionsAreVisible, setOptionsAreVisible] = useState(false);
  const id = useMemo(() => getNextId(), []);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [highlightedValue, setHighlightedValue] = useState<string>();
  const selectedOption = options.find(o => o.value === selectedValue);

  // Clicking outside of the component's outer div should hide its dropdown options.
  const outerDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const onDocumentClick = (evt: MouseEvent) => {
      if (!outerDivRef || (evt.target instanceof Node && !outerDivRef.current?.contains(evt.target))) {
        setOptionsAreVisible(false);
      }
    };
    document.addEventListener('click', onDocumentClick);

    return () => document.removeEventListener('click', onDocumentClick);
  }, []);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const showOptions = () => {
    setHighlightedValue(selectedValue);
    setOptionsAreVisible(true);
  };
  const toggleOptionsAreVisible = () => {
    if (optionsAreVisible) {
      setOptionsAreVisible(false);
    } else {
      showOptions();
    }
  };

  const onOptionClick = (option: DropdownOption) => {
    setOptionsAreVisible(false);
    setSelectedValue(option.value);
    onChange(option.value);
  };

  const onClearValue = () => {
    setOptionsAreVisible(false);
    setSelectedValue(undefined);
    onChange(undefined);
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLButtonElement>) => {
    if (evt.key !== 'Tab') {
      evt.preventDefault();
    }
    const navKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'];
    const highlightedOption = options.find(o => o.value === highlightedValue);

    if (!optionsAreVisible && evt.key !== 'Meta' && evt.key !== 'Tab') {
      showOptions();
      return;
    }
    if (!highlightedOption && navKeys.includes(evt.key)) {
      setHighlightedValue(options[0].value);
      return;
    }

    let currentValueIndex = 0;
    if (highlightedOption) {
      currentValueIndex = options.indexOf(highlightedOption);
    }
    switch (evt.key) {
      case 'ArrowDown':
      case 'ArrowRight': {
        const nextValue = options[getCircularNextIdx(options, currentValueIndex)].value;
        setHighlightedValue(nextValue);
        break;
      }
      case 'ArrowUp':
      case 'ArrowLeft': {
        const nextValue = options[getCircularPrevIdx(options, currentValueIndex)].value;
        setHighlightedValue(nextValue);
        break;
      }
      case 'Escape':
      case 'Tab':
        setOptionsAreVisible(false);
        break;
      case 'Enter':
        setSelectedValue(options[currentValueIndex].value);
        setOptionsAreVisible(false);
        break;
      default:
        break;
    }
  };

  const toggleBtnClasses = joinArgs('text-input', classes.toggle, utilClasses.textLeft, utilClasses.flex, utilClasses.spaceBetween, utilClasses.alignCenter, utilClasses.pr08);

  return (
    <div className={joinArgs(classes.dropdownRoot, utilClasses.flexGrow, utilClasses.positionRelative)} ref={outerDivRef}>
      <button
        type="button"
        className={toggleBtnClasses}
        onClick={toggleOptionsAreVisible}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      >
        <span className={utilClasses.fs14}>{selectedOption?.displayName || placeholder}</span>
      </button>

      <div className={joinArgs(classes.inputActions, utilClasses.pointerEventsNone)}>
        <If condition={!disableClear && !!selectedValue}>
          <button
            type="button"
            className={joinArgs(classes.inputActionClear, utilClasses.pointerEventsAll)}
            onClick={onClearValue}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </If>

        <FontAwesomeIcon icon={optionsAreVisible ? faCaretUp : faCaretDown} />
      </div>

      <div className={joinArgs(classes.listBoxContainer, optionsAreVisible ? '' : utilClasses.srOnly)}>
        <ul className={joinArgs(classes.listBox, classes.listReset)}>
          { options.map((option) => (
            <li
              id={`dropdown-${id}-list-item-${option.value.toString()}`}
              className={joinArgs(
                classes.listItem,
                utilClasses.flex,
                utilClasses.spaceBetween,
                utilClasses.alignCenter,
                (option.value === highlightedValue) ? 'focused' : '',
              )}
              key={`dropdown-${id}-list-item-${option.value.toString()}`}
              onClick={() => onOptionClick(option)}
              onKeyDown={() => {}}
              role="option"
              aria-selected={option.value === highlightedValue}
            >
              {option.displayName}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
