import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-flexbox-grid';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { ApproverRequisitions } from './ApproverRequisitions/ApproverRequisitions';
import { VendorRequisitions } from './VendorRequisitions/VendorRequisitions';
import { ReqStub } from '../../../Data/Requisition';
import { fetchRequisitions } from '../../../Services/RequisitionService';
import { If } from '../../../Shared/If';
import { getRequisitionRows, ReqForIndexTable, shouldIncludeReqInResults } from './shared/RequisitionsTable/reqTable.utils';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../../dictionary';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { routes } from '../../../Utils/routes';
import { currentUserIsAdminForAtLeastOneHospital, currentUserSelector } from '../../../redux/user/userSelectors';
import { userRoles } from '../../../Utils/userRoles';
import { SupportRequisitions } from './SupportRequisitions/SupportRequisitions';
import { RequisitionsIndexFilters } from './RequisitionsIndexFilters';
import { TabInfo, Tabs } from '../../../Shared/Tabs/Tabs';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { ReduxState } from '../../../redux/initialState';
import { RequisitionStatus } from '../../../Data/RequisitionStatus';
import { actionTypes } from '../../../redux/actionTypes';

export const RequisitionsIndex: FC = () => {
  const editRequisition = useSelector((state: ReduxState) => state.requisition.editRequisition);
  const dispatch = useDispatch();
  const [formattedReqs, setFormattedReqs] = useState<ReqForIndexTable[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [filterColumnSelectedOption, setFilterColumnSelectedOption] = useState<keyof ReqForIndexTable>();
  const [activeTab, setActiveTab] = useState(0);
  const user = useSelector(currentUserSelector);
  const utilClasses = useUtilityStyles();

  const isHospitalAdmin = useSelector(currentUserIsAdminForAtLeastOneHospital);
  const canCreateReq = isHospitalAdmin || user?.roleName === userRoles.vendor;

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const requisitionsResponse: ReqStub[] = await fetchRequisitions();
        const incomingFormattedReqs: ReqForIndexTable[] = getRequisitionRows(requisitionsResponse);
        setFormattedReqs(incomingFormattedReqs);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const filteredReqs = useMemo(
    () => formattedReqs.filter(req => req.status && shouldIncludeReqInResults(filter, req, filterColumnSelectedOption)),
    [filter, filterColumnSelectedOption, formattedReqs]
  );

  const tabs: TabInfo[] = useMemo(() => {
    if (user?.roleName === userRoles.omiAdmin) {
      return [
        { title: dictionary.REQS_TAB_ALL, active: activeTab === 0, onClick: () => { setActiveTab(0); } },
        { title: dictionary.REQS_TAB_PENDING, active: activeTab === 1, onClick: () => { setActiveTab(1); } },
        { title: dictionary.REQS_TAB_APPROVED, active: activeTab === 2, onClick: () => { setActiveTab(2); } },
        { title: dictionary.REQS_TAB_DECLINED, active: activeTab === 3, onClick: () => { setActiveTab(3); } },
      ];
    } if (user?.roleName === userRoles.vendor) {
      return [
        { title: dictionary.REQS_TAB_ALL, active: activeTab === 0, onClick: () => { setActiveTab(0); } },
        { title: dictionary.REQS_TAB_AWAITING, active: activeTab === 1, onClick: () => { setActiveTab(1); } },
        { title: dictionary.REQS_TAB_PENDING, active: activeTab === 2, onClick: () => { setActiveTab(2); } },
        { title: dictionary.REQS_TAB_APPROVED, active: activeTab === 3, onClick: () => { setActiveTab(3); } },
        { title: dictionary.REQS_TAB_DECLINED, active: activeTab === 4, onClick: () => { setActiveTab(4); } },
        { title: dictionary.REQS_TAB_ARCHIVED, active: activeTab === 5, onClick: () => { setActiveTab(5); } },
      ];
    }
    return [
      { title: dictionary.REQS_TAB_ALL, active: activeTab === 0, onClick: () => { setActiveTab(0); } },
      { title: dictionary.REQS_TAB_AWAITING, active: activeTab === 1, onClick: () => { setActiveTab(1); } },
      { title: dictionary.REQS_TAB_PENDING, active: activeTab === 2, onClick: () => { setActiveTab(2); } },
      { title: dictionary.REQS_TAB_APPROVED, active: activeTab === 3, onClick: () => { setActiveTab(3); } },
      { title: dictionary.REQS_TAB_DECLINED, active: activeTab === 4, onClick: () => { setActiveTab(4); } },
    ];
  }, [activeTab, user?.roleName]);

  if (isError) {
    return <ErrorPage />;
  }

  const clearAnyDraft = () => {
    if (editRequisition.status === RequisitionStatus.draft) {
      dispatch({ type: actionTypes.requisition.clearNew });
    }
  };

  return (
    <>
      <PageHeader title={dictionary.REQ_HEADER} withoutBottomMargin>
        <If condition={canCreateReq}>
          <LinkButton to={routes.requisitions.new} onClick={clearAnyDraft} data-testid="create-requisition">
            {dictionary.REQ_CREATE_ACTION}
          </LinkButton>
        </If>
      </PageHeader>

      <Row>
        <Col xs={12} lg={6} className={joinArgs(utilClasses.flexRow, utilClasses.p0)}>
          <Tabs tabs={tabs} />
        </Col>
        <Col xs={12} lg={6}>
          <RequisitionsIndexFilters
            filterColumn={filterColumnSelectedOption}
            onSelectFilterColumn={setFilterColumnSelectedOption}
            filterText={filter}
            onChangeFilterText={setFilter}
          />
        </Col>
      </Row>

      <If condition={user?.roleName === userRoles.omiAdmin}>
        <SupportRequisitions isLoading={isLoading} requisitions={filteredReqs} activeTab={activeTab} />
      </If>

      <If condition={user?.roleName === userRoles.vendor}>
        <VendorRequisitions isLoading={isLoading} requisitions={filteredReqs} activeTab={activeTab} />
      </If>

      <If condition={user?.roleName === userRoles.hospital.approver}>
        <ApproverRequisitions isLoading={isLoading} requisitions={filteredReqs} activeTab={activeTab} />
      </If>
    </>
  );
};
