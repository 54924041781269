import { createAction, RSAAAction } from 'redux-api-middleware';
import { ActionCreator } from 'redux';
import { actionTypes } from '../actionTypes';
import { apiBaseUrl, apiMgmtSubscriptionKey } from '../../envSettings';

export const getCurrentUserEndpoint = `${apiBaseUrl}/users/me`;

export const getCurrentUserAction: ActionCreator<RSAAAction> = () => createAction({
  endpoint: getCurrentUserEndpoint,
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': apiMgmtSubscriptionKey,
  },
  types: [actionTypes.user.fetch, actionTypes.user.fetchSuccess, actionTypes.user.fetchError],
});
