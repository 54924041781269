import { useState } from 'react';
import { Value } from './autocomplete.types';

interface UseAutocompleteHookValues<T> {
  value: Value<T, boolean>;
  validationError: string;
  onBlur: () => boolean;
  onChange: (newValue: Value<T, boolean>) => void;
  validate: (newValue: Value<T, boolean>) => newValue is T | T[];
  resetValue: () => void;
}

export const useAutocomplete = <T extends unknown>(fieldName: string, isMultiple: boolean, defaultValueOverride?: T): UseAutocompleteHookValues<T> => {
  const defaultValue = isMultiple ? [] : null;
  const [value, setValue] = useState<Value<T, typeof isMultiple>>(defaultValueOverride ?? defaultValue);
  const [validationError, setValidationError] = useState<string>('');

  function validate(newValue: Value<T, typeof isMultiple>): newValue is T | T[] {
    const isValid = !!(newValue !== null && (!Array.isArray(newValue) || newValue.length));
    if (!isValid) {
      setValidationError(`${fieldName} is required`);
    } else {
      setValidationError('');
    }

    return isValid;
  }

  const onChange = (newValue: Value<T, typeof isMultiple>) => {
    setValue(newValue);
    validate(newValue);
  };

  return {
    value,
    validationError,
    onBlur: () => validate(value),
    onChange,
    validate,
    resetValue: () => {
      setValue(defaultValueOverride ?? defaultValue);
      setValidationError('');
    },
  };
};
