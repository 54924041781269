import { AnyAction } from 'redux';
import initialState, { RequisitionState } from '../initialState';
import { actionTypes } from '../actionTypes';
import { ApplyCapitationAction, RemoveCapitationFromReqAction, RemoveProductFromCapitationAction, UpdateProductAction } from './requisitionActions';
import { RequisitionCapitation } from '../../Data/Requisition';
import RequisitionProduct from '../../Data/RequisitionProduct';

export function requisitionReducer(state = initialState.requisition, action: AnyAction): RequisitionState {
  switch (action.type) {
    case actionTypes.requisition.removeProduct: {
      return {
        ...state,
        editRequisition: {
          ...state.editRequisition,
          products: state.editRequisition.products?.filter((_p, idx) => idx !== action.productIndex) || [],
        },
      };
    }
    case actionTypes.requisition.updateNew: {
      return {
        ...state,
        editRequisition: {
          ...state.editRequisition,
          ...action.requisition,
        },
      };
    }
    case actionTypes.requisition.applyCapitation: {
      if (!state.editRequisition.products) {
        return state;
      }
      const { capitation, productIndices } = action as ApplyCapitationAction;
      const reqCapitation: RequisitionCapitation = {
        id: undefined,
        capitationId: capitation.id,
        name: capitation.name,
        price: capitation.price,
        products: productIndices.map(productIdx => ({ ...state.editRequisition.products![productIdx] })),
      };

      return {
        ...state,
        editRequisition: {
          ...state.editRequisition,
          capitations: [...(state.editRequisition.capitations ?? []), reqCapitation],
          products: state.editRequisition.products?.filter((_p, idx) => !productIndices.includes(idx)) || [],
        },
      };
    }
    case actionTypes.requisition.removeCapitation: {
      const { capitationIndex } = action as RemoveCapitationFromReqAction;
      const currentStateCapitations = state.editRequisition?.capitations ?? [];
      const matchingCapitationProducts = currentStateCapitations[capitationIndex].products?.map(p => ({ ...p, requisitionCapitationId: null })) ?? [];
      const currentStateProducts = state.editRequisition.products ?? [];

      return {
        ...state,
        editRequisition: {
          ...state.editRequisition,
          capitations: currentStateCapitations.filter((_c, idx) => idx !== capitationIndex),
          products: [...currentStateProducts, ...matchingCapitationProducts],
        },
      };
    }
    case actionTypes.requisition.removeProductFromCapitation: {
      const { capitationIndex, productIndex } = action as RemoveProductFromCapitationAction;

      const currentStateCapitations = state.editRequisition?.capitations ?? [];
      const currentStateProducts = state.editRequisition.products ?? [];

      const productToRemove = currentStateCapitations[capitationIndex].products?.[productIndex];

      const updatedCapitations = currentStateCapitations.map((cap, capIndex) => {
        if (capIndex === capitationIndex) {
          return {
            ...cap,
            products: cap.products.filter((_prod, prodIndex) => prodIndex !== productIndex),
          };
        }
        return cap;
      }).filter((cap) => cap.products.length > 0);

      return {
        ...state,
        editRequisition: {
          ...state.editRequisition,
          capitations: updatedCapitations,
          products: [...currentStateProducts, { ...productToRemove, requisitionCapitationId: null }],
        },
      };
    }
    case actionTypes.requisition.clearNew: {
      return {
        ...state,
        editRequisition: initialState.requisition.editRequisition,
      };
    }
    case actionTypes.requisition.clearManufacturer: {
      const stateCopy = { ...state };
      delete stateCopy.editRequisition.manufacturer;
      return stateCopy;
    }
    case actionTypes.requisition.clearProducts: {
      const stateCopy = { ...state };
      stateCopy.editRequisition.products = [];
      return stateCopy;
    }
    case actionTypes.requisition.clearPatient: {
      const stateCopy = { ...state };
      delete stateCopy.editRequisition.patient;
      return stateCopy;
    }
    case actionTypes.requisition.updateProduct: {
      const updateProductAction = action as UpdateProductAction;
      const updateHospitalProductDataIfMatchingProductNumber = (currentProduct: RequisitionProduct, updatedProduct: RequisitionProduct) =>
        (currentProduct.healthSystemProductId === updatedProduct.healthSystemProductId && currentProduct.id === updatedProduct.id ?
          {
            ...currentProduct,
            orderIdentifier: updatedProduct.orderIdentifier,
            productCategoryName: updatedProduct.productCategoryName,
            contractPrice: updatedProduct.contractPrice,
            quantity: updatedProduct.quantity,
          } : currentProduct);

      const products = state.editRequisition.products?.map((product, idx) =>
        (idx === updateProductAction.productIndex && updateProductAction.capitationIndex === undefined ?
          updateProductAction.updatedProduct
          : updateHospitalProductDataIfMatchingProductNumber(product, updateProductAction.updatedProduct)
        ));
      const capitations = state.editRequisition.capitations?.map((capitation, capIdx) =>
        ({
          ...capitation,
          products: capitation.products?.map((product, idx) =>
            (idx === updateProductAction.productIndex && capIdx === updateProductAction.capitationIndex ?
              updateProductAction.updatedProduct
              : updateHospitalProductDataIfMatchingProductNumber(product, updateProductAction.updatedProduct)
            )),
        }));

      return {
        ...state,
        editRequisition: {
          ...state.editRequisition,
          products,
          capitations,
        },
      };
    }

    default: {
      return state;
    }
  }
}
