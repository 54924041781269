import apiClient from './apiClient';
import CostCenter, { CostCenterEditRequest, CostCenterStub, CreateCostCenterRequest, UpdateCostCenterRequest } from '../Data/CostCenter';

export const getCostCentersUrl = '/departments/costCenters';
export const getCostCenterByIdUrl = (id: number): string => `/costCenters/${id}`;
export const getCostCentersByDepartmentUrl = (departmentId: number): string => `/costCenters/departments/${departmentId}`;
export const createCostCenterUrl = (departmentId: number): string => `/departments/${departmentId}/costCenters`;

export interface CostCentersResponse {
  costCenters: CostCenterStub[];
}

const getCostCenters = async (): Promise<CostCenterStub[]> => apiClient.get({ url: getCostCentersUrl }).then((response: CostCentersResponse) => response.costCenters);

const getCostCenterById = async (id: number): Promise<CostCenterStub> =>
  apiClient.get<CostCenterStub>({ url: getCostCenterByIdUrl(id) });

const getCostCentersByDepartment = async (departmentId: number): Promise<CostCenter[]> => {
  let costCenters = null;
  try {
    costCenters = await apiClient.get({ url: getCostCentersByDepartmentUrl(departmentId) });
  } catch (e) {
    console.error(e);
  }

  return costCenters;
};

const createCostCenter = async (req: CreateCostCenterRequest): Promise<{ id: number }> =>
  apiClient.post({ url: createCostCenterUrl(req.departmentId), data: JSON.stringify(req) });

const updateCostCenter = async (id: number, req: UpdateCostCenterRequest | CostCenterEditRequest): Promise<void> =>
  apiClient.put({ url: getCostCenterByIdUrl(id), data: req });

export const costCenterService = {
  getAll: getCostCenters,
  getByDepartment: getCostCentersByDepartment,
  getById: getCostCenterById,
  create: createCostCenter,
  update: updateCostCenter,
};
