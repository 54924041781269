import { FunctionComponent, ReactElement, useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useSelector } from 'react-redux';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { dictionary } from '../../../dictionary';
import CostCenter from '../../../Data/CostCenter';
import SubCostCenter from '../../../Data/SubCostCenter';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { ApiRequisition } from '../../../Data/Requisition';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import useUtilityStyles from '../../../Themes/utility.styles';
import { PhysicianBase } from '../../../Data/Physician';
import { ProcedureBase } from '../../../Data/Procedure';
import {
  editReqFieldDescriptors,
  EditRequisitionFieldErrors,
  EditRequisitionFields,
  getEditReqFormInitialValues,
  getSubCostCenterPlaceholder,
  useEditRequisitionForm,
} from './editRequisition.utils';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { DateFieldWithLabel } from '../../../Shared/fields/DateFieldWithLabel';
import { getFormikErrorOrEmptyString } from '../../../Utils/formik.utils';
import { caseDateMax, caseDateMin } from '../CreateRequisition/CasePatientInfo/casePatientInfo.utils';
import { ReqPatientForm } from '../shared/ReqPatientForm';
import { useCaseDateUpdateConfirmation } from '../shared/useCaseDateUpdateConfirmation';
import { If } from '../../../Shared/If';
import { currentUserRoleSelector, currentUserSelector } from '../../../redux/user/userSelectors';
import { PermissionTo } from '../../../Utils/permissionChecker';
import { alphanumericSortDescriptor, nameSortDescriptor } from '../../../Utils/SortUtils';
import { RequisitionStatus } from '../../../Data/RequisitionStatus';
import { userRoles } from '../../../Utils/userRoles';

export interface EditRequisitionFormProps extends FormFlowStepComponent<EditRequisitionFields> {
  requisition: ApiRequisition;
  procedures: ProcedureBase[];
  physicians: PhysicianBase[];
  costCenters: CostCenter[];
}

export const EditRequisitionForm: FunctionComponent<EditRequisitionFormProps> = ({
  requisition,
  procedures,
  physicians,
  costCenters,
  onFormStepComplete,
  FormActionsComponent,
}): ReactElement => {
  const initialFormValues = useMemo(() => getEditReqFormInitialValues(requisition), [requisition]);
  const { onTryFormSubmit, CaseDateConfirmationModal } = useCaseDateUpdateConfirmation(initialFormValues, onFormStepComplete, requisition);
  const formikProps = useEditRequisitionForm(initialFormValues, onTryFormSubmit);
  const user = useSelector(currentUserSelector);
  const currentUserCanUpdateCostCenter = PermissionTo.updateAReqCostCenter.isGrantedTo(user);

  const { handleSubmit, handleBlur, values } = formikProps;

  const validationErrors: EditRequisitionFieldErrors = {
    procedure: getFormikErrorOrEmptyString(formikProps, 'procedure'),
    physician: getFormikErrorOrEmptyString(formikProps, 'physician'),
    costCenter: getFormikErrorOrEmptyString(formikProps, 'costCenter'),
  };

  const utilityClasses = useUtilityStyles();

  const selectableSubCostCenters = (costCenters.find(c => c.id === values.costCenter?.id)?.subCostCenters ?? [])
    .sort(alphanumericSortDescriptor('name', 1));
  const aCostCenterIsSelected = !!(values.costCenter && values.costCenter.id);
  const areAnySubCostCentersSelectable = !!selectableSubCostCenters?.length;

  const sortedProcedures = procedures.sort(alphanumericSortDescriptor('name', 1));
  const sortedActivePhysicians = physicians.filter(p => !p.archived).sort(nameSortDescriptor);
  const sortedCostCenters = costCenters.sort(alphanumericSortDescriptor('name', 1));

  const currentUserRole = useSelector(currentUserRoleSelector);
  const userCanPostNote = requisition.status === RequisitionStatus.returned
  && (currentUserRole === userRoles.omiAdmin || currentUserRole === userRoles.vendor);

  return (
    <>
      <form className="input-form" onSubmit={handleSubmit}>
        <If condition={userCanPostNote}>
          <SimpleFieldWithLabel form={formikProps} descriptor={editReqFieldDescriptors.additionalNotes} />
        </If>

        <Row>
          <Col xs={12}>
            <h2>{dictionary.REQ_PROCEDURE_INFO_HEADER}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={4} className={utilityClasses.pr2}>
            <section className={`details-section ${utilityClasses.mt2} ${utilityClasses.px2} ${utilityClasses.pt1} ${utilityClasses.pb2} ${utilityClasses.backgroundNearWhite}`}>
              <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_HOSPITAL} value={requisition.hospital?.name} />
              <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_DEPARTMENT} value={requisition.department?.name} />
            </section>
          </Col>

          <Col xs={6} lg={4}>
            <section className={utilityClasses.mt3}>
              <Autocomplete
                id="procedure"
                data-testid="procedure-autocomplete"
                label={`${dictionary.FORM_CONTROL_LABEL_PROCEDURE} ${dictionary.REQUIRED_FIELD_MARK}`}
                validationError={validationErrors.procedure}
                options={sortedProcedures}
                value={values.procedure ?? null}
                onChange={(newValue: ProcedureBase | null) => { formikProps.setFieldValue('procedure', newValue); }}
                onBlur={() => {}}
              />

              <Autocomplete
                id="physician"
                data-testid="physician-autocomplete"
                label={`${dictionary.FORM_CONTROL_LABEL_PHYSICIAN} ${dictionary.REQUIRED_FIELD_MARK}`}
                validationError={validationErrors.physician}
                options={sortedActivePhysicians}
                value={values.physician ?? null}
                onChange={(newValue: PhysicianBase | null) => { formikProps.setFieldValue('physician', newValue); }}
                onBlur={() => {}}
              />
            </section>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <h2>{dictionary.REQ_CASE_INFO_HEADER}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={4} className={utilityClasses.pr2}>
            <ReqPatientForm formikProps={formikProps} requisition={requisition} />
          </Col>
          <Col xs={6} lg={4}>
            <SimpleFieldWithLabel form={formikProps} descriptor={editReqFieldDescriptors.caseNumber} />
            <DateFieldWithLabel form={formikProps} descriptor={editReqFieldDescriptors.caseDate} minDate={caseDateMin} maxDate={caseDateMax} />
          </Col>
        </Row>

        <If condition={currentUserCanUpdateCostCenter}>
          <Row>
            <Col xs={12}>
              <h2>{dictionary.REQ_BILLING_INFO_HEADER}</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={6} lg={4} className={utilityClasses.pr2}>
              <Autocomplete
                id="costCenter"
                data-testid="costCenter-autocomplete"
                label={`${dictionary.COST_CENTER_LABEL} ${dictionary.REQUIRED_FIELD_MARK}`}
                validationError={validationErrors.costCenter}
                options={sortedCostCenters ?? []}
                value={sortedCostCenters.length === 1 ? sortedCostCenters[0] : values.costCenter ?? null}
                onChange={(newValue: CostCenter | null) => {
                  formikProps.setFieldValue('costCenter', newValue);
                  formikProps.setFieldValue('subCostCenter', null);
                }}
                onBlur={handleBlur}
              />
              <Autocomplete
                allowClear
                id="subCostCenter"
                data-testid="subCostCenter-autocomplete"
                label={dictionary.SUB_COST_CENTER_LABEL}
                disabled={!areAnySubCostCentersSelectable}
                placeholder={getSubCostCenterPlaceholder(aCostCenterIsSelected, areAnySubCostCentersSelectable)}
                options={selectableSubCostCenters}
                value={values.subCostCenter ?? null}
                onChange={(newValue: SubCostCenter | null) => formikProps.setFieldValue('subCostCenter', newValue)}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
        </If>

        <Row>
          <Col xs={12} lg={8}>
            <FormActionsComponent />
          </Col>
        </Row>
      </form>

      <CaseDateConfirmationModal formikProps={formikProps} />
    </>
  );
};
