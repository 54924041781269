import { faEdit, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableOptions, UseExpandedOptions, Column, TableInstance, UseExpandedInstanceProps, useTable, useExpanded } from 'react-table';
import { MergedRequisition, RequisitionCapitation } from '../../../../../Data/Requisition';
import RequisitionProduct from '../../../../../Data/RequisitionProduct';
import { dictionary } from '../../../../../dictionary';
import { NotificationType } from '../../../../../redux/initialState';
import { addNotification } from '../../../../../redux/notifications/notificationsActionCreator';
import { removeCapitationFromReqAction, removeProductFromCapitationAction } from '../../../../../redux/requisition/requisitionActions';
import { currentUserSelector } from '../../../../../redux/user/userSelectors';
import { ExpandCell } from '../../../../../Shared/tables/ExpandCell';
import { getActionsCellComponent } from '../../../../../Shared/tables/getActionsCellComponent';
import { buildTableCols } from '../../../../../Shared/tables/tableUtils';
import { toCurrency } from '../../../../../Utils/formatNumber';
import { userRoles } from '../../../../../Utils/userRoles';
import { getRequisitionUniquePoNumberCount } from '../../requisition.utils';
import { renderRequisitionProductCategory } from '../Badges/ProductCategoryDisplay';
import { renderRequisitionProductPoNumber } from '../RequisitionProductPoNumberBadge';
import { capSavingsAmountAccessor, capSavingsPctAccessor } from './CapitationColumns';

const valueOrEmpty = (value?: string | null) => value || dictionary.EMPTY_FIELD_MARK;

const capitationKey = (cap: RequisitionCapitation, index: number): string => `${index}-${cap.capitationId}-${cap.id}-${cap.products.length}`;

export type ReqCapTableInstance = TableInstance<RequisitionCapitation> & UseExpandedInstanceProps<RequisitionCapitation>;

export const useReqCapitationTable = (editable: boolean, requisition: MergedRequisition): ReqCapTableInstance => {
  const memoizedCapitations = useMemo(() => requisition.capitations || [], [requisition.capitations]);
  const memoizedExpandedCapitations = useMemo(() => memoizedCapitations.reduce((acc: Record<string, boolean>, cur: RequisitionCapitation, index) => {
    const capKey = capitationKey(cur, index);
    acc[capKey] = true;
    return acc;
  }, {}), [memoizedCapitations]);

  const dispatch = useDispatch();
  const getRowId = useMemo(() => (cap: RequisitionCapitation, index: number) => capitationKey(cap, index), []);

  const columns: Column<RequisitionCapitation>[] = useMemo(() => {
    const reqCapAccessor = (cap: RequisitionCapitation) => toCurrency(cap.price ?? 0);
    const onRemoveClick = (capIndex: number) => {
      dispatch(removeCapitationFromReqAction(capIndex));
      dispatch(addNotification(NotificationType.success, dictionary.REQ_CAP_REMOVED_MSG));
    };
    const ActionsCell = getActionsCellComponent([
      { key: 'capRemove', intent: 'danger', title: dictionary.REQ_CAP_REMOVE_BTN, icon: faTrash, action: (cellProps) => onRemoveClick(cellProps.row.index) },
    ]);

    const cols = buildTableCols<RequisitionCapitation>([
      { Header: dictionary.REQ_CAP_NAME, accessor: 'name' },
      { Header: dictionary.REQ_CAP_SAVING_AMOUNT, className: 'cellAlignRight', accessor: capSavingsAmountAccessor, dataTestId: 'cost-saving-amount-cell' },
      { Header: dictionary.REQ_CAP_SAVING_PERCENT, className: 'cellAlignRight', accessor: capSavingsPctAccessor, dataTestId: 'cost-saving-percent-cell' },
      { Header: dictionary.REQ_CAP_PRICE, className: 'cellAlignRight', accessor: reqCapAccessor, dataTestId: 'subtotal-cell' },
      { id: 'expander', Cell: ExpandCell, className: 'cellAlignRight', width: '2rem' },
      { keep: editable, id: 'capActions', Cell: ActionsCell, width: '4rem', className: 'cellAlignRight' },
    ]);

    return cols;
  }, [editable, dispatch]);

  type CapTableOptions = TableOptions<RequisitionCapitation> & UseExpandedOptions<RequisitionCapitation> & { initialState: { expanded: Record<string, boolean> } };

  const capGroupedTableOptions: CapTableOptions = {
    data: memoizedCapitations,
    columns,
    autoResetExpanded: false,
    initialState: {
      expanded: memoizedExpandedCapitations,
    },
    getRowId,
  };

  return useTable<RequisitionCapitation>(capGroupedTableOptions, useExpanded) as ReqCapTableInstance;
};

export const useCapProductSubtable = (
  products: RequisitionProduct[],
  editable: boolean,
  requisition: MergedRequisition,
  capitationIndex: number,
  onEditClick: (capitationIndex: number, productIndex: number) => void,
  hospHasProductCategories: boolean
): TableInstance<RequisitionProduct> => {
  const dispatch = useDispatch();
  const memoizedProducts = useMemo(() => products, [products]);

  const currentUser = useSelector(currentUserSelector);
  const canSeeExtraColumns = currentUser && currentUser.roleName !== userRoles.vendor;

  const columns: Column<RequisitionProduct>[] = useMemo(() => {
    const onRemoveClick = (productIndex: number) => {
      dispatch(removeProductFromCapitationAction(capitationIndex, productIndex));
      dispatch(addNotification(NotificationType.success, dictionary.REQ_CAP_PRODUCT_REMOVED_MSG));
    };
    const ActionsCell = getActionsCellComponent([
      { key: 'productEdit', intent: 'primary', title: dictionary.REQ_PRODUCT_EDIT_BTN, icon: faEdit, action: (cellProps) => onEditClick(capitationIndex, cellProps.row.index) },
      { key: 'productRemove', intent: 'danger', title: dictionary.REQ_CAP_PRODUCT_REMOVE_BTN, icon: faTimes, action: (cellProps) => onRemoveClick(cellProps.row.index) },
    ]);

    const productCategoryAccessor = (rowProduct: any) => renderRequisitionProductCategory(rowProduct, hospHasProductCategories);

    const reqHasMultiplePoNumbers = getRequisitionUniquePoNumberCount(requisition) > 1;

    const cols = buildTableCols<RequisitionProduct>([
      { Header: dictionary.REQ_PRODUCTS_TO_BILL_CATALOG_NUMBER, accessor: 'catalogNumber', width: '10%' },
      { Header: dictionary.REQ_PRODUCT_DESCRIPTION, accessor: 'description', width: '25%' },
      { Header: dictionary.REQ_PRODUCTS_TO_BILL_QUANTITY, accessor: row => valueOrEmpty(row.quantity?.toString()) },
      { Header: dictionary.REQ_PRODUCTS_TO_BILL_SERIAL_NUMBER, accessor: row => valueOrEmpty(row.serialNumber) },
      { Header: dictionary.REQ_PRODUCTS_TO_BILL_LOT_NUMBER, accessor: row => valueOrEmpty(row.lotNumber) },
      { keep: canSeeExtraColumns, Header: dictionary.REQ_PRODUCT_ITEM_ID, accessor: row => valueOrEmpty(row.orderIdentifier) },
      { keep: reqHasMultiplePoNumbers, Header: dictionary.REQ_PO_NUM, accessor: row => renderRequisitionProductPoNumber(row.poNumber) },
      { keep: canSeeExtraColumns, Header: dictionary.REQ_PRODUCT_PRODUCT_CATEGORY, accessor: productCategoryAccessor },
      { keep: canSeeExtraColumns, Header: dictionary.REQ_PRODUCT_CONTRACT_IDENTIFIER, accessor: row => valueOrEmpty(row.contractIdentifier) },
      { keep: !canSeeExtraColumns, id: 'emptyReqPriceColumn' },
      { id: 'emptyExpand' },
      { keep: editable, id: 'actions', Cell: ActionsCell, width: '5%' },
    ]);

    return cols;
  }, [requisition, canSeeExtraColumns, editable, dispatch, capitationIndex, onEditClick, hospHasProductCategories]);

  return useTable<RequisitionProduct>({ data: memoizedProducts, columns });
};
