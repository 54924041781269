import { FormikValues, FormikErrors } from 'formik';
import { dictionary } from '../dictionary';
import { FormFieldDescriptor } from '../Shared/fields/formTypes';

export const twoDecimalNumRegEx = /^\d+(?:\.\d{1,2})?$/;
export const dateRegEx = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/;

export const isInRangeInclusive = (fieldValue: number, min: number, max: number): boolean => (fieldValue >= min && fieldValue <= max);

export const doesNotExceed = (fieldValue: number, max: number): boolean => (fieldValue <= max);

export const isWholeNumber = (fieldValue: number): boolean => Number.isInteger(Number(fieldValue));

export const isNumeric = (num: string): boolean => /^-?\d+\.?\d*$/.test(num);

export const nonAlphanumericRegex = /([^A-Za-z0-9])/;

export const nonAlphanumericRegexGlobal = /([^A-Za-z0-9])/g; // NOTE: This is here because IE11 does not support RegExp() taking a literal instead of a string

export const whiteSpaceRegexGlobal = /\s/g; // Matches all occurance of whitespace

export const maxLimitMsg = (limit: number): string => `Limit of ${limit} chars`;

export const requiredMsg = (name: string): string => dictionary.REQUIRED_ERROR(name);

export const validateRequiredFields = (formValues: FormikValues, fieldsToCheck: FormFieldDescriptor[]): FormikErrors<FormikValues> => {
  const errors: FormikErrors<FormikValues> = {};
  fieldsToCheck.forEach(field => {
    const { name, label, required } = field;

    if (required && !fieldHasValue(formValues[name])) {
      errors[name] = requiredMsg(label);
    }
  });
  return errors;
};

const fieldHasValue = (field: unknown): boolean => {
  switch (typeof field) {
    case 'string':
      return !!field.trim();
    default:
      return Array.isArray(field) ? !!field?.length : !!field;
  }
};

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const validateEmailFormat = (formValues: FormikValues, fieldToCheck: FormFieldDescriptor): FormikErrors<FormikValues> =>
  findErrors(formValues, fieldToCheck, (value) => (!emailRegex.test(value) ? dictionary.INVALID_EMAIL_ERROR : undefined));

// Note: Phone number validation
const phoneRegex = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;
export const validatePhoneNumber = (formValues: FormikValues, fieldToCheck: FormFieldDescriptor): FormikErrors<FormikValues> =>
  findErrors(formValues, fieldToCheck, (value) => (!phoneRegex.test(value?.trim()) ? dictionary.INVALID_PHONE_ERROR : undefined));

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{8,64}$|^$/;
export const validatePassword = (formValues: FormikValues, fieldToCheck: FormFieldDescriptor): FormikErrors<FormikValues> =>
  findErrors(formValues, fieldToCheck, (value) => (!passwordRegex.test(value) ? dictionary.INVALID_PASSWORD_ERROR : undefined));

export const validateCharacterLimit = (formValues: FormikValues, fieldsToCheck: FormFieldDescriptor[], limit: number): FormikErrors<FormikValues> =>
  findErrors(formValues, fieldsToCheck, value => (value?.length > limit ? maxLimitMsg(limit) : undefined));

export const validateTextMinLength = (formValues: FormikValues, fieldsToCheck: FormFieldDescriptor[], limit: number): FormikErrors<FormikValues> =>
  findErrors(formValues, fieldsToCheck, (value?: string) => {
    const scrubbedVal = value?.replace(nonAlphanumericRegexGlobal, '');
    const valLength = scrubbedVal ? scrubbedVal.length : 0;
    return valLength < limit ? dictionary.MIN_CHAR_LIMIT_ERROR(limit) : undefined;
  });

export const validateCurrency = (formValues: FormikValues, fieldsToCheck: FormFieldDescriptor[]): FormikErrors<FormikValues> =>
  findErrors(formValues, fieldsToCheck, validateCurrencyValue);

export const validateNumberInRangeInclusive = (formValues: FormikValues, fieldsToCheck: FormFieldDescriptor[], min: number, max: number): FormikErrors<FormikValues> =>
  findErrors(formValues, fieldsToCheck, value =>
    value &&
    (validateNumeric(value) || (!isInRangeInclusive(Number(value), min, max) ? dictionary.NOT_IN_RANGE_ERROR(min, max) : undefined)));

export const validateNumberDoesNotExceed = (formValues: FormikValues, fieldsToCheck: FormFieldDescriptor[], max: number): FormikErrors<FormikValues> =>
  findErrors(formValues, fieldsToCheck, value =>
    value &&
    (validateNumeric(value) || (!doesNotExceed(Number(value), max) ? dictionary.DOES_NOT_EXCEED_ERROR(max) : undefined)));

export const validateNumberIsWhole = (formValues: FormikValues, fieldsToCheck: FormFieldDescriptor[]): FormikErrors<FormikValues> =>
  findErrors(formValues, fieldsToCheck, value =>
    validateNumeric(value)
    || (!isWholeNumber(value) ? dictionary.NOT_WHOLE_ERROR : undefined));

const findErrors = (
  formValues: FormikValues,
  fieldsToCheck: FormFieldDescriptor[] | FormFieldDescriptor,
  validate: (value: any) => string | undefined
): FormikErrors<FormikValues> => {
  const errors: FormikErrors<FormikValues> = {};
  const addErrorIfExists = (field: FormFieldDescriptor) => {
    const { name } = field;
    const err = validate(formValues[name]);
    if (err) {
      errors[name] = err;
    }
  };

  if (Array.isArray(fieldsToCheck)) {
    fieldsToCheck.forEach(addErrorIfExists);
  } else {
    addErrorIfExists(fieldsToCheck);
  }

  return errors;
};

export const validateCurrencyValue = (value: string | undefined): string | undefined => (!value ? undefined : validateNumeric(value) || validateIsTwoDecimal(value));

const validateNumeric = (value: string) => (!isNumeric(value) ? dictionary.NOT_NUMERIC_ERROR : undefined);
const validateIsTwoDecimal = (value: string) => (!twoDecimalNumRegEx.test(value) ? dictionary.ONLY_TWO_DECIMAL_ERROR : undefined);
