import { FC, useEffect, useState } from 'react';
import { fas, IconName } from '@fortawesome/free-solid-svg-icons';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { Row } from 'react-flexbox-grid';
import { dictionary } from '../../dictionary';
import { PageHeader } from '../../Shared/PageHeader/PageHeader';
import { ReportsLink } from './ReportsLink';
import { routes } from '../../Utils/routes';
import { ReportTile } from '../../Data/Report';
import { reportService } from '../../Services/ReportService';

library.add(fas);

const getIconFromString = (name: IconName) =>
  icon({ prefix: 'fas', iconName: name });

export const Reports: FC = () => {
  const [tiles, setTiles] = useState<ReportTile[]>([]);

  useEffect(() => {
    const tileFetch = async () => reportService.getTileInformation();

    tileFetch()
      .then((t) => setTiles(t))
      .catch(console.error);
  }, []);

  return (
    <>
      <PageHeader title={dictionary.REPORTS_HEADER} />
      <Row>
        {tiles.map((t) => (
          <ReportsLink
            key={t.id}
            toPath={routes.reports.details(t.reportGuid)}
            label={t.reportName}
            icon={getIconFromString(t.iconName)}
            data-testid="full-product-extract"
          />
        ))}
      </Row>
    </>
  );
};
