import { useState, useMemo, useCallback, FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { SubCostCenterForTable, useSubCostCenterTable } from './subCostCenterTable.utils';
import { useTableStyles } from '../../../../Themes/table.styles';
import useUtilityStyles from '../../../../Themes/utility.styles';
import { TableHeaderRow } from '../../../../Shared/ReactTable/TableHeaderRow';
import { Button } from '../../../../Shared/buttons/Button';
import { SubHeader } from '../../../../Shared/SubHeader/SubHeader';
import { TabInfo } from '../../../../Shared/Tabs/Tabs';
import { dictionary } from '../../../../dictionary';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../../redux/initialState';
import { If } from '../../../../Shared/If';
import { EmptyState } from '../../../../Shared/EmptyState/EmptyState';
import { TableBodyRow } from '../../../../Shared/ReactTable/TableBodyRow';
import { joinArgs } from '../../../../Utils/arrayUtils';

interface SubCostCenterTableProps {
  subCostCenters: SubCostCenterForTable[];
  setSubCostCenters: (subCostCenters: SubCostCenterForTable[]) => void;
  isEdit: boolean;
  isCostCenterArchived: boolean;
  userIsAllowedToEdit: boolean;
}

export const SubCostCenterTable: FC<SubCostCenterTableProps> = (props) => {
  const { subCostCenters, setSubCostCenters, isEdit, isCostCenterArchived, userIsAllowedToEdit } = props;

  const [showArchived, setShowArchived] = useState(false);
  const classes = useTableStyles();
  const utilClasses = useUtilityStyles();
  const dispatch = useDispatch();

  const subCostCenterTabs: TabInfo[] = useMemo(() => [
    { title: dictionary.ACTIVE_TAB_LABEL, active: !showArchived, onClick: () => setShowArchived(false) },
    { title: dictionary.ARCHIVED_TAB_LABEL, active: showArchived, onClick: () => setShowArchived(true) },
  ], [showArchived]);

  const onRemoveClick = useCallback((rowId: number) => {
    const subCostCenterRows = [...subCostCenters];
    const updatedRows = subCostCenterRows.filter(scc => scc.id !== rowId);
    setSubCostCenters(updatedRows);
  }, [setSubCostCenters, subCostCenters]);

  const onArchiveActivateClick = useCallback(async (subIds: number[]) => {
    try {
      setSubCostCenters(subCostCenters.map((scc) => (subIds.some(x => x === scc.id) ? { ...scc, archived: !showArchived } : scc)));
    } catch (e) {
      dispatch(addNotification(NotificationType.error, dictionary.ARCHIVE_SUB_COST_CENTER_ERROR));
    }
  }, [dispatch, setSubCostCenters, showArchived, subCostCenters]);

  const filteredSubCostCenters = useMemo(() => subCostCenters.filter((scc) => scc.archived === showArchived), [showArchived, subCostCenters]);
  const userIsAllowedToArchiveTheseRecords = isEdit && !isCostCenterArchived && userIsAllowedToEdit;
  const tableContext = useSubCostCenterTable(filteredSubCostCenters, userIsAllowedToArchiveTheseRecords, userIsAllowedToEdit, onRemoveClick);

  const selectedSubCCs = tableContext.selectedFlatRows.map(r => +r.id);

  return (
    <section>
      <SubHeader title={dictionary.SUB_COST_CENTER_TABLE_HEADER} tabs={isEdit ? subCostCenterTabs : undefined} />
      <If condition={!!tableContext.rows.length}>
        <table {...tableContext.getTableProps()} className={joinArgs(classes.root, classes.tableStripes)}>
          <thead>
            <TableHeaderRow headerGroups={tableContext.headerGroups} />
          </thead>
          <tbody {...tableContext.getTableBodyProps()}>
            {tableContext.rows.map(row => {
              tableContext.prepareRow(row);
              return (
                <TableBodyRow key={row.id} row={row} />
              );
            })}
          </tbody>
        </table>
        <If condition={userIsAllowedToArchiveTheseRecords}>
          <Button
            onClick={() => onArchiveActivateClick(selectedSubCCs)}
            buttonStyle="reverse"
            leadingIcon={<FontAwesomeIcon icon={showArchived ? faFolder : faFolderOpen} />}
            disabled={!selectedSubCCs.length || isCostCenterArchived}
            className={utilClasses.mt1}
          >
            {showArchived ? dictionary.ACTIVATE_SUB_COST_CENTER_BTN : dictionary.ARCHIVE_SUB_COST_CENTER_BTN}
          </Button>
        </If>
      </If>
      <If condition={!tableContext.rows.length}>
        <EmptyState>
          {showArchived ? dictionary.ARCHIVED_SUB_COST_CENTER_TABLE_EMPTY : dictionary.ACTIVE_SUB_COST_CENTER_TABLE_EMPTY}
        </EmptyState>
      </If>
    </section>
  );
};
