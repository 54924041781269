import User from '../../Data/User';
import { ReduxState } from '../initialState';
import { userRoles } from '../../Utils/userRoles';

export const currentUserSelector = (state: ReduxState): User | undefined => state.user?.currentUser;
export const currentUserRoleSelector = (state: ReduxState): string => state.user?.currentUser?.roleName || '';
export const currentUserIsAdminForAtLeastOneHospital = (state: ReduxState): boolean =>
  (state.user?.currentUser?.roleName === userRoles.omiAdmin) || (state.user?.currentUser?.hospitalAssociations.some(x => x.isHospitalAdmin) ?? false);
export const currentUserIsCSRForHospital = (hospitalId: number) => (state: ReduxState): boolean =>
  (state.user?.currentUser?.hospitalAssociations.some(x => x.hospitalId === hospitalId && x.isHospitalCSR) ?? false);
