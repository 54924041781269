import { FormikErrors, FormikValues } from 'formik';
import { DepartmentBase } from '../../../../Data/Department';
import { PhysicianHospital } from '../../../../Data/Physician';
import { dictionary } from '../../../../dictionary';
import { createDescriptor, getFieldDescriptorMap, getFormikInitialValues } from '../../../../Utils/formik.utils';
import { validateCharacterLimit } from '../../../../Utils/validationUtils';

export interface EditPhysicianHospitalFields {
  departments: DepartmentBase[];
  physicianIdentifier: string;
}

export const getPhysicianHospitalModalValues = (association?: PhysicianHospital): EditPhysicianHospitalFields =>
  getFormikInitialValues<EditPhysicianHospitalFields>(
    editPhysicianHospitalFields,
    {
      departments: association?.departments || undefined,
      physicianIdentifier: association?.physicianIdentifier,
    }
  );

export const editPhysicianHospitalFields = getFieldDescriptorMap<EditPhysicianHospitalFields>([
  createDescriptor({ name: 'departments', label: dictionary.PHYSICIAN_FORM_DEPARTMENT_ID, required: true, inputFormat: 'multi-select' }),
  createDescriptor({ name: 'physicianIdentifier', label: dictionary.PHYSICIAN_FORM_PHYSICIAN_ID }),
]);

export const validateEditPhysicianHospitalFields = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { physicianIdentifier } = editPhysicianHospitalFields;

  return {
    ...validateCharacterLimit(values, [physicianIdentifier], 50),
  };
};
