import { PublicClientApplication, EventMessage, EventType } from '@azure/msal-browser';
import crypto from 'crypto';
import * as msalConfig from './AuthConfig';

const pca = new PublicClientApplication(msalConfig.msalInstanceConfig);
export const events: string[] = [];
const loginSuccessCallbacks: (() => void)[] = [];

export function isLoginInProgress(): boolean {
  if (events.length === 0) return true;
  if (events.indexOf(EventType.LOGIN_START) > -1 && events.indexOf(EventType.LOGIN_SUCCESS) === -1) return true;
  return false;
}

pca.handleRedirectPromise()
  .then(handleResponse)
  .catch((err) => {
    console.error(err);
  });

pca.addEventCallback(handleAuthEvent);

export const registerLoginSuccessCb = (cb: () => void): void => {
  loginSuccessCallbacks.push(cb);
  if (events.indexOf(EventType.LOGIN_SUCCESS) > -1) {
    cb();
  }
};

function handleAuthEvent(event: EventMessage) {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    loginSuccessCallbacks.forEach(cb => cb());
  }
  events.push(event.eventType);
}

function handleResponse() {
  // TODO: add logging
}

export function getAccount(): any {
  return pca.getAllAccounts()[0];
}

export async function acquireTokenSilent(): Promise<void | any> {
  const { REACT_APP_B2C_BLANK_URI = '', REACT_APP_B2C_REDIRECT_URI = '' } = process.env;
  try {
    return await pca.acquireTokenSilent(msalConfig.tokenHeaders(REACT_APP_B2C_BLANK_URI, getAccount()));
  } catch (e) {
    return pca.acquireTokenRedirect(msalConfig.tokenHeaders(REACT_APP_B2C_REDIRECT_URI, getAccount()));
  }
}

export function logout(): void {
  pca.logout();
}

export function login(): void {
  try {
    if (isLoginInProgress()) return;
    pca.loginRedirect({ scopes: msalConfig.b2cScopes });
  } catch (error) {
    console.error(`login exception: ${error}`);
  }
}

export function passwordReset(): void {
  const pwdResetFlow = `${process.env.REACT_APP_B2C_PWDRESET_POLICY}`;
  const redirectUrl = encodeURIComponent(`${process.env.REACT_APP_B2C_REDIRECT_URI}`);
  const b2cOfflineScope = encodeURIComponent(`${msalConfig.b2cScopesBase}`);

  let pwdResetURL = `${msalConfig.b2cAuthority}/oauth2/v2.0/authorize?`;
  pwdResetURL += `p=${pwdResetFlow}`;
  pwdResetURL += `&client_id=${msalConfig.b2cClientId}`;
  pwdResetURL += '&nonce=defaultNonce';
  pwdResetURL += `&redirect_uri=${redirectUrl}`;
  pwdResetURL += `&scope=${b2cOfflineScope}`;
  pwdResetURL += '&response_type=code';
  pwdResetURL += '&response_mode=fragment';
  pwdResetURL += '&prompt=login';
  pwdResetURL += `&code_challenge=${challenge}`;
  pwdResetURL += '&code_challenge_method=S256';

  window.location.href = pwdResetURL;
}

const verifier = base64URLEncode(crypto.randomBytes(32));
const challenge = base64URLEncode(sha256(verifier));

function base64URLEncode(str: Buffer) {
  return str.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

function sha256(buffer: any) {
  return crypto.createHash('sha256').update(buffer).digest();
}
