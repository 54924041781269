import apiClient from './apiClient';
import { HospitalProductRequest, HospitalProduct } from '../Data/HospitalProduct';
import Hospital, { HospitalRequest, HospitalBase, AutoArchiveHospital } from '../Data/Hospital';
import { CreateOrEditProductCategoryRequest, ProductCategoriesResponse, ProductCategoryStub } from '../Data/ProductCategory';
import {
  HospMfctIdentifierRequest,
  HospMfctIdentifierResponse,
  HospMfctIdentifierSearchRequest,
  HospMfctIdentifierSearchResponse,
  HospMfctIdentifiersResponse,
  HospMfctIdentifierUpdateRequest,
} from '../Data/ManufacturerIdentifier';
import { HospitalDepartmentApprovalRoutingResponse } from '../Data/Department';
import { HospitalSettings } from '../Data/Settings';

export const hospitalsUrl = '/hospitals';
export const adminHospitalsUrl = '/hospitals/admin';
export const adminHospitalForAutoArchiveUrl = '/hospitals/settings/auto-archive';
export const hospitalBaseByIdUrl = (hospitalId: number): string => `${hospitalsUrl}/bases/${hospitalId}`;
export const hospitalByIdUrl = (hospitalId: number): string => `${hospitalsUrl}/${hospitalId}`;
export const hospitalProductsUrl = '/HospitalProducts';
export const hospitalProductsCatalogNumberUrl = '/HospitalProducts/catalogNumber';
export const hospitalProductCategoriesUrl = '/hospitals/productCategories';
export const hospitalProductCategoriesByHealthSystemIdUrl = (healthSystemId: number): string => `${hospitalsUrl}/${healthSystemId}/productCategories`;
export const productCategoryByIdUrl = (productCategoryId: number): string => `${hospitalProductCategoriesUrl}/${productCategoryId}`;
export const hospMfctIdentifiersUrl = '/hospitals/manufacturer-identifiers';
export const hospMfctIdentifierByIdUrl = (id: number): string => `/hospitals/manufacturer-identifiers/${id}`;
export const hospMfctIdentifierSearchUrl = `${hospMfctIdentifiersUrl}/search`;
export const hospitalSettingsUrl = `${hospitalsUrl}/settings`;

// Hotfix url
export const hospitalDeparmentsByHospitalIdUrl = (hospitalId: number): string => `${hospitalsUrl}/departments/${hospitalId}`;

const createHospital = async (req: HospitalRequest): Promise<{ id: number }> =>
  apiClient.post({ url: hospitalsUrl, data: JSON.stringify(req) });

const getHospitals = async (): Promise<HospitalBase[]> =>
  apiClient.get({ url: hospitalsUrl });

// get associated hospital data from here!
const getAdminHospitals = async (): Promise<HospitalBase[]> =>
  apiClient.get({ url: adminHospitalsUrl });

const getAdminHospitalsForAutoArchive = async (): Promise<AutoArchiveHospital[]> =>
  apiClient.get({ url: adminHospitalForAutoArchiveUrl });

const updateNumberOfDaysUntilArchive = async (request: AutoArchiveHospital): Promise<void> =>
  apiClient.put({ url: adminHospitalForAutoArchiveUrl, data: JSON.stringify(request) });

const updateHospital = async (id: number, hospital: HospitalRequest): Promise<void> =>
  apiClient.put({ url: hospitalByIdUrl(id), data: hospital });

const getHospitalById = async (hospitalId: number): Promise<Hospital> =>
  apiClient.get<Hospital>({ url: hospitalByIdUrl(hospitalId) });

const getHospitalBaseById = async (hospitalId: number): Promise<HospitalBase> =>
  apiClient.get<HospitalBase>({ url: hospitalBaseByIdUrl(hospitalId) });

const getHospitalSettingsById = async (hospitalId: number): Promise<HospitalSettings> =>
  apiClient.get<HospitalSettings>({ url: `${hospitalSettingsUrl}/${hospitalId}` });

const updateHospitalSettings = async (request: HospitalSettings): Promise<void> =>
  apiClient.put({ url: hospitalSettingsUrl, data: request });

const createHospitalProduct = async (req: HospitalProductRequest): Promise<{ id: number }> =>
  apiClient.post({ url: hospitalProductsUrl, data: JSON.stringify(req) });

const fetchHospitalProductsWithCatalogNumber = async (hospitalId: number, manufacturerId: number, catalogNumber: string, caseDate?: string): Promise<HospitalProduct[]> =>
  apiClient.get<HospitalProduct[]>({ url: hospitalProductsCatalogNumberUrl, params: { hospitalId, manufacturerId, catalogNumber, caseDate } });

const createProductCategory = async (req: CreateOrEditProductCategoryRequest): Promise<{ id: number }> =>
  apiClient.post({ url: hospitalProductCategoriesUrl, data: JSON.stringify(req) });

const updateProductCategory = async (id: number, req: CreateOrEditProductCategoryRequest): Promise<void> =>
  apiClient.put({ url: productCategoryByIdUrl(id), data: req });

const fetchProductCategories = async (): Promise<ProductCategoriesResponse> =>
  apiClient.get<ProductCategoriesResponse>({ url: hospitalProductCategoriesUrl });

const getProductCategoryById = async (productCategoryId: number): Promise<ProductCategoryStub> =>
  apiClient.get<ProductCategoryStub>({ url: productCategoryByIdUrl(productCategoryId) });

const getProductCategoriesByHealthSystem = async (healthSystemId: number): Promise<ProductCategoriesResponse> =>
  apiClient.get({ url: hospitalProductCategoriesByHealthSystemIdUrl(healthSystemId) });

const createHospitalManufacturerIdentifier = async (req: HospMfctIdentifierRequest): Promise<void> =>
  apiClient.post({ url: hospMfctIdentifiersUrl, data: JSON.stringify(req) });

const fetchHospitalManufacturerIdentifiers = async (): Promise<HospMfctIdentifiersResponse> =>
  apiClient.get({ url: hospMfctIdentifiersUrl });

const getHospMfctIdentifierById = async (id: number): Promise<HospMfctIdentifierResponse> =>
  apiClient.get({ url: hospMfctIdentifierByIdUrl(id) });

const searchHospitalManufacturerIdentifiers = async (searchRequest: HospMfctIdentifierSearchRequest): Promise<HospMfctIdentifierSearchResponse> =>
  apiClient.post({ url: hospMfctIdentifierSearchUrl, data: JSON.stringify(searchRequest) });

const updateHospMfctIdentifier = async (id: number, req: HospMfctIdentifierUpdateRequest): Promise<void> =>
  apiClient.put({ url: hospMfctIdentifierByIdUrl(id), data: JSON.stringify(req) });

// Hotfix calls
const getDepartmentsByHospitalId = async (hospitalId: number): Promise<HospitalDepartmentApprovalRoutingResponse[]> =>
  apiClient.get({ url: hospitalDeparmentsByHospitalIdUrl(hospitalId) });

export const hospitalService = {
  create: createHospital,
  update: updateHospital,
  getHospitalBases: getHospitals,
  getAdminHospitals,
  getHospitalBaseById,
  getHospitalById,
  getHospitalSettingsById,
  updateHospitalSettings,
  createProduct: createHospitalProduct,
  getProductsWithCatNumber: fetchHospitalProductsWithCatalogNumber,
  getProductCategories: fetchProductCategories,
  getProductCategoryById,
  createProductCategory,
  updateProductCategory,
  getProductCategoriesByHealthSystem,
  fetchHospitalManufacturerIdentifiers,
  getHospMfctIdentifierById,
  searchHospitalManufacturerIdentifiers,
  createManufacturerIdentifier: createHospitalManufacturerIdentifier,
  updateHospMfctIdentifier,
  getDepartmentsByHospitalId,
  getAdminHospitalsForAutoArchive,
  updateNumberOfDaysUntilArchive,
};
