import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { dictionary } from '../../../dictionary';
import { If } from '../../../Shared/If';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';

const useStyles = createUseStyles({
  wrapper: {
    borderRadius: '0.25rem',
    padding: '0.75rem',
  },
  actionLinks: {
    color: 'inherit',
  },
  contactMessage: {
    marginRight: '1rem',
  },
});

interface ContactProps {
  contactPhoneNumber?: string;
  contactEmail?: string;
  contactMessage?: string;
}

export const HospitalContactNotification: FC<ContactProps> = (props) => {
  const classes = useStyles();
  const utilClasses = useUtilityStyles();
  const { contactPhoneNumber, contactEmail, contactMessage } = props;
  const wrapperClasses = joinArgs(utilClasses.backgroundBlue, utilClasses.p1, utilClasses.flex, utilClasses.spaceBetween, utilClasses.textTrueWhite, classes.wrapper);

  return (
    <If condition={!!contactPhoneNumber || !!contactEmail || !!contactMessage}>
      <div data-testid="hospital-contact-notification" className={wrapperClasses}>
        <div className={classes.contactMessage}>
          <FontAwesomeIcon icon={faInfoCircle} className={utilClasses.mr05} />
          {contactMessage}
        </div>
        <div>
          <If condition={!!contactEmail || !!contactPhoneNumber}>
            <span>{`${dictionary.HOSPITAL_CONTACT_LABEL} `}</span>
          </If>

          <a className={classes.actionLinks} href={`mailto:${contactEmail}`}>
            {contactEmail}
          </a>
          <If condition={!!contactEmail && !!contactPhoneNumber}>
            <span>
              {` ${dictionary.OR} `}
            </span>
          </If>
          <a className={classes.actionLinks} href={`tel:${contactPhoneNumber}`}>
            {contactPhoneNumber}
          </a>
        </div>
      </div>
    </If>
  );
};
