import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CreateProcedureRequest } from '../../../Data/Procedure';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { procedureService } from '../../../Services/ProcedureService';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { routes } from '../../../Utils/routes';
import { CreateProcedureForm } from './CreateProcedureForm';

export const CreateProcedure: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onCancel = () => history.push(routes.procedures.index);
  const onComplete = async (request: CreateProcedureRequest) => {
    try {
      await procedureService.create(request);
      dispatch(addNotification(NotificationType.success, dictionary.CREATE_PROCEDURE_SUCCESS));
      history.push(routes.procedures.index);
    } catch (exception) {
      if (exception.message.includes('409')) {
        dispatch(addNotification(NotificationType.error, dictionary.PROCEDURE_NOT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.CREATE_PROCEDURE_ERROR));
      }
    }
  };

  return (
    <FormFlow<CreateProcedureRequest>
      onComplete={onComplete}
      onCancel={onCancel}
      completeButtonLabel={dictionary.CREATE_PROCEDURE_SUBMIT_BTN}
      steps={[
        { pageHeader: dictionary.CREATE_PROCEDURE_PAGE_HEADER, component: CreateProcedureForm },
      ]}
    />
  );
};
