import { ApiRequisition, MergedRequisition } from '../../../Data/Requisition';
import { RequisitionHistoryAction } from '../../../Data/RequisitionHistoryNote';
import { RequisitionStatus } from '../../../Data/RequisitionStatus';
import { uniqueStringFilter } from '../../../Utils/arrayUtils';

export const isStatusApprovedOrCompleted = (status?: RequisitionStatus): boolean => status === RequisitionStatus.approved || status === RequisitionStatus.completed
  || status === RequisitionStatus.archived;

export const getDisplayStatus = (requisition: { status: RequisitionStatus, poNumber: string | null }): string =>
  (requisition.status || '');

export const getLatestReturnReason = (requisition: ApiRequisition): string | undefined =>
  requisition.history.find(n => n.historyActionId === RequisitionHistoryAction.Returned)?.comment;

export const getRequisitionUniquePoNumberCount = (requisition: MergedRequisition): number => {
  let allPoNumbers: (string | null)[] = [requisition.poNumber ?? null];

  if (requisition.products?.length) {
    allPoNumbers = [
      ...requisition.products.map((prod) => prod.poNumber ?? null),
      ...allPoNumbers,
    ];
  }

  if (requisition.capitations?.length) {
    allPoNumbers = [
      ...requisition.capitations.flatMap((cap) => cap.products).map((prod) => prod.poNumber ?? null),
      ...allPoNumbers,
    ];
  }

  const uniquePoNumbers = allPoNumbers.filter(uniqueStringFilter);

  return uniquePoNumbers.length;
};
