import { FC, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch } from 'react-redux';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { HospitalProductDetails, HospitalProductEditRequest } from '../../../Data/HospitalProduct';
import {
  editProductFormFieldsToApiRequest,
  getHospitalProductEditFormInitialVals,
  HospitalProductEditFormFields,
  validateHospitalProductEditForm,
} from './editProduct.utils';
import { hospitalService } from '../../../Services/HospitalService';
import { ProductCategoryStub } from '../../../Data/ProductCategory';
import { contractService } from '../../../Services/ContractService';
import { HealthSystemManufacturerContract } from '../../../Data/Contract';
import LoadState from '../../../redux/loadState';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { EditProductFields } from './EditProductFields';
import { asEasternDateTime, getLocalDateWithTime } from '../../../Utils/dateUtils';
import { EditProductContractPrompt, EditProductContractModalProps } from './EditProductContractPrompt';
import { dictionary } from '../../../dictionary';

interface EditProductFormProps extends FormFlowStepComponent<HospitalProductEditRequest, HospitalProductDetails> {
  product?: HospitalProductDetails
}

export const EditProductForm: FC<EditProductFormProps> = ({ product: productInput, FormActionsComponent, onFormStepComplete }) => {
  const product = productInput!;
  const [productCategories, setProductCategories] = useState<ProductCategoryStub[]>([]);
  const [contracts, setContracts] = useState<HealthSystemManufacturerContract[]>([]);
  const [contractsLoadState, setContractsLoadState] = useState(LoadState.loading);
  const [productCategoriesLoadState, setProductCategoriesLoadState] = useState(LoadState.loading);
  const [modalContext, setModalContext] = useState<EditProductContractModalProps['modalContext']>();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const response = await hospitalService.getProductCategoriesByHealthSystem(product.healthSystemId);
        setProductCategories(response.healthSystemProductCategories);
        setProductCategoriesLoadState(LoadState.loaded);
      } catch (err) {
        setProductCategoriesLoadState(LoadState.error);
      }
    })();
  }, [product, dispatch]);

  useEffect(() => {
    (async () => {
      try {
        const contractResponse = await contractService.getByHospitalAndMfct(product.healthSystemId, product.manufacturerId, product.id);
        setContracts(contractResponse);
        setContractsLoadState(LoadState.loaded);
      } catch (err) {
        setContractsLoadState(LoadState.error);
      }
    })();
  }, [product.healthSystemId, product.manufacturerId, product.id]);

  const formikInitialValues = useMemo(() => getHospitalProductEditFormInitialVals(product), [product]);
  const formikProps = useFormik<HospitalProductEditFormFields>({
    initialValues: formikInitialValues,
    validate: validateHospitalProductEditForm,
    onSubmit: values => {
      const submitForm = () => onFormStepComplete(editProductFormFieldsToApiRequest(values));
      const continueOnClick = () => {
        submitForm();
        setModalContext(undefined);
      };
      const cancelOnClick = () => setModalContext(undefined);
      const newContractWasPicked = product.contractId !== values.contractId;
      const newlyPickedContract = newContractWasPicked ? contracts.find(c => c.id === values.contractId) : undefined;

      if (newlyPickedContract && asEasternDateTime(newlyPickedContract.effectiveDate) > asEasternDateTime(getLocalDateWithTime())) {
        setModalContext({
          message: dictionary.PRODUCT_EDIT_FUTURE_CONTRACT_MSG,
          title: dictionary.PRODUCT_EDIT_FUTURE_MODAL_TITLE,
          cancelOnClick,
          continueOnClick,
        });
      } else if (newlyPickedContract && asEasternDateTime(newlyPickedContract.expirationDate) <= asEasternDateTime(getLocalDateWithTime())) {
        setModalContext({
          message: dictionary.PRODUCT_EDIT_EXPIRED_CONTRACT_MSG,
          title: dictionary.PRODUCT_EDIT_EXPIRED_MODAL_TITLE,
          cancelOnClick,
          continueOnClick,
        });
      } else {
        submitForm();
      }
    },
  });

  if (productCategoriesLoadState === LoadState.error || contractsLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  if (productCategoriesLoadState === LoadState.loading || contractsLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <EditProductFields formikProps={formikProps} product={product} contracts={contracts} productCategories={productCategories} />
      <Row>
        <Col xl={9}>
          <FormActionsComponent />
        </Col>
      </Row>
      <EditProductContractPrompt modalContext={modalContext} />
    </form>
  );
};
