import { FC, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch } from 'react-redux';
import { capitationCategoryFormFields, CapitationCategoryFormFields, validateCreateCapitationCategoryForm, validateEditCapitationCategoryForm } from './capitationCategory.utils';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { CapitationCategory, CapitationCategoryRequest } from '../../../Data/CapitationCategory';
import { HospitalBase } from '../../../Data/Hospital';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { getFormikInitialValues } from '../../../Utils/formik.utils';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';
import { hospitalService } from '../../../Services/HospitalService';
import { If } from '../../../Shared/If';
import { DisplayField } from '../../../Shared/fields/DisplayField';

interface CapitationCategoryFormProps extends FormFlowStepComponent<CapitationCategoryRequest> {
  capitationCategory?: CapitationCategory;
}

export const CapitationCategoryForm: FC<CapitationCategoryFormProps> = (props) => {
  const { FormActionsComponent, onFormStepComplete, capitationCategory } = props;
  const dispatch = useDispatch();
  const [hospitalBases, setHospitalBases] = useState<HospitalBase[]>([]);
  const sortedHospitals = hospitalBases.sort(alphanumericSortDescriptor('name', 1));

  const isEditing = !!capitationCategory;
  const userIsAllowedToFillForm = !isEditing || !!capitationCategory?.canCurrentUserEdit;

  useEffect(() => {
    (async () => {
      if (!isEditing && !hospitalBases.length) {
        try {
          const hospitalBasesResult = await hospitalService.getAdminHospitals();
          setHospitalBases(hospitalBasesResult);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospitalBases, isEditing]);

  const validate = useMemo(() => (isEditing ? validateEditCapitationCategoryForm : validateCreateCapitationCategoryForm), [isEditing]);

  const formikProps = useFormik<CapitationCategoryFormFields>({
    initialValues: getFormikInitialValues<CapitationCategoryFormFields>(
      capitationCategoryFormFields, { name: capitationCategory?.name || '' }
    ),
    validate,
    onSubmit: (formValues) => {
      onFormStepComplete({
        name: formValues.name,
        hospitals: formValues.hospitals.map((h: HospitalBase) => h.id),
      });
    },
  });

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={4}>
          <SimpleFieldWithLabel form={formikProps} descriptor={capitationCategoryFormFields.name} disabled={!userIsAllowedToFillForm} />
          <If condition={!isEditing}>
            <Autocomplete
              multiple
              descriptor={capitationCategoryFormFields.hospitals}
              formikProps={formikProps}
              options={sortedHospitals}
              disabled={!hospitalBases.length}
            />
          </If>
          <If condition={isEditing}>
            <DisplayField
              label={capitationCategoryFormFields.hospitals.label}
              value={capitationCategory?.hospital?.name}
            />
          </If>
        </Col>
      </Row>

      <Row>
        <Col xs={4}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
