import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { UseRowSelectInstanceProps } from 'react-table';
import RequisitionProduct from '../../../../Data/RequisitionProduct';
import { dictionary } from '../../../../dictionary';
import { Button } from '../../../../Shared/buttons/Button';
import { If } from '../../../../Shared/If';
import { useUtilityStyles } from '../../../../Themes/utility.styles';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { ReqProductsTableInstance } from './IndividualProductsTable/individualProductsTable.utils';

export interface AddCapitationsButtonProps {
  editable: boolean;
  ungroupedTableContext: ReqProductsTableInstance;
  addCapitationOnClick?: (productIndices: number[]) => void;
  capitationsApplicable: boolean;
}

export const AddCapitationsButton: FC<AddCapitationsButtonProps> = (props) => {
  const { editable, ungroupedTableContext, addCapitationOnClick, capitationsApplicable } = props;
  const selectedReqProducts = (ungroupedTableContext as unknown as UseRowSelectInstanceProps<RequisitionProduct>).selectedFlatRows.map(r => r.index);
  const isAddCapButtonDisabled = !selectedReqProducts || !selectedReqProducts.length;
  const utilClasses = useUtilityStyles();
  const onClick = addCapitationOnClick ?? (() => {});

  return (
    <>
      <If condition={editable && !capitationsApplicable}>
        <span className={joinArgs(utilClasses.fwNormal, utilClasses.fs14)}>
          {dictionary.CAPITATIONS_NOT_AVAILABLE}
        </span>
      </If>
      <If condition={editable && capitationsApplicable}>
        <Button
          onClick={() => onClick(selectedReqProducts)}
          buttonStyle="reverse"
          leadingIcon={<FontAwesomeIcon icon={faPlus} />}
          disabled={isAddCapButtonDisabled}
        >
          {dictionary.REQ_CAP_SEARCH_OPEN_BUTTON}
        </Button>
      </If>
    </>
  );
};
