import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { HospitalBase } from '../../../../Data/Hospital';

export const hospitalColumnProperties: ColumnProperty<HospitalBase>[] = [
  { displayName: dictionary.HOSPITAL_NAME_LABEL, name: 'name' },
  { displayName: dictionary.HOSPITAL_ID_LABEL, name: 'id' },
  { displayName: dictionary.HEALTH_SYSTEM_NAME_LABEL, name: 'healthSystemName' },
  { displayName: dictionary.HOSPITAL_PHONE_LABEL, name: 'contactPhoneNumber' },
  { displayName: dictionary.HOSPITAL_EMAIL_LABEL, name: 'contactEmail' },
];
