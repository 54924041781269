import { dictionary } from '../../../../dictionary';
import { renderTruncatedCell } from '../../../../Shared/tables/sortableTableRenderers';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

export type CapitationCategoryForTable = {
  id: number;
  hospitalName: string;
  name: string;
};

export const defaultColumnProperties: ColumnProperty<CapitationCategoryForTable>[] = [
  {
    displayName: dictionary.HOSPITAL_NAME_LABEL,
    name: 'hospitalName',
    colRenderCell: renderTruncatedCell(50),
    width: '20%',
  },
  {
    displayName: dictionary.CAPITATION_CATEGORY_LABEL,
    name: 'name',
    colRenderCell: renderTruncatedCell(50),
  },
];
