import { FC } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from './buttons/Button';
import { dictionary } from '../dictionary';
import { Modal } from './modals/Modal/Modal';
import { useTimer } from './useTimer';
import { If } from './If';

type Props = {
  isOpen: boolean,
  exitNow: () => void,
  stayLoggedIn: () => void,
};

export const LogoutModal: FC<Props> = ({ isOpen, exitNow, stayLoggedIn }) => (
  <If condition={isOpen}>
    <LogoutModalInternal exitNow={exitNow} stayLoggedIn={stayLoggedIn} />
  </If>
);

export const LogoutModalInternal: FC<Omit<Props, 'isOpen'>> = ({ exitNow, stayLoggedIn }) => {
  useTimer(exitNow, 60_000);

  return (
    <Modal
      data-testid="logout-modal"
      title={dictionary.AUTH_LOGOUT_MODAL_TITLE}
      isOpen
      icon={faInfoCircle}
      actionsContent={(
        <>
          <Button buttonStyle="reverse" onClick={() => exitNow()} data-testid="exit-now">
            {dictionary.AUTH_LOGOUT_MODAL_EXIT_NOW}
          </Button>
          <Button type="submit" onClick={() => stayLoggedIn()} data-testid="stay-logged-in">
            {dictionary.AUTH_LOGOUT_MODAL_STAY_LOGGED_IN}
          </Button>
        </>
      )}
    >
      {dictionary.AUTH_LOGOUT_MODAL_BODY}
    </Modal>
  );
};
