import { ReactElement, FunctionComponent } from 'react';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { procedureColumnProperties, ProcedureForTable } from './proceduresTable.utils';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

type ProceduresTableProps = {
  procedures: ProcedureForTable[],
  columnProperties?: ColumnProperty<ProcedureForTable>[],
  handleRowClick?: (procedure: ProcedureForTable) => void,
  isLoading: boolean,
};

export const ProceduresTable: FunctionComponent<ProceduresTableProps> = ({
  procedures,
  columnProperties = procedureColumnProperties,
  handleRowClick,
  isLoading,
}): ReactElement => (
  <section>
    <SortableTable
      initialColumnSort="hospitalName"
      rowData={procedures}
      columns={columnProperties}
      handleRowSelect={handleRowClick}
      noDataMessage={dictionary.PROCEDURE_TABLE_EMPTY}
      isLoading={isLoading}
      tableTestId="procedures-table"
    />
  </section>
);
