import { FormikValues, FormikErrors } from 'formik';
import { DepartmentBase } from '../../../Data/Department';
import { HospitalBase } from '../../../Data/Hospital';
import { dictionary } from '../../../dictionary';
import { createDescriptor, getFieldDescriptorMap } from '../../../Utils/formik.utils';
import { validateCharacterLimit, validateRequiredFields } from '../../../Utils/validationUtils';

export interface PhysicianFormFields {
  firstName: string;
  lastName: string;
  middleInitial: string;
  suffix: string;
}

export interface PhysicianAddHospitalFormFields {
  hospital: HospitalBase;
  hospitalPhysicianIdentifier: string;
  departments: DepartmentBase[];
}

export const physicianFormFields = getFieldDescriptorMap<PhysicianFormFields>([
  createDescriptor({ name: 'firstName', label: dictionary.CREATE_PHYSICIAN_FIRST_NAME, required: true }),
  createDescriptor({ name: 'lastName', label: dictionary.CREATE_PHYSICIAN_LAST_NAME, required: true }),
  createDescriptor({ name: 'middleInitial', label: dictionary.CREATE_PHYSICIAN_MIDDLE_INITIAL }),
  createDescriptor({ name: 'suffix', label: dictionary.CREATE_PHYSICIAN_SUFFIX }),
]);

export const physicianAddHospitalFormFields = getFieldDescriptorMap<PhysicianAddHospitalFormFields>([
  createDescriptor({ name: 'hospital', label: dictionary.PHYSICIAN_FORM_HOSPITAL_ID, required: true }),
  createDescriptor({ name: 'hospitalPhysicianIdentifier', label: dictionary.PHYSICIAN_FORM_PHYSICIAN_ID }),
  createDescriptor({ name: 'departments', label: dictionary.PHYSICIAN_FORM_DEPARTMENT_ID, required: true }),
]);

export const validatePhysicianForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { firstName, lastName, middleInitial, suffix } = physicianFormFields;

  return {
    ...validateCharacterLimit(values, [middleInitial], 1),
    ...validateCharacterLimit(values, [firstName, lastName, suffix], 50),
    ...validateRequiredFields(values, Object.values(physicianFormFields)),
  };
};

export const validatePhysicianAddHospitalForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { hospitalPhysicianIdentifier } = physicianAddHospitalFormFields;

  return {
    ...validateCharacterLimit(values, [hospitalPhysicianIdentifier], 50),
    ...validateRequiredFields(values, Object.values(physicianAddHospitalFormFields)),
  };
};
