import { ApiRequisition, ReqStub, ArchivedReqStub } from '../Data/Requisition';
import apiClient from './apiClient';
import { RequisitionCreateRequest, RequisitionUpdateRequest, RequisitionProductsUpdateRequest, RequisitionPatchRequest } from '../Data/RequisitionRequest';
import { RequisitionReturnRequest, RequisitionStatusLogRequest } from '../Data/RequisitionStatusLog';
import RequisitionProduct from '../Data/RequisitionProduct';

export const interfaceRequisitionsUrl = '/requisitions/interface';
export const archiveRequisitionsUrl = '/archived-requisitions';
export const requisitionsUrl = '/requisitions';
export const fetchReqExceptionProducts = (id: number): string => `${requisitionsUrl}/${id}/products`;
export const fetchReqUrl = (id: number): string => `${requisitionsUrl}/${id}`;
export const updateReqUrl = (id: number): string => `${requisitionsUrl}/${id}`;
export const updateReqProductsUrl = (id: number): string => `${requisitionsUrl}/${id}/products`;
export const patchReqProductsUrl = (id: number): string => `${requisitionsUrl}/products/${id}`;
const returnToVendorUrl = (id: number) => `${requisitionsUrl}/${id}/return`;
export const resubmitReqUrl = (id: number): string => `${requisitionsUrl}/${id}/resubmit`;
export const approveReqUrl = (id: number): string => `${requisitionsUrl}/${id}/approve`;
export const declineReqUrl = (id: number): string => `${requisitionsUrl}/${id}/decline`;
export const deleteReqUrl = (id: number): string => `${requisitionsUrl}/${id}/delete`;
export const updateReqStatusIdUrl = (id: number): string => `${requisitionsUrl}/${id}/archive`;
export const unarchiveReqUrl = (id: number): string => `${archiveRequisitionsUrl}/${id}/unarchive`;

export const fetchInterfaceRequisitions = async (): Promise<ReqStub[]> =>
  apiClient.get<ReqStub[]>({ url: interfaceRequisitionsUrl });

export const fetchArchivedRequisitions = async (): Promise<ArchivedReqStub[]> =>
  apiClient.get<ArchivedReqStub[]>({ url: archiveRequisitionsUrl });

export const updateReqStatusId = async (id: number): Promise<void> =>
  apiClient.put({ url: updateReqStatusIdUrl(id) });

export const unarchiveReq = async (id: number): Promise<void> =>
  apiClient.put({ url: unarchiveReqUrl(id) });

export const fetchRequisitions = async (): Promise<ReqStub[]> =>
  apiClient.get<ReqStub[]>({ url: requisitionsUrl });

export const fetchRequisitionExceptionProducts = async (id: number): Promise<RequisitionProduct[]> =>
  apiClient.get<RequisitionProduct[]>({ url: fetchReqExceptionProducts(id) });

export const fetchRequisitionById = async (id: number): Promise<ApiRequisition> =>
  apiClient.get({ url: fetchReqUrl(id) });

export const createRequisition = async (req: RequisitionCreateRequest): Promise<{ id: number }> =>
  apiClient.post({
    url: requisitionsUrl,
    data: JSON.stringify(req),
  });

export const updateRequisition = async (req: RequisitionUpdateRequest): Promise<void> =>
  apiClient.patch({
    url: updateReqUrl(req.id),
    data: JSON.stringify(req),
  });

export const deleteRequisition = async (id: number): Promise<void> =>
  apiClient.post({ url: deleteReqUrl(id) });

export const updateRequisitionProducts = async (id: number, requisitionProducts: RequisitionProductsUpdateRequest): Promise<void> =>
  apiClient.put({
    url: updateReqProductsUrl(id),
    data: JSON.stringify(requisitionProducts),
  });
export const patchRequisitionProducts = async (id: number, product: RequisitionPatchRequest): Promise<void> =>
  apiClient.patch({ url: patchReqProductsUrl(id), data: product });

export const approveRequisition = async (req: RequisitionStatusLogRequest): Promise<void> =>
  apiClient.post({ url: approveReqUrl(req.requisitionId!), data: JSON.stringify(req) });

export const declineRequisition = async (req: RequisitionStatusLogRequest): Promise<void> =>
  apiClient.post({ url: declineReqUrl(req.requisitionId!), data: JSON.stringify(req) });

const returnRequisitionToVendor = async (id: number, req: RequisitionReturnRequest): Promise<void> =>
  apiClient.post({
    url: returnToVendorUrl(id),
    data: JSON.stringify(req),
  });

const resubmitRequisition = async (id: number): Promise<void> =>
  apiClient.post({ url: resubmitReqUrl(id) });

export const requisitionApiService = {
  getAll: fetchRequisitions,
  getAllArchive: fetchArchivedRequisitions,
  getAllForInterface: fetchInterfaceRequisitions,
  getById: fetchRequisitionById,
  create: createRequisition,
  updateBaseInfo: updateRequisition,
  getExceptionProducts: fetchRequisitionExceptionProducts,
  updateProducts: updateRequisitionProducts,
  patchProducts: patchRequisitionProducts,
  returnToVendor: returnRequisitionToVendor,
  resubmit: resubmitRequisition,
  approve: approveRequisition,
  decline: declineRequisition,
  delete: deleteRequisition,
  updateReqStatus: updateReqStatusId,
  unarchiveRequisition: unarchiveReq,
};
