import * as msal from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cAuthority = `${process.env.REACT_APP_B2C_AUTHORITY}`;
export const b2cLoginPolicy = `${process.env.REACT_APP_B2C_LOGIN_POLICY}`;
export const b2cClientId = `${process.env.REACT_APP_B2C_CLIENT_ID}`;

export const b2cScopesBase = `${process.env.REACT_APP_B2C_DOMAIN_NAME}/${process.env.REACT_APP_B2C_CLIENT_ID}`;
export const b2cScopes = [`${b2cScopesBase}/openid`];
export const b2cScopesToken = [`${b2cScopesBase}/offline_access`];
export const b2cAuthorityPolicy = `${b2cAuthority}/${b2cLoginPolicy}`;

export const msalInstanceConfig = {
  auth: {
    clientId: b2cClientId,
    authority: b2cAuthorityPolicy,
    knownAuthorities: [b2cAuthorityPolicy],
    postLogoutRedirectUri: `${process.env.REACT_APP_B2C_REDIRECT_URI}`,
    navigateToLoginRequestUrl: false,
    redirectUri: `${process.env.REACT_APP_B2C_REDIRECT_URI}`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE,
  },
};

export const tokenHeaders = (redirectUri: string, account: msal.AccountInfo): msal.SilentRequest => ({
  scopes: b2cScopesToken,
  authority: b2cAuthorityPolicy,
  redirectUri,
  account,
});
