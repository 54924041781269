import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useSelector } from 'react-redux';
import { ContractProduct } from '../../../Data/Contract';
import { dictionary } from '../../../dictionary';
import { currentUserRoleSelector } from '../../../redux/user/userSelectors';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { If } from '../../../Shared/If';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { routes } from '../../../Utils/routes';
import { userRoles } from '../../../Utils/userRoles';
import { ProductsOnContractTable } from './ProductsOnContractTable/ProductsOnContractTable';
import { ContractProductForTable, getContractProductRows } from './ProductsOnContractTable/productsOnContractTable.utils';

interface ProductsOnContractProps {
  products?: ContractProduct[];
  contractId?: number;
  isActiveContract?: boolean;
  canCurrentUserEdit?: boolean;
}

export const ProductsOnContract: FC<ProductsOnContractProps> = ({ products, contractId, isActiveContract, canCurrentUserEdit }) => {
  const utilClasses = useUtilityStyles();
  const incomingFormattedProducts: ContractProductForTable[] = products ? getContractProductRows(products) : [];
  const numberOfProductsOnContract = incomingFormattedProducts.length;
  const currentUserRole = useSelector(currentUserRoleSelector);

  return (
    <section>
      <Row className={utilClasses.spaceBetween}>
        <Col xs={6}>
          <h3>{dictionary.CONTRACT_PRODUCTS_ON_CONTRACT_TABLE_HEADER}</h3>
        </Col>
        <Col xs={6}>
          <h3 className={joinArgs(utilClasses.fwNormal, utilClasses.textRight)}>
            {`${dictionary.CONTRACT_PRODUCTS_ON_CONTRACT} ${numberOfProductsOnContract}`}
          </h3>
        </Col>
      </Row>
      <If condition={!!contractId && !!isActiveContract && (!!canCurrentUserEdit || currentUserRole === userRoles.omiAdmin)}>
        <Row className={joinArgs(utilClasses.flexEnd, utilClasses.mb3)}>
          <LinkButton to={routes.contracts.updateProducts(contractId!)} leadingIcon={faEdit}>
            {dictionary.CONTRACT_UPDATE_PRODUCTS}
          </LinkButton>
        </Row>
      </If>

      <ProductsOnContractTable
        formattedProducts={incomingFormattedProducts ?? []}
        isLoading={false}
      />
    </section>
  );
};
