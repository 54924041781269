import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { HospMfctIdentifiersIndex } from './HospMfctIdentifiersIndex/HospMfctIdentifiersIndex';
import { CreateHospMfctIdentifier } from './CreateHospMfctIdentifier/CreateHospMfctIdentifier';
import { EditHospMfctIdentifier } from './EditHospMfctIdentifier/EditHospMfctIdentifier';
import { HospitalAdminRoute } from '../../Shared/ProtectedRoute/ProtectedRoute';

export const HospitalManufacturerIdentifiersRoutes: FC = () => (
  <Switch>
    <Route exact path={routes.hospitalManufacturerIdentifiers.index}>
      <HospMfctIdentifiersIndex />
    </Route>
    <HospitalAdminRoute exact path={routes.hospitalManufacturerIdentifiers.create}>
      <CreateHospMfctIdentifier />
    </HospitalAdminRoute>
    <Route exact path={routes.hospitalManufacturerIdentifiers.editTemplate}>
      <EditHospMfctIdentifier />
    </Route>
  </Switch>
);
