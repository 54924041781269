import { FormikValues, FormikErrors } from 'formik';
import { ProductCategoryStub } from '../../../Data/ProductCategory';
import { dictionary } from '../../../dictionary';
import { FormFieldDescriptor } from '../../../Shared/fields/formTypes';
import { createDescriptor, getFormikInitialValues } from '../../../Utils/formik.utils';
import { validateCharacterLimit, validateRequiredFields } from '../../../Utils/validationUtils';

export interface ProductCategoryFormFields {
  healthSystem?: string;
  name: string;
  hospitalProductCategoryIdentifier: string;
}

export const productCategoryFormFields: Record<keyof ProductCategoryFormFields, FormFieldDescriptor> = {
  healthSystem: createDescriptor({ name: 'healthSystem', label: dictionary.HEALTH_SYSTEM_NAME_LABEL, required: true }),
  name: createDescriptor({ name: 'name', label: dictionary.PRODUCT_CATEGORY_FORM_NAME, required: true }),
  hospitalProductCategoryIdentifier: createDescriptor({ name: 'hospitalProductCategoryIdentifier', label: dictionary.PRODUCT_CATEGORY_IDENTIFIER_NAME_LABEL, required: true }),
};

export const getProductCategoryFormInitialVals = (productCategory?: ProductCategoryStub): ProductCategoryFormFields =>
  getFormikInitialValues<ProductCategoryFormFields>(
    productCategoryFormFields,
    {
      healthSystem: productCategory?.healthSystemName,
      name: productCategory?.name,
      hospitalProductCategoryIdentifier: productCategory?.hospitalProductCategoryIdentifier,
    }
  );

export const validateProductCategoryForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { name, hospitalProductCategoryIdentifier } = productCategoryFormFields;

  return {
    ...validateCharacterLimit(values, [name], 150),
    ...validateCharacterLimit(values, [hospitalProductCategoryIdentifier], 50),
    ...validateRequiredFields(values, Object.values(productCategoryFormFields)),
  };
};
