import { createUseStyles } from 'react-jss';
import colors from './colors';

export default createUseStyles({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    width: '100%',
    whiteSpace: 'pre',
    marginRight: '1rem',
    padding: '0.25rem 1.25rem',
    borderRadius: '0.25rem',
    border: '0.0625rem solid transparent',
  },
  large: {
    width: 'auto',
    fontSize: '1.3125rem',
  },
  info: {
    backgroundColor: colors.ice,
    borderColor: colors.blue,
  },
  success: {
    backgroundColor: colors.radiumGreen,
    borderColor: colors.tookishGreen,
  },
  danger: {
    backgroundColor: colors.red,
    color: colors.trueWhite,
  },
  warning: {
    backgroundColor: colors.cantaloupe,
    borderColor: colors.mirkwood,
  },
  processing: {
    backgroundColor: colors.radiumGreen,
    borderColor: colors.tookishGreen,
  },
});
