import { FC, useMemo } from 'react';
import { Column, TableInstance, TableState, usePagination, UsePaginationInstanceProps, useSortBy, UseSortByState, useTable } from 'react-table';
import { TableFooter } from '../../../Shared/ReactTable/TableFooter';
import { joinArgs } from '../../../Utils/arrayUtils';
import { SortableTableProps } from '../../../Shared/SortableTable/SortableTable';
import { useTableStyles } from '../../../Themes/table.styles';
import { SortableTableHeader } from '../../../Shared/ReactTable/SortableTableHeader';
import { TableBody } from '../../../Shared/ReactTable/TableBody';
import { ReqForInterfacesTable } from '../InterfaceIndex/interfacesIndex.utils';

interface InterfacesToBeExportedTableProps {
  requisitions: ReqForInterfacesTable[];
  handleRowClick: (requisition: ReqForInterfacesTable) => void;
  columns: Column<ReqForInterfacesTable>[];
  noDataMessage: string;
  isLoading: boolean;
  initialSortOrder?: SortableTableProps<ReqForInterfacesTable>['defaultSortDirection'];
  initialSortColumn?: string;
  toolTip?: string;
  toolTipDef?: string;
}

export const InterfaceToBeExportedTable: FC<InterfacesToBeExportedTableProps> = (props) => {
  const { requisitions: data, columns, isLoading, noDataMessage, handleRowClick, initialSortColumn = 'approvedDate', initialSortOrder = 'desc', toolTip, toolTipDef } = props;
  const initialSortCol = initialSortColumn;

  const initialState: Partial<TableState<ReqForInterfacesTable> & UseSortByState<ReqForInterfacesTable>> = useMemo(() =>
    ({ sortBy: [{ id: initialSortCol, [initialSortOrder]: true }] }), [initialSortOrder, initialSortCol]);

  const tableContext = useTable({ columns, data, getRowId: item => item.id.toString(), initialState }, useSortBy, usePagination) as
    unknown as TableInstance<ReqForInterfacesTable> & UsePaginationInstanceProps<ReqForInterfacesTable>;

  const tblClasses = useTableStyles({ hasTableContainer: true });
  const reactTableProps = tableContext.getTableProps();

  return (
    <section className={tblClasses.container}>
      <div className={tblClasses.scrollableContainer}>
        <table {...reactTableProps} className={joinArgs(reactTableProps.className, tblClasses.root)}>
          <SortableTableHeader tableContext={tableContext} />
          <TableBody
            tableContext={tableContext}
            toolTip={toolTip}
            toolTipDef={toolTipDef}
            rows={tableContext.page}
            isLoading={isLoading}
            noDataMessage={noDataMessage}
            handleRowClick={handleRowClick}
          />
        </table>
      </div>
      <TableFooter tableContext={tableContext} />
    </section>
  );
};
