import { FormikErrors, FormikValues } from 'formik';
import { ProductCategoryStub } from '../../../../Data/ProductCategory';
import RequisitionProduct from '../../../../Data/RequisitionProduct';
import { dictionary } from '../../../../dictionary';
import { FormFieldDescriptor } from '../../../../Shared/fields/formTypes';
import { createDescriptor, getFieldDescriptorMap } from '../../../../Utils/formik.utils';
import {
  requiredMsg,
  validateCharacterLimit,
  validateCurrency,
  validateNumberDoesNotExceed,
  validateNumberInRangeInclusive,
  validateNumberIsWhole,
  validateRequiredFields,
} from '../../../../Utils/validationUtils';
import { HospitalManufacturerContract } from '../../../../Data/Contract';

export interface EditReqProductFields {
  serialNumber: string;
  lotNumber: string;
  quantity: string;
  price: string;
  orderIdentifier?: string;
  category?: ProductCategoryStub;
  contract?: HospitalManufacturerContract;
  contractPrice?: string;
}

export const getEditReqProductFields = () => getFieldDescriptorMap<EditReqProductFields>([
  createDescriptor({ name: 'serialNumber', label: dictionary.REQ_PRODUCT_SERIAL_NUM }),
  createDescriptor({ name: 'lotNumber', label: dictionary.REQ_PRODUCT_LOT_NUM }),
  createDescriptor({ name: 'quantity', label: dictionary.REQ_PRODUCT_QUANTITY, required: true }),
  createDescriptor({ name: 'price', label: dictionary.REQ_PRODUCT_PRICE, required: true }),
  createDescriptor({ name: 'orderIdentifier', label: dictionary.REQ_PRODUCT_ITEM_ID }),
  createDescriptor({ name: 'category', label: dictionary.REQ_PRODUCT_PRODUCT_CATEGORY }),
  createDescriptor({ name: 'contract', label: dictionary.CONTRACT_ID_LABEL }),
  createDescriptor({ name: 'contractPrice', label: dictionary.REQ_PRODUCT_CONTRACT_PRICE }),
]);

export const validateEditReqProductForm = async (
  values: FormikValues,
  editReqProductFields: Record<keyof EditReqProductFields, FormFieldDescriptor<EditReqProductFields>>
): Promise<FormikErrors<FormikValues>> => {
  const { serialNumber, lotNumber, price, quantity, orderIdentifier, contractPrice } = editReqProductFields;

  const priceValidation = values.contract?.id ? validateNumberInRangeInclusive(values, [contractPrice], 0, 100_000_000) : {};
  if (values.contract?.id && !values.contractPrice) {
    priceValidation.contractPrice = requiredMsg(dictionary.PRODUCT_CONTRACT_PRICE);
  }

  return {
    ...validateCharacterLimit(values, [serialNumber, lotNumber, orderIdentifier], 100),
    ...validateCurrency(values, [price, contractPrice]),
    ...validateNumberDoesNotExceed(values, [price], 100_000_000),
    ...priceValidation,
    ...validateNumberInRangeInclusive(values, [quantity], 1, 5_000),
    ...validateNumberIsWhole(values, [quantity],),
    ...validateRequiredFields(values, Object.values(editReqProductFields)),
  };
};

export const getTranTypeString = (reqProduct: RequisitionProduct): string => {
  const tranTypes = [
    reqProduct.explantDiscountApplied ? dictionary.REQ_PRODUCT_EXPLANT_DISCOUNT : null,
    reqProduct.wastedDiscountApplied ? dictionary.REQ_PRODUCT_WASTED_DISCOUNT : null,
    reqProduct.trialDiscountApplied ? dictionary.REQ_PRODUCT_TRIAL_DISCOUNT : null,
  ];
  return tranTypes.filter(x => !!x).join('|');
};
