import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import User, { EditHospitalUserRequest } from '../../../../Data/User';
import { dictionary } from '../../../../dictionary';
import { NotificationType } from '../../../../redux/initialState';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { editHospitalUser, editVendorUser } from '../../../../Services/UserService';
import { ArchiveButton } from '../../../../Shared/buttons/ArchiveButton';
import { If } from '../../../../Shared/If';
import { createArchiveModalComponent } from '../../../../Shared/modals/ArchiveModal';
import { PageHeader } from '../../../../Shared/PageHeader/PageHeader';

interface DeactivateUserPageHeaderProps {
  user: User;
  isHospitalUser: boolean;
  pageHeader: string;
  onSuccess: (updatedUser: User) => void;
}

export const DeactivateUserPageHeader: FC<DeactivateUserPageHeaderProps> = ({ user, isHospitalUser, pageHeader, onSuccess }) => {
  const dispatch = useDispatch();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const toggleArchived = useCallback(async (userReq: EditHospitalUserRequest) => {
    const userRequest = { ...userReq, archived: !user?.archived };

    try {
      if (isHospitalUser) await editHospitalUser(userRequest);
      else await editVendorUser(userRequest);

      if (!user?.archived) dispatch(addNotification(NotificationType.success, dictionary.DEACTIVATE_USER_SUCCESS));
      else dispatch(addNotification(NotificationType.success, dictionary.ACTIVATE_USER_SUCCESS));

      onSuccess({ ...user!, archived: userRequest.archived });
    } catch (e) {
      dispatch(addNotification(NotificationType.error, dictionary.ARCHIVE_USER_ERROR));
    }
  }, [user, isHospitalUser, dispatch, onSuccess]);

  const openArchiveModal = () => setIsArchiveModalOpen(true);
  const closeArchiveModal = () => setIsArchiveModalOpen(false);

  const toggleArchivedAndCloseModal = useCallback(() => {
    toggleArchived(user!);
    closeArchiveModal();
  }, [user, toggleArchived]);

  const archiveBtnText = useMemo(() => (user?.archived ? dictionary.ACTIVATE_USER_BTN : dictionary.DEACTIVATE_USER_BTN), [user?.archived]);
  const archiveBtnOnClick = useMemo(() => (user?.archived ? toggleArchivedAndCloseModal : openArchiveModal), [user?.archived, toggleArchivedAndCloseModal]);
  const DeactivateUserModal = useMemo(() => createArchiveModalComponent(dictionary.DEACTIVATE_USER_MODAL_HEADER, dictionary.DEACTIVATE_USER_MODAL_TEXT), []);

  return (
    <>
      <PageHeader title={pageHeader}>
        <If condition={!!user?.canCurrentUserEdit}>
          <ArchiveButton archived={!!user?.archived} onClick={archiveBtnOnClick}>{archiveBtnText}</ArchiveButton>
        </If>
      </PageHeader>
      <If condition={!!user?.canCurrentUserEdit}>
        <DeactivateUserModal isOpen={isArchiveModalOpen} onConfirm={toggleArchivedAndCloseModal} onDismiss={closeArchiveModal} />
      </If>
    </>
  );
};
