import colors from './colors';

export const theme = {
  typography: {
    fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
  },
  palette: {
    error: {
      main: colors.red,
    },
    info: {
      main: colors.kingfisher,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        border: undefined,
        font: undefined,
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
  },
};
