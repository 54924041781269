import { faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createUseStyles } from 'react-jss';
import { FC } from 'react';
import { dictionary } from '../../dictionary';

const useSpinnerClasses = createUseStyles({
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(-1turn)' },
  },
  root: {
    animation: '$spin 0.8s linear infinite',
  },
});

export const LoadingSpinner: FC = () => {
  const classes = useSpinnerClasses();

  return (
    <FontAwesomeIcon
      icon={faUndoAlt}
      className={classes.root}
      title={dictionary.LOADING_SPINNER_TITLE}
      name={dictionary.LOADING_SPINNER_TITLE}
      role="status"
    />
  );
};
