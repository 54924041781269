import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HospitalManufacturerContractCapitation } from '../../../../../Data/Capitation';
import { HospitalManufacturerContract } from '../../../../../Data/Contract';
import { MergedRequisition } from '../../../../../Data/Requisition';
import { dictionary } from '../../../../../dictionary';
import { NotificationType } from '../../../../../redux/initialState';
import { addNotification } from '../../../../../redux/notifications/notificationsActionCreator';
import { FormFlowStepComponent } from '../../../../../Shared/FormFlow/FormFlow';
import useUtilityStyles from '../../../../../Themes/utility.styles';
import { joinArgs } from '../../../../../Utils/arrayUtils';
import { ApplyCapitationModal } from '../../ProductsToBill/ApplyCapitationModal/ApplyCapitationModal';
import { ProductsToBill } from '../../ProductsToBill/ProductsToBill';
import { ProductInfoAddProductForm } from '../ProductInfoAddProductForm';
import { ProductInfoNotifications } from '../ProductInfoNotifications';
import { ProductInfoFormValues, defaultValuesWithManufacturer, composeRequisitionForTempProduct, areProductsOnRequisition } from '../shared/ProductInfo.utils';

interface ProductInformationProps extends FormFlowStepComponent<MergedRequisition> {
  editRequisition: MergedRequisition;
  dispatchNewReqStateUpdate: (updatedRequisition: MergedRequisition) => void;
  applyCapitation: (capitation: HospitalManufacturerContractCapitation, productIndices: number[]) => void;
  showHospitalContactNotification?: boolean;
}

export const ProductInfoForm: FC<ProductInformationProps> = (props) => {
  const {
    editRequisition,
    dispatchNewReqStateUpdate,
    onFormStepComplete,
    onFormStepBack,
    onFormSaveDraft,
    FormActionsComponent,
    applyCapitation,
    showHospitalContactNotification = true,
  } = props;
  const [activeContracts, setActiveContracts] = useState<HospitalManufacturerContract[] | null>(null);
  // const [contracts, setContracts] = useState<HospitalManufacturerContract[] | null>(null);
  const [isCapModalOpen, setIsCapModalOpen] = useState(false);
  const [selectedProductIndices, setSelectedProductIndices] = useState<number[]>([]);
  const [capitations, setCapitations] = useState<HospitalManufacturerContractCapitation[]>([]);
  const dispatch = useDispatch();
  const utilClasses = useUtilityStyles();

  const productInfoInitialValues: ProductInfoFormValues = defaultValuesWithManufacturer(editRequisition?.manufacturer);

  const [productInfoFormValues, setProductInfoFormValues] = useState<ProductInfoFormValues>(productInfoInitialValues);
  // const activeContractCapitations = activeContracts?.flatMap(contract => contract.capitations);

  const saveAndBack = () => {
    const updatedRequisition: MergedRequisition = composeRequisitionForTempProduct(productInfoFormValues);
    dispatchNewReqStateUpdate(updatedRequisition);
    if (onFormStepBack) {
      onFormStepBack();
    }
  };

  const saveAndNavigate = (values: ProductInfoFormValues): void => {
    const updatedRequisition: MergedRequisition = composeRequisitionForTempProduct(values);
    dispatchNewReqStateUpdate(updatedRequisition);
    onFormStepComplete(updatedRequisition);
  };

  const onSaveDraftAction = () => {
    onFormSaveDraft!(editRequisition);
  };

  const onNextButton = (values: ProductInfoFormValues): void => {
    if (editRequisition.validate && !areProductsOnRequisition(editRequisition)) {
      dispatch(addNotification(NotificationType.error, dictionary.REQ_PRODUCT_MINIMUM_ERROR));
      return;
    }

    saveAndNavigate(values);
    window.scrollTo(0, 0);
  };

  const closeCapModal = () => setIsCapModalOpen(false);
  const onCapModalOpenClick = (productIndices: number[]) => {
    setSelectedProductIndices(productIndices);
    setIsCapModalOpen(true);
  };
  const onCapModalSubmit = (capitation: HospitalManufacturerContractCapitation) => {
    applyCapitation(capitation, selectedProductIndices);
    closeCapModal();
  };

  const onBackAction = onFormStepBack ? saveAndBack : undefined;
  // const capitationsApplicable = !!contracts?.length && !!activeContractCapitations?.length;
  const capitationsApplicable = !!capitations?.length;

  return (
    <>
      <ProductInfoNotifications requisition={editRequisition} showHospitalContactInfo={showHospitalContactNotification} />
      <ProductInfoAddProductForm
        requisition={editRequisition}
        activeContracts={activeContracts}
        setProductInfoFormValues={setProductInfoFormValues}
        setActiveContracts={setActiveContracts}
        // setContracts={setContracts}
        dispatchNewReqStateUpdate={dispatchNewReqStateUpdate}
        // capitations={capitations}
        setCapitations={setCapitations}
      />

      <h2 className={joinArgs(utilClasses.pb1, utilClasses.horizontalRuleBorder)}>
        {dictionary.REQ_PRODUCTS_TO_BILL_LABEL}
      </h2>

      <section>
        <ProductsToBill editable requisition={editRequisition} addCapitationOnClick={onCapModalOpenClick} capitationsApplicable={capitationsApplicable} />
      </section>
      <FormActionsComponent onBackAction={onBackAction} onSaveDraftAction={onSaveDraftAction as () => void} onSubmitAction={() => onNextButton(productInfoFormValues)} />
      <ApplyCapitationModal isOpen={isCapModalOpen} cancelOnClick={closeCapModal} submitOnClick={onCapModalSubmit} capitationsFromParent={capitations} />
    </>
  );
};
