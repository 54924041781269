import { FC } from 'react';
import useUtilityStyles from '../../Themes/utility.styles';
import useTabStyles from '../../Themes/tabs.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import { Button } from '../buttons/Button';

export interface TabInfo {
  title: string;
  testId?: string;
  active: boolean;
  onClick: () => void;
}

export interface TabsProps {
  tabs: TabInfo[]
}

export const Tabs: FC<TabsProps> = ({ tabs }) => {
  const utilClasses = useUtilityStyles();
  const tabStyles = useTabStyles();

  return (
    <div className={utilClasses.mb2}>
      {tabs.map((tab) => (
        <Button
          key={tab.title}
          buttonStyle={tab.active ? 'text' : 'altText'}
          onClick={tab.onClick}
          data-testid={tab.testId}
          className={joinArgs(utilClasses.mb2, utilClasses.positionRelative, tab.active ? tabStyles.active : '')}
        >
          {tab.title}
        </Button>
      ))}
    </div>
  );
};
