import { FormikProps } from 'formik';
import { FC, useMemo, useState } from 'react';
import { MergedRequisition } from '../../../Data/Requisition';
import { UpdateInformationModal } from './UpdateInformationModal';

interface CaseDateConfirmationFields { caseDate?: any }

export const useCaseDateUpdateConfirmation = <TInput extends CaseDateConfirmationFields, TOutput extends CaseDateConfirmationFields>(
  initialValues: Partial<TInput>,
  onFormStepComplete: (data: TOutput) => void,
  requisition: MergedRequisition,
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const reqHasProducts = (!!requisition.products?.length) || (!!requisition.capitations?.length);

  const onTryFormSubmit = (data: TOutput) => {
    const needsToPresentCaseDatePrompt = reqHasProducts && !isModalOpen && (data.caseDate !== initialValues.caseDate);
    if (needsToPresentCaseDatePrompt) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
      onFormStepComplete(data);
    }
  };

  interface CaseDateConfirmationModalProps { formikProps: FormikProps<TInput>; }
  const CaseDateConfirmationModal = useMemo<FC<CaseDateConfirmationModalProps>>(() => ({ formikProps }) => (
    <UpdateInformationModal
      isOpen={isModalOpen}
      cancelOnClick={() => { setIsModalOpen(false); }}
      continueOnClick={formikProps.handleSubmit}
    />
  ), [isModalOpen]);

  return { onTryFormSubmit, CaseDateConfirmationModal };
};
