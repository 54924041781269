import { ReactElement, useEffect, useMemo, useState } from 'react';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../../dictionary';
import { DepartmentsTable } from './DepartmentsTable/DepartmentsTable';
import { LoadState } from '../../../redux/loadState';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { DepartmentForTable } from './DepartmentsTable/departmentsTable.utils';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { routes } from '../../../Utils/routes';
import { departmentService } from '../../../Services/DepartmentService';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { TabInfo } from '../../../Shared/Tabs/Tabs';

export function DepartmentsIndex(): ReactElement {
  const [departments, setDepartments] = useState<DepartmentForTable[]>([]);
  const [showArchived, setShowArchived] = useState(false);
  const [deptsLoadState, setDeptsLoadState] = useState(LoadState.loading);

  useEffect(() => {
    (async () => {
      try {
        const deptsResponse = await departmentService.get();
        const deptsForTable: DepartmentForTable[] = deptsResponse.departments.map(d => ({
          id: d.id,
          hospitalName: d.hospitalName,
          hospitalId: d.hospitalId,
          departmentName: d.name,
          archived: d.archived,
        }));
        setDepartments(deptsForTable);
        setDeptsLoadState(LoadState.loaded);
      } catch {
        setDeptsLoadState(LoadState.error);
      }
    })();
  }, []);

  const filteredDepts = departments.filter((d) => d.archived === showArchived);

  const tabs: TabInfo[] = useMemo(() => [
    { title: dictionary.ACTIVE_TAB_LABEL, active: !showArchived, onClick: () => setShowArchived(false) },
    { title: dictionary.ARCHIVED_TAB_LABEL, active: showArchived, onClick: () => setShowArchived(true) },
  ], [showArchived]);

  if (deptsLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  if (deptsLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }

  const isLoading = !deptsLoadState || [LoadState.notLoaded, LoadState.loading].includes(deptsLoadState);

  return (
    <>
      <PageHeader title={dictionary.DEPARTMENTS_LIST_HEADER_LABEL} tabs={tabs}>
        <LinkButton to={routes.departments.create}>{dictionary.DEPARTMENTS_CREATE_NEW_ACTION}</LinkButton>
      </PageHeader>

      <DepartmentsTable
        departments={filteredDepts ?? []}
        isLoading={isLoading}
      />
    </>
  );
}
