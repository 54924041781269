import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { actionTypes } from '../../../../redux/actionTypes';
import { NotificationType, ReduxState } from '../../../../redux/initialState';
import { SimpleFieldWithLabel } from '../../../../Shared/fields/SimpleFieldWithLabel';
import { DateFieldWithLabel } from '../../../../Shared/fields/DateFieldWithLabel';
import Manufacturer, { ManufacturerBase } from '../../../../Data/Manufacturer';
import { ContractIdOptional } from '../../../../Data/Contract';
import { contractInfoFormValidateNew } from './contractInfo.validation';
import Autocomplete from '../../../../Shared/Autocomplete/Autocomplete';
import { HospitalManufacturerIdentifier } from '../../../../Data/ManufacturerIdentifier';
import useUtilityStyles from '../../../../Themes/utility.styles';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { FormFlowStepComponent } from '../../../../Shared/FormFlow/FormFlow';
import {
  contractInfoFields, ContractInfoFormFields, getContractInfoFormInitialValues, getContractInfoValidateRequestNew, getContractStateUpdatesFromContractInfoFields,
} from './contractInfo.utils';
import { SimpleDiscountField } from '../../../../Shared/fields/SimpleDiscountField';
import { dictionary } from '../../../../dictionary';
import { contractService } from '../../../../Services/ContractService';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { manufacturerService } from '../../../../Services/ManufacturerService';
import { ErrorPage } from '../../../../Shared/ErrorPage';
import { healthSystemService } from '../../../../Services/HealthSystemService';
import { HealthSystem } from '../../../../Data/HealthSystem';
import { alphanumericSortDescriptor } from '../../../../Utils/SortUtils';

export const ContractInfo: FC<FormFlowStepComponent<ContractIdOptional>> = ({ onFormStepComplete, FormActionsComponent }) => {
  const newContract = useSelector((state: ReduxState) => state.contract.newContract);
  const dispatch = useDispatch();
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [isLoadingHealthSystems, setIsLoadingHealthSystems] = useState(true);
  const [isLoadingManufacturers, setIsLoadingManufacturers] = useState(true);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const [healthSystems, setHealthSystems] = useState<HealthSystem[]>([]);
  const utilClasses = useUtilityStyles();

  useEffect(() => {
    (async () => {
      try {
        const results = await healthSystemService.get();
        if (results) {
          setHealthSystems(results.sort(alphanumericSortDescriptor('name', 1)));
          setIsLoadingHealthSystems(false);
        }
      } catch (e) {
        setHasLoadingError(true);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const fetchedManufacturers = await manufacturerService.getAll();
        if (fetchedManufacturers) {
          setManufacturers(fetchedManufacturers);
          setIsLoadingManufacturers(false);
        }
      } catch (err) {
        setHasLoadingError(true);
      }
    })();
  }, []);

  const formikInitialValues = getContractInfoFormInitialValues({ ...newContract, manufacturerIdentifier: {} as HospitalManufacturerIdentifier });

  const onSubmit = async (values: ContractInfoFormFields) => {
    const updatedContract = getContractStateUpdatesFromContractInfoFields(values);
    try {
      await contractService.validateNew(getContractInfoValidateRequestNew(values));
      dispatch({ type: actionTypes.contract.updateNew, contract: updatedContract });
      onFormStepComplete(updatedContract);
    } catch (e) {
      const exception = e as AxiosError;
      if (exception.response?.status === 409) {
        const healthSystemNameWithConflict = [exception.response?.data.healthSystemName] || [];
        dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_IDENTIFIER_UNIQUENESS_ERROR(healthSystemNameWithConflict)));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_INFO_VALIDATION_REQUEST_FAILED_ERROR));
      }
    }
  };

  const validate = (values: ContractInfoFormFields) => contractInfoFormValidateNew(values, manufacturers);
  const formikProps = useFormik({ initialValues: formikInitialValues, validate, enableReinitialize: true, onSubmit });
  const { values: formValues } = formikProps;

  const chosenMfct = (
    manufacturers
    && formValues.manufacturer
    && manufacturers.find((m: ManufacturerBase) => m.id === formValues.manufacturer?.id)) ? formValues.manufacturer : null;

  if (hasLoadingError) {
    return <ErrorPage />;
  }

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={12} sm={5} lg={4}>
          <Autocomplete
            formikProps={formikProps}
            descriptor={contractInfoFields.healthSystem}
            options={healthSystems}
            getOptionLabel={h => h.name}
            value={formValues.healthSystem}
            disabled={isLoadingHealthSystems || !!newContract.products?.length}
          />
          <Autocomplete
            formikProps={formikProps}
            descriptor={contractInfoFields.manufacturer}
            options={manufacturers}
            value={chosenMfct}
            getOptionLabel={m => m.name}
            disabled={isLoadingManufacturers}
          />
          <SimpleFieldWithLabel descriptor={contractInfoFields.contractIdentifier} form={formikProps} maxLength={200} />
          <DateFieldWithLabel descriptor={contractInfoFields.effectiveDate} form={formikProps} />
          <DateFieldWithLabel descriptor={contractInfoFields.expirationDate} form={formikProps} />
        </Col>

        <Col xs={12} sm={6} lg={4} smOffset={1} className={joinArgs(utilClasses.mt2, utilClasses['sm-mt0'])}>
          <div className={joinArgs(utilClasses.backgroundNearWhite, utilClasses.px2, utilClasses.py1)}>
            <SimpleDiscountField descriptor={contractInfoFields.wastedDiscount} form={formikProps} />
            <SimpleDiscountField descriptor={contractInfoFields.explantDiscount} form={formikProps} />
            <SimpleDiscountField descriptor={contractInfoFields.trialDiscount} form={formikProps} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={9}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
