import { AnyAction } from 'redux';
import initialState, { UserState } from '../initialState';
import { actionTypes } from '../actionTypes';
import LoadState from '../loadState';

export default function userReducer(state = initialState.user, action: AnyAction): UserState {
  switch (action.type) {
    case actionTypes.user.fetch:
      return {
        ...state,
        fetchLoadState: LoadState.loading,
      };
    case actionTypes.user.fetchSuccess:
      return {
        ...state,
        fetchLoadState: LoadState.loaded,
        currentUser: action.payload,
      };
    case actionTypes.user.fetchError:
      return {
        ...state,
        fetchLoadState: LoadState.error,
      };
    case actionTypes.user.clearUser:
      return {
        ...initialState.user,
      };
    default:
      return state;
  }
}
