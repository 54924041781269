import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

export interface ProcedureForTable {
  id: number;
  hospitalName: string;
  departmentName: string;
  procedureName: string;
  archived: boolean;
}

export const procedureColumnProperties: ColumnProperty<ProcedureForTable>[] = [
  { displayName: dictionary.HOSPITAL_NAME_LABEL, name: 'hospitalName' },
  { displayName: dictionary.DEPARTMENT_NAME_LABEL, name: 'departmentName' },
  { displayName: dictionary.PROCEDURE_NAME_LABEL, name: 'procedureName' },
];
