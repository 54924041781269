import { FC, FocusEvent } from 'react';
import { FormikValues } from 'formik';
import { joinArgs } from '../../Utils/arrayUtils';
import { useUtilityStyles } from '../../Themes/utility.styles';

interface DeprecatedCurrencyFieldProps {
  name: string,
  label: string,
  placeholder?: string,
  formikValues: FormikValues,
  handleBlur: (event: FocusEvent<HTMLInputElement>) => void,
  disabled?: boolean
  form: any,
  field: any,
}

export const DeprecatedCurrencyField: FC<DeprecatedCurrencyFieldProps> = (props) => {
  const { form, field, name, label, placeholder = '0.00', handleBlur, disabled = false } = props;
  const utilClasses = useUtilityStyles();

  const isValidationError = form.errors[name] && form.touched[name];
  const labelClass = isValidationError ? 'label-invalid-field' : '';

  return (
    <label htmlFor={name} className={labelClass} data-testid={`${name}-label`}>
      <div className={joinArgs(utilClasses.flex, utilClasses.spaceBetween)}>
        <div>{label}</div>

        {isValidationError && (
          <div role="alert" className="validation-errors" data-testid={`${name}-input-error`}>
            {form.errors[name]}
          </div>
        )}
      </div>

      <input
        {...field}
        className="text-input"
        id={name}
        value={field.value || ''}
        placeholder={placeholder}
        type="text"
        data-testid={`${name}-input`}
        disabled={disabled}
        onBlur={handleBlur}
      />
    </label>
  );
};
