import { FC } from 'react';
import { Route } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { DepartmentsIndex } from './DepartmentsIndex/DepartmentsIndex';
import { CreateDepartment } from './CreateDepartment/CreateDepartment';
import { EditDepartment } from './EditDepartment/EditDepartment';

export const DepartmentsRoutes: FC = () => (
  <>
    <Route exact path={routes.departments.index}>
      <DepartmentsIndex />
    </Route>
    <Route exact path={routes.departments.create}>
      <CreateDepartment />
    </Route>
    <Route exact path={routes.departments.editTemplate}>
      <EditDepartment />
    </Route>
  </>
);
