import { Action } from 'redux';
import initialState, { Notification, NotificationState } from '../initialState';

export type NotificationAction = NotificationAddAction | NotificationRemoveAction;

enum NotificationActionType {
  add = 'notification.add',
  remove = 'notification.remove',
}
export interface NotificationAddAction extends Action {
  type: NotificationActionType.add;
  notification: Notification;
}

export interface NotificationRemoveAction extends Action {
  type: NotificationActionType.remove;
  id: number;
}

export default function notificationsReducer(
  state = initialState.notifications,
  action: NotificationAction,
): NotificationState {
  switch (action.type) {
    case NotificationActionType.add: {
      return [action.notification, ...state];
    }
    case NotificationActionType.remove: {
      return state.filter(notification => notification.id !== action.id);
    }
    default: return state;
  }
}
