import { FC } from 'react';
import { createUseStyles } from 'react-jss';

const displayFieldStyles = createUseStyles({
  label: {
    display: 'flex',
    flexBasis: '210px',
    minHeight: '40px',
    '&.compact': {
      width: '110px',
    },
    '&.center': {
      alignItems: 'center',
    },
    '&.start': {
      alignItems: 'flex-start',
    },
  },
  value: {
    display: 'flex',
    width: 'calc(100% - 210px)',
    fontSize: '0.875rem',
    '&.compact': {
      width: 'calc(100% - 110px)',
    },
    '&.right': {
      justifyContent: 'flex-end',
    },
    '&.center': {
      alignItems: 'center',
    },
    '&.start': {
      alignItems: 'flex-start',
    },
  },
});

export interface DisplayFieldProps {
  label: string;
  value?: string | number | JSX.Element | null;
  compact?: boolean;
  align?: 'right' | 'left';
  labelAlignItems?: 'start' | 'center';
  valueAlignItems?: 'start' | 'center';
}

export const DisplayField: FC<DisplayFieldProps> = ({ label, value, compact, align = 'left', labelAlignItems = 'center', valueAlignItems = 'center' }) => {
  const classes = displayFieldStyles();
  const labelClass = `details-label ${classes.label} ${compact ? 'compact' : ''} ${labelAlignItems}`;
  const valueClass = `${classes.value} ${compact ? 'compact' : ''} ${align} ${valueAlignItems}`;

  return (
    <>
      <div className={labelClass}>{label}</div>
      <div className={valueClass}>{value}</div>
    </>
  );
};
