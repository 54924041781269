import { ReactElement, FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { HospitalProductSearchResult } from '../../../../Data/HospitalProduct';
import { dictionary } from '../../../../dictionary';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { routes } from '../../../../Utils/routes';
import { defaultColumnProperties } from './productSearchResultsTable.utils';

interface ProductSearchResultsTableProps {
  products: HospitalProductSearchResult[];
  isLoading: boolean;
  searchTerm: string;
  searchIsActive: boolean;
}

export const ProductSearchResultsTable: FC<ProductSearchResultsTableProps> = (props): ReactElement => {
  const history = useHistory();
  const { products, isLoading, searchTerm, searchIsActive } = props;
  const noResultsMessage = useMemo(() => dictionary.PRODUCT_SEARCH_TABLE_NO_RESULTS(searchTerm), [searchTerm]);
  const emptyMessage = useMemo(() => (searchIsActive ? noResultsMessage : dictionary.PRODUCT_SEARCH_TABLE_BEGIN), [noResultsMessage, searchIsActive]);

  const onRowClick = (product: HospitalProductSearchResult) => history.push(routes.products.details(product.id));

  return (
    <section>
      <SortableTable
        initialColumnSort="healthSystemName"
        rowData={products || []}
        columns={defaultColumnProperties}
        noDataMessage={emptyMessage}
        isLoading={isLoading}
        tableTestId="product-search-results-table"
        handleRowSelect={onRowClick}
      />
    </section>
  );
};
