import { FC } from 'react';
import { useSelector } from 'react-redux';
import { joinArgs } from '../../Utils/arrayUtils';
import useBadgeStyles from '../../Themes/status-badge.styles';
import { RequisitionStatus } from '../../Data/RequisitionStatus';
import { dictionary } from '../../dictionary';
import { currentUserSelector } from '../../redux/user/userSelectors';
import { userRoles } from '../../Utils/userRoles';

interface StatusBadgeProps {
  status: string;
  large?: boolean;
  getBadgeColorClass: (status: string) => string;
}

export const StatusBadge: FC<StatusBadgeProps> = ({ status, large = false, getBadgeColorClass }) => {
  const user = useSelector(currentUserSelector);
  const classes = useBadgeStyles();
  let value = '';

  if (user?.roleName === userRoles.vendor) {
    let reqStatus = status;
    if (reqStatus === RequisitionStatus.approvedWithExceptions) {
      reqStatus = RequisitionStatus.approved;
    }

    const colorClass = getBadgeColorClass(reqStatus) ?? classes.root;
    return (
      <div className={joinArgs(classes.root, colorClass, large ? classes.large : '')} data-testid="status-badge">
        {reqStatus}
      </div>
    );
  }

  const colorClass = getBadgeColorClass(status) ?? classes.root;
  value = status === RequisitionStatus.approvedWithExceptions ? dictionary.INTERFACE_EXCEPTION_STATUS_BADGE : status;
  return (
    <div className={joinArgs(classes.root, colorClass, large ? classes.large : '')} data-testid="status-badge">
      {value}
    </div>
  );
};
