import { FunctionComponent } from 'react';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { createHospMfctIdentifierColumnProperties, HospMfctIdentifierForCreateTable } from './createHospMfctIdentifierTable.utils';

interface CreateHospMfctIdentifierTableProps {
  identifier: HospMfctIdentifierForCreateTable[];
  columnProperties?: ColumnProperty<HospMfctIdentifierForCreateTable>[];
}

export const CreateHospMfctIdentifierTable: FunctionComponent<CreateHospMfctIdentifierTableProps> = ({ identifier: hospitalManufacturerId }) => (
  <section>
    <SortableTable
      initialColumnSort="manufacturerIdentifier"
      rowData={hospitalManufacturerId}
      columns={createHospMfctIdentifierColumnProperties}
      noDataMessage={dictionary.CREATE_HOSPITAL_MANUFACTURER_ID_TABLE_EMPTY}
      tableTestId="hospital-manufacturers-id-table"
      isLoading={false}
    />
  </section>
);
