import { ReactElement } from 'react';
import { Column } from 'react-table';
import { dictionary } from '../../../../../dictionary';
import { ApiRequisition, ReqStub } from '../../../../../Data/Requisition';
import { StatusRenderReqCell } from '../../../../../Shared/ReqStatusBadge/ReqStatusCell';
import { ColumnProperty } from '../../../../../Shared/SortableTable/SortableTable.types';
import { getDisplayStatus } from '../../../shared/requisition.utils';
import { toCurrency } from '../../../../../Utils/formatNumber';
import { SortValues } from '../../../../../Shared/SortableTable/useSortedRows';
import { nonAlphanumericRegexGlobal } from '../../../../../Utils/validationUtils';
import { buildTableCols, IpmReactTableColumn } from '../../../../../Shared/tables/tableUtils';
import { getTruncatedCellComponent } from '../../../../../Shared/tables/TruncatedCell';
import { getSlashDateCellComponent } from '../../../../../Shared/tables/SlashDateCell';

export const renderStatusReqCell = (val: string): ReactElement => <StatusRenderReqCell value={val} />;

export type ReqForIndexTable = Pick<ApiRequisition,
|'id'
|'createdDate'
|'caseDate'
|'actionReason'
|'approvedDate'
|'processedDate'
|'poNumber'
|'canCurrentUserApprove'
> & {
  status: string;
  statusCode: string;
  hospitalName: string;
  manufacturerName: string;
  departmentName: string;
  value: string;
  mrn: string;
};

export const getRequisitionRows = (reqs: ReqStub[]): ReqForIndexTable[] =>
  reqs.map(req => ({
    status: getDisplayStatus(req),
    statusCode: req.status || '',
    id: req.id || 0,
    createdDate: req.createdDate,
    hospitalName: req.hospital?.name || '',
    departmentName: req.department?.name || '',
    manufacturerName: req.manufacturer?.name || '',
    value: toCurrency(req.totalPrice),
    caseDate: req.caseDate,
    actionReason: req.actionReason || '',
    approvedDate: req.approvedDate,
    processedDate: req.processedDate,
    poNumber: req.poNumber,
    canCurrentUserApprove: req.canCurrentUserApprove || false,
    mrn: req.patientMrn,
  }));

export type ReqTableCols = ColumnProperty<ReqForIndexTable>[];

export const shouldIncludeReqInResults = (filterInput: string, req: ReqForIndexTable, colName?: keyof ReqForIndexTable): boolean => {
  if (!colName) {
    return true;
  }
  const filterColValue = req[colName]?.toString().toLocaleLowerCase() || '';
  const filterInputValue = colName === 'mrn' ? filterInput.replace(nonAlphanumericRegexGlobal, '') : filterInput;

  // Should fall into left part of ternary unless a non-string column is added to col options.
  return typeof filterColValue === 'string' ? (filterColValue.toLocaleLowerCase().indexOf(filterInputValue.toLocaleLowerCase()) !== -1) : true;
};

const composeColumnProperties = (extraCols: Partial<IpmReactTableColumn<ReqForIndexTable>>[]) => buildTableCols<ReqForIndexTable>([
  { Header: dictionary.REQ_STATUS, accessor: 'status', Cell: StatusRenderReqCell },
  { Header: dictionary.REQ_ID, accessor: 'id', Cell: getTruncatedCellComponent(50) },
  { Header: dictionary.FORM_CONTROL_LABEL_PATIENT_MRN, accessor: 'mrn' },
  { Header: dictionary.REQ_DATE_CREATED, accessor: 'createdDate', Cell: getSlashDateCellComponent() },
  ...extraCols,
  { Header: dictionary.REQ_HOSPITAL, accessor: 'hospitalName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_DEPARTMENT, accessor: 'departmentName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_MANUFACTURER, accessor: 'manufacturerName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_VALUE, accessor: 'value', className: 'cellAlignRight' },
]);

export const pendingApprovalColumnProperties = composeColumnProperties([
  { Header: dictionary.REQ_CASE_DATE, accessor: 'caseDate', Cell: getSlashDateCellComponent() },
]);

export const vendorRequisitionTableColumnProperties = composeColumnProperties([
  { Header: dictionary.REQ_CASE_DATE, accessor: 'caseDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_STATUS_REASON, accessor: 'actionReason', Cell: getTruncatedCellComponent(50), width: '30%' },
]);

export const declinedColumnProperties = composeColumnProperties([
  { Header: dictionary.REQ_CASE_DATE, accessor: 'caseDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_STATUS_REASON, accessor: 'actionReason', Cell: getTruncatedCellComponent(50), width: '30%' },
]);

export const approvedColumnProperties = buildTableCols<ReqForIndexTable>([
  { Header: dictionary.REQ_STATUS, accessor: 'status', Cell: StatusRenderReqCell },
  { Header: dictionary.REQ_ID, accessor: 'id', Cell: getTruncatedCellComponent(50) },
  { Header: dictionary.FORM_CONTROL_LABEL_PATIENT_MRN, accessor: 'mrn' },
  { Header: dictionary.REQ_DATE_CREATED, accessor: 'createdDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_DATE_APPROVED, accessor: 'approvedDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_DATE_EXPORTED, accessor: 'processedDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_PO_NUM, accessor: 'poNumber' },
  { Header: dictionary.REQ_HOSPITAL, accessor: 'hospitalName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_DEPT, accessor: 'departmentName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_MANUFACTURER, accessor: 'manufacturerName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_VALUE, accessor: 'value', className: 'cellAlignRight' },
]);

export const archivedColumnProperties = composeColumnProperties([
  { Header: dictionary.REQ_CASE_DATE, accessor: 'caseDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_DATE_APPROVED, accessor: 'approvedDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_DATE_EXPORTED, accessor: 'processedDate', Cell: getSlashDateCellComponent() },
]);

export type ReqTableForIndex = {
  testId: string;
  title: string;
  reqs: ReqForIndexTable[];
  colProps: Column<ReqForIndexTable>[];
  noDataMsg: string;
  sortOrder?: SortValues;
};
