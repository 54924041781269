import { FC } from 'react';
import { useSelector } from 'react-redux';
import RequisitionProduct from '../../../../../Data/RequisitionProduct';
import { currentUserRoleSelector } from '../../../../../redux/user/userSelectors';
import { toCurrency } from '../../../../../Utils/formatNumber';
import { userRoles } from '../../../../../Utils/userRoles';
import useBadgeStyles from './Badges.style';

interface RequisitionProductPriceBadgeProps {
  reqProduct: RequisitionProduct,
}

const RequisitionProductPriceBadge: FC<RequisitionProductPriceBadgeProps> = ({ reqProduct }) => {
  const currentUserRole = useSelector(currentUserRoleSelector);
  const classes = useBadgeStyles();

  const percentMore = Math.round(((reqProduct.price! - reqProduct.contractPrice!) / reqProduct.contractPrice!) * 100);

  if (
    (currentUserRole !== userRoles.vendor)
    && reqProduct.contractPrice
    && (reqProduct.contractPrice < reqProduct.price!)
  ) {
    return (
      <div className={classes.root}>
        <span className={classes.percentMore}>{`${percentMore}% more`}</span>
        <span>{toCurrency(reqProduct.price)}</span>
      </div>
    );
  }

  return <>{toCurrency(reqProduct.price ?? 0)}</>;
};

export const renderRequisitionProductPrice = (reqProduct: RequisitionProduct): JSX.Element => <RequisitionProductPriceBadge reqProduct={reqProduct} />;
