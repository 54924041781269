import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { CellProps, Row, UseExpandedRowProps } from 'react-table';
import { dictionary } from '../../dictionary';
import useUtilityStyles from '../../Themes/utility.styles';
import { Button } from '../buttons/Button';

export const ExpandCell: FC<CellProps<any>> = ({ row }) => {
  const expandableRow = row as Row<any> & UseExpandedRowProps<any>;
  const utilClasses = useUtilityStyles();

  return (
    <Button
      {...expandableRow.getToggleRowExpandedProps()}
      buttonStyle="icon"
      data-testid="collapsable-row-action"
      aria-label="Some data is hidden. Click to show all the data."
      className={utilClasses.fs30}
      leadingIcon={<FontAwesomeIcon icon={expandableRow.isExpanded ? faCaretUp : faCaretDown} size="1x" />}
      intent="secondary"
      id={row.id}
      tooltipTitle={expandableRow.isExpanded ? dictionary.REQ_PRODUCTS_TO_BILL_COLLAPSE_SECTION : dictionary.REQ_PRODUCTS_TO_BILL_EXPAND_SECTION}
    />
  );
};
