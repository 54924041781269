import { ReactElement, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { routes } from '../../../../Utils/routes';
import { HealthSystemForTable, healthSystemColumnProperties } from './healthSystemsTable.utils';

type HealthSystemsTableProps = {
  healthSystems: HealthSystemForTable[],
  columnProperties?: ColumnProperty<HealthSystemForTable>[],
  isLoading: boolean,
};

export const HealthSystemsTable: FunctionComponent<HealthSystemsTableProps> = ({
  healthSystems,
  columnProperties = healthSystemColumnProperties,
  isLoading,
}): ReactElement => {
  const history = useHistory();
  const handleRowClick = (healthSystem: HealthSystemForTable) => history.push(routes.healthSystems.edit(healthSystem.id));

  return (
    <section>
      <SortableTable
        initialColumnSort="name"
        rowData={healthSystems}
        columns={columnProperties}
        handleRowSelect={handleRowClick}
        noDataMessage={dictionary.HEALTH_SYSTEM_TABLE_EMPTY}
        isLoading={isLoading}
        tableTestId="healthsystems-table"
      />
    </section>
  );
};
