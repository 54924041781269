import { FormikProps } from 'formik';
import { FC } from 'react';
import { dictionary } from '../../../dictionary';
import { HospitalContactNotification } from '../../Requisitions/shared/HospitalContactNotification';
import { hospitalFormFields, HospitalFormFields } from './hospitalForm.utils';

interface HospitalFormContactPreviewProps {
  formikProps: FormikProps<HospitalFormFields>;
}

export const HospitalFormContactPreview: FC<HospitalFormContactPreviewProps> = ({ formikProps }) => (
  <>
    <h2>{dictionary.CREATE_HOSPITAL_CONTACT_PREVIEW_LABEL}</h2>
    <HospitalContactNotification
      contactEmail={formikProps.values.contactEmail || `{${hospitalFormFields.contactEmail.label}}`}
      contactMessage={formikProps.values.contactMessage || `{${hospitalFormFields.contactMessage.label}}`}
      contactPhoneNumber={formikProps.values.contactPhoneNumber || `{${hospitalFormFields.contactPhoneNumber.label}}`}
    />
  </>
);
