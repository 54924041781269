import { FC } from 'react';
import { StatusBadge } from './StatusBadge';

interface StatusRenderCellProps {
  cellValue: string;
  getCellValueColorClass: (status: string) => string;
}

export const StatusRenderCell: FC<StatusRenderCellProps> = ({ cellValue, getCellValueColorClass }) => (
  <StatusBadge status={cellValue} getBadgeColorClass={getCellValueColorClass} />
);
