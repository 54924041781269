/* eslint-disable max-len */
import apiClient from './apiClient';
import {
  Contract,
  ContractForPost,
  ContractStub,
  ContractUpdateProductsRequest,
  ContractUpdateRequest,
  HealthSystemManufacturerContract,
  HospitalManufacturerContract,
  ValidateContractRequestNew } from '../Data/Contract';
import { HospitalManufacturerContractCapitation } from '../Data/Capitation';

export const getProductPricingTemplateEndpoint = '/contracts/productPricingTemplate';

export const contractsUrl = '/contracts';
export const userAdminContractsUrl = '/contracts/admin';
export const activeContractsUrl = `${contractsUrl}/active`;
export const validateContractsUrlNew = `${contractsUrl}/validate-new`;
export const getContractsByIdUrl = (contractId: number): string => `${contractsUrl}/${contractId}`;
export const updateContractProductsUrl = (contractId: number): string => `${contractsUrl}/${contractId}/products`;
export const getContractCapitationUrl = (): string => `${contractsUrl}/capitations`;

export const fetchContracts = async (showActive: boolean): Promise<ContractStub[]> =>
  apiClient.get<ContractStub[]>({ url: contractsUrl, params: { showActive } });

export const fetchContractsForAdminUser = async (): Promise<ContractStub[]> =>
  apiClient.get<ContractStub[]>({ url: userAdminContractsUrl });

export const validateContractNew = async (validateRequest: ValidateContractRequestNew): Promise<void> =>
  apiClient.post({ url: validateContractsUrlNew, data: JSON.stringify(validateRequest) });

export const createContract = async (contract: ContractForPost): Promise<number> =>
  apiClient.post({ url: contractsUrl, data: JSON.stringify(contract) });

export const getContractById = async (contractId: number): Promise<Contract> =>
  apiClient.get<Contract>({ url: getContractsByIdUrl(contractId) });

export const updateContractById = async (contract: ContractUpdateRequest): Promise<void> =>
  apiClient.put({ url: getContractsByIdUrl(contract.id), data: JSON.stringify(contract) });

export const updateContractProductsById = async (contract: ContractUpdateProductsRequest): Promise<number> =>
  apiClient.put({ url: updateContractProductsUrl(contract.id), data: JSON.stringify(contract) });

export const fetchActiveHospitalManufacturerContracts = async (hospitalId: number, manufacturerId: number, caseDate: string, catalogNumber: string): Promise<HospitalManufacturerContract[]> =>
  apiClient.get({ url: activeContractsUrl, params: { hospitalId, manufacturerId, caseDate, catalogNumber } });

export const fetchHealthSystemManufacturerContracts = async (healthSystemId: number, manufacturerId: number, contractProductId: number): Promise<HealthSystemManufacturerContract[]> =>
  apiClient.get({ url: contractsUrl, params: { healthSystemId, manufacturerId, contractProductId } });

export const fetchCapitationsByContractId = async (manufacturerId: number, hospitalId: number): Promise<HospitalManufacturerContractCapitation[]> =>
  apiClient.get({ url: getContractCapitationUrl(), params: { manufacturerId, hospitalId } });

export const contractService = {
  get: fetchContracts,
  validateNew: validateContractNew,
  create: createContract,
  getById: getContractById,
  update: updateContractById,
  updateProducts: updateContractProductsById,
  getActive: fetchActiveHospitalManufacturerContracts,
  getByHospitalAndMfct: fetchHealthSystemManufacturerContracts,
  getCapitations: fetchCapitationsByContractId,
};
