import { FC, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ReqStub } from '../../../Data/Requisition';
import { dictionary } from '../../../dictionary';
import { fetchInterfaceRequisitions } from '../../../Services/RequisitionService';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { TabInfo, Tabs } from '../../../Shared/Tabs/Tabs';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { InterfacesExceptionsTable } from '../InterfaceExceptions/InterfaceExceptions';
import { InterfacesIndexFilters } from './InterfacesIndexFilters';
import { InterfacesToBeExportedTable } from '../InterfaceToBeExported/InterfaceToBeExported';
import { getInterfaceRows, ReqForInterfacesTable, shouldIncludeReqInResults } from './interfacesIndex.utils';
import { If } from '../../../Shared/If';

export const Interfaces: FC = () => {
  const [formattedReqs, setFormattedReqs] = useState<ReqForInterfacesTable[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [filterColumnSelectedOption, setFilterColumnSelectedOption] = useState<keyof ReqForInterfacesTable>();
  const [activeTab, setActiveTab] = useState(0);
  const utilClasses = useUtilityStyles();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const requisitionsResponse: ReqStub[] = await fetchInterfaceRequisitions();
        const incomingFormattedReqs: ReqForInterfacesTable[] = getInterfaceRows(requisitionsResponse);
        setFormattedReqs(incomingFormattedReqs);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [activeTab]);

  const filteredReqs = useMemo(
    () => formattedReqs.filter(req => req.status && shouldIncludeReqInResults(filter, req, filterColumnSelectedOption)),
    [filter, filterColumnSelectedOption, formattedReqs]
  );

  const interfaceTabs: TabInfo[] = useMemo(() => [
    { title: dictionary.TO_BE_EXPORTED, active: activeTab === 0, onClick: () => { setActiveTab(0); } },
    { title: dictionary.INTERFACE_EXCEPTION_TAB, active: activeTab === 1, onClick: () => { setActiveTab(1); } },
  ], [activeTab]);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader title={dictionary.INTERFACES_HEADER} withoutBottomMargin />

      <Row>
        <Col xs={12} lg={6} className={joinArgs(utilClasses.flexRow, utilClasses.p0)}>
          <Tabs tabs={interfaceTabs} />
        </Col>
        <Col xs={12} lg={6}>
          <InterfacesIndexFilters
            filterColumn={filterColumnSelectedOption}
            onSelectFilterColumn={setFilterColumnSelectedOption}
            filterText={filter}
            onChangeFilterText={setFilter}
          />
        </Col>
      </Row>
      <If condition={activeTab === 0}>
        <InterfacesToBeExportedTable isLoading={isLoading} requisitions={filteredReqs} />
      </If>
      <If condition={activeTab === 1}>
        <InterfacesExceptionsTable isLoading={isLoading} requisitions={filteredReqs} />
      </If>
    </>
  );
};
