import { FC, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { ModalContent } from './ModalContent';
import { ModalProps } from './modalTypes';

export const Modal: FC<ModalProps> = (props) => {
  const { isOpen, onOpen } = props;
  const appRootElementRef = useRef(document.getElementById('root') || document.body);
  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen, onOpen]);

  if (!isOpen || !appRootElementRef.current) return null;

  return createPortal(<ModalContent {...props} />, appRootElementRef.current);
};
