import { FormikValues, FormikErrors } from 'formik';
import { HospitalBase } from '../../../Data/Hospital';
import { dictionary } from '../../../dictionary';
import { FormFieldDescriptor } from '../../../Shared/fields/formTypes';
import { createDescriptor } from '../../../Utils/formik.utils';
import { validateCharacterLimit, validateRequiredFields } from '../../../Utils/validationUtils';

export interface CapitationCategoryFormFields {
  name: string;
  hospitals: HospitalBase[];
}

export const capitationCategoryFormFields: Record<keyof CapitationCategoryFormFields, FormFieldDescriptor> = ({
  name: createDescriptor({ name: 'name', label: dictionary.CAPITATION_CATEGORY_FORM_CATEGORY_NAME, required: true }),
  hospitals: createDescriptor({ name: 'hospitals', label: dictionary.CAPITATION_CATEGORY_FORM_HOSPITAL, required: true, inputFormat: 'multi-select' }),
});

export const validateCreateCapitationCategoryForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { name } = capitationCategoryFormFields;

  return {
    ...validateCharacterLimit(values, [name], 150),
    ...validateRequiredFields(values, Object.values(capitationCategoryFormFields)),
  };
};

export const validateEditCapitationCategoryForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { name } = capitationCategoryFormFields;

  return {
    ...validateCharacterLimit(values, [name], 150),
    ...validateRequiredFields(values, [capitationCategoryFormFields.name]),
  };
};
