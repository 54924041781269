import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { ContractsTable } from '../ContractsTable/ContractsTable';
import { routes } from '../../../Utils/routes';
import { dictionary } from '../../../dictionary';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { contractService, fetchContracts } from '../../../Services/ContractService';
import { ContractStatus, ContractStub } from '../../../Data/Contract';
import { currentUserIsAdminForAtLeastOneHospital } from '../../../redux/user/userSelectors';
import { If } from '../../../Shared/If';
import LoadState from '../../../redux/loadState';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../redux/initialState';
import { TabInfo } from '../../../Shared/Tabs/Tabs';

export const ContractsIndex: FC = () => {
  const [contracts, setContracts] = useState<ContractStub[]>([]);
  const [showActiveContracts, setShowActiveContracts] = useState(true);
  const [contractsLoadState, setContractsLoadState] = useState(LoadState.loading);
  const canCurrentUserCreate = useSelector(currentUserIsAdminForAtLeastOneHospital);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const fetchedContracts = await fetchContracts(showActiveContracts);
        setContracts(fetchedContracts);
        setContractsLoadState(LoadState.loaded);
      } catch {
        setContractsLoadState(LoadState.error);
      }
    })();
  }, [showActiveContracts]);

  const reloadContract = async (id: number) => {
    const idx = contracts.findIndex(c => c.id === id);
    contracts[idx].status = ContractStatus.Loading;
    setContracts([...contracts]);

    try {
      const fetchedContract = await contractService.getById(id) as ContractStub;
      contracts[idx].status = fetchedContract.status;
      contracts[idx].numberOfProducts = fetchedContract.numberOfProducts;

      setTimeout(() => setContracts([...contracts]), 3000);
    } catch (e) {
      contracts[idx].status = ContractStatus.Processing;
      setTimeout(() => {
        setContracts([...contracts]);
        dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
      }, 3000);
    }
  };

  const costCenterTabs: TabInfo[] = useMemo(() => [
    { title: dictionary.ACTIVE_TAB_LABEL, active: showActiveContracts, onClick: () => setShowActiveContracts(true) },
    { title: dictionary.CONTRACT_STATUS_EXPIRED, active: !showActiveContracts, onClick: () => setShowActiveContracts(false) },
  ], [showActiveContracts]);

  if (contractsLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  if (contractsLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }

  const isLoading = !contractsLoadState || [LoadState.notLoaded, LoadState.loading].includes(contractsLoadState);

  return (
    <>
      <PageHeader title={dictionary.CONTRACTS_INDEX_HEADER} tabs={costCenterTabs}>
        <If condition={canCurrentUserCreate}>
          <LinkButton data-testid="create-contract" to={routes.contracts.create}>
            {dictionary.CONTRACTS_INDEX_ACTION}
          </LinkButton>
        </If>
      </PageHeader>

      <ContractsTable isLoading={isLoading} contracts={contracts} reloadContract={reloadContract} />
    </>
  );
};
