import { DateTime } from 'luxon';
import { Column, SortByFn, UseSortByColumnOptions } from 'react-table';

export type IpmReactTableColumn<T extends Record<string, any>> = Column<T> & UseSortByColumnOptions<T> & {
  keep?: boolean;
};

const buildTableCol = <T extends Record<string, any>>(provided: Partial<IpmReactTableColumn<T>>): Column<T> | undefined => {
  const shouldBuildAColumnToBeIncluded = provided.keep || provided.keep === undefined;
  if (!shouldBuildAColumnToBeIncluded) return undefined;

  const col: Column<T> = {
    width: 'auto',
    ...provided,
  } as Column<T>;

  return col;
};

export const buildTableCols = <T extends Record<string, any>>(providedCols: Partial<IpmReactTableColumn<T>>[]): Column<T>[] =>
  providedCols
    .map(buildTableCol)
    .filter((c): c is Column<T> => c !== undefined);

export const sortDateString: SortByFn<any> = (rowA, rowB, columnId) => {
  const datetimeA = DateTime.fromISO((rowA.original[columnId]));
  const datetimeB = DateTime.fromISO((rowB.original[columnId]));

  return datetimeA > datetimeB ? -1 : 1;
};
