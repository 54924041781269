import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../../dictionary';
import { PhysiciansTable } from './PhysiciansTable/PhysiciansTable';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { Physician } from '../../../Data/Physician';
import { getFullName } from '../../../Utils/nameUtils';
import { PhysicianForTable } from './PhysiciansTable/physicansTable.utils';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { routes } from '../../../Utils/routes';
import { physicianService } from '../../../Services/PhysicianService';
import { TabInfo } from '../../../Shared/Tabs/Tabs';
import { currentUserIsAdminForAtLeastOneHospital } from '../../../redux/user/userSelectors';
import { If } from '../../../Shared/If';

export const PhysiciansIndex: FC = () => {
  const [physicians, setPhysicians] = useState<Physician[]>([]);
  const [showArchived, setShowArchived] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const isHospitalAdmin = useSelector(currentUserIsAdminForAtLeastOneHospital);

  useEffect(() => {
    (async () => {
      try {
        const fetchedPhysicians = await physicianService.getAll();
        if (fetchedPhysicians) {
          setPhysicians(fetchedPhysicians);
          setIsLoading(false);
        }
      } catch (err) {
        setHasLoadingError(true);
      }
    })();
  }, []);

  const physicianTabs: TabInfo[] = useMemo(() => [
    { title: dictionary.ACTIVE_TAB_LABEL, active: !showArchived, onClick: () => setShowArchived(false) },
    { title: dictionary.ARCHIVED_TAB_LABEL, active: showArchived, onClick: () => setShowArchived(true) },
  ], [showArchived]);

  if (hasLoadingError) {
    return <ErrorPage />;
  }

  const tablePhysicians: PhysicianForTable[] =
    physicians.flatMap((p) =>
      (p.hospitals.length ? p.hospitals.map(h => ({
        ...p,
        id: `${p.id}-${h.id}`,
        physicianId: p.id,
        name: getFullName(p, true),
        physicianIdentifier: h.physicianIdentifier ?? dictionary.EMPTY_FIELD_MARK,
        hospitalName: h.name,
        departmentName: h.departments.map(d => d.name).join(', '),
        archived: p.archived,
      })) : [{
        ...p,
        id: p.id.toString(),
        physicianId: p.id,
        name: getFullName(p, true),
        physicianIdentifier: dictionary.EMPTY_FIELD_MARK,
        hospitalName: dictionary.EMPTY_FIELD_MARK,
        departmentName: dictionary.EMPTY_FIELD_MARK,
      }]));

  const filteredPhysicians = tablePhysicians.filter((p) => p.archived === showArchived);

  return (
    <>
      <PageHeader title={dictionary.PHYSICIANS_LIST_HEADER_LABEL} tabs={physicianTabs}>
        <If condition={isHospitalAdmin}>
          <LinkButton to={routes.physicians.create}>{dictionary.PHYSICIANS_CREATE_NEW_ACTION}</LinkButton>
        </If>
      </PageHeader>

      <PhysiciansTable physicians={filteredPhysicians ?? []} isLoading={isLoading} />
    </>
  );
};
