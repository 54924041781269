import { maxProductsPerRequisition } from '../envSettings';

export const maximumProductsPerRequisition = maxProductsPerRequisition || 500;

export enum UnitOfMeasure {
  EA = 'EA',
  BG = 'BG',
  BX = 'BX',
  CA = 'CA',
  CS = 'CS',
  DOZ = 'DOZ',
  KIT = 'KIT',
  KT = 'KT',
  PCS = 'PCS',
  PK = 'PK',
  PKG = 'PKG',
  SET = 'SET',
}
export const autoArchiveNumDaysDefault = 30;
export const autoArchiveNumDaysMin = 1;
export const autoArchiveNumDaysMax = 365;

export const unitOfMeasures = Object.values(UnitOfMeasure);
export interface UnitOfMeasureOption {
  id: number;
  name: string;
}
export const unitOfMeasuresAsOptions: UnitOfMeasureOption[] = Object.values(UnitOfMeasure).map((uom: string, idx) => ({ id: idx, name: uom }));

export const toUnitOfMeasure = (s: string): UnitOfMeasure | undefined => (UnitOfMeasure as any)[s];
