import { FormikValues } from 'formik';
import { dictionary } from '../../../dictionary';
import {
  requiredMsg,
  isNumeric,
  isInRangeInclusive,
  isWholeNumber,
  validateCurrencyValue,
} from '../../../Utils/validationUtils';

export interface FormikErrors {
  hospital?: string,
  department?: string,
  buyerApprovers?: string,
  firstApprovers?: string,
  firstApproverThreshold?: string,
  secondApprovers?: string,
  secondApproverThreshold?: string,
  thirdApprovers?: string,
}

export const validateBaseInformation = (values: FormikValues): FormikErrors => {
  const { hospital, department } = values;
  const errors: FormikErrors = {};

  if (!hospital) {
    errors.hospital = requiredMsg('Hospital');
  }

  if (!department) {
    errors.department = requiredMsg('Department');
  }

  return errors;
};

export const validateApprovers = (values: FormikValues): FormikErrors => {
  const { firstApprovers, firstApproverThreshold, secondApprovers, secondApproverThreshold, thirdApprovers, buyerApprovers } = values;
  const errors: FormikErrors = {};

  if (!buyerApprovers || buyerApprovers.length === 0) {
    errors.buyerApprovers = requiredMsg('Buyer(s)');
  }

  if (!firstApprovers || firstApprovers.length === 0) {
    errors.firstApprovers = requiredMsg('First Approver(s)');
  }

  if (firstApproverThreshold) {
    if (validateCurrencyValue(firstApproverThreshold)) {
      errors.firstApproverThreshold = validateCurrencyValue(firstApproverThreshold);
    } else if (!isInRangeInclusive(firstApproverThreshold, 1, 500_000)) {
      errors.firstApproverThreshold = dictionary.APPROVAL_ROUTES_THRESHOLD_ERROR;
    } else if (!isWholeNumber(firstApproverThreshold)) {
      errors.firstApproverThreshold = dictionary.APPROVAL_ROUTES_WHOLE_DOLLAR_ERROR;
    }

    if ((Number(firstApproverThreshold) >= 1) && (secondApprovers.length === 0)) {
      errors.secondApprovers = requiredMsg('Second Approver(s)');
    }
  }

  if (secondApprovers && secondApprovers.length && !firstApproverThreshold) {
    errors.firstApproverThreshold = requiredMsg('1st Approver Threshold');
  }

  if (secondApproverThreshold) {
    if (!isNumeric(secondApproverThreshold)) {
      errors.secondApproverThreshold = dictionary.NOT_NUMERIC_ERROR;
    } else if (!isInRangeInclusive(secondApproverThreshold, 0, 500_000)) {
      errors.secondApproverThreshold = dictionary.APPROVAL_ROUTES_THRESHOLD_ERROR;
    } else if (!isWholeNumber(secondApproverThreshold)) {
      errors.secondApproverThreshold = dictionary.APPROVAL_ROUTES_WHOLE_DOLLAR_ERROR;
    } else if (Number(secondApproverThreshold) <= Number(firstApproverThreshold)) {
      errors.secondApproverThreshold = dictionary.APPROVAL_ROUTES_SECOND_GREATER_THAN_FIRST_ERROR;
    }

    if (thirdApprovers.length === 0) {
      errors.thirdApprovers = requiredMsg('Third Approver(s)');
    }
  }

  if (thirdApprovers && thirdApprovers.length && !secondApproverThreshold) {
    errors.secondApproverThreshold = requiredMsg('2nd Approver Threshold');
  }

  return errors;
};
