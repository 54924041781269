import { FC } from 'react';
import { FormikValues, useFormik } from 'formik';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../Shared/buttons/Button';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { dictionary } from '../../../dictionary';
import { Modal } from '../../../Shared/modals/Modal/Modal';
import { validateCharacterLimit, validateRequiredFields } from '../../../Utils/validationUtils';
import { FormFieldDescriptor } from '../../../Shared/fields/formTypes';
import { createDescriptor } from '../../../Utils/formik.utils';
import { useUtilityStyles } from '../../../Themes/utility.styles';

type Props = {
  isOpen: boolean,
  cancelOnClick: () => void,
  declineOnClick: (reason: string) => void,
};

export const ReqDeclineModal: FC<Props> = ({ isOpen, cancelOnClick, declineOnClick }) => {
  const utilClasses = useUtilityStyles();

  const declineModalFields: Record<string, FormFieldDescriptor> = {
    declineReason: createDescriptor({ name: 'declineReason', label: dictionary.REQ_DECLINE_MODAL_REASON, required: true }),
  };
  const validate = (values: FormikValues) => ({
    ...validateCharacterLimit(values, [declineModalFields.declineReason], 255),
    ...validateRequiredFields(values, [declineModalFields.declineReason]),
  });
  const declineSubmitHandler = (values: FormikValues) => declineOnClick(values.declineReason);
  const formikConfig = useFormik({
    initialValues: { declineReason: '' },
    validate,
    onSubmit: declineSubmitHandler,
    validateOnChange: true,
    validateOnBlur: true,
  });

  return (
    <Modal
      data-testid="decline-modal"
      title={dictionary.REQ_DETAILS_DECLINE_MODAL_DESC}
      isOpen={isOpen}
      icon={faInfoCircle}
      actionsContent={(
        <>
          <Button buttonStyle="reverse" onClick={() => cancelOnClick()} data-testid="modal-cancel">
            {dictionary.CANCEL}
          </Button>
          <Button type="submit" data-testid="modal-decline" onClick={formikConfig.submitForm}>
            {dictionary.REQ_DECLINE_MODAL_SUBMIT_BUTTON}
          </Button>
        </>
      )}
    >
      <SimpleFieldWithLabel
        className={utilClasses.mt075n}
        form={formikConfig}
        name="declineReason"
        labelText={dictionary.REQ_DECLINE_MODAL_REASON}
        value={formikConfig.values.declineReason}
        placeholder={dictionary.REQ_DECLINE_MODAL_REASON_PLACEHOLDER}
      />
    </Modal>
  );
};
