import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { useModalStyles } from '../../../../Shared/modals/Modal/modal.styles';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { RowAction, ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

interface UserHospitalAssociationRowData {
  id: number;
  name: string;
}

interface UserHospitalAssociationDetailsTableProps {
  rowData: UserHospitalAssociationRowData[];
  displayName: string;
  tableEmptyText: string;
  removeRowText: string;
  onRemoveAssociation?: (dept: UserHospitalAssociationRowData) => void;
}

export const EditUserAssociationModalFormTable: FC<UserHospitalAssociationDetailsTableProps> = (props) => {
  const { rowData, onRemoveAssociation, displayName, tableEmptyText, removeRowText } = props;
  const modalClasses = useModalStyles();

  const rowActions: RowAction<UserHospitalAssociationRowData>[] = !onRemoveAssociation ? [] : [
    {
      name: removeRowText,
      icon: faTrash,
      onAction: row => onRemoveAssociation(row),
      intent: 'danger',
    }];

  const tableColumns: ColumnProperty<UserHospitalAssociationRowData>[] = [
    {
      displayName,
      name: 'name',
    },
  ];

  return (
    <SortableTable
      rowData={rowData}
      columns={tableColumns}
      initialColumnSort="name"
      isLoading={false}
      noDataMessage={tableEmptyText}
      rowActions={rowActions}
      className={modalClasses.table}
    />
  );
};
