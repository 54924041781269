import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { Contract, buildContractUpdateProductsRequest, ContractIdentifierUniquenessError } from '../../../Data/Contract';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { actionTypes } from '../../../redux/actionTypes';
import { routes } from '../../../Utils/routes';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { contractService } from '../../../Services/ContractService';
import { EditContractProducts } from './EditContractProducts';
import { ReviewAndUpdateProducts } from './ReviewAndUpdateProducts';

export const UpdateContractProducts: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onComplete = async (contractSubmitted: Partial<Contract>) => {
    try {
      const contract = buildContractUpdateProductsRequest(contractSubmitted as Contract);
      await contractService.updateProducts(contract); // TODO: patch

      dispatch({ type: actionTypes.contract.clearEditing });
      setTimeout(() => dispatch(addNotification(NotificationType.success, dictionary.CONTRACT_UPDATE_PROCESSING)), 1000);
      history.push(routes.contracts.index);
    } catch (e) {
      const exception = e as AxiosError;
      if (exception.response?.status === 409) {
        const hospitalNamesWithConflict = (exception.response?.data as ContractIdentifierUniquenessError).hospitalNames || [];
        dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_IDENTIFIER_UNIQUENESS_ERROR(hospitalNamesWithConflict)));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_CREATE_ERROR));
      }
    }
  };

  const onCancel = () => {
    dispatch({ type: actionTypes.contract.clearEditing });
    history.goBack();
  };

  return (
    <FormFlow<Contract>
      steps={[
        { pageHeader: dictionary.CONTRACT_UPDATE_PRODUCTS, stepTitle: dictionary.CONTRACT_EDIT_PRODUCTS_STEPPER_LABEL, component: EditContractProducts },
        { pageHeader: dictionary.CONTRACT_UPDATE_PRODUCTS, stepTitle: dictionary.CONTRACT_REVIEW_AND_UPDATE_PRODUCTS_STEPPER_LABEL, component: ReviewAndUpdateProducts },
      ]}
      completeButtonLabel={dictionary.CONTRACT_UPDATE_BUTTON_LABEL}
      onCancel={onCancel}
      onComplete={onComplete}
    />
  );
};
