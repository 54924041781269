import { useState, useEffect, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../redux/initialState';
import LoadState from '../redux/loadState';
import { getCurrentUserAction } from '../redux/user/userActionCreators';
import { login, getAccount, passwordReset, registerLoginSuccessCb } from '../Services/LoginService';
import { If } from './If';
import { ErrorPage } from './ErrorPage';

export const Authentication: FC = ({ children }) => {
  const userAccount = getAccount();
  const [isLoggedIn, setIsLoggedIn] = useState(!!userAccount);
  const { fetchLoadState, currentUser } = useSelector((state: ReduxState) => state.user);
  const dispatch = useDispatch();
  const loginSuccessCb = useCallback(() => {
    setIsLoggedIn(true);
  }, []);

  useEffect(() => {
    if (!userAccount) {
      registerLoginSuccessCb(loginSuccessCb);
    }
  }, [loginSuccessCb, userAccount]);

  useEffect(() => {
    if (window.location.href.indexOf('/passwordreset') > -1) {
      passwordReset();
    } else if (!userAccount) {
      login();
    }
  }, [userAccount]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCurrentUserAction());
    }
  }, [isLoggedIn, dispatch]);

  if (fetchLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  return (
    <>
      <If condition={isLoggedIn && !!currentUser}>
        { children }
      </If>
    </>
  );
};
