import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../../dictionary';
import { ProductCategoriesTable } from './ProductCategoriesTable/ProductCategoriesTable';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { ProductCategoryStub } from '../../../Data/ProductCategory';
import { hospitalService } from '../../../Services/HospitalService';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { routes } from '../../../Utils/routes';
import { currentUserIsAdminForAtLeastOneHospital } from '../../../redux/user/userSelectors';
import { If } from '../../../Shared/If';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';

export function ProductCategoriesIndex(): ReactElement {
  const [productCategories, setProductCategories] = useState<ProductCategoryStub[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const showCreateButton = useSelector(currentUserIsAdminForAtLeastOneHospital);

  useEffect(() => {
    (async () => {
      try {
        const response = await hospitalService.getProductCategories();
        if (response) {
          setProductCategories(response.healthSystemProductCategories);
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        setHasLoadingError(true);
      }
    })();
  }, []);

  if (isLoading) {
    return (
      <LoadingSpinner />
    );
  }

  if (hasLoadingError) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader title={dictionary.PRODUCT_CATEGORIES_LIST_HEADER_LABEL}>
        <If condition={showCreateButton}>
          <LinkButton to={routes.productCategories.create}>{dictionary.PRODUCT_CATEGORY_CREATE_NEW_ACTION}</LinkButton>
        </If>
      </PageHeader>

      <ProductCategoriesTable
        productCategories={productCategories ?? []}
        isLoading={isLoading}
      />
    </>
  );
}
