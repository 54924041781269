import { FormikErrors } from 'formik';
import { DepartmentBase } from '../../../../Data/Department';
import { HospitalBase } from '../../../../Data/Hospital';
import { PhysicianBase } from '../../../../Data/Physician';
import { ProcedureBase } from '../../../../Data/Procedure';
import { MergedRequisition } from '../../../../Data/Requisition';
import { dictionary } from '../../../../dictionary';
import { createDescriptor, getFieldDescriptorMap, getFormikInitialValues } from '../../../../Utils/formik.utils';
import { validateRequiredFields } from '../../../../Utils/validationUtils';

export interface ProcedureInfoFormFields {
  hospital?: HospitalBase;
  department?: DepartmentBase;
  procedure?: ProcedureBase;
  physician?: PhysicianBase;
}

export const procedureInfoFields = getFieldDescriptorMap<ProcedureInfoFormFields>([
  createDescriptor({ name: 'hospital', label: dictionary.FORM_CONTROL_LABEL_HOSPITAL, required: true }),
  createDescriptor({ name: 'department', label: dictionary.FORM_CONTROL_LABEL_DEPARTMENT, required: true }),
  createDescriptor({ name: 'procedure', label: dictionary.FORM_CONTROL_LABEL_PROCEDURE, required: true }),
  createDescriptor({ name: 'physician', label: dictionary.FORM_CONTROL_LABEL_PHYSICIAN, required: true }),
]);

type ProcedureInfoFormValidationErrors = Record<keyof ProcedureInfoFormFields, string>;

export const validateProcedureInfoForm = (values: ProcedureInfoFormFields): FormikErrors<ProcedureInfoFormValidationErrors> => ({
  ...validateRequiredFields(values, Object.values(procedureInfoFields)),
} as any);

export const validateProcedureInfoFormMinimum = (values: ProcedureInfoFormFields): FormikErrors<ProcedureInfoFormValidationErrors> => ({
  ...validateRequiredFields(values, Object.values([procedureInfoFields.hospital, procedureInfoFields.department])),
} as any);

export const getProcedureInfoInitialValues = (req: MergedRequisition) => getFormikInitialValues(procedureInfoFields, req);
