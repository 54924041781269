import { FC, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../../dictionary';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { CostCenterStub } from '../../../Data/CostCenter';
import { costCenterService } from '../../../Services/CostCenterService';
import { CostCentersTable } from './CostCentersTable/CostCentersTable';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { routes } from '../../../Utils/routes';
import { TabInfo } from '../../../Shared/Tabs/Tabs';
import LoadState from '../../../redux/loadState';
import { currentUserIsAdminForAtLeastOneHospital } from '../../../redux/user/userSelectors';
import { If } from '../../../Shared/If';

export const CostCentersIndex: FC = () => {
  const [costCenters, setCostCenters] = useState<CostCenterStub[]>([]);
  const [showArchived, setShowArchived] = useState(false);
  const [costCenterLoadState, setCostCenterLoadState] = useState(LoadState.loading);
  const showCreateButton = useSelector(currentUserIsAdminForAtLeastOneHospital);

  useEffect(() => {
    (async () => {
      try {
        const fetchedCostCenters = await costCenterService.getAll();
        if (fetchedCostCenters) {
          setCostCenters(fetchedCostCenters);
          setCostCenterLoadState(LoadState.loaded);
        }
      } catch (e) {
        setCostCenterLoadState(LoadState.error);
      }
    })();
  }, []);

  const filteredCostCenters = costCenters.filter((cc) => cc.archived === showArchived);

  const costCenterTabs: TabInfo[] = useMemo(() => [
    { title: dictionary.ACTIVE_TAB_LABEL, active: !showArchived, onClick: () => setShowArchived(false) },
    { title: dictionary.ARCHIVED_TAB_LABEL, active: showArchived, onClick: () => setShowArchived(true) },
  ], [showArchived]);

  if (costCenterLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  const isLoading = !costCenterLoadState || [LoadState.notLoaded, LoadState.loading].includes(costCenterLoadState);

  return (
    <>
      <PageHeader title={dictionary.COST_CENTERS_LABEL} tabs={costCenterTabs}>
        <If condition={showCreateButton}>
          <LinkButton to={routes.costCenters.create}>{dictionary.COST_CENTERS_CREATE_NEW_ACTION}</LinkButton>
        </If>
      </PageHeader>

      <CostCentersTable
        costCenters={filteredCostCenters ?? []}
        isLoading={isLoading}
      />
    </>
  );
};
