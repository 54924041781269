import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../../dictionary';
import { HospitalsTable } from './HospitalsTable/HospitalsTable';
import { routes } from '../../../Utils/routes';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { HospitalBase } from '../../../Data/Hospital';
import { hospitalService } from '../../../Services/HospitalService';
import { ErrorPage } from '../../../Shared/ErrorPage';

export function HospitalsIndex(): ReactElement {
  const [isLoading, setIsLoading] = useState(true);
  const [hospitalBases, setHospitalBases] = useState<HospitalBase[]>([]);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hospitalBases.length) {
      (async () => {
        try {
          const hospitalBaseResult = await hospitalService.getHospitalBases();
          setHospitalBases(hospitalBaseResult);
          setIsLoading(false);
        } catch (e) {
          setHasLoadingError(true);
        }
      })();
    }
  }, [dispatch, hospitalBases.length]);

  if (hasLoadingError) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader title={dictionary.HOSPITALS_LIST_HEADER_LABEL}>
        <LinkButton to={routes.hospitals.create}>{dictionary.HOSPITALS_CREATE_NEW_ACTION}</LinkButton>
      </PageHeader>

      <HospitalsTable
        hospitalBases={hospitalBases ?? []}
        isLoading={isLoading}
      />
    </>
  );
}
