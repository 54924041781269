import { MergedRequisition } from '../Data/Requisition';
import { RequisitionCreateRequest } from '../Data/RequisitionRequest';
import RequisitionProduct from '../Data/RequisitionProduct';
import { getIsoFormattedDate } from './dateUtils';

export function convertToCreateRequest(req: MergedRequisition, savingAsDraft = false): RequisitionCreateRequest {
  const requestRequisition = {} as RequisitionCreateRequest;

  // create req page 1 - Procedure Info
  requestRequisition.hospitalId = req.hospital!.id;
  requestRequisition.departmentId = req.department!.id;
  requestRequisition.procedureId = req.procedure?.id;
  requestRequisition.physicianId = req.physician?.id;
  // create req page 2 - Case/Patient Info
  requestRequisition.patientId = req.patient?.id;
  requestRequisition.caseNumber = req.caseNumber || '';
  requestRequisition.caseDate = req.caseDate ? getIsoFormattedDate(req.caseDate!) : null;
  // create req page 3 - Product Info
  requestRequisition.manufacturerId = req.manufacturer?.id;
  requestRequisition.vendorId = req.vendor?.id;
  if (!req.products) req.products = [];
  requestRequisition.products = req.products!.map((p: RequisitionProduct) => ({
    // HospitalProduct already exists, so these are the only fields the API needs
    requisitionId: req.id, // putting this on here for simplicity for now
    healthSystemProductId: p.healthSystemProductId,
    serialNumber: p.serialNumber,
    lotNumber: p.lotNumber,
    quantity: p.quantity,
    explantDiscountApplied: p.explantDiscountApplied,
    trialDiscountApplied: p.trialDiscountApplied,
    wastedDiscountApplied: p.wastedDiscountApplied,
    discount: p.discount,
    price: p.price,
    contractProductId: p.contractProductId || null,
  }));
  requestRequisition.capitations = req.capitations!;
  // create req page 4 - Review & Submit
  requestRequisition.additionalNotes = req.additionalNotes! || '';
  // for draft feature
  requestRequisition.savingAsDraft = savingAsDraft;
  requestRequisition.id = req.id;

  return requestRequisition;
}

export function reqIsReadyForSubmit(req: RequisitionCreateRequest): boolean {
  return !!req.caseDate
    && !!req.caseNumber
    && !!req.departmentId
    && !!req.hospitalId
    && !!req.manufacturerId
    && !!req.patientId
    && !!req.physicianId
    && !!req.procedureId
    && !!(req.products?.length || req.capitations?.length);
}
