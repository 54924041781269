import { FormikErrors, FormikValues } from 'formik';
import { createDescriptor, getFieldDescriptorMap } from '../../../Utils/formik.utils';
import { nonAlphanumericRegexGlobal, validateCharacterLimit, validateTextMinLength } from '../../../Utils/validationUtils';
import { HealthSystem } from '../../../Data/HealthSystem';

export interface ProductSearchOption {
  id: string;
  value: string | null;
  displayName: string;
}

export interface ProductSearchFields {
  healthSystem: HealthSystem | null;
  searchText: string;
  hospital: ProductSearchOption;
  manufacturer: ProductSearchOption;
  contractIdentifier: ProductSearchOption;
}

export const productSearchFields = getFieldDescriptorMap<ProductSearchFields>([
  createDescriptor({ name: 'searchText', label: '' }),
  createDescriptor({ name: 'healthSystem', label: '' }),
  createDescriptor({ name: 'manufacturer', label: '' }),
  createDescriptor({ name: 'contractIdentifier', label: '' }),
]);

export const validateProductSearchForm = (values: ProductSearchFields): FormikErrors<FormikValues> => {
  const valsWithAlphanumSearchText: ProductSearchFields = { ...values, searchText: values.searchText.replace(nonAlphanumericRegexGlobal, '') };
  return {
    ...validateCharacterLimit(values, [productSearchFields.searchText], 500),
    ...validateTextMinLength(valsWithAlphanumSearchText, [productSearchFields.searchText], 3),
  };
};
