import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import Hospital, { HospitalBase } from '../../../../Data/Hospital';
import Manufacturer from '../../../../Data/Manufacturer';
import { HospitalUserRole, hospitalUserRolesforHospitalUsers, hospitalUserRolesforVendorUsers, UserHospitalAssociation } from '../../../../Data/User';
import { dictionary } from '../../../../dictionary';
import { NotificationType } from '../../../../redux/initialState';
import Autocomplete from '../../../../Shared/Autocomplete/Autocomplete';
import { Button } from '../../../../Shared/buttons/Button';
import { useUtilityStyles } from '../../../../Themes/utility.styles';
import { alphanumericSortDescriptor } from '../../../../Utils/SortUtils';
import { manufacturerService } from '../../../../Services/ManufacturerService';
import { If } from '../../../../Shared/If';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { hospitalService } from '../../../../Services/HospitalService';
import { currentUserSelector } from '../../../../redux/user/userSelectors';
import { userRoles } from '../../../../Utils/userRoles';

import {
  AddUserAssociationFormFields,
  getAddUserAssociationFormFields,
  getUserRoleOption,
  validateAddHospitalUserAssociationForm,
  validateAddVendorUserAssociationForm,
} from '../user.utils';
import { getFormikInitialValues } from '../../../../Utils/formik.utils';
import { useSubFormKeyPress } from '../../../../Shared/SubFormKeyPress/useSubFormKeyPress';
import { joinArgs } from '../../../../Utils/arrayUtils';

interface UserHospitalAssociationFormProps {
  hospitalAssociations: UserHospitalAssociation[];
  addHospitalAssociation: (row: UserHospitalAssociation) => void;
  displayVendorUser?: boolean;
}

export const UserHospitalAddAssociationForm: FC<UserHospitalAssociationFormProps> = (props) => {
  const utilClasses = useUtilityStyles();
  const { addHospitalAssociation, hospitalAssociations, displayVendorUser = false } = props;
  const dispatch = useDispatch();

  const [hospitalBases, setHospitalBases] = useState<HospitalBase[]>([]);
  const [selectedHospital, setSelectedHospital] = useState<Hospital>();
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const filteredHospitalOptions = hospitalBases.filter(h => !hospitalAssociations.map(ha => ha.hospitalId).includes(h.id));

  const addAssociationFormFields = getAddUserAssociationFormFields(displayVendorUser);
  const defaultUserRole = getUserRoleOption(displayVendorUser ? HospitalUserRole.StandardRepresentative : HospitalUserRole.Staff);
  const currentUser = useSelector(currentUserSelector);

  const validate = useMemo(() => (displayVendorUser ? validateAddVendorUserAssociationForm : validateAddHospitalUserAssociationForm), [displayVendorUser]);
  const formikProps = useFormik({
    initialValues: getFormikInitialValues<AddUserAssociationFormFields>(addAssociationFormFields, { [addAssociationFormFields.userRole.name]: defaultUserRole }),
    validate,
    onSubmit: (formValues, formikHelpers) => {
      const association: UserHospitalAssociation = {
        hospitalId: formValues.hospital.id,
        hospitalName: formValues.hospital.name,
        departments: formValues.departments,
        manufacturers: formValues.manufacturers,
        hospitalUserRole: formValues.userRole?.id,
      };
      addHospitalAssociation(association);
      formikHelpers.resetForm();
    },
  });

  const selectedHospitalId = formikProps.values.hospital?.id;

  useEffect(() => {
    (async () => {
      try {
        const hospitalBasesResult = await hospitalService.getAdminHospitals();
        setHospitalBases(hospitalBasesResult);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.REQ_HOSPITAL_FETCH_ERROR));
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (selectedHospitalId) {
        setSelectedHospital(undefined);
        try {
          const hosp = await hospitalService.getHospitalById(selectedHospitalId);
          setSelectedHospital(hosp);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.REQ_HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, selectedHospitalId]);

  useEffect(() => {
    if (!displayVendorUser) return;

    (async () => {
      try {
        const fetchedManufacturers = await manufacturerService.getAll();
        if (fetchedManufacturers) {
          setManufacturers(fetchedManufacturers);
        }
      } catch (err) {
        dispatch(addNotification(NotificationType.error, dictionary.FETCH_MANUFACTURERS_ERROR));
      }
    })();
  }, [displayVendorUser, dispatch]);

  const handleKeyPress = useSubFormKeyPress(formikProps.handleSubmit);

  const sortedHospitals = filteredHospitalOptions?.sort(alphanumericSortDescriptor('name', 1));
  const sortedActiveDepartments = useMemo(() => selectedHospital?.departments.filter(d => !d.archived).sort(alphanumericSortDescriptor('name', 1)) || [], [selectedHospital]);

  return (
    <>
      <h2 className={utilClasses.m0}>{dictionary.FORM_CONTROL_LABEL_ADD_ASSOCIATIONS}</h2>
      <Row>
        <If condition={displayVendorUser}>
          <Col xs={6} className={displayVendorUser ? utilClasses.pr2 : ''}>
            <Autocomplete
              formikProps={formikProps}
              multiple
              id="user-association-manufacturer"
              data-testid="user-association-manufacturer"
              descriptor={addAssociationFormFields.manufacturers}
              options={manufacturers}
              onKeyPress={handleKeyPress}
              disabled={manufacturers.length === 0}
            />
          </Col>
        </If>
        <Col xs={displayVendorUser ? 6 : 12} className={displayVendorUser ? joinArgs(utilClasses.pl2, utilClasses.dividerBorder, utilClasses.pb7) : ''}>
          <Autocomplete
            formikProps={formikProps}
            id="user-association-hospital"
            data-testid="user-association-hospital"
            descriptor={addAssociationFormFields.hospital}
            options={sortedHospitals}
            onKeyPress={handleKeyPress}
            disabled={!!formikProps.values.departments?.length}
          />
          <Autocomplete
            formikProps={formikProps}
            id="user-association-user-role"
            data-testid="user-association-user-role"
            descriptor={addAssociationFormFields.userRole}
            options={displayVendorUser ? hospitalUserRolesforVendorUsers : hospitalUserRolesforHospitalUsers}
            onKeyPress={handleKeyPress}
            disabled={!(currentUser?.roleName === userRoles.omiAdmin)}
          />
          <Autocomplete
            formikProps={formikProps}
            multiple
            id="user-association-department"
            data-testid="user-association-department"
            descriptor={addAssociationFormFields.departments}
            disabled={!selectedHospital}
            options={sortedActiveDepartments}
            onKeyPress={handleKeyPress}
          />
        </Col>
      </Row>

      <Row className={joinArgs(utilClasses.mt2)}>
        <Col xs={12} className={utilClasses.textRight}>
          <Button
            data-testid="add-associations"
            buttonStyle="reverse"
            leadingIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={formikProps.submitForm}
          >
            {dictionary.FORM_CONTROL_LABEL_ADD_ASSOCIATIONS}
          </Button>
        </Col>
      </Row>
    </>
  );
};
