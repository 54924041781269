import { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-flexbox-grid';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { Button } from '../../../Shared/buttons/Button';
import { dictionary } from '../../../dictionary';
import { logout } from '../../../Services/LoginService';
import { userAcceptTermsOfUse } from '../../../Services/UserService';
import { getCurrentUserAction } from '../../../redux/user/userActionCreators';
import { actionTypes } from '../../../redux/actionTypes';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { If } from '../../../Shared/If';
import LoadState from '../../../redux/loadState';
import { ipmMetaInfoService } from '../../../Services/IpmMetaInfoService';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { TermsOfUseResponse } from '../../../Data/TermsOfUse';
import { joinArgs } from '../../../Utils/arrayUtils';
import useUtilityStyles from '../../../Themes/utility.styles';

const termsOfUseStyles = createUseStyles({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '2.4rem',
  },
});

interface TermsOfUseProps {
  showButtons?: boolean
}

export const TermsOfUse: FunctionComponent<TermsOfUseProps> = (props): ReactElement => {
  const { showButtons = true } = props;
  const classes = termsOfUseStyles();
  const dispatch = useDispatch();
  const [termsOfUse, setTermsOfUse] = useState<TermsOfUseResponse>();
  const [termsOfUseGetLoadState, setTermsOfUseGetLoadState] = useState<LoadState>(LoadState.loading);
  const [isAttemptingAccept, setIsAttemptingAccept] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasTriedAccepting, setHasTriedAccepting] = useState(false);

  const utilClasses = useUtilityStyles();

  useEffect(() => {
    (async () => {
      try {
        const termsOfUseResponse = await ipmMetaInfoService.getTermsOfUse();
        setTermsOfUse(termsOfUseResponse);
        setTermsOfUseGetLoadState(LoadState.loaded);
      } catch (e) {
        setHasError(true);
      }
    })();
  }, []);

  const termsOfUseHtml = useMemo(() => {
    if (!termsOfUse) return '';

    return termsOfUse.html;
  }, [termsOfUse]);

  const handleLogout = () => {
    logout();
    dispatch({ type: actionTypes.user.clearUser });
  };

  const handleAcknowledgement = async () => {
    setIsAttemptingAccept(true);
    setHasTriedAccepting(true);

    try {
      await userAcceptTermsOfUse(termsOfUse!.lastModifiedTimestamp);
      dispatch(getCurrentUserAction());
    } catch (e) {
      setIsAttemptingAccept(false);
      if (hasTriedAccepting) {
        setHasError(true);
      }
    }
  };

  if (hasError || termsOfUseGetLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  if (termsOfUseGetLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageHeader title={dictionary.TERMS_OF_USE_HEADER} />

      <Row>
        <Col xs={6}>
          <section className={joinArgs(utilClasses.p24, utilClasses.backgroundNearWhite)}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: termsOfUseHtml }} />

            <If condition={showButtons}>
              <div className={classes.actions}>
                <Button large onClick={handleLogout} buttonStyle="destructive">
                  {dictionary.EXIT}
                </Button>

                <Button large onClick={handleAcknowledgement} disabled={isAttemptingAccept}>
                  {isAttemptingAccept ? dictionary.FORM_SUBMIT_PROCESSING_BUTTON : dictionary.ACKNOWLEDGE_AND_PROCEED}
                </Button>
              </div>
            </If>
          </section>
        </Col>
      </Row>
    </>
  );
};
