import { KeyboardEventHandler } from 'react';

export const useSubFormKeyPress = (submitHandler: () => void): KeyboardEventHandler =>
  (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      submitHandler();
    }
  };
