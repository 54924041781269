import { FC } from 'react';
import User, { UserHospitalAssociation } from '../../../../Data/User';
import { dictionary } from '../../../../dictionary';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

interface VendorsTableProps {
  users: User[];
  isLoading: boolean;
  onRowClick?: (user: User) => void;
}

interface TableUser extends User {
  hospitalManufacturersString: string;
}

const getAlphabetizedUniqueManufacturers = (hospitalAssociations: UserHospitalAssociation[]): string =>
  hospitalAssociations
    .flatMap((hA) => hA.manufacturers)
    .map(m => m.name)
    .filter((val, idx, self) => self.indexOf(val) === idx) // unique string filter
    .sort()
    .join(', ');

const defaultColumnDefinitions: ColumnProperty<TableUser>[] = [
  { name: 'firstName', displayName: dictionary.USER_FIRST_NAME_LABEL },
  { name: 'lastName', displayName: dictionary.USER_LAST_NAME_LABEL },
  { name: 'email', displayName: dictionary.USER_EMAIL_LABEL },
  { name: 'phoneNumber', displayName: dictionary.USER_PHONE_LABEL },
  {
    name: 'hospitalManufacturersString',
    displayName: 'Manufacturer',
  },
];

export const VendorsTable: FC<VendorsTableProps> = ({ users, isLoading, onRowClick }) => {
  const tableUsers: TableUser[] = users.map(user => ({
    ...user,
    hospitalManufacturersString: getAlphabetizedUniqueManufacturers(user.hospitalAssociations),
  }));

  return (
    <section>
      <SortableTable
        rowData={tableUsers}
        columns={defaultColumnDefinitions}
        initialColumnSort="firstName"
        noDataMessage={dictionary.VENDOR_USERS_TABLE_EMPTY}
        isLoading={isLoading}
        handleRowSelect={onRowClick}
        tableTestId="vendor-users-table"
      />
    </section>
  );
};
