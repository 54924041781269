import { FC } from 'react';
import { HeaderGroup } from 'react-table';
import { If } from '../If';

interface HeaderRowsProps {
  headerGroups: HeaderGroup<any>[];
  className?: string;
  extraColSpan?: number;
}

export const TableHeaderRow: FC<HeaderRowsProps> = ({ headerGroups, className, extraColSpan = 0 }) => (
  <>
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps({ className })}>
        {headerGroup.headers.map(column => (
          <th {...column.getHeaderProps({ className: column.className, width: column.width, colSpan: column.colSpan })}>
            {column.render('Header')}
          </th>
        ))}
        <If condition={extraColSpan > 0}>
          <td colSpan={extraColSpan}>&nbsp;</td>
        </If>
      </tr>
    ))}
  </>
);
