import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { Col, Row } from 'react-flexbox-grid';
import { useUtilityStyles } from '../../Themes/utility.styles';
import { TabInfo, Tabs } from '../Tabs/Tabs';
import { If } from '../If';
import { joinArgs } from '../../Utils/arrayUtils';

export const usePageHeaderStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: '1rem',
    marginBottom: '1rem',
  },
  aside: {
    marginLeft: '4rem',
    flexShrink: 0,
  },
});

export interface PageHeaderProps {
  title?: string;
  children?: ReactNode;
  tabs?: TabInfo[];
  withoutBottomMargin?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = (props) => {
  const { title, children, tabs = [], withoutBottomMargin = !!tabs.length } = props;
  const classes = usePageHeaderStyles();
  const utilClasses = useUtilityStyles();

  return (
    <If condition={!!title}>
      <header className={utilClasses.mt1}>
        <Row>
          <Col xs={6} className={utilClasses.alignCenter}>
            <h1 data-testid="page-header" className={utilClasses.m0}>{title}</h1>
          </Col>
          <Col xs={6} className={joinArgs(utilClasses.flex, utilClasses.flexEnd, utilClasses.alignCenter)}>
            <div className={classes.aside}>
              {children}
            </div>
          </Col>
        </Row>

        <hr className={joinArgs(utilClasses.mt1, withoutBottomMargin ? utilClasses.mb0 : utilClasses.mb1)} />

        <If condition={!!tabs.length}>
          <Row>
            <Col xs={12} className={joinArgs(utilClasses.flex, utilClasses.flexEnd, utilClasses.pr025)}>
              <Tabs tabs={tabs} />
            </Col>
          </Row>
        </If>
      </header>
    </If>
  );
};
