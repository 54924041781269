import { FC, useMemo } from 'react';
import { TableState, UseSortByState } from 'react-table';
import { TableFooter } from '../../../Shared/ReactTable/TableFooter';
import { joinArgs } from '../../../Utils/arrayUtils';
import { SortableTableProps } from '../../../Shared/SortableTable/SortableTable';
import { useReqExceptionTable } from './interfaceExceptionTable.utils';
import { useTableStyles } from '../../../Themes/table.styles';
import { SortableTableHeader } from '../../../Shared/ReactTable/SortableTableHeader';
import { InterfaceExceptionsReqProductRow } from './InterfaceExceptionsReqProductRow';
import { If } from '../../../Shared/If';
import { EmptyState } from '../../../Shared/EmptyState/EmptyState';
import useUtilityStyles from '../../../Themes/utility.styles';
import { ReqForInterfacesTable } from '../InterfaceIndex/interfacesIndex.utils';

interface InterfacesBaseTableProps {
  requisitions: ReqForInterfacesTable[];
  noDataMessage: string;
  isLoading: boolean;
  initialSortOrder?: SortableTableProps<ReqForInterfacesTable>['defaultSortDirection'];
  initialSortColumn?: string;
  toolTip?: string;
  toolTipDef?: string;
}

export const InterfaceExceptionsTable: FC<InterfacesBaseTableProps> = (props) => {
  const { requisitions: data, isLoading, noDataMessage, initialSortColumn = 'approvedDate', initialSortOrder = 'desc' } = props;
  const utilClasses = useUtilityStyles();
  const tblClasses = useTableStyles({ hasTableContainer: true });

  const initialSortCol = initialSortColumn;

  const initialState: Partial<TableState<ReqForInterfacesTable> & UseSortByState<ReqForInterfacesTable>> = useMemo(() =>
    ({ sortBy: [{ id: initialSortCol, [initialSortOrder]: true }] }), [initialSortOrder, initialSortCol]);

  const tableContext = useReqExceptionTable(data, initialState);
  const reactTableProps = tableContext.getTableProps();

  return (
    <section className={tblClasses.container}>
      <div className={tblClasses.scrollableContainer}>
        <table {...reactTableProps} className={joinArgs(reactTableProps.className, tblClasses.root)}>
          <SortableTableHeader tableContext={tableContext} />
          <If condition={isLoading || !data.length}>
            <tbody>
              <tr>
                <td colSpan={tableContext.visibleColumns.length} className={utilClasses.textCenter}>
                  <EmptyState>
                    {noDataMessage}
                  </EmptyState>
                </td>
              </tr>
            </tbody>
          </If>
          <If condition={!isLoading && !!data.length}>
            <tbody {...reactTableProps}>
              {tableContext.rows.map(row => {
                tableContext.prepareRow(row);
                return (
                  <InterfaceExceptionsReqProductRow
                    key={row.id}
                    tableContext={tableContext}
                    row={row}
                  />
                );
              })}
            </tbody>
          </If>
        </table>
      </div>
      <TableFooter tableContext={tableContext} />
    </section>
  );
};
