import apiClient from './apiClient';
import { ApprovalRouting, ApprovalRoutingForTable, ApprovalRoutingRequest } from '../Data/ApprovalRouting';

export const approvalRoutingsUrl = '/approvalRoutings';
export const getByIdUrl = (id: number): string => `${approvalRoutingsUrl}/${id}`;

export const fetchApprovalRoutings = async (): Promise<ApprovalRoutingForTable[]> =>
  apiClient.get<ApprovalRoutingForTable[]>({ url: approvalRoutingsUrl });

export const createApprovalRouting = async (req: Partial<ApprovalRoutingRequest>): Promise<any> => {
  const requestConfig = {
    url: approvalRoutingsUrl,
    data: JSON.stringify(req),
  };
  return apiClient.post(requestConfig);
};

export const editApprovalRouting = async (id: number, req: Partial<ApprovalRoutingRequest>): Promise<any> => {
  const requestConfig = {
    url: getByIdUrl(id),
    data: JSON.stringify(req),
  };
  return apiClient.put(requestConfig);
};

export const fetchApprovalRoutingById = async (id: number): Promise<ApprovalRouting> =>
  apiClient.get({ url: getByIdUrl(id) });
