import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { NotificationType, ReduxState } from '../../../redux/initialState';
import { actionTypes } from '../../../redux/actionTypes';
import { routes } from '../../../Utils/routes';
import { dictionary } from '../../../dictionary';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { MergedRequisition } from '../../../Data/Requisition';
import { convertToCreateRequest, reqIsReadyForSubmit } from '../../../Utils/ConvertRequisition';
import { CasePatientInfo } from './CasePatientInfo/CasePatientInfo';
import { ProcedureInfo } from './ProcedureInfo/ProcedureInfo';
import { ReviewSubmitReq } from './ReviewSubmitReq/ReviewSubmitReq';
import { ProductInfo } from '../shared/ProductInfo/ProductInfo';
import { requisitionApiService } from '../../../Services/RequisitionService';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { If } from '../../../Shared/If';
import { Button } from '../../../Shared/buttons/Button';
import { createArchiveModalComponent } from '../../../Shared/modals/ArchiveModal';

export const CreateRequisitionIndex: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const editRequisition = useSelector((state: ReduxState) => state.requisition.editRequisition);

  const onComplete = async (formRequisition: Partial<MergedRequisition>) => {
    const requisitionRequest = convertToCreateRequest(formRequisition);
    if (reqIsReadyForSubmit(requisitionRequest)) {
      try {
        await requisitionApiService.create(requisitionRequest);
        history.push(routes.requisitions.index);
        dispatch({ type: actionTypes.requisition.clearNew });
        dispatch(addNotification(NotificationType.success, dictionary.REQ_SUBMISSION_SUCCESS));
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.REQ_SUBMISSION_ERROR));
      }
    } else {
      dispatch({ type: actionTypes.requisition.updateNew, requisition: { ...formRequisition, validate: true } });
      throw new Error(dictionary.REQ_SUBMISSION_VALIDATION_ERROR);
    }
  };

  const onCancel = () => {
    dispatch({ type: actionTypes.requisition.clearNew });
    history.push(routes.requisitions.index);
  };

  const onSaveDraft = async (formRequisition: Partial<MergedRequisition>) => {
    if (formRequisition.hospital && formRequisition.department) {
      const requisitionRequest = convertToCreateRequest(formRequisition, true);
      const successMessage = editRequisition.statusId === 11 ? dictionary.REQ_UPDATE_DRAFT_SUCCESS : dictionary.REQ_SAVE_DRAFT_SUCCESS;
      try {
        await requisitionApiService.create(requisitionRequest);
        history.push(routes.requisitions.index);
        dispatch({ type: actionTypes.requisition.clearNew });
        dispatch(addNotification(NotificationType.success, successMessage));
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.REQ_SAVE_DRAFT_ERROR));
      }
    } else {
      throw new Error(dictionary.REQ_SAVE_DRAFT_VALIDATION_ERROR);
    }
  };

  const discardDraft = async () => {
    if (editRequisition.id) {
      try {
        await requisitionApiService.delete(editRequisition!.id);
        history.push(routes.requisitions.index);
        dispatch({ type: actionTypes.requisition.clearNew });
        dispatch(addNotification(NotificationType.success, dictionary.REQ_DRAFT_DISCARD_SUCCESS));
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.REQ_DRAFT_DISCARD_ERROR));
      }
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const DeleteDraftModal = createArchiveModalComponent(dictionary.REQ_DRAFT_DISCARD_BUTTON, dictionary.REQ_DRAFT_DISCARD_WARNING);

  const pageHeader = editRequisition.id ? dictionary.REQ_EDIT_DRAFT_HEADER(editRequisition.id) : dictionary.REQ_CREATE_HEADER;

  const PageHeaderComponent = () => (
    <PageHeader title={pageHeader}>
      <If condition={!!editRequisition.id}>
        <Button intent="danger" onClick={openModal} leadingIcon={<FontAwesomeIcon icon={faTrash} />}>
          {dictionary.REQ_DRAFT_DISCARD_BUTTON}
        </Button>
        <DeleteDraftModal isOpen={isModalOpen} onConfirm={() => { discardDraft(); closeModal(); }} onDismiss={closeModal} />
      </If>
    </PageHeader>
  );

  return (
    <FormFlow<MergedRequisition>
      steps={[
        { pageHeader, PageHeaderComponent, stepTitle: dictionary.REQ_PROCEDURE_INFO_STEPPER, component: ProcedureInfo },
        { pageHeader, PageHeaderComponent, stepTitle: dictionary.REQ_CASE_INFO_STEPPER, component: CasePatientInfo },
        { pageHeader, PageHeaderComponent, stepTitle: dictionary.REQ_PRODUCT_INFO_STEPPER, component: ProductInfo },
        { pageHeader, PageHeaderComponent, stepTitle: dictionary.REQ_REVIEW_STEPPER, component: ReviewSubmitReq },
      ]}
      completeButtonLabel={dictionary.REQ_SUBMIT_BUTTON_LABEL}
      onCancel={onCancel}
      onSaveDraft={onSaveDraft}
      onComplete={onComplete}
    />
  );
};
