import { combineReducers } from 'redux';
import { requisitionReducer } from './requisition/requisitionReducer';
import contract from './contract/contractReducer';
import user from './user/userReducer';
import notifications from './notifications/notificationsReducer';

const rootReducer = combineReducers({
  requisition: requisitionReducer,
  contract,
  user,
  notifications,
});

export default rootReducer;
