import { FC } from 'react';
import { ReqTable } from '../shared/RequisitionsTable/ReqTable';
import { dictionary } from '../../../../dictionary';
import useUtilityStyles from '../../../../Themes/utility.styles';
import {
  pendingApprovalColumnProperties,
  approvedColumnProperties,
  declinedColumnProperties,
  ReqForIndexTable,
  ReqTableForIndex,
} from '../shared/RequisitionsTable/reqTable.utils';
import { RequisitionStatus } from '../../../../Data/RequisitionStatus';
import { If } from '../../../../Shared/If';

interface ApproverRequisitionsProps {
  isLoading: boolean;
  requisitions: ReqForIndexTable[];
  activeTab: number;
}

export const ApproverRequisitions: FC<ApproverRequisitionsProps> = (props) => {
  const { requisitions, isLoading, activeTab } = props;

  const utilClasses = useUtilityStyles();

  const notApprovedOrDeclined = (req: ReqForIndexTable) =>
    req.statusCode !== RequisitionStatus.approved
    && req.statusCode !== RequisitionStatus.completed
    && req.statusCode !== RequisitionStatus.archived
    && req.statusCode !== RequisitionStatus.declined;

  const awaitingActionRequisitions = requisitions.filter(req => req.canCurrentUserApprove && notApprovedOrDeclined(req));
  const approvedRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.approved || req.statusCode === RequisitionStatus.completed);
  const declinedRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.declined);
  const processingRequisitions = requisitions.filter(notApprovedOrDeclined);

  const requisitionTables: ReqTableForIndex[] = [
    {
      testId: 'awaiting-action-requisitions-box',
      title: dictionary.REQS_AWAITING_YOUR_ACTION_HEADER,
      reqs: awaitingActionRequisitions,
      colProps: pendingApprovalColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_AWAITING_ACTION_EMPTY,
    },
    {
      testId: 'processing-requisitions-box',
      title: dictionary.REQS_PROCESS_PROCESSING_HEADER,
      reqs: processingRequisitions,
      colProps: pendingApprovalColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_PROCESSING_EMPTY,
    },
    {
      testId: 'approved-requisitions-box',
      title: dictionary.REQS_APPROVED_HEADER,
      reqs: approvedRequisitions,
      colProps: approvedColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_APPROVED_EMPTY,
    },
    {
      testId: 'declined-requisitions-box',
      title: dictionary.REQS_DECLINED_HEADER,
      reqs: declinedRequisitions,
      colProps: declinedColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_DECLINED_EMPTY,
    },
  ];

  return (
    <>
      { requisitionTables.map((table, index) => (
        <If condition={activeTab === 0 || activeTab === index + 1} key={table.testId}>
          <div className={utilClasses.mb2} data-testid={table.testId}>
            <h2 className={utilClasses.mt0}>{table.title}</h2>
            <ReqTable
              requisitions={table.reqs}
              columns={table.colProps}
              noDataMessage={table.noDataMsg}
              isLoading={isLoading}
            />
          </div>
        </If>
      )) }
    </>
  );
};
