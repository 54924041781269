import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { MergedRequisition } from '../../../Data/Requisition';
import { dictionary } from '../../../dictionary';
import { actionTypes } from '../../../redux/actionTypes';
import { ReduxState, NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { fetchRequisitionById, updateRequisitionProducts } from '../../../Services/RequisitionService';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { routes } from '../../../Utils/routes';
import { getEditRequisitionHeaderComponent } from '../shared/EditRequisitionHeader';
import { ProductInfoFormWrapper } from './ProductInfoFormWrapper';

export const EditRequisitionProducts: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const updateReqState = useCallback(
    (req: MergedRequisition) => dispatch({ type: actionTypes.requisition.updateNew, requisition: req }),
    [dispatch]
  );
  const req = useSelector((state: ReduxState) => state.requisition.editRequisition);

  useEffect(() => {
    (async () => {
      const fetchedReq = await fetchRequisitionById(parseInt(id, 10));
      updateReqState(fetchedReq);
    })();

    return () => {
      dispatch({ type: actionTypes.requisition.clearNew });
    };
  }, [dispatch, id, updateReqState]);

  const goBackToReqDetails = () => history.push(routes.requisitions.detail(req.id!));

  const onComplete = async () => {
    const requisitionProducts = { products: req.products!, capitations: req.capitations! };

    try {
      await updateRequisitionProducts(req.id!, requisitionProducts);
      dispatch(addNotification(NotificationType.success, dictionary.REQ_EDIT_PRODUCTS_SUCCESS));
      goBackToReqDetails();
    } catch (e) {
      dispatch(addNotification(NotificationType.error, dictionary.REQ_EDIT_PRODUCTS_ERROR));
    }
  };

  if (!req || !req.manufacturer) {
    return <h1>{dictionary.REQ_DETAILS_LOADING_REQ}</h1>;
  }

  const HeaderComponent = getEditRequisitionHeaderComponent(req.id);

  return (
    <FormFlow<MergedRequisition>
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      onCancel={goBackToReqDetails}
      onComplete={onComplete}
      steps={[{ pageHeader: dictionary.REQ_EDIT_PRODUCTS_HEADER, PageHeaderComponent: HeaderComponent, component: ProductInfoFormWrapper }]}
    />
  );
};
