import { HospitalProductSearchResult } from '../../../../Data/HospitalProduct';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { renderPriceCell } from '../../../../Shared/tables/sortableTableRenderers';

export const defaultColumnProperties: ColumnProperty<HospitalProductSearchResult>[] = [
  { displayName: dictionary.HEALTH_SYSTEM_NAME_LABEL, name: 'healthSystemName' },
  { displayName: dictionary.PRODUCT_CONTRACT_ID, name: 'contractIdentifier' },
  { displayName: dictionary.PRODUCT_MANUFACTURER, name: 'manufacturerName' },
  { displayName: dictionary.PRODUCT_CATALOG_NUMBER, name: 'catalogNumber' },
  { displayName: dictionary.PRODUCT_ITEM_IDENTIFIER, name: 'orderIdentifier' },
  { displayName: dictionary.PRODUCT_DESCRIPTION, name: 'description' },
  { displayName: dictionary.PRODUCT_CONTRACT_PRICE, name: 'contractPrice', align: 'right', colRenderCell: renderPriceCell },
];
