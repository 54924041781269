import { Name } from '../Data/Name';
import { joinArgs } from './arrayUtils';

export const getFullName = (name?: Name | null, lastNameFirst = false): string => {
  if (!name) {
    return '';
  }

  return lastNameFirst
    ? joinArgs(`${joinArgs(name.lastName, name.suffix)},`, name.firstName, name.middleInitial)
    : joinArgs(name.firstName, name.middleInitial, name.lastName, name.suffix);
};
