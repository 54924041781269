import { FC } from 'react';
import { Route } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { HealthSystemsIndex } from './HealthSystemsIndex/HealthSystemsIndex';
import { CreateHealthSystem } from './CreateHealthSystem/CreateHealthSystem';
import { EditHealthSystem } from './EditHealthSystem/EditHealthSystem';

export const HealthSystemsRoutes: FC = () => (
  <>
    <Route exact path={routes.healthSystems.index}>
      <HealthSystemsIndex />
    </Route>
    <Route exact path={routes.healthSystems.create}>
      <CreateHealthSystem />
    </Route>
    <Route exact path={routes.healthSystems.editTemplate}>
      <EditHealthSystem />
    </Route>
  </>
);
