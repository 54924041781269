import { FC, useState } from 'react';
import { Row, UseExpandedRowProps } from 'react-table';
import RequisitionProduct from '../../../Data/RequisitionProduct';
import { requisitionApiService } from '../../../Services/RequisitionService';
import { TableBodyRow } from '../../../Shared/ReactTable/TableBodyRow';
import { ReqForInterfacesTable } from '../InterfaceIndex/interfacesIndex.utils';
import { ReqProductsTableInstance, useReqExceptionSubtable } from './interfaceExceptionTable.utils';

interface InterfaceExceptionsReqProductRowProps {
  row: Row<ReqForInterfacesTable>;
  tableContext: ReqProductsTableInstance;
}

export const InterfaceExceptionsReqProductRow: FC<InterfaceExceptionsReqProductRowProps> = (props) => {
  const {
    row: uncastRow,
    tableContext,
  } = props;

  const [exceptionProducts, setExceptionProducts] = useState<RequisitionProduct[]>([]);

  const row = uncastRow as Row<ReqForInterfacesTable> & UseExpandedRowProps<any>;
  const subtableContext = useReqExceptionSubtable(exceptionProducts);
  const expandTableExtraColSpan = tableContext.allColumns.length - subtableContext.allColumns.length;

  const expandOnClick = (async () => {
    const reqProducts: RequisitionProduct[] = await requisitionApiService.getExceptionProducts(row.original.id);
    setExceptionProducts(reqProducts);
  });

  return (
    <TableBodyRow
      row={row}
      expandTableContext={subtableContext}
      expandTableExtraColSpan={expandTableExtraColSpan}
      onClick={expandOnClick}
      data-testid="exception-products-product-row"
    />
  );
};
