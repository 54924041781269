import { FC } from 'react';
import { TableInstance, UsePaginationInstanceProps } from 'react-table';
import { TablePagingControls } from './TablePagingControls';

interface TableFooterProps {
  tableContext: TableInstance<any> & UsePaginationInstanceProps<any>;
}

export const TableFooter: FC<TableFooterProps> = ({ tableContext }) => {
  if (!tableContext.page.length) {
    return null;
  }

  return (
    <TablePagingControls tableContext={tableContext} />
  );
};
