import { FC, useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useFormik } from 'formik';
import { ManufacturerBase, ManufacturerRequest } from '../../../Data/Manufacturer';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { getMfctFormInitialVals, ManufacturerFormFields, manufacturerFormFields, validateManufacturerForm, getMfctUpdateRequestFromFormFields } from './manufacturerForm.utils';

interface ManufacturerFormProps extends FormFlowStepComponent<ManufacturerRequest, ManufacturerBase> {
  manufacturer?: ManufacturerBase;
}

export const ManufacturerForm: FC<ManufacturerFormProps> = ({ manufacturer, FormActionsComponent, onFormStepComplete }) => {
  const formikInitialValues = useMemo(() => getMfctFormInitialVals(manufacturer), [manufacturer]);
  const formikProps = useFormik<ManufacturerFormFields>({
    initialValues: formikInitialValues,
    validate: validateManufacturerForm,
    onSubmit: async (values: ManufacturerFormFields) => {
      const updatedManufacturer = getMfctUpdateRequestFromFormFields(values);
      onFormStepComplete(updatedManufacturer);
    },
  });

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={4}>
          <SimpleFieldWithLabel form={formikProps} descriptor={manufacturerFormFields.name} />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
