import { faFilter, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { dictionary } from '../../../dictionary';
import { Dropdown } from '../../../Shared/Dropdown/Dropdown';
import { useDropdownStyles } from '../../../Shared/Dropdown/dropdown.styles';
import { If } from '../../../Shared/If';
import colors from '../../../Themes/colors';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { ArchivedReqForIndexTable } from './shared/ArchivedRequisitionsTable/reqTable.utils';

interface ArchivedRequisitionsIndexFiltersProps {
  filterText?: string;
  onChangeFilterText: (text: string) => void;
  filterColumn?: keyof ArchivedReqForIndexTable;
  onSelectFilterColumn: (selectedColumn: keyof ArchivedReqForIndexTable) => void;
}

export const ArchivedRequisitionsIndexFilters: FC<ArchivedRequisitionsIndexFiltersProps> = ({ filterText, onChangeFilterText, filterColumn, onSelectFilterColumn }) => {
  const utilClasses = useUtilityStyles();
  const dropdownClasses = useDropdownStyles();

  const filterColumnOptions: { displayName: string, value: keyof ArchivedReqForIndexTable }[] = [
    { displayName: 'Document Number', value: 'id' },
    { displayName: 'Hospital', value: 'hospitalName' },
    { displayName: 'Department', value: 'departmentName' },
    { displayName: 'PO #', value: 'poNumber' },
    { displayName: 'MRN', value: 'mrn' },
  ];

  return (
    <Row className="input-form">
      <Col xs={2} className={joinArgs(utilClasses.flex, utilClasses.flexEnd, utilClasses.alignCenter)}>
        <FontAwesomeIcon
          icon={faFilter}
          className={utilClasses.mr1}
          color={filterColumn && filterText ? colors.blue : colors.darkGray}
        />

        <div className={joinArgs(utilClasses.fs21, utilClasses.fwBold, filterColumn && filterText ? utilClasses.textBlue : utilClasses.textNearBlack)}>
          {dictionary.FILTER_LABEL}
        </div>
      </Col>

      <Col xs={5} className={utilClasses.flex}>
        <Dropdown
          options={filterColumnOptions}
          onChange={(selectedColumn) => { onSelectFilterColumn(selectedColumn as keyof ArchivedReqForIndexTable); }}
          placeholder={dictionary.FILTER_CATEGORY_PLACEHOLDER}
        />
      </Col>

      <Col xs={5} className={utilClasses.flex}>
        <div className={joinArgs(utilClasses.positionRelative, utilClasses.flexGrow)}>
          <input
            type="text"
            placeholder={dictionary.SEARCH_FIELD_INPUT_PLACEHOLDER_DEFAULT}
            className="text-input"
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => { onChangeFilterText(evt.target.value); }}
            value={filterText}
            data-testid="requisition-filter-text"
          />
          <div className={joinArgs(dropdownClasses.inputActions, utilClasses.pointerEventsNone)}>
            <If condition={!!filterText}>
              <button
                type="button"
                onClick={() => { onChangeFilterText(''); }}
                className={joinArgs(dropdownClasses.inputActionClear, utilClasses.pointerEventsAll)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </If>
            <If condition={!filterText}>
              <FontAwesomeIcon icon={faSearch} />
            </If>
          </div>
        </div>
      </Col>
    </Row>
  );
};
