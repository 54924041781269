import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Manufacturer from '../../../Data/Manufacturer';
import { dictionary } from '../../../dictionary';
import { Button } from '../../../Shared/buttons/Button';
import { useUtilityStyles } from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { getFormikInitialValues } from '../../../Utils/formik.utils';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';
import {
  HospMfctIdentifierAssocFormFields,
  hospMfctIdentifierAssocFormFields,
  validateCreateHospMfctIdentifierAssocForm,
} from '../shared/hospMfctIdentifier.utils';
import { HospMfctIdentifierAssocFields } from '../shared/HospMfctIdentifierAssocFields';

interface CreateHospMfctIdentifierAssocProps {
  disableManufacturer: boolean;
  manufacturers: Manufacturer[];
  addedManufacturers: number[];
  addHospMfctIdentifierAssoc: (identifierAssociation: HospMfctIdentifierAssocFormFields) => void;
  errorHighlight: boolean;
}

export const CreateHospMfctIdentifierAssoc: FC<CreateHospMfctIdentifierAssocProps> = (props) => {
  const { manufacturers, addedManufacturers, addHospMfctIdentifierAssoc, errorHighlight, disableManufacturer } = props;
  const formikProps = useFormik({
    initialValues: getFormikInitialValues<HospMfctIdentifierAssocFormFields>(hospMfctIdentifierAssocFormFields),
    validate: validateCreateHospMfctIdentifierAssocForm,
    onSubmit: (formValues, formikHelpers) => {
      addHospMfctIdentifierAssoc({
        identifier: formValues.identifier,
        manufacturer: formValues.manufacturer,
        siteName: formValues.siteName,
        siteIdentifier: formValues.siteIdentifier,
      });
      formikHelpers.resetForm();
    },
  });

  const utilClasses = useUtilityStyles();

  const rootElementClasses = joinArgs(utilClasses.backgroundNearWhite, utilClasses.px2, utilClasses.py1, errorHighlight ? utilClasses.errorBorder : '');

  const filteredManufacturers = manufacturers.filter((manufacturer) => !addedManufacturers.includes(manufacturer.id));
  const sortedManufacturers = filteredManufacturers.sort(alphanumericSortDescriptor('name', 1));

  return (
    <Col xs={6} data-testid="hospital-associations" className={rootElementClasses}>
      <h2 className={utilClasses.m0}>{dictionary.CREATE_HOSPITAL_MANUFACTURER_ID_SUBFORM_HEADER}</h2>
      <Row>
        <Col xs={12}>
          <HospMfctIdentifierAssocFields disableManufacturer={disableManufacturer} manufacturers={sortedManufacturers} formikProps={formikProps} />
        </Col>
      </Row>

      <Row className={utilClasses.mt2}>
        <Col xs={12} className={utilClasses.textRight}>
          <Button
            data-testid="add-hospital-manufacturer-association"
            buttonStyle="reverse"
            leadingIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={formikProps.submitForm}
          >
            {dictionary.CREATE_HOSPITAL_MANUFACTURER_ID_ADD_ASSOCIATED_MANUFACTURER_BUTTON}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
