import { FC } from 'react';
import { useSelector } from 'react-redux';
import { dictionary } from '../../../../dictionary';
import { routes } from '../../../../Utils/routes';
import { EditVendorUserRequest } from '../../../../Data/User';
import { editVendorUser } from '../../../../Services/UserService';
import { CreateOrEditUser } from '../../shared/CreateOrEditUser/CreateOrEditUser';
import { PermissionTo } from '../../../../Utils/permissionChecker';
import { currentUserSelector } from '../../../../redux/user/userSelectors';

export const EditVendorUser: FC = () => {
  const currentUser = useSelector(currentUserSelector);
  const allowEdits = PermissionTo.createAHospitalUser.isGrantedTo(currentUser);
  const pageHeader = allowEdits ? dictionary.EDIT_VENDOR_USER_ACCOUNT : dictionary.VIEW_VENDOR_USER_ACCOUNT;

  return (
    <CreateOrEditUser<EditVendorUserRequest>
      pageHeader={pageHeader}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      onDoneRoutePath={routes.users.vendors.index}
      submitRequest={editVendorUser}
    />
  );
};
