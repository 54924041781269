import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HospitalAdminRoute } from '../../Shared/ProtectedRoute/ProtectedRoute';
import { routes } from '../../Utils/routes';
import { CapitationCategoriesIndex } from './CapitationCategoriesIndex/CapitationCategoriesIndex';
import { CreateCapitationCategory } from './CreateCapitationCategory/CreateCapitationCategory';
import { EditCapitationCategory } from './EditCapitationCategory/EditCapitationCategory';

export const CapitationCategoriesRoutes: FC = () => (
  <Switch>
    <Route exact path={routes.capitationCategories.index}>
      <CapitationCategoriesIndex />
    </Route>
    <HospitalAdminRoute exact path={routes.capitationCategories.create}>
      <CreateCapitationCategory />
    </HospitalAdminRoute>
    <Route exact path={routes.capitationCategories.editTemplate}>
      <EditCapitationCategory />
    </Route>
    {/* To Do: See about redirecting /capitation-categories/[junk] to /capitation-categories */}
  </Switch>
);
