import { useLayoutEffect, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { MergedRequisition } from '../../../Data/Requisition';
import useUtilityStyles from '../../../Themes/utility.styles';
import { casePatientInfoStyles } from './casePatientInfo.styles';
import { SearchField } from '../../../Shared/fields/SearchField';
import { Button } from '../../../Shared/buttons/Button';
import { CreatePatientForm } from './CreatePatientForm/CreatePatientForm';
import { If } from '../../../Shared/If';
import { ReqPatientDisplayFields } from './ReqPatientDisplayFields';
import Patient, { PatientSearchRequest } from '../../../Data/Patient';
import { searchForPatient } from '../../../Services/PatientService';
import { joinArgs } from '../../../Utils/arrayUtils';
import { casePatientInfoDefaultValues } from '../CreateRequisition/CasePatientInfo/casePatientInfo.utils';
import { dictionary } from '../../../dictionary';
import { nonAlphanumericRegexGlobal } from '../../../Utils/validationUtils';

export interface ReqPatientFormFields {
  patientsMRN: string;
  patient: Patient | null;
}
interface Props<T extends ReqPatientFormFields> {
  requisition: MergedRequisition;
  formikProps: FormikProps<T>;
}

export const ReqPatientForm = <T extends ReqPatientFormFields>({ requisition, formikProps }: Props<T>): JSX.Element => {
  const [patientSearchInProgress, setPatientSearchInProgress] = useState(false);
  const [patientNotFound, setPatientNotFound] = useState(false);
  const [creatingPatient, setCreatingPatient] = useState(false);
  const mrnSearchInput = useRef<HTMLInputElement>(null);

  const utilClasses = useUtilityStyles();
  const classes = casePatientInfoStyles();

  useLayoutEffect(() => {
    if (!creatingPatient && !formikProps.values.patient) {
      if (mrnSearchInput.current) {
        mrnSearchInput.current.focus();
      }
    }
  }, [formikProps.values.patient, creatingPatient]);

  const handlePatientSearch = async (patientsMRN: string) => {
    setPatientSearchInProgress(true);
    const searchRequest: PatientSearchRequest = {
      mrn: patientsMRN.replace(nonAlphanumericRegexGlobal, ''),
      hospitalId: requisition.hospital!.id,
    };

    try {
      formikProps.setFieldTouched('patientsMRN', true);

      if (!formikProps.errors.patientsMRN) {
        const fetchedPatient = await searchForPatient(searchRequest);
        formikProps.setFieldValue('patient', fetchedPatient);
        setPatientNotFound(false);
      }
    } catch (exception) {
      formikProps.setFieldValue('patient', null);
      setPatientNotFound(true);
    } finally {
      setPatientSearchInProgress(false);
      formikProps.setFieldTouched('patient', true);
    }
  };

  const highlightPatientInfoError = (!!formikProps.errors.patient || patientNotFound) && formikProps.touched.patient;

  const onRemovePatient = () => {
    formikProps.setFieldTouched('patient', false);
    formikProps.setFieldValue('patient', casePatientInfoDefaultValues.patient);
    setPatientNotFound(false);
  };

  const onOpenCreatePatientForm = () => {
    setCreatingPatient(true);
  };

  const onCancelCreatePatient = () => {
    setCreatingPatient(false);
  };

  const onPatientCreated = (patient: Patient) => {
    formikProps.setFieldValue('patient', patient);
    setCreatingPatient(false);
    setPatientNotFound(false);
  };

  return (
    <>
      <SearchField
        form={formikProps}
        field={formikProps.getFieldProps('patientsMRN')}
        name="patientsMRN"
        labelText={`${dictionary.REQ_CASE_PATIENT_SEARCH_LABEL} ${dictionary.REQUIRED_FIELD_MARK}`}
        autoComplete="off"
        onSearch={(patientsMRN: string) => handlePatientSearch(patientsMRN)}
        placeholderText={dictionary.REQ_CASE_PATIENT_SEARCH_PLACEHOLDER}
        inputRef={mrnSearchInput}
        disabled={!!formikProps.values.patient || creatingPatient || patientSearchInProgress}
        processing={patientSearchInProgress}
        onChange={
          (e: React.FormEvent<HTMLInputElement>) => {
            setPatientNotFound(false);
            formikProps.setFieldTouched('patient', false);
            formikProps.getFieldProps('patientsMRN').onChange(e);
          }
        }
      />

      <If condition={creatingPatient}>
        <CreatePatientForm
          hospitalId={requisition.hospital!.id}
          mrn={formikProps.values.patientsMRN.replace(nonAlphanumericRegexGlobal, '')}
          onPatientCreated={onPatientCreated}
          onCancel={onCancelCreatePatient}
          needsExplicitValidation={formikProps.submitCount > 0}
        />
      </If>
      <If condition={!creatingPatient}>
        <div className={joinArgs(classes.patientInfo, highlightPatientInfoError ? utilClasses.errorBorder : '')}>
          <div className={joinArgs(utilClasses.flex, utilClasses.spaceBetween, utilClasses.alignCenter)}>
            <If condition={patientNotFound}>
              <h3 className={utilClasses.textRed}>{dictionary.REQ_CASE_PATIENT_NOT_FOUND_MSG}</h3>
            </If>
            <If condition={!patientNotFound}>
              <h3 className={highlightPatientInfoError ? utilClasses.textRed : undefined}>{dictionary.REQ_CASE_PATIENT_INFO}</h3>
            </If>

            <If condition={!!formikProps.errors.patient && !!formikProps.touched.patient && !patientNotFound}>
              <p className={utilClasses.textRed}>{formikProps.errors.patient}</p>
            </If>

            <If condition={!!formikProps.values.patient}>
              <Button onClick={onRemovePatient} buttonStyle="reverse">{dictionary.REQ_CASE_PATIENT_REMOVE_PATIENT_BUTTON}</Button>
            </If>

            <If condition={patientNotFound}>
              <Button onClick={onOpenCreatePatientForm} buttonStyle="reverse">{dictionary.REQ_CASE_PATIENT_OPEN_CREATE_PATIENT_FORM_BUTTON}</Button>
            </If>
          </div>

          <ReqPatientDisplayFields patient={formikProps.values.patient} collapseName={false} />
        </div>
      </If>
    </>
  );
};
