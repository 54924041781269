import { ApiRequisition } from '../../../Data/Requisition';
import { RequisitionReturnRequest, RequisitionStatusLogRequest } from '../../../Data/RequisitionStatusLog';

export const getReqStatusLogRequest = (req: ApiRequisition, isApproval: boolean, declineReason?: string): RequisitionStatusLogRequest => ({
  requisitionId: Number(req.id),
  isApproval,
  actionReason: declineReason || null,
  currentStatus: req.status,
});

export const getReqReturnRequest = (req: ApiRequisition, returnReason: string): RequisitionReturnRequest => ({
  actionReason: returnReason,
  currentStatus: req.status,
});
