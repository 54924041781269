import { FC } from 'react';
import {
  faFileSignature, faUserTie, faUserNurse, faUserCheck, faInfoCircle, faTags,
  faHospitalAlt, faSitemap, faProcedures, faIndustry, faProjectDiagram, faUserMd, faMoneyCheckAlt, faToolbox, faHospital, faArchive,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Row } from 'react-flexbox-grid';
import { dictionary } from '../../dictionary';
import { PageHeader } from '../../Shared/PageHeader/PageHeader';
import { AdminDashboardLink } from './AdminDashboardLink';
import { routes } from '../../Utils/routes';
import { If } from '../../Shared/If';
import { currentUserRoleSelector } from '../../redux/user/userSelectors';
import { userRoles } from '../../Utils/userRoles';

export const AdminDashboard: FC = () => {
  const currentUserRole = useSelector(currentUserRoleSelector);
  const userIsOMIAdmin = currentUserRole === userRoles.omiAdmin;
  const pcListHeaderLabel = dictionary.PRODUCT_CATEGORIES_LIST_HEADER_LABEL;
  const ccListHeaderLabel = dictionary.CAPITATION_CATEGORIES_LIST_HEADER_LABEL;
  const hmiListHeaderLabel = dictionary.HOSPITAL_MANUFACTURER_ID_LIST_HEADER_LABEL;

  return (
    <>
      <PageHeader title={dictionary.ADMIN} />

      <Row>
        <AdminDashboardLink toPath={routes.contracts.index} label={dictionary.CONTRACTS} icon={faFileSignature} data-testid="contracts" />
        <AdminDashboardLink toPath={routes.users.hospitals.index} label={dictionary.HOSPITAL_USERS} icon={faUserNurse} data-testid="hospitalUsers" />
        <AdminDashboardLink toPath={routes.users.vendors.index} label={dictionary.VENDOR_USERS} icon={faUserTie} data-testid="vendorUsers" />
        <AdminDashboardLink toPath={routes.approvalRoutings.index} label={dictionary.APPROVAL_ROUTES} icon={faUserCheck} data-testid="approvals" />
        <AdminDashboardLink toPath={routes.archivedRequisitions.index} label={dictionary.REQ_ARCHIVED} icon={faArchive} data-testid="archivedrequisitions" />
        <AdminDashboardLink toPath={routes.products.index} label={dictionary.PRODUCTS} icon={faToolbox} data-testid="products" />
        <AdminDashboardLink toPath={routes.productCategories.index} label={pcListHeaderLabel} icon={faProjectDiagram} data-testid="product-categories" />

        <AdminDashboardLink toPath={routes.capitations.index} label={dictionary.CAPITATIONS} icon={faTags} data-testid="capitations" />
        <AdminDashboardLink toPath={routes.capitationCategories.index} label={ccListHeaderLabel} icon={faProjectDiagram} data-testid="capitation-categories" />

        <If condition={userIsOMIAdmin}>
          <AdminDashboardLink toPath={routes.healthSystems.index} label={dictionary.HEALTH_SYSTEMS_LIST_HEADER_LABEL} icon={faHospital} data-testid="health-systems" />
          <AdminDashboardLink toPath={routes.hospitals.index} label={dictionary.HOSPITALS_LIST_HEADER_LABEL} icon={faHospitalAlt} data-testid="hospitals" />
          <AdminDashboardLink toPath={routes.departments.index} label={dictionary.DEPARTMENTS_LIST_HEADER_LABEL} icon={faSitemap} data-testid="departments" />
        </If>

        <AdminDashboardLink toPath={routes.costCenters.index} label={dictionary.COST_CENTERS_LABEL} icon={faMoneyCheckAlt} data-testid="cost-centers" />
        <AdminDashboardLink toPath={routes.physicians.index} label={dictionary.PHYSICIANS_LIST_HEADER_LABEL} icon={faUserMd} data-testid="physicians" />
        <AdminDashboardLink toPath={routes.procedures.index} label={dictionary.PROCEDURES_LIST_HEADER_LABEL} icon={faProcedures} data-testid="procedures" />

        <If condition={userIsOMIAdmin}>
          <AdminDashboardLink toPath={routes.manufacturers.index} label={dictionary.MANUFACTURERS_LIST_HEADER_LABEL} icon={faIndustry} data-testid="manufacturers" />
        </If>

        <AdminDashboardLink toPath={routes.hospitalManufacturerIdentifiers.index} label={hmiListHeaderLabel} icon={faInfoCircle} data-testid="hospital-manufacturer-ids" />
      </Row>
    </>
  );
};
