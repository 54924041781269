import { FC } from 'react';
import { dictionary } from '../../../dictionary';
import { If } from '../../../Shared/If';
import { PageHeader, PageHeaderProps } from '../../../Shared/PageHeader/PageHeader';
import { useUtilityStyles } from '../../../Themes/utility.styles';

type EditReqHeaderCreator = (reqId: number | undefined) => FC<PageHeaderProps>;

export const getEditRequisitionHeaderComponent: EditReqHeaderCreator = (reqId) => ({ title }) => {
  const utilClasses = useUtilityStyles();

  return (
    <PageHeader title={title}>
      <If condition={!!reqId}>
        <span className={utilClasses.fs21}>
          {`${dictionary.REQ_VIEW_DOCUMENT_NUMBER}: ${reqId}`}
        </span>
      </If>
    </PageHeader>
  );
};
