import { FieldInputProps, FormikProps } from 'formik';
import { FC } from 'react';
import { dictionary } from '../../dictionary';
import { useUtilityStyles } from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import { If } from '../If';
import { FormFieldDescriptor } from './formTypes';

export interface SimpleFieldProps extends Omit<React.HTMLProps<HTMLInputElement>, 'form'> {
  field?: FieldInputProps<any>;
  name?: string;
  descriptor?: FormFieldDescriptor;
  form: FormikProps<any>;
  labelText?: string;
  inputRef?: any;
  autoFocus?: boolean;
}

/* Works for text fields and number fields */
export const SimpleFieldWithLabel: FC<SimpleFieldProps> = ({
  field,
  form: formikProps,
  labelText,
  inputRef,
  name: userProvidedName,
  descriptor,
  autoComplete: userProvidedAutoComplete,
  className,
  value,
  id,
  ...inputProps
}) => {
  const utilClasses = useUtilityStyles();

  const name = userProvidedName || descriptor?.name;
  const formikField = field || formikProps.getFieldProps(name);
  const { name: formikFieldName } = formikField;

  const fieldError = formikProps.errors[formikFieldName];
  const isErrorState = fieldError && formikProps.touched[formikFieldName];

  const requiredText = descriptor?.required ? dictionary.REQUIRED_FIELD_MARK : '';
  const label = `${(labelText || descriptor?.label)} ${requiredText}`.trim();

  const labelErrClass = isErrorState ? 'label-invalid-field' : '';
  const labelTextClass = label.length ? '' : 'no-text';
  const fieldClassName = isErrorState ? 'input-invalid-field' : '';

  const descriptorAutoCompleteVal = descriptor?.autoComplete ? 'on' : 'off';
  const autoCompleteValue = userProvidedAutoComplete || descriptorAutoCompleteVal;

  const inputFormat = descriptor?.inputFormat || 'text' || 'number';

  return (
    <div className={className}>
      <label htmlFor={formikFieldName} key={formikFieldName} className={joinArgs(labelErrClass, labelTextClass)} data-testid={`${formikFieldName}-label`}>
        <div className={joinArgs(utilClasses.flex, utilClasses.spaceBetween)}>
          {label}

          <If condition={!!isErrorState && formikFieldName !== 'password'}>
            <div role="alert" className="validation-errors" data-testid={`${formikFieldName}-input-error`}>
              {fieldError}
            </div>
          </If>
        </div>

        <If condition={inputFormat === 'text'}>
          <input
            type="text"
            {...formikField ?? ''}
            data-testid={`${formikFieldName}-input`}
            className={`text-input ${fieldClassName}`}
            id={id || formikFieldName}
            ref={inputRef}
            name={name}
            autoComplete={autoCompleteValue}
            value={value || formikField?.value || ''}
            {...inputProps}
          />
        </If>

        <If condition={inputFormat === 'number'}>
          <input
            type="number"
            {...formikField ?? ''}
            data-testid={`${formikFieldName}-input`}
            className={`text-input ${fieldClassName}`}
            id={id || formikFieldName}
            ref={inputRef}
            name={name}
            autoComplete={autoCompleteValue}
            value={value || formikField?.value || ''}
            {...inputProps}
          />
        </If>

        <If condition={inputFormat === 'textarea'}>
          <textarea
            className={`textarea ${fieldClassName}`}
            rows={4}
            {...formikField ?? ''}
            data-testid={`${formikFieldName}-input`}
            id={id || formikFieldName}
            ref={inputRef}
            name={name}
            value={value || formikField?.value || ''}
          />
        </If>

        <If condition={!!isErrorState && formikFieldName === 'password'}>
          <div role="alert" className="validation-errors" data-testid={`${formikFieldName}-input-error`}>
            {fieldError}
          </div>
        </If>
      </label>
    </div>
  );
};
