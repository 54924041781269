import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { parseProductsFile, readFile } from '../../../../../Utils/contracts/contractUtils';
import { getAddContractProductsAction,
  getAddContractProductsEditingAction,
  getSetProductUploadErrorsAction,
  getSetProductUploadErrorsEditingAction } from '../../../../../redux/contract/contractActionCreators';
import { ProductCategoryStub } from '../../../../../Data/ProductCategory';

export type FileListState = FileList | null;

export type UseProductCsvDispatcherType = (hospitalIds: number[], productCategories: ProductCategoryStub[], isEditMode: boolean) => (stateUpdate: FileListState) => void;

export const useProductCsvDispatcher: UseProductCsvDispatcherType = (hospitalIds, productCategories, isEditMode) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<FileListState>(null);
  useEffect(() => {
    const handleFileUploads = async () => {
      if (files && files.length) {
        const productFileBlob = await readFile(files[0]);
        const productPricingsParsed = parseProductsFile({ productFileBlob, hospitalIds, productCategories, isEditMode });

        if (isEditMode) {
          dispatch(getAddContractProductsEditingAction(productPricingsParsed.successful));
          dispatch(getSetProductUploadErrorsEditingAction(productPricingsParsed.failedLines));
        } else {
          dispatch(getAddContractProductsAction(productPricingsParsed.successful));
          dispatch(getSetProductUploadErrorsAction(productPricingsParsed.failedLines));
        }
      }
    };
    handleFileUploads();
  }, [dispatch, files, hospitalIds, productCategories, isEditMode]);

  return setFiles;
};
