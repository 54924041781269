import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { fetchCapitationCategories } from '../../../Services/capitationService';
import { dictionary } from '../../../dictionary';
import { CapitationCategory } from '../../../Data/CapitationCategory';
import { CapitationCategoriesTable } from './CapitationCategoriesTable/CapitationCategoriesTable';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { routes } from '../../../Utils/routes';
import { CapitationCategoryForTable } from './CapitationCategoriesTable/capitationCategoriesTable.utils';
import { If } from '../../../Shared/If';
import { currentUserIsAdminForAtLeastOneHospital } from '../../../redux/user/userSelectors';

export const CapitationCategoriesIndex: FC = () => {
  const [categories, setCategories] = useState<CapitationCategory[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const history = useHistory();
  const showCreateButton = useSelector(currentUserIsAdminForAtLeastOneHospital);

  useEffect(() => {
    (async () => {
      try {
        const fetchedCategories = await fetchCapitationCategories();
        if (fetchedCategories) {
          setCategories(fetchedCategories);
          setIsLoading(false);
        }
      } catch (e) {
        setHasLoadingError(true);
      }
    })();
  }, []);

  if (hasLoadingError) {
    return <ErrorPage />;
  }

  const handleRowClick = (capCat: CapitationCategoryForTable) => history.push(routes.capitationCategories.edit(capCat.id));

  return (
    <>
      <PageHeader title={dictionary.CAPITATION_CATEGORIES_LIST_HEADER_LABEL}>
        <If condition={showCreateButton}>
          <LinkButton to={routes.capitationCategories.create}>{dictionary.CAPITATION_CATEGORIES_CREATE_NEW_ACTION}</LinkButton>
        </If>
      </PageHeader>

      <CapitationCategoriesTable
        categories={categories ?? []}
        handleRowClick={handleRowClick}
        isLoading={isLoading}
      />
    </>
  );
};
