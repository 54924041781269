import { FC, useEffect, useMemo, useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../Shared/buttons/Button';
import { dictionary } from '../../../../dictionary';
import { Modal } from '../../../../Shared/modals/Modal/Modal';
import { getFormikInitialValues } from '../../../../Utils/formik.utils';
import { EditUserAssociationFields, getEditUserAssociationFields } from './editUserAssociationModal.utils';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../../redux/initialState';
import { hospitalService } from '../../../../Services/HospitalService';
import { HospitalUserRole, UserHospitalAssociation } from '../../../../Data/User';
import { DepartmentStub } from '../../../../Data/Department';
import { EditUserAssociationModalForm } from './EditUserAssociationModalForm';
import { getUserRoleOption } from '../user.utils';
import { manufacturerService } from '../../../../Services/ManufacturerService';
import { ManufacturerBase } from '../../../../Data/Manufacturer';

interface EditUserAssociationFormModalProps {
  association?: UserHospitalAssociation;
  onEditAssociation: (assoc: UserHospitalAssociation) => void;
  closeModal: () => void;
  isVendorUser: boolean;
}

export const EditUserAssociationModal: FC<EditUserAssociationFormModalProps> = (props) => {
  const { association, onEditAssociation, closeModal, isVendorUser } = props;
  const dispatch = useDispatch();
  const [highlightAssociationSectionError, setHighlightAssociationSectionError] = useState(false);
  const [hospitalDepartments, setHospitalDepartments] = useState<DepartmentStub[]>();
  const [manufacturers, setManufacturers] = useState<ManufacturerBase[]>();
  const hospitalId = association?.hospitalId;

  const editUserAssociationFields = useMemo(() => getEditUserAssociationFields(isVendorUser), [isVendorUser]);
  const initialValues = getFormikInitialValues<EditUserAssociationFields>(editUserAssociationFields, {
    departments: association?.departments || undefined,
    userRole: isVendorUser ?
      getUserRoleOption(association?.hospitalUserRole ?? HospitalUserRole.StandardRepresentative) :
      getUserRoleOption(association?.hospitalUserRole ?? HospitalUserRole.Staff),
    manufacturers: isVendorUser ? association?.manufacturers : undefined,
  });

  useEffect(() => {
    (async () => {
      if (hospitalId) {
        try {
          const fetchedHospital = await hospitalService.getHospitalById(hospitalId!);
          setHospitalDepartments(fetchedHospital.departments.map(dept => ({
            ...dept,
            hospitalId: fetchedHospital.id,
            hospitalName: fetchedHospital.name,
          })));
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.USER_ASSOCIATIONS_HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospitalId]);

  useEffect(() => {
    if (!isVendorUser) return;

    (async () => {
      try {
        const fetchedManufacturers = await manufacturerService.getAll();
        if (fetchedManufacturers) {
          setManufacturers(fetchedManufacturers);
        }
      } catch (err) {
        dispatch(addNotification(NotificationType.error, dictionary.FETCH_MANUFACTURERS_ERROR));
      }
    })();
  }, [isVendorUser, dispatch]);

  const onSubmit = async (vals: EditUserAssociationFields) => {
    if (isVendorUser && (!vals.departments.length || !vals.manufacturers?.length)) {
      setHighlightAssociationSectionError(true);
      dispatch(addNotification(NotificationType.error, dictionary.EDIT_USER_ASSOCIATION_NO_ASSOCIATIONS_ERROR));
    } else if (!vals.departments.length) {
      setHighlightAssociationSectionError(true);
      dispatch(addNotification(NotificationType.error, dictionary.CREATE_EDIT_USER_NO_ASSOCIATIONS_ERROR));
    } else {
      onEditAssociation({ ...association!, ...vals, hospitalUserRole: vals.userRole?.id });
      closeModal();
    }
  };

  const formikProps = useFormik<EditUserAssociationFields>({ initialValues, onSubmit, enableReinitialize: true });

  if (!association) {
    return null;
  }

  return (
    <Modal
      data-testid="edit-association-modal"
      title={dictionary.USER_ASSOCIATIONS_EDIT_MODAL_TITLE}
      isOpen={!!association}
      size="md"
      icon={faInfoCircle}
      actionsContent={(
        <>
          <Button buttonStyle="reverse" onClick={() => closeModal()} data-testid="cancel-edit-association-button">
            {dictionary.CANCEL}
          </Button>
          <Button type="submit" onClick={() => { formikProps.submitForm(); }} data-testid="update-edit-association-button">
            {dictionary.USER_ASSOCIATIONS_EDIT_MODAL_SUBMIT}
          </Button>
        </>
      )}
    >
      <EditUserAssociationModalForm
        formikProps={formikProps}
        userAssociation={association!}
        departments={hospitalDepartments}
        manufacturers={manufacturers}
        isVendorUser={isVendorUser}
        setHighlightAssociationSectionError={setHighlightAssociationSectionError}
        highlightAssociationSectionError={highlightAssociationSectionError}
      />
    </Modal>
  );
};
