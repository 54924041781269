import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

export interface ManufacturerForTable {
  id: number;
  name: string;
}

export const procedureColumnProperties: ColumnProperty<ManufacturerForTable>[] = [
  { displayName: dictionary.MANUFACTURER_NAME_LABEL, name: 'name' },
  { displayName: dictionary.MANUFACTURER_ID_LABEL, name: 'id', align: 'right' },
];
