import { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currentUserIsAdminForAtLeastOneHospital, currentUserRoleSelector } from '../../redux/user/userSelectors';
import { userRoles } from '../../Utils/userRoles';
import { If } from '../If';

interface ProtectedRouteProps extends RouteProps {
  allowedRoles: string[];
  hospitalAdminRequired?: boolean;
  path: string;
}

export const hospitalRouteAllowedRoles = [userRoles.hospital.approver, userRoles.omiAdmin];
export const vendorRouteAllowedRoles = [userRoles.vendor, userRoles.omiAdmin];
export const adminOrVendorRouteAllowedRoles = [userRoles.hospital.approver, userRoles.vendor, userRoles.omiAdmin];

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, allowedRoles, hospitalAdminRequired = false, path, ...rest }) => {
  const currentUserRole = useSelector(currentUserRoleSelector);
  const isHospitalAdmin = useSelector(currentUserIsAdminForAtLeastOneHospital);
  const isHospitalUser = currentUserRole === userRoles.hospital.approver;
  const userIsAllowed = allowedRoles.includes(currentUserRole) && (!(isHospitalUser && hospitalAdminRequired) || isHospitalAdmin);

  return (
    <>
      <If condition={userIsAllowed}>
        <Route path={path} {...rest}>
          {children}
        </Route>
      </If>

      <If condition={!userIsAllowed}>
        <Redirect to="/" />
      </If>
    </>
  );
};

interface UserRoleRouteProps extends RouteProps {
  path: string;
  exact?: boolean;
}

export const AdminOrVendorRoute: FC<UserRoleRouteProps> = ({ path, children, exact }) => (
  <ProtectedRoute
    path={path}
    allowedRoles={adminOrVendorRouteAllowedRoles}
    hospitalAdminRequired
    exact={exact}
  >
    {children}
  </ProtectedRoute>
);

export const HospitalAdminRoute: FC<UserRoleRouteProps> = ({ path, children, exact }) => (
  <ProtectedRoute
    path={path}
    allowedRoles={hospitalRouteAllowedRoles}
    hospitalAdminRequired
    exact={exact}
  >
    {children}
  </ProtectedRoute>
);

export const HospitalRoute: FC<UserRoleRouteProps> = ({ path, children, exact }) => (
  <ProtectedRoute
    path={path}
    allowedRoles={hospitalRouteAllowedRoles}
    exact={exact}
  >
    {children}
  </ProtectedRoute>
);

export const VendorRoute: FC<UserRoleRouteProps> = ({ path, children, exact }) => (
  <ProtectedRoute
    path={path}
    allowedRoles={vendorRouteAllowedRoles}
    exact={exact}
  >
    {children}
  </ProtectedRoute>
);

export const SupportUserRoute: FC<UserRoleRouteProps> = ({ path, children, exact }) => (
  <ProtectedRoute
    path={path}
    allowedRoles={[userRoles.omiAdmin]}
    exact={exact}
  >
    {children}
  </ProtectedRoute>
);
