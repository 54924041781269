import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import useUtilityStyles from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import colors from '../../Themes/colors';
import { fontWeights } from '../../Themes/fontWeights';

const useEmptyStateStyles = createUseStyles({
  emptyState: {
    fontWeight: fontWeights.normal,
    fontSize: '21px',
    color: colors.darkGray,
    textAlign: 'center',
  },
});

export const EmptyState: FC = ({ children }) => {
  const utilClasses = useUtilityStyles();
  const classes = useEmptyStateStyles();

  return (
    <div className={joinArgs(classes.emptyState, utilClasses.py1, utilClasses.displayLineBreak)}>
      {children}
    </div>
  );
};
