import { FC } from 'react';
import { SimpleFieldProps, SimpleFieldWithLabel } from './SimpleFieldWithLabel';

export const SimpleDiscountField: FC<SimpleFieldProps> = ({ labelText, descriptor, ...props }) => {
  const providedLabelText = labelText || descriptor?.label || '';
  const discountLabelText = providedLabelText ? `${providedLabelText} (%)` : '';

  return (
    <SimpleFieldWithLabel
      placeholder="0.00"
      type="number"
      min="0.00"
      max="100.00"
      step="0.01"
      labelText={discountLabelText}
      descriptor={descriptor}
      {...props}
    />
  );
};
