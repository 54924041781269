import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

export interface PhysicianForTable {
  id: string;
  physicianId: number;
  name: string,
  physicianIdentifier: string,
  departmentName: string;
  hospitalName: string;
  archived?: boolean;
}

export const physicianColumnProperties: ColumnProperty<PhysicianForTable>[] = [
  { displayName: dictionary.PHYSICIAN_NAME_LABEL, name: 'name' },
  { displayName: dictionary.PHYSICIAN_ID_LABEL, name: 'physicianIdentifier' },
  { displayName: dictionary.HOSPITAL_NAME_LABEL, name: 'hospitalName' },
  { displayName: dictionary.PHYSICIAN_DEPARTMENTS_LABEL, name: 'departmentName', width: '45%' },
];
