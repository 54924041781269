import { FC, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { ContractForDisplay, ContractStub } from '../../../Data/Contract';
import { SortableTable } from '../../../Shared/SortableTable/SortableTable';
import { getSlashFormattedDate } from '../../../Utils/dateUtils';
import { dictionary } from '../../../dictionary';
import { routes } from '../../../Utils/routes';
import { renderTruncatedCell } from '../../../Shared/tables/sortableTableRenderers';
import { ColumnProperty } from '../../../Shared/SortableTable/SortableTable.types';
import { getContractStatusText } from '../../../Utils/contracts/contractUtils';
import { StatusRenderCell } from '../../../Shared/StatusBadge/StatusCell';
import useBadgeStyles from '../../../Themes/status-badge.styles';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';

const getContractRows = (contracts: ContractStub[]): ContractForDisplay[] =>
  contracts.map(contract => ({
    id: contract.id,
    expirationDate: getSlashFormattedDate(contract.expirationDate),
    contractIdentifier: contract.contractIdentifier,
    numberOfProducts: contract.numberOfProducts,
    healthSystemName: contract.healthSystemName,
    manufacturerName: contract.manufacturer.name ?? 'Unknown Manufacturer',
    statusText: getContractStatusText(contract.status),
  }));

interface ContractsTableProps {
  contracts: ContractStub[];
  isLoading: boolean;
  reloadContract: (id: number) => void;
}

export const ContractsTable: FC<ContractsTableProps> = ({ contracts, isLoading, reloadContract }) => {
  const history = useHistory();
  const classes = useBadgeStyles();

  const renderStatusCell = (val: string): ReactElement => {
    const getContractStatusColorClass = (status: string): string => {
      switch (status) {
        case dictionary.CONTRACT_STATUS_NOT_YET_ACTIVE: { return classes.info; }
        case dictionary.CONTRACT_STATUS_EXPIRED: { return classes.danger; }
        case dictionary.CONTRACT_STATUS_EXPIRING_SOON: { return classes.warning; }
        case dictionary.CONTRACT_STATUS_PROCESSING: { return classes.processing; }
        default: { return classes.root; }
      }
    };

    if (val === 'Loading') {
      return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <LoadingSpinner />
        </div>
      );
    }
    return <StatusRenderCell cellValue={val} getCellValueColorClass={getContractStatusColorClass} />;
  };

  const columnProperties: Array<ColumnProperty<ContractForDisplay>> = [
    { displayName: 'Health System', name: 'healthSystemName', colRenderCell: renderTruncatedCell(50) },
    { displayName: 'Manufacturer', name: 'manufacturerName', colRenderCell: renderTruncatedCell(50) },
    { displayName: 'Contract ID', name: 'contractIdentifier' },
    { displayName: dictionary.CONTRACT_INDEX_PRODUCTS, name: 'numberOfProducts' },
    { displayName: 'Expiration Date', name: 'expirationDate', align: 'left' },
    { displayName: 'Status', name: 'statusText', colRenderCell: renderStatusCell },
  ];

  const contractRowSelectHandler = async (contract: ContractForDisplay) => {
    if (contract.statusText === dictionary.CONTRACT_STATUS_PROCESSING) {
      reloadContract(contract.id);
    } else {
      history.push(routes.contracts.details(contract.id));
    }
  };

  return (
    <div data-testid="all-contracts-box">
      <SortableTable
        columns={columnProperties}
        rowData={getContractRows(contracts)}
        initialColumnSort="expirationDate"
        handleRowSelect={contractRowSelectHandler}
        noDataMessage={dictionary.CONTRACTS_TABLE_EMPTY}
        isLoading={isLoading}
        tableTestId="contracts-table"
      />
    </div>
  );
};
