import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { CSVLink } from 'react-csv';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContractInfo } from './ContractInfo/ContractInfo';
import { ReviewSubmitContract } from './ReviewSubmitContract/ReviewSubmitContract';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { Contract, buildCreateContractRequest, ContractIdentifierUniquenessError } from '../../../Data/Contract';
import { ProductPricingInfo } from './ProductPricingInfo/ProductPricingInfo';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { actionTypes } from '../../../redux/actionTypes';
import { routes } from '../../../Utils/routes';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { contractService } from '../../../Services/ContractService';
import { PageHeader, PageHeaderProps } from '../../../Shared/PageHeader/PageHeader';
import { csvHeaders } from '../../../Utils/contracts/contractUtils';
import { useButtonStyles } from '../../../Themes/button.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import useUtilityStyles from '../../../Themes/utility.styles';

export const CreateContract: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const utilClasses = useUtilityStyles();
  const buttonClasses = useButtonStyles();

  const onComplete = async (contractSubmitted: Partial<Contract>) => {
    try {
      const contract = buildCreateContractRequest(contractSubmitted as Contract);
      await contractService.create(contract);

      dispatch({ type: actionTypes.contract.clearNew });
      setTimeout(() => dispatch(addNotification(NotificationType.success, dictionary.CONTRACT_CREATE_SUCCESS)), 1000);
      history.push(routes.contracts.index);
    } catch (e) {
      const exception = e as AxiosError;
      if (exception.response?.status === 409) {
        const hospitalNamesWithConflict = (exception.response?.data as ContractIdentifierUniquenessError).hospitalNames || [];
        dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_IDENTIFIER_UNIQUENESS_ERROR(hospitalNamesWithConflict)));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_CREATE_ERROR));
      }
    }
  };

  const onCancel = () => {
    dispatch({ type: actionTypes.contract.clearNew });
    history.push(routes.contracts.index);
  };

  const PageHeaderComponent: FC<PageHeaderProps> = useMemo(() => () => (
    <PageHeader title={dictionary.CONTRACT_NEW_HEADER_LABEL}>
      <CSVLink
        data={[]}
        headers={csvHeaders}
        filename="contractProductPricingTemplate.csv"
        className={joinArgs(buttonClasses.base, buttonClasses.leadingIcon, buttonClasses.reverse, utilClasses.mb1)}
      >
        <FontAwesomeIcon icon={faFileDownload} />
        {dictionary.CONTRACT_TEMPLATE_DOWNLOAD}
      </CSVLink>
    </PageHeader>
  ), [buttonClasses, utilClasses]);

  return (
    <FormFlow<Contract>
      steps={[
        {
          pageHeader: dictionary.CONTRACT_NEW_HEADER_LABEL,
          PageHeaderComponent,
          stepTitle: dictionary.CONTRACT_NEW_CONTRACT_INFO_STEPPER_LABEL,
          component: ContractInfo,
        },
        { pageHeader: dictionary.CONTRACT_NEW_HEADER_LABEL, stepTitle: dictionary.CONTRACT_NEW_PRODUCT_PRICING_STEPPER_LABEL, component: ProductPricingInfo },
        { pageHeader: dictionary.CONTRACT_NEW_HEADER_LABEL, stepTitle: dictionary.CONTRACT_NEW_REVIEW_SUBMIT_STEPPER_LABEL, component: ReviewSubmitContract },
      ]}
      completeButtonLabel={dictionary.CONTRACT_SUBMIT_BUTTON_LABEL}
      onCancel={onCancel}
      onComplete={onComplete}
    />
  );
};
