import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { SubCostCenterStub } from '../../../Data/SubCostCenter';
import { dictionary } from '../../../dictionary';
import { Button } from '../../../Shared/buttons/Button';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { useUtilityStyles } from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { getFormikInitialValues } from '../../../Utils/formik.utils';
import { SubCostCenterFormFields, subCostCenterFormFields, validateSubCostCenterForm } from './costCenterForm.utils';
import { useSubFormKeyPress } from '../../../Shared/SubFormKeyPress/useSubFormKeyPress';

interface CostCenterAddSubCostCenterProps {
  addSubCostCenter: (subCostCenters: SubCostCenterStub) => void;
  addedSubCostCenters: SubCostCenterStub[];
  archived?: boolean;
}

export const CostCenterAddSubCostCenter: FC<CostCenterAddSubCostCenterProps> = ({ addSubCostCenter, addedSubCostCenters, archived }) => {
  const utilClasses = useUtilityStyles();
  const rootElementClasses = joinArgs(utilClasses.backgroundNearWhite, utilClasses.px2, utilClasses.py1);

  const formikProps = useFormik({
    initialValues: getFormikInitialValues<SubCostCenterFormFields>(subCostCenterFormFields),
    validate: validateSubCostCenterForm(addedSubCostCenters),
    onSubmit: (formValues, formikHelpers) => {
      addSubCostCenter({
        name: formValues.name,
        hospitalSubCostCenterIdentifier: formValues.hospitalSubCostCenterIdentifier,
        archived: false,
      });
      formikHelpers.resetForm();
    },
  });

  const handleKeyPress = useSubFormKeyPress(formikProps.handleSubmit);

  return (
    <Col xs={6} data-testid="hospital-associations" className={rootElementClasses}>
      <h2 className={utilClasses.m0}>{dictionary.SUB_COST_CENTER_SUBFORM_HEADER}</h2>
      <Row>
        <Col xs={12}>
          <SimpleFieldWithLabel
            form={formikProps}
            descriptor={subCostCenterFormFields.name}
            id={`sub-cost-center-${subCostCenterFormFields.name.name}`}
            disabled={archived}
            onKeyPress={handleKeyPress}
          />
          <SimpleFieldWithLabel form={formikProps} descriptor={subCostCenterFormFields.hospitalSubCostCenterIdentifier} disabled={archived} onKeyPress={handleKeyPress} />
        </Col>
      </Row>
      <Row className={utilClasses.mt2}>
        <Col xs={12} className={utilClasses.textRight}>
          <Button
            data-testid="add-sub-cost-center"
            buttonStyle="reverse"
            leadingIcon={<FontAwesomeIcon icon={faPlus} />}
            disabled={archived}
            onClick={formikProps.submitForm}
          >
            {dictionary.COST_CENTER_ADD_SUB_COST_CENTER_BUTTON}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
