import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ManufacturerRequest } from '../../../Data/Manufacturer';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { manufacturerService } from '../../../Services/ManufacturerService';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { routes } from '../../../Utils/routes';
import { ManufacturerForm } from '../shared/ManufacturerForm';

const ManufacturerFormWrapper: FC<FormFlowStepComponent<ManufacturerRequest>> = ({ FormActionsComponent, onFormStepComplete }) =>
  <ManufacturerForm FormActionsComponent={FormActionsComponent} onFormStepComplete={onFormStepComplete} />;

export const CreateManufacturer: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onCancel = () => history.push(routes.manufacturers.index);
  const onComplete = async (request: ManufacturerRequest) => {
    try {
      await manufacturerService.create(request);
      dispatch(addNotification(NotificationType.success, dictionary.CREATE_MANUFACTURER_SUCCESS));
      history.push(routes.manufacturers.index);
    } catch (exception) {
      if (exception.message.includes('409')) {
        dispatch(addNotification(NotificationType.error, dictionary.MANUFACTURER_NOT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.CREATE_MANUFACTURER_ERROR));
      }
    }
  };

  return (
    <FormFlow<ManufacturerRequest>
      onComplete={onComplete}
      onCancel={onCancel}
      completeButtonLabel={dictionary.CREATE_MANUFACTURER_SUBMIT_BTN}
      steps={[
        { pageHeader: dictionary.CREATE_MANUFACTURER_PAGE_HEADER, component: ManufacturerFormWrapper },
      ]}
    />
  );
};
