import { FormikValues, useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'react-flexbox-grid';
import User, { UserHospitalAssociation } from '../../../../Data/User';
import { dictionary } from '../../../../dictionary';
import { NotificationType } from '../../../../redux/initialState';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { LoadState } from '../../../../redux/loadState';
import { fetchSingleHospitalUser, fetchSingleVendorUser } from '../../../../Services/UserService';
import { FormFlowStepComponent } from '../../../../Shared/FormFlow/FormFlow';
import { userFormFields } from '../user.utils';
import { validateCreateOrEditUserForm } from '../createOrEditUserForm.validation';
import { validateCreateUserForm } from '../createUserForm.validation';
import { UserHospitalAssociationsTable } from './UserHospitalAssociationsTable';
import { UserHospitalAddAssociationForm } from '../UserHospitalAddAssociationForm/UserHospitalAddAssociationForm';
import { useUtilityStyles } from '../../../../Themes/utility.styles';
import { currentUserIsAdminForAtLeastOneHospital, currentUserSelector } from '../../../../redux/user/userSelectors';
import { If } from '../../../../Shared/If';
import { userRoles } from '../../../../Utils/userRoles';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { getFormikInitialValues } from '../../../../Utils/formik.utils';
import { SimpleFieldWithLabel } from '../../../../Shared/fields/SimpleFieldWithLabel';
import { BackButton } from '../../../../Shared/buttons/BackButton';
import { routes } from '../../../../Utils/routes';

type CreateOrEditUserFormProps = FormFlowStepComponent<User>;

export const CreateOrEditHospitalUserForm: FC<CreateOrEditUserFormProps> = ({ FormActionsComponent, onFormStepComplete, onFormStepBack, children }) => (
  <CreateOrEditUserForm isHospitalUser FormActionsComponent={FormActionsComponent} onFormStepComplete={onFormStepComplete} onFormStepBack={onFormStepBack}>
    {children}
  </CreateOrEditUserForm>
);

export const CreateOrEditVendorUserForm: FC<CreateOrEditUserFormProps> = ({ FormActionsComponent, onFormStepComplete, onFormStepBack, children }) => (
  <CreateOrEditUserForm isHospitalUser={false} FormActionsComponent={FormActionsComponent} onFormStepComplete={onFormStepComplete} onFormStepBack={onFormStepBack}>
    {children}
  </CreateOrEditUserForm>
);

const CreateOrEditUserForm: FC<CreateOrEditUserFormProps & { isHospitalUser: boolean }> = (props) => {
  const { onFormStepComplete, FormActionsComponent, isHospitalUser } = props;
  const { id: idStr } = useParams<{ id: string }>();
  const id = Number(idStr);
  const isEdit = Boolean(id);
  const [user, setUser] = useState<User>();
  const [userLoadState, setUserLoadState] = useState<LoadState>(LoadState.loading);
  const [hospitalAssociations, setHospitalAssociations] = useState<UserHospitalAssociation[]>([]);
  const [highlightAssociationSectionError, setHighlightAssociationSectionError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const utilClasses = useUtilityStyles();
  const currentUser = useSelector(currentUserSelector);

  useEffect(() => {
    if (!isEdit) {
      setUserLoadState(LoadState.loaded);
      return;
    }

    (async () => {
      try {
        let fetchedUser;
        if (isHospitalUser) fetchedUser = await fetchSingleHospitalUser(id);
        else fetchedUser = await fetchSingleVendorUser(id);
        if (fetchedUser) {
          setUser(fetchedUser);
          setHospitalAssociations(fetchedUser.hospitalAssociations);
          setUserLoadState(LoadState.loaded);
        }
      } catch (e) {
        setUserLoadState(LoadState.error);
      }
    })();
  }, [id, isEdit, isHospitalUser]);

  const currentUserHasCreatePermission = useSelector(currentUserIsAdminForAtLeastOneHospital);
  const displayReadOnly = !(currentUserHasCreatePermission || !!user?.canCurrentUserEdit) || !!user?.archived;

  const addHospitalAssociation = (association: UserHospitalAssociation) => {
    setHighlightAssociationSectionError(false);
    setHospitalAssociations([...hospitalAssociations, { ...association, canCurrentUserEdit: true }]);
  };
  const editHospitalAssociation = (association: UserHospitalAssociation) => {
    const updatedList = hospitalAssociations.filter(ha => ha.hospitalId !== association.hospitalId);
    setHospitalAssociations([...updatedList, association]);
  };
  const removeHospitalAssociation = (association: UserHospitalAssociation) => {
    const updatedList = hospitalAssociations.filter(ha => ha.hospitalId !== association.hospitalId);
    setHospitalAssociations(updatedList);
  };

  const onSubmit = (values: FormikValues) => {
    if (!hospitalAssociations.length) {
      setHighlightAssociationSectionError(true);
      dispatch(addNotification(NotificationType.error, dictionary.CREATE_EDIT_USER_NO_ASSOCIATIONS_ERROR));
    } else {
      setHighlightAssociationSectionError(false);
      onFormStepComplete({
        ...values,
        id: user?.id,
        email: values.email || user?.email,
        hospitalAssociations,
      });
    }
  };

  const initialValues = getFormikInitialValues(userFormFields, { ...user });
  const formikProps = useFormik({
    initialValues,
    validate: isEdit ? validateCreateOrEditUserForm : validateCreateUserForm,
    onSubmit,
    enableReinitialize: true,
  });

  if (isEdit && (userLoadState !== LoadState.loaded || !user)) {
    const stateMsg = userLoadState === LoadState.error ? dictionary.EDIT_USER_FAILED_TO_LOAD : dictionary.EDIT_USER_LOADING;

    return <h2>{stateMsg}</h2>;
  }

  const backDestination = isHospitalUser ? routes.users.hospitals.index : routes.users.vendors.index;

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={4} className={utilClasses.pr2}>
          <SimpleFieldWithLabel descriptor={userFormFields.firstName} form={formikProps} disabled={displayReadOnly} />
          <SimpleFieldWithLabel descriptor={userFormFields.lastName} form={formikProps} disabled={displayReadOnly} />

          <If condition={!isEdit || currentUser?.roleName !== userRoles.vendor}>
            <SimpleFieldWithLabel descriptor={userFormFields.email} form={formikProps} />
          </If>

          <SimpleFieldWithLabel descriptor={userFormFields.phoneNumber} form={formikProps} disabled={displayReadOnly} />
          <If condition={isHospitalUser}>
            <SimpleFieldWithLabel descriptor={userFormFields.hospitalEmployeeId} form={formikProps} disabled={displayReadOnly} />
          </If>
          <If condition={currentUser?.roleName === userRoles.omiAdmin}>
            <h2 className={utilClasses.mt3}>
              {dictionary.FORM_CONTROL_LABEL_TEST_ACCOUNTS_ONLY}
            </h2>
            <SimpleFieldWithLabel type="password" descriptor={userFormFields.password} form={formikProps} placeholder={dictionary.FORM_CONTROL_LABEL_TEMP_PASSWORD_PLACEHOLDER} />
          </If>

        </Col>
        <If condition={!displayReadOnly}>
          <Col
            xs={!isHospitalUser ? 8 : 4}
            data-testid="user-associations"
            className={joinArgs(
              utilClasses.backgroundNearWhite,
              utilClasses.px2,
              utilClasses.py1,
              highlightAssociationSectionError ? utilClasses.errorBorder : ''
            )}
          >
            <UserHospitalAddAssociationForm
              displayVendorUser={!isHospitalUser}
              hospitalAssociations={hospitalAssociations}
              addHospitalAssociation={addHospitalAssociation}
            />
          </Col>
        </If>
      </Row>
      <Row>
        <Col xs={12}>
          <h2>{dictionary.FORM_DISPLAY_HOSPITAL_ASSOCIATIONS}</h2>
          <UserHospitalAssociationsTable
            isVendorUser={!isHospitalUser}
            associations={hospitalAssociations}
            onRemoveAssociation={displayReadOnly ? undefined : removeHospitalAssociation}
            onEditAssociation={displayReadOnly ? undefined : editHospitalAssociation}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <If condition={!displayReadOnly}>
            <FormActionsComponent />
          </If>
          <If condition={displayReadOnly}>
            <BackButton onClick={() => history.push(backDestination)} />
          </If>
        </Col>
      </Row>
    </form>
  );
};
