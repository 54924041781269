import { ApiRequisition, ReqStub } from '../../../Data/Requisition';
import { toCurrency } from '../../../Utils/formatNumber';
import { nonAlphanumericRegexGlobal } from '../../../Utils/validationUtils';
import { getDisplayStatus } from '../../Requisitions/shared/requisition.utils';

export type ReqForInterfacesTable = Pick<ApiRequisition,
| 'id'
| 'createdDate'
| 'caseDate'
| 'actionReason'
| 'approvedDate'
| 'processedDate'
| 'poNumber'
| 'canCurrentUserApprove'
> & {
  status: string;
  statusCode: string;
  hospitalName: string;
  hospitalId: number;
  manufacturerName: string;
  manufacturerId: number;
  departmentName: string;
  departmentId: number;
  value: string;
  mrn: string;
  numberOfExceptions: number;
  hospitalManufacturerIdentifier: string;
};

export const getInterfaceRows = (reqs: ReqStub[]): ReqForInterfacesTable[] =>
  reqs.map(req => ({
    status: getDisplayStatus(req),
    statusCode: req.status || '',
    id: req.id || 0,
    createdDate: req.createdDate,
    hospitalName: req.hospital?.name || '',
    hospitalId: req.hospital?.id,
    departmentName: req.department?.name || '',
    departmentId: req.department?.id,
    manufacturerName: req.manufacturer?.name || '',
    manufacturerId: req.manufacturer.id,
    value: toCurrency(req.totalPrice),
    caseDate: req.caseDate,
    actionReason: req.actionReason || '',
    approvedDate: req.approvedDate,
    processedDate: req.processedDate,
    poNumber: req.poNumber,
    canCurrentUserApprove: req.canCurrentUserApprove || false,
    mrn: req.patientMrn,
    numberOfExceptions: 0,
    hospitalManufacturerIdentifier: req.hospitalManufacturerIdentifier,
  }));

export const shouldIncludeReqInResults = (filterInput: string, req: ReqForInterfacesTable, colName?: keyof ReqForInterfacesTable): boolean => {
  if (!colName) {
    return true;
  }
  const filterColValue = req[colName]?.toString().toLocaleLowerCase() || '';
  const filterInputValue = colName === 'mrn' ? filterInput.replace(nonAlphanumericRegexGlobal, '') : filterInput;

  // Should fall into left part of ternary unless a non-string column is added to col options.
  return typeof filterColValue === 'string' ? (filterColValue.toLocaleLowerCase().indexOf(filterInputValue.toLocaleLowerCase()) !== -1) : true;
};
