import { FC } from 'react';
import { Row, UseExpandedRowProps, TableInstance } from 'react-table';
import { MergedRequisition } from '../../../../../Data/Requisition';
import { TableBodyRow } from '../../../../../Shared/ReactTable/TableBodyRow';
import { KeyedReqProductForTable } from '../../ProductInfo/shared/ProductInfo.utils';
import { useReqProductSubtable } from './individualProductsTable.utils';
import colors from '../../../../../Themes/colors';
import { dictionary } from '../../../../../dictionary';

interface IndividualReqProductRowProps {
  row: Row<KeyedReqProductForTable>;
  editable: boolean;
  tableContext: TableInstance<KeyedReqProductForTable>;
  requisition: MergedRequisition,
}

export const IndividualReqProductRow: FC<IndividualReqProductRowProps> = (props) => {
  const {
    row: uncastRow,
    editable,
    tableContext,
    requisition,
  } = props;
  const row = uncastRow as Row<KeyedReqProductForTable> & UseExpandedRowProps<any>;
  const subtableContext = useReqProductSubtable(row.original, editable, requisition);
  const expandTableExtraColSpan = tableContext.allColumns.length - subtableContext.allColumns.length;
  const rowStyle = row.index % 2 === 0 ? colors.mintCream : colors.trueWhite;
  const toolTip = 'catalogNumber';
  const toolTipDef = dictionary.REQ_PRODUCTS_TO_BILL_ID_TOOLTIP;

  return (
    <TableBodyRow
      row={row}
      rowStyle={rowStyle}
      expandTableContext={subtableContext}
      expandTableExtraColSpan={expandTableExtraColSpan}
      data-testid="req-products-product-row"
      toolTip={toolTip}
      toolTipDef={toolTipDef}
    />
  );
};
