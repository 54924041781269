import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLifeRing, faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navBarStyles } from './navbar.styles';
import { logout } from '../../Services/LoginService';
import { actionTypes } from '../../redux/actionTypes';
import { dictionary } from '../../dictionary';
import { PermissionTo } from '../../Utils/permissionChecker';
import { routes } from '../../Utils/routes';
import { currentUserIsAdminForAtLeastOneHospital, currentUserSelector } from '../../redux/user/userSelectors';
import { useUtilityStyles } from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';
import { Button } from '../buttons/Button';
import { If } from '../If';

const isDev = process.env.REACT_APP_ENV === 'LOCAL_DEV' || process.env.REACT_APP_ENV === 'DEV';

export const NavBar: FC = () => {
  const classes = navBarStyles();
  const utilClasses = useUtilityStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const showAdminLink = PermissionTo.performAnyHospitalAdminAction.isGrantedTo(currentUser);
  const showSettingsLink = useSelector(currentUserIsAdminForAtLeastOneHospital);

  const handleLogout = () => {
    logout();
    dispatch({ type: actionTypes.user.clearUser });
  };

  return (
    <header className={classes.header}>
      <div className={classes.topBar}>
        <div className={joinArgs(utilClasses.maxAppWidth, utilClasses.flex, utilClasses.spaceBetween)}>
          <img className={classes.logo} src="/images/qsight-logo-no-tag.png" alt="QSight" />

          <Button
            buttonStyle="text"
            data-testid="logout-button"
            onClick={handleLogout}
            leadingIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
          >
            Logout
          </Button>
        </div>
      </div>

      <nav className={classes.topNav}>
        <div className={joinArgs(utilClasses.maxAppWidth, classes.topNavLinks)}>
          <Link className="top-nav-link" to={routes.requisitions.index}>{dictionary.REQUISITIONS}</Link>

          <If condition={showAdminLink}>
            <Link className="top-nav-link" to={routes.interfaces.index}>{dictionary.INTERFACES_HEADER}</Link>
          </If>

          <If condition={showAdminLink}>
            <Link className="top-nav-link" to={routes.reports.index}>{dictionary.REPORTS_HEADER}</Link>
          </If>

          <If condition={showAdminLink}>
            <Link className="top-nav-link" to={routes.adminDashboard.index}>{dictionary.ADMIN}</Link>
          </If>

          <If condition={isDev}>
            <Link className="top-nav-link" to={routes.devTools.index}>Dev Tools</Link>
          </If>

          <div className={utilClasses.flexGrow} />

          <If condition={showSettingsLink}>
            <Link className="top-nav-link" to={routes.settings.index}>
              <FontAwesomeIcon icon={faCog} size="lg" />
            </Link>
          </If>

          <Link className="top-nav-link" to={routes.helpCenter.index}>
            <FontAwesomeIcon icon={faLifeRing} size="lg" className={utilClasses.mr05} />
            Help
          </Link>
        </div>
      </nav>
    </header>
  );
};
