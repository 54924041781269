import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { VendorUsersIndex } from './VendorUsers/VendorUsersIndex/VendorUsersIndex';
import { CreateVendorUser } from './VendorUsers/CreateVendorUser/CreateVendorUser';
import { EditVendorUser } from './VendorUsers/EditVendorUser/EditVendorUser';
import { HospitalUsersIndex } from './HospitalUsers/HospitalUsersIndex/HospitalUsersIndex';
import { CreateHospitalUser } from './HospitalUsers/CreateHospitalUser/CreateHospitalUser';
import { EditHospitalUser } from './HospitalUsers/EditHospitalUser/EditHospitalUser';
import { HospitalAdminRoute } from '../../Shared/ProtectedRoute/ProtectedRoute';

export const UsersRoutes: FC = () => (
  <Switch>
    <Route exact path={routes.users.vendors.index}>
      <VendorUsersIndex />
    </Route>
    <HospitalAdminRoute exact path={routes.users.vendors.create}>
      <CreateVendorUser />
    </HospitalAdminRoute>
    <Route exact path={routes.users.vendors.editTemplate}>
      <EditVendorUser />
    </Route>

    <Route exact path={routes.users.hospitals.index}>
      <HospitalUsersIndex />
    </Route>
    <HospitalAdminRoute exact path={routes.users.hospitals.create}>
      <CreateHospitalUser />
    </HospitalAdminRoute>
    <Route exact path={routes.users.hospitals.editTemplate}>
      <EditHospitalUser />
    </Route>
  </Switch>
);
