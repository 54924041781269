import { createUseStyles } from 'react-jss';

export default createUseStyles<any, undefined | { numberOfIcons?: number }>({
  autocompleteWrapper: {
    boxSizing: 'border-box',
    position: 'relative',
    flexGrow: 1,
  },
  inputWrapper: {
    position: 'relative',
    zIndex: 1,
  },
  input: {
    margin: '0',
    padding: '0.5rem 0.75rem',
    paddingRight: ({ numberOfIcons = 1 }) => `${numberOfIcons * 2.02}rem !important`, // overriding the global input
    boxSizing: 'border-box',
  },
  tagBox: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '1rem',
  },
  disableUnderline: { borderBottom: '0' },
  tag: {
    marginRight: '.75rem',
    marginTop: '.75rem',
    '&:last-child': { marginRight: 0 },
  },
  tagName: { marginRight: '.5rem' },
  elevate: { position: 'relative', zIndex: 2 },
  label: { marginBottom: '.5rem' },
});
