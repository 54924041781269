import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { FormikProps, useFormik } from 'formik';
import { FC, useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { useDispatch } from 'react-redux';
import { HospitalManufacturerContract } from '../../../../../Data/Contract';
import { HospitalBase } from '../../../../../Data/Hospital';
import { HospitalProduct } from '../../../../../Data/HospitalProduct';
import { ManufacturerBase } from '../../../../../Data/Manufacturer';
import { ProductCategoryStub } from '../../../../../Data/ProductCategory';
import { dictionary } from '../../../../../dictionary';
import { NotificationType } from '../../../../../redux/initialState';
import { addNotification } from '../../../../../redux/notifications/notificationsActionCreator';
import { hospitalService } from '../../../../../Services/HospitalService';
import { Button } from '../../../../../Shared/buttons/Button';
import { Modal } from '../../../../../Shared/modals/Modal/Modal';
import useUtilityStyles from '../../../../../Themes/utility.styles';
import { joinArgs } from '../../../../../Utils/arrayUtils';
import { getFormikInitialValues } from '../../../../../Utils/formik.utils';
import { ProductInfoFormValues } from '../shared/ProductInfo.utils';
import { ProductInfoReqProductFormFields } from '../shared/ProductInfoReqProductFormFields';
import {
  composeHospitalProductRequest,
  productInfoCreateProductFormFields,
  ProductInfoCreateProductFormValues,
  validateProductInfoCreateProductForm,
} from './productInfoCreateProduct.utils';
import { ProductInfoHospitalProductFields } from './ProductInfoHospitalProductFields';

interface ProductInfoCreateProductModalProps {
  isOpen: boolean;
  close: () => void;
  activeContracts: HospitalManufacturerContract[] | null;
  onHospitalProductCreation: (hospitalProduct: HospitalProduct, values: ProductInfoCreateProductFormValues) => void;
  productCategories: ProductCategoryStub[];
  hospital: HospitalBase;
  initialCatalogNumber: string;
  manufacturer?: ManufacturerBase;
}

export const ProductInfoCreateProductModal: FC<ProductInfoCreateProductModalProps> = (props) => {
  const { isOpen, close, activeContracts, productCategories, hospital, manufacturer, initialCatalogNumber, onHospitalProductCreation } = props;
  const utilStyles = useUtilityStyles();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = getFormikInitialValues<ProductInfoCreateProductFormValues>(
    productInfoCreateProductFormFields,
    { manufacturer, catalogNumber: initialCatalogNumber }
  );

  const onSubmit = async (values: ProductInfoCreateProductFormValues) => {
    if (manufacturer) {
      const createHospProdRequest = composeHospitalProductRequest(values, hospital.id, manufacturer);
      try {
        const { id } = await hospitalService.createProduct(createHospProdRequest);
        const newHospitalProduct: HospitalProduct = { ...createHospProdRequest, id };
        onHospitalProductCreation(newHospitalProduct, values);
        formikProps.resetForm();
        setIsSubmitting(false);
        close();
      } catch (e) {
        const exception = e as AxiosError;
        if (exception.response?.status === 409) {
          dispatch(addNotification(NotificationType.error, dictionary.PRODUCT_CATALOG_NUMBER_NOT_UNIQUE_ERROR));
        } else {
          dispatch(addNotification(NotificationType.error, dictionary.REQ_PRODUCT_ADD_PRODUCT_ERROR));
        }
        setIsSubmitting(false);
      }
    }
  };
  const formikProps = useFormik({ initialValues, onSubmit, validate: validateProductInfoCreateProductForm, enableReinitialize: true });
  const onCancelClick = () => {
    formikProps.resetForm();
    close();
  };
  const modalActionsContent = (
    <>
      <Button buttonStyle="reverse" onClick={onCancelClick} data-testid="product-info-create-product-modal-close-btn">
        {dictionary.CANCEL}
      </Button>
      <Button onClick={formikProps.submitForm} leadingIcon={<FontAwesomeIcon icon={faPlus} />} disabled={isSubmitting} data-testid="product-info-create-product-modal-close-btn">
        {dictionary.REQ_CREATE_HOSP_PROD_MODAL_ADD_BTN}
      </Button>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      data-testid="create-product-modal"
      title={dictionary.REQ_CREATE_HOSP_PROD_MODAL_TITLE}
      size="lg"
      actionsContent={modalActionsContent}
    >
      <Row>
        <Col xs={6} className={joinArgs(utilStyles.backgroundNearWhite, utilStyles.px2)}>
          <h3>{dictionary.REQ_EDIT_PRODUCTS_PRODUCT_INFO_HEADER}</h3>
          <ProductInfoHospitalProductFields formikProps={formikProps} productCategories={productCategories} />
        </Col>
        <Col xs={6} className={utilStyles.px2}>
          <h3>{dictionary.REQ_EDIT_PRODUCTS_REQ_INFO_HEADER}</h3>
          <ProductInfoReqProductFormFields allowPriceChanges activeContracts={activeContracts} formikConfig={formikProps as unknown as FormikProps<ProductInfoFormValues>} />
        </Col>
      </Row>
    </Modal>
  );
};
