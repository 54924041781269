import axios, { AxiosRequestConfig } from 'axios';
import { acquireTokenSilent } from './LoginService';
import { apiBaseUrl, apiMgmtSubscriptionKey } from '../envSettings';

type ApiMethod = <ResponseData = any>(config: AxiosRequestConfig, queryParams?: any) => Promise<ResponseData>;

const get: ApiMethod = async (config, queryParams = {}) => request({ method: 'get', ...config }, queryParams);

const post: ApiMethod = async (config, queryParams = {}) => request({ method: 'post', ...config }, queryParams);

const put: ApiMethod = async (config, queryParams = {}) => request({ method: 'put', ...config }, queryParams);

const patch: ApiMethod = async (config, queryParams = {}) => request({ method: 'patch', ...config }, queryParams);

const remove: ApiMethod = async (config, queryParams = {}) => request({ method: 'delete', ...config }, queryParams);

const request: ApiMethod = async (requestConfig, queryParams) => {
  const token = await acquireTokenSilent();
  if (!token) throw new Error('acquireTokenSilent failed');
  const config: AxiosRequestConfig = {
    baseURL: apiBaseUrl,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.idToken}`,
      'Ocp-Apim-Subscription-Key': apiMgmtSubscriptionKey,
    },
    params: queryParams,
    ...requestConfig,
  };

  return axios(config)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export default { get, post, put, patch, remove };
