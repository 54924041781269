import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FC, useState } from 'react';
import { DepartmentBase } from '../../../../Data/Department';
import { ManufacturerBase } from '../../../../Data/Manufacturer';
import { UserHospitalAssociation } from '../../../../Data/User';
import { dictionary } from '../../../../dictionary';
import { If } from '../../../../Shared/If';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { RowAction, ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { EditUserAssociationModal } from '../EditUserAssociationModal/EditUserAssociationModal';
import { getUserRoleName } from '../user.utils';

interface CreateUserHospitalAssociationsTableProps {
  associations: UserHospitalAssociation[];
  onRemoveAssociation?: (association: UserHospitalAssociation) => void;
  onEditAssociation?: (association: UserHospitalAssociation) => void;
  isVendorUser?: boolean;
}

interface AssociationsForUserTableData extends UserHospitalAssociation {
  id: number;
  hospitalId: number;
  hospitalName: string;
  departmentsList: string;
  manufacturersList?: string;
  userRole?: string;
}

export const UserHospitalAssociationsTable: FC<CreateUserHospitalAssociationsTableProps> = (props) => {
  const { associations, onRemoveAssociation, onEditAssociation, isVendorUser = false } = props;
  const [associationInEdit, setAssociationInEdit] = useState<UserHospitalAssociation>();

  const closeModal = () => setAssociationInEdit(undefined);
  const onEditClickAction = (assoc: UserHospitalAssociation) => setAssociationInEdit(assoc);

  const rowData: AssociationsForUserTableData[] = associations.map(a => {
    const association: AssociationsForUserTableData = {
      ...a,
      id: a.hospitalId,
      departmentsList: a.departments.map((d: DepartmentBase) => d.name).join(', '),
    };
    if (isVendorUser) {
      association.manufacturersList = a.manufacturers.map((d: ManufacturerBase) => d.name).join(', ');
    }
    if (onEditAssociation) {
      association.userRole = a.hospitalUserRole ? getUserRoleName(a.hospitalUserRole) : undefined;
    }

    return association;
  });

  const rowActions: RowAction<UserHospitalAssociation>[] = [];
  if (onEditAssociation) {
    rowActions.push({
      name: dictionary.USER_ASSOCIATIONS_ROW_EDIT_TEXT,
      icon: faEdit,
      onAction: assoc => onEditClickAction(assoc),
      intent: 'primary',
    });
  }
  if (onRemoveAssociation) {
    rowActions.push({
      name: dictionary.USER_ASSOCIATIONS_ROW_REMOVE_TEXT,
      icon: faTrash,
      onAction: assoc => onRemoveAssociation(assoc),
      intent: 'danger',
    });
  }

  const tableColumns: ColumnProperty<AssociationsForUserTableData>[] = [
    {
      displayName: 'Hospitals',
      name: 'hospitalName',
    },
    {
      displayName: 'Departments',
      name: 'departmentsList',
    },
  ];

  if (isVendorUser) {
    tableColumns.push({
      displayName: 'Manufacturers',
      name: 'manufacturersList',
    });
  }
  if (onEditAssociation) {
    tableColumns.push({
      displayName: 'User Role',
      name: 'userRole',
    });
  }

  const shouldRenderRowActions = (association: UserHospitalAssociation) => association.canCurrentUserEdit ?? false;

  return (
    <>
      <SortableTable
        rowData={rowData}
        columns={tableColumns}
        initialColumnSort="hospitalName"
        isLoading={false}
        noDataMessage={dictionary.CREATE_VENDOR_USER_ASSOCIATIONS_TABLE_EMPTY}
        rowActions={rowActions}
        rowActionsWidth="10%"
        shouldRenderRowActions={shouldRenderRowActions}
      />

      <If condition={!!associationInEdit}>
        <EditUserAssociationModal
          association={associationInEdit}
          closeModal={closeModal}
          onEditAssociation={onEditAssociation!}
          isVendorUser={isVendorUser}
        />
      </If>
    </>
  );
};
