import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTag } from '@fortawesome/free-solid-svg-icons';
import useUtilityStyles from '../../../../../Themes/utility.styles';
import { calculatePrice, toCurrency, formatPercentageVariable } from '../../../../../Utils/formatNumber';
import { RequisitionCapitation } from '../../../../../Data/Requisition';
import RequisitionProduct from '../../../../../Data/RequisitionProduct';
import { joinArgs } from '../../../../../Utils/arrayUtils';
import useBadgeStyles from '../Badges/Badges.style';

interface CapitationColumnProps {
  rowCap: RequisitionCapitation;
}

const calcTotalPrice = (products: RequisitionProduct[]) => products.reduce((acc, cur) => acc + calculatePrice(cur.price, cur.quantity, cur.discount), 0);

export const CapitationColumnSavingAmount: FC<CapitationColumnProps> = (props) => {
  const { rowCap } = props;
  const badgeClasses = useBadgeStyles();
  const utilityClasses = useUtilityStyles();

  const totalPrice = calcTotalPrice(rowCap.products);
  const amountSaved = totalPrice - rowCap.price;

  return (amountSaved > 0)
    ? <span className={utilityClasses.fwBold}>{ toCurrency(amountSaved) }</span>
    : (
      <div className={badgeClasses.root}>
        <span className={joinArgs(utilityClasses.fwBold, utilityClasses.textTrueWhite)}>{ `Overage: ${toCurrency(Math.abs(amountSaved))}` }</span>
      </div>
    );
};
export const capSavingsAmountAccessor = (rowCap: RequisitionCapitation): JSX.Element => <CapitationColumnSavingAmount rowCap={rowCap} />;

export const CapitationColumnSavingPercent: FC<CapitationColumnProps> = (props) => {
  const { rowCap } = props;
  const classes = useBadgeStyles();
  const utilityClasses = useUtilityStyles();

  const totalPrice = calcTotalPrice(rowCap.products);
  const percentSaved = ((totalPrice - rowCap.price) / totalPrice) * 100;
  let cellBaseClass = '';
  let cellIcon = faTag;

  if (percentSaved < 0) {
    cellIcon = faExclamationTriangle;
    cellBaseClass = classes.root;
  }

  return (
    <div className={joinArgs(cellBaseClass, utilityClasses.flexEnd)}>
      <FontAwesomeIcon icon={cellIcon} className={utilityClasses.mr1} />
      <span className={utilityClasses.fwBold}>
        { formatPercentageVariable(percentSaved) }
      </span>
    </div>
  );
};
export const capSavingsPctAccessor = (rowCap: RequisitionCapitation): JSX.Element => <CapitationColumnSavingPercent rowCap={rowCap} />;
