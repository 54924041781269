import { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import UserRoleSwitch from './UserRoleSwitch/UserRoleSwitch';
import UserSetTermsAccepted from './UserSetTermsAccepted';
import { Callout } from '../../Shared/Callout/Callout';
import { DevToolsSection } from './DevToolsSection';
import { DevToolsStyleGuide } from './DevToolsStyleGuide';

const useDevToolStyles = createUseStyles({
  root: {
    padding: '2rem 4rem',
  },
});

export function DevTools(): ReactElement {
  const classes = useDevToolStyles();
  return (
    <Callout className={classes.root} title={`Dev Tools - Environment: ${process.env.REACT_APP_ENV}`}>
      <h1 style={{ fontSize: '2rem' }}>Utilities</h1>
      <DevToolsSection title="User Utils">
        <UserRoleSwitch />
        <UserSetTermsAccepted />
      </DevToolsSection>

      <DevToolsStyleGuide />
    </Callout>
  );
}
