import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currentUserSelector } from './redux/user/userSelectors';
import { routes } from './Utils/routes';
import { HospitalAdminRoute, HospitalRoute, SupportUserRoute } from './Shared/ProtectedRoute/ProtectedRoute';
import { DevTools } from './Components/DevTools/DevTools';
import { RedirectHome } from './Shared/RedirectHome/RedirectHome';
import { TermsOfUse } from './Components/HelpCenter/TermsOfUse/TermsOfUse';
import { HelpCenter } from './Components/HelpCenter/HelpCenter';
import { TermsOfUseEditor } from './Components/HelpCenter/TermsOfUseEditor/TermsOfUseEditor';
import { RequisitionsRoutes } from './Components/Requisitions/RequisitionsRoutes';
import { AdminDashboard } from './Components/AdminDashboard/AdminDashboard';
import { ContractsRoutes } from './Components/Contracts/ContractsRoutes';
import { UsersRoutes } from './Components/Users/UsersRoutes';
import { ApprovalRoutingsRoutes } from './Components/ApprovalRoutings/ApprovalRoutingsRoutes';
import { ProductsRoutes } from './Components/Products/ProductsRoutes';
import { ProductCategoriesRoutes } from './Components/ProductCategories/ProductCategoriesRoutes';
import { CapitationsRoutes } from './Components/Capitations/CapitationsRoutes';
import { CapitationCategoriesRoutes } from './Components/CapitationCategories/CapitationCategoriesRoutes';
import { HospitalsRoutes } from './Components/Hospitals/HospitalsRoutes';
import { DepartmentsRoutes } from './Components/Departments/DepartmentsRoutes';
import { CostCentersRoutes } from './Components/CostCenters/CostCentersRoutes';
import { PhysiciansRoutes } from './Components/Physicians/PhysiciansRoutes';
import { ProceduresRoutes } from './Components/Procedures/ProceduresRoutes';
import { ManufacturersRoutes } from './Components/Manufacturers/ManufacturersRoutes';
import { HospitalManufacturerIdentifiersRoutes } from './Components/HospitalManufacturerIdentifiers/HospitalManufacturerIdentifiersRoutes';
import { Interfaces } from './Components/Interfaces/InterfaceIndex/InterfacesIndex';
import { Reports } from './Components/Reports/ReportsIndex';
import { ReportDetail } from './Components/Reports/ReportDetail';
import { SettingsIndex } from './Components/Settings/SettingsIndex';
import { ArchivedRequisitionsRoutes } from './Components/ArchivedRequisitions/ArchivedRequisitionsRoutes';
import { HealthSystemsRoutes } from './Components/HealthSystems/HealthSystemsRoutes';

const isDev = process.env.REACT_APP_ENV === 'LOCAL_DEV' || process.env.REACT_APP_ENV === 'DEV';
const AppRouter: FC = () => {
  const currentUser = useSelector(currentUserSelector);

  return (
    <Switch>
      {isDev && <Route path={routes.devTools.index} component={DevTools} />}

      {!currentUser?.acceptedTermsOfUse && (
        <Route path="/" component={TermsOfUse} />
      )}

      <Route exact path="/">
        <RedirectHome />
      </Route>

      <Route exact path={routes.login}>
        <RedirectHome />
      </Route>

      <HospitalAdminRoute exact path={routes.settings.index}>
        <SettingsIndex />
      </HospitalAdminRoute>

      <Route exact path={routes.helpCenter.index}>
        <HelpCenter />
      </Route>

      <Route exact path={routes.helpCenter.termsOfUse}>
        <TermsOfUse showButtons={false} />
      </Route>

      <SupportUserRoute exact path={routes.helpCenter.editTermsOfUse}>
        <TermsOfUseEditor />
      </SupportUserRoute>

      <HospitalRoute path={routes.adminDashboard.index}>
        <AdminDashboard />
      </HospitalRoute>

      <HospitalRoute path={routes.interfaces.index}>
        <Interfaces />
      </HospitalRoute>

      <HospitalRoute path={routes.reports.index}>
        <Reports />
      </HospitalRoute>

      <HospitalRoute path={routes.reports.detailsTemplate}>
        <ReportDetail />
      </HospitalRoute>

      <Route path={routes.requisitions.index}>
        <RequisitionsRoutes />
      </Route>

      <HospitalRoute path={routes.archivedRequisitions.index}>
        <ArchivedRequisitionsRoutes />
      </HospitalRoute>

      <HospitalRoute path={routes.contracts.index}>
        <ContractsRoutes />
      </HospitalRoute>

      <HospitalRoute path={routes.users.index}>
        <UsersRoutes />
      </HospitalRoute>

      <HospitalRoute path={routes.approvalRoutings.index}>
        <ApprovalRoutingsRoutes />
      </HospitalRoute>

      <HospitalRoute path={routes.products.index}>
        <ProductsRoutes />
      </HospitalRoute>

      <HospitalRoute path={routes.productCategories.index}>
        <ProductCategoriesRoutes />
      </HospitalRoute>

      <HospitalRoute path={routes.capitations.index}>
        <CapitationsRoutes />
      </HospitalRoute>

      <HospitalRoute path={routes.capitationCategories.index}>
        <CapitationCategoriesRoutes />
      </HospitalRoute>

      <SupportUserRoute path={routes.healthSystems.index}>
        <HealthSystemsRoutes />
      </SupportUserRoute>

      <SupportUserRoute path={routes.hospitals.index}>
        <HospitalsRoutes />
      </SupportUserRoute>

      <SupportUserRoute path={routes.departments.index}>
        <DepartmentsRoutes />
      </SupportUserRoute>

      <HospitalRoute path={routes.costCenters.index}>
        <CostCentersRoutes />
      </HospitalRoute>

      <HospitalRoute path={routes.physicians.index}>
        <PhysiciansRoutes />
      </HospitalRoute>

      <HospitalRoute path={routes.procedures.index}>
        <ProceduresRoutes />
      </HospitalRoute>

      <SupportUserRoute path={routes.manufacturers.index}>
        <ManufacturersRoutes />
      </SupportUserRoute>

      <HospitalRoute path={routes.hospitalManufacturerIdentifiers.index}>
        <HospitalManufacturerIdentifiersRoutes />
      </HospitalRoute>

      <RedirectHome />
    </Switch>
  );
};

export default AppRouter;
