import { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import User from '../../../Data/User';
import { updateUser } from '../../../Services/UserService';
import { userRoles } from '../../../Utils/userRoles';
import { ReduxState } from '../../../redux/initialState';
import { getCurrentUserAction } from '../../../redux/user/userActionCreators';
import LoadState from '../../../redux/loadState';
import { dictionary } from '../../../dictionary';

const useStyles = createUseStyles({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {},
});

export default function UserRoleSwitch(): ReactElement {
  const classes = useStyles();
  const userState = useSelector((state: ReduxState) => state.user);
  const user = userState.currentUser;
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    if (!user || !user.id) {
      return;
    }
    const userCopy: User = { ...user };
    userCopy.roleName = event.target.value;
    updateUser(userCopy).then(() => {
      dispatch(getCurrentUserAction());
    });
  };

  return (
    <div>
      <label>
        {`Change Role (DEV) ${dictionary.NBSP}`}
        <select
          className={classes.formControl}
          value={user?.roleName || userRoles.hospital.approver}
          onChange={handleChange}
          name="RoleName"
        >
          <option value={userRoles.omiAdmin}>OMI Admin</option>
          <option value={userRoles.hospital.approver}>Approver</option>
          <option value={userRoles.vendor}>Vendor</option>
        </select>
      </label>
      {userState.fetchLoadState === LoadState.loading ? <strong>Loading user...</strong> : null}
    </div>
  );
}
