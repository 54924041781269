import { FC, useMemo } from 'react';
import { Column } from 'react-table';
import { ReqTable } from './shared/ArchivedRequisitionsTable/ReqTable';
import { dictionary } from '../../../dictionary';
import useUtilityStyles from '../../../Themes/utility.styles';
import {
  ArchivedRequisitionColumnProperties,
  ArchivedReqForIndexTable,
  ArchivedReqTableForIndex,
} from './shared/ArchivedRequisitionsTable/reqTable.utils';
import { RequisitionStatus } from '../../../Data/RequisitionStatus';

interface ArchivedRequisitionsProps {
  isLoading: boolean;
  requisitions: ArchivedReqForIndexTable[];
  activeTab: number;
}

export const ArchivedRequisitions: FC<ArchivedRequisitionsProps> = (props) => {
  const { isLoading, requisitions } = props;
  const utilClasses = useUtilityStyles();
  const archivedRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.archived);
  const ArchivedReqTable = useMemo((): FC<{ reqs: ArchivedReqForIndexTable[], colProps: Column<ArchivedReqForIndexTable>[], noDataMsg: string }> => ({ reqs, colProps, noDataMsg }) => (
    <ReqTable
      requisitions={reqs}
      columns={colProps}
      noDataMessage={noDataMsg}
      isLoading={isLoading}
      initialSortOrder="desc"
    />
  ), [isLoading]);

  const requisitionTables: ArchivedReqTableForIndex[] = [
    {
      testId: 'archived-requisitions-box',
      title: '',
      reqs: archivedRequisitions,
      colProps: ArchivedRequisitionColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_ARCHIVED_EMPTY,
    },
  ];

  return (
    <>
      { requisitionTables.map((table) => (
        <div className={utilClasses.mb2} data-testid={table.testId}>
          <h2 className={utilClasses.mt0}>{table.title}</h2>
          <ArchivedReqTable reqs={table.reqs} colProps={table.colProps} noDataMsg={table.noDataMsg} />
        </div>
      )) }
    </>
  );
};
