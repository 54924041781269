import { FormikErrors, FormikValues, useFormik } from 'formik';
import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import { MergedRequisition } from '../../../Data/Requisition';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { currentUserRoleSelector } from '../../../redux/user/userSelectors';
import { updateRequisition } from '../../../Services/RequisitionService';
import { Button } from '../../../Shared/buttons/Button';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { If } from '../../../Shared/If';
import { useUtilityStyles } from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { dateToString, getSlashFormattedDate } from '../../../Utils/dateUtils';
import { createDescriptor, getFieldDescriptorMap, getFormikInitialValues } from '../../../Utils/formik.utils';
import { userRoles } from '../../../Utils/userRoles';
import { validateCharacterLimit } from '../../../Utils/validationUtils';
import { isStatusApprovedOrCompleted } from './requisition.utils';

interface RequisitionInfoDisplayProps {
  requisition: MergedRequisition;
}

interface PONumberFormFields {
  poNumber: string;
}

const poNumberFormFields = getFieldDescriptorMap<PONumberFormFields>([
  createDescriptor({ name: 'poNumber', label: dictionary.REQ_DETAILS_PO_NUMBER }),
]);

export const validatePONumberForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { poNumber } = poNumberFormFields;

  return {
    ...validateCharacterLimit(values, [poNumber], 50),
  };
};

export const ReqBillingInfoDisplay: FC<RequisitionInfoDisplayProps> = ({ requisition }) => {
  const utilityClasses = useUtilityStyles();
  const currentUserRole = useSelector(currentUserRoleSelector);
  const rolesCanSeeCostCenter = [userRoles.hospital.approver, userRoles.omiAdmin];
  const dispatch = useDispatch();

  const initialValues = getFormikInitialValues<PONumberFormFields>(
    poNumberFormFields,
  );

  const formikProps = useFormik({
    initialValues,
    validate: validatePONumberForm,
    onSubmit: async (formValues: any) => {
      try {
        await updateRequisition({
          id: requisition.id!,
          procedureId: requisition.procedure!.id,
          physicianId: requisition.physician!.id,
          patientId: requisition.patient!.id,
          caseNumber: requisition.caseNumber!,
          caseDate: requisition.caseDate!,
          costCenterId: requisition.costCenter?.id || null,
          subCostCenterId: requisition.subCostCenter?.id || null,
          poNumber: formValues.poNumber || null,
          additionalNotes: formValues.additionalNotes || null,
        });
        window.location.reload();
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
      }
    },
  });

  return (
    <section className={`details-section ${utilityClasses.h100} ${utilityClasses.px2} ${utilityClasses.py1} ${utilityClasses.backgroundNearWhite}`}>
      <h2>{dictionary.REQ_BILLING_INFO_HEADER}</h2>

      <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_MANUFACTURER} value={requisition.manufacturer?.name} />

      { requisition.vendor ? (
        <SimpleDisplayField
          label={dictionary.REQ_DETAILS_VENDOR_REP_LABEL}
          value={`${requisition.vendor.firstName} ${requisition.vendor.lastName}` ?? 'N/A'}
        />
      ) : null }

      { requisition.createdDate ? (
        <SimpleDisplayField label={dictionary.REQ_DETAILS_CREATED_DATE} value={getSlashFormattedDate(dateToString(requisition.createdDate))} />
      ) : null }

      <If condition={isStatusApprovedOrCompleted(requisition.status)}>
        <SimpleDisplayField label={dictionary.REQ_DETAILS_PO_NUMBER} value={requisition.poNumber} />
      </If>

      <If condition={requisition && !!requisition.canCurrentUserAssignPONumber}>
        <form onSubmit={formikProps.handleSubmit}>
          <Row>
            <Col xs={8}>
              <SimpleFieldWithLabel form={formikProps} descriptor={poNumberFormFields.poNumber} />
            </Col>
            <Col xs={4} className={joinArgs(utilityClasses.textRight, utilityClasses.mt6)}>
              <Button buttonStyle="reverse" onClick={formikProps.submitForm}>
                {dictionary.SAVE_BUTTON_LABEL}
              </Button>
            </Col>
          </Row>
        </form>
      </If>

      { rolesCanSeeCostCenter.includes(currentUserRole) ? (
        <>
          <SimpleDisplayField label={dictionary.COST_CENTER_LABEL} value={requisition.costCenter?.name} />
          <SimpleDisplayField label={dictionary.SUB_COST_CENTER_LABEL} value={requisition.subCostCenter?.name} />
        </>
      ) : null }
    </section>
  );
};
