import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { FormikProps } from 'formik';
import { CasePatientFormFields } from './casePatientInfo.types';
import { dictionary } from '../../../../dictionary';
import { DateFieldWithLabel } from '../../../../Shared/fields/DateFieldWithLabel';
import { SimpleFieldWithLabel } from '../../../../Shared/fields/SimpleFieldWithLabel';
import { MergedRequisition } from '../../../../Data/Requisition';
import { caseDateMin, caseDateMax } from './casePatientInfo.utils';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { useUtilityStyles } from '../../../../Themes/utility.styles';
import { ReqPatientForm } from '../../shared/ReqPatientForm';

interface CasePatientInfoFormProps {
  formikProps: FormikProps<CasePatientFormFields>;
  editRequisition: MergedRequisition;
}

export const CasePatientInfoForm: FC<CasePatientInfoFormProps> = ({ formikProps, editRequisition }) => {
  const utilClasses = useUtilityStyles();

  return (
    <Row>
      <Col xs={6} lg={4} className="input-form">
        <ReqPatientForm requisition={editRequisition} formikProps={formikProps} />
      </Col>

      <Col xs={6} lg={4} className={joinArgs('input-form', utilClasses.pl2)}>
        <SimpleFieldWithLabel
          form={formikProps}
          name="caseNumber"
          labelText={`${dictionary.FORM_CONTROL_LABEL_CASE_NUMBER} ${dictionary.REQUIRED_FIELD_MARK}`}
          autoComplete="off"
        />
        <DateFieldWithLabel
          form={formikProps}
          name="caseDate"
          labelText={`${dictionary.FORM_CONTROL_LABEL_CASE_DATE} ${dictionary.REQUIRED_FIELD_MARK}`}
          minDate={caseDateMin}
          maxDate={caseDateMax}
        />
      </Col>
    </Row>
  );
};
