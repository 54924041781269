import { useState, FunctionComponent, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { Contract, ContractIdentifierUniquenessError } from '../../../Data/Contract';
import { dictionary } from '../../../dictionary';
import { routes } from '../../../Utils/routes';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { EditContractForm } from './EditContractForm';
import { contractService } from '../../../Services/ContractService';
import LoadState from '../../../redux/loadState';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { ErrorPage } from '../../../Shared/ErrorPage';

export const EditContract: FunctionComponent = () => {
  const params = useParams<{ id: string }>();
  const contractId = Number(params.id);
  const [contract, setContract] = useState<Contract>();
  const [contractLoadState, setContractLoadState] = useState(LoadState.loading);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const fetchedContract: (Contract | null) = await contractService.getById(contractId);
        setContract(fetchedContract);
        setContractLoadState(LoadState.loaded);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_LOAD_ERROR));
        setContractLoadState(LoadState.error);
      }
    })();
  }, [dispatch, contractId]);

  const onComplete = async (updatedContract: Contract) => {
    try {
      await contractService.update(updatedContract);
      setTimeout(() => dispatch(addNotification(NotificationType.success, dictionary.CONTRACT_UPDATE_SUCCESS)), 1000);
      history.push(routes.contracts.details(contractId));
    } catch (e) {
      const exception = e as AxiosError;
      if (exception.response?.status === 409) {
        const hospitalNamesWithConflict = (exception.response?.data as ContractIdentifierUniquenessError).hospitalNames || [];
        dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_IDENTIFIER_UNIQUENESS_ERROR(hospitalNamesWithConflict)));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_INFO_VALIDATION_REQUEST_FAILED_ERROR));
      }
    }
  };

  if (contractLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }

  if (contractLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  return (
    <FormFlow<Contract, Contract>
      editSource={contract}
      steps={[
        { pageHeader: `${dictionary.CONTRACT_EDIT_HEADER_LABEL} ${contract?.contractIdentifier}`, component: EditContractForm },
      ]}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      onCancel={() => { history.push(routes.contracts.details(contractId)); }}
      onComplete={onComplete}
    />
  );
};
