import { useHistory } from 'react-router-dom';
import { ReactElement, FunctionComponent } from 'react';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { physicianColumnProperties, PhysicianForTable } from './physicansTable.utils';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { routes } from '../../../../Utils/routes';

interface PhysicianTableProps {
  physicians: PhysicianForTable[];
  columnProperties?: ColumnProperty<PhysicianForTable>[];
  isLoading: boolean;
}

export const PhysiciansTable: FunctionComponent<PhysicianTableProps> = ({
  physicians,
  columnProperties = physicianColumnProperties,
  isLoading,
}): ReactElement => {
  const history = useHistory();
  const handleRowClick = (physician: PhysicianForTable) => history.push(routes.physicians.edit(physician.physicianId));

  return (
    <section>
      <SortableTable
        initialColumnSort="name"
        rowData={physicians}
        columns={columnProperties}
        handleRowSelect={handleRowClick}
        noDataMessage={dictionary.PHYSICIAN_TABLE_EMPTY}
        isLoading={isLoading}
        tableTestId="physicians-table"
      />
    </section>
  );
};
