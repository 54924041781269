import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { HospitalProductDetails } from '../../../Data/HospitalProduct';
import { dictionary } from '../../../dictionary';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import { getSlashFormattedDate } from '../../../Utils/dateUtils';
import { toCurrency } from '../../../Utils/formatNumber';

interface ProductDetailsFieldsProps {
  product: HospitalProductDetails;
}

export const ProductDetailsFields: FC<ProductDetailsFieldsProps> = ({ product }) => (
  <div>
    <h2>{dictionary.PRODUCT_DETAILS_PRODUCT_INFO_HEADER}</h2>
    <SimpleDisplayField label={dictionary.PRODUCT_BRAND_NAME} value={product.brandName} />
    <SimpleDisplayField label={dictionary.PRODUCT_CATALOG_NUMBER} value={product.catalogNumber} />
    <Row>
      <Col xl={6}>
        <SimpleDisplayField label={dictionary.PRODUCT_DESCRIPTION} value={product.description} />
      </Col>
    </Row>
    <Row>
      <Col xs={6} md={4} xl={2}>
        <SimpleDisplayField label={dictionary.PRODUCT_UNIT_OF_MEASURE} value={product.unitOfMeasure} />
      </Col>
      <Col xs={6}>
        <SimpleDisplayField label={dictionary.PRODUCT_QTY_UNIT_OF_MEASURE} value={product.quantityUnitOfMeasure} />
      </Col>
    </Row>
    <SimpleDisplayField label={dictionary.PRODUCT_TYPE} value={product.productType} />

    <Row>
      <Col md={6} xl={4}>
        <h2>{dictionary.PRODUCT_DETAILS_CONTRACT_INFO_HEADER}</h2>
        <SimpleDisplayField label={dictionary.CONTRACT_ID_LABEL} value={product.contractIdentifier} />
        <SimpleDisplayField label={dictionary.HEALTH_SYSTEM_NAME_LABEL} value={product.healthSystemName} />
        <SimpleDisplayField label={dictionary.PRODUCT_MANUFACTURER} value={product.manufacturerName} />
        <Row>
          <Col xs={6} md={5}>
            <SimpleDisplayField label={dictionary.CONTRACT_EFFECTIVE_DATE_LABEL} value={getSlashFormattedDate(product.contractEffectiveDate)} />
          </Col>
          <SimpleDisplayField label={dictionary.CONTRACT_EXPIRATION_DATE_LABEL} value={getSlashFormattedDate(product.contractExpirationDate)} />
        </Row>
        <SimpleDisplayField label={dictionary.PRODUCT_CONTRACT_PRICE} value={toCurrency(product.contractPrice)} />
      </Col>

      <Col xs={6}>
        <h2>{dictionary.PRODUCT_DETAILS_BILLING_INFO_HEADER}</h2>
        <SimpleDisplayField label={dictionary.PRODUCT_ITEM_IDENTIFIER} value={product.orderIdentifier} />
        <SimpleDisplayField label={dictionary.PRODUCT_CATEGORY_NAME_LABEL} value={product.category?.name} />
        <SimpleDisplayField label={dictionary.PRODUCT_CATEGORY_IDENTIFIER_NAME_LABEL} value={product.category?.hospitalProductCategoryIdentifier} />
      </Col>
    </Row>
  </div>
);
