import { FC, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { FormikValues, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { CostCenterStub, CreateCostCenterRequest, UpdateCostCenterRequest } from '../../../Data/CostCenter';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { costCenterFormFields, getCostCenterFormInitialVals, validateCostCenterForm } from './costCenterForm.utils';
import { NotificationType } from '../../../redux/initialState';
import Hospital, { HospitalBase } from '../../../Data/Hospital';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { dictionary } from '../../../dictionary';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';
import { SubCostCenterTable } from './SubCostCenterTable/SubCostCenterTable';
import useUtilityStyles from '../../../Themes/utility.styles';
import { CostCenterAddSubCostCenter } from './CostCenterAddSubCostCenter';
import { getNextSubCostCenterId, SubCostCenterForTable } from './SubCostCenterTable/subCostCenterTable.utils';
import { hospitalService } from '../../../Services/HospitalService';
import { If } from '../../../Shared/If';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import { SubCostCenterStub } from '../../../Data/SubCostCenter';

interface CostCenterFormProps extends FormFlowStepComponent<CreateCostCenterRequest | UpdateCostCenterRequest> {
  costCenter?: CostCenterStub;
}

export const CostCenterForm: FC<CostCenterFormProps> = ({ costCenter, FormActionsComponent, onFormStepComplete }) => {
  const dispatch = useDispatch();
  const utilClasses = useUtilityStyles();
  const [subCostCenters, setSubCostCenters] = useState<SubCostCenterForTable[]>([]);
  const [hospitalBases, setHospitalBases] = useState<HospitalBase[]>([]);
  const [selectedHospital, setSelectedHospital] = useState<Hospital>();
  const isEdit = !!costCenter;
  const userIsAllowedToFillForm = !isEdit || !!costCenter?.canCurrentUserEdit;

  useEffect(() => {
    (async () => {
      if (!hospitalBases.length && !costCenter) {
        try {
          const hospitalBasesResult = await hospitalService.getAdminHospitals();
          setHospitalBases(hospitalBasesResult);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospitalBases, costCenter]);

  useEffect(() => {
    if (costCenter) {
      setSubCostCenters(costCenter.subCostCenters.map((scc) =>
        ({ ...scc, subCostCenterId: scc.id, id: scc.id ?? getNextSubCostCenterId(costCenter.subCostCenters ?? []) })));
    }
  }, [costCenter]);

  const formikInitialValues = useMemo(() => getCostCenterFormInitialVals(costCenter), [costCenter]);
  const formikProps = useFormik({
    initialValues: formikInitialValues,
    validate: validateCostCenterForm,
    onSubmit: (formValues: FormikValues) => {
      onFormStepComplete({
        name: formValues.name,
        departmentId: formValues.department.id,
        hospitalCostCenterIdentifier: formValues.hospitalCostCenterIdentifier,
        subCostCenters: subCostCenters.map((scc) => ({ ...scc, id: scc.subCostCenterId })),
      });
    },
  });

  const { hospital } = formikProps.values;

  useEffect(() => {
    (async () => {
      if (hospital && !costCenter) {
        try {
          const hosp = await hospitalService.getHospitalById(hospital.id);
          setSelectedHospital(hosp);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospital, costCenter]);

  const sortedHospitals = hospitalBases.sort(alphanumericSortDescriptor('name', 1));
  const sortedActiveDepartments = selectedHospital?.departments.filter(d => !d.archived).sort(alphanumericSortDescriptor('name', 1)) || [];

  const onHospitalChange = (hosp: HospitalBase | null) => formikProps.setValues({ hospital: hosp ?? undefined, department: undefined });

  const addSubCostCenter = (subCostCenter: SubCostCenterStub) =>
    setSubCostCenters([...subCostCenters, { ...subCostCenter, id: getNextSubCostCenterId(subCostCenters) }]);

  const canSelectDept = selectedHospital && userIsAllowedToFillForm;

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row className={utilClasses.mb2}>
        <Col xs={4}>
          <If condition={!costCenter}>
            <Autocomplete
              descriptor={costCenterFormFields.hospital}
              formikProps={formikProps}
              options={sortedHospitals}
              disabled={!hospitalBases.length || !userIsAllowedToFillForm}
              onChange={onHospitalChange}
            />
            <Autocomplete descriptor={costCenterFormFields.department} formikProps={formikProps} options={sortedActiveDepartments} disabled={!canSelectDept} />
          </If>
          <If condition={isEdit}>
            <SimpleDisplayField label={costCenterFormFields.hospital.label} value={costCenter?.hospitalName} />
            <SimpleDisplayField label={costCenterFormFields.department.label} value={costCenter?.departmentName} />
          </If>
          <SimpleFieldWithLabel form={formikProps} descriptor={costCenterFormFields.name} disabled={costCenter?.archived || !userIsAllowedToFillForm} />
          <SimpleFieldWithLabel form={formikProps} descriptor={costCenterFormFields.hospitalCostCenterIdentifier} disabled={costCenter?.archived || !userIsAllowedToFillForm} />
        </Col>
        <If condition={userIsAllowedToFillForm}>
          <Col xs={8} className={utilClasses.pl3}>
            <CostCenterAddSubCostCenter addSubCostCenter={addSubCostCenter} addedSubCostCenters={subCostCenters} archived={costCenter?.archived} />
          </Col>
        </If>
      </Row>

      <Row>
        <Col xs={12}>
          <If condition={!!subCostCenters.length}>
            <SubCostCenterTable
              subCostCenters={subCostCenters}
              setSubCostCenters={setSubCostCenters}
              isEdit={isEdit}
              userIsAllowedToEdit={userIsAllowedToFillForm}
              isCostCenterArchived={costCenter?.archived ?? false}
            />
          </If>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
