import { SortValues } from './useSortedRows';

export const getAriaLabel = <T>(propertyDisplayName: string, propertyName: keyof T, field: keyof T, direction: SortValues): string => {
  const string = `Sort ${propertyDisplayName}`;

  if (field !== propertyName) {
    return `${string} ascending.`;
  }

  if (direction === 'asc') {
    return `${string} descending.`;
  }

  return `${string} ascending.`;
};
