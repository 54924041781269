import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CreateOrEditProductCategoryRequest, ProductCategoryStub } from '../../../Data/ProductCategory';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import LoadState from '../../../redux/loadState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { hospitalService } from '../../../Services/HospitalService';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { routes } from '../../../Utils/routes';
import { ProductCategoryForm } from '../shared/ProductCategoryForm';

const ProductCategoryFormWrapper: FC<FormFlowStepComponent<CreateOrEditProductCategoryRequest, ProductCategoryStub>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <ProductCategoryForm FormActionsComponent={FormActionsComponent} productCategory={editSource} onFormStepComplete={onFormStepComplete} />;

export const EditProductCategory: FC = () => {
  const params = useParams<{ id: string }>();
  const productCategoryId = Number(params.id);
  const history = useHistory();
  const dispatch = useDispatch();
  const [productCategory, setProductCategory] = useState<ProductCategoryStub>();
  const [productCategoryLoadState, setProductCategoryLoadState] = useState(LoadState.loading);

  useEffect(() => {
    (async () => {
      try {
        const result = await hospitalService.getProductCategoryById(productCategoryId);
        setProductCategory(result);
        setProductCategoryLoadState(LoadState.loaded);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_PRODUCT_CATEGORY_LOAD_ERROR));
        setProductCategoryLoadState(LoadState.error);
      }
    })();
  }, [dispatch, productCategoryId]);

  if (productCategoryLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }
  if (productCategoryLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  const onCancel = () => history.push(routes.productCategories.index);
  const onComplete = async (request: CreateOrEditProductCategoryRequest) => {
    try {
      await hospitalService.updateProductCategory(productCategoryId, request);
      dispatch(addNotification(NotificationType.success, dictionary.EDIT_PRODUCT_CATEGORY_SUCCESS));
      history.push(routes.productCategories.index);
    } catch (exception: any) {
      if (exception?.response?.status === 409) {
        dispatch(addNotification(NotificationType.error, exception.response.data.message));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_PRODUCT_CATEGORY_FAILURE));
      }
    }
  };

  return (
    <FormFlow<CreateOrEditProductCategoryRequest, ProductCategoryStub>
      editSource={productCategory}
      onComplete={onComplete}
      onCancel={onCancel}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      steps={[
        { pageHeader: dictionary.EDIT_PRODUCT_CATEGORY_PAGE_HEADER, component: ProductCategoryFormWrapper },
      ]}
    />
  );
};
