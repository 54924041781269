import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CapitationCategory, CapitationCategoryRequest } from '../../../Data/CapitationCategory';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import LoadState from '../../../redux/loadState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { capitationService } from '../../../Services/capitationService';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { routes } from '../../../Utils/routes';
import { CapitationCategoryForm } from '../shared/CapitationCategoryForm';

const EditCapitationCategoryFormWrapper: FC<FormFlowStepComponent<CapitationCategoryRequest, CapitationCategory>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <CapitationCategoryForm FormActionsComponent={FormActionsComponent} onFormStepComplete={onFormStepComplete} capitationCategory={editSource} />;

export const EditCapitationCategory: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const capCategoryId = Number(params.id);
  const [capitationCategory, setCapitationCategory] = useState<CapitationCategory>();
  const [capitationCategoryLoadState, setCapitationCategoryLoadState] = useState(LoadState.loading);

  useEffect(() => {
    (async () => {
      try {
        const result = await capitationService.getCategoryById(capCategoryId);
        setCapitationCategory(result);
        setCapitationCategoryLoadState(LoadState.loaded);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_CAPITATION_CATEGORY_LOAD_ERROR));
        setCapitationCategoryLoadState(LoadState.error);
      }
    })();
  }, [dispatch, capCategoryId]);

  const onCancel = () => history.push(routes.capitationCategories.index);
  const onComplete = async (request: CapitationCategoryRequest) => {
    try {
      await capitationService.updateCategory(capCategoryId, request);
      dispatch(addNotification(NotificationType.success, dictionary.EDIT_CAPITATION_CATEGORY_SUCCESS));
      history.push(routes.capitationCategories.index);
    } catch (exception) {
      if (exception?.response?.status === 409) {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_CAPITATION_CATEGORY_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_CAPITATION_CATEGORY_ERROR));
      }
    }
  };

  if (capitationCategoryLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }
  if (capitationCategoryLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  return (
    <FormFlow<CapitationCategoryRequest, CapitationCategory>
      editSource={capitationCategory}
      isViewOnly={!capitationCategory?.canCurrentUserEdit}
      onComplete={onComplete}
      onCancel={onCancel}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      completeButtonDisabled={!capitationCategory?.canCurrentUserEdit}
      steps={[
        { pageHeader: dictionary.EDIT_CAPITATION_CATEGORY_FORM_PAGE_HEADER, component: EditCapitationCategoryFormWrapper },
      ]}
    />
  );
};
