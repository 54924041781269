import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'react-jss';
import App from './App';
import createStoreWithMiddleware from './redux/store';
import { theme } from './Themes/theme';

const { store } = createStoreWithMiddleware();

ReactDOM.render(
  <>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </>,
  document.getElementById('root'),
);
