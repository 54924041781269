import { useHistory } from 'react-router-dom';
import { ReactElement, FC } from 'react';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { costCenterColumnProperties } from './costCentersTable.utils';
import { dictionary } from '../../../../dictionary';
import { CostCenterStub } from '../../../../Data/CostCenter';
import { routes } from '../../../../Utils/routes';

interface CostCentersTableProps {
  costCenters: CostCenterStub[];
  columnProperties?: ColumnProperty<CostCenterStub>[];
  isLoading: boolean;
}

export const CostCentersTable: FC<CostCentersTableProps> = ({
  costCenters,
  columnProperties = costCenterColumnProperties,
  isLoading,
}): ReactElement => {
  const history = useHistory();

  const handleRowClick = (costCenter: CostCenterStub) => history.push(routes.costCenters.edit(costCenter.id));

  return (
    <section>
      <SortableTable
        rowData={costCenters}
        columns={columnProperties}
        handleRowSelect={handleRowClick}
        initialColumnSort="hospitalName"
        noDataMessage={dictionary.COST_CENTERS_TABLE_EMPTY}
        isLoading={isLoading}
        tableTestId="cost-centers-table"
      />
    </section>
  );
};
