import { FC } from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../buttons/Button';
import { dictionary } from '../../dictionary';
import { Modal } from './Modal/Modal';

type Props = {
  isOpen: boolean,
  onConfirm: () => void,
  onDismiss: () => void,
};

export const CancelModal: FC<Props> = ({ isOpen, onConfirm, onDismiss }) => (
  <Modal
    data-testid="cancel-modal"
    title={dictionary.CANCEL_MODAL_TITLE}
    isOpen={isOpen}
    icon={faExclamationTriangle}
    actionsContent={(
      <>
        <Button
          onClick={onDismiss}
          buttonStyle="reverse"
          data-testid="attention-stayonthispage"
        >
          {dictionary.CANCEL}
        </Button>
        <Button
          onClick={onConfirm}
          buttonStyle="primary"
          data-testid="attention-confirm"
        >
          {dictionary.CONTINUE_AND_EXIT}
        </Button>
      </>
    )}
  >
    {dictionary.CANCEL_MODAL_TEXT}
  </Modal>
);
