import { EmbedParams, ReportTile } from '../Data/Report';
import apiClient from './apiClient';

export const reportUrl = '/report';
export const reportTileUrl = '/report/tiles/list';

export const fetchReportTileInformation = async (): Promise<ReportTile[]> =>
  apiClient.get<ReportTile[]>({ url: reportTileUrl });
export const fetchReportData = async (reportId: string): Promise<EmbedParams> =>
  apiClient.get<EmbedParams>({ url: `${reportUrl}/${reportId}` });

export const reportService = {
  getTileInformation: fetchReportTileInformation,
  getReportEmbed: fetchReportData,
};
