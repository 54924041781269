import { FC, useMemo } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { HealthSystem } from '../../../Data/HealthSystem';
import {
  healthSystemFormFields,
  getHealthSystemFormInitialVals,
  getHealthSystemUpdateRequestFromFormFields,
  HealthSystemFormFields,
  validateHealthSystemForm } from './healthSystemForm.utils';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { dictionary } from '../../../dictionary';
import useUtilityStyles from '../../../Themes/utility.styles';
import { DisplayField } from '../../../Shared/fields/DisplayField';
import { If } from '../../../Shared/If';

interface HealthSystemFormProps extends FormFlowStepComponent<HealthSystem> {
  healthSystem?: HealthSystem;
}

export const HealthSystemForm: FC<HealthSystemFormProps> = ({ healthSystem, FormActionsComponent, onFormStepComplete }) => {
  const formikInitialValues = useMemo(() => getHealthSystemFormInitialVals(healthSystem), [healthSystem]);
  const formikProps = useFormik<HealthSystemFormFields>({
    initialValues: formikInitialValues,
    validate: validateHealthSystemForm,
    onSubmit: async (values: HealthSystemFormFields) => {
      const updatedHealthSystem = getHealthSystemUpdateRequestFromFormFields(values);
      onFormStepComplete(updatedHealthSystem);
    },
  });

  const utilityClasses = useUtilityStyles();

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={4}>
          <SimpleFieldWithLabel form={formikProps} descriptor={healthSystemFormFields.name} />
          <If condition={!!healthSystem}>
            <div className={`${utilityClasses.topMargin}`}>
              <DisplayField
                label={dictionary.HOSPITALS_LIST_HEADER_LABEL}
                labelAlignItems="start"
                value={(
                  <div className={utilityClasses.flexColumn}>
                    {healthSystem?.hospitalStubs.length
                      ? healthSystem.hospitalStubs.map(hospital => <div key={hospital.id}>{hospital.name}</div>)
                      : dictionary.EMPTY_FIELD_MARK}
                  </div>
                )}
              />
            </div>
          </If>
        </Col>
      </Row>

      <Row>
        <Col xs={4}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
