import { FC } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../Shared/buttons/Button';
import { dictionary } from '../../../dictionary';
import { Modal } from '../../../Shared/modals/Modal/Modal';

export interface EditProductContractModalProps {
  modalContext?: {
    message: string;
    title: string;
    cancelOnClick: () => void;
    continueOnClick: () => void;
  }
}

export const EditProductContractPrompt: FC<EditProductContractModalProps> = (props) => {
  const { modalContext } = props;
  if (!modalContext) return null;

  return (
    <Modal
      data-testid="edit-product-contract-modal"
      title={modalContext.title}
      isOpen={!!modalContext}
      icon={faInfoCircle}
      actionsContent={(
        <>
          <Button buttonStyle="reverse" onClick={modalContext.cancelOnClick} data-testid="modal-cancel">
            {dictionary.CANCEL}
          </Button>
          <Button buttonStyle="primary" onClick={modalContext.continueOnClick} data-testid="modal-continue">
            {dictionary.CONTINUE}
          </Button>
        </>
      )}
    >
      {modalContext.message}
    </Modal>
  );
};
