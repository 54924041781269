import { AnyAction } from 'redux';
import { HospitalManufacturerContractCapitation } from '../../Data/Capitation';
import RequisitionProduct from '../../Data/RequisitionProduct';
import { actionTypes } from '../actionTypes';

export interface ApplyCapitationAction extends AnyAction {
  capitation: HospitalManufacturerContractCapitation;
  productIndices: number[];
}

export const applyCapitationAction = (capitation: HospitalManufacturerContractCapitation, productIndices: number[]): ApplyCapitationAction => ({
  type: actionTypes.requisition.applyCapitation,
  capitation,
  productIndices,
});

export interface RemoveCapitationFromReqAction extends AnyAction {
  capitationIndex: number;
}

export const removeCapitationFromReqAction = (capitationIndex: number): RemoveCapitationFromReqAction => ({
  type: actionTypes.requisition.removeCapitation,
  capitationIndex,
});

export interface RemoveProductFromCapitationAction extends AnyAction {
  capitationIndex: number;
  productIndex: number;
}

export const removeProductFromCapitationAction = (capitationIndex: number, productIndex: number) => ({
  type: actionTypes.requisition.removeProductFromCapitation,
  capitationIndex,
  productIndex,
});

export interface UpdateProductAction extends AnyAction {
  productIndex: number;
  capitationIndex?: number;
  updatedProduct: RequisitionProduct;
}

export const updateProductAction = (productIndex: number, capitationIndex: number, updatedProduct: RequisitionProduct): UpdateProductAction => ({
  type: actionTypes.requisition.updateProduct,
  productIndex,
  capitationIndex,
  updatedProduct,
});
