import { createRef, FC, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { DateTime } from 'luxon';
import { joinArgs } from '../../Utils/arrayUtils';
import { useUtilityStyles } from '../../Themes/utility.styles';
import { SimpleFieldProps } from './SimpleFieldWithLabel';
import { dictionary } from '../../dictionary';
import { getSlashFormattedDate } from '../../Utils/dateUtils';
import { useDateFieldStyles } from './dateFieldWithLabel.styles';
import 'react-datepicker/dist/react-datepicker.css';
import { If } from '../If';

interface DateFieldWithLabelProps extends SimpleFieldProps {
  minDate?: number,
  maxDate?: number,
  showIcon?: boolean,
}

export const DateFieldWithLabel: FC<DateFieldWithLabelProps> = ({
  field,
  form: formikProps,
  labelText,
  minDate,
  maxDate,
  disabled = false,
  name: userProvidedName,
  descriptor,
  showIcon = true,
}) => {
  const classes = useDateFieldStyles();
  const utilClasses = useUtilityStyles();

  const name = userProvidedName || descriptor?.name;
  const formikField = field || formikProps.getFieldProps(name);
  const { name: formikFieldName } = formikField;
  const formValueAsJsDate = useMemo(() => (formikField.value ? new Date(formikField.value) : null), [formikField.value]);

  const fieldError = formikProps.errors[formikFieldName];
  const isErrorState = fieldError && formikProps.touched[formikFieldName];

  const requiredText = descriptor?.required ? dictionary.REQUIRED_FIELD_MARK : '';
  const label = `${(labelText || descriptor?.label)} ${requiredText}`;

  const labelClassName = isErrorState ? 'label-invalid-field' : '';
  const fieldClassName = isErrorState ? 'input-invalid-field' : '';

  const onChange: ReactDatePickerProps['onChange'] = (date, event) => {
    event?.preventDefault();
    const dateStr = date ? getSlashFormattedDate(date as Date) : null;
    formikProps.setFieldValue(formikFieldName, dateStr);
  };

  const inputClassName = joinArgs('date-input', 'text-input', fieldClassName);
  const ref = useMemo(() => createRef<DatePicker>(), []);
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.getModifierState('Shift') && event.key === 'Tab') {
      ref.current?.setOpen(false);
    }
  };

  return (
    <div className={classes.date}>
      <label htmlFor={formikFieldName} className={labelClassName}>
        <div className={joinArgs(utilClasses.flex, utilClasses.spaceBetween)}>

          <div>
            {label}
            <If condition={showIcon}>
              <FontAwesomeIcon icon={faCalendarAlt} style={{ marginLeft: '1em' }} />
            </If>
          </div>

          {isErrorState && (
            <div role="alert" className="validation-errors">
              {fieldError}
            </div>
          )}
        </div>

        <div className={joinArgs(utilClasses.flex, utilClasses.positionRelative)}>
          <DatePicker
            id={formikFieldName}
            data-testid={`${formikFieldName}-input`}
            ref={ref}
            className={inputClassName}
            disabled={disabled}
            placeholderText="mm/dd/yyyy"
            selected={formValueAsJsDate}
            onChange={onChange}
            onBlur={formikField.onBlur}
            autoComplete="off"
            minDate={DateTime.local().minus({ days: minDate ?? 7500 }).toJSDate()}
            maxDate={(maxDate || maxDate === 0) ? DateTime.local().plus({ days: maxDate ?? 0 }).toJSDate() : undefined}
            wrapperClassName={utilClasses.w100}
            shouldCloseOnSelect
            popperPlacement="bottom"
            customInput={<NumberFormat format="##/##/####" mask={['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']} />}
            onKeyDown={onKeyDown}
          />
        </div>
      </label>
    </div>
  );
};
