import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { dictionary } from '../../../dictionary';
import { LoadState } from '../../../redux/loadState';
import { ipmMetaInfoService } from '../../../Services/IpmMetaInfoService';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { routes } from '../../../Utils/routes';
import { TermsOfUseEditorForm } from './TermsOfUseEditorForm';

export const TermsOfUseEditor: FC = () => {
  const [termsOfUseRaw, setTermsOfUseRaw] = useState<string>();
  const [termsOfUseGetLoadState, setTermsOfUseGetLoadState] = useState<LoadState>(LoadState.loading);
  const [hasError, setHasError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const response = await ipmMetaInfoService.getTermsOfUse();
        setTermsOfUseRaw(response.html);
        setTermsOfUseGetLoadState(LoadState.loaded);
      } catch (e) {
        setHasError(true);
      }
    })();
  }, []);

  if (hasError || termsOfUseGetLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  if (termsOfUseGetLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }

  return (
    <FormFlow<string, string>
      steps={[
        { pageHeader: dictionary.MANAGE_TERMS_OF_USE_PAGE_HEADER, component: TermsOfUseEditorForm },
      ]}
      editSource={termsOfUseRaw}
      completeButtonLabel={dictionary.MANAGE_TERMS_UPDATE_BUTTON}
      onCancel={() => {
        history.push(routes.helpCenter.index);
      }}
      onComplete={async (newTermsContent: string) => {
        try {
          await ipmMetaInfoService.updateTermsOfUse(newTermsContent);
          history.push(routes.helpCenter.index);
        } catch (e) {
          setHasError(true);
        }
      }}
    />
  );
};
