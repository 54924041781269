import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HospitalAdminRoute } from '../../Shared/ProtectedRoute/ProtectedRoute';
import { routes } from '../../Utils/routes';
import { ApprovalRoutingsIndex } from './ApprovalRoutingsIndex/ApprovalRoutingsIndex';
import { CreateApprovalRouting } from './CreateApprovalRouting/CreateApprovalRouting';
import { EditApprovalRouting } from './EditApprovalRouting/EditApprovalRouting';

export const ApprovalRoutingsRoutes: FC = () => (
  <Switch>
    <Route exact path={routes.approvalRoutings.index}>
      <ApprovalRoutingsIndex />
    </Route>
    <HospitalAdminRoute exact path={routes.approvalRoutings.new}>
      <CreateApprovalRouting />
    </HospitalAdminRoute>
    <Route exact path={routes.approvalRoutings.editTemplate}>
      <EditApprovalRouting />
    </Route>
  </Switch>
);
