import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/initialState';

interface ProductUploadCounts {
  successes: number;
  errors: number;
}

interface ProductEditCounts {
  successes: number;
  errors: number;
  deletes: number;
}

export const useProductUploadCounts = (): ProductUploadCounts => {
  const successes = useSelector(
    (state: ReduxState) => state?.contract?.newContract?.products ?? [],
  ).length;

  const errors = useSelector(
    (state: ReduxState) => state?.contract?.newContract?.errorFailedLines ?? [],
  ).length;

  return { successes, errors };
};

export const useProductEditCounts = (): ProductEditCounts => {
  const successes = useSelector(
    (state: ReduxState) => state?.contract?.editContract?.products?.filter(p => p.deleteProduct?.toLowerCase() !== 'y') ?? [],
  ).length;

  const errors = useSelector(
    (state: ReduxState) => state?.contract?.editContract?.errorFailedLines ?? [],
  ).length;

  const deletes = useSelector(
    (state: ReduxState) => state?.contract?.editContract?.products?.filter(p => p.deleteProduct?.toLowerCase() === 'y') ?? [],
  ).length;

  return { successes, errors, deletes };
};
