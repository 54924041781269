import csvParse from 'csv-parse/lib/sync';
import { whiteSpaceRegexGlobal, nonAlphanumericRegexGlobal } from '../validationUtils';
import { unitOfMeasures, UnitOfMeasure, toUnitOfMeasure } from '../Constants';
import { ContractProduct, ContractProductStrings, ContractProductCSV, ContractStatus } from '../../Data/Contract';
import { ProductCategoryStub } from '../../Data/ProductCategory';
import { dictionary } from '../../dictionary';

export const csvHeaders = [
  { label: '*Catalog No.', key: 'catalogNumber' },
  { label: '*Description', key: 'description' },
  { label: '*Contract Price', key: 'price' },
  { label: '*Unit of Measure', key: 'unitOfMeasure' },
  { label: '*Qty Unit of Measure', key: 'quantityUnitOfMeasure' },
  { label: 'Brand Name', key: 'brandName' },
  { label: 'Item ID', key: 'orderIdentifier' },
  { label: 'Product Category ID', key: 'productCategoryIdentifier' },
  { label: '* - indicates required field', key: '' },
];

export const csvHeadersPlusDelete = [
  { label: '*Catalog No.', key: 'catalogNumber' },
  { label: '*Description', key: 'description' },
  { label: '*Contract Price', key: 'price' },
  { label: '*Unit of Measure', key: 'unitOfMeasure' },
  { label: '*Qty Unit of Measure', key: 'quantityUnitOfMeasure' },
  { label: 'Brand Name', key: 'brandName' },
  { label: 'Item ID', key: 'orderIdentifier' },
  { label: 'Product Category ID', key: 'productCategoryIdentifier' },
  { label: 'Delete? (Y)', key: 'deleteProduct' },
  { label: '* - indicates required field', key: '' },
];

export const csvHeadersExceptions = csvHeaders.map(x => x.label).map(x => (x === '* - indicates required field' ? 'Exceptions' : x));
export const csvHeadersExceptionsWithDelete = csvHeadersPlusDelete.map(x => x.label).map(x => (x === '* - indicates required field' ? 'Exceptions' : x));

export const readFile = async (file: File, encoding = 'UTF-8'): Promise<string> => {
  const reader = new FileReader();

  return new Promise<string>(res => {
    // TODO: handle reader.onerror
    reader.onload = (evt) => {
      res((evt?.target?.result ?? '') as string);
    };
    reader.readAsText(file, encoding);
  });
};
export interface ContractProductPricingsParseResult {
  successful: ContractProductCSV[];
  failedLines: string[];
  // deleted: ContractProductCSV[];
}

export interface ProductPricingsParseInput {
  productFileBlob: string;
  hospitalIds: number[];
  productCategories: ProductCategoryStub[];
  isEditMode?: boolean;
}

export type CellParse<T> =
| { parsed: true; raw: string; value: T; }
| { parsed: false; raw: string; reason: string; };

// An object with the same keys as ContractProduct, with values of type ParseInfo -dm
export type RowParse = {
  [K in keyof ContractProductCSV]: CellParse<ContractProduct[K]>
};

const priceBounds = { min: 0, max: 100_000_000 }; // dollars
const quantityUnitOfMeasureBounds = { min: 1, max: 4999 };

const EXCEPTION_PRICE_FORMAT = 'looks wrong';
const EXCEPTION_UNRECOGNISED_UNIT_OF_MEASURE = `must be one of ${unitOfMeasures.join('/')}`;
const EXCEPTION_INTEGER_FORMAT = 'is not a whole number';
const EXCEPTION_CATALOG_NO_REPEAT = 'must not be repeated';
const EXCEPTION_NUMBER_TOO_HIGH = (max: number) => `must be no more than ${max}`;
const EXCEPTION_NUMBER_TOO_LOW = (min: number) => `must be at least ${min}`;
const EXCEPTION_STRING_TOO_SHORT = (n: number) => `must be at least ${n} character${n === 1 ? '' : 's'}`;
const EXCEPTION_STRING_TOO_LONG = (n: number) => `must be no more than ${n} character${n === 1 ? '' : 's'}`;

export const parseRow = (
  contractProductStrings: Partial<ContractProductStrings>,
  hospitalIds: number[],
  productCategories: ProductCategoryStub[],
  products: Array<Partial<ContractProductStrings>>
): RowParse => {
  const {
    deleteProduct,
    catalogNumber,
    description,
    price,
    unitOfMeasure: UofM,
    quantityUnitOfMeasure: QUofM,
    brandName,
    orderIdentifier,
    productCategoryIdentifier,
  } = trimObjectValues(mapUndefinedValuesToEmptyString(contractProductStrings));

  let productCategoryParseResult = parseString(productCategoryIdentifier, { max: 100 });
  if (productCategoryParseResult.parsed) {
    productCategoryParseResult = verifyProductCategoriesExistAtHospitals(productCategoryIdentifier, hospitalIds, productCategories);
  }
  const cleanCatalogNumber = catalogNumber.replace(nonAlphanumericRegexGlobal, '');
  let catalogNumberParseResult = parseString(catalogNumber, { min: 1, max: 100 });
  if (catalogNumberParseResult.parsed) {
    catalogNumberParseResult = verifyCatalogNumberUnique(catalogNumber, products);
  }
  return {
    deleteProduct: parseString(deleteProduct, { max: 1 }),
    cleanCatalogNumber: { parsed: true, raw: '', value: cleanCatalogNumber },
    catalogNumber: catalogNumberParseResult,
    description: parseString(description, { min: 1, max: 500 }),
    price: parsePrice(price),
    unitOfMeasure: parseUnitOfMeasure(UofM),
    quantityUnitOfMeasure: parseInteger(QUofM, quantityUnitOfMeasureBounds),
    brandName: parseString(brandName, { max: 100 }),
    orderIdentifier: parseString(orderIdentifier, { max: 100 }),
    productCategoryIdentifier: productCategoryParseResult,
  };
};

const verifyCatalogNumberUnique = (providedCatalogNumber: string, products: Array<Partial<ContractProductStrings>>): CellParse<string> => {
  const catalogNumber = providedCatalogNumber.toLowerCase().replace(whiteSpaceRegexGlobal, '');
  const successParseResult: CellParse<string> = { parsed: true, raw: providedCatalogNumber, value: providedCatalogNumber };
  const catalogNumberIsUnique = products.filter(p => p.catalogNumber?.toLowerCase().replace(whiteSpaceRegexGlobal, '') === catalogNumber).length < 2;

  return catalogNumberIsUnique
    ? successParseResult
    : { parsed: false, raw: providedCatalogNumber, reason: EXCEPTION_CATALOG_NO_REPEAT };
};

const translateRowParseToContractProduct = (p: RowParse): ContractProduct => {
  const contractProduct: any = {};
  Object.entries(p).forEach(([key, parseInfo]) => {
    if (parseInfo?.parsed) {
      contractProduct[key] = parseInfo.value;
    } else {
      throw new Error('unparsed values shouldn\'t be getting through to this point');
    }
  });
  return contractProduct;
};

const parsePrice = (s: string): CellParse<number> => {
  if (!priceRegEx.test(s)) return { parsed: false, raw: s, reason: EXCEPTION_PRICE_FORMAT };
  const n = parseFloat(s.replace(/\$/g, '').replace(/,/g, ''));
  if (Number.isNaN(n)) return { parsed: false, raw: s, reason: EXCEPTION_PRICE_FORMAT };
  if (n < priceBounds.min) return { parsed: false, raw: s, reason: EXCEPTION_NUMBER_TOO_LOW(priceBounds.min) };
  if (n > priceBounds.max) return { parsed: false, raw: s, reason: EXCEPTION_NUMBER_TOO_HIGH(priceBounds.max) };
  return { parsed: true, raw: s, value: n };
};

const priceRegEx = /^\${0,1}(\d{0,3}(,\d{3})*|(\d+))(\.\d{0,2})?$/;

const parseUnitOfMeasure = (s: string): CellParse<UnitOfMeasure> => {
  const uom = toUnitOfMeasure(s);
  return uom
    ? { parsed: true, raw: s, value: uom }
    : { parsed: false, raw: s, reason: EXCEPTION_UNRECOGNISED_UNIT_OF_MEASURE };
};

const parseInteger = (s: string, { min, max }: {min?: number, max?: number} = {}): CellParse<number> => {
  const n = Number(s);
  if (Number.isNaN(n)) { return { parsed: false, raw: s, reason: EXCEPTION_INTEGER_FORMAT }; }
  if (!Number.isInteger(n)) { return { parsed: false, raw: s, reason: EXCEPTION_INTEGER_FORMAT }; }
  if (min !== undefined && n < min) { return { parsed: false, raw: s, reason: EXCEPTION_NUMBER_TOO_LOW(min) }; }
  if (max !== undefined && n > max) { return { parsed: false, raw: s, reason: EXCEPTION_NUMBER_TOO_HIGH(max) }; }
  return { parsed: true, raw: s, value: n };
};

const parseString = (s: string,
  { max, min, rejectBecause }: {max?: number, min?: number, rejectBecause?: ((s: string) => string | undefined)} = {}): CellParse<string> => {
  if (max !== undefined && max < s.length) {
    return { parsed: false, raw: s, reason: EXCEPTION_STRING_TOO_LONG(max) };
  } if (min !== undefined && min > s.length) {
    return { parsed: false, raw: s, reason: EXCEPTION_STRING_TOO_SHORT(min) };
  }
  if (rejectBecause) {
    const rejectReason = rejectBecause(s);
    if (rejectReason) {
      return { parsed: false, raw: s, reason: rejectReason };
    }
  }
  return { parsed: true, raw: s, value: s };
};

const verifyProductCategoriesExistAtHospitals = (providedCatIdentifier: string, hospitalIds: number[], productCategories: ProductCategoryStub[]): CellParse<string> => {
  const catIdentifier = providedCatIdentifier.toLowerCase();
  const successParseResult: CellParse<string> = { parsed: true, raw: catIdentifier, value: catIdentifier };
  if (!catIdentifier) {
    return successParseResult;
  }
  const matchesExistForAllHospitals = hospitalIds.filter(hId => productCategories.some(cat =>
    hId && cat.hospitalProductCategoryIdentifier.toLowerCase() === catIdentifier)).length === hospitalIds.length;

  return matchesExistForAllHospitals
    ? successParseResult
    : { parsed: false, raw: catIdentifier, reason: dictionary.CONTRACT_UPLOAD_ERR_PROD_CAT_ID_MISMATCH };
};

const trimObjectValues = (o: Readonly<Record<string, string>>): Record<string, string> => {
  const newObject: Record<string, string> = {};
  Object.keys(o).forEach(k => { newObject[k] = o[k].trim(); });
  return newObject;
};

const mapUndefinedValuesToEmptyString = (p: Readonly<Partial<ContractProductStrings>>): ContractProductStrings => ({
  catalogNumber: p.catalogNumber ?? '',
  description: p.description ?? '',
  price: p.price ?? '',
  unitOfMeasure: p.unitOfMeasure ?? '',
  quantityUnitOfMeasure: p.quantityUnitOfMeasure ?? '',
  brandName: p.brandName ?? '',
  orderIdentifier: p.orderIdentifier ?? '',
  productCategoryIdentifier: p.productCategoryIdentifier ?? '',
  deleteProduct: p.deleteProduct ?? '',
});

export const parseProductsFile = ({ productFileBlob, hospitalIds, productCategories, isEditMode }: ProductPricingsParseInput): ContractProductPricingsParseResult => {
  const columnMapper = isEditMode ?
    (columnName: string) => csvHeadersPlusDelete.find(
      header => header.label.trim() === columnName.trim(),
    )?.key
    :
    (columnName: string) => csvHeaders.find(
      header => header.label.trim() === columnName.trim(),
    )?.key;

  const products: Array<Partial<ContractProductStrings>> = csvParse(productFileBlob, {
    columns: columnNames => columnNames.map(columnMapper),
    relax: true,
    relax_column_count: true,
  });

  const parseResult: ContractProductPricingsParseResult = { successful: [], failedLines: [] };
  products.forEach((product, i, arr) => {
    const row: RowParse = parseRow(product, hospitalIds, productCategories, arr);
    if (product.catalogNumber === '\n') {
      return;
    }
    if (isRowValid(row)) {
      parseResult.successful.push(translateRowParseToContractProduct(row));
    } else {
      const failedLine = insertExceptionMessagesIntoRow({ rowParse: row, exceptions: exceptionList(row) });
      parseResult.failedLines.push(failedLine);
    }
  });
  parseResult.failedLines.sort();
  return parseResult;
};

const isRowValid = (productInfo: RowParse): boolean => Object.values(productInfo).every(x => x?.parsed);

const exceptionList = (productInfo: RowParse): string[] => {
  const stringsAndNulls = Object.entries(productInfo).map(([key, parseInfo]) =>
    (parseInfo?.parsed ? null : `${key === 'catalogNumber' ? 'Catalog Number' : key} ${parseInfo?.reason}`));
  return stringsAndNulls.filter(x => x !== null) as string[];
};

const insertExceptionMessagesIntoRow = ({ rowParse, exceptions }: {rowParse: RowParse, exceptions: string[]}) => {
  const {
    catalogNumber,
    description,
    price,
    unitOfMeasure: UofM,
    quantityUnitOfMeasure: QUofM,
    brandName,
    orderIdentifier,
    productCategoryIdentifier,
    deleteProduct,
  } = rowParse;
  const userEnteredCells = [
    catalogNumber,
    description,
    price,
    UofM,
    QUofM,
    brandName,
    orderIdentifier,
    productCategoryIdentifier,
    deleteProduct,
  ].map(info => rfc4180EncodeCell(info!.raw));
  return `${userEnteredCells.join(',')},${exceptions.join('; ')},`;
};

export const rfc4180EncodeCell = (s: string): string => (s.match(/[\n,"]/) ? `"${s.replace(/"/g, '""')}"` : s);

export const getContractStatusText = (status: ContractStatus): string => {
  switch (status) {
    case ContractStatus.NotYetActive: { return dictionary.CONTRACT_STATUS_NOT_YET_ACTIVE; }
    case ContractStatus.Active: { return dictionary.CONTRACT_STATUS_ACTIVE; }
    case ContractStatus.Expired: { return dictionary.CONTRACT_STATUS_EXPIRED; }
    case ContractStatus.ExpiringSoon: { return dictionary.CONTRACT_STATUS_EXPIRING_SOON; }
    case ContractStatus.Processing: { return dictionary.CONTRACT_STATUS_PROCESSING; }
    case ContractStatus.Loading: { return dictionary.CONTRACT_STATUS_LOADING; }
    default: { return dictionary.EMPTY_FIELD_MARK; }
  }
};
