import { ReactElement, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { departmentColumnProperties, DepartmentForTable } from './departmentsTable.utils';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { routes } from '../../../../Utils/routes';

interface DepartmentsTableProps {
  departments: DepartmentForTable[];
  columnProperties?: ColumnProperty<DepartmentForTable>[];
  isLoading: boolean;
}

export const DepartmentsTable: FunctionComponent<DepartmentsTableProps> = ({
  departments,
  columnProperties = departmentColumnProperties,
  isLoading,
}): ReactElement => {
  const history = useHistory();
  const handleRowClick = (department: DepartmentForTable) => history.push(routes.departments.edit(department.id));

  return (
    <section>
      <SortableTable
        initialColumnSort="hospitalName"
        rowData={departments}
        columns={columnProperties}
        handleRowSelect={handleRowClick}
        noDataMessage={dictionary.DEPARTMENTS_TABLE_EMPTY}
        isLoading={isLoading}
        tableTestId="departments-table"
      />
    </section>
  );
};
