import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

interface UseTimerResult {
  restartTimer: () => void;
}

export const useTimer = (fn: () => any, millisecondsUntilExec: number): UseTimerResult => {
  const [startTime, setStartTime] = useState(DateTime.local());
  const restartTimer = useCallback(() => {
    setStartTime(DateTime.local());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = DateTime.local();
      const millisecondsSinceStart = now.diff(startTime).milliseconds;

      const isPastTime = millisecondsSinceStart >= millisecondsUntilExec;
      if (isPastTime) {
        fn();
      }
    }, 1_000);

    return () => { clearInterval(interval); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  return { restartTimer };
};
