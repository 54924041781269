import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { ProductCategoriesIndex } from './ProductCategoriesIndex/ProductCategoriesIndex';
import { CreateProductCategory } from './CreateProductCategory/CreateProductCategory';
import { EditProductCategory } from './EditProductCategory/EditProductCategory';
import { HospitalAdminRoute } from '../../Shared/ProtectedRoute/ProtectedRoute';

export const ProductCategoriesRoutes: FC = () => (
  <Switch>
    <Route exact path={routes.productCategories.index}>
      <ProductCategoriesIndex />
    </Route>
    <HospitalAdminRoute exact path={routes.productCategories.create}>
      <CreateProductCategory />
    </HospitalAdminRoute>
    <Route exact path={routes.productCategories.editTemplate}>
      <EditProductCategory />
    </Route>
  </Switch>
);
