import { FC } from 'react';
import User, { UserHospitalAssociation } from '../../../../Data/User';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

interface HospitalUsersTableProps {
  users: User[];
  isLoading: boolean;
  onRowClick?: (user: User) => void;
}

const getAlphabetizedHospitalsFromAssociations = (hospitalAssociations: UserHospitalAssociation[]) =>
  hospitalAssociations
    .map(ha => ha.hospitalName)
    .filter((val, idx, self) => self.indexOf(val) === idx) // unique string filter
    .sort()
    .join(', ');

interface TableUser extends User {
  hospitalAssociationsString: string;
}

const defaultColumnDefinitions: ColumnProperty<TableUser>[] = [
  { name: 'firstName', displayName: dictionary.USER_FIRST_NAME_LABEL },
  { name: 'lastName', displayName: dictionary.USER_LAST_NAME_LABEL },
  { name: 'email', displayName: dictionary.USER_EMAIL_LABEL },
  { name: 'phoneNumber', displayName: dictionary.USER_PHONE_LABEL },
  { name: 'hospitalEmployeeId', displayName: dictionary.HOSPITAL_EMPLOYEE_ID_LABEL },
  { name: 'hospitalAssociationsString', displayName: dictionary.USER_HOSPITALS_LABEL },
];

export const HospitalUsersTable: FC<HospitalUsersTableProps> = ({ users, isLoading, onRowClick }) => {
  const tableUsers: TableUser[] = users.map((user) => ({
    ...user,
    hospitalAssociationsString: getAlphabetizedHospitalsFromAssociations(user.hospitalAssociations),
  }));

  return (
    <section>
      <SortableTable
        rowData={tableUsers}
        columns={defaultColumnDefinitions}
        initialColumnSort="firstName"
        noDataMessage={dictionary.HOSPITAL_USERS_TABLE_EMPTY}
        isLoading={isLoading}
        handleRowSelect={onRowClick}
        tableTestId="hospital-users-table"
      />
    </section>
  );
};
