import { FormikProps } from 'formik';
import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useSelector } from 'react-redux';
import { HospitalManufacturerContractProduct } from '../../../../Data/Contract';
import { dictionary } from '../../../../dictionary';
import { currentUserRoleSelector } from '../../../../redux/user/userSelectors';
import { SimpleDisplayField } from '../../../../Shared/fields/SimpleDisplayField';
import { If } from '../../../../Shared/If';
import { toCurrency } from '../../../../Utils/formatNumber';
import { userRoles } from '../../../../Utils/userRoles';
import { ProductInfoFormValues } from './shared/ProductInfo.utils';

interface ProductInfoHospitalProductDetailsProps {
  formikProps: FormikProps<ProductInfoFormValues>;
  activeContractProduct?: HospitalManufacturerContractProduct;
}

export const ProductInfoHospitalProductDetails: FC<ProductInfoHospitalProductDetailsProps> = ({ formikProps, activeContractProduct }) => {
  const { values } = formikProps;
  const currentUserRole = useSelector(currentUserRoleSelector);

  return (
    <>
      <SimpleDisplayField label={dictionary.REQ_PRODUCT_CATALOG_NUM} value={values.hospitalProduct?.catalogNumber} />
      <SimpleDisplayField label={dictionary.REQ_PRODUCT_DESCRIPTION} value={values.hospitalProduct?.description} />
      <SimpleDisplayField label={dictionary.REQ_PRODUCT_PRODUCT_TYPE} value={values.hospitalProduct?.productType} />
      <SimpleDisplayField label={dictionary.REQ_PRODUCT_BRAND} value={values.hospitalProduct?.brandName} />

      <Row>
        <Col xs={6}>
          <SimpleDisplayField label={dictionary.REQ_PRODUCT_UOM_FULL} value={values.hospitalProduct?.unitOfMeasure} />
        </Col>
        <Col xs={6}>
          <SimpleDisplayField label={dictionary.REQ_PRODUCT_QUANTITY_UOM_FULL} value={values.hospitalProduct?.quantityUnitOfMeasure} />
        </Col>
      </Row>

      <If condition={currentUserRole !== userRoles.vendor}>
        <SimpleDisplayField label={dictionary.PRODUCT_ITEM_IDENTIFIER} value={values.hospitalProduct?.orderIdentifier} />
        <SimpleDisplayField label={dictionary.PRODUCT_CATEGORY_NAME_LABEL} value={values.hospitalProduct?.productCategoryName} />
        <SimpleDisplayField label={dictionary.REQ_PRODUCT_CONTRACT_PRICE} value={toCurrency(activeContractProduct?.price)} data-testid="hospital-product-contract-price" />
      </If>
    </>
  );
};
