import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { DepartmentBase } from '../../../../Data/Department';
import { dictionary } from '../../../../dictionary';
import { useModalStyles } from '../../../../Shared/modals/Modal/modal.styles';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { RowAction, ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

interface PhysicianHospitalDetailsTableProps {
  departments: DepartmentBase[];
  onRemoveAssociation: (dept: DepartmentBase) => void;
}

export const EditPhysicianHospitalModalFormTable: FC<PhysicianHospitalDetailsTableProps> = (props) => {
  const { departments, onRemoveAssociation } = props;
  const modalClasses = useModalStyles();

  const rowActions: RowAction<DepartmentBase>[] = [
    {
      name: dictionary.EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_MODAL_TABLE_ROW_REMOVE,
      icon: faTrash,
      onAction: dept => onRemoveAssociation(dept),
      intent: 'danger',
    }];

  const tableColumns: ColumnProperty<DepartmentBase>[] = [
    {
      displayName: 'Department',
      name: 'name',
    },
  ];

  return (
    <SortableTable
      rowData={departments}
      columns={tableColumns}
      initialColumnSort="name"
      isLoading={false}
      noDataMessage={dictionary.EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_MODAL_TABLE_EMPTY}
      rowActions={rowActions}
      className={modalClasses.table}
    />
  );
};
