import { FC } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { dictionary } from '../dictionary';
import useUtilityStyles from '../Themes/utility.styles';

export const ErrorPage: FC = () => {
  const utilityClasses = useUtilityStyles();
  const sectionClasses = `${utilityClasses.h100} ${utilityClasses.displayLineBreak}`;

  return (
    <Row>
      <Col xs={4} className={utilityClasses.ml2}>
        <section data-testid="error-page" className={sectionClasses}>
          <h1>{dictionary.ERROR_PAGE_TITLE}</h1>
          <p>
            {dictionary.ERROR_PAGE_TEXT}
          </p>
        </section>
      </Col>
    </Row>
  );
};
