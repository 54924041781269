import { createUseStyles } from 'react-jss';

export const useReportStyle = createUseStyles({
  reportStyle: {
    height: '80vh',
    position: 'relative',
    top: '-2%',
    left: '-2.5%',
    width: '105%',
  },
});

export default useReportStyle;
