import { FormikProps } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ProductCategoryStub } from '../../../../../Data/ProductCategory';
import { currentUserRoleSelector } from '../../../../../redux/user/userSelectors';
import Autocomplete from '../../../../../Shared/Autocomplete/Autocomplete';
import { SimpleFieldWithLabel } from '../../../../../Shared/fields/SimpleFieldWithLabel';
import { If } from '../../../../../Shared/If';
import { unitOfMeasuresAsOptions } from '../../../../../Utils/Constants';
import { userRoles } from '../../../../../Utils/userRoles';
import { productInfoCreateProductFormFields, ProductInfoCreateProductFormValues } from './productInfoCreateProduct.utils';

interface ProductInfoHospitalProductFieldsProps {
  formikProps: FormikProps<ProductInfoCreateProductFormValues>;
  productCategories: ProductCategoryStub[];
}

export const ProductInfoHospitalProductFields: FC<ProductInfoHospitalProductFieldsProps> = ({ formikProps, productCategories }) => {
  const currentUserRole = useSelector(currentUserRoleSelector);

  return (
    <>
      <SimpleFieldWithLabel form={formikProps} descriptor={productInfoCreateProductFormFields.catalogNumber} />
      <SimpleFieldWithLabel form={formikProps} descriptor={productInfoCreateProductFormFields.description} />
      <SimpleFieldWithLabel form={formikProps} descriptor={productInfoCreateProductFormFields.productType} />
      <SimpleFieldWithLabel form={formikProps} descriptor={productInfoCreateProductFormFields.brand} />
      <Autocomplete descriptor={productInfoCreateProductFormFields.unitOfMeasure} formikProps={formikProps} options={unitOfMeasuresAsOptions} />
      <SimpleFieldWithLabel form={formikProps} descriptor={productInfoCreateProductFormFields.quantityUnitOfMeasure} />
      <If condition={currentUserRole !== userRoles.vendor}>
        <SimpleFieldWithLabel form={formikProps} descriptor={productInfoCreateProductFormFields.orderIdentifier} />
        <Autocomplete
          formikProps={formikProps}
          descriptor={productInfoCreateProductFormFields.productCategory}
          options={productCategories}
          allowClear
          direction="up"
        />
      </If>
    </>
  );
};
