import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { routes } from '../../../Utils/routes';
import { HealthSystem, HealthSystemRequest } from '../../../Data/HealthSystem';
import { healthSystemService } from '../../../Services/HealthSystemService';
import { HealthSystemForm } from '../shared/HealthSystemForm';

const HealthSystemFormWrapper: FC<FormFlowStepComponent<HealthSystemRequest, HealthSystem>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <HealthSystemForm FormActionsComponent={FormActionsComponent} healthSystem={editSource} onFormStepComplete={onFormStepComplete} />;

export const EditHealthSystem: FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const healthSystemId = Number(params.id);
  const [healthSystem, setHealthSystem] = useState<HealthSystem>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const result = await healthSystemService.getHealthSystemById(healthSystemId);
        setHealthSystem(result);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.HEALTH_SYSTEM_DETAILS_LOAD_ERR));
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, healthSystemId]);

  if (loading) {
    return <LoadingSpinner />;
  }
  if (!healthSystem) {
    return <ErrorPage />;
  }

  const onComplete = async (request: HealthSystemRequest) => {
    try {
      await healthSystemService.update(healthSystem.id, request);
      dispatch(addNotification(NotificationType.success, dictionary.HEALTH_SYSTEM_EDIT_SUCCESS));
      history.push(routes.healthSystems.index);
    } catch (e) {
      const exception = e as AxiosError;
      if (exception.response?.status === 409) {
        dispatch(addNotification(NotificationType.error, dictionary.HEALTH_SYSTEM_NOT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.HEALTH_SYSTEM_EDIT_FAILURE));
      }
    }
  };
  return (
    <FormFlow<HealthSystemRequest, HealthSystem>
      editSource={healthSystem}
      steps={[{ pageHeader: dictionary.HEALTH_SYSTEM_EDIT_HEADER, component: HealthSystemFormWrapper }]}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      onCancel={() => { history.push(routes.healthSystems.index); }}
      onComplete={onComplete}
    />
  );
};
