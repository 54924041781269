import { FormikValues } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { FormFlow } from '../../../../Shared/FormFlow/FormFlow';
import { CreateOrEditHospitalUserForm, CreateOrEditVendorUserForm } from '../CreateOrEditUserForm/CreateOrEditUserForm';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../../redux/initialState';
import { dictionary } from '../../../../dictionary';
import { User } from '../../../../Data/User';
import { DeactivateUserPageHeader } from './DeactivateUserPageHeader';
import { PageHeaderProps } from '../../../../Shared/PageHeader/PageHeader';
import { LoadingSpinner } from '../../../../Shared/LoadingSpinner/LoadingSpinner';
import { ErrorPage } from '../../../../Shared/ErrorPage';
import LoadState from '../../../../redux/loadState';
import { fetchSingleHospitalUser, fetchSingleVendorUser } from '../../../../Services/UserService';

export interface CreateOrEditUserProps<T extends any> {
  onDoneRoutePath: string;
  completeButtonLabel: string;
  submitRequest: (request: T) => any;
  pageHeader: string;
  isHospitalUser?: boolean;
}

export const CreateOrEditUser = <T extends Record<string, any>>({
  onDoneRoutePath,
  completeButtonLabel,
  submitRequest,
  pageHeader,
  isHospitalUser = false,
}: CreateOrEditUserProps<T>): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isEdit = Boolean(useParams<{ id: string }>().id);

  const { id: idStr } = useParams<{ id: string }>();
  const id = Number(idStr);
  const [user, setUser] = useState<User>();
  const [userLoadState, setUserLoadState] = useState<LoadState>(LoadState.loading);

  useEffect(() => {
    if (!isEdit) {
      setUserLoadState(LoadState.loaded);
      return;
    }

    (async () => {
      try {
        let fetchedUser;
        if (isHospitalUser) fetchedUser = await fetchSingleHospitalUser(id);
        else fetchedUser = await fetchSingleVendorUser(id);
        if (fetchedUser) {
          setUser(fetchedUser);
          setUserLoadState(LoadState.loaded);
        }
      } catch (e) {
        setUserLoadState(LoadState.error);
      }
    })();
  }, [id, isEdit, isHospitalUser]);

  const onCancel = () => {
    history.push(onDoneRoutePath);
  };

  const onComplete = async (values: FormikValues) => {
    const request = values as T;

    try {
      await submitRequest(request);
      history.push(onDoneRoutePath);
      dispatch(addNotification(NotificationType.success, successMessage));
    } catch (e: any) {
      if (e.response?.status === 409 && !!e?.response?.data?.email) {
        dispatch(addNotification(NotificationType.error, dictionary.EMAIL_NOT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.CREATE_UPDATE_USER_ERROR));
      }
    }
  };

  const successMessage = useMemo(() => {
    if (isEdit) return isHospitalUser ? dictionary.EDIT_HOSPITAL_USER_ACCOUNT_SUCCESS : dictionary.EDIT_VENDOR_USER_ACCOUNT_SUCCESS;
    return isHospitalUser ? dictionary.CREATE_HOSPITAL_USER_ACCOUNT_SUCCESS : dictionary.CREATE_VENDOR_USER_ACCOUNT_SUCCESS;
  }, [isEdit, isHospitalUser]);

  const PageHeaderComponent: FC<PageHeaderProps> = useMemo(() => () =>
    <DeactivateUserPageHeader user={user!} isHospitalUser={!!isHospitalUser} pageHeader={pageHeader} onSuccess={setUser} />,
  [user, isHospitalUser, pageHeader]);

  if (userLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }
  if (userLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  return (
    <FormFlow<User>
      steps={[{
        pageHeader,
        PageHeaderComponent,
        component: isHospitalUser ? CreateOrEditHospitalUserForm : CreateOrEditVendorUserForm,
      }]}
      completeButtonLabel={completeButtonLabel}
      onCancel={onCancel}
      onComplete={onComplete}
      isViewOnly={user?.archived}
    />
  );
};
