export const getCircularNextIdx = (opts: any[], fromIdx: number): number => {
  const nextIdx = (fromIdx + 1) % opts.length;

  return nextIdx;
};

export const getCircularPrevIdx = (opts: any[], fromIdx: number): number => {
  const prevIdx = (((fromIdx - 1) % opts.length) + opts.length) % opts.length;

  return prevIdx;
};
