import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ApiRequisition } from '../../../Data/Requisition';
import { dictionary } from '../../../dictionary';
import useUtilityStyles from '../../../Themes/utility.styles';
import { joinArgs } from '../../../Utils/arrayUtils';
import { ProductsToBill } from '../../Requisitions/shared/ProductsToBill/ProductsToBill';

interface ReqDetailsProductInfoProps {
  req: ApiRequisition;
  hospHasProductCategories: boolean;
}

export const ReqDetailsProductInfo: FC<ReqDetailsProductInfoProps> = ({ req, hospHasProductCategories }) => {
  const utilClasses = useUtilityStyles();

  return (
    <>
      <Row className={utilClasses.my1}>
        <Col xs={12}>
          <div className={joinArgs(utilClasses.pb1, utilClasses.horizontalRuleBorder)}>
            <Row>
              <Col xs={6}>
                <h2 className={utilClasses.mb0}>{dictionary.REQ_PRODUCTS_TO_BILL_LABEL}</h2>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <ProductsToBill requisition={req} hospHasProductCategories={hospHasProductCategories} />
        </Col>
      </Row>
    </>
  );
};
