import { HospitalBase } from './Hospital';
import { ManufacturerBase } from './Manufacturer';
import { UnitOfMeasure } from '../Utils/Constants';
import { getIsoFormattedDate } from '../Utils/dateUtils';
import { HospitalManufacturerContractCapitation } from './Capitation';
import { HealthSystem } from './HealthSystem';

export enum ContractStatus {
  NotYetActive,
  Active,
  Expired,
  ExpiringSoon,
  Processing,
  Loading
}

interface BaseContract {
  contractIdentifier: string;
  healthSystem: HealthSystem;
  healthSystemId?: number;
  hospitals: HospitalBase[];
  expirationDate: string;
  effectiveDate: string;
  manufacturer?: ManufacturerBase;
  manufacturerIdentifier?: string;
  wastedDiscount?: number;
  explantDiscount?: number;
  trialDiscount?: number;
  products?: ContractProduct[];
  errorFailedLines?: string[];
  manufacturerId?: number;
}

export interface ContractStub {
  id: number;
  contractIdentifier: string;
  healthSystem: HealthSystem;
  healthSystemName: string;
  hospitals: HospitalBase[];
  expirationDate: string;
  effectiveDate: string;
  manufacturer: ManufacturerBase;
  manufacturerIdentifier: string;
  wastedDiscount: number;
  explantDiscount: number;
  trialDiscount: number;
  numberOfProducts: number;
  status: ContractStatus;
}

export interface Contract extends BaseContract {
  id: number;
  status?: ContractStatus;
  canCurrentUserEdit?: boolean;
}

export interface ValidateContractRequestNew {
  healthSystemId: number;
  contractIdentifier: string;
  manufacturerId: number;
}

export interface ContractIdOptional extends BaseContract {
  id?: number
}

export interface ContractIdentifierUniquenessError {
  hospitalNames: string[];
}

export type ContractUpdateRequest = Omit<Contract, 'healthSystem' | 'hospitals' | 'products' | 'manufacturer' | 'status'>;
export interface ContractProduct {
  deleteProduct?: string;
  cleanCatalogNumber: string;
  catalogNumber: string;
  description: string;
  price: number;
  unitOfMeasure: UnitOfMeasure;
  quantityUnitOfMeasure: number;
  brandName: string;
  orderIdentifier: string;
  productCategoryId?: number;
  productCategoryIdentifier?: string;
  categoryName?: string;
  hospitalProductId: number;
  hospitalId?: number;
  hospitalName?: string;
}

export type ContractProductCSV = Omit<ContractProduct, 'hospitalProductId' | 'categoryName' | 'productCategoryId' | 'hospitalId' | 'hospitalName'>;

export type ContractProductStrings = Record<keyof Omit<ContractProductCSV, 'cleanCatalogNumber'>, string>;

export type ContractForPost = Pick<
BaseContract,
'contractIdentifier' |
'products' |
'trialDiscount' |
'explantDiscount' |
'wastedDiscount' |
'expirationDate' |
'effectiveDate' |
'manufacturerIdentifier'
> & {
  healthSystemId?: HealthSystem['id'];
  hospitalIds?: Array<HospitalBase['id']>;
  manufacturerId?: ManufacturerBase['id'];
};

export type ContractUpdateProductsRequest = ContractForPost & { id: number };

// For POST contract
export const buildCreateContractRequest = (contract: Contract): ContractForPost => ({
  contractIdentifier: contract.contractIdentifier,
  healthSystemId: contract.healthSystemId,
  hospitalIds: contract.hospitals?.map(hospital => hospital.id),
  manufacturerId: contract.manufacturer?.id,
  manufacturerIdentifier: contract.manufacturerIdentifier,
  expirationDate: getIsoFormattedDate(contract.expirationDate),
  effectiveDate: getIsoFormattedDate(contract.effectiveDate),
  wastedDiscount: contract.wastedDiscount,
  explantDiscount: contract.explantDiscount,
  trialDiscount: contract.trialDiscount,
  products: contract.products,
});

export const buildContractUpdateProductsRequest = (contract: Contract): ContractUpdateProductsRequest => ({
  contractIdentifier: contract.contractIdentifier,
  hospitalIds: contract.hospitals?.map(hospital => hospital.id),
  manufacturerId: contract.manufacturer?.id,
  manufacturerIdentifier: contract.manufacturerIdentifier,
  expirationDate: getIsoFormattedDate(contract.expirationDate),
  effectiveDate: getIsoFormattedDate(contract.effectiveDate),
  wastedDiscount: contract.wastedDiscount,
  explantDiscount: contract.explantDiscount,
  trialDiscount: contract.trialDiscount,
  products: contract.products,
  id: contract.id,
});

export interface HospitalManufacturerContractProduct {
  id: number;
  cleanCatalogNumber: string;
  catalogNumber: string;
  description: string;
  price: number;
  unitOfMeasure: UnitOfMeasure;
  quantityUnitOfMeasure: number;
  brandName: string;
  orderIdentifier: string;
}

export interface HospitalManufacturerContract {
  id: number;
  contractIdentifier: string;
  manufacturerId: number;
  effectiveDate: string;
  expirationDate: string;
  wastedDiscount: number;
  explantDiscount: number;
  trialDiscount: number;
  hospitalId: number;
  products: HospitalManufacturerContractProduct[];
  capitations: HospitalManufacturerContractCapitation[];
}

export interface HealthSystemManufacturerContract {
  id: number;
  contractIdentifier: string;
  manufacturerId: number;
  effectiveDate: string;
  expirationDate: string;
  wastedDiscount: number;
  explantDiscount: number;
  trialDiscount: number;
  healthSystemId: number;
  products: HospitalManufacturerContractProduct[];
  capitations: HospitalManufacturerContractCapitation[];
}

export type ContractForDisplay = Pick<Contract, 'id' | 'contractIdentifier'> & {
  expirationDate: string;
  healthSystemName: string;
  manufacturerName: string;
  statusText: string;
  numberOfProducts: number;
};
