import { FormikErrors, FormikValues } from 'formik';
import Capitation from '../../../Data/Capitation';
import { CapitationCategoryBase } from '../../../Data/CapitationCategory';
import { Contract } from '../../../Data/Contract';
import { dictionary } from '../../../dictionary';
import { createDescriptor, getFieldDescriptorMap, getFormikInitialValues } from '../../../Utils/formik.utils';
import { validateCharacterLimit, validateCurrency, validateNumberInRangeInclusive, validateRequiredFields } from '../../../Utils/validationUtils';

export interface CapitationFormFields {
  contract: Contract,
  name: string,
  price: string,
  orderIdentifier?: string,
  category?: CapitationCategoryBase,
}

export const capitationFormFields = getFieldDescriptorMap<CapitationFormFields>([
  createDescriptor({ name: 'contract', label: dictionary.CAPITATION_CONTRACT_ID_LABEL, required: true }),
  createDescriptor({ name: 'name', label: dictionary.CAPITATION_NAME_SHORT_LABEL, required: true }),
  createDescriptor({ name: 'price', label: dictionary.CAPITATION_PRICE_SHORT_LABEL, required: true }),
  createDescriptor({ name: 'orderIdentifier', label: dictionary.CAPITATION_ITEM_ID_LABEL }),
  createDescriptor({ name: 'category', label: dictionary.CAPITATION_CATEGORY_LABEL }),
]);

export const getCapitationFormInitialVals = (contract?: Contract, capitation?: Capitation, category?: CapitationCategoryBase): CapitationFormFields =>
  getFormikInitialValues<CapitationFormFields>(
    capitationFormFields,
    {
      contract,
      name: capitation?.name,
      price: capitation?.price.toFixed(2),
      orderIdentifier: capitation?.orderIdentifier || undefined,
      category,
    }
  );

export const validateCapitationForm = async (values: FormikValues, contractOptions: Contract[]): Promise<FormikErrors<FormikValues>> => {
  const { contract } = values;
  const contractChoiceErrMsg: string | undefined = (!contract || !contract.id || !contractOptions.filter((cOpt: Contract) => cOpt.id === contract.id))
    ? dictionary.CAPITATION_NO_VALID_CONTRACT_CHOSEN_ERR
    : undefined;

  const errors: FormikErrors<FormikValues> = {
    ...validateCharacterLimit(values, [capitationFormFields.name], 300),
    ...validateCharacterLimit(values, [capitationFormFields.orderIdentifier], 100),
    ...validateCurrency(values, [capitationFormFields.price]),
    ...validateNumberInRangeInclusive(values, [capitationFormFields.price], 0, 100_000_000),
    ...validateRequiredFields(values, Object.values(capitationFormFields)),
  };
  if (contractChoiceErrMsg) {
    errors[capitationFormFields.contract.name] = contractChoiceErrMsg;
  }

  return errors;
};
