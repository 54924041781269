import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { dictionary } from '../../../../dictionary';
import { SimpleFieldWithLabel } from '../../../../Shared/fields/SimpleFieldWithLabel';
import { Button } from '../../../../Shared/buttons/Button';
import { SimpleDisplayField } from '../../../../Shared/fields/SimpleDisplayField';
import useUtilityStyles from '../../../../Themes/utility.styles';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { validateCreatePatient } from './createPatient.validation';
import { CreatePatientFields } from './createPatient.types';
import Patient, { PatientSearchRequest } from '../../../../Data/Patient';
import { postPatient, searchForPatient } from '../../../../Services/PatientService';
import { casePatientInfoStyles } from '../casePatientInfo.styles';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../../redux/initialState';
import { LoadingSpinner } from '../../../../Shared/LoadingSpinner/LoadingSpinner';
import { createPatientFieldDescriptors } from './createPatient.utils';

interface CreatePatientFormProps {
  hospitalId: number;
  mrn: string;
  onPatientCreated: (patient: Patient) => void;
  onCancel: () => void;
  needsExplicitValidation: boolean;
}

export const CreatePatientForm: FC<CreatePatientFormProps> = ({ hospitalId, mrn, onPatientCreated, onCancel, needsExplicitValidation }) => {
  const casePatientClasses = casePatientInfoStyles();
  const utilClasses = useUtilityStyles();
  const dispatch = useDispatch();

  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [duplicatePatient, setDuplicatePatient] = useState<Patient | null>(null);
  const formik = useFormik<CreatePatientFields>({
    initialValues: { firstName: '', lastName: '' },
    validate: validateCreatePatient,
    onSubmit: async ({ firstName, lastName }) => {
      setSubmitInProgress(true);
      try {
        const createdPatient = await postPatient({ hospitalId, mrn, firstName, lastName });
        setSubmitInProgress(false);
        dispatch(addNotification(NotificationType.success, dictionary.REQ_CASE_PATIENT_CREATE_PATIENT_SUCCESS));

        onPatientCreated(createdPatient);
      } catch (exception) {
        setSubmitInProgress(false);
        if (exception.message.includes('409')) {
          setIsDuplicate(true);
          onDuplicateFound();
        } else {
          dispatch(addNotification(NotificationType.error, dictionary.REQ_CASE_PATIENT_CREATE_PATIENT_FAILED_ERR));
        }
      }
    },
  });

  const onDuplicateFound = async () => {
    const searchRequest: PatientSearchRequest = {
      mrn,
      hospitalId,
    };

    try {
      const fetchedPatient = await searchForPatient(searchRequest);
      setDuplicatePatient(fetchedPatient);
    } catch (exception) {
      console.error(exception);
    }
  };

  const onUseExistingPatient = () => {
    onPatientCreated(duplicatePatient!);
  };

  useEffect(() => {
    if (!needsExplicitValidation) {
      return;
    }

    formik.setFieldTouched('firstName');
    formik.setFieldTouched('lastName');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsExplicitValidation]);

  // TODO: Evaluate whether this function should just live in SimpleFieldWithLabel as default behavior
  const onFieldKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      formik.submitForm();
    }
  };

  const submitButtonIconContent = submitInProgress ? <LoadingSpinner /> : <FontAwesomeIcon icon={faPlus} />;

  return (
    <div className={joinArgs(casePatientClasses.patientInfo, isDuplicate ? utilClasses.errorBorder : '')}>
      <div className={joinArgs(utilClasses.flex, utilClasses.spaceBetween, utilClasses.alignCenter, isDuplicate ? utilClasses.textRed : '')}>
        <h3>{dictionary.REQ_CASE_PATIENT_CREATE_PATIENT_HEADER}</h3>
        <Button onClick={onCancel} buttonStyle="reverse">{dictionary.CANCEL}</Button>
      </div>

      {!isDuplicate
        ? (
          <>
            <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_PATIENT_MRN} value={mrn} />
            <SimpleFieldWithLabel form={formik} descriptor={createPatientFieldDescriptors.firstName} onKeyPress={onFieldKeyPress} />
            <SimpleFieldWithLabel form={formik} descriptor={createPatientFieldDescriptors.lastName} onKeyPress={onFieldKeyPress} />
            <div className={joinArgs(utilClasses.flex, utilClasses.flexEnd)}>
              <Button
                buttonStyle="primary"
                leadingIcon={submitButtonIconContent}
                className={joinArgs(utilClasses.mt1, utilClasses.mb1)}
                onClick={formik.submitForm}
                disabled={submitInProgress}
              >
                {dictionary.REQ_CASE_PATIENT_CREATE_PATIENT_BUTTON}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={utilClasses.textRed}>
              <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_PATIENT_MRN} value={mrn} />
              <div>{ dictionary.REQ_CASE_PATIENT_ERR_MRN_DUPLICATE }</div>
            </div>

            <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_FIRST_NAME} value={duplicatePatient?.firstName} />
            <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_LAST_NAME} value={duplicatePatient?.lastName} />

            <div className={joinArgs(utilClasses.flex, utilClasses.flexEnd)}>
              <Button buttonStyle="primary" className={joinArgs(utilClasses.mt1, utilClasses.mb1)} onClick={onUseExistingPatient}>
                {dictionary.REQ_CASE_PATIENT_USE_EXISTING_PATIENT_BUTTON}
              </Button>
            </div>
          </>
        )}
    </div>
  );
};
