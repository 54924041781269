let id = 0;
let minBeenSet = false;
export const getNextId = (minimum?: number | undefined): number => {
  if (minimum && !minBeenSet) {
    id = minimum;
    minBeenSet = true;
  }
  id += 1;
  return id;
};
