import { ApiRequisition, MergedRequisition, RequisitionCapitation } from '../Data/Requisition';
import RequisitionProduct from '../Data/RequisitionProduct';
import { toCurrency } from './formatNumber';

const calcProductTotal = (product: RequisitionProduct) => product.price! * product.quantity! * (1 - (product.discount ?? 0) / 100);

export const calculateGrandProductTotalDisplay = (req: ApiRequisition): string => {
  if (!req) {
    return toCurrency(0);
  }

  const productsTotal = req.products?.reduce((acc: number, product: RequisitionProduct) => acc + calcProductTotal(product), 0) || 0;
  return toCurrency(productsTotal);
};

export const calculateGrandTotalDisplay = (req: MergedRequisition): string => {
  if (!req) {
    return toCurrency(0);
  }

  const productsTotal = req.products?.reduce((acc: number, product: RequisitionProduct) => acc + calcProductTotal(product), 0) || 0;

  const capitationTotal = req.capitations ? req.capitations.reduce((acc: number, capitation: RequisitionCapitation) => acc + capitation.price, 0) : 0;

  return toCurrency(productsTotal + capitationTotal);
};
