import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CreateCostCenterRequest } from '../../../Data/CostCenter';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { costCenterService } from '../../../Services/CostCenterService';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { routes } from '../../../Utils/routes';
import { CostCenterForm } from '../shared/CostCenterForm';

const CostCenterFormWrapper: FC<FormFlowStepComponent<CreateCostCenterRequest>> = ({ FormActionsComponent, onFormStepComplete }) =>
  <CostCenterForm FormActionsComponent={FormActionsComponent} onFormStepComplete={onFormStepComplete} />;

export const CreateCostCenter: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onCancel = () => history.push(routes.costCenters.index);
  const onComplete = async (request: CreateCostCenterRequest) => {
    try {
      await costCenterService.create(request);
      dispatch(addNotification(NotificationType.success, dictionary.CREATE_COST_CENTER_SUCCESS));
      history.push(routes.costCenters.index);
    } catch (exception: any) {
      if (exception?.response?.status === 409 && exception?.response?.data?.data) {
        dispatch(addNotification(NotificationType.error, dictionary.COST_CENTER_IDENTIFIER_NOT_UNIQUE_ERROR));
      } else if (exception?.response?.status === 409) {
        dispatch(addNotification(NotificationType.error, dictionary.COST_CENTER_NOT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.CREATE_COST_CENTER_ERROR));
      }
    }
  };

  return (
    <FormFlow<CreateCostCenterRequest>
      onComplete={onComplete}
      onCancel={onCancel}
      completeButtonLabel={dictionary.CREATE_COST_CENTER_SUBMIT_BTN}
      steps={[
        { pageHeader: dictionary.CREATE_COST_CENTER_PAGE_HEADER, component: CostCenterFormWrapper },
      ]}
    />
  );
};
