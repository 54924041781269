import { FC, ReactNode } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useUtilityStyles } from '../../Themes/utility.styles';
import { TabInfo, Tabs } from '../Tabs/Tabs';
import { If } from '../If';

import { joinArgs } from '../../Utils/arrayUtils';

export interface SubHeaderProps {
  title: string;
  children?: ReactNode;
  tabs?: TabInfo[];
}

export const SubHeader: FC<SubHeaderProps> = (props) => {
  const { title, tabs = [] } = props;
  const utilClasses = useUtilityStyles();

  return (
    <header className={utilClasses.mt1}>
      <hr className={joinArgs(utilClasses.mt1, tabs.length ? utilClasses.mb0 : utilClasses.mb1)} />
      <Row>
        <Col xs={tabs.length ? 6 : 12}>
          <h2 data-testid="sub-header" className={utilClasses.mt1}>{title}</h2>
        </Col>
        <If condition={!!tabs.length}>
          <Col xs={6} className={joinArgs(utilClasses.flex, utilClasses.flexEnd)}>
            <Tabs tabs={tabs} />
          </Col>
        </If>
      </Row>
    </header>
  );
};
