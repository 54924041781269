import { FC } from 'react';
import { useSelector } from 'react-redux';
import { dictionary } from '../../../../dictionary';
import { routes } from '../../../../Utils/routes';
import { EditHospitalUserRequest } from '../../../../Data/User';
import { CreateOrEditUser } from '../../shared/CreateOrEditUser/CreateOrEditUser';
import { editHospitalUser } from '../../../../Services/UserService';
import { PermissionTo } from '../../../../Utils/permissionChecker';
import { currentUserSelector } from '../../../../redux/user/userSelectors';

export const EditHospitalUser: FC = () => {
  const currentUser = useSelector(currentUserSelector);
  const allowEdits = PermissionTo.createAHospitalUser.isGrantedTo(currentUser);
  const pageHeader = allowEdits ? dictionary.EDIT_HOSPITAL_USER_ACCOUNT : dictionary.VIEW_HOSPITAL_USER_ACCOUNT;

  return (
    <CreateOrEditUser<EditHospitalUserRequest>
      pageHeader={pageHeader}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      onDoneRoutePath={routes.users.hospitals.index}
      submitRequest={editHospitalUser}
      isHospitalUser
    />
  );
};
