import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Col, Row } from 'react-flexbox-grid';
import { dictionary } from '../../../../dictionary';
import { ReduxState, NotificationType } from '../../../../redux/initialState';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { csvHeaders } from '../../../../Utils/contracts/contractUtils';
import { joinArgs } from '../../../../Utils/arrayUtils';
import utilityClasses from '../../../../Themes/utility.styles';
import { useButtonStyles } from '../../../../Themes/button.styles';
import { Contract } from '../../../../Data/Contract';
import { FormFlowStepComponent } from '../../../../Shared/FormFlow/FormFlow';
import { ExceptionDownloadButton } from './ExceptionDownloadButton';
import { useProductUploadCounts } from './useProductUploadCount';
import { UploadContractInput } from './UploadContractInput/UploadContractInput';
import { If } from '../../../../Shared/If';

export const ProductPricingInfo: FC<FormFlowStepComponent<Contract>> = ({ onFormStepComplete, FormActionsComponent }) => {
  const dispatch = useDispatch();
  const contract = useSelector((state: ReduxState) => state.contract.newContract);
  const utilClasses = utilityClasses();
  const buttonClasses = useButtonStyles();
  const { successes: productSuccessCount, errors: productErrorCount } = useProductUploadCounts();

  const onNextStepClicked = () => {
    if (productSuccessCount) {
      onFormStepComplete(contract);
    } else {
      dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_HAS_NO_PRODUCTS_ERROR_MESSAGE));
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} md={8} lg={6}>
          <div className={joinArgs(utilClasses.p1, utilClasses.backgroundNearWhite)}>
            <h4 className={joinArgs(utilClasses.m0, utilClasses.mb1)}>
              {dictionary.CONTRACT_TEMPLATE_BOX_LABEL}
            </h4>
            <p>{dictionary.CONTRACT_TEMPLATE_BOX_INSTRUCTIONS}</p>
            <div className={joinArgs(utilClasses.flex, utilClasses.flexWrap, utilClasses.alignCenter)}>
              <UploadContractInput />

              <div>
                <CSVLink
                  data={[]}
                  headers={csvHeaders}
                  filename="contractProductPricingTemplate.csv"
                  className={joinArgs(buttonClasses.base, buttonClasses.leadingIcon, buttonClasses.reverse, utilClasses.mb1)}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                  {dictionary.CONTRACT_TEMPLATE_DOWNLOAD}
                </CSVLink>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <If condition={!!productSuccessCount}>
        <div className={joinArgs(utilClasses.fs18, utilClasses.fwBold, utilClasses.pt1, utilClasses.textTookishGreen)} data-testid="product-upload-success-msg">
          <FontAwesomeIcon icon={faCheckCircle} size="lg" className={utilClasses.pr05} />
          {productSuccessCount}
          {' '}
          {dictionary.CONTRACT_PRODUCTS_SUCCESSFULLY_ADDED_MESSAGE}
        </div>
      </If>

      <If condition={!!productErrorCount}>
        <div className={joinArgs(utilClasses.fs18, utilClasses.fwBold, utilClasses.pt1, utilClasses.textRed)} data-testid="product-upload-failure-msg">
          {productErrorCount}
          {' '}
          {dictionary.CONTRACT_PRODUCTS_EXCEPTIONS_INSTRUCTIONS}
        </div>
        <ExceptionDownloadButton lines={contract.errorFailedLines ?? []} />
      </If>

      <Col xs={12} md={8} lg={6}>
        <FormActionsComponent onSubmitAction={onNextStepClicked} />
      </Col>
    </>
  );
};
