import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Physician } from '../../../Data/Physician';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { physicianService } from '../../../Services/PhysicianService';
import { ArchiveButton } from '../../../Shared/buttons/ArchiveButton';
import { createArchiveModalComponent } from '../../../Shared/modals/ArchiveModal';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';

interface ArchivePhysicianPageHeaderProps {
  physician: Physician;
  onSuccess: (updatedPhysician: Physician) => void;
}

export const ArchivePhysicianPageHeader: FC<ArchivePhysicianPageHeaderProps> = ({ physician, onSuccess }) => {
  const dispatch = useDispatch();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const toggleArchived = useCallback(async () => {
    const physicianRequest = {
      ...physician,
      hospitals: physician.hospitals.map(h => ({ hospitalId: h.id, hospitalPhysicianIdentifier: h.physicianIdentifier, departmentIds: h.departments.map(d => d.id) })),
      archived: !physician?.archived,
    };

    try {
      await physicianService.update(physician.id, physicianRequest);
      if (!physician?.archived) dispatch(addNotification(NotificationType.success, dictionary.ARCHIVE_PHYSICIAN_SUCCESS));
      else dispatch(addNotification(NotificationType.success, dictionary.ACTIVATE_PHYSICIAN_SUCCESS));

      onSuccess({ ...physician!, archived: physicianRequest.archived });
    } catch (e) {
      dispatch(addNotification(NotificationType.error, dictionary.ARCHIVE_PHYSICIAN_ERROR));
    }
  }, [physician, dispatch, onSuccess]);

  const openArchiveModal = () => setIsArchiveModalOpen(true);
  const closeArchiveModal = () => setIsArchiveModalOpen(false);

  const toggleArchivedAndCloseModal = useCallback(() => {
    toggleArchived();
    closeArchiveModal();
  }, [toggleArchived]);

  const archiveBtnText = useMemo(() => (physician?.archived ? dictionary.ACTIVATE_PHYSICIAN_BTN : dictionary.ARCHIVE_PHYSICIAN_BTN), [physician?.archived]);
  const archiveBtnOnClick = useMemo(() => (physician?.archived ? toggleArchivedAndCloseModal : openArchiveModal), [physician?.archived, toggleArchivedAndCloseModal]);
  const ArchivePhysicianModal = useMemo(() => createArchiveModalComponent(dictionary.ARCHIVE_PHYSICIAN_MODAL_HEADER, dictionary.ARCHIVE_PHYSICIAN_MODAL_TEXT), []);

  return (
    <>
      <PageHeader title={dictionary.EDIT_PHYSICIAN_PAGE_HEADER}>
        <ArchiveButton archived={!!physician?.archived} onClick={archiveBtnOnClick}>{archiveBtnText}</ArchiveButton>
      </PageHeader>
      <ArchivePhysicianModal isOpen={isArchiveModalOpen} onConfirm={toggleArchivedAndCloseModal} onDismiss={closeArchiveModal} />
    </>
  );
};
