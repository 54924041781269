import { FormikErrors, FormikValues } from 'formik';
import { UpdateProcedureRequest } from '../../../Data/Procedure';
import { dictionary } from '../../../dictionary';
import { getFieldDescriptorMap, createDescriptor } from '../../../Utils/formik.utils';
import { validateCharacterLimit, validateRequiredFields } from '../../../Utils/validationUtils';

export interface EditProcedureFormFields {
  name: string;
}

export const editProcedureFormFields = getFieldDescriptorMap<EditProcedureFormFields>([
  createDescriptor({ name: 'name', label: dictionary.CREATE_EDIT_PROCEDURE_NAME, required: true }),
]);

export const getEditProcedureFormInitialValues = (procedure: EditProcedureFormFields): EditProcedureFormFields => ({
  name: procedure.name,
});

export const getUpdateProcedureRequestFromFormFields = (values: EditProcedureFormFields): UpdateProcedureRequest =>
  ({
    name: values.name,
  });

export const validateEditProcedureForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { name } = editProcedureFormFields;

  return {
    ...validateCharacterLimit(values, [name], 255),
    ...validateRequiredFields(values, Object.values(editProcedureFormFields)),
  };
};
