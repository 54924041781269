export const routes = Object.freeze({
  login: '/login',
  devTools: Object.freeze({
    index: '/devtools',
  }),
  adminDashboard: Object.freeze({
    index: '/admin',
  }),
  settings: Object.freeze({
    index: '/settings',
  }),
  businessRules: Object.freeze({
    index: '/business-rules',
    detailsTemplate: '/business-rules/:id',
    details: (id: number) => `/business-rules/${id}`,
    create: '/business-rules/create',
    editTemplate: '/business-rules/:id/edit',
    edit: (id: number) => `/business-rules/${id}/edit`,
  }),
  contracts: Object.freeze({
    index: '/contracts',
    detailsTemplate: '/contracts/:id',
    details: (id: number) => `/contracts/${id}`,
    create: '/contracts/create/',
    editTemplate: '/contracts/:id/edit',
    edit: (id: number) => `/contracts/${id}/edit`,
    updateProductsTemplate: '/contracts/:id/update-products',
    updateProducts: (id: number) => `/contracts/${id}/update-products`,
  }),
  capitations: Object.freeze({
    index: '/capitations',
    new: '/capitations/create/',
    editTemplate: '/capitations/:id/edit',
    edit: (id: number) => `/capitations/${id}/edit`,
  }),
  capitationCategories: Object.freeze({
    index: '/capitation-categories',
    create: '/capitation-categories/create',
    edit: (id: number) => `/capitation-categories/${id}/edit`,
    editTemplate: '/capitation-categories/:id/edit',
  }),
  costCenters: Object.freeze({
    index: '/cost-centers',
    create: '/cost-centers/create',
    editTemplate: '/cost-centers/:id/edit',
    edit: (id: number) => `/cost-centers/${id}/edit`,
  }),
  users: Object.freeze({
    index: '/users',
    vendors: Object.freeze({
      index: '/users/vendors',
      editTemplate: '/users/vendors/:id/edit',
      edit: (id: number) => `/users/vendors/${id}/edit`,
      create: '/users/vendors/create',
    }),
    hospitals: Object.freeze({
      index: '/users/hospital-users',
      editTemplate: '/users/hospital-users/:id/edit',
      edit: (id: number) => `/users/hospital-users/${id}/edit`,
      create: '/users/hospital-users/create',
    }),
  }),
  approvalRoutings: Object.freeze({
    index: '/approval-routings',
    new: '/approval-routings/create',
    editTemplate: '/approval-routings/:id/edit',
    edit: (id: number) => `/approval-routings/${id}/edit`,
  }),
  archivedRequisitions: Object.freeze({
    index: '/archived-requisitions',
    detailTemplate: '/archived-requisitions/:id',
    detail: (id: number) => `/archived-requisitions/${id}`,
  }),
  requisitions: Object.freeze({
    index: '/requisitions',
    detailTemplate: '/requisitions/:id',
    detail: (id: number) => `/requisitions/${id}`,
    editTemplate: '/requisitions/:id/edit',
    edit: (id: number) => `/requisitions/${id}/edit`,
    editProductsTemplate: '/requisitions/:id/edit-products',
    editProducts: (id: number) => `/requisitions/${id}/edit-products`,
    new: '/requisitions/create',
  }),
  healthSystems: Object.freeze({
    index: '/health-systems',
    create: '/health-systems/create',
    editTemplate: '/health-systems/:id/edit',
    edit: (id: number) => `health-systems/${id}/edit`,
  }),
  hospitals: Object.freeze({
    index: '/hospitals',
    create: '/hospitals/create',
    editTemplate: '/hospitals/:id/edit',
    edit: (id: number) => `/hospitals/${id}/edit`,
  }),
  departments: Object.freeze({
    index: '/departments',
    create: '/departments/create',
    editTemplate: '/departments/:id/edit',
    edit: (id: number) => `/departments/${id}/edit`,
  }),
  procedures: Object.freeze({
    index: '/procedures',
    create: '/procedures/create',
    editTemplate: '/procedures/:id/edit',
    edit: (id: number) => `/procedures/${id}/edit`,
  }),
  manufacturers: Object.freeze({
    index: '/manufacturers',
    create: '/manufacturers/create',
    editTemplate: '/manufacturers/:id/edit',
    edit: (id: number) => `/manufacturers/${id}/edit`,
  }),
  physicians: Object.freeze({
    index: '/physicians',
    create: '/physicians/create',
    editTemplate: '/physicians/:id/edit',
    edit: (id: number) => `/physicians/${id}/edit`,
  }),
  productCategories: Object.freeze({
    index: '/product-categories',
    create: '/product-categories/create',
    editTemplate: '/product-categories/:id/edit',
    edit: (id: number) => `/product-categories/${id}/edit`,
  }),
  hospitalManufacturerIdentifiers: Object.freeze({
    index: '/hospital-manufacturer-ids',
    create: '/hospital-manufacturer-ids/create',
    editTemplate: '/hospital-manufacturer-ids/:id/edit',
    edit: (id: number) => `/hospital-manufacturer-ids/${id}/edit`,
  }),
  helpCenter: Object.freeze({
    index: '/help-center',
    termsOfUse: '/help-center/terms-of-use',
    editTermsOfUse: '/help-center/edit-terms-of-use',
  }),
  products: Object.freeze({
    index: '/products',
    detailsTemplate: '/products/:id',
    details: (id: number) => `/products/${id}`,
    editTemplate: '/products/:id/edit',
    edit: (id: number) => `/products/${id}/edit`,
  }),
  interfaces: Object.freeze({
    index: '/interface',
  }),
  reports: Object.freeze({
    index: '/reports',
    detailsTemplate: '/report/:reportId',
    details: (reportId: string) => `/report/${reportId}`,
  }),
});
