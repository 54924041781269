import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { FormikValues, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { CreateProcedureRequest } from '../../../Data/Procedure';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { getFormikInitialValues } from '../../../Utils/formik.utils';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { CreateProcedureFormFields, createProcedureFormFields, validateCreateProcedureForm } from './createProcedure.utils';
import { NotificationType } from '../../../redux/initialState';
import Hospital, { HospitalBase } from '../../../Data/Hospital';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { dictionary } from '../../../dictionary';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';
import { hospitalService } from '../../../Services/HospitalService';

export const CreateProcedureForm: FC<FormFlowStepComponent<CreateProcedureRequest>> = (props) => {
  const { FormActionsComponent, onFormStepComplete } = props;
  const dispatch = useDispatch();
  const [hospitalBases, setHospitalBases] = useState<HospitalBase[]>([]);
  const [selectedHospital, setSelectedHospital] = useState<Hospital>();

  useEffect(() => {
    (async () => {
      if (!hospitalBases.length) {
        try {
          const hospitalBasesResult = await hospitalService.getAdminHospitals();
          setHospitalBases(hospitalBasesResult);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospitalBases.length]);

  const formikProps = useFormik({
    initialValues: getFormikInitialValues<CreateProcedureFormFields>(createProcedureFormFields),
    validate: validateCreateProcedureForm,
    onSubmit: (formValues: FormikValues) => {
      onFormStepComplete({
        name: formValues.name,
        departmentId: formValues.department.id,
      });
    },
  });

  const { hospital, department } = formikProps.values;

  useEffect(() => {
    (async () => {
      if (hospital) {
        setSelectedHospital(undefined);
        try {
          const chosenHospital = await hospitalService.getHospitalById(hospital.id);
          setSelectedHospital(chosenHospital);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospital, department]);

  const sortedHospitals = hospitalBases.sort(alphanumericSortDescriptor('name', 1));
  const sortedDepartments = selectedHospital?.departments.sort(alphanumericSortDescriptor('name', 1)) || [];

  const onHospitalChange = (hosp: HospitalBase | null) => formikProps.setValues({ hospital: hosp ?? undefined, department: undefined, name: undefined });

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={4}>
          <Autocomplete
            descriptor={createProcedureFormFields.hospital}
            formikProps={formikProps}
            options={sortedHospitals}
            disabled={!hospitalBases.length}
            onChange={onHospitalChange}
          />
          <Autocomplete descriptor={createProcedureFormFields.department} formikProps={formikProps} options={sortedDepartments} disabled={!selectedHospital} />
          <SimpleFieldWithLabel form={formikProps} descriptor={createProcedureFormFields.name} disabled={!department} />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
