import { ReactElement, FunctionComponent } from 'react';
import { SortableTable } from '../../../Shared/SortableTable/SortableTable';
import { ApprovalRoutingForTable } from '../../../Data/ApprovalRouting';
import { dictionary } from '../../../dictionary';
import { ColumnProperty } from '../../../Shared/SortableTable/SortableTable.types';

type ApprovalRoutingsTableProps = {
  capitations: ApprovalRoutingForTable[],
  columnProperties?: ColumnProperty<ApprovalRoutingForTable>[],
  handleRowClick?: (capitation: ApprovalRoutingForTable) => void,
  isLoading: boolean,
};

const defaultColumnProperties: ColumnProperty<ApprovalRoutingForTable>[] = [
  { displayName: 'Hospital', name: 'hospitalName' },
  { displayName: 'Department', name: 'departmentName' },
];

export const ApprovalRoutingsTable: FunctionComponent<ApprovalRoutingsTableProps> = ({
  capitations,
  columnProperties = defaultColumnProperties,
  handleRowClick,
  isLoading,
}): ReactElement => (
  <SortableTable<ApprovalRoutingForTable>
    initialColumnSort="hospitalName"
    rowData={capitations}
    columns={columnProperties}
    handleRowSelect={handleRowClick}
    noDataMessage={dictionary.APPROVAL_ROUTES_TABLE_EMPTY}
    isLoading={isLoading}
    tableTestId="approval-routings-table"
  />
);
