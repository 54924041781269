import { FC, useState } from 'react';
import { CancelModal } from './modals/CancelModal';
import { Button } from './buttons/Button';
import { dictionary } from '../dictionary';
import { FormActionsComponentProps } from './FormFlow/FormFlow';
import { joinArgs } from '../Utils/arrayUtils';
import { useUtilityStyles } from '../Themes/utility.styles';
import { If } from './If';
import { BackButton } from './buttons/BackButton';

interface FormActionsProps extends FormActionsComponentProps {
  onBackAction?: () => void;
  onSubmitAction?: () => void;
  onCancelAction: () => void;
  onSaveDraftAction?: () => void;
  submitButtonLabel: string;
  submitButtonDisabled?: boolean;
  isSubmitting?: boolean;
  showSubmit?: boolean;
  isSavingDraft?: boolean;
  saveDraftButtonDisabled?: boolean;
  isViewOnly?: boolean;
}

export const FormActions: FC<FormActionsProps> = (props) => {
  const {
    onCancelAction,
    onBackAction,
    onSubmitAction,
    onSaveDraftAction,
    submitButtonLabel,
    submitButtonDisabled,
    showSubmit = true,
    isSubmitting = false,
    isSavingDraft,
    saveDraftButtonDisabled,
    isViewOnly = false,
  } = props;
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const utilClasses = useUtilityStyles();

  const onSubmitClick = onSubmitAction ? (e: React.UIEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmitAction();
  } : undefined;

  const onSaveDraftClick = onSaveDraftAction ? (e: React.UIEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onSaveDraftAction();
  } : undefined;

  const onBackClick = onBackAction ? (e: React.UIEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onBackAction();
  } : undefined;
  const submitLabel = isSubmitting ? dictionary.FORM_SUBMIT_PROCESSING_BUTTON : submitButtonLabel;
  const saveDraftLabel = isSavingDraft ? dictionary.FORM_SUBMIT_PROCESSING_BUTTON : 'Save as Draft';
  const backOrCancelOnClick = (e: React.UIEvent) => {
    if (isViewOnly) {
      onCancelAction();
    } else if (onBackClick) {
      onBackClick(e);
    } else {
      setIsCancelModalVisible(true);
    }
  };

  const triggerCancelModal = () => setIsCancelModalVisible(true);

  return (
    <>
      <div className={joinArgs(utilClasses.flex, utilClasses.spaceBetween, utilClasses.mt5)}>
        <div className={utilClasses.flex}>
          <If condition={!isViewOnly}>
            <Button buttonStyle="text" onClick={triggerCancelModal} data-testid="cancel-button">
              {dictionary.CANCEL}
            </Button>
          </If>
          <If condition={!!onBackClick || isViewOnly}>
            <BackButton onClick={backOrCancelOnClick} />
          </If>
        </div>
        <div className={utilClasses.flex}>
          <If condition={!!onSaveDraftAction}>
            <Button buttonStyle="reverse" type="button" data-testid="draft-button" onClick={onSaveDraftClick} disabled={isSavingDraft || isSubmitting || saveDraftButtonDisabled}>
              {saveDraftLabel}
            </Button>
          </If>
          <span style={{ width: '10px' }} />
          <If condition={!!showSubmit}>
            <Button type="submit" data-testid="submit-button" onClick={onSubmitClick} disabled={isSavingDraft || isSubmitting || submitButtonDisabled}>
              {submitLabel}
            </Button>
          </If>
        </div>
      </div>
      <CancelModal
        isOpen={isCancelModalVisible}
        onDismiss={() => setIsCancelModalVisible(false)}
        onConfirm={onCancelAction}
      />
    </>
  );
};
