import { ReactElement, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SortableTable } from '../../../../Shared/SortableTable/SortableTable';
import { hospitalColumnProperties } from './hospitalsTable.utils';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { HospitalBase } from '../../../../Data/Hospital';
import { routes } from '../../../../Utils/routes';
import { currentUserRoleSelector } from '../../../../redux/user/userSelectors';
import { userRoles } from '../../../../Utils/userRoles';

type HospitalsTableProps = {
  hospitalBases: HospitalBase[],
  columnProperties?: ColumnProperty<HospitalBase>[],
  isLoading: boolean,
};

export const HospitalsTable: FunctionComponent<HospitalsTableProps> = ({
  hospitalBases,
  columnProperties = hospitalColumnProperties,
  isLoading,
}): ReactElement => {
  const history = useHistory();
  const handleRowClick = (hospital: HospitalBase) => history.push(routes.hospitals.edit(hospital.id));
  const currentUserRole = useSelector(currentUserRoleSelector);
  const isOmiSupport = currentUserRole === userRoles.omiAdmin;

  return (
    <section>
      <SortableTable
        initialColumnSort="name"
        rowData={hospitalBases}
        columns={columnProperties}
        handleRowSelect={isOmiSupport ? handleRowClick : undefined}
        noDataMessage={dictionary.HOSPITAL_TABLE_EMPTY}
        isLoading={isLoading}
        tableTestId="hospitals-table"
      />
    </section>
  );
};
