import { CostCenterStub } from '../../../../Data/CostCenter';
import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';

export const costCenterColumnProperties: ColumnProperty<CostCenterStub>[] = [
  { displayName: dictionary.COST_CENTERS_HOSPITAL_NAME, name: 'hospitalName' },
  { displayName: dictionary.COST_CENTERS_DEPARTMENT_NAME, name: 'departmentName' },
  { displayName: dictionary.COST_CENTER_LABEL, name: 'name' },
  { displayName: dictionary.COST_CENTER_ID, name: 'hospitalCostCenterIdentifier' },
];
