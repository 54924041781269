import apiClient from './apiClient';
import Manufacturer, { ManufacturerBase, ManufacturerRequest } from '../Data/Manufacturer';

export const manufacturersUrl = '/manufacturers';
export const manufacturerByIdUrl = (mfctId: number): string => `${manufacturersUrl}/${mfctId}`;
export const manufacturersByHospitalUrl = (hospId: number): string => `/hospitals/${hospId}${manufacturersUrl}`;

const fetchManufacturers = async (): Promise<Manufacturer[]> => apiClient.get<Manufacturer[]>({ url: manufacturersUrl });

const fetchManufacturersByHospitalId = async (hospId: number): Promise<Manufacturer[]> => apiClient.get<Manufacturer[]>({ url: manufacturersByHospitalUrl(hospId) });

const fetchManufacturerById = async (mfctId: number): Promise<ManufacturerBase> => apiClient.get<ManufacturerBase>({ url: manufacturerByIdUrl(mfctId) });

const createManufacturer = async (req: ManufacturerRequest): Promise<{ id: number }> => apiClient.post({ url: manufacturersUrl, data: JSON.stringify(req) });

const updateManufacturer = async (id: number, manufacturer: ManufacturerRequest): Promise<{ id: number }> =>
  apiClient.put({ url: manufacturerByIdUrl(id), data: manufacturer });

export const manufacturerService = {
  getAll: fetchManufacturers,
  getAllByHospitalId: fetchManufacturersByHospitalId,
  getManufacturerById: fetchManufacturerById,
  create: createManufacturer,
  update: updateManufacturer,
};
