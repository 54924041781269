import { DateTime } from 'luxon';
import { dictionary } from '../../../../dictionary';
import { dateRegEx, nonAlphanumericRegex } from '../../../../Utils/validationUtils';
import { CasePatientFormFields } from '../../CreateRequisition/CasePatientInfo/casePatientInfo.types';
import { caseDateMax, caseDateMin } from '../../CreateRequisition/CasePatientInfo/casePatientInfo.utils';

type CasePatientValidationErrors = Record<keyof CasePatientFormFields, string>;

const validatePatientMRN = (patientsMRN: string, require = false): string | undefined => {
  let errorMessage;
  if (!patientsMRN) {
    if (require) errorMessage = dictionary.REQ_CASE_PATIENT_ERR_MRN_REQUIRED;
  } else if (patientsMRN.length > 50) {
    errorMessage = dictionary.REQ_CASE_PATIENT_ERR_MRN_LENGTH;
  }

  return errorMessage;
};

export const validateCaseDate = (caseDate?: string | null, require = false): string | undefined => {
  let msg: string | undefined;
  if (!caseDate) {
    if (require) msg = dictionary.REQ_CASE_PATIENT_ERR_CASE_DATE_REQUIRED;
  } else if (!dateRegEx.test(caseDate) || !DateTime.fromFormat(caseDate, 'MM/dd/yyyy').isValid) {
    msg = dictionary.REQ_CASE_PATIENT_ERR_CASE_DATE_INVALID;
  } else if (DateTime.fromFormat(caseDate, 'MM/dd/yyyy') < DateTime.local().minus({ days: caseDateMin + 1 })) {
    msg = dictionary.REQ_CASE_PATIENT_ERR_CASE_DATE_BOUNDS;
  } else if (DateTime.fromFormat(caseDate, 'MM/dd/yyyy') > DateTime.local().plus({ days: caseDateMax })) {
    msg = dictionary.REQ_CASE_PATIENT_ERR_CASE_DATE_BOUNDS;
  }

  return msg;
};

export const validateCasePatientForm = (formFieldVals: CasePatientFormFields): CasePatientValidationErrors => {
  const { caseNumber, caseDate, patient, patientsMRN } = formFieldVals;
  const errors: any = {};

  const patientMrnError = validatePatientMRN(patientsMRN, true);
  if (patientMrnError) {
    errors.patientsMRN = patientMrnError;
  }

  if (!patient) {
    errors.patient = dictionary.REQ_CASE_PATIENT_ERR_PATIENT;
  }

  if (!caseNumber || !caseNumber.trim()) {
    errors.caseNumber = dictionary.REQ_CASE_PATIENT_ERR_CASE_NUM_REQUIRED;
  } else if (nonAlphanumericRegex.test(caseNumber) || caseNumber.length > 50) {
    errors.caseNumber = dictionary.REQ_CASE_PATIENT_ERR_CASE_NUM_CHARS;
  }

  const caseDateErr = validateCaseDate(caseDate, true);
  if (caseDateErr) {
    errors.caseDate = caseDateErr;
  }

  return errors;
};

export const validateCasePatientFormMinimum = (formFieldVals: CasePatientFormFields): CasePatientValidationErrors => {
  const { caseNumber, caseDate, patientsMRN } = formFieldVals;
  const errors: any = {};

  const patientMrnError = validatePatientMRN(patientsMRN);
  if (patientMrnError) {
    errors.patientsMRN = patientMrnError;
  }

  if ((caseNumber && nonAlphanumericRegex.test(caseNumber)) || (caseNumber && caseNumber.length > 50)) {
    errors.caseNumber = dictionary.REQ_CASE_PATIENT_ERR_CASE_NUM_CHARS;
  }

  const caseDateErr = validateCaseDate(caseDate, true);
  if (caseDateErr) {
    errors.caseDate = caseDateErr;
  }

  return errors;
};
