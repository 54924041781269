import { ContractIdOptional, ValidateContractRequestNew } from '../../../../Data/Contract';
import { HealthSystem } from '../../../../Data/HealthSystem';
import { HospitalBase } from '../../../../Data/Hospital';
import { ManufacturerBase } from '../../../../Data/Manufacturer';
import { HospitalManufacturerIdentifier } from '../../../../Data/ManufacturerIdentifier';
import { dictionary } from '../../../../dictionary';
import { getFieldDescriptorMap, createDescriptor } from '../../../../Utils/formik.utils';

export interface ContractInfoFormFields {
  healthSystem: HealthSystem;
  hospitals: HospitalBase[];
  manufacturer: ManufacturerBase;
  manufacturerIdentifier?: HospitalManufacturerIdentifier;
  contractIdentifier: string;
  effectiveDate?: string | null;
  expirationDate?: string | null;
  wastedDiscount: number | '';
  explantDiscount: number | '';
  trialDiscount: number | '';
}

export const contractInfoFields = getFieldDescriptorMap<ContractInfoFormFields>([
  createDescriptor({ name: 'healthSystem', label: dictionary.HEALTH_SYSTEM_NAME_LABEL, required: true }),
  createDescriptor({ name: 'hospitals', label: dictionary.CONTRACT_HOSPITALS, inputFormat: 'multi-select', required: true }),
  createDescriptor({ name: 'manufacturer', label: dictionary.CONTRACT_MANUFACTURER_LABEL, required: true }),
  createDescriptor({ name: 'manufacturerIdentifier', label: dictionary.HOSPITAL_MANUFACTURER_ID_NAME_LABEL, required: false }),
  createDescriptor({ name: 'contractIdentifier', label: dictionary.CONTRACT_ID_LABEL, required: true }),
  createDescriptor({ name: 'effectiveDate', label: dictionary.CONTRACT_EFFECTIVE_DATE_LABEL, required: true }),
  createDescriptor({ name: 'expirationDate', label: dictionary.CONTRACT_EXPIRATION_DATE_LABEL, required: true }),
  createDescriptor({ name: 'wastedDiscount', label: dictionary.CONTRACT_WASTED_DISCOUNT_LABEL, required: false }),
  createDescriptor({ name: 'explantDiscount', label: dictionary.CONTRACT_EXPLANT_DISCOUNT_LABEL, required: false }),
  createDescriptor({ name: 'trialDiscount', label: dictionary.CONTRACT_TRIAL_DISCOUNT_LABEL, required: false }),
]);

export const getContractStateUpdatesFromContractInfoFields = (values: ContractInfoFormFields): ContractIdOptional => {
  const {
    healthSystem, manufacturer, manufacturerIdentifier, contractIdentifier, effectiveDate, expirationDate, wastedDiscount, explantDiscount, trialDiscount,
  } = values;

  return {
    healthSystem,
    healthSystemId: healthSystem.id,
    hospitals: healthSystem.hospitalStubs,
    manufacturer,
    manufacturerIdentifier: manufacturerIdentifier?.identifier,
    contractIdentifier,
    effectiveDate: effectiveDate!,
    expirationDate: expirationDate!,
    wastedDiscount: wastedDiscount || undefined,
    explantDiscount: explantDiscount || undefined,
    trialDiscount: trialDiscount || undefined,
  };
};

export const getContractInfoFormInitialValues = (contract: Partial<ContractInfoFormFields>): ContractInfoFormFields => ({
  healthSystem: contract?.healthSystem ?? {} as HealthSystem,
  hospitals: contract?.hospitals ?? [] as HospitalBase[],
  manufacturer: contract?.manufacturer ?? {} as ManufacturerBase,
  manufacturerIdentifier: contract?.manufacturerIdentifier,
  contractIdentifier: contract?.contractIdentifier ?? '',
  effectiveDate: contract?.effectiveDate ?? null,
  expirationDate: contract?.expirationDate ?? null,
  wastedDiscount: contract?.wastedDiscount || '',
  explantDiscount: contract?.explantDiscount || '',
  trialDiscount: contract?.trialDiscount || '',
});

export const getContractInfoValidateRequestNew = (fields: Partial<ContractInfoFormFields>): ValidateContractRequestNew => ({
  healthSystemId: fields.healthSystem!.id,
  contractIdentifier: fields.contractIdentifier || '',
  manufacturerId: fields.manufacturer!.id,
});
