import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../../dictionary';
import { ProceduresTable } from './ProceduresTable/ProceduresTable';
import { LoadState } from '../../../redux/loadState';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { ProcedureForTable } from './ProceduresTable/proceduresTable.utils';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { routes } from '../../../Utils/routes';
import { procedureService } from '../../../Services/ProcedureService';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { TabInfo } from '../../../Shared/Tabs/Tabs';
import { If } from '../../../Shared/If';
import { currentUserIsAdminForAtLeastOneHospital } from '../../../redux/user/userSelectors';

export function ProceduresIndex(): ReactElement {
  const history = useHistory();
  const [procedures, setProcedures] = useState<ProcedureForTable[]>([]);
  const [showArchived, setShowArchived] = useState(false);
  const [procsLoadState, setProcsLoadState] = useState(LoadState.loading);
  const showCreateButton = useSelector(currentUserIsAdminForAtLeastOneHospital);

  useEffect(() => {
    (async () => {
      try {
        const proceduresResponse = await procedureService.getProcedures();
        const procsForTable: ProcedureForTable[] = proceduresResponse.procedures.map(p =>
          ({ id: p.id, procedureName: p.name, hospitalName: p.hospitalName, departmentName: p.departmentName, archived: p.archived }));
        setProcedures(procsForTable);
        setProcsLoadState(LoadState.loaded);
      } catch {
        setProcsLoadState(LoadState.error);
      }
    })();
  }, []);

  const filteredProcedures = procedures.filter((p) => p.archived === showArchived);

  const procedureTabs: TabInfo[] = useMemo(() => [
    { title: dictionary.ACTIVE_TAB_LABEL, active: !showArchived, onClick: () => setShowArchived(false) },
    { title: dictionary.ARCHIVED_TAB_LABEL, active: showArchived, onClick: () => setShowArchived(true) },
  ], [showArchived]);

  if (procsLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  if (procsLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }

  const isLoading = !procsLoadState || [LoadState.notLoaded, LoadState.loading].includes(procsLoadState);

  const procedureRowSelectHandler = (procedure: ProcedureForTable) => {
    history.push(routes.procedures.edit(procedure.id));
  };

  return (
    <>
      <PageHeader title={dictionary.PROCEDURES_LIST_HEADER_LABEL} tabs={procedureTabs}>
        <If condition={showCreateButton}>
          <LinkButton to={routes.procedures.create}>{dictionary.PROCEDURES_CREATE_NEW_ACTION}</LinkButton>
        </If>
      </PageHeader>

      <ProceduresTable procedures={filteredProcedures ?? []} handleRowClick={procedureRowSelectHandler} isLoading={isLoading} />
    </>
  );
}
