import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../../dictionary';
import { HealthSystemsTable } from './HealthSystemsTable/HealthSystemsTable';
import { routes } from '../../../Utils/routes';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { HealthSystem } from '../../../Data/HealthSystem';
import { healthSystemService } from '../../../Services/HealthSystemService';
import { HealthSystemForTable } from './HealthSystemsTable/healthSystemsTable.utils';

export function HealthSystemsIndex(): ReactElement {
  const [isLoading, setIsLoading] = useState(true);
  const [healthSystems, setHealthSystems] = useState<HealthSystem[]>([]);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!healthSystems.length) {
      (async () => {
        try {
          const results = await healthSystemService.get();
          setHealthSystems(results);
          setIsLoading(false);
        } catch (e) {
          setHasLoadingError(true);
        }
      })();
    }
  }, [dispatch, healthSystems.length]);

  if (hasLoadingError) {
    return <ErrorPage />;
  }

  const tableHealthSystems: HealthSystemForTable[] = healthSystems.map(hs => ({
    id: hs.id,
    name: hs.name,
    hospitalNames: hs.hospitalStubs.length ? hs.hospitalStubs.map(h => h.name).join(', ') : dictionary.EMPTY_FIELD_MARK,
  }));

  return (
    <>
      <PageHeader title={dictionary.HEALTH_SYSTEMS_LIST_HEADER_LABEL}>
        <LinkButton to={routes.healthSystems.create}>{dictionary.HEALTH_SYSTEMS_CREATE_NEW_ACTION}</LinkButton>
      </PageHeader>

      <HealthSystemsTable
        healthSystems={tableHealthSystems ?? []}
        isLoading={isLoading}
      />
    </>
  );
}
