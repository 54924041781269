import { useState, useMemo, useRef } from 'react';
import { sortItemsOn, SortDescriptor, alphanumericSortDescriptor } from '../../Utils/SortUtils';

export type SortValues = 'asc' | 'desc';
type HookReturnType<T> = [
  Array<T> | 'loading',
  SortValues,
  keyof T,
  (field: keyof T, sortDescriptor: SortDescriptor) => void,
];

const useSortedRows = <T>(
  rows: T[] | 'loading',
  defaultDirection: SortValues,
  defaultColumn: keyof T,
): HookReturnType<T> => {
  const [sortDirection, setSortDirection] = useState<SortValues>(defaultDirection);
  const sortDescriptorRef = useRef<SortDescriptor>(alphanumericSortDescriptor);
  const [sortField, setSortField] = useState<keyof T>(defaultColumn);

  const sortedRows = useMemo(() => ((rows === 'loading') ? 'loading' : sortItemsOn(rows, sortField, sortDirection, sortDescriptorRef.current)),
    [rows, sortDescriptorRef, sortDirection, sortField]);

  function toggleSortDirection() {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  }

  function handleSort(field: keyof T, nextSortDescriptor: SortDescriptor) {
    if (field !== sortField) {
      setSortField(field);
      setSortDirection('asc');
    } else {
      toggleSortDirection();
    }
    sortDescriptorRef.current = nextSortDescriptor || alphanumericSortDescriptor;
  }

  return [
    sortedRows,
    sortDirection,
    sortField,
    handleSort,
  ];
};

export default useSortedRows;
