import { faFolderOpen, faFolder, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Button, ButtonProps } from './Button';

interface ArchiveButtonProps {
  archived: boolean;
  onClick: ButtonProps['onClick'];
}

export const ArchiveButton: FC<ArchiveButtonProps> = ({ archived, onClick, children }) => {
  const btnIntent: ButtonProps['intent'] = archived ? 'primary' : 'danger';
  const icon: IconDefinition = archived ? faFolder : faFolderOpen;

  return (
    <Button intent={btnIntent} onClick={onClick} leadingIcon={<FontAwesomeIcon icon={icon} />}>
      {children}
    </Button>
  );
};
