import { createUseStyles } from 'react-jss';

export const useDateFieldStyles = createUseStyles({
  date: {
    '& .react-datepicker__navigation': {
      overflow: 'inherit',
    },
    '& .react-datepicker__navigation-icon': {
      width: '100%',
    },
    '& .react-datepicker__navigation-icon--previous::before': {
      left: '0.625rem',
    },
    '& .react-datepicker__navigation-icon--next::before': {
      right: '0.625rem',
      left: 'auto',
    },
    '& .react-datepicker__day--outside-month': {
      color: 'transparent',
      pointerEvents: 'none',
    },
  } });
