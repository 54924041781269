import { createUseStyles } from 'react-jss';
import colors from '../../Themes/colors';
import { fontWeights } from '../../Themes/fontWeights';
import { tableContainerStyle } from '../../Themes/table.styles';

export const useSortableTableStyles = createUseStyles({
  container: { ...tableContainerStyle },
  tableRoot: {
    position: 'relative',
    borderCollapse: 'collapse',
    width: '100%',
    margin: 0,
    backgroundColor: colors.trueWhite,

    // This is a cross-browser method of drawing a drop shadow in the table heading
    '& thead th': {
      position: 'relative',
      overflow: 'hidden',
      paddingTop: '1rem',
      paddingBottom: '2rem',

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: '-1rem',
        bottom: '1rem',
        left: '-1rem',
        borderBottom: `0.0625rem solid ${colors.darkGray}`,
        boxShadow: '0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.25)',
        pointerEvents: 'none',
      },
    },
  },
  rowBody: {
    height: '3rem',
    lineHeight: 1.5,
    '&:nth-child(odd)': { backgroundColor: colors.nearWhite },
    '&.selectedRow, &.selectedRow:hover, &.selectedRow:focus': {
      backgroundColor: colors.tookishGreen,
      color: colors.nearWhite,
    },
  },
  rowBodyInteractive: {
    '&:hover, &:focus': {
      outline: 'none',
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: colors.grayHover,
    },
    '&:focus': {
      backgroundColor: colors.disabled,
    },
  },
  sortButtons: {
    marginLeft: '1rem',
    '&:hover, &:focus': { backgroundColor: colors.nearWhite, outline: 'none' },
  },
  paddedCell: {
    padding: '0.25rem 1rem',
    '&:first-of-type': { paddingLeft: '1.75rem' },
    '&:last-of-type': { paddingRight: '1.75rem' },
  },

  loadingState: {
    fontWeight: fontWeights.bold,
    color: colors.darkGray,
    textAlign: 'center',
  },

  // Utility classes
  flex: { display: 'inline-flex' },
});
