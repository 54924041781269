import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApprovalRouting } from '../../../Data/ApprovalRouting';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { createApprovalRouting } from '../../../Services/ApprovalRoutingService';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { routes } from '../../../Utils/routes';
import { ApprovalRoutingFormFields } from '../shared/ApprovalRoutingForm.types';
import { convertApprovalRoutingFormFieldsToApprovalRoutingRequest } from '../shared/approvalRoutingForm.utils';
import { ApprovalRoutingFormWrapper } from '../shared/ApprovalRoutingFormWrapper';

export const CreateApprovalRouting: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <FormFlow<ApprovalRoutingFormFields, ApprovalRouting>
      steps={[
        { pageHeader: dictionary.APPROVAL_ROUTES_NEW_HEADER_LABEL, component: ApprovalRoutingFormWrapper },
      ]}
      completeButtonLabel={dictionary.APPROVAL_ROUTES_NEW_BUTTON_LABEL}
      onCancel={() => {
        history.push(routes.approvalRoutings.index);
      }}
      onComplete={async (values: ApprovalRoutingFormFields) => {
        const createRequest = convertApprovalRoutingFormFieldsToApprovalRoutingRequest(values);
        try {
          await createApprovalRouting(createRequest);
          history.push(routes.approvalRoutings.index);
        } catch (exception) {
          if (exception.message.includes('409')) {
            dispatch(addNotification(NotificationType.error, dictionary.APPROVAL_ROUTES_ALREADY_EXISTS_ERROR(values.hospital!.name, values.department!.name)));
          } else {
            dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
          }
        }
      }}
    />
  );
};
