import { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useGlobalStyles } from './Themes/global.styles';
import { Authentication } from './Shared/Authentication';
import { NotificationCenter } from './Components/Notifications/Notifications';
import { NavBar } from './Shared/NavBar/NavBar';
import Footer from './Shared/Footer/Footer';
import AppRouter from './AppRouter';
import useAppStyles from './Themes/app.styles';
import { joinArgs } from './Utils/arrayUtils';
import { useUtilityStyles } from './Themes/utility.styles';
import { AutoLogout } from './Shared/AutoLogout';
import { Ie11FocusableElementFixer } from './Shared/Ie11FocusableElementFixer/Ie11FocusableElementFixer';

const App: FC = () => {
  useGlobalStyles();
  const appClasses = useAppStyles();
  const utilClasses = useUtilityStyles();

  return (
    <Authentication>
      <Router>
        <AutoLogout />
        <NotificationCenter />
        <NavBar />
        <Ie11FocusableElementFixer />

        <div className={joinArgs(appClasses.main, utilClasses.maxAppWidth)}>
          <AppRouter />
        </div>

        <Footer />
      </Router>
    </Authentication>
  );
};

export default App;
