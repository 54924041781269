import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RequisitionProduct from '../../../../../Data/RequisitionProduct';
import { dictionary } from '../../../../../dictionary';
import { currentUserRoleSelector } from '../../../../../redux/user/userSelectors';
import { userRoles } from '../../../../../Utils/userRoles';
import useBadgeStyles from './Badges.style';
import useUtilityStyles from '../../../../../Themes/utility.styles';

interface ProductCategoryDisplayProps {
  reqProduct: RequisitionProduct,
  hospHasProductCategories: boolean,
}

const ProductCategoryDisplay: FC<ProductCategoryDisplayProps> = ({ reqProduct, hospHasProductCategories }) => {
  const currentUserRole = useSelector(currentUserRoleSelector);
  const classes = useBadgeStyles();
  const utilClasses = useUtilityStyles();

  if (
    (currentUserRole !== userRoles.vendor)
    && hospHasProductCategories
    && !reqProduct.productCategoryName
  ) {
    return (
      <div className={classes.root}>
        <FontAwesomeIcon icon={faExclamationTriangle} className={utilClasses.mr05} />
        <span className={classes.required}>Required</span>
      </div>
    );
  }

  return <>{reqProduct.productCategoryName || dictionary.EMPTY_FIELD_MARK}</>;
};

export const renderRequisitionProductCategory = (reqProduct: RequisitionProduct, hospHasProductCategories: boolean): JSX.Element =>
  <ProductCategoryDisplay reqProduct={reqProduct} hospHasProductCategories={hospHasProductCategories} />;
