import { dictionary } from '../../../../dictionary';
import { ColumnProperty } from '../../../../Shared/SortableTable/SortableTable.types';
import { getNextId } from '../../../../Utils/idUtils';
import { HospMfctIdentifierAssocFormFields } from '../../shared/hospMfctIdentifier.utils';

export interface HospMfctIdentifierForCreateTable {
  id: number;
  manufacturerIdentifier: string;
  manufacturerName: string;
  siteName?: string;
  siteIdentifier?: string;
}

export const createHospMfctIdentifierColumnProperties: ColumnProperty<HospMfctIdentifierForCreateTable>[] = [
  { displayName: dictionary.HOSPITAL_MANUFACTURER_ID_NAME_LABEL, name: 'manufacturerIdentifier' },
  { displayName: dictionary.CREATE_HOSPITAL_MANUFACTURER_ID_MANUFACTURER, name: 'manufacturerName' },
  { displayName: dictionary.HOSPITAL_MANUFACTURER_ID_SITE_NAME_LABEL, name: 'siteName' },
  { displayName: dictionary.HOSPITAL_MANUFACTURER_ID_SITE_ID_LABEL, name: 'siteIdentifier' },
];

export const formHospitalManufacturerToTableFormat = (hospitalManufacturerAssociations: HospMfctIdentifierAssocFormFields[]): HospMfctIdentifierForCreateTable[] =>
  hospitalManufacturerAssociations.map(assoc => ({
    id: getNextId(),
    manufacturerIdentifier: assoc.identifier,
    manufacturerName: assoc.manufacturer.name,
    siteName: assoc.siteName || dictionary.EMPTY_FIELD_MARK,
    siteIdentifier: assoc.siteIdentifier || dictionary.EMPTY_FIELD_MARK,
  }));
