import { FocusEvent, MouseEvent } from 'react';
import { Value, ListBoxEvents, Id } from './autocomplete.types';
import { hasOwnProperty } from '../../Utils/objectUtils';
import { Name } from '../../Data/Name';
import { getFullName } from '../../Utils/nameUtils';

export enum ChangeReason {
  Add = 'ListBoxChangeReason.Add',
  Remove = 'ListBoxChangeReason.Remove',
  CreateNew = 'ListBoxChangeReason.CreateNew',
}

export enum ListBoxState {
  Hidden,
  Visible,
}

function isLabelName(obj: any) {
  return hasOwnProperty(obj, 'name');
}

function isLabelFirstName(obj: any) {
  return hasOwnProperty(obj, 'firstName');
}

function isLabelLastName(obj: any) {
  return hasOwnProperty(obj, 'lastName');
}

function isLabelFullNameInParts(obj: any) {
  return isLabelLastName(obj) && isLabelFirstName(obj) && hasOwnProperty(obj, 'middleInitial') && hasOwnProperty(obj, 'suffix');
}

function isLabelContractIdentifier(obj: any) {
  return hasOwnProperty(obj, 'contractIdentifier');
}

function isLabelCatalogNumber(obj: any) {
  return hasOwnProperty(obj, 'catalogNumber');
}

function compileFullName(obj: Name) {
  return getFullName(obj);
}

export function getOptionLabel(o: Record<string, any>): string {
  if (typeof o === 'string') return '';
  if (isLabelName(o)) return o.name;
  if (isLabelFullNameInParts(o)) return compileFullName(o as Name);
  if (isLabelFirstName(o)) return o.firstName;
  if (isLabelLastName(o)) return o.lastName;
  if (isLabelContractIdentifier(o)) return o.contractIdentifier;
  if (isLabelCatalogNumber(o)) return o.catalogNumber;

  throw new Error('Tried to get label that does not exist');
}

function isId(obj: Id): obj is Id {
  return hasOwnProperty(obj, 'id');
}

export function getOptionId(o: Id): number | string {
  if (isId(o)) return o.id;

  throw new Error('Tried to get identifier that does not exist');
}

export function filterItems<T extends Id>(
  selectedItems: Value<T, boolean>,
  allItems: T[] = [],
  searchValue: string,
  discardSelectedItems: boolean,
  providedGetOptionLabel: (val: T) => string,
): T[] {
  let selectedIds: (number | string)[] = [];
  if (discardSelectedItems === true && selectedItems !== null) {
    if (Array.isArray(selectedItems)) selectedIds = selectedItems.map(i => getOptionId(i));
    else selectedIds = selectedItems ? [getOptionId(selectedItems)] : [];
  }

  const needle = searchValue?.trim().toLowerCase();

  return allItems.filter(item => {
    if (selectedIds.includes(getOptionId(item))) return false;
    const searchName = providedGetOptionLabel(item).toLowerCase();

    return searchName.includes(needle);
  });
}

export function getSelectedItemsAsArray<T>(selectedItems: Value<T, boolean>): T[] {
  if (selectedItems === null || selectedItems === undefined) return [];
  if (Array.isArray(selectedItems)) return selectedItems;

  return [selectedItems];
}

export function isFocusEvent(evt: ListBoxEvents): evt is FocusEvent<HTMLInputElement> {
  return evt.type === 'blur';
}

export function isClickEvent(evt: ListBoxEvents): evt is MouseEvent<HTMLInputElement> {
  return evt.type === 'click';
}

export function hasValueIsNotArray<T>(item: Value<T, boolean>): item is T {
  return item !== null && !Array.isArray(item);
}

export function hasValueIsArray<T>(item: Value<T, boolean>): item is T[] {
  return item !== null && (Array.isArray(item) && item.length > 0);
}
