import { dictionary } from '../../dictionary';
import { FormFieldDescriptor } from '../../Shared/fields/formTypes';
import { createDescriptor } from '../../Utils/formik.utils';

export interface ApprovalRoutingCustomizationFormFields {
  offContractSkipBuyer: boolean;
  offContractDoNotPermit: boolean;
  contractPriceChangeSkipBuyer: boolean;
  contractPriceChangeDoNotPermit: boolean;
  newProductsSkipBuyer: boolean;
  newProductsDoNotPermit: boolean;
  zeroPriceProductsRouteToBuyer: boolean;
  missingItemIdRouteToBuyer: boolean;
  trialProductsRouteToBuyer: boolean;
  wastedProductsRouteToBuyer: boolean;
  explantedProductsRouteToBuyer: boolean;
}

export const approvalRoutingCustomizationFormFields: Record<keyof ApprovalRoutingCustomizationFormFields, FormFieldDescriptor> = {
  offContractSkipBuyer: createDescriptor({ name: 'offContractSkipBuyer', label: dictionary.SKIP_BUYER, required: false }),
  offContractDoNotPermit: createDescriptor({ name: 'offContractDoNotPermit', label: dictionary.DO_NOT_PERMIT_IN_PRODUCT_SEARCH, required: false }),
  contractPriceChangeSkipBuyer: createDescriptor({ name: 'contractPriceChangeSkipBuyer', label: dictionary.SKIP_BUYER, required: false }),
  contractPriceChangeDoNotPermit: createDescriptor({ name: 'contractPriceChangeDoNotPermit', label: dictionary.DO_NOT_PERMIT, required: false }),
  newProductsSkipBuyer: createDescriptor({ name: 'newProductsSkipBuyer', label: dictionary.SKIP_BUYER, required: false }),
  newProductsDoNotPermit: createDescriptor({ name: 'newProductsDoNotPermit', label: dictionary.DO_NOT_PERMIT, required: false }),
  zeroPriceProductsRouteToBuyer: createDescriptor({ name: 'zeroPriceProductsRouteToBuyer', label: dictionary.ZERO_PRICE_PRODUCTS, required: false }),
  missingItemIdRouteToBuyer: createDescriptor({ name: 'missingItemIdRouteToBuyer', label: dictionary.PRODUCTS_MISSING_ITEM_ID, required: false }),
  trialProductsRouteToBuyer: createDescriptor({ name: 'trialProductsRouteToBuyer', label: dictionary.TRIAL_PRODUCTS, required: false }),
  wastedProductsRouteToBuyer: createDescriptor({ name: 'wastedProductsRouteToBuyer', label: dictionary.WASTED_PRODUCTS, required: false }),
  explantedProductsRouteToBuyer: createDescriptor({ name: 'explantedProductsRouteToBuyer', label: dictionary.EXPLANTED_PRODUCTS, required: false }),
};
