/* eslint-disable max-len */
import { useEffect, FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { dictionary } from '../../../../dictionary';
import { actionTypes } from '../../../../redux/actionTypes';
import { ReduxState, NotificationType } from '../../../../redux/initialState';
import { HospitalBase } from '../../../../Data/Hospital';
import { DepartmentBase, DepartmentPhysicianResponse, DepartmentProcedureResponse, HospitalDepartmentApprovalRoutingResponse } from '../../../../Data/Department';
import { HospitalContactNotification } from '../../shared/HospitalContactNotification';
import Autocomplete from '../../../../Shared/Autocomplete/Autocomplete';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { FormFlowStepComponent } from '../../../../Shared/FormFlow/FormFlow';
import { MergedRequisition } from '../../../../Data/Requisition';
import { If } from '../../../../Shared/If';
import { alphanumericSortDescriptor, nameSortDescriptor } from '../../../../Utils/SortUtils';
import { getProcedureInfoInitialValues, procedureInfoFields, validateProcedureInfoForm, validateProcedureInfoFormMinimum } from './procedureInfo.utils';
import { hospitalService } from '../../../../Services/HospitalService';
import { departmentService } from '../../../../Services/DepartmentService';
import { omitNullFromObj } from '../../../../Utils/objectUtils';

export type ProcedureInfoProps = FormFlowStepComponent<Partial<MergedRequisition>>;

export const ProcedureInfo: FC<ProcedureInfoProps> = ({ onFormStepComplete, FormActionsComponent, onFormSaveDraft }) => {
  const dispatch = useDispatch();
  const editRequisition = useSelector((state: ReduxState) => state.requisition.editRequisition);
  const updateRequisition = (updatedRequisition: MergedRequisition) => dispatch({ type: actionTypes.requisition.updateNew, requisition: updatedRequisition });
  const clearManufacturer = () => dispatch({ type: actionTypes.requisition.clearManufacturer });
  const clearProducts = () => dispatch({ type: actionTypes.requisition.clearProducts });
  const clearPatient = () => dispatch({ type: actionTypes.requisition.clearPatient });
  const [hospitalBases, setHospitalBases] = useState<HospitalBase[]>([]);
  const [selectedHospital, setSelectedHospital] = useState<HospitalBase>();
  const [departmentBases, setDepartmentBases] = useState<HospitalDepartmentApprovalRoutingResponse[]>([]);
  const [departmentProcedures, setDepartmentProcedureResponse] = useState<DepartmentProcedureResponse[]>([]);
  const [departmentPhysicians, setDepartmentPhysicianResponse] = useState<DepartmentPhysicianResponse[]>([]);

  useEffect(() => {
    (async () => {
      if (!hospitalBases.length) {
        try {
          const hospitalBasesResult = await hospitalService.getHospitalBases();
          setHospitalBases(hospitalBasesResult);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.REQ_HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospitalBases.length]);

  const handleSubmit = async (updatedReq: MergedRequisition) => {
    const { hospital, department } = updatedReq;
    if (!departmentBases.find(d => d.id === department?.id)?.approvalRoute) {
      dispatch(addNotification(NotificationType.error, dictionary.REQ_NO_APPROVAL_ROUTE_ERROR));
      return;
    }
    if (editRequisition?.hospital && editRequisition?.hospital?.id !== hospital?.id) {
      clearManufacturer();
      clearProducts();
      clearPatient();
    }

    updateRequisition(updatedReq);
    onFormStepComplete(updatedReq);
  };

  const formik = useFormik({
    initialValues: getProcedureInfoInitialValues(editRequisition),
    validate: editRequisition.validate ? validateProcedureInfoForm : validateProcedureInfoFormMinimum,
    onSubmit: handleSubmit,
  });

  const onSaveDraftAction = () => {
    if (department?.id && !departmentBases.find(d => d.id === department.id)?.approvalRoute) {
      dispatch(addNotification(NotificationType.error, dictionary.REQ_NO_APPROVAL_ROUTE_ERROR));
      return;
    }
    onFormSaveDraft!({ ...omitNullFromObj(editRequisition), ...omitNullFromObj(formik.values) });
  };

  const setHospitalAndDeptFormValues = (hospital?: HospitalBase, department?: DepartmentBase) => {
    formik.setValues({ hospital, department, procedure: undefined, physician: undefined });
  };

  const { hospital, department } = formik.values;

  useEffect(() => {
    (async () => {
      if (hospital) {
        setSelectedHospital(undefined);
        try {
          const hosp = await hospitalService.getHospitalBaseById(hospital.id);
          setSelectedHospital(hosp);
          const depts = await hospitalService.getDepartmentsByHospitalId(hospital.id);
          const sortedDepts = depts.filter(d => !d.archived).sort(alphanumericSortDescriptor('name', 1));
          setDepartmentBases(sortedDepts);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.REQ_HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospital]);

  useEffect(() => {
    (async () => {
      if (department) {
        try {
          // Get procedures
          const procedures = await departmentService.getProceduresByDepartmentId(department.id);
          const sortedProcedures = procedures.sort(alphanumericSortDescriptor('name', 1));
          // Get physicians
          const physicians = await departmentService.getPhysiciansByDepartmentId(department.id);
          const sortedPhysicians = physicians.filter(p => !p.archived).sort(nameSortDescriptor);
          setDepartmentProcedureResponse(sortedProcedures);
          setDepartmentPhysicianResponse(sortedPhysicians);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.REQ_HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, department]);

  const sortedHospitals = useMemo(() => hospitalBases.sort(alphanumericSortDescriptor('name', 1)), [hospitalBases]);
  const onHospitalChange = (hosp: HospitalBase | null) => setHospitalAndDeptFormValues(hosp ?? undefined, undefined);
  const onDeptChange = (dept: DepartmentBase | null) => setHospitalAndDeptFormValues(formik.values.hospital, dept ?? undefined);

  return (
    <>
      <If condition={!!hospital}>
        <HospitalContactNotification
          contactPhoneNumber={hospital?.contactPhoneNumber}
          contactEmail={hospital?.contactEmail}
          contactMessage={hospital?.contactMessage}
        />
      </If>

      <form className="input-form" onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} lg={8} xl={6}>
            <Autocomplete formikProps={formik} descriptor={procedureInfoFields.hospital} options={sortedHospitals} disabled={!!editRequisition.id} onChange={onHospitalChange} />
            <Autocomplete formikProps={formik} descriptor={procedureInfoFields.department} options={departmentBases} disabled={!!editRequisition.id || !selectedHospital} onChange={onDeptChange} />
            <Autocomplete formikProps={formik} descriptor={procedureInfoFields.procedure} options={departmentProcedures} disabled={!department} />
            <Autocomplete formikProps={formik} descriptor={procedureInfoFields.physician} options={departmentPhysicians} disabled={!department} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={8} xl={6}>
            <FormActionsComponent onSaveDraftAction={onSaveDraftAction} />
          </Col>
        </Row>
      </form>
    </>
  );
};
