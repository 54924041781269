import { FC, InputHTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { joinArgs } from '../../Utils/arrayUtils';
import { useCheckboxStyles } from './checkbox.styles';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name?: string;
  label?: string;
  checked?: boolean;
}
export const Checkbox: FC<CheckboxProps> = ({ id, className, label, ...rest }) => {
  const classes = useCheckboxStyles({ label });
  const rootClassNames = joinArgs(className || '', classes.root);

  return (
    <label className={rootClassNames} htmlFor={id}>
      <div className={classes.checkboxIcon}>
        <input {...rest} id={id} type="checkbox" className={classes.input} />
        <FontAwesomeIcon icon={faCheck} />
      </div>
      {label ? <span className={classes.trailingLabelText}>{label}</span> : null}
    </label>
  );
};
