import { FormikErrors, FormikValues } from 'formik';
import { ManufacturerBase } from '../../../../Data/Manufacturer';
import { validateContractBaseInfo, ContractFormFields, validateContractParticipantsNew } from '../../validations/contract.validations';

export const contractInfoFormValidateNew = (values: FormikValues, manufacturerOptions: ManufacturerBase[]): FormikErrors<ContractFormFields> => {
  const participantErrors = validateContractParticipantsNew(values, manufacturerOptions);
  const baseInfoErrors = validateContractBaseInfo(values);

  return { ...participantErrors, ...baseInfoErrors };
};
