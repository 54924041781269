export const genders = Object.freeze({
  M: 'Male',
  F: 'Female',
  U: 'Unknown',
  N: 'Non-Binary',
});

export default interface Patient {
  id: number,
  mrn: string,
  hospitalId: number,
  firstName: string,
  lastName: string,
  middleName?: string | null,
  dateOfBirth?: string | null,
  gender?: keyof typeof genders | null,
}

export interface PatientSearchRequest {
  mrn: string,
  hospitalId: number
}
