import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HealthSystem } from '../../../Data/HealthSystem';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { healthSystemService } from '../../../Services/HealthSystemService';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { routes } from '../../../Utils/routes';
import { HealthSystemForm } from '../shared/HealthSystemForm';

const HealthSystemFormWrapper: FC<FormFlowStepComponent<HealthSystem>> = ({ FormActionsComponent, onFormStepComplete }) =>
  <HealthSystemForm FormActionsComponent={FormActionsComponent} onFormStepComplete={onFormStepComplete} />;

export const CreateHealthSystem: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onCancel = () => history.push(routes.healthSystems.index);
  const onComplete = async (request: HealthSystem) => {
    try {
      await healthSystemService.create(request);
      dispatch(addNotification(NotificationType.success, dictionary.CREATE_HEALTH_SYSTEM_SUCCESS));
      history.push(routes.healthSystems.index);
    } catch (exception: any) {
      if (exception.message.includes('409')) {
        dispatch(addNotification(NotificationType.error, dictionary.HEALTH_SYSTEM_NOT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.CREATE_HEALTH_SYSTEM_ERROR));
      }
    }
  };

  return (
    <FormFlow<HealthSystem>
      onComplete={onComplete}
      onCancel={onCancel}
      completeButtonLabel={dictionary.CREATE_HEALTH_SYSTEM_SUBMIT_BTN}
      steps={[
        { pageHeader: dictionary.CREATE_HEALTH_SYSTEM_PAGE_HEADER, component: HealthSystemFormWrapper },
      ]}
    />
  );
};
