import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { routes } from '../../../Utils/routes';
import { HospitalBase, HospitalRequest } from '../../../Data/Hospital';
import { hospitalService } from '../../../Services/HospitalService';
import { HospitalForm } from '../shared/HospitalForm';

const HospitalFormWrapper: FC<FormFlowStepComponent<HospitalRequest, HospitalBase>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <HospitalForm FormActionsComponent={FormActionsComponent} hospital={editSource} onFormStepComplete={onFormStepComplete} />;

export const EditHospital: FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const hospitalId = Number(params.id);
  const [hospital, setHospital] = useState<HospitalBase>();
  const [loadingHospital, setLoadingHospital] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const result = await hospitalService.getHospitalBaseById(hospitalId);
        setHospital(result);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_DETAILS_LOAD_ERR));
      } finally {
        setLoadingHospital(false);
      }
    })();
  }, [dispatch, hospitalId]);

  if (loadingHospital) {
    return <LoadingSpinner />;
  }
  if (!hospital) {
    return <ErrorPage />;
  }

  const loadedHospital = hospital!;
  const onComplete = async (hospitalRequest: HospitalRequest) => {
    try {
      await hospitalService.update(loadedHospital.id, hospitalRequest);
      dispatch(addNotification(NotificationType.success, dictionary.HOSPITAL_EDIT_SUCCESS));
      history.push(routes.hospitals.index);
    } catch (e) {
      const exception = e as AxiosError;
      if (exception.response?.status === 409) {
        dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_NOT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_EDIT_FAILURE));
      }
    }
  };
  return (
    <FormFlow<HospitalRequest, HospitalBase>
      editSource={loadedHospital}
      steps={[{ pageHeader: dictionary.HOSPITAL_EDIT_HEADER, component: HospitalFormWrapper }]}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      onCancel={() => { history.push(routes.hospitals.index); }}
      onComplete={onComplete}
    />
  );
};
