import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { RequisitionStatus } from '../../../Data/RequisitionStatus';
import { dictionary } from '../../../dictionary';
import useUtilityStyles from '../../../Themes/utility.styles';
import { routes } from '../../../Utils/routes';
import { InterfaceReqTableForIndex, pastExportColumnProperties, pendingExportColumnProperties } from './interfaceToBeExportedTable.utils';
import { InterfaceToBeExportedTable } from './InterfaceToBeExportedTable';
import { ReqForInterfacesTable } from '../InterfaceIndex/interfacesIndex.utils';

interface InterfacesExportTableProps {
  isLoading: boolean;
  requisitions: ReqForInterfacesTable[];
}

export const InterfacesToBeExportedTable: FC<InterfacesExportTableProps> = (props) => {
  const { isLoading, requisitions } = props;

  const history = useHistory();
  const utilClasses = useUtilityStyles();

  const approvedRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.approved);

  const waitingForPoRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.waitingForPo
    || req.statusCode === RequisitionStatus.completed);

  const InterfacesReqTable = useMemo((): FC<{
    reqs: ReqForInterfacesTable[],
    colProps: Column<ReqForInterfacesTable>[],
    noDataMsg: string,
    defaultSortColumn: string | undefined,
    toolTip: string | undefined,
    toolTipDef: string | undefined
  }> =>
    ({ reqs, colProps, noDataMsg, defaultSortColumn, toolTip, toolTipDef }) => {
      const handleRowClick = (requisition: ReqForInterfacesTable) => {
        history.push(routes.requisitions.detail(requisition.id!));
      };
      return (
        <InterfaceToBeExportedTable
          requisitions={reqs}
          columns={colProps}
          handleRowClick={handleRowClick}
          noDataMessage={noDataMsg}
          isLoading={isLoading}
          initialSortOrder="desc"
          initialSortColumn={defaultSortColumn}
          toolTip={toolTip}
          toolTipDef={toolTipDef}
        />
      );
    }, [history, isLoading]);

  const requisitionTables: InterfaceReqTableForIndex[] = [
    {
      testId: 'pending-exports-box',
      title: dictionary.INTERFACE_PENDING_EXPORT_HEADER,
      reqs: approvedRequisitions,
      colProps: pendingExportColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_APPROVED_EMPTY,
      defaultSortColumn: 'approvedDate',
      toolTip: 'id',
      toolTipDefinition: dictionary.REQ_ID_TOOLTIP,
    },
    {
      testId: 'past-exports-box',
      title: dictionary.PAST_EXPORT_HEADER,
      reqs: waitingForPoRequisitions,
      colProps: pastExportColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_APPROVED_EMPTY,
      defaultSortColumn: 'processedDate',
      toolTip: 'id',
      toolTipDefinition: dictionary.REQ_ID_TOOLTIP,
    },
  ];

  return (
    <>
      {requisitionTables.map((table) => (
        <div key={table.testId} className={utilClasses.mb2} data-testid={table.testId}>
          <h2 className={utilClasses.mt0}>{table.title}</h2>
          <InterfacesReqTable
            reqs={table.reqs}
            colProps={table.colProps}
            noDataMsg={table.noDataMsg}
            defaultSortColumn={table.defaultSortColumn}
            toolTip={table.toolTip}
            toolTipDef={table.toolTipDefinition}
          />
        </div>
      ))}
    </>
  );
};
