import { HospitalProductDetails, HospitalProductEditRequest, HospitalProductPatchRequest, HospitalProductSearchResult } from '../Data/HospitalProduct';
import apiClient from './apiClient';

export const hospitalProductsSearchUrl = '/hospitalProducts/search';
export const hospitalProductDetailsUrl = (id: number): string => `/hospitalProducts/${id}`;

const searchHospitalProducts = async (searchString: string, healthSystemId?: number): Promise<HospitalProductSearchResult[]> =>
  apiClient.post({ url: hospitalProductsSearchUrl, data: { searchString, healthSystemId } });

const getHospitalProductById = async (id: number): Promise<HospitalProductDetails> =>
  apiClient.get({ url: hospitalProductDetailsUrl(id) });

const updateHospitalProduct = async (id: number, product: HospitalProductEditRequest): Promise<void> =>
  apiClient.put({ url: hospitalProductDetailsUrl(id), data: product });

const patchHospitalProduct = async (id: number, product: HospitalProductPatchRequest): Promise<{ id: number }> =>
  apiClient.patch({ url: hospitalProductDetailsUrl(id), data: product });

export const hospitalProductService = {
  search: searchHospitalProducts,
  getById: getHospitalProductById,
  update: updateHospitalProduct,
  patch: patchHospitalProduct,
};
