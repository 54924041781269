import { FormikErrors, FormikValues } from 'formik';
import { dictionary } from '../../../dictionary';
import { createDescriptor, getFieldDescriptorMap, getFormikInitialValues } from '../../../Utils/formik.utils';
import { validateCharacterLimit, validateEmailFormat, validatePhoneNumber, validateRequiredFields } from '../../../Utils/validationUtils';
import { HospitalBase, HospitalRequest } from '../../../Data/Hospital';
import { HealthSystem } from '../../../Data/HealthSystem';

export interface HospitalFormFields {
  healthSystem: HealthSystem
  name: string;
  contactEmail: string;
  contactPhoneNumber: string;
  contactMessage: string;
}

export const hospitalFormFields = getFieldDescriptorMap<HospitalFormFields>([
  createDescriptor({ name: 'healthSystem', label: dictionary.HEALTH_SYSTEM_NAME_LABEL, required: true }),
  createDescriptor({ name: 'name', label: dictionary.HOSPITAL_FORM_NAME, required: true }),
  createDescriptor({ name: 'contactEmail', label: dictionary.HOSPITAL_FORM_CONTACT_EMAIL, required: true }),
  createDescriptor({ name: 'contactPhoneNumber', label: dictionary.HOSPITAL_FORM_CONTACT_PHONE, required: true }),
  createDescriptor({ name: 'contactMessage', label: dictionary.HOSPITAL_FORM_CONTACT_MSG, required: true, defaultVal: dictionary.HOSPITAL_CONTACT_MSG_DEFAULT }),
]);

export const getHospitalFormInitialVals = (hospital?: HospitalBase, healthSystems?: HealthSystem[]): HospitalFormFields =>
  getFormikInitialValues<HospitalFormFields>(
    hospitalFormFields,
    {
      healthSystem: hospital ? healthSystems?.find(hs => hs.hospitalStubs.map(h => h.id).includes(hospital!.id)) : undefined,
      name: hospital?.name,
      contactEmail: hospital?.contactEmail,
      contactPhoneNumber: hospital?.contactPhoneNumber,
      contactMessage: hospital?.contactMessage,
    }
  );

export const validateHospitalForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { name, contactEmail, contactMessage, contactPhoneNumber } = hospitalFormFields;

  return {
    ...validateCharacterLimit(values, [name], 255),
    ...validateCharacterLimit(values, [contactPhoneNumber], 10),
    ...validateCharacterLimit(values, [contactEmail], 320),
    ...validateCharacterLimit(values, [contactMessage], 500),
    ...validateEmailFormat(values, contactEmail),
    ...validatePhoneNumber(values, contactPhoneNumber),
    ...validateRequiredFields(values, Object.values(hospitalFormFields)),
  };
};

export const getHospitalUpdateRequestFromFormFields = (values: HospitalFormFields): HospitalRequest =>
  ({
    healthSystemId: values.healthSystem.id,
    name: values.name,
    contactEmail: values.contactEmail,
    contactPhoneNumber: values.contactPhoneNumber,
    contactMessage: values.contactMessage,
  });
