import { ReactElement } from 'react';
import { Column } from 'react-table';
import { dictionary } from '../../../../../dictionary';
import { ApiArchivedRequisition, ArchivedReqStub } from '../../../../../Data/Requisition';
import { StatusRenderReqCell } from '../../../../../Shared/ReqStatusBadge/ReqStatusCell';
import { ColumnProperty } from '../../../../../Shared/SortableTable/SortableTable.types';
import { getDisplayStatus } from '../../../shared/requisition.utils';
import { toCurrency } from '../../../../../Utils/formatNumber';
import { SortValues } from '../../../../../Shared/SortableTable/useSortedRows';
import { nonAlphanumericRegexGlobal } from '../../../../../Utils/validationUtils';
import { buildTableCols } from '../../../../../Shared/tables/tableUtils';
import { getTruncatedCellComponent } from '../../../../../Shared/tables/TruncatedCell';
import { getSlashDateCellComponent } from '../../../../../Shared/tables/SlashDateCell';

export const renderStatusReqCell = (val: string): ReactElement => <StatusRenderReqCell value={val} />;

export type ArchivedReqForIndexTable = Pick<ApiArchivedRequisition,
|'id'
|'createdDate'
|'caseDate'
|'actionReason'
|'approvedDate'
|'processedDate'
|'statusUpdatedDate'
|'poNumber'
|'canCurrentUserApprove'
> & {
  status: string;
  statusCode: string;
  hospitalName: string;
  manufacturerName: string;
  departmentName: string;
  value: string;
  mrn: string;
};

export const getRequisitionRows = (reqs: ArchivedReqStub[]): ArchivedReqForIndexTable[] =>
  reqs.map(req => ({
    status: getDisplayStatus(req),
    statusCode: req.status || '',
    id: req.id || 0,
    createdDate: req.createdDate,
    hospitalName: req.hospital?.name || '',
    departmentName: req.department?.name || '',
    manufacturerName: req.manufacturer?.name || '',
    value: toCurrency(req.totalPrice),
    caseDate: req.caseDate,
    actionReason: req.actionReason || '',
    approvedDate: req.approvedDate,
    processedDate: req.processedDate,
    statusUpdatedDate: req.statusUpdatedDate,
    poNumber: req.poNumber,
    canCurrentUserApprove: req.canCurrentUserApprove || false,
    mrn: req.patientMrn,
  }));

export type ArchivedReqTableCols = ColumnProperty<ArchivedReqForIndexTable>[];

export const shouldIncludeReqInResults = (filterInput: string, req: ArchivedReqForIndexTable, colName?: keyof ArchivedReqForIndexTable): boolean => {
  if (!colName) {
    return true;
  }
  const filterColValue = req[colName]?.toString().toLocaleLowerCase() || '';
  const filterInputValue = colName === 'mrn' ? filterInput.replace(nonAlphanumericRegexGlobal, '') : filterInput;

  // Should fall into left part of ternary unless a non-string column is added to col options.
  return typeof filterColValue === 'string' ? (filterColValue.toLocaleLowerCase().indexOf(filterInputValue.toLocaleLowerCase()) !== -1) : true;
};

export const ArchivedRequisitionColumnProperties = buildTableCols<ArchivedReqForIndexTable>([
  { Header: dictionary.REQ_STATUS, accessor: 'status', Cell: StatusRenderReqCell },
  { Header: dictionary.REQ_ID, accessor: 'id', Cell: getTruncatedCellComponent(50) },
  { Header: dictionary.REQ_PO_NUM, accessor: 'poNumber' },
  { Header: dictionary.REQ_HOSPITAL, accessor: 'hospitalName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_DEPT, accessor: 'departmentName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_MRN, accessor: 'mrn', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_COMPLETION_DATE, accessor: 'statusUpdatedDate', Cell: getSlashDateCellComponent() },
]);

export type ArchivedReqTableForIndex = {
  testId: string;
  title: string;
  reqs: ArchivedReqForIndexTable[];
  colProps: Column<ArchivedReqForIndexTable>[];
  noDataMsg: string;
  sortOrder?: SortValues;
};
