import { ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApprovalRoutingForTable } from '../../../Data/ApprovalRouting';
import { fetchApprovalRoutings } from '../../../Services/ApprovalRoutingService';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { ApprovalRoutingsTable } from './ApprovalRoutingsTable';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../redux/initialState';
import { dictionary } from '../../../dictionary';
import { routes } from '../../../Utils/routes';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { currentUserIsAdminForAtLeastOneHospital } from '../../../redux/user/userSelectors';
import { If } from '../../../Shared/If';

export function ApprovalRoutingsIndex(): ReactElement {
  const [approvalRoutes, setApprovalRoutes] = useState<ApprovalRoutingForTable[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const showCreateButton = useSelector(currentUserIsAdminForAtLeastOneHospital);

  useEffect(() => {
    const fn = async (): Promise<void> => {
      try {
        const results: ApprovalRoutingForTable[] = await fetchApprovalRoutings();
        setApprovalRoutes(results);
        setIsLoading(false);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
      }
    };
    fn();
  }, [dispatch]);

  const handleRowClick = (approvalRouting: ApprovalRoutingForTable) => {
    history.push(routes.approvalRoutings.edit(approvalRouting.id));
  };

  return (
    <>
      <PageHeader title={dictionary.APPROVAL_ROUTES_HEADER_LABEL}>
        <If condition={showCreateButton}>
          <LinkButton to={routes.approvalRoutings.new}>
            {dictionary.APPROVAL_ROUTES_NEW_BUTTON_LABEL}
          </LinkButton>
        </If>
      </PageHeader>

      <ApprovalRoutingsTable
        capitations={approvalRoutes}
        isLoading={isLoading}
        handleRowClick={handleRowClick}
      />
    </>
  );
}
