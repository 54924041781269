import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Department, { DepartmentEditRequest } from '../../../Data/Department';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { departmentService } from '../../../Services/DepartmentService';
import { ArchiveButton } from '../../../Shared/buttons/ArchiveButton';
import { createArchiveModalComponent } from '../../../Shared/modals/ArchiveModal';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';

interface ArchiveDeptPageHeaderProps {
  department: Department;
  onSuccess: (updatedDept: Department) => void;
}

export const ArchiveDeptPageHeader: FC<ArchiveDeptPageHeaderProps> = ({ department, onSuccess }) => {
  const dispatch = useDispatch();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const toggleArchived = useCallback(async (departmentRequest: DepartmentEditRequest) => {
    const deptRequest = { ...departmentRequest, archived: !department?.archived };

    try {
      await departmentService.update(department.id, deptRequest);
      if (!department?.archived) dispatch(addNotification(NotificationType.success, dictionary.ARCHIVE_DEPARTMENT_SUCCESS));
      else dispatch(addNotification(NotificationType.success, dictionary.ACTIVATE_DEPARTMENT_SUCCESS));

      onSuccess({ ...department!, archived: deptRequest.archived });
    } catch (e) {
      dispatch(addNotification(NotificationType.error, dictionary.ARCHIVE_DEPARTMENT_ERROR));
    }
  }, [department, dispatch, onSuccess]);

  const openArchiveModal = () => setIsArchiveModalOpen(true);
  const closeArchiveModal = () => setIsArchiveModalOpen(false);

  const toggleArchivedAndCloseModal = useCallback(() => {
    toggleArchived(department!);
    closeArchiveModal();
  }, [department, toggleArchived]);

  const archiveBtnText = useMemo(() => (department?.archived ? dictionary.ACTIVATE_DEPARTMENT_BTN : dictionary.ARCHIVE_DEPARTMENT_BTN), [department?.archived]);
  const archiveBtnOnClick = useMemo(() => (department?.archived ? toggleArchivedAndCloseModal : openArchiveModal), [department?.archived, toggleArchivedAndCloseModal]);
  const ArchiveDepartmentModal = useMemo(() => createArchiveModalComponent(dictionary.ARCHIVE_DEPARTMENT_MODAL_HEADER, dictionary.ARCHIVE_DEPARTMENT_MODAL_TEXT), []);

  return (
    <>
      <PageHeader title={dictionary.EDIT_DEPARTMENT_PAGE_HEADER}>
        <ArchiveButton archived={!!department?.archived} onClick={archiveBtnOnClick}>{archiveBtnText}</ArchiveButton>
      </PageHeader>
      <ArchiveDepartmentModal isOpen={isArchiveModalOpen} onConfirm={toggleArchivedAndCloseModal} onDismiss={closeArchiveModal} />
    </>
  );
};
