import { FormikErrors, FormikValues } from 'formik';
import { DateTime } from 'luxon';
import { ManufacturerBase } from '../../../Data/Manufacturer';
import { dictionary } from '../../../dictionary';
import { requiredMsg, isInRangeInclusive, twoDecimalNumRegEx, dateRegEx } from '../../../Utils/validationUtils';

export interface ContractFormFields {
  healthSystem: string,
  hospitals: string,
  manufacturer: string,
  manufacturerIdentifier?: string,
  contractIdentifier: string,
  effectiveDate: string,
  expirationDate: string,
  wastedDiscount?: string,
  explantDiscount?: string,
  trialDiscount?: string,
}

export const validateContractParticipantsNew = (values: FormikValues, manufacturerOptions: ManufacturerBase[]): FormikErrors<ContractFormFields> => {
  const { healthSystem, manufacturer } = values;

  const errors: FormikErrors<ContractFormFields> = {};

  if (!healthSystem || !healthSystem.id) {
    errors.healthSystem = requiredMsg(dictionary.HEALTH_SYSTEM_NAME_LABEL);
  }

  if (!manufacturer || !manufacturer.id || !manufacturerOptions.find((x: ManufacturerBase) => x.id === manufacturer.id)) {
    errors.manufacturer = requiredMsg(dictionary.CONTRACT_MANUFACTURER_LABEL);
  }

  return errors;
};

export const validateContractBaseInfo = (values: FormikValues): FormikErrors<ContractFormFields> => {
  const { contractIdentifier, wastedDiscount, explantDiscount, trialDiscount, effectiveDate, expirationDate } = values;
  const errors: FormikErrors<ContractFormFields> = {};

  if (!contractIdentifier) { errors.contractIdentifier = requiredMsg(dictionary.CONTRACT_ID_LABEL); }

  // discount rates validation
  const discountRangeError = dictionary.NOT_IN_RANGE_ERROR(0, 100);
  if (!!wastedDiscount && !isInRangeInclusive(wastedDiscount, 0, 100)) {
    errors.wastedDiscount = discountRangeError;
  } else if (!!wastedDiscount && !twoDecimalNumRegEx.test(wastedDiscount)) {
    errors.wastedDiscount = dictionary.ONLY_TWO_DECIMAL_ERROR;
  }
  if (!!explantDiscount && !isInRangeInclusive(explantDiscount, 0, 100)) {
    errors.explantDiscount = discountRangeError;
  } else if (!!explantDiscount && !twoDecimalNumRegEx.test(explantDiscount)) {
    errors.explantDiscount = dictionary.ONLY_TWO_DECIMAL_ERROR;
  }
  if (!!trialDiscount && !isInRangeInclusive(trialDiscount, 0, 100)) {
    errors.trialDiscount = discountRangeError;
  } else if (!!trialDiscount && !twoDecimalNumRegEx.test(trialDiscount)) {
    errors.trialDiscount = dictionary.ONLY_TWO_DECIMAL_ERROR;
  }

  // date validations
  if (!effectiveDate) {
    errors.effectiveDate = requiredMsg(dictionary.CONTRACT_EFFECTIVE_DATE_LABEL);
  } else if (!dateRegEx.test(effectiveDate) || !DateTime.fromFormat(effectiveDate, 'MM/dd/yyyy').isValid) {
    errors.effectiveDate = dictionary.CONTRACT_INVALID_EFFECTIVE_DATE;
  }

  if (!expirationDate) {
    errors.expirationDate = requiredMsg('Expiration Date');
  } else if (!dateRegEx.test(expirationDate) || !DateTime.fromFormat(expirationDate, 'MM/dd/yyyy').isValid) {
    errors.expirationDate = dictionary.CONTRACT_INVALID_EXPIRATION_DATE;
  }

  if (!!effectiveDate && !!expirationDate && (Date.parse(effectiveDate) > Date.parse(expirationDate))) {
    errors.expirationDate = dictionary.CONTRACT_EFFECTIVE_DATE_BEFORE_EXPIRATION_ERROR;
  }

  return errors;
};
