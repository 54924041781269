import { FC, useCallback, useEffect, useRef, useState } from 'react';

const focusableElementsSelector = '[tabindex], a, button, input, select, textarea';

export const FocusTrap: FC = ({ children }) => {
  const focusTrapRef = useRef<HTMLInputElement | HTMLDivElement>(null);
  const startFocusGuardRef = useRef<HTMLInputElement | HTMLDivElement>(null);
  const endFocusGuardRef = useRef<HTMLInputElement | HTMLDivElement>(null);
  const [focusableChildren, setFocusableChildren] = useState<(HTMLInputElement)[]>([]);

  // On load, capture all focusable child elements
  useEffect(() => {
    setFocusableChildren(Array.from(focusTrapRef.current?.querySelectorAll(focusableElementsSelector) || []));
  }, []);

  // After focusable children are loaded, focus the first child
  useEffect(() => {
    const enabledFocusables = focusableChildren.filter(c => !c.disabled);
    enabledFocusables[1]?.focus();
  }, [focusableChildren]);

  // When tabbing back past the start, focus the last child element
  const onFocusStart = useCallback(() => {
    const enabledFocusables = focusableChildren.filter(c => !c.disabled);
    focusableChildren[enabledFocusables.length - 2]?.focus();
  }, [focusableChildren]);

  // When tabbing forward past the end, focus the first child element
  const onFocusEnd = useCallback(() => {
    const enabledFocusables = focusableChildren.filter(c => !c.disabled);
    enabledFocusables[1].focus();
  }, [focusableChildren]);

  return (
    <div ref={focusTrapRef}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div ref={startFocusGuardRef} tabIndex={0} onFocus={onFocusStart} />

      {children}

      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div ref={endFocusGuardRef} tabIndex={0} onFocus={onFocusEnd} />
    </div>
  );
};
