import { createUseStyles } from 'react-jss';
import colors from './colors';
import { fontWeights } from './fontWeights';
import 'draft-js/dist/Draft.css';

const fontStack = 'Barlow, Arial, Helvetica, sans-serif';
const inputHeight = '32px';

export const useGlobalStyles = createUseStyles({
  '@global': {
    hr: {
      border: 'none',
      height: '1px',
      backgroundColor: colors.darkGray,
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    '*': {
      boxSizing: 'border-box',
    },
    'html, body': {
      minHeight: '100%',
    },
    body: {
      margin: 0,
      fontFamily: fontStack,
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    'button, input, textarea': {
      fontFamily: fontStack,
    },
    code: {
      whiteSpace: 'pre',
      fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, \'Courier New\', monospace',
    },

    a: {
      color: colors.blue,
    },

    // Typography
    h1: {
      fontSize: '2rem',
      fontWeight: fontWeights.light,
      color: colors.nearBlack,
      lineHeight: 1.25,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: fontWeights.bold,
      color: colors.nearBlack,
      marginTop: '23px',
    },
    h3: {
      color: colors.nearBlack,
      marginBottom: '1.3125rem',
      fontSize: '1.3125rem',
      fontWeight: fontWeights.bold,
    },

    // Details - TODO: move out
    '.details': {
      marginTop: 0,
    },
    '.details-section-grid': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    '.details-section': {
      padding: '8px 16px 16px 16px',
      backgroundColor: colors.nearWhite,
      marginBottom: '8px',
    },
    '.details-page-section': {
      width: '100%',
      marginTop: '24px',
    },
    '.details-label': {
      fontWeight: fontWeights.bold,
      fontSize: '0.875rem',
    },

    // Forms
    label: {
      display: 'block',
      marginTop: '1rem',
      fontSize: '0.875rem',
      fontWeight: fontWeights.bold,
      '&.no-text': {
        marginTop: 0,
      },
    },
    '.input-form': {
      '& input:not([type="checkbox"])': {
        width: '100%',
        height: inputHeight,
        display: 'block',
        borderRadius: '.25rem',
        lineHeight: '50%',
        borderWidth: '1px',
        borderStyle: 'solid',
        margin: '8px 0',
        padding: '2px 20px',
        boxSizing: 'border-box',
        '&:focus': {
          outline: 'none',
          boxShadow: `0 0 0 2px ${colors.kingfisher}`,
          borderColor: colors.blue,
        },
        '.input-invalid-field &': {
          borderColor: colors.red,
        },
      },
    },
    '.text-input': {
      backgroundColor: colors.trueWhite,
      color: colors.gray,
      display: 'block',
      width: '100%',
      height: inputHeight,
      borderRadius: '0.25rem',
      border: `0.0625rem solid ${colors.gray}`,
      margin: '0.5rem 0',
      padding: '0.125rem 1.25rem',
      fontSize: '1rem',
      background: 'transparent',
      zIndex: '1',
      '&:focus': {
        outline: 'none',
        boxShadow: `0 0 0 0.125rem ${colors.coastBlue}`,
        borderColor: colors.blue,
      },
      '&:disabled, &:disabled:hover': {
        backgroundColor: colors.disabled,
      },
      '&::placeholder': {
        fontSize: '0.875rem',
      },
    },
    '.textarea': {
      backgroundColor: colors.trueWhite,
      color: colors.gray,
      display: 'block',
      width: '100%',
      borderRadius: '0.25rem',
      border: `0.0625rem solid ${colors.gray}`,
      margin: '0.5rem 0',
      padding: '0.125rem 1.25rem',
      fontSize: '1rem',
      background: 'transparent',
      zIndex: '1',
      '&:focus': {
        outline: 'none',
        boxShadow: `0 0 0 0.125rem ${colors.coastBlue}`,
        borderColor: colors.blue,
      },
      '&:disabled, &:disabled:hover': {
        backgroundColor: colors.disabled,
      },
      '&::placeholder': {
        fontSize: '0.875rem',
      },
    },
    '.input-invalid-field': {
      borderColor: colors.red,
    },
    '.label-invalid-field': {
      color: colors.red,
    },
    '.validation-errors': {
      color: colors.red,
      fontWeight: fontWeights.normal,
      display: 'inline-block',
      fontSize: '14px',
    },

    // Autocomplete - TODO: move into autocomplete styles
    '.auto-complete-arrow-icon': {
      position: 'absolute',
      top: '0.9rem',
      right: '0.9rem',
      fontSize: '1.5em',
      zIndex: 1,
    },
    '.Mui-focused .auto-complete-arrow-icon, input:not([value=""]):hover + .auto-complete-arrow-icon': {
      display: 'none',
    },

    // Product Info specific styles - TODO: move out
    '.form-group-transactionType': {
      marginTop: 20,
      '& label': {
        fontWeight: fontWeights.normal,
        display: 'flex',
      },
    },
    '.discount-label-container': {
      display: 'flex',
      flexWrap: 'wrap',
      '& .discount-left span': {
        display: 'block',
      },
      '& .discount-right': {
        width: '168px',
        paddingLeft: '10px',
        alignSelf: 'flex-end',
      },
    },

    // DraftJS editor
    '.public-DraftEditor-content': {
      padding: '2.4rem',
    },
    '.public-DraftEditorPlaceholder-root': {
      margin: '2.4rem',
    },
    '.public-DraftStyleDefault-block': {
      margin: '1em 0',
    },

    // IE overrides
    'input::-ms-clear': {
      display: 'none',
      width: 0,
      height: 0,
    },

    // Miscellaneous
    'div.validation-error': {
      color: colors.red,
    },
  },
});
