import { FormikErrors, FormikValues } from 'formik';
import { DepartmentBase } from '../../../../Data/Department';
import { HospitalUserRoleOption } from '../../../../Data/User';
import { ManufacturerBase } from '../../../../Data/Manufacturer';
import { dictionary } from '../../../../dictionary';
import { createDescriptor, getFieldDescriptorMap } from '../../../../Utils/formik.utils';
import { validateRequiredFields } from '../../../../Utils/validationUtils';

export interface EditUserAssociationFields {
  departments: DepartmentBase[];
  userRole?: HospitalUserRoleOption;
  manufacturers?: ManufacturerBase[];
}

export const getEditUserAssociationFields = (isVendor: boolean) => getFieldDescriptorMap<EditUserAssociationFields>([
  createDescriptor({ name: 'departments', label: dictionary.FORM_CONTROL_LABEL_DEPARTMENTS, required: !isVendor, inputFormat: 'multi-select' }),
  createDescriptor({ name: 'userRole', label: dictionary.USER_ASSOCIATIONS_EDIT_USER_ROLE, required: true }),
  createDescriptor({ name: 'manufacturers', label: dictionary.FORM_CONTROL_LABEL_MANUFACTURERS, inputFormat: 'multi-select' }),
]);

export const validateEditVendorUserAssociationForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> =>
  validateRequiredFields(values, Object.values(getEditUserAssociationFields(true)));

export const validateEditHospitalUserAssociationForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> =>
  validateRequiredFields(values, Object.values(getEditUserAssociationFields(false)));
