import { FC, useMemo } from 'react';
import { Column, TableInstance, TableState, usePagination, UsePaginationInstanceProps, useSortBy, UseSortByState, useTable } from 'react-table';
import { SortableTableHeader } from '../../../../Shared/ReactTable/SortableTableHeader';
import { TableBody } from '../../../../Shared/ReactTable/TableBody';
import { TableFooter } from '../../../../Shared/ReactTable/TableFooter';
import { SortableTableProps } from '../../../../Shared/SortableTable/SortableTable';
import { useTableStyles } from '../../../../Themes/table.styles';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { ContractProductForTable } from './productsOnContractTable.utils';

interface ProductsBaseTableProps {
  formattedProducts: ContractProductForTable[];
  columns: Column<ContractProductForTable>[];
  noDataMessage: string;
  isLoading: boolean;
  initialSortOrder?: SortableTableProps<ContractProductForTable>['defaultSortDirection'];
  initialSortColumn?: string;
}

export const ProductsBaseTable: FC<ProductsBaseTableProps> = (props) => {
  const { formattedProducts: data, columns, isLoading, noDataMessage, initialSortColumn = 'catalogNumber', initialSortOrder = 'desc' } = props;
  const initialSortCol = initialSortColumn;

  const initialState: Partial<TableState<ContractProductForTable> & UseSortByState<ContractProductForTable>> = useMemo(() =>
    ({ sortBy: [{ id: initialSortCol, [initialSortOrder]: true }], pageSize: 30 }), [initialSortOrder, initialSortCol]);

  const tableContext = useTable({ columns, data, getRowId: item => item.id.toString(), initialState }, useSortBy, usePagination,) as
    unknown as TableInstance<ContractProductForTable> & UsePaginationInstanceProps<ContractProductForTable>;

  const tblClasses = useTableStyles({ hasTableContainer: true });
  const reactTableProps = tableContext.getTableProps();

  return (
    <section className={tblClasses.container}>
      <div className={tblClasses.scrollableContainer}>
        <table {...reactTableProps} className={joinArgs(reactTableProps.className, tblClasses.root)}>
          <SortableTableHeader tableContext={tableContext} />
          <TableBody tableContext={tableContext} rows={tableContext.page} isLoading={isLoading} noDataMessage={noDataMessage} />
        </table>
      </div>
      <TableFooter tableContext={tableContext} />
    </section>
  );
};
