import { AnyAction } from 'redux';
import initialState, { ContractState } from '../initialState';
import { actionTypes } from '../actionTypes';
import { ContractProduct } from '../../Data/Contract';

export default function contractReducer(state = initialState.contract, action: AnyAction): ContractState {
  if (action.type === actionTypes.contract.updateNew) {
    return {
      ...state,
      newContract: {
        ...state.newContract,
        ...action.contract,
      },
    };
  }

  if (action.type === actionTypes.contract.clearNew) {
    return {
      ...state,
      newContract: initialState.contract.newContract,
    };
  }

  if (action.type === actionTypes.contract.addProducts) {
    const oldProducts = state?.newContract?.products ?? [];
    const newProducts = action?.newProductPricings ?? [];
    const newCatalogNumbers = newProducts.map((p: ContractProduct) => p.catalogNumber);
    const oldMinusNew = oldProducts.filter((p) => !newCatalogNumbers.includes(p.catalogNumber));
    return {
      ...state,
      newContract: {
        ...state.newContract,
        products: [...oldMinusNew, ...newProducts],
      },
    };
  }

  if (action.type === actionTypes.contract.setProductUploadErrors) {
    return {
      ...state,
      newContract: {
        ...state.newContract,
        errorFailedLines: [...action?.failedLines ?? []],
      },
    };
  }

  if (action.type === actionTypes.contract.updateEditing) {
    return {
      ...state,
      editContract: {
        ...action.contract,
      },
    };
  }

  if (action.type === actionTypes.contract.clearEditing) {
    return {
      ...state,
      editContract: initialState.contract.editContract,
    };
  }

  if (action.type === actionTypes.contract.addProductsEditing) {
    const oldProducts = state?.editContract?.products ?? [];
    const newProducts = action?.newProductPricings ?? [];
    const newCatalogNumbers = newProducts.map((p: ContractProduct) => p.catalogNumber);
    const oldMinusNew = oldProducts.filter((p) => !newCatalogNumbers.includes(p.catalogNumber));
    return {
      ...state,
      editContract: {
        ...state.editContract,
        products: [...oldMinusNew, ...newProducts],
      },
    };
  }

  if (action.type === actionTypes.contract.setProductUploadErrorsEditing) {
    return {
      ...state,
      editContract: {
        ...state.editContract,
        errorFailedLines: [...action?.failedLines ?? []],
      },
    };
  }

  return state;
}
