import { FC } from 'react';
import { dictionary } from '../../../../dictionary';
import { routes } from '../../../../Utils/routes';
import { createVendorUser } from '../../../../Services/UserService';
import { CreateOrEditUser } from '../../shared/CreateOrEditUser/CreateOrEditUser';
import { CreateVendorUserRequest } from '../../../../Data/User';

export const CreateVendorUser: FC = () => (
  <CreateOrEditUser<CreateVendorUserRequest>
    pageHeader={dictionary.CREATE_VENDOR_USER_ACCOUNT}
    completeButtonLabel={dictionary.CREATE_NEW_USER_BUTTON_LABEL}
    onDoneRoutePath={routes.users.vendors.index}
    submitRequest={createVendorUser}
  />
);
