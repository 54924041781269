import { ReactElement, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { CapitationsTable } from '../CapitationsTable/CapitationsTable';
import Capitation from '../../../Data/Capitation';
import { fetchCapitations } from '../../../Services/capitationService';
import { routes } from '../../../Utils/routes';
import { dictionary } from '../../../dictionary';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { currentUserIsAdminForAtLeastOneHospital } from '../../../redux/user/userSelectors';
import { If } from '../../../Shared/If';
import { ErrorPage } from '../../../Shared/ErrorPage';
import LoadState from '../../../redux/loadState';

export function CapitationsIndex(): ReactElement {
  const [capitations, setCapitations] = useState<Capitation[]>([]);
  const [loadState, setLoadState] = useState(LoadState.loading);
  const history = useHistory();
  const showCreateButton = useSelector(currentUserIsAdminForAtLeastOneHospital);

  useEffect(() => {
    (async () => {
      const fetchedCapitations = await fetchCapitations();
      try {
        if (fetchedCapitations) {
          setCapitations(fetchedCapitations);
          setLoadState(LoadState.loaded);
        }
      } catch (err) {
        setLoadState(LoadState.error);
      }
    })();
  }, []);

  if (loadState === LoadState.error) {
    return <ErrorPage />;
  }

  const handleRowClick = (capitation: Capitation) => {
    history.push(routes.capitations.edit(capitation.id));
  };

  return (
    <>
      <PageHeader title={dictionary.CAPITATIONS_LIST_HEADER_LABEL}>
        <If condition={showCreateButton}>
          <LinkButton
            data-testid="create-capitation"
            to={routes.capitations.new}
          >
            {dictionary.CAPITATIONS_CREATE_NEW_ACTION}
          </LinkButton>
        </If>
      </PageHeader>

      <CapitationsTable
        capitations={capitations ?? []}
        handleRowClick={handleRowClick}
        isLoading={[LoadState.notLoaded, LoadState.loading].includes(loadState)}
      />
    </>
  );
}
