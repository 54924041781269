import { FC, useEffect, useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../Shared/buttons/Button';
import { dictionary } from '../../../../dictionary';
import { Modal } from '../../../../Shared/modals/Modal/Modal';
import { EditReqProductForm } from './EditReqProductForm';
import RequisitionProduct from '../../../../Data/RequisitionProduct';
import { getFormikInitialValues } from '../../../../Utils/formik.utils';
import { EditReqProductFields, getEditReqProductFields, validateEditReqProductForm } from './editReqProduct.utils';
import { updateProductAction } from '../../../../redux/requisition/requisitionActions';
import { hospitalProductService } from '../../../../Services/hospitalProductService';
import { HospitalProductDetails } from '../../../../Data/HospitalProduct';
import { addNotification } from '../../../../redux/notifications/notificationsActionCreator';
import { NotificationType, ReduxState } from '../../../../redux/initialState';
import { hospitalService } from '../../../../Services/HospitalService';
import { ProductCategoryStub } from '../../../../Data/ProductCategory';
import { currentUserRoleSelector } from '../../../../redux/user/userSelectors';
import { userRoles } from '../../../../Utils/userRoles';
import { contractService } from '../../../../Services/ContractService';
import { HospitalManufacturerContract } from '../../../../Data/Contract';

interface EditReqProductFormModalProps {
  productInfo?: [RequisitionProduct, number | undefined, number];
  requisitionId?: number;
  closeModal: () => void;
}

export const EditReqProductFormModal: FC<EditReqProductFormModalProps> = (props) => {
  const { productInfo = undefined, requisitionId = undefined, closeModal } = props;
  const [product, capitationIndex, productIndex] = productInfo || [];
  const [hospitalProduct, setHospitalProduct] = useState<HospitalProductDetails>();
  const [activeContracts, setActiveContracts] = useState<HospitalManufacturerContract[]>();
  const [hospitalProductCategories, setHospitalProductCategories] = useState<ProductCategoryStub[]>();
  const healthSystemId = useSelector((state: ReduxState) => state.requisition.editRequisition.hospital?.healthSystemId);
  const hospitalId = useSelector((state: ReduxState) => state.requisition.editRequisition.hospital!.id);
  const caseDate = useSelector((state: ReduxState) => state.requisition.editRequisition.caseDate!);
  const userIsVendor = useSelector(currentUserRoleSelector) === userRoles.vendor;

  const editReqProductFields = getEditReqProductFields();
  const [submittingForm, setSubmittingForm] = useState(false);

  const initialValues = getFormikInitialValues<EditReqProductFields>(editReqProductFields, {
    lotNumber: product?.lotNumber || undefined,
    serialNumber: product?.serialNumber || undefined,
    price: product?.price?.toString(),
    quantity: product?.quantity?.toString(),
    category: hospitalProduct?.category || undefined,
    orderIdentifier: hospitalProduct?.orderIdentifier || undefined,
    contract: activeContracts?.find(x => x.contractIdentifier === product?.contractIdentifier) || undefined,
    contractPrice: product?.contractPrice?.toString(),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (healthSystemId && !userIsVendor) {
        try {
          const fetchedHospProdCategoriesResponse = await hospitalService.getProductCategoriesByHealthSystem(healthSystemId!);
          setHospitalProductCategories(fetchedHospProdCategoriesResponse.healthSystemProductCategories);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.REQ_EDIT_PRODUCT_CATEGORY_GET_FAIL_ERROR));
        }
      }
    })();
  }, [dispatch, healthSystemId, userIsVendor]);

  useEffect(() => {
    const hospitalProductId = product?.healthSystemProductId;
    (async () => {
      if (hospitalProductId) {
        try {
          const fetchedHospProd = await hospitalProductService.getById(hospitalProductId);
          setHospitalProduct(fetchedHospProd);
          const fetchedContracts = await contractService.getActive(hospitalId, fetchedHospProd.manufacturerId, caseDate, 'any');
          setActiveContracts(fetchedContracts);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.REQ_EDIT_PRODUCT_HOSP_PROD_GET_FAIL_ERROR));
          closeModal();
        }
      } else {
        setHospitalProduct(undefined);
      }
    })();
  }, [closeModal, dispatch, product?.healthSystemProductId, caseDate, hospitalId]);

  const falseyAsNull = <T extends any>(something: T) => (!something ? null : something);
  const onSubmit = async (vals: EditReqProductFields) => {
    let newContractProductId = { id: 0 };
    const { orderIdentifier, category, contractPrice, contract } = vals;
    const userHasEditedHospOrContractProdFields =
      falseyAsNull(hospitalProduct?.category?.id) !== falseyAsNull(category?.id)
      || falseyAsNull(hospitalProduct?.orderIdentifier) !== falseyAsNull(orderIdentifier)
      || falseyAsNull(hospitalProduct?.contractIdentifier) !== falseyAsNull(contract?.contractIdentifier)
      || falseyAsNull(product?.contractPrice) !== falseyAsNull(contractPrice);
    try {
      if (!userIsVendor && userHasEditedHospOrContractProdFields) {
        setSubmittingForm(true);
        newContractProductId = await hospitalProductService.patch(product!.healthSystemProductId!, {
          orderIdentifier: orderIdentifier || undefined,
          categoryId: category?.id || undefined,
          contractId: contract?.id || undefined,
          contractPrice: contract?.id ? contractPrice || undefined : undefined,
          requisitionId,
        });
      }
      // TODO: determine what to do with productIndex given now it needs to know about capitations
      dispatch(updateProductAction(productIndex!, capitationIndex!, {
        ...product,
        productCategoryName: category?.name,
        orderIdentifier,
        contractIdentifier: contract?.contractIdentifier,
        contractProductId: contract?.contractIdentifier ? newContractProductId.id || product?.contractProductId : undefined,
        contractPrice: contract?.id && vals.contractPrice ? Number(vals.contractPrice) : undefined,
        lotNumber: vals.lotNumber,
        serialNumber: vals.serialNumber,
        price: Number(vals.price),
        quantity: Number(vals.quantity),
      }));
      closeModal();
    } catch (e) {
      dispatch(addNotification(NotificationType.error, dictionary.REQ_EDIT_PRODUCT_PATCH_FAIL_ERROR));
    } finally {
      setSubmittingForm(false);
    }
  };

  const formikProps = useFormik<EditReqProductFields>({
    initialValues,
    onSubmit,
    validate: (values) => validateEditReqProductForm(values, editReqProductFields),
    enableReinitialize: true,
  });

  if (!productInfo || !hospitalProduct) {
    return null;
  }

  return (
    <Modal
      data-testid="edit-product-modal"
      title={dictionary.REQ_EDIT_PRODUCT_MODAL_TITLE}
      isOpen={!!productInfo}
      size="md"
      icon={faInfoCircle}
      actionsContent={(
        <>
          <Button buttonStyle="reverse" onClick={() => closeModal()} data-testid="cancel-edit-products-button">
            {dictionary.CANCEL}
          </Button>
          <Button type="submit" onClick={() => { formikProps.submitForm(); }} data-testid="update-products-button" disabled={!!submittingForm}>
            {submittingForm ? dictionary.FORM_SUBMIT_PROCESSING_BUTTON : dictionary.REQ_EDIT_PRODUCT_MODAL_SUBMIT_BTN}
          </Button>
        </>
      )}
    >
      <EditReqProductForm
        formikProps={formikProps}
        reqProduct={product!}
        productIndex={productIndex!}
        capitationIndex={capitationIndex!}
        activeContracts={activeContracts || []}
        chosenContractIdentifier={formikProps.values.contract?.contractIdentifier}
        hospitalProduct={hospitalProduct}
        productCategories={hospitalProductCategories}
        editReqProductFields={editReqProductFields}
        isProductCapitated={capitationIndex !== undefined}
      />
    </Modal>
  );
};
