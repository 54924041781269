import { FormikErrors, FormikValues } from 'formik';
import { HospitalProductDetails, HospitalProductEditRequest } from '../../../Data/HospitalProduct';
import { ProductCategoryStub } from '../../../Data/ProductCategory';
import { dictionary } from '../../../dictionary';
import { createDescriptor, getFieldDescriptorMap, getFormikInitialValues } from '../../../Utils/formik.utils';
import { nonAlphanumericRegexGlobal, requiredMsg, validateCharacterLimit, validateNumberInRangeInclusive, validateRequiredFields } from '../../../Utils/validationUtils';

export type HospitalProductEditFormFields = HospitalProductEditRequest & {
  category: ProductCategoryStub;
};

export const hospitalProductEditFormFields = getFieldDescriptorMap<HospitalProductEditFormFields>([
  createDescriptor({ name: 'contractId', label: dictionary.CONTRACT_ID_LABEL }),
  createDescriptor({ name: 'contractProductId', label: dictionary.EMPTY_FIELD_MARK }),
  createDescriptor({ name: 'contractPrice', label: dictionary.PRODUCT_CONTRACT_PRICE }),
  createDescriptor({ name: 'brandName', label: dictionary.PRODUCT_BRAND_NAME }),
  createDescriptor({ name: 'catalogNumber', label: dictionary.PRODUCT_CATALOG_NUMBER, required: true }),
  createDescriptor({ name: 'description', label: dictionary.PRODUCT_DESCRIPTION, required: true }),
  createDescriptor({ name: 'unitOfMeasure', label: dictionary.PRODUCT_UNIT_OF_MEASURE, required: true }),
  createDescriptor({ name: 'quantityUnitOfMeasure', label: dictionary.PRODUCT_QTY_UNIT_OF_MEASURE, required: true }),
  createDescriptor({ name: 'productType', label: dictionary.PRODUCT_TYPE }),
  createDescriptor({ name: 'orderIdentifier', label: dictionary.PRODUCT_ITEM_IDENTIFIER }),
  createDescriptor({ name: 'category', label: dictionary.PRODUCT_CATEGORY_NAME_LABEL }),
]);

export const getHospitalProductEditFormInitialVals = (product: HospitalProductDetails): HospitalProductEditFormFields =>
  getFormikInitialValues<HospitalProductEditFormFields>(
    hospitalProductEditFormFields,
    {
      contractId: product.contractId,
      contractProductId: product.contractProductId,
      contractPrice: product.contractPrice,
      brandName: product.brandName,
      catalogNumber: product.catalogNumber,
      description: product.description,
      unitOfMeasure: product.unitOfMeasure,
      quantityUnitOfMeasure: product.quantityUnitOfMeasure,
      productType: product.productType,
      orderIdentifier: product.orderIdentifier,
      category: product.category ?? undefined,
    }
  );

export const validateHospitalProductEditForm = async (values: FormikValues): Promise<FormikErrors<FormikValues>> => {
  const { contractPrice, quantityUnitOfMeasure, description, catalogNumber, brandName, productType, orderIdentifier } = hospitalProductEditFormFields;

  const priceValidation = values.contractId ? validateNumberInRangeInclusive(values, [contractPrice], 0, 100_000_000) : {};
  if (values.contractId && !values.contractPrice) {
    priceValidation.contractPrice = requiredMsg(dictionary.PRODUCT_CONTRACT_PRICE);
  }

  return {
    ...validateCharacterLimit(values, [description], 500),
    ...validateCharacterLimit(values, [catalogNumber, brandName, productType, orderIdentifier], 100),
    ...validateNumberInRangeInclusive(values, [quantityUnitOfMeasure], 1, 5_000),
    ...priceValidation,
    ...validateRequiredFields(values, Object.values(hospitalProductEditFormFields)),
  };
};

export const editProductFormFieldsToApiRequest = (values: HospitalProductEditFormFields): HospitalProductEditRequest => ({
  contractId: values.contractId,
  contractProductId: values.contractProductId,
  contractPrice: values.contractPrice,
  brandName: values.brandName,
  catalogNumber: values.catalogNumber,
  cleanCatalogNumber: values.catalogNumber.replace(nonAlphanumericRegexGlobal, ''),
  description: values.description,
  unitOfMeasure: values.unitOfMeasure,
  quantityUnitOfMeasure: values.quantityUnitOfMeasure,
  productType: values.productType,
  orderIdentifier: values.orderIdentifier?.trim().length ? values.orderIdentifier : null,
  categoryId: values.category?.id,
});
