import { FC } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { createUseStyles } from 'react-jss';
import { dictionary } from '../../../dictionary';
import { Modal } from '../../../Shared/modals/Modal/Modal';
import { Button } from '../../../Shared/buttons/Button';

interface ManuallyUnarchiveReqModalProps {
  cancelOnClick: () => void;
  isModalOpen: boolean;
  manuallyUnarchiveReq: (id: number) => void;
  captureReqId: number | undefined;
}

export const ManuallyUnarchiveReqModal: FC<ManuallyUnarchiveReqModalProps> = ({
  cancelOnClick,
  isModalOpen,
  manuallyUnarchiveReq,
  captureReqId }) => {
  const ManuallyUnarchiveReqModalStyles = createUseStyles({
    message: {
      borderBottom: '0.09rem solid #d8d8d8',
      paddingBottom: '1rem',
      fontSize: '1.2rem',
    },
  });

  const classes = ManuallyUnarchiveReqModalStyles();

  return (
    <>
      <Modal
        data-testid="update-information-modal"
        title="Un-Archive Requisition"
        isOpen={isModalOpen}
        icon={faInfoCircle}
        actionsContent={(
          <>
            <Button buttonStyle="reverse" onClick={cancelOnClick} data-testid="modal-cancel">
              {dictionary.CANCEL}
            </Button>
            <Button buttonStyle="primary" onClick={() => manuallyUnarchiveReq(captureReqId!)} data-testid="modal-continue">
              {dictionary.MANUALLY_UNARCHIVE_REQ_CONFORMATION}
            </Button>
          </>
        )}
      >
        <h3 className={classes.message}>
          { dictionary.MANUALLY_UNARCHIVE_REQ_MODAL_QUESTION}
        </h3>
      </Modal>
    </>
  );
};
