import { FC } from 'react';
import Patient from '../../../Data/Patient';
import { dictionary } from '../../../dictionary';
import { SimpleDisplayField } from '../../../Shared/fields/SimpleDisplayField';
import { If } from '../../../Shared/If';
import useUtilityStyles from '../../../Themes/utility.styles';
import { getSlashFormattedDate } from '../../../Utils/dateUtils';
import { formatGender, getPatientDisplayName } from '../../../Utils/patientUtils';

interface Props {
  patient?: Patient | null;
  collapseName?: boolean;
}

export const ReqPatientDisplayFields: FC<Props> = ({ patient, collapseName = true }) => {
  const utilityClasses = useUtilityStyles();

  return (
    <>
      <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_PATIENT_MRN} value={patient?.mrn} />

      <If condition={collapseName}>
        <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_PATIENT_NAME} value={getPatientDisplayName(patient)} />
      </If>
      <If condition={!collapseName}>
        <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_FIRST_NAME} value={patient?.firstName} />
        <If condition={!!patient?.middleName}>
          <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_MIDDLE_NAME} value={patient?.middleName ?? ''} />
        </If>
        <SimpleDisplayField label={dictionary.FORM_CONTROL_LABEL_LAST_NAME} value={patient?.lastName} />
      </If>

      <div className={utilityClasses.flex}>
        <If condition={!!patient?.dateOfBirth}>
          <SimpleDisplayField
            label={dictionary.FORM_CONTROL_LABEL_PATIENT_DOB}
            value={getSlashFormattedDate(patient?.dateOfBirth)}
            className={utilityClasses.flexGrow}
          />
        </If>

        <If condition={!!patient?.gender}>
          <SimpleDisplayField
            label={dictionary.FORM_CONTROL_LABEL_PATIENT_GENDER}
            value={formatGender(patient?.gender)}
            className={utilityClasses.flexGrow}
          />
        </If>
      </div>
    </>
  );
};
