import apiClient from './apiClient';
import { BusinessRulesRequest, HospitalBusinessRuleDescriptionRequest } from '../Data/BusinessRules';
import { DepartmentStub } from '../Data/Department';

export const businessRulesUrl = '/businessrules';
export const businessRulesByDeptUrl = `${businessRulesUrl}/dept`;
export const businessRulesByHosptUrl = `${businessRulesUrl}/hospital`;
export const businessRulesByDeptIdUrl = (businessRulesId: number): string => `${businessRulesByDeptUrl}/${businessRulesId}`;
export const businessRulesByHospIdUrl = (businessRulesId: number): string => `${businessRulesByHosptUrl}/${businessRulesId}`;

const createBusinessRules = async (req: BusinessRulesRequest): Promise<void> =>
  apiClient.post({ url: businessRulesUrl, data: JSON.stringify(req) });

const getBusinessRulesByDeptId = async (businessRulesId: number): Promise<BusinessRulesRequest> =>
  apiClient.get<BusinessRulesRequest>({ url: businessRulesByDeptIdUrl(businessRulesId) });

const getBusinessRulesByHospId = async (businessRulesId: number): Promise<HospitalBusinessRuleDescriptionRequest[]> =>
  apiClient.get<HospitalBusinessRuleDescriptionRequest[]>({ url: businessRulesByHospIdUrl(businessRulesId) });

const updateBusinessRules = async (req: BusinessRulesRequest): Promise<void> =>
  apiClient.put({ url: businessRulesUrl, data: JSON.stringify(req) });

const getBusinessRules = async (): Promise<DepartmentStub[]> =>
  apiClient.get({ url: businessRulesByDeptUrl });

export const businessRulesService = {
  create: createBusinessRules,
  get: getBusinessRules,
  update: updateBusinessRules,
  getBusinessRulesByDeptId,
  getBusinessRulesByHospId,
};
