import { FC } from 'react';
import { Row, UseExpandedRowProps, TableInstance } from 'react-table';
import { RequisitionCapitation, MergedRequisition } from '../../../../../Data/Requisition';
import { dictionary } from '../../../../../dictionary';
import { TableBodyRow } from '../../../../../Shared/ReactTable/TableBodyRow';
import { useCapProductSubtable } from './capitatedProductsTable.utils';

interface CapitationRowProps {
  row: Row<RequisitionCapitation>;
  tableContext: TableInstance<RequisitionCapitation>;
  editable: boolean;
  requisition: MergedRequisition;
  index: number;
  onEditClick: (capitationIndex: number, productIndex: number) => void; // TODO: share this between capProdTable.utils and here
  hospHasProductCategories: boolean;
}

export const CapitationRow: FC<CapitationRowProps> = (props) => {
  const {
    row: uncastRow,
    editable,
    requisition,
    index,
    onEditClick,
    hospHasProductCategories,
  } = props;

  const row = uncastRow as Row<RequisitionCapitation> & UseExpandedRowProps<any>;
  const capProductSubtableContext = useCapProductSubtable(row.original.products, editable, requisition, index, onEditClick, hospHasProductCategories);
  const toolTip = 'catalogNumber';
  const toolTipDef = dictionary.REQ_PRODUCTS_TO_BILL_ID_TOOLTIP;

  return (
    <TableBodyRow
      row={row}
      expandTableContext={capProductSubtableContext}
      data-testid="req-products-capitation-row"
      expandRowInset
      toolTip={toolTip}
      toolTipDef={toolTipDef}
    />
  );
};
