import { FC } from 'react';
import { getSlashFormattedDateTime } from '../../../../Utils/dateUtils';
import { RequisitionHistoryNote, RequisitionHistoryNoteUser } from '../../../../Data/RequisitionHistoryNote';
import { dictionary } from '../../../../dictionary';
import { useNotesTableStyles } from './Notes.styles';

const getNotesFormattedName = (user?: RequisitionHistoryNoteUser | null) =>
  (user?.firstName ? `${user.firstName} ${user.lastName}` : dictionary.EMPTY_FIELD_MARK);
const getFormattedTimestamp = (timestamp: Date | string) => getSlashFormattedDateTime(typeof timestamp === 'string' ? timestamp : timestamp.toISOString());

const Row: FC<RequisitionHistoryNote> = ({ timestamp, actionTaken, user, userRole, comment }) => (
  <tr>
    <td>{getFormattedTimestamp(timestamp)}</td>
    <td>{dictionary.REQ_NOTES_ACTION_COMMENT(actionTaken, userRole)}</td>
    <td>{comment.length ? comment : dictionary.EMPTY_FIELD_MARK}</td>
    <td>{getNotesFormattedName(user)}</td>
  </tr>
);

export interface NotesProps {
  historyNotes: RequisitionHistoryNote[]
}
const Notes: FC<NotesProps> = ({ historyNotes }) => {
  const classes = useNotesTableStyles();

  return (
    <div
      className={classes.root}
      data-testid="notes-container"
    >
      <h2>{dictionary.REQ_NOTES_SECTION_HEADER}</h2>

      <div className={classes.notesTableContainer}>
        {historyNotes?.length ? (
          <table className={classes.notesTable}>
            <thead>
              <tr>
                <th align="left">{dictionary.REQ_NOTES_DATE_LABEL}</th>
                <th align="left">{dictionary.REQ_NOTES_ACTION_LABEL}</th>
                <th align="left">{dictionary.REQ_NOTES_COMMENT_LABEL}</th>
                <th align="left">{dictionary.REQ_NOTES_USER_LABEL}</th>
              </tr>
            </thead>
            <tbody>
              {historyNotes.map((update, index) => <Row key={`history-notes-${String(index)}`} {...update} />)}
            </tbody>
          </table>
        ) : <p style={{ textAlign: 'center' }}>{dictionary.REQ_NOTES_NO_CONTENT}</p>}
      </div>
    </div>
  );
};

export default Notes;
