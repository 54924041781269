import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { HospitalProductDetails } from '../../../Data/HospitalProduct';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { hospitalProductService } from '../../../Services/hospitalProductService';
import { BackButton } from '../../../Shared/buttons/BackButton';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import useUtilityStyles from '../../../Themes/utility.styles';
import { routes } from '../../../Utils/routes';
import { ProductDetailsFields } from './ProductDetailsFields';

export const ProductDetails: FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const productId = Number(params.id);
  const [product, setProduct] = useState<HospitalProductDetails>();
  const [loadingProduct, setLoadingProduct] = useState(true);
  const history = useHistory();
  const routeUserBackToProductSearch = () => { history.push(routes.products.index); };
  const utilClasses = useUtilityStyles();

  useEffect(() => {
    (async () => {
      try {
        const result = await hospitalProductService.getById(productId);
        setProduct(result);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.PRODUCT_DETAILS_LOAD_ERR));
      } finally {
        setLoadingProduct(false);
      }
    })();
  }, [dispatch, productId]);

  if (loadingProduct) {
    return <LoadingSpinner />;
  }
  if (!product) {
    return <ErrorPage />;
  }

  const loadedProduct = product!;

  return (
    <>
      <PageHeader title={dictionary.PRODUCT_DETAILS_HEADER(loadedProduct.catalogNumber)}>
        <LinkButton to={routes.products.edit(loadedProduct.id)} leadingIcon={faEdit}>
          {dictionary.PRODUCT_EDIT_BUTTON}
        </LinkButton>
      </PageHeader>

      <ProductDetailsFields product={loadedProduct} />

      <div className={utilClasses.mt5}>
        <BackButton onClick={routeUserBackToProductSearch} />
      </div>
    </>
  );
};
