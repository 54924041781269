import apiClient from './apiClient';
import { DepartmentRequest, DepartmentEditRequest, Department, GetDepartmentsResponse, DepartmentPhysicianResponse, DepartmentProcedureResponse } from '../Data/Department';

export const departmentsUrl = '/departments';
export const departmentByIdUrl = (departmentId: number): string => `${departmentsUrl}/${departmentId}`;
// hotfix prop
export const physiciansByDeptIdUrl = (departmentId: number): string => `${departmentsUrl}/physicians/${departmentId}`;
export const proceduresByDeptIdUrl = (departmentId: number): string => `${departmentsUrl}/procedures/${departmentId}`;

const createDepartment = async (req: DepartmentRequest): Promise<void> =>
  apiClient.post({ url: departmentsUrl, data: JSON.stringify(req) });

const getDepartmentById = async (departmentId: number): Promise<Department> =>
  apiClient.get<Department>({ url: departmentByIdUrl(departmentId) });

const updateDepartment = async (id: number, department: DepartmentEditRequest): Promise<void> =>
  apiClient.put({ url: departmentByIdUrl(id), data: department });

const getDepartments = async (): Promise<GetDepartmentsResponse> => apiClient.get({ url: departmentsUrl });

// hotfix calls
const getPhysiciansByDepartmentId = async (departmentId: number): Promise<DepartmentPhysicianResponse[]> =>
  apiClient.get({ url: physiciansByDeptIdUrl(departmentId) });
const getProceduresByDepartmentId = async (departmentId: number): Promise<DepartmentProcedureResponse[]> =>
  apiClient.get({ url: proceduresByDeptIdUrl(departmentId) });

export const departmentService = {
  create: createDepartment,
  get: getDepartments,
  update: updateDepartment,
  getDepartmentById,
  getPhysiciansByDepartmentId,
  getProceduresByDepartmentId,
};
