import { FC, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch } from 'react-redux';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { HospitalRequest, HospitalBase } from '../../../Data/Hospital';
import { hospitalFormFields, getHospitalFormInitialVals, getHospitalUpdateRequestFromFormFields, HospitalFormFields, validateHospitalForm } from './hospitalForm.utils';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import { useUtilityStyles } from '../../../Themes/utility.styles';
import { HospitalFormContactPreview } from './HospitalFormContactPreview';
import { HealthSystem } from '../../../Data/HealthSystem';
import { healthSystemService } from '../../../Services/HealthSystemService';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../redux/initialState';
import { dictionary } from '../../../dictionary';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';

interface HospitalFormProps extends FormFlowStepComponent<HospitalRequest, HospitalBase> {
  hospital?: HospitalBase;
}

export const HospitalForm: FC<HospitalFormProps> = ({ hospital, FormActionsComponent, onFormStepComplete }) => {
  const [healthSystems, setHealthSystems] = useState<HealthSystem[]>([]);
  const utilClasses = useUtilityStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const results = await healthSystemService.get();
        const sortedResults = results.sort(alphanumericSortDescriptor('name', 1));
        setHealthSystems(sortedResults);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.HEALTH_SYSTEMS_LOAD_ERROR));
      }
    })();
  }, [dispatch]);

  const formikInitialValues = useMemo(() => getHospitalFormInitialVals(hospital, healthSystems), [hospital, healthSystems]);
  const formikProps = useFormik<HospitalFormFields>({
    initialValues: formikInitialValues,
    enableReinitialize: true,
    validate: validateHospitalForm,
    onSubmit: async (values: HospitalFormFields) => {
      const updatedHospital = getHospitalUpdateRequestFromFormFields(values);
      onFormStepComplete(updatedHospital);
    },
  });

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={4}>
          <Autocomplete
            descriptor={hospitalFormFields.healthSystem}
            formikProps={formikProps}
            options={healthSystems}
            disabled={!healthSystems.length}
          />
          <SimpleFieldWithLabel form={formikProps} descriptor={hospitalFormFields.name} />
          <SimpleFieldWithLabel form={formikProps} descriptor={hospitalFormFields.contactEmail} />
          <SimpleFieldWithLabel form={formikProps} descriptor={hospitalFormFields.contactPhoneNumber} />
          <SimpleFieldWithLabel form={formikProps} descriptor={hospitalFormFields.contactMessage} />
        </Col>
        <Col xs={8} className={utilClasses.pl3}>
          <HospitalFormContactPreview formikProps={formikProps} />
        </Col>
      </Row>

      <Row>
        <Col xs={4}>
          <FormActionsComponent />
        </Col>
      </Row>
    </form>
  );
};
