import { useState, FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import Department from '../../../Data/Department';
import User from '../../../Data/User';
import { NotificationType } from '../../../redux/initialState';
import { fetchUsers } from '../../../Services/UserService';
import { ApprovalRouting } from '../../../Data/ApprovalRouting';
import { validateBaseInformation, validateApprovers } from '../validations/approvalrouting.validation';
import { userRoles } from '../../../Utils/userRoles';
import { ApprovalRoutingFormFields } from './ApprovalRoutingForm.types';
import { ApprovalRoutingForm } from './ApprovalRoutingForm';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { dictionary } from '../../../dictionary';
import { hospitalService } from '../../../Services/HospitalService';
import { HospitalBase } from '../../../Data/Hospital';

type ApprovalRoutingFormWrapperType = FC<FormFlowStepComponent<ApprovalRoutingFormFields, ApprovalRouting>>;
export const ApprovalRoutingFormWrapper: ApprovalRoutingFormWrapperType = ({ FormActionsComponent, onFormStepComplete, editSource: approvalRouting }) => {
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState<Department[]>([]);
  const [approverOptions, setApproverOptions] = useState<User[]>([]);
  const [hospitalBases, setHospitalBases] = useState<HospitalBase[]>([]);
  const isEdit = !!approvalRouting;
  const canCurrentUserEdit = approvalRouting?.canCurrentUserEdit ?? false;

  useEffect(() => {
    (async () => {
      if (!hospitalBases.length && !isEdit) {
        try {
          const hospitalBasesResult = await hospitalService.getAdminHospitals();
          setHospitalBases(hospitalBasesResult);
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
        }
      }
    })();
  }, [dispatch, hospitalBases.length, isEdit]);

  // Load approvers list
  useEffect(() => {
    (async () => {
      const fetchedUsers = await fetchUsers(userRoles.hospital.approver);
      if (fetchedUsers) {
        setApproverOptions(fetchedUsers.filter(u => !u.archived));
      }
    })();
  }, []);

  const onHospitalChange = async (hospital: HospitalBase | null) => {
    let chosenHospital;
    try {
      chosenHospital = await hospitalService.getHospitalById(hospital!.id);
    } catch (e) {
      dispatch(addNotification(NotificationType.error, dictionary.HOSPITAL_FETCH_ERROR));
    }
    const newDepts = chosenHospital?.departments ?? [];
    setDepartments(newDepts);
  };

  const validate = (values: ApprovalRoutingFormFields) => {
    const basicErrors = validateBaseInformation(values);
    const approverErrors = validateApprovers(values);
    return { ...basicErrors, ...approverErrors };
  };

  const initialHospital = hospitalBases.length
    ? hospitalBases.find(x => x.id === approvalRouting?.hospital.id)
    : approvalRouting?.hospital;

  const formik = useFormik<ApprovalRoutingFormFields>({
    initialValues: {
      hospital: initialHospital ?? null,
      department: approvalRouting?.department ?? null,
      buyerApprovers: approvalRouting?.buyerApprovers ?? [],
      firstApprovers: approvalRouting?.firstApprovers ?? [],
      firstApproverThreshold: approvalRouting?.firstApproverThreshold ?? 0,
      secondApprovers: approvalRouting?.secondApprovers ?? [],
      secondApproverThreshold: approvalRouting?.secondApproverThreshold ?? 0,
      thirdApprovers: approvalRouting?.thirdApprovers ?? [],
    },
    validate,
    onSubmit: (values) => onFormStepComplete(values),
    enableReinitialize: true,
  });

  return (
    <ApprovalRoutingForm
      formik={formik}
      hospitals={hospitalBases}
      departments={departments}
      approverUsers={approverOptions}
      onHospitalChange={onHospitalChange}
      isEdit={isEdit}
      canCurrentUserEdit={canCurrentUserEdit}
      FormActionsComponent={FormActionsComponent}
      onFormStepComplete={onFormStepComplete}
    />
  );
};
