import { FC } from 'react';
import { dictionary } from '../../../dictionary';
import useUtilityStyles from '../../../Themes/utility.styles';
import { ReqForInterfacesTable } from '../InterfaceIndex/interfacesIndex.utils';
import { InterfaceExceptionsTable } from './InterfaceExceptionsTable';
import { RequisitionStatus } from '../../../Data/RequisitionStatus';

interface InterfacesExceptionsTableProps {
  isLoading: boolean;
  requisitions: ReqForInterfacesTable[];
}

export const InterfacesExceptionsTable: FC<InterfacesExceptionsTableProps> = (props) => {
  const { isLoading, requisitions } = props;

  const utilClasses = useUtilityStyles();

  const approvedWithExceptionsRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.approvedWithExceptions);

  return (
    <>
      <div className={utilClasses.mb2} data-testid="pending-exports-box">
        <h2 className={utilClasses.mt0}>{dictionary.INTERFACE_EXCEPTIONS_HEADER}</h2>
        <InterfaceExceptionsTable
          requisitions={approvedWithExceptionsRequisitions}
          noDataMessage={dictionary.INTERFACE_NO_EXCEPTIONS}
          isLoading={isLoading}
          initialSortOrder="desc"
          initialSortColumn="approvedDate"
        />
      </div>
    </>
  );
};
