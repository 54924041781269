import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ManufacturerBase, ManufacturerRequest } from '../../../Data/Manufacturer';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { manufacturerService } from '../../../Services/ManufacturerService';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { routes } from '../../../Utils/routes';
import { ManufacturerForm } from '../shared/ManufacturerForm';

const ManufacturerFormWrapper: FC<FormFlowStepComponent<ManufacturerRequest, ManufacturerBase>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <ManufacturerForm FormActionsComponent={FormActionsComponent} onFormStepComplete={onFormStepComplete} manufacturer={editSource} />;

export const EditManufacturer: FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const manufacturerId = Number(params.id);
  const [manufacturer, setManufacturer] = useState<ManufacturerBase>();
  const [loadingManufacturer, setLoadingManufacturer] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const result = await manufacturerService.getManufacturerById(manufacturerId);
        setManufacturer(result);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.MANUFACTURER_DETAILS_LOAD_ERR));
      } finally {
        setLoadingManufacturer(false);
      }
    })();
  }, [dispatch, manufacturerId]);

  if (loadingManufacturer) {
    return <LoadingSpinner />;
  }
  if (!manufacturer) {
    return <ErrorPage />;
  }

  const loadedManufacturer = manufacturer!;
  const onComplete = async (manufacturerRequest: ManufacturerRequest) => {
    try {
      await manufacturerService.update(loadedManufacturer.id, manufacturerRequest);
      dispatch(addNotification(NotificationType.success, dictionary.MANUFACTURER_EDIT_SUCCESS));
      history.push(routes.manufacturers.index);
    } catch (e) {
      const exception = e as AxiosError;
      if (exception.response?.status === 409) {
        dispatch(addNotification(NotificationType.error, dictionary.MANUFACTURER_NOT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.MANUFACTURER_EDIT_FAILURE));
      }
    }
  };
  return (
    <FormFlow<ManufacturerRequest, ManufacturerBase>
      editSource={loadedManufacturer}
      steps={[{ pageHeader: dictionary.MANUFACTURER_EDIT_HEADER, component: ManufacturerFormWrapper }]}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      onCancel={() => { history.push(routes.manufacturers.index); }}
      onComplete={onComplete}
    />
  );
};
