import { ContractUpdateRequest } from '../../../Data/Contract';
import { HospitalManufacturerIdentifier } from '../../../Data/ManufacturerIdentifier';
import { dictionary } from '../../../dictionary';
import { getIsoFormattedDate, getSlashFormattedDate } from '../../../Utils/dateUtils';
import { getFieldDescriptorMap, createDescriptor } from '../../../Utils/formik.utils';

export interface EditContractFormFields {
  manufacturerIdentifier?: HospitalManufacturerIdentifier,
  contractIdentifier: string,
  effectiveDate?: string | null,
  expirationDate?: string | null,
  wastedDiscount: number | '',
  explantDiscount: number | '',
  trialDiscount: number | '',
}

export const editContractFields = getFieldDescriptorMap<EditContractFormFields>([
  createDescriptor({ name: 'manufacturerIdentifier', label: dictionary.HOSPITAL_MANUFACTURER_ID_NAME_LABEL, required: false }),
  createDescriptor({ name: 'contractIdentifier', label: dictionary.CONTRACT_ID_LABEL, required: true }),
  createDescriptor({ name: 'effectiveDate', label: dictionary.CONTRACT_EFFECTIVE_DATE_LABEL, required: true }),
  createDescriptor({ name: 'expirationDate', label: dictionary.CONTRACT_EXPIRATION_DATE_LABEL, required: true }),
  createDescriptor({ name: 'wastedDiscount', label: dictionary.CONTRACT_WASTED_DISCOUNT_LABEL, required: false }),
  createDescriptor({ name: 'explantDiscount', label: dictionary.CONTRACT_EXPLANT_DISCOUNT_LABEL, required: false }),
  createDescriptor({ name: 'trialDiscount', label: dictionary.CONTRACT_TRIAL_DISCOUNT_LABEL, required: false }),
]);

export const getEditContractFormInitialValues = (contract: Partial<EditContractFormFields>): EditContractFormFields => ({
  manufacturerIdentifier: contract?.manufacturerIdentifier,
  contractIdentifier: contract?.contractIdentifier ?? '',
  effectiveDate: contract?.effectiveDate ? getSlashFormattedDate(contract?.effectiveDate) : '',
  expirationDate: contract?.expirationDate ? getSlashFormattedDate(contract?.expirationDate) : '',
  wastedDiscount: contract?.wastedDiscount || '',
  explantDiscount: contract?.explantDiscount || '',
  trialDiscount: contract?.trialDiscount || '',
});

export const getContractUpdateRequestFromFormFields = (contractId: number, values: EditContractFormFields): ContractUpdateRequest =>
  ({
    id: contractId,
    manufacturerIdentifier: values.manufacturerIdentifier?.identifier,
    contractIdentifier: values.contractIdentifier,
    effectiveDate: getIsoFormattedDate(values.effectiveDate!),
    expirationDate: getIsoFormattedDate(values.expirationDate!),
    wastedDiscount: values.wastedDiscount || undefined,
    explantDiscount: values.explantDiscount || undefined,
    trialDiscount: values.trialDiscount || undefined,
  });
