import { useFormik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useHistory } from 'react-router-dom';
import { fetchContractsForAdminUser, getContractById } from '../../../Services/ContractService';
import { SimpleFieldWithLabel } from '../../../Shared/fields/SimpleFieldWithLabel';
import Capitation, { CapitationRequest } from '../../../Data/Capitation';
import { fetchCapitationCategoriesByHospitalIds } from '../../../Services/capitationService';
import { CapitationCategoryBase } from '../../../Data/CapitationCategory';
import { Contract } from '../../../Data/Contract';
import { getSlashFormattedDate } from '../../../Utils/dateUtils';
import useUtilityClasses from '../../../Themes/utility.styles';
import Autocomplete from '../../../Shared/Autocomplete/Autocomplete';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import Hospital, { HospitalBase } from '../../../Data/Hospital';
import { alphanumericSortDescriptor } from '../../../Utils/SortUtils';
import { DisplayField } from '../../../Shared/fields/DisplayField';
import { capitationFormFields, getCapitationFormInitialVals, validateCapitationForm } from './capitationForm.utils';
import { CurrencyField } from '../../../Shared/fields/CurrencyField';
import { dictionary } from '../../../dictionary';
import { BackButton } from '../../../Shared/buttons/BackButton';
import { If } from '../../../Shared/If';
import { routes } from '../../../Utils/routes';

interface CapitationFormProps extends FormFlowStepComponent<Capitation> {
  capitation?: Capitation
}

export const CapitationForm: FC<CapitationFormProps> = ({ capitation, FormActionsComponent, onFormStepComplete }) => {
  const [contractOptions, setContractOptions] = useState<Contract[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<CapitationCategoryBase[]>([]);
  const [initialContract, setInitialContract] = useState<Contract>();
  const utilityClasses = useUtilityClasses();
  const history = useHistory();
  const isEdit = !!capitation;
  const isFormDisabled = isEdit && !capitation?.canCurrentUserEdit;

  useEffect(() => {
    (async () => {
      if (isFormDisabled && !!capitation?.contractId) {
        const fetchedContract = await getContractById(capitation.contractId);
        setContractOptions([fetchedContract]);
        if (fetchedContract) {
          setInitialContract(fetchedContract);
          if (capitation.categoryId) {
            setCategoryOptions([{ id: capitation.categoryId, name: capitation.category }]);
          }
        }
      } else {
        const fetchedContracts = await fetchContractsForAdminUser();
        setContractOptions(fetchedContracts);
        const matchedContract: Contract | undefined = (fetchedContracts?.find(x => x.id === capitation?.contractId));
        if (matchedContract) {
          setInitialContract(matchedContract);
          const hospitalIds: number[] = matchedContract.hospitals.map((h: HospitalBase) => h.id);
          const filteredCategoryOptions = await fetchCapitationCategoriesByHospitalIds(hospitalIds);
          const sortedUniqueCategoryOptions = filteredCategoryOptions
            .filter((value, index, arr) => arr.findIndex(v => v.name === value.name) === index)
            .sort(alphanumericSortDescriptor('name', 1));
          setCategoryOptions(sortedUniqueCategoryOptions);
        }
      }
    })();
  }, [capitation, isFormDisabled]);

  const initialCategory: CapitationCategoryBase | undefined = useMemo(() =>
    categoryOptions?.find(x => x.name === capitation?.category), [capitation, categoryOptions]);
  const formikInitialValues = useMemo(() =>
    getCapitationFormInitialVals(initialContract, capitation, initialCategory), [capitation, initialCategory, initialContract]);
  const formikProps = useFormik({
    initialValues: formikInitialValues,
    validate: values => validateCapitationForm(values, contractOptions),
    onSubmit: values => {
      const capitationRequest: CapitationRequest = {
        name: values.name,
        price: Number.parseFloat(values.price),
        orderIdentifier: values.orderIdentifier || null,
        contractId: values.contract.id!,
        capitationCategoryId: values.category?.id,
      };

      if (isEdit) {
        capitationRequest.id = capitation?.id;
      }
      onFormStepComplete(capitationRequest);
    },
    enableReinitialize: true,
  });
  const { contract, category } = formikProps.values;
  const contractHospitals = useMemo(() => contract?.hospitals || [], [contract]);
  const handleOnContractChange = async (selectedContract: any) => {
    setCategoryOptions([]);
    const hospitalIds: number[] = selectedContract.hospitals.map((h: Hospital) => h.id);
    const filteredCategoryOptions = await fetchCapitationCategoriesByHospitalIds(hospitalIds);
    setCategoryOptions(filteredCategoryOptions);
    formikProps.setFieldValue('contract', selectedContract);
    formikProps.setFieldValue('category', null);
  };

  const contractExpDateDisplayValue = contract && contract.expirationDate ? getSlashFormattedDate(contract.expirationDate) : undefined;
  const chosenContractOption = useMemo(() => contractOptions?.find(c => c.id === contract?.id) || null, [contractOptions, contract]);
  const chosenCategoryOption = useMemo(() => categoryOptions.find((c: CapitationCategoryBase) => c.id === category?.id) || null, [categoryOptions, category]);

  return (
    <form className="input-form" onSubmit={formikProps.handleSubmit}>
      <Row>
        <Col xs={12} md={8} xl={4}>
          <Autocomplete
            options={contractOptions}
            descriptor={capitationFormFields.contract}
            formikProps={formikProps}
            value={chosenContractOption}
            onChange={handleOnContractChange}
            disabled={isFormDisabled}
          />
          <div className={`details-section-grid ${utilityClasses.topMargin}`}>
            <DisplayField label={dictionary.CAPITATION_CONTRACT_EXP_DATE_LONG_LABEL} value={contractExpDateDisplayValue} />
            <DisplayField
              label={dictionary.CAPITATION_CONTRACT_HOSPITAL_LABEL}
              labelAlignItems="start"
              value={(
                <div className={utilityClasses.flexColumn}>
                  {contractHospitals.map(hospital => <div key={hospital.id}>{hospital.name}</div>)}
                </div>
              )}
            />
            <DisplayField label={dictionary.CAPITATION_MANUFACTURER_LABEL} value={contract?.manufacturer?.name} />
          </div>
          <SimpleFieldWithLabel descriptor={capitationFormFields.name} form={formikProps} disabled={isFormDisabled} />
          <CurrencyField descriptor={capitationFormFields.price} form={formikProps} value={formikProps.values.price} disabled={isFormDisabled} />
          <SimpleFieldWithLabel descriptor={capitationFormFields.orderIdentifier} form={formikProps} disabled={isFormDisabled} />
          <Autocomplete
            options={categoryOptions}
            descriptor={capitationFormFields.category}
            formikProps={formikProps}
            value={chosenCategoryOption}
            disabled={!categoryOptions.length || isFormDisabled}
            allowClear
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8} xl={4}>
          <If condition={!isFormDisabled}>
            <FormActionsComponent />
          </If>
          <If condition={isFormDisabled}>
            <BackButton onClick={() => history.push(routes.capitations.index)} className={utilityClasses.mt5} />
          </If>
        </Col>
      </Row>
    </form>
  );
};
