import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from './Button';
import { dictionary } from '../../dictionary';

type Props = {
  onClick: (e: React.UIEvent) => void,
  className?: string
};

export const BackButton: FC<Props> = ({ onClick, className }) => (
  <Button
    type="button"
    buttonStyle="text"
    onClick={onClick}
    data-testid="back-button"
    className={className || ''}
    leadingIcon={<FontAwesomeIcon icon={faChevronLeft} />}
  >
    {dictionary.BACK}
  </Button>
);
