import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import { FC, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { DepartmentBase, DepartmentStub } from '../../../../Data/Department';
import { PhysicianHospital } from '../../../../Data/Physician';
import { dictionary } from '../../../../dictionary';
import Autocomplete from '../../../../Shared/Autocomplete/Autocomplete';
import { useAutocomplete } from '../../../../Shared/Autocomplete/autocomplete.hooks';
import { Button } from '../../../../Shared/buttons/Button';
import { SimpleDisplayField } from '../../../../Shared/fields/SimpleDisplayField';
import { SimpleFieldWithLabel } from '../../../../Shared/fields/SimpleFieldWithLabel';
import useUtilityStyles from '../../../../Themes/utility.styles';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { alphanumericSortDescriptor } from '../../../../Utils/SortUtils';
import { editPhysicianHospitalFields, EditPhysicianHospitalFields } from './editPhysicianHospitalModal.utils';
import { EditPhysicianHospitalModalFormTable } from './EditPhysicianHospitalModalFormTable';

interface EditPhysicianHospitalFormProps {
  association: PhysicianHospital;
  formikProps: FormikProps<EditPhysicianHospitalFields>;
  departments: DepartmentStub[] | undefined;
}

export const EditPhysicianHospitalModalForm: FC<EditPhysicianHospitalFormProps> = ({ association, formikProps, departments }) => {
  const utilClasses = useUtilityStyles();
  const [selectedDepartments, setSelectedDepartments] = useState<DepartmentBase[]>(association.departments);
  const [highlightAssociationSectionError, setHighlightAssociationSectionError] = useState(false);
  const sortedActiveDepartments = departments
    ?.filter(d => !d.archived && !selectedDepartments.some(selectedD => selectedD.id === d.id))
    ?.sort(alphanumericSortDescriptor('name', 1)) || [];
  const departmentsAc = useAutocomplete<DepartmentBase>('Department', true);

  const handleUpdateDepartments = (updatedDeptList: DepartmentBase[]) => {
    setSelectedDepartments(updatedDeptList);
    formikProps.setValues({
      ...formikProps.values,
      [editPhysicianHospitalFields.departments.name]: updatedDeptList,
    });
    setHighlightAssociationSectionError(!updatedDeptList.length);
  };
  const onRemoveDepartment = (dept: DepartmentBase) => handleUpdateDepartments(selectedDepartments.filter(d => d.id !== dept.id));
  const onAddClicked = () => {
    const departmentsValid = departmentsAc.validate(departmentsAc.value);
    if (departmentsValid && Array.isArray(departmentsAc.value)) {
      const updatedDeptList = [...selectedDepartments, ...departmentsAc.value];
      handleUpdateDepartments(updatedDeptList);
      departmentsAc.resetValue();
    }
  };

  return (
    <form className="input-form">
      <Row>
        <Col xs={6} className={utilClasses.px2}>
          <SimpleDisplayField label={dictionary.PHYSICIAN_FORM_HOSPITAL_ID} value={association.name} />
          <SimpleFieldWithLabel form={formikProps} descriptor={editPhysicianHospitalFields.physicianIdentifier} />
        </Col>
        <Col xs={6} className={joinArgs(utilClasses.backgroundNearWhite, utilClasses.px2, highlightAssociationSectionError ? utilClasses.errorBorder : '')}>
          <Row className={utilClasses.mt2}>
            <Col xs={12}>
              <Autocomplete
                multiple
                data-testid="edit-physician-association-department"
                descriptor={editPhysicianHospitalFields.departments}
                formikProps={formikProps}
                onChange={departmentsAc.onChange}
                options={sortedActiveDepartments}
                value={departmentsAc.value as DepartmentBase[]}
              />
            </Col>
          </Row>
          <Row className={joinArgs(utilClasses.mt2, utilClasses.mb2)}>
            <Col xs={12} className={utilClasses.textRight}>
              <Button
                data-testid="add-departments"
                buttonStyle="reverse"
                leadingIcon={<FontAwesomeIcon icon={faPlus} />}
                onClick={onAddClicked}
              >
                {dictionary.EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_ADD_DEPARTMENTS_BUTTON}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr className={utilClasses.mt5} />
      <Row>
        <Col xs={12}>
          <h3>{dictionary.EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_MODAL_TABLE_HEADER}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <EditPhysicianHospitalModalFormTable departments={selectedDepartments} onRemoveAssociation={onRemoveDepartment} />
        </Col>
      </Row>
    </form>
  );
};
