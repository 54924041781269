import { FormikProps } from 'formik';
import { FC } from 'react';
import { HospitalManufacturerContract } from '../../../../../Data/Contract';
import { dictionary } from '../../../../../dictionary';
import { DeprecatedCurrencyField } from '../../../../../Shared/fields/DeprecatedCurrencyField';
import { SimpleFieldWithLabel } from '../../../../../Shared/fields/SimpleFieldWithLabel';
import { fontWeights } from '../../../../../Themes/fontWeights';
import { getClassForLabel, getClassForInput } from '../../../../../Utils/formik.utils';
import { createReqProductFormFields, handleChangeDiscount, handleBlurDiscount, ProductInfoFormValues, calculateHighestDiscount } from './ProductInfo.utils';
import { TransactionTypeCheckbox } from './TransactionTypeCheckbox';

interface ProductInfoReqProductFormFieldsProps {
  formikConfig: FormikProps<ProductInfoFormValues>;
  activeContracts: HospitalManufacturerContract[] | null;
  disabled?: boolean;
  allowPriceChanges?: boolean;
}
export const ProductInfoReqProductFormFields: FC<ProductInfoReqProductFormFieldsProps> = (props) => {
  const { formikConfig, activeContracts, disabled = false, allowPriceChanges } = props;
  const { errors, touched, setFieldValue, handleBlur } = formikConfig;
  const { trialDiscountApplied, wastedDiscountApplied, explantDiscountApplied } = formikConfig.values || {};
  const discountApplied = trialDiscountApplied || wastedDiscountApplied || explantDiscountApplied;
  const trialDiscountField = formikConfig.getFieldProps('trialDiscountApplied');
  const explantDiscountField = formikConfig.getFieldProps('explantDiscountApplied');
  const wastedDiscountField = formikConfig.getFieldProps('wastedDiscountApplied');

  const handleChangeTransactionType = (fieldName: string, fieldValue: boolean, form: any) => {
    let discountValues = {
      explantDiscountApplied: formikConfig.values.explantDiscountApplied,
      trialDiscountApplied: formikConfig.values.trialDiscountApplied,
      wastedDiscountApplied: formikConfig.values.wastedDiscountApplied,
    };
    discountValues = { ...discountValues, [fieldName]: !fieldValue };
    form.setFieldValue(fieldName, !fieldValue);
    // @ts-ignore
    const noDiscountsApplied = Object.keys(discountValues).every((k) => !discountValues[k]);

    if (noDiscountsApplied) {
      form.setFieldTouched('discount', false);
    }
    if (noDiscountsApplied && formikConfig.values.discount) {
      form.setFieldValue('discount', '');
    } else {
      const highestDiscount = calculateHighestDiscount(
        discountValues.explantDiscountApplied,
        discountValues.trialDiscountApplied,
        discountValues.wastedDiscountApplied,
        formikConfig.values.hospitalProduct,
        activeContracts ?? []
      );
      form.setFieldValue('discount', highestDiscount);
    }
  };
  const transationTypeSelected = explantDiscountApplied || trialDiscountApplied || wastedDiscountApplied;
  const discountPctPlaceholder = !transationTypeSelected ? 'select a transaction type first' : undefined;

  return (
    <>
      <SimpleFieldWithLabel form={formikConfig} descriptor={createReqProductFormFields.serialNumber} disabled={disabled} />
      <SimpleFieldWithLabel form={formikConfig} descriptor={createReqProductFormFields.lotNumber} disabled={disabled} />
      <SimpleFieldWithLabel form={formikConfig} descriptor={createReqProductFormFields.quantity} disabled={disabled} type="number" />

      <div className="form-group-transactionType">
        <span className="label"><strong>Transaction Type (If Applicable)</strong></span>

        <TransactionTypeCheckbox
          formikErrors={errors.wastedDiscountApplied}
          displayErrors={!!(errors.wastedDiscountApplied && formikConfig.values.wastedDiscountApplied && touched.wastedDiscountApplied && touched.discount)}
          fieldLabel={dictionary.REQ_PRODUCT_WASTED_DISCOUNT}
          fieldName={wastedDiscountField.name}
          checked={wastedDiscountField.value}
          formikFieldConfig={wastedDiscountField}
          onChangeHandler={() => handleChangeTransactionType(wastedDiscountField.name, wastedDiscountField.value, formikConfig)}
          onBlurHandler={handleBlur}
          disabled={disabled}
        />

        <TransactionTypeCheckbox
          formikErrors={errors.explantDiscountApplied}
          displayErrors={!!(errors.explantDiscountApplied && formikConfig.values.explantDiscountApplied && touched.explantDiscountApplied && touched.discount)}
          fieldLabel={dictionary.REQ_PRODUCT_EXPLANT_DISCOUNT}
          fieldName={explantDiscountField.name}
          checked={explantDiscountField.value}
          formikFieldConfig={explantDiscountField}
          onChangeHandler={() => handleChangeTransactionType(explantDiscountField.name, explantDiscountField.value, formikConfig)}
          onBlurHandler={handleBlur}
          disabled={disabled}
        />

        <TransactionTypeCheckbox
          formikErrors={errors.trialDiscountApplied}
          displayErrors={!!(errors.trialDiscountApplied && formikConfig.values.trialDiscountApplied && touched.trialDiscountApplied && touched.discount)}
          fieldLabel={dictionary.REQ_PRODUCT_TRIAL_DISCOUNT}
          fieldName={trialDiscountField.name}
          checked={trialDiscountField.value}
          formikFieldConfig={trialDiscountField}
          onChangeHandler={() => handleChangeTransactionType(trialDiscountField.name, trialDiscountField.value, formikConfig)}
          onBlurHandler={handleBlur}
          disabled={disabled}
        />
      </div>

      <label htmlFor="discount" className={`label-discount ${getClassForLabel('discount', errors, touched)}`} data-testid="discount-label">
        <div className="discount-label-container">
          <div className="discount-left">
            <span>{dictionary.REQ_PRODUCT_DISCOUNT_PERCENTAGE}</span>
            <span style={{ fontWeight: fontWeights.normal }}>Enter highest applicable discount.</span>
          </div>
          <div className="discount-right">
            <span>
              {
                errors.discount && touched.discount
                  ? <div role="alert" className="validation-errors" data-testid="discount-input-error">{errors.discount}</div>
                  : null
              }
            </span>
          </div>
        </div>
        <input
          {...formikConfig.getFieldProps('discount')}
          data-testid="discount-input"
          className={`text-input ${getClassForInput('discount', errors, touched)}`}
          placeholder={discountPctPlaceholder}
          type="number"
          min="0.00"
          max="100.00"
          step="0.01"
          disabled={!formikConfig.values.discount && (!discountApplied || Number.isNaN(formikConfig.values.discount))}
          onChange={handleChangeDiscount(setFieldValue, formikConfig.values, formikConfig)}
          onBlur={handleBlurDiscount(formikConfig.values, formikConfig, handleBlur)}
        />
      </label>

      <DeprecatedCurrencyField
        name="price"
        label="Requisition Price *"
        formikValues={formikConfig.values}
        handleBlur={handleBlur}
        disabled={disabled || !allowPriceChanges}
        form={{ touched, errors }}
        field={{
          name: 'price',
          value: formikConfig.values.price,
          type: 'text',
          onChange: (evt: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('price', evt.target.value);
          },
        }}
      />
    </>
  );
};
