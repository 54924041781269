import { createUseStyles } from 'react-jss';
import { FC } from 'react';
import { colors } from '../../Themes/colors';
import { fontWeights } from '../../Themes/fontWeights';
import useUtilityStyles from '../../Themes/utility.styles';
import { joinArgs } from '../../Utils/arrayUtils';

interface CalloutProps {
  title: string;
  className?: string;
  titleClass?: string;
}

const useCalloutStyles = createUseStyles({
  aside: {
    border: `0.0625rem solid ${colors.darkGray}`,
    borderRadius: '0.25rem',
    overflow: 'hidden',
  },
  title: {
    margin: 0,
    padding: '0.25rem 1.25rem',
    fontWeight: fontWeights.normal,
  },
  content: {
    padding: '0.75rem 1.25rem',
  },
});

export const Callout: FC<CalloutProps> = ({ children, title, titleClass, className = '' }) => {
  const styles = useCalloutStyles();
  const utilStyles = useUtilityStyles();
  const defaultTitleColorClasses = joinArgs(utilStyles.backgroundNearWhite, utilStyles.textNearBlack);
  const titleClasses = joinArgs(styles.title, titleClass || defaultTitleColorClasses);

  return (
    <aside className={joinArgs(styles.aside, className)}>
      <h2 className={titleClasses}>{title}</h2>
      <div className={styles.content}>
        {children}
      </div>
    </aside>
  );
};
