import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CostCenterStub, UpdateCostCenterRequest } from '../../../Data/CostCenter';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import LoadState from '../../../redux/loadState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { costCenterService } from '../../../Services/CostCenterService';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { FormFlow, FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';
import { PageHeaderProps } from '../../../Shared/PageHeader/PageHeader';
import { routes } from '../../../Utils/routes';
import { CostCenterForm } from '../shared/CostCenterForm';
import { ArchiveCostCenterPageHeader } from './ArchiveCostCenterPageHeader';

const CostCenterFormWrapper: FC<FormFlowStepComponent<UpdateCostCenterRequest, CostCenterStub>> = ({ FormActionsComponent, onFormStepComplete, editSource }) =>
  <CostCenterForm FormActionsComponent={FormActionsComponent} costCenter={editSource} onFormStepComplete={onFormStepComplete} />;

export const EditCostCenter: FC = () => {
  const params = useParams<{ id: string }>();
  const costCenterId = Number(params.id);
  const history = useHistory();
  const dispatch = useDispatch();
  const [costCenter, setCostCenter] = useState<CostCenterStub>();
  const [costCenterLoadState, setCostCenterLoadState] = useState(LoadState.loading);

  useEffect(() => {
    (async () => {
      try {
        const result = await costCenterService.getById(costCenterId);
        setCostCenter(result);
        setCostCenterLoadState(LoadState.loaded);
      } catch (e) {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_COST_CENTER_LOAD_ERROR));
        setCostCenterLoadState(LoadState.error);
      }
    })();
  }, [dispatch, costCenterId]);

  const PageHeaderComponent: FC<PageHeaderProps> = useMemo(() => () =>
    <ArchiveCostCenterPageHeader costCenter={costCenter!} onSuccess={setCostCenter} />,
  [costCenter]);

  if (costCenterLoadState === LoadState.loading) {
    return <LoadingSpinner />;
  }
  if (costCenterLoadState === LoadState.error) {
    return <ErrorPage />;
  }

  const onCancel = () => history.push(routes.costCenters.index);
  const onComplete = async (request: UpdateCostCenterRequest) => {
    try {
      await costCenterService.update(costCenterId, request);
      dispatch(addNotification(NotificationType.success, dictionary.EDIT_COST_CENTER_SUCCESS));
      history.push(routes.costCenters.index);
    } catch (exception) {
      if (exception?.response?.status === 409 && exception?.response?.data?.data) {
        dispatch(addNotification(NotificationType.error, dictionary.COST_CENTER_IDENTIFIER_NOT_UNIQUE_ERROR));
      } else if (exception?.response?.status === 409) {
        dispatch(addNotification(NotificationType.error, dictionary.COST_CENTER_NOT_UNIQUE_ERROR));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.EDIT_COST_CENTER_FAILURE));
      }
    }
  };

  return (
    <FormFlow<UpdateCostCenterRequest, CostCenterStub>
      editSource={costCenter}
      onComplete={onComplete}
      onCancel={onCancel}
      completeButtonLabel={dictionary.SAVE_CHANGES_BUTTON_LABEL}
      completeButtonDisabled={costCenter?.archived || !costCenter?.canCurrentUserEdit}
      isViewOnly={costCenter?.archived || !costCenter?.canCurrentUserEdit}
      steps={[
        { pageHeader: dictionary.EDIT_COST_CENTER_PAGE_HEADER, PageHeaderComponent, component: CostCenterFormWrapper },
      ]}
    />
  );
};
