import { createUseStyles } from 'react-jss';

export const useAppStyles = createUseStyles({
  main: {
    boxSizing: 'border-box',
    flex: '1 0 auto',
    margin: '0 auto',
    marginTop: '7rem',
    paddingTop: '1.25rem',
    paddingBottom: '2.5rem',
  },
});

export default useAppStyles;
