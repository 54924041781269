import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../Utils/routes';
import { PhysiciansIndex } from './PhysiciansIndex/PhysiciansIndex';
import { CreatePhysician } from './CreatePhysician/CreatePhysician';
import { EditPhysician } from './EditPhysician/EditPhysician';
import { HospitalAdminRoute } from '../../Shared/ProtectedRoute/ProtectedRoute';

export const PhysiciansRoutes: FC = () => (
  <Switch>
    <Route exact path={routes.physicians.index}>
      <PhysiciansIndex />
    </Route>
    <HospitalAdminRoute exact path={routes.physicians.create}>
      <CreatePhysician />
    </HospitalAdminRoute>
    <Route exact path={routes.physicians.editTemplate}>
      <EditPhysician />
    </Route>
  </Switch>
);
