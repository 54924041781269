import { Column } from 'react-table';
import { dictionary } from '../../../dictionary';
import { StatusRenderReqCell } from '../../../Shared/ReqStatusBadge/ReqStatusCell';
import { SortValues } from '../../../Shared/SortableTable/useSortedRows';
import { getSlashDateCellComponent } from '../../../Shared/tables/SlashDateCell';
import { buildTableCols } from '../../../Shared/tables/tableUtils';
import { getTruncatedCellComponent } from '../../../Shared/tables/TruncatedCell';
import { ReqForInterfacesTable } from '../InterfaceIndex/interfacesIndex.utils';

export const pendingExportColumnProperties = buildTableCols<ReqForInterfacesTable>([
  { Header: dictionary.REQ_STATUS, accessor: 'status', Cell: StatusRenderReqCell },
  { Header: dictionary.REQ_ID, accessor: 'id', Cell: getTruncatedCellComponent(50) },
  { Header: dictionary.FORM_CONTROL_LABEL_PATIENT_MRN, accessor: 'mrn' },
  { Header: dictionary.REQ_CASE_DATE, accessor: 'caseDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_DATE_CREATED, accessor: 'createdDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_DATE_APPROVED, accessor: 'approvedDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_HOSPITAL, accessor: 'hospitalName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_DEPT, accessor: 'departmentName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_MANUFACTURER, accessor: 'manufacturerName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_VALUE, accessor: 'value', className: 'cellAlignRight' },
]);

export const pastExportColumnProperties = buildTableCols<ReqForInterfacesTable>([
  { Header: dictionary.REQ_STATUS, accessor: 'status', Cell: StatusRenderReqCell },
  { Header: dictionary.REQ_ID, accessor: 'id', Cell: getTruncatedCellComponent(50) },
  { Header: dictionary.FORM_CONTROL_LABEL_PATIENT_MRN, accessor: 'mrn' },
  { Header: dictionary.REQ_DATE_CREATED, accessor: 'createdDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_DATE_APPROVED, accessor: 'approvedDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_DATE_EXPORTED, accessor: 'processedDate', Cell: getSlashDateCellComponent() },
  { Header: dictionary.REQ_HOSPITAL, accessor: 'hospitalName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_DEPT, accessor: 'departmentName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_MANUFACTURER, accessor: 'manufacturerName', Cell: getTruncatedCellComponent(50), width: '20%' },
  { Header: dictionary.REQ_VALUE, accessor: 'value', className: 'cellAlignRight' },
]);

export type InterfaceReqTableForIndex = {
  testId: string;
  title: string;
  reqs: ReqForInterfacesTable[];
  colProps: Column<ReqForInterfacesTable>[];
  noDataMsg: string;
  sortOrder?: SortValues;
  defaultSortColumn?: string;
  toolTip?: string;
  toolTipDefinition?: string;
};
