import { FC, useCallback, useMemo, useState } from 'react';
import { Row } from 'react-flexbox-grid';
import { MergedRequisition } from '../../../../Data/Requisition';
import RequisitionProduct from '../../../../Data/RequisitionProduct';
import { EditReqProductFormModal } from '../EditReqProductModal/EditReqProductFormModal';
import { If } from '../../../../Shared/If';
import { IndividualProductsTable } from './IndividualProductsTable/IndividualProductsTable';
import { CapitatedProductsTable } from './CapitatedProductsTable/CapitatedProductsTable';
import { dictionary } from '../../../../dictionary';
import { joinArgs } from '../../../../Utils/arrayUtils';
import { calculateGrandTotalDisplay } from '../../../../Utils/calculateGrandTotalDisplay';
import useUtilityStyles from '../../../../Themes/utility.styles';
import { EmptyState } from '../../../../Shared/EmptyState/EmptyState';

export interface ProductsToBillProps {
  requisition: MergedRequisition;
  addCapitationOnClick?: (productIndices: number[]) => void;
  editable?: boolean;
  capitationsApplicable?: boolean;
  hospHasProductCategories?: boolean;
}

export const ProductsToBill: FC<ProductsToBillProps> = (props) => {
  const { requisition, addCapitationOnClick, editable = false, capitationsApplicable = false, hospHasProductCategories = false } = props;

  const [productInEditInfo, setProductInEditInfo] = useState<[RequisitionProduct, number | undefined, number]>();
  const utilClasses = useUtilityStyles();

  const closeModal = useCallback(() => setProductInEditInfo(undefined), []);
  const grandTotal = useMemo(() => calculateGrandTotalDisplay(requisition), [requisition]);
  const anyProductsAddedToReq = (!!requisition.products?.length) || (!!requisition.capitations?.length);
  return (
    <>
      <If condition={anyProductsAddedToReq}>
        <If condition={!!requisition.products?.length}>
          <IndividualProductsTable
            editable={editable}
            requisition={requisition}
            setProductInEditInfo={setProductInEditInfo}
            addCapitationOnClick={addCapitationOnClick}
            capitationsApplicable={capitationsApplicable}
            hospHasProductCategories={hospHasProductCategories}
          />
        </If>
        <If condition={!!requisition.capitations?.length}>
          <CapitatedProductsTable
            editable={editable}
            requisition={requisition}
            setProductInEditInfo={setProductInEditInfo}
            hospHasProductCategories={hospHasProductCategories}
          />
        </If>
        <hr className={utilClasses.mt5} />
        <Row className={joinArgs(utilClasses.flexEnd, utilClasses.mr2, utilClasses.fs18)}>
          <strong className={utilClasses.mr2}>{ dictionary.REQ_PRODUCTS_TOTAL }</strong>
          <strong data-testid="grand-total">{ grandTotal }</strong>
        </Row>

        <If condition={editable}>
          <EditReqProductFormModal productInfo={productInEditInfo} requisitionId={requisition.id} closeModal={closeModal} />
        </If>
      </If>
      <If condition={!anyProductsAddedToReq}>
        <EmptyState>
          {dictionary.REQ_PRODUCTS_TO_BILL_EMPTY}
        </EmptyState>
      </If>
    </>
  );
};
