import { createUseStyles, Styles } from 'react-jss';
import { ButtonProps } from '../Shared/buttons/Button';
import { Intent } from '../Shared/UiTypes';
import Colors from './colors';
import { fontWeights } from './fontWeights';

const faChildElement = (rules: Styles) => ({
  '& [data-icon][class*=fa-]': rules,
});

const disabledCSSProperties = {
  backgroundColor: Colors.disabledDark,
  color: Colors.grayHover,
  borderColor: Colors.disabledDark,
};

const disabledStyles: Styles = ({
  ...disabledCSSProperties,
  cursor: 'not-allowed',
  pointerEvents: 'none',
});

interface ButtonStylesProps {
  buttonStyle?: ButtonProps['buttonStyle'];
  intent?: Intent;
}

export const useButtonStyles = (props: ButtonStylesProps = { buttonStyle: 'primary', intent: 'primary' }): ReturnType<typeof useButtonStylesBehind> => useButtonStylesBehind(props);

const getIconButtonColor = (intent: Intent = 'primary', hasHoverOrFocus: boolean): string => {
  const defaultMap: Record<Intent, string> = {
    primary: Colors.blue,
    secondary: Colors.nearBlack,
    danger: Colors.nearBlack,
  };
  const hoverOrFocusMap: Record<Intent, string> = {
    primary: Colors.kingfisher,
    secondary: Colors.nearBlack,
    danger: Colors.red,
  };

  return hasHoverOrFocus ? hoverOrFocusMap[intent] : defaultMap[intent];
};

// Don't modify the cursor property here, `cursor: pointer;` is intended only
// for links — https://drafts.csswg.org/css-ui-3/#valdef-cursor-pointer
const useButtonStylesBehind = createUseStyles<any, ButtonStylesProps>({
  base: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0.125rem solid',
    borderRadius: '0.25rem',
    fontSize: '.875rem',
    fontWeight: fontWeights.bold,
    padding: '.5em 1.5em',
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: '0.025em',
    '&[disabled]': disabledStyles,
  },
  primaryIntent: {
    backgroundColor: Colors.blue,
    color: props => (props.buttonStyle !== 'text' && props.buttonStyle !== 'reverse' ? Colors.trueWhite : Colors.blue),
    borderColor: Colors.blue,

    '&:hover, &:focus': {
      backgroundColor: Colors.kingfisher,
      borderColor: Colors.kingfisher,
    },
  },
  dangerIntent: {
    color: props => (props.buttonStyle !== 'text' && props.buttonStyle !== 'reverse' ? Colors.trueWhite : Colors.red),
    backgroundColor: Colors.red,
    borderColor: Colors.red,

    '&:hover, &:focus': {
      borderColor: Colors.darkRedHover,
      backgroundColor: Colors.darkRedHover,
    },
  },
  leadingIcon: props => (props.buttonStyle !== 'icon' ? faChildElement({ marginRight: '1rem' }) : ''),
  trailingIcon: faChildElement({ marginLeft: '1rem' }),
  large: {
    fontSize: '1.125rem',
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
  reverse: {
    backgroundColor: Colors.trueWhite,
    color: Colors.blue,

    '&:hover, &:focus': {
      backgroundColor: Colors.nearWhite,
      color: Colors.kingfisher,
    },
  },
  text: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: props => (props.intent === 'danger' ? Colors.nearBlack : Colors.blue),

    '&:hover, &:focus': {
      borderColor: Colors.nearWhite,
      backgroundColor: Colors.nearWhite,
      color: props => (props.intent === 'danger' ? Colors.redHover : Colors.kingfisher),
    },
    '&[disabled]': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  },
  altText: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: props => (props.intent === 'danger' ? Colors.red : Colors.nearBlack),

    '&:hover, &:focus': {
      borderColor: Colors.nearWhite,
      backgroundColor: Colors.nearWhite,
      color: props => (props.intent === 'danger' ? Colors.redHover : Colors.nearBlack),
    },
    '&[disabled]': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  },
  icon: {
    padding: '0.5rem',
    margin: 0,
    backgroundColor: 'transparent',
    border: 'none',
    color: props => getIconButtonColor(props.intent, false),
    fontSize: '1rem',
    '&:hover, &:focus': {
      backgroundColor: Colors.nearWhite,
      color: props => getIconButtonColor(props.intent, true),
    },
    '&[disabled]': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  },
  insetRight: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 'calc(100% - 1rem)',
    margin: '0.5rem 0',
    padding: '0 0.5rem',
    borderColor: 'transparent',
    color: Colors.nearBlack,
    zIndex: 1,

    '&:hover, &:focus': {
      borderColor: Colors.kingfisher,
    },
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});
