import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CapitationCategoryRequest } from '../../../Data/CapitationCategory';
import { dictionary } from '../../../dictionary';
import { NotificationType } from '../../../redux/initialState';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { createCapitationCategory } from '../../../Services/capitationService';
import { FormFlow } from '../../../Shared/FormFlow/FormFlow';
import { routes } from '../../../Utils/routes';
import { CapitationCategoryForm } from '../shared/CapitationCategoryForm';

export const CreateCapitationCategory: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onCancel = () => history.push(routes.capitationCategories.index);
  const onComplete = async (request: CapitationCategoryRequest) => {
    try {
      await createCapitationCategory(request);
      dispatch(addNotification(NotificationType.success, dictionary.CREATE_CAPITATION_CATEGORY_SUCCESS));
      history.push(routes.capitationCategories.index);
    } catch (exception) {
      if (exception?.response?.status === 409) {
        dispatch(addNotification(NotificationType.error, dictionary.CREATE_CAPITATION_CATEGORY_UNIQUE_ERROR(exception.response.data?.data)));
      } else {
        dispatch(addNotification(NotificationType.error, dictionary.CREATE_CAPITATION_CATEGORY_ERROR));
      }
    }
  };

  return (
    <FormFlow<CapitationCategoryRequest>
      onComplete={onComplete}
      onCancel={onCancel}
      completeButtonLabel={dictionary.CREATE_CAPITATION_CATEGORY_SUBMIT_BTN}
      steps={[
        { pageHeader: dictionary.CREATE_CAPITATION_CATEGORY_FORM_PAGE_HEADER, component: CapitationCategoryForm },
      ]}
    />
  );
};
