import { Name } from '../Data/Name';
import { getFullName } from './nameUtils';

export type SortDescriptor = (sortProperty: string | number | symbol, sortOrderMulit: number) => (a: any, b: any) => number;

export const priceSortDescriptor: SortDescriptor =
  (sortProperty, sortOrderMulit) =>
    (a, b) =>
      (parseFloat(a[sortProperty].split('$')[1].replace(/,/g, '')) - parseFloat(b[sortProperty].split('$')[1].replace(/,/g, ''))) * sortOrderMulit;

const replaceNonDigitsAndSlashes = (s: string): string => s.replace(/[^\d/]/g, '');
const replaceNonDigits = (s: string): string => s.replace(/\D/g, '');
const toInt = (s: string): number => parseInt(s, 10);
const isEnUSDate = (s: string): boolean => /\d{1,2}\/\d{1,2}\/\d{2,4}/.test(s);
const dateStringToDate = (dateString: string | Date): Date => {
  if (dateString instanceof Date) return dateString;

  if (!isEnUSDate(replaceNonDigitsAndSlashes(dateString))) return new Date(dateString);
  const [month, day, year] = dateString.split('/').map(segment => toInt(replaceNonDigits(segment)));

  return new Date(year, month - 1, day);
};

export const dateSortDescriptor: SortDescriptor =
  (sortProperty, sortOrderMultiplier) =>
    (a, b) => {
      const dateA = dateStringToDate(a[sortProperty]).getTime();
      const dateB = dateStringToDate(b[sortProperty]).getTime();

      if (dateA < dateB) return -1 * sortOrderMultiplier;
      if (dateA > dateB) return 1 * sortOrderMultiplier;

      return 0;
    };

export const alphanumericSortDescriptor: SortDescriptor =
  (sortProperty, sortOrderMultiplier) => (a, b) => (a[sortProperty] || '').toString().localeCompare((b[sortProperty] || '').toString()) * sortOrderMultiplier;

export const nameSortDescriptor = (a: Name, b: Name): number => (getFullName(a) || '').localeCompare((getFullName(b) || ''));

export const numberSortDescriptor: SortDescriptor =
  (sortProperty, sortOrderMulit) =>
    (a, b) => (a[sortProperty] - b[sortProperty]) * sortOrderMulit;

const dateColumns: (string | number | symbol)[] =
  ['createdDate', 'caseDate', 'approvedDate', 'expirationDate'];

const numberColumns: (string | number | symbol)[] = ['id'];

export const sortItemsOn =
  <T>(
    items: T[],
    sortProperty: keyof T,
    sortOrder: 'asc' | 'desc',
    sortDescriptor: SortDescriptor = alphanumericSortDescriptor,
  ): T[] => {
    const sortOrderMultiplier = (sortOrder === 'asc' ? 1 : -1);
    let sortFunction = sortDescriptor(sortProperty, sortOrderMultiplier);

    // TODO: Remove these if checks for determining the descriptor to use.
    if (dateColumns.includes(sortProperty)) {
      sortFunction = dateSortDescriptor(sortProperty, sortOrderMultiplier);
    }

    if (sortProperty === 'value') {
      sortFunction = priceSortDescriptor(sortProperty, sortOrderMultiplier);
    }

    if (numberColumns.includes(sortProperty)) {
      sortFunction = numberSortDescriptor(sortProperty, sortOrderMultiplier);
    }
    return items.slice().sort(sortFunction);
  };
