import { FC, useMemo } from 'react';
import { Column } from 'react-table';
import { ReqTable } from '../shared/RequisitionsTable/ReqTable';
import { dictionary } from '../../../../dictionary';
import useUtilityStyles from '../../../../Themes/utility.styles';
import {
  pendingApprovalColumnProperties,
  approvedColumnProperties,
  declinedColumnProperties,
  ReqForIndexTable,
  ReqTableForIndex,
} from '../shared/RequisitionsTable/reqTable.utils';
import { RequisitionStatus } from '../../../../Data/RequisitionStatus';
import { If } from '../../../../Shared/If';

interface SupportRequisitionsProps {
  isLoading: boolean;
  requisitions: ReqForIndexTable[];
  activeTab: number;
}

export const SupportRequisitions: FC<SupportRequisitionsProps> = (props) => {
  const { isLoading, requisitions, activeTab } = props;

  const utilClasses = useUtilityStyles();

  const notApprovedOrDeclined = (req: ReqForIndexTable) =>
    req.statusCode !== RequisitionStatus.approved
    && req.statusCode !== RequisitionStatus.waitingForPo
    && req.statusCode !== RequisitionStatus.completed
    && req.statusCode !== RequisitionStatus.archived
    && req.statusCode !== RequisitionStatus.declined;

  const processingRequisitions = requisitions.filter(notApprovedOrDeclined);
  const approvedRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.approved
    || req.statusCode === RequisitionStatus.waitingForPo
    || req.statusCode === RequisitionStatus.completed);
  const declinedRequisitions = requisitions.filter(req => req.statusCode === RequisitionStatus.declined);

  const SupportReqTable = useMemo((): FC<{ reqs: ReqForIndexTable[], colProps: Column<ReqForIndexTable>[], noDataMsg: string }> => ({ reqs, colProps, noDataMsg }) => (
    <ReqTable
      requisitions={reqs}
      columns={colProps}
      noDataMessage={noDataMsg}
      isLoading={isLoading}
      initialSortOrder="desc"
    />
  ), [isLoading]);

  const requisitionTables: ReqTableForIndex[] = [
    {
      testId: 'processing-requisitions-box',
      title: dictionary.REQS_PROCESS_PROCESSING_HEADER,
      reqs: processingRequisitions,
      colProps: pendingApprovalColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_PROCESSING_EMPTY,
    },
    {
      testId: 'approved-requisitions-box',
      title: dictionary.REQS_APPROVED_HEADER,
      reqs: approvedRequisitions,
      colProps: approvedColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_APPROVED_EMPTY,
    },
    {
      testId: 'declined-requisitions-box',
      title: dictionary.REQS_DECLINED_HEADER,
      reqs: declinedRequisitions,
      colProps: declinedColumnProperties,
      noDataMsg: dictionary.REQUISITIONS_APPROVER_DECLINED_EMPTY,
    },
  ];

  return (
    <>
      { requisitionTables.map((table, index) => (
        <If condition={activeTab === 0 || activeTab === index + 1} key={table.testId}>
          <div className={utilClasses.mb2} data-testid={table.testId}>
            <h2 className={utilClasses.mt0}>{table.title}</h2>
            <SupportReqTable reqs={table.reqs} colProps={table.colProps} noDataMsg={table.noDataMsg} />
          </div>
        </If>
      )) }
    </>
  );
};
