import { createStore, compose, applyMiddleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { apiMiddleware } from 'redux-api-middleware';
import authInjectorMiddleware from '../Utils/authInjectorMiddleware';
import rootReducer from './rootReducer';

export default function createStoreWithMiddleware(initialState = {}): { store: Store } {
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(compose(applyMiddleware(authInjectorMiddleware, apiMiddleware))),
  );

  return { store };
}
