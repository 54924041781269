/* eslint-disable react/require-default-props */
import { useRef, useState, useEffect, FocusEvent, ReactElement, MouseEvent, KeyboardEvent, ChangeEvent, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faPlus, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { joinArgs } from '../../Utils/arrayUtils';
import useUtilStyles from '../../Themes/utility.styles';
import { useButtonStyles } from '../../Themes/button.styles';
import useStyles from './autocomplete.styles';
import { useDropdownStyles } from '../Dropdown/dropdown.styles';
import {
  ListBoxState,
  ChangeReason,
  filterItems,
  getSelectedItemsAsArray,
  isFocusEvent,
  getOptionLabel as defaultGetOptionLabel,
  getOptionId,
  hasValueIsNotArray } from './autocomplete.utils';
import { AutocompleteProps, Id, ListBoxEvents } from './autocomplete.types';
import { dictionary } from '../../dictionary';
import { If } from '../If';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

type CreateNew = {
  id: -1;
  name: 'CREATE_NEW';
};
const CREATE_NEW_MAGIC_OBJ: CreateNew = { id: -1, name: 'CREATE_NEW' };

type NoResults = {
  id: -2;
  name: 'NO_RESULTS';
};
const NO_RESULTS_MAGIC_OBJ: NoResults = { id: -2, name: 'NO_RESULTS' };
type PossibleOptions<T> = T | CreateNew | NoResults;
type VisibleOptions<T> = PossibleOptions<T>[];

function isCreateNew<T>(item: PossibleOptions<T>): item is CreateNew {
  if (item === CREATE_NEW_MAGIC_OBJ) return true;

  return false;
}

function isNoResults<T>(item: PossibleOptions<T>): item is NoResults {
  if (item === NO_RESULTS_MAGIC_OBJ) return true;

  return false;
}

export default function Autocomplete<T extends Id>({
  value: providedValue,
  descriptor,
  options,
  formikProps,
  onChange: providedOnChange,
  onFilterTextChange,
  onKeyPress,
  label: providedLabel,
  id: providedId,
  onBlur: providedOnBlur,
  validationError: providedValidationError,
  multiple = false,
  'data-testid': providedTestId,
  className = '',
  placeholder = '',
  disabled = false,
  allowClear = false,
  direction = 'down',
  onCreateNew,
  disableUnderline = false,
  getOptionLabel = defaultGetOptionLabel,
  isLoading = false,
  icon,
}: AutocompleteProps<T>): ReactElement {
  // Would prefer useState<T['Name'] | T['ContractIdentifier']> but don't know how to type that correctly. - tb
  if ((descriptor && !formikProps) || (formikProps && !descriptor)) {
    throw new Error('If you provide descriptor or formik props, you must provide the other.');
  }
  const id = providedId || `${descriptor!.name}-autocomplete`;
  const testId = providedTestId || id;
  let label = providedLabel || descriptor?.label || '';
  if (!providedLabel && descriptor?.required) {
    label = `${label} ${dictionary.REQUIRED_FIELD_MARK}`;
  }

  const fallbackOnChange = useMemo(() => (
    multiple
      ? (nextValue: T[] | null) => formikProps!.setFieldValue(descriptor!.name, nextValue)
      : (nextValue: T | null) => formikProps!.setFieldValue(descriptor!.name, nextValue)
  ), [descriptor, formikProps, multiple]);
  const onChange = providedOnChange || fallbackOnChange;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onChangeIsMultiple(onChangeFn: typeof fallbackOnChange): onChangeFn is (nextValue: T[] | null) => void {
    return multiple;
  }

  const onBlur = providedOnBlur || formikProps?.handleBlur(descriptor!.name) || (() => {});
  const validationError = providedValidationError || (formikProps?.touched[descriptor!.name] ? formikProps!.errors[descriptor!.name] : '');
  const value = providedValue || formikProps?.values[descriptor!.name] || null;

  const [inputValue, setInputValue] = useState<string>(() => {
    if (
      multiple === false &&
      value !== null &&
      value !== undefined &&
      value !== '' &&
      !Array.isArray(value)
    ) {
      return getOptionLabel(value);
    }

    return '';
  });
  const [visibleOptions, setVisibleOptions] = useState<VisibleOptions<T>>(() => {
    const items = filterItems(value, options, inputValue, multiple, getOptionLabel);
    if (onCreateNew) {
      return [
        ...(items.length ? items : [NO_RESULTS_MAGIC_OBJ]),
        CREATE_NEW_MAGIC_OBJ,
      ];
    }

    return items.length ? items : [NO_RESULTS_MAGIC_OBJ];
  });
  const [listBoxVisibility, setListBoxVisiblity] = useState<ListBoxState>(ListBoxState.Hidden);
  const [activeItemIndex, setActiveItemIndex] = useState<null | number>(null);
  const [cursorIsTrapped, setCursorIsTrapped] = useState(false);
  const activeItemRef = useRef<HTMLLIElement| null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const numberOfIcons = icon ? 2 : 1;
  const classes = useStyles({ numberOfIcons });
  const dropdownClasses = useDropdownStyles();
  const utilClasses = useUtilStyles();
  const buttonClasses = useButtonStyles();

  // as input value changes, update the list of visible items
  useEffect(() => {
    const filteredItems = filterItems(value, options, inputValue, multiple, getOptionLabel);

    let curVisibleOptions: VisibleOptions<T> = filteredItems;

    if (filteredItems.length === 0) {
      curVisibleOptions = [NO_RESULTS_MAGIC_OBJ];
    }

    if (onCreateNew) {
      curVisibleOptions = [
        ...curVisibleOptions,
        CREATE_NEW_MAGIC_OBJ,
      ];
    }

    setActiveItemIndex(null);
    setVisibleOptions(curVisibleOptions);
  }, [inputValue, options, value, multiple, onCreateNew, getOptionLabel]);

  // ensure active item is in view
  useEffect(() => {
    if (activeItemIndex === null) return;
    if (!activeItemRef.current) return;

    activeItemRef.current.scrollIntoView(false);
  }, [activeItemIndex]);

  // keep input value up to date with `value` props
  useEffect(() => {
    if (multiple) {
      if (!Array.isArray(value) && value !== null) throw new Error('Autocomplete is `multiple`, but value is not an array.');
      setInputValue('');
    } else {
      if (Array.isArray(value)) throw new Error('Autocomplete is not `multiple`, but value is an array.');
      setInputValue(!value ? '' : getOptionLabel(value));
    }
  }, [getOptionLabel, multiple, value]);

  function clearAutocomplete() {
    onChange(null);
    setInputValue('');
    setListBoxVisiblity(ListBoxState.Hidden);
  }

  function updateSelectedItems(reason: ChangeReason, item: PossibleOptions<T>) {
    if (isNoResults(item)) throw new Error('Trying to call `updateSelectedItems` with NoResults magic obhject. This is an error in Autocomplete');
    switch (reason) {
      case ChangeReason.Add: {
        if (isCreateNew(item)) throw new Error('Trying to call add new item and the item is the CreateNew Magic Obj. This is an error in Autocomplete');
        if (onChangeIsMultiple(onChange)) {
          if (value === null) onChange([item]);
          else if (Array.isArray(value)) onChange(value.concat(item));
          else throw new Error('Trying to update Autocomplete with an array when Autcomplete is not `multiple`.');

          setInputValue('');
          setListBoxVisiblity(ListBoxState.Hidden);
        } else {
          if (Array.isArray(value)) throw new Error('Trying to update Autocomplete with an array when Autcomplete is not `multiple`.');

          onChange(item);
          setInputValue(item === null ? '' : getOptionLabel(item));
          setListBoxVisiblity(ListBoxState.Hidden);
        }
        break;
      }
      case ChangeReason.Remove: {
        if (isCreateNew(item)) throw new Error('Trying to call add new item and the item is the CreateNew Magic Obj. This is an error in Autocomplete');
        if (value === null) throw new Error('Trying to remove an item when the value is set to null. This should never occur.');
        if (onChangeIsMultiple(onChange)) {
          if (Array.isArray(value)) onChange(value.filter(i => getOptionId(i) !== getOptionId(item)));
          else throw new Error('Error trying to remove item: Autocomplete is set to `multiple` and value is not an Array.');
          setInputValue('');
          setListBoxVisiblity(ListBoxState.Hidden);
        } else {
          if (Array.isArray(value)) throw new Error('Trying to remove an items in Autocomplete with an array when Autcomplete is not `multiple`.');

          onChange(item);
          setInputValue('');
          setListBoxVisiblity(ListBoxState.Hidden);
        }
        break;
      }
      case ChangeReason.CreateNew: {
        if (!onCreateNew) throw new Error(`${ChangeReason.CreateNew} called when \`onCreateNew\` not defined.`);
        onCreateNew();
        setInputValue('');
        setListBoxVisiblity(ListBoxState.Hidden);
        break;
      }

      default: throw new Error('Invalid change passed to `updateSelectedItems`');
    }

    setListBoxVisiblity(ListBoxState.Hidden);
  }

  function updateListBoxState(
    reason: ListBoxState,
    evt: ListBoxEvents,
  ) {
    switch (reason) {
      case ListBoxState.Hidden: {
        if (cursorIsTrapped) {
          setCursorIsTrapped(false);
          return;
        }
        setListBoxVisiblity(reason);
        if (isFocusEvent(evt)) {
          if (value === null || value === undefined) setInputValue('');
          else if (Array.isArray(value)) setInputValue('');
          else if (getOptionLabel(value) !== inputValue) setInputValue(getOptionLabel(value));

          onBlur(evt as FocusEvent<HTMLInputElement>);
        }
        break;
      }
      case ListBoxState.Visible: {
        /**
         * @Refactor - We should unify how we figure out what options to display.
         */
        if (multiple === false) {
          let opts: VisibleOptions<T> = options;

          if (opts.length === 0) {
            opts = [NO_RESULTS_MAGIC_OBJ];
          }
          setVisibleOptions(onCreateNew ? [...opts, CREATE_NEW_MAGIC_OBJ] : opts);
        }

        setListBoxVisiblity(reason);
        break;
      }
      default: throw new Error('Invalid reason passed to `updateListBoxState`');
    }
  }

  function trapCursorIfListBoxVisible(evt: MouseEvent<HTMLUListElement>) {
    evt.preventDefault();
    if (listBoxVisibility === ListBoxState.Visible && inputRef.current) {
      setCursorIsTrapped(true);
      inputRef.current.focus();
    }
  }

  function handleKeyDown(evt: KeyboardEvent<HTMLInputElement>) {
    // if listbox is not visible, we want to highlight the current item on one
    // these key down events.
    if (listBoxVisibility === ListBoxState.Hidden) {
      if (evt.key === 'ArrowDown' || evt.key === 'ArrowUp' || evt.key === 'Home' || evt.key === 'End') {
        evt.preventDefault();
        if (inputValue) {
          if (multiple === false) {
            const idx = options.findIndex(option => getOptionLabel(option) === inputValue);

            if (idx !== -1) {
              setActiveItemIndex(idx);
            }
          }
        }
        updateListBoxState(ListBoxState.Visible, evt);
      }
      return;
    }
    const noResultsIndex = visibleOptions.findIndex(opt => isNoResults(opt));

    switch (evt.key) {
      // navigation related
      case 'ArrowDown': {
        evt.preventDefault();
        let idx = activeItemIndex;

        if (idx === null || idx === visibleOptions.length - 1) {
          idx = 0;
        } else {
          idx += 1;
        }

        if (noResultsIndex === idx) {
          if (visibleOptions.length === 1) {
            idx = null;
          } else {
            idx += 1;
          }
        }
        setActiveItemIndex(idx);
        break;
      }
      case 'ArrowUp': {
        evt.preventDefault();
        let idx = activeItemIndex;

        if (idx === null || idx === 0) {
          idx = visibleOptions.length - 1;
        } else {
          idx -= 1;
        }

        if (noResultsIndex === idx) {
          if (visibleOptions.length === 1) {
            idx = null;
          } else {
            idx += 1;
          }
        }

        setActiveItemIndex(idx);
        break;
      }
      case 'Home': {
        evt.preventDefault();
        if (visibleOptions.length === 0) break;

        let idx: number | null = 0;

        if (noResultsIndex !== -1) {
          if (visibleOptions.length === 1) {
            idx = null;
          } else {
            idx = 1;
          }
        }
        setActiveItemIndex(idx);
        break;
      }
      case 'End': {
        evt.preventDefault();
        if (visibleOptions.length === 0) break;
        let idx: number | null = visibleOptions.length - 1;

        if (noResultsIndex === 0 && visibleOptions.length === 1) {
          idx = null;
        }

        setActiveItemIndex(idx);
        break;
      }

      // close the listbox
      case 'Escape': {
        evt.preventDefault();
        updateListBoxState(ListBoxState.Hidden, evt);
        break;
      }

      // select value
      case 'Enter': {
        evt.preventDefault();
        if (activeItemIndex === null) return;
        const item = visibleOptions
          .slice(activeItemIndex, activeItemIndex + 1)
          .shift();

        if (item === undefined) return;

        setActiveItemIndex(null);
        updateSelectedItems(isCreateNew(item) ? ChangeReason.CreateNew : ChangeReason.Add, item);
        break;
      }
      case 'Shift':
      case 'Control':
      case 'Alt':
        break;
      default:
    }
  }

  function handleInputChange(evt: ChangeEvent<HTMLInputElement>) {
    if (listBoxVisibility === ListBoxState.Hidden) {
      updateListBoxState(ListBoxState.Visible, evt);
    }
    setInputValue(evt.target.value);
    if (onFilterTextChange) {
      onFilterTextChange(evt.target.value);
    }
  }

  let renderedOptions = null;

  if (listBoxVisibility === ListBoxState.Visible) {
    renderedOptions = visibleOptions.map((item, idx) => {
      if (isCreateNew(item)) {
        return (
          <li
            id={`${id}-listbox-item-create-new`}
            data-testid={`${testId}-listbox-item-create-new`}
            className={joinArgs(
              dropdownClasses.listItem,
              validationError ? 'label-invalid-field' : '',
              idx === activeItemIndex ? 'focused' : '',
              utilClasses.flex,
              utilClasses.spaceBetween,
              utilClasses.alignCenter,
            )}
            ref={idx === activeItemIndex ? activeItemRef : null}
            key={`autocomplete-visibleoptions-${id}-create-new`}
            onClick={() => updateSelectedItems(ChangeReason.CreateNew, item)}
            onKeyDown={() => {}}
            role="option"
            aria-selected={idx === activeItemIndex ? 'true' : 'false'}
          >
            {dictionary.AUTOCOMPLETE_CANT_FIND_PRODUCT}

            <em>{dictionary.AUTOCOMPLETE_CREATE_NEW_PRODUCT}</em>
          </li>
        );
      }

      if (isNoResults(item)) {
        return (
          <li
            key={`autocomplete-visibleoptions-${id}-no-results`}
            className={joinArgs(
              dropdownClasses.listItem,
              utilClasses.textGrayMinimumContrast,
              utilClasses.pointerEventsNone,
            )}
          >
            {id !== 'hospitalProduct' ? dictionary.AUTOCOMPLETE_NO_OPTIONS : dictionary.AUTOCOMPLETE_NEED_RESULTS}
          </li>
        );
      }

      const optionId = getOptionId(item);
      return (
        <li
          id={`${id}-listbox-item-${optionId}`}
          data-testid={`${testId}-listbox-item-${optionId}`}
          className={joinArgs(
            dropdownClasses.listItem,
            idx === activeItemIndex ? 'focused' : '',
          )}
          ref={idx === activeItemIndex ? activeItemRef : null}
          key={`autocomplete-visibleoptions-${id}-${optionId}`}
          onClick={() => {
            if (!isCreateNew(item)) {
              updateSelectedItems(ChangeReason.Add, item);
            }
          }}
          onKeyDown={() => {}}
          role="option"
          aria-selected={idx === activeItemIndex ? 'true' : 'false'}
        >
          {getOptionLabel(item)}
        </li>
      );
    });
  }

  const showInputClear = allowClear && hasValueIsNotArray(value) && !multiple;

  let placeholderText = placeholder;

  if (!placeholderText) {
    placeholderText = multiple ? 'type to filter list, click to add' : 'type to filter list';
  }

  return (
    <section
      className={joinArgs(
        className ?? '',
        classes.autocompleteWrapper,
        listBoxVisibility === ListBoxState.Visible ? classes.elevate : '',
        validationError ? 'label-invalid-field' : '',
      )}
      data-testid={testId}
    >
      <If condition={!!label}>
        <label
          id={`${id}-label`}
          htmlFor={id}
          data-testid={`${testId}-label`}
          className={`${classes.label}${label}`}
        >
          <div className={joinArgs(utilClasses.flex, utilClasses.spaceBetween,)}>
            <div>{label}</div>

            {validationError && <div role="alert" className="validation-errors" data-testid={`${testId}-validation-error`}>{validationError}</div>}
          </div>
        </label>
      </If>

      <div
        id={`${id}-combobox`}
        data-testid={`${testId}-combobox`}
        className={classes.inputWrapper}
        role="combobox"
        aria-expanded="false"
        aria-owns={`${id}-listbox`}
        aria-controls={`${id}-listbox`}
        aria-haspopup="listbox"
      >
        <input
          id={id}
          data-testid={`${testId}-input`}
          className={joinArgs(
            classes.input,
            'text-input',
            (disabled || isLoading) ? utilClasses.backgroundDisabled : '',
            validationError ? 'input-invalid-field' : '',
            showInputClear ? dropdownClasses.inputClearVisible : '',
            utilClasses.my05,
          )}
          placeholder={placeholderText}
          disabled={disabled || isLoading}
          type="text"
          value={inputValue || ''}
          ref={inputRef}
          onBlur={evt => { updateListBoxState(ListBoxState.Hidden, evt); }}
          onClick={evt => {
            if (listBoxVisibility === ListBoxState.Hidden) updateListBoxState(ListBoxState.Visible, evt);
          }}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onKeyPress={onKeyPress}
          spellCheck="false"
          autoComplete="off"
          autoCapitalize="none"
          aria-activedescendant={activeItemIndex !== null ? `${id}-listbox-item-${getOptionId(visibleOptions[activeItemIndex])}` : ''}
        />
        <InputActions
          disabled={disabled || isLoading}
          showClear={showInputClear}
          onClear={() => clearAutocomplete()}
          icon={icon}
          dropdownIcon={multiple ? faPlus : faCaretDown}
          data-testid={`${testId}-clear-input`}
          isLoading={isLoading}
        />
      </div>

      <div
        className={joinArgs(
          dropdownClasses.listBoxContainer,
          listBoxVisibility === ListBoxState.Hidden ? utilClasses.srOnly : '',
          direction === 'up' ? dropdownClasses.listBoxDirectionUp : '',
        )}
      >
        <ul
          id={`${id}-listbox`}
          data-testid={`${testId}-listbox`}
          className={joinArgs(
            dropdownClasses.listReset,
            dropdownClasses.listBox,
          )}
          onMouseDown={trapCursorIfListBoxVisible}
          aria-labelledby={`${id}-label`}
          role="listbox"
        >
          {renderedOptions}
        </ul>
      </div>

      {multiple && (
        <ul
          id={`${id}-tagbox`}
          data-testid={`${testId}-tagbox`}
          className={joinArgs(
            dropdownClasses.listReset,
            classes.tagBox,
            disableUnderline ? classes.disableUnderline : '',
          )}
        >
          {getSelectedItemsAsArray(value).map((item) => {
            const optionId = getOptionId(item);
            return (
              <li
                className={classes.tag}
                id={`${id}-tagitem-${optionId}`}
                data-testid={`${testId}-tagitem-${optionId}`}
                key={`autocomplete-selected-item-${id}-${optionId}`}
              >
                <button
                  className={joinArgs(buttonClasses.base, buttonClasses.reverse, buttonClasses.trailingIcon, utilClasses.noTextTransform)}
                  type="button"
                  onClick={() => {
                    if (disabled) { return; }
                    updateSelectedItems(ChangeReason.Remove, item);
                  }}
                  disabled={disabled}
                >
                  <span className={classes.tagName}>{getOptionLabel(item)}</span>
                  <If condition={!disabled}>
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                  </If>
                </button>
              </li>
            );
          }) }
        </ul>
      )}
    </section>
  );
}

export interface AutocompleteInputActionsProps {
  disabled: boolean;
  showClear: boolean;
  onClear: () => void;
  icon?: IconDefinition;
  dropdownIcon: IconDefinition;
  'data-testid': string;
  isLoading: boolean;
}

function InputActions({
  showClear,
  onClear,
  icon,
  dropdownIcon,
  'data-testid': testId,
  disabled,
  isLoading,
}: AutocompleteInputActionsProps) {
  const utilClasses = useUtilStyles();
  const dropdownClasses = useDropdownStyles();

  if (isLoading) {
    return (
      <div className={joinArgs(dropdownClasses.inputActions, utilClasses.pointerEventsNone)}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className={joinArgs(dropdownClasses.inputActions, utilClasses.pointerEventsNone)}>
      {showClear && (
        <button
          disabled={disabled}
          type="button"
          onClick={onClear}
          className={joinArgs(
            dropdownClasses.inputActionClear,
            utilClasses.mr025,
            utilClasses.pointerEventsAll,
          )}
          aria-label="Clear autocomplete value."
          data-testid={testId}
        >
          <FontAwesomeIcon icon={faTimes} size="1x" />
        </button>
      )}
      <If condition={!!icon}>
        <FontAwesomeIcon icon={icon!} size="1x" className={utilClasses.mr05} />
      </If>
      <FontAwesomeIcon icon={dropdownIcon} size="1x" />
    </div>
  );
}
