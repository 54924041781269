import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { routes } from '../../../Utils/routes';
import { dictionary } from '../../../dictionary';
import { hospitalService } from '../../../Services/HospitalService';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { ErrorPage } from '../../../Shared/ErrorPage';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { If } from '../../../Shared/If';
import { HospMfctIdentifiersTable } from './HospMfctIdentifiersTable/HospMfctIdentifiersTable';
import { HospMfctIdentifierStub } from '../../../Data/ManufacturerIdentifier';
import { currentUserIsAdminForAtLeastOneHospital } from '../../../redux/user/userSelectors';

export const HospMfctIdentifiersIndex: FC = () => {
  const [hmIdentifiers, setHmIdentifiers] = useState<HospMfctIdentifierStub[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const showCreateButton = useSelector(currentUserIsAdminForAtLeastOneHospital);

  useEffect(() => {
    (async () => {
      try {
        const hmiResponse = await hospitalService.fetchHospitalManufacturerIdentifiers();
        setHmIdentifiers(hmiResponse.hospitalManufacturerIdentifiers);
        setIsLoading(false);
      } catch (e) {
        setHasLoadingError(true);
      }
    })();
  }, []);

  if (hasLoadingError) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader title={dictionary.HOSPITAL_MANUFACTURER_ID_LIST_HEADER_LABEL}>
        <If condition={showCreateButton}>
          <LinkButton to={routes.hospitalManufacturerIdentifiers.create}>{dictionary.HOSPITAL_MANUFACTURER_ID_CREATE_NEW_ACTION}</LinkButton>
        </If>
      </PageHeader>

      <HospMfctIdentifiersTable hospitalManufacturerIds={hmIdentifiers ?? []} isLoading={isLoading} />
    </>
  );
};
