import { useState, useEffect, FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { ApiRequisition } from '../../../Data/Requisition';
import { fetchRequisitionById, requisitionApiService } from '../../../Services/RequisitionService';
import { dictionary } from '../../../dictionary';
import Notes from './Notes/Notes';
import { routes } from '../../../Utils/routes';
import { ReqProcedureInfoDisplay } from '../../Requisitions/shared/ReqProcedureInfoDisplay';
import { ReqCasePatientInfoDisplay } from '../../Requisitions/shared/ReqCasePatientInfoDisplay';
import { ReqBillingInfoDisplay } from '../../Requisitions/shared/ReqBillingInfoDisplay';
import { joinArgs } from '../../../Utils/arrayUtils';
import { useUtilityStyles } from '../../../Themes/utility.styles';
import { ReqDetailsProductInfo } from './ReqDetailsProductInfo';
import { ReqStatusBadge } from '../../../Shared/ReqStatusBadge/ReqStatusBadge';
import { BackButton } from '../../../Shared/buttons/BackButton';
import { Button } from '../../../Shared/buttons/Button';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../redux/initialState';
import { getDisplayStatus } from '../shared/requisition.utils';
import { costCenterService } from '../../../Services/CostCenterService';
import { RequisitionUpdateRequest } from '../../../Data/RequisitionRequest';
import { ManuallyUnarchiveReqModal } from './ManuallyUnarchiveReqModal';
import { If } from '../../../Shared/If';
import { currentUserIsAdminForAtLeastOneHospital } from '../../../redux/user/userSelectors';

export const RequisitionDetails: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [requisition, setRequisition] = useState<ApiRequisition>();
  const utilClasses = useUtilityStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const goToReqIndex = () => history.push(routes.archivedRequisitions.index);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [captureReqId, setCaptureReqId] = useState<number | undefined>();
  const canUnarchive = useSelector(currentUserIsAdminForAtLeastOneHospital);

  useEffect(() => {
    (async () => {
      if (requisition?.department && !requisition.costCenter) {
        const costCenterResponse = await costCenterService.getByDepartment(requisition.department.id) || [];
        if (costCenterResponse!.length === 1) {
          requisition.costCenter = costCenterResponse[0]; // eslint-disable-line
          const reqUpdateRequest: RequisitionUpdateRequest = {
            id: requisition.id,
            procedureId: requisition.procedure.id,
            physicianId: requisition.physician.id,
            patientId: requisition.patient.id,
            caseNumber: requisition.caseNumber,
            caseDate: requisition.caseDate!,
            costCenterId: requisition.costCenter.id,
            subCostCenterId: requisition.subCostCenter?.id || null,
            poNumber: requisition.poNumber || null,
            additionalNotes: requisition.additionalNotes || null,
          };
          requisitionApiService.updateBaseInfo(reqUpdateRequest);
        }
      }
    })();
  }, [requisition, isModalOpen]);

  useEffect(() => {
    (async () => setRequisition(await fetchRequisitionById(Number(id))))();
  }, [id]);

  if (!requisition?.id) {
    return <p data-testid="requisition-details-loading">{dictionary.REQ_DETAILS_LOADING_REQ}</p>;
  }

  const cancelOnClick = () => setIsModalOpen(false);

  const manuallyUnarchiveReq = async (reqId: number | undefined) => {
    if (reqId) {
      try {
        await requisitionApiService.unarchiveRequisition(reqId);
        goToReqIndex();
        dispatch(addNotification(NotificationType.success, dictionary.UNARCHIVED_REQ_SUCCESS));
      } catch (exception) {
        dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
      }
    }
    setIsModalOpen(false);
  };

  const openModalAndCaptureReqId = (reqId: number) => {
    setIsModalOpen(true);
    setCaptureReqId(reqId);
  };

  return (
    <>
      <PageHeader title={`${dictionary.REQ_VIEW_HEADER}: ${requisition.id}`}>
        <span className={utilClasses.fs21}>
          {`${dictionary.REQ_VIEW_DOCUMENT_NUMBER}: ${requisition.id}`}
        </span>
      </PageHeader>

      <Row className={utilClasses.mb1}>
        <Col xs={3}>
          <ReqStatusBadge status={getDisplayStatus(requisition)} large />
        </Col>
        <Col xs={9} className={joinArgs(utilClasses.flex, utilClasses.flexEnd)}>
          <If condition={canUnarchive}>
            <Button onClick={() => openModalAndCaptureReqId(requisition.id)}>{dictionary.MANUALLY_UNARCHIVE_REQ_BUTTON}</Button>
          </If>
        </Col>
      </Row>

      <Row>
        <Col xs={4}><ReqProcedureInfoDisplay requisition={requisition} /></Col>
        <Col xs={4}><ReqCasePatientInfoDisplay requisition={requisition} /></Col>
        <Col xs={4}><ReqBillingInfoDisplay requisition={requisition} /></Col>
      </Row>

      <ReqDetailsProductInfo req={requisition} hospHasProductCategories={false} />

      <Row>
        <Col xs={12}><Notes historyNotes={requisition.history ?? []} /></Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div data-testid="action-buttons" className={joinArgs(utilClasses.flex, utilClasses.spaceBetween, utilClasses.mt5)}>
            <BackButton onClick={goToReqIndex} />
          </div>
        </Col>
      </Row>
      <ManuallyUnarchiveReqModal
        cancelOnClick={cancelOnClick}
        isModalOpen={isModalOpen}
        manuallyUnarchiveReq={manuallyUnarchiveReq}
        captureReqId={captureReqId}
      />
    </>
  );
};
